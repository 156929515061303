import React, { Component } from 'react';
import moment from "moment";
import {isEmpty} from '../../../../../../utils/helpers';
import Loader from '../../../../../../components/Loader';
import ServiceOrder from './ServiceOrder';
import Panels from "../../../index";

export class Services extends Component {

	state = {
		activeServiceDropdown: '',
		activeServiceLineDropdownId: '',
		search: '',
	}

	handleActiveDropDown = (type, id, forceClose) => {
		if (type === 'service') {
			this.setState({
				activeServiceDropdown: (forceClose || this.state.activeServiceDropdown === id) ? '' : id,
				activeServiceLineDropdownId: ''
			});
		}
		else {
			this.setState({
				activeServiceLineDropdownId: (forceClose || this.state.activeServiceLineDropdownId === id) ? '' : id,
				activeServiceDropdown: ''
			});
		}
	};

	handleProductAndPackagesClick = (planId, serviceLineId, planRelation, startDate) => {
		if (moment(startDate).isAfter(moment())) {
			alert(`Line has a future date, ${moment(startDate).format('MM/DD/YYYY')}. You are not allowed to make changes to the selected line at this time.`);
			return;
		}
		let data = {
			wizardType: 'updateService',
			wizardData: {
				planId,
				serviceLineId,
				planRelation
			}
		};

		this.props.showAccountDetailsWizard(data);
	};

	handleRemoveServiceLinesClick = (accountInfo, serviceInfo) => {
		let data = {
			wizardType: 'removeServiceLines',
			wizardData: {
				accountInfo,
				service: serviceInfo
			}
		};

		this.props.showAccountDetailsWizard(data);
	};

	handleSearch = (e) => {
		this.setState({
			search: e.target.value
		});
	};

	searchWord = (data) => {
		if (this.state.search === '') return data;
		let result = data.filter(this.filteredData);
		return result;
	};

	filteredData = (item) => {

		// search term
		let search = this.state.search.toLowerCase();

		// properties excluded from search
		let excludeKeys = ['activeFeaturePackages', 'activeFeatures'];

		// go over all item properties
		for (let key in item) {

			// if we encounter a nested array...
			if (Array.isArray(item[key]) && !excludeKeys.includes(key)) {

				// ... go over its properties
				for (const element of item[key]) {

					if (this.filteredData(element) === true) {
						return true;
					}
				}
			}
			else if (
				!excludeKeys.includes(key) &&
				item.hasOwnProperty(key) &&
				item[key]
			) {

/* UNCOMMENT THE LINES BELOW TO DO AN EXACT SERVICE LINE NUMBER MATCH
// if we're looking at service line number...
if (key === "number") {

// ... do a complete match
if (item[key].toString().toLowerCase() === search) {
return true;
}
}
// ... otherwise do a partial match
else
*/

				if (item[key].toString().toLowerCase().indexOf(search) !== -1) {
					return true;
				}
			}
		}
		return false;
	};

	render() {

		const {
			activeServiceDropdown,
			activeServiceLineDropdownId,
			search
		} = this.state;

		const {
			servicesLoading,
			servicesData,
			history,
			showHistoricalData,
		} = this.props;

		if (servicesLoading) {
			return <Loader />;
		}

		if (!servicesLoading && isEmpty(servicesData)) {
			return <p className="tab-pane-message-empty">No data found</p>;
		}

		let filteredData = this.searchWord(servicesData);

		return (
			<>

				<div className="tab-pane-toolbar">
					<input className="form-control" placeholder="Search Servicelines" onChange={this.handleSearch} />
				</div>

				{!servicesLoading && filteredData.map((service, index) => (
				<ServiceOrder
					key={index}
					searchWord={search}
					serviceInfo={service}
					activeServiceDropdown={activeServiceDropdown}
					activeServiceLineDropdownId={activeServiceLineDropdownId}
					handleActiveDropDown={this.handleActiveDropDown}
					handleProductAndPackagesClick={this.handleProductAndPackagesClick}
					handleRemoveServiceLinesClick={this.handleRemoveServiceLinesClick}
					showAddEquipmentWizardHandler={this.props.showAddEquipmentWizardHandler}
					showRemoveEquipmentWizardHandler={this.props.showRemoveEquipmentWizardHandler}
					showSwapEquipmentWizardHandler={this.props.showSwapEquipmentWizardHandler}
					showValidateServiceWizardHandler={this.props.showValidateServiceWizardHandler}
					showHistoricalData={showHistoricalData}
					history={history}
				/>
				))}

			</>
		);
	}
}

export default Services;
