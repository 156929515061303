import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import isEmpty from "src/utils/helpers";
import FormSelect from "src/components/UI/FormSelect";

import { hideModal } from "src/actions/modal.actions";
import { getModalData, getUser, getInventory } from "src/selectors";
import {
  getInventoryLocations,
  getSerializedItem,
  addSerializedItem,
  updateSerializedItemStatus,
  clearSerializedItem,
} from "src/actions/inventory.actions";
import LoadingModalContent from "src/components/Modal/LoadingModalContent";

/**
 * Edit location and status of a serialized inventory item
 * 
 * @param {any} modalProps.value - Serialized inventory item
 * @param {any[]} modalProps.locations - List of locations
 * @param {(item) => void} modalProps.onSuccess - callback function to be called on successful update, called with updated item
 */
class EditInventorySerializedModal extends Component {
  state = {
    alertMessage: "",
    isLoading: true,
  };

  componentDidMount() {
    const { modalProps } = this.props;
    (!this.props.locations || this.props.locations.length === 0) && this.props.getInventoryLocations();

    if (modalProps.value) {
      this.props.getSerializedItem(modalProps.value.id).then((response) => {
        this.setState({
          isLoading: false,
        });
      });
    } else {
      this.props.clearSerializedItem().then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  }

  componentWillUnmount() {}

  formSchema = () => {
    let validationSchema = {
      locationId: Yup.string().required("Required"),
      status: Yup.string().required("Required"),
    };

    return Yup.object().shape(validationSchema);
  };

  hideModal = () => {
    this.props.clearSerializedItem();
    this.props.hideModal();
  };

  formSubmit = (values, actions) => {
    this.setState({ alertMessage: "" }, () => {
      const postData = {
        ...values,
      };

      this.props.updateSerializedItemStatus(this.props.serializedItem.id, postData).then((response) => {
        if (!response.data) {
          actions.setSubmitting(false);
          this.setState({
            alertMessage: "Unknown error. Please try again later",
          });
          return;
        }

        if (!response.data.success) {
          actions.setSubmitting(false);
          this.setState({ alertMessage: response.data.error.message });
          return;
        }

        this.hideModal();

        let serializedItem = { ...this.props.modalProps.value };
        serializedItem.status = values.status
        serializedItem.location = this.props.modalProps.locations.find(l => l.id === values.locationId)
        this.props.modalProps.onSuccess(serializedItem);
      });
    });
  };

  scrollDropdownIntoView = (e) => {
    const elementClicked = e.target.nodeName;
    const boundingElement = e.currentTarget;
    const modalBody = document.getElementsByClassName("modal-body")[0];

    if (elementClicked !== "LABEL") {
      setTimeout(function () {
        // Scroll down if the bottom is hidden...
        if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {
          // ... and the top won't end up hidden by scrolling down
          if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {
            // Scroll down till bottom of element reaches bottom of screen
            boundingElement.scrollIntoView({ block: "end" });
          }
        }
      }, 200);
    }
  };

  render() {
    const { alertMessage } = this.state;
    const { serializedItem, locations, statuses } = this.props;

    const locationOptions = (locations || []).map((p) => ({
      name: "locationId",
      value: p.id,
      label: p.name,
    }));
    const inventoryStatusOptions = (statuses || []).map((p) => ({
      name: "status",
      value: p,
      label: p,
    }));

    return (
      <Fragment>
        <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
          {this.state.isLoading && <LoadingModalContent modalTitle="Edit Inventory" submitButtonLabel="Save" />}
          {!this.state.isLoading && (
            <Formik
              initialValues={{
                locationId: serializedItem?.location?.id || "",
                status: serializedItem?.status || "",
              }}
              validationSchema={this.formSchema()}
              onSubmit={this.formSubmit}
            >
              {({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Edit Inventory</h5>
                        <button onClick={this.hideModal} type="button" className="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body cmv-form form-horizontal">
                        {!isEmpty(alertMessage) && (
                          <div className="alert alert-inline alert-danger alert-dismissible">
                            <p className="mb-0">{alertMessage}</p>
                          </div>
                        )}
                        <div className="order-service-order-wrapper">
                          <div>
                            <h4 className="text-uppercase">{serializedItem?.item?.name}</h4>
                            <p className="billing-job-id">{serializedItem?.item?.description}</p>
                            <ul className="billing-job-id pl-3">
                            {serializedItem?.numbers?.map((n) => (
                              <li key={`item_num_${n.id}`}>
                                {n.systemNumType?.name}: {n.number}
                              </li>
                            ))}
                            </ul>
                          </div>
                        </div>

                        <div className="form-group position-relative" onClick={this.scrollDropdownIntoView.bind(this)}>
                          <FormSelect
                            fieldName="locationId"
                            placeholder={"Select one ..."}
                            options={locationOptions}
                            value={values.locationId}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            isLoading={this.state.isLoading}
                            isDisabled={isSubmitting}
                          />
                          <p className="text-success">
                            <small>Current location is {serializedItem.location?.name}</small>
                          </p>
                        </div>
                        <div className="form-group position-relative" onClick={this.scrollDropdownIntoView.bind(this)}>
                          <FormSelect
                            fieldName="status"
                            placeholder={"Select one ..."}
                            options={inventoryStatusOptions}
                            value={values.status}
                            onBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            isDisabled={isSubmitting}
                          />
                          <p className="text-success">
                            <small>Current status is {serializedItem.status}</small>
                          </p>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button onClick={this.hideModal} className="btn" type="button">
                          Cancel
                        </button>
                        <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps,
    serializedItem = getInventory(state).serializedItem,
    statuses = getInventory(state).statuses,
    locations = getInventory(state).locations,
    currentUser = getUser(state).user;

  return {
    modalProps,
    serializedItem,
    locations,
    statuses,
    currentUser,
  };
};

const mapDispatchToProps = {
  hideModal,
  addSerializedItem,
  updateSerializedItemStatus,
  getSerializedItem,
  clearSerializedItem,
  getInventoryLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInventorySerializedModal);
