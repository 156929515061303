import React, {Component, Fragment} from 'react';
import moment from 'moment';
import isEmpty from "../../../../../../utils/helpers";

class Service extends Component {

	state = {
		showAllFeatures: false,
	}

	toggleShowAllFeatures = () => {
		this.setState({showAllFeatures: !this.state.showAllFeatures});
	}

	render() {

		const {
			showAllFeatures,
		} = this.state;

		const {
			serviceInfo,
		} = this.props;

		let planFeatures = [];

		// Iterate service lines
		if (serviceInfo && serviceInfo.serviceLines) {
			serviceInfo.serviceLines.forEach(serviceLine => {

				// Iterate feature packages
				if (serviceLine.activeFeaturePackages) {
					serviceLine.activeFeaturePackages.forEach(activeFeaturePackage => {

						// Iterate feature package options
						if (activeFeaturePackage.featurePackageOptions) {
							activeFeaturePackage.featurePackageOptions.forEach(featurePackageOption => {

								// Iterate features
								if (featurePackageOption.features) {
									featurePackageOption.features.forEach(feature => {

										// Check feature against ones already collected
										planFeatures = checkFeature(feature, planFeatures);
									});
								}
							});
						}
					});
				}
				// Iterate features
				if (serviceLine.servicelineFeatures) {
					serviceLine.servicelineFeatures.forEach(feature => {
						// Check feature against ones already collected
						planFeatures = checkFeature(feature, planFeatures);
					});
				}
			});
		}

		function checkFeature(feature, planFeaturesUpdated) {

			let featuresExists = false,
				featuresIndex = false,
				planFeaturesLength = planFeaturesUpdated.length;

			// If we have collected no features yet
			if (planFeaturesLength === 0) {
				// Add first feature
				planFeaturesUpdated.push({
					qty: feature.qty || 1,
					featureDescription: feature.featureDescription,
					recurringMonthlyPrice: feature.recurringMonthlyPrice
				});
			}
			// If we have collected some features
			else if (planFeaturesLength > 0) {

				// Go over collected features
				for (let i = 0; i < planFeaturesLength; i++) {

					// If feature exists - note index & stop
					if (planFeaturesUpdated[i].featureDescription === feature.featureDescription && planFeaturesUpdated[i].recurringMonthlyPrice === feature.recurringMonthlyPrice) {
						featuresExists = true;
						featuresIndex = i;
						break;
					}
				}

				// If feature found - add quantity
				if (featuresExists) {
					planFeaturesUpdated[featuresIndex].qty += feature.qty;
				}

				// If feature not found - add feature
				else {
					planFeaturesUpdated.push({
						qty: feature.qty || 1,
						featureDescription: feature.featureDescription,
						recurringMonthlyPrice: feature.recurringMonthlyPrice
					});
				}
			}

			return planFeaturesUpdated;
		}

		return (
			<div className="card-horizontal card-plan">
				<div className="card-body">
					<div className="plan-body">
						<div className="service-wrapper">

							{serviceInfo.serviceLines.map((serviceLine, index) => (serviceLine.planRelation === "Main" && (
								<div className="service-header">
									<span className="service-title"><strong>{serviceLine.number || "Main Service Line"}</strong></span>
								</div>
							)))}

							<div className="service-body service-body-aligned">

								{isEmpty(planFeatures) && (
									<div className="service-summary">
										No data found.
									</div>
								)}

								{!isEmpty(planFeatures) && planFeatures.map((planFeature, index) => (showAllFeatures || (!showAllFeatures && index < 4)) &&
									<div className="service-summary">
										<span>{planFeature.qty}</span>
										<span>{planFeature.featureDescription}</span>
										<span>
										{!isEmpty(planFeature.recurringMonthlyPrice) && (
											planFeature.qty
												? <>${((planFeature.recurringMonthlyPrice.toFixed(2)) * planFeature.qty).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo</>
												: <>${planFeature.recurringMonthlyPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo</>
										)}
									</span>
									</div>
								)}

								{planFeatures.length > 4 &&
									<div className="text-center">
										<button
											className="btn btn-link"
											onClick={this.toggleShowAllFeatures}
										>
											Show {showAllFeatures ? "Less" : "More"}
										</button>
									</div>
								}

							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Service;