import {
	GET_ALLOWED_BILL_CYCLES,
	LOAD_BILLING_JOBS,
	LOAD_BILLING_JOB,
} from '../actions/billingJobs.actions';

let initialState = {
	allowedBillCycles: [],
	billingJobs: [],
	billingJob: [],
};

export const billingJobsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ALLOWED_BILL_CYCLES.SUCCESS:
			return {
				...state,
				allowedBillCycles: action.payload.allowedBillCycles || [],
			}
		case LOAD_BILLING_JOBS.SUCCESS:
			return {
				...state,
				billingJobs: action.payload.billingJobs
			}
		case LOAD_BILLING_JOBS.CLEAR:
			return {
				...state,
				billingJobs: []
			}
		case LOAD_BILLING_JOB.SUCCESS:
			return {
				...state,
				billingJob: action.payload
			}
		default:
			return state;
	}
};
