import {
	SET_BILLING_BADJES,
	SET_BALANCES_INFO,
	SET_ADJUSTMENTS,
	SET_ADJUSTMENT_TYPE,
	SET_ACCOUNT_SERVICELINES,
	SET_INVOICES,
	SET_PAYMENTS,
	SET_INSTALLMENTPLAN,
	SET_DISCOUNTS,
	SET_RECCURING_CREDITS,
	SET_DISCOUNT_TYPES,
	SET_RECCURING_CREDIT_TYPES,
	SET_PAYMENT_PROMISES,
	SET_TAX_EXEMPTIONS,
	SET_TAX_CODES,
	SET_STATUS_HISTORY,
	GET_BILLING_SETTINGS,
	GET_DUE_DATE,
	GET_ACCOUNT_BALANCE,
	GET_ACCOUNT_CREDIT_BALANCE,
	GET_DUE_DETAILS,
	GET_AMOUNT_DUE,
	SET_SUPPORTED_REVERSAL_TYPES,
	UPDATE_BILLING_SETTINGS,
	// SET_PAYMENT_DETAILS
} from '../../actions/accountDetailsActions/billing.actions';
import {GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS} from "../../actions/admin.actions";

let initialState = {
	billingBadjes: {},
	balancesInfo: {},
	adjustments: [],
	adjustmentTypes: [],
	accountServicelines: [],
	invoices: [],
	payments: [],
	installmentplans: [],
	reccuringCredits: [],
	reccuringCreditTypes: [],
	discounts: [],
	discountTypes: [],
	paymentPromises: [],
	taxExemptions: [],
	taxCodes: [],
	statusHistories: [],
	paymentDetails: [],
	billingSettings: null,
	dueDate: null,
	amountDue: null,
	balance: null,
	creditBalance: null,
	distributionMethods: [],
	supportedReversalTypes: [],
};

export const billing = (state = initialState, action) => {
	switch (action.type) {
		case SET_BILLING_BADJES.SUCCESS:
			return {
				...state,
				billingBadjes: action.payload
			}
		case SET_BILLING_BADJES.EMPTY: {
			return {
				...state,
				billingBadjes: {}
			}
		}
		case SET_BALANCES_INFO.SUCCESS:
			return {
				...state,
				balancesInfo: action.payload
			};
		case SET_BALANCES_INFO.EMPTY:
			return {
				...state,
				balancesInfo: {}
			};
		case SET_ADJUSTMENTS.SUCCESS:
			return {
				...state,
				adjustments: action.payload
			};
		case SET_ADJUSTMENTS.EMPTY:
			return {
				...state,
				adjustments: []
			};
		case SET_ADJUSTMENT_TYPE.SUCCESS:
			return {
				...state,
				adjustmentTypes: action.payload
			};
		case SET_ADJUSTMENT_TYPE.EMPTY:
			return {
				...state,
				adjustmentTypes: []
			};
		case SET_ACCOUNT_SERVICELINES.SUCCESS:
			return {
				...state,
				accountServicelines: action.payload
			};

		case SET_ACCOUNT_SERVICELINES.EMPTY:
			return {
				...state,
				accountServicelines: []
			};
		case SET_INVOICES.SUCCESS:
			return {
				...state,
				invoices: action.payload
			};
		case SET_INVOICES.EMPTY:
			return {
				...state,
				invoices: []
			};
		case SET_PAYMENTS.SUCCESS:
			return {
				...state,
				payments: action.payload
			};
		case SET_PAYMENTS.EMPTY:
			return {
				...state,
				payments: []
			};
		case SET_INSTALLMENTPLAN.SUCCESS:
			return {
				...state,
				installmentplans: action.payload
			};
		case SET_INSTALLMENTPLAN.EMPTY:
			return {
				...state,
				installmentplans: []
			};
		case SET_SUPPORTED_REVERSAL_TYPES.SUCCESS:
			return {
				...state,
				supportedReversalTypes: action.payload
			};
		case SET_SUPPORTED_REVERSAL_TYPES.EMPTY:
			return {
				...state,
				supportedReversalTypes: []
			};
		case SET_RECCURING_CREDITS.SUCCESS:
			return {
				...state,
				reccuringCredits: action.payload
			}
		case SET_RECCURING_CREDITS.EMPTY:
			return {
				...state,
				reccuringCredits: []
			}
		case SET_DISCOUNTS.SUCCESS:
			return {
				...state,
				discounts: action.payload
			}
		case SET_DISCOUNTS.EMPTY:
			return {
				...state,
				discounts: []
			}
		case SET_DISCOUNT_TYPES.SUCCESS:
			return {
				...state,
				discountTypes: action.payload
			}
		case SET_DISCOUNT_TYPES.EMPTY:
			return {
				...state,
				discountTypes: []
			}
		case SET_RECCURING_CREDIT_TYPES.SUCCESS:
			return {
				...state,
				reccuringCreditTypes: action.payload
			}
		case SET_PAYMENT_PROMISES.SUCCESS:
			return {
				...state,
				paymentPromises: action.payload
			}
		case SET_PAYMENT_PROMISES.EMPTY:
			return {
				...state,
				paymentPromises: []
			}
		case SET_TAX_EXEMPTIONS.SUCCESS:
			return {
				...state,
				taxExemptions: action.payload
			}
		case SET_TAX_EXEMPTIONS.EMPTY:
			return {
				...state,
				taxExemptions: []
			}
		case SET_TAX_CODES.SUCCESS:
			return {
				...state,
				taxCodes: action.payload
			}
		case SET_TAX_CODES.EMPTY:
			return {
				...state,
				taxCodes: []
			}
		case SET_STATUS_HISTORY.SUCCESS:
			return {
				...state,
				statusHistories: action.payload
			}
		case SET_STATUS_HISTORY.EMPTY:
			return {
				...state,
				statusHistories: []
			}
		case GET_BILLING_SETTINGS.SUCCESS:
			return {
				...state,
				billingSettings: action.payload
			}
		case UPDATE_BILLING_SETTINGS.SUCCESS:
			return {
				...state,
				billingSettings: action.payload
			}
		case GET_BILLING_SETTINGS.EMPTY:
			return {
				...state,
				billingSettings: null
			}
		case GET_DUE_DATE.SUCCESS:
			return {
				...state,
				dueDate: action.payload,
			}
		case GET_DUE_DATE.EMPTY:
			return {
				...state,
				dueDate: null,
			}
		case GET_AMOUNT_DUE.SUCCESS:
			return {
				...state,
				amountDue: action.payload,
			}
		case GET_AMOUNT_DUE.EMPTY:
			return {
				...state,
				amountDue: null,
			}
		case GET_ACCOUNT_BALANCE.SUCCESS:
			return {
				...state,
				balance: action.payload
			}
		case GET_ACCOUNT_BALANCE.EMPTY:
			return {
				...state,
				balance: null
			}
		case GET_ACCOUNT_CREDIT_BALANCE.SUCCESS:
			return {
				...state,
				creditBalance: action.payload
			}
		case GET_ACCOUNT_CREDIT_BALANCE.EMPTY:
			return {
				...state,
				creditBalance: null
			}
		// case SET_PAYMENT_DETAILS.SUCCESS:
		// 	return {
		// 		...state,
		// 		paymentDetails: action.payload
		// 	}
		// case SET_PAYMENT_DETAILS.EMPTY:
		// 	return {
		// 		...state,
		// 		paymentDetails: []
		// 	}
		case GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS.SUCCESS:
			return {
				...state,
				distributionMethods: action.payload
			}
		case GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS.EMPTY:
			return {
				...state,
				distributionMethods: []
			}
		default:
			return state;
	}
};
