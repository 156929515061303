import React, {Component} from 'react';
import {connect} from 'react-redux';
import {createLoadingSelector} from '../../../../selectors';
import {Form, Formik} from "formik";
import isEmpty, {generateId, getErrorMessage} from "../../../../utils/helpers";
import {createOrderChangeDevice} from "../../../../actions/dashboardActions/orders.actions";
import ServiceOrder from "../ServiceOrder";
import {validateServiceLineStatus} from "../../../../actions/serviceLine.actions";
import {getInterfacesByProvisioningPath} from "../../../../actions/provisioning.actions";
import {toastr} from "react-redux-toastr";

class Validate extends Component {

    state = {
        provisioningInterfaces: null,
        allInterfacesSelected: true,
    }

    componentDidMount() {
        const {
            wizardData,
        } = this.props;
        let deviceProfiles = [];
        for (const serviceDevice of wizardData.serviceDevices) {
            deviceProfiles.push(serviceDevice.deviceProfileName);
        }
        this.loadInterfaces(deviceProfiles);
    }

    loadInterfaces = (deviceProfiles) => {
        const { setStepState } = this.props;

        let actionType = 'QUERY';
        this.setState({ loadingProvisioningInterfaces: true });
        this.props.getInterfacesByProvisioningPath(deviceProfiles).then((resp) => {
            if (resp.data && resp.data.success) {
                this.setState({provisioningInterfaces: resp.data.provisioningInterfaces.map(pi => ({...pi, selected: false,})),
                                     allInterfacesSelected: true,
                                     loadingProvisioningInterfaces: false})
            } else {
                this.setState({ loadingProvisioningInterfaces: false });
                toastr.error(getErrorMessage(resp).message, { timeOut: 3000, position: 'top-center' });
            }
        }).catch(error => {
            this.setState({ loadingProvisioningInterfaces: false });
            toastr.error(getErrorMessage(error).message, { timeOut: 3000, position: 'top-center' });
            console.error(error);
        });
    }

    handleOnSubmit = (values, actions) => {
        const { provisioningInterfaces, allInterfacesSelected, loadingProvisioningInterfaces } = this.state;

        if (loadingProvisioningInterfaces) { toastr.error("Please wait for provisioning interfaces to load then select and click validate.")}
        let interfaceList = provisioningInterfaces
            .filter(provInterface => allInterfacesSelected || provInterface.selected)
            .map(provInterface => provInterface.name);
        if (isEmpty(interfaceList)) {
            toastr.error("Please select at least one Network Element or All Network Elements and try again.");
        } else {
            this.props.validateServiceLineStatus(values.directoryNumber, interfaceList).then(response => {
                if (response && response.success) {
                    let updatedValues = values;
                    updatedValues['provisioningQueueGroupId'] = response.id;
                    this.handleNext({...updatedValues});
                }
            });
        }
    }

    handleNext = (values) => {
        const { next } = this.props;
        if (!next) {
            return;
        }

        next({
            values: values,
            formValues: values
        });
    }

    isSubmitting = () => {
        return this.props.orderCreationLoader === true;
    }

    toggleAllInterfaces = () => {
        this.setState((prevProps) => ({
            allInterfacesSelected: !prevProps.allInterfacesSelected
        }));
    }

    render() {
        const { stepFormValues } = this.props;
        const { provisioningInterfaces, allInterfacesSelected, loadingProvisioningInterfaces } = this.state;

        const {
            wizardData,
        } = this.props;

        return (
            <>
                <ServiceOrder
                    serviceInfo={wizardData.service}
                />

                <Formik
                    onSubmit={this.handleOnSubmit}
                    initialValues={isEmpty(stepFormValues)? {
                        serviceInfo: wizardData.service,
                        directoryNumber: wizardData.service.number
                    } : stepFormValues}
                    render={(formProps) => (
                        <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

                            <div className="wizard-footer d-flex justify-content-between">
                                {!loadingProvisioningInterfaces &&
                                    <>
                                    {isEmpty(provisioningInterfaces) && (
                                        <span className="form-text text-danger text-left">
                                            No provisioning interfaces found
                                        </span>
                                    ) ||
                                    (
                                        <>
                                            <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        name={`allInterfacesSelected`}
                                                        value={allInterfacesSelected}
                                                        checked={allInterfacesSelected === true}
                                                        onChange={this.toggleAllInterfaces}
                                                        disabled={this.isSubmitting()}
                                                    />
                                                    <span>All Network Elements</span>
                                                </label>
                                            </div>
                                        </>
                                    )}
                                    {!allInterfacesSelected && provisioningInterfaces.map((provInterface, index) => {
                                        return (
                                            <div className="form-check checkbox-slider checkbox-slider--b-flat" key={index}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        value={provInterface.selected}
                                                        checked={provInterface.selected}
                                                        disabled={this.isSubmitting()}
                                                        onChange={val => this.setState({
                                                            provisioningInterfaces: provisioningInterfaces.map(pi => {
                                                                if (pi.id === provInterface.id) {
                                                                    return {
                                                                        ...pi,
                                                                        selected: !provInterface.selected,
                                                                    }
                                                                }

                                                                return pi;
                                                            }),
                                                        })}
                                                    />
                                                    <span>{provInterface.description}</span>
                                                </label>
                                            </div>
                                        )
                                    })}
                                    </>
                                }






                                <button
                                    type="submit"
                                    className="btn btn-primary btn-next-tab ml-auto"
                                    onClick={formProps.handleSubmit}
                                    disabled={this.isSubmitting() || isEmpty(formProps.values.directoryNumber)}
                                >
                                    Validate
                                </button>
                            </div>

                        </Form>
                    )}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = {
    validateServiceLineStatus,
    getInterfacesByProvisioningPath,
    createOrderChangeDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Validate);