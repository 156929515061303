import React, { Component } from 'react';
import {connect} from 'react-redux';
import {
    getAccountDocuments,
    emptyAccountDocuments,
    createAccountDocument,
} from '../../../../../../actions/accountDetailsActions/documents.actions';
import {createLoadingSelector, getAccountDetails} from '../../../../../../selectors';
import Dropzone from 'react-dropzone';
import Document from "./Document";
import Loader from "../../../../../../components/Loader";
import isEmpty from "../../../../../../utils/helpers";
import {toastr} from "react-redux-toastr";
import * as Yup from "yup";
import {Formik} from "formik";

const DocumentSchema = Yup.object().shape({
    document: Yup.mixed().nullable().required('Required'),
    description: Yup.string(),
});

class Documents extends Component {

    state = {
        isCreating: false,
    }

    componentDidMount() {
        this.props.getAccountDocuments(this.props.accountId);
    }

    componentWillUnmount() {
        this.props.emptyAccountDocuments();
    }

    handleCreateDocument = (values, actions) => {
        const {accountId} = this.props;

        this.setState({isCreating: true});

        var formData = new FormData();
        formData.append("document", values.document);
        formData.append("description", values.description);

        this.props.createAccountDocument(accountId, formData).then((response) => {
            this.setState({isCreating: false});

            if (response.success === true) {
                this.props.getAccountDocuments(accountId);
                actions.resetForm();
                toastr.success(values.document.name+' uploaded successfully', { timeOut: 2000, position: 'top-center' });
            } else {
                let errorMessage = 'Something went wrong';
                if (response && response.error && response.error.message) errorMessage = response.error.message;
                toastr.error(errorMessage, { timeOut: 2000, position: 'top-center' });
            }
        });
    }

    render() {

        const {
            isCreating,
        } = this.state;

        const {
            accountId,
            documentsLoader,
            documents,
        } = this.props;

        return (
            <div className="documents-panel">

                <Formik
                    initialValues={{
                        document: '',
                        description: ''
                    }}
                    validationSchema={DocumentSchema}
                    onSubmit={this.handleCreateDocument}
                    render={({handleChange, handleSubmit, values, errors, touched, isSubmitting, setFieldValue}) => (
                        <form className="documents-panel-form" onSubmit={handleSubmit}>

                            <Dropzone
                                multiple={false}
                                disabled={isCreating}
                                // onDropAccepted={() => console.log("onDropAccepted")}
                                // onDropRejected={() => console.log("onDropRejected")}
                                onDrop={acceptedFiles => setFieldValue("document", acceptedFiles[0])}
                            >
                                {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject}) => (
                                    <div {...getRootProps()}
                                         className={"documents-panel-droparea" + (
                                             isDragActive && isDragAccept ? " documents-panel-droparea-dragging-valid" :
                                                 isDragActive && isDragReject ? " documents-panel-droparea-dragging-invalid" :
                                                     !isEmpty(values.document) ? " documents-panel-droparea-accepted" :
                                                         errors.document && touched.document ? " documents-panel-droparea-required" :
                                                            ""
                                         ) + (
                                             isCreating ? " documents-panel-droparea-disabled" : ""
                                         )}
                                    >
                                        <input {...getInputProps()} />
                                        <i className="fas fa-upload" />
                                        <p data-text={!isEmpty(values.document) ? values.document.name : "Drag and Drop Files or Click to Upload Files"} />
                                    </div>
                                )}
                            </Dropzone>

                            <div className="input-group">

                                <input
                                    className={"form-control" + (errors.description && touched.description ? ' is-invalid' : '')}
                                    type="text"
                                    id="description"
                                    name="description"
                                    placeholder="Enter Description"
                                    onChange={handleChange}
                                    value={values.description}
                                    disabled={isCreating}
                                />

                                <div className="input-group-append">
                                    <button
                                        className="btn btn-primary btn-show-cam-search-results"
                                        type="submit"
                                        disabled={isCreating}
                                    >
                                        {isCreating
                                            ? <i className="fas fa-fw fa-spin fa-spinner" />
                                            : <i className="fas fa-fw fa-save" />
                                        }
                                    </button>
                                </div>

                            </div>

                            {errors.description && touched.description &&
                            <div className="invalid-feedback">{errors.description}</div>
                            }

                        </form>
                    )}
                />

                <div className="cmv-form cmv-form-client-users">
                    <div className="form-subsection">

                        <div className="form-header">
                            <span className="form-header-title">
                                <h6>Documents</h6>
                            </span>
                        </div>

                        {documentsLoader
                        ?
                        <Loader/>
                        :
                        !isEmpty(documents)
                        ?
                        documents.map(document => (
                            <Document
                                accountId={accountId}
                                documentId={document.id}
                                documentName={document.name}
                                documentDate={document.createdDateTime}
                                documentDescription={document.description}
                                canDelete={this.props.canDelete === false ? false : true}
                            />
                        ))
                        :
                        <p>No documents found.</p>
                        }

                    </div>
                </div>

            </div>
        )
    }
}

const getLoaderDocuments = createLoadingSelector(['GET_ACCOUNT_DOCUMENTS']);

const mapStateToProps = (state) => {
    const
        documentsLoader = getLoaderDocuments(state),
        documents = getAccountDetails(state).accountDocuments.documents;

    return {
        documentsLoader,
        documents,
    };
};

const mapDispatchToProps = {
    getAccountDocuments,
    emptyAccountDocuments,
    createAccountDocument,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
