import React, {Component} from 'react';
import {connect} from "react-redux";
import {showModal} from "../../../../actions/modal.actions";
import Select from "react-select";
import {groupStyles} from "../../../../utils/SelectStyles";
import isEmpty from "../../../../utils/helpers";
import moment from "moment/moment";
import {getFormattedFilters} from "../Tasks/utils";
import {getFilteredTasks} from '../../../../actions/dashboardActions/tasks.actions'
import PersonalTasks from "./PersonalTasks";
import {createLoadingSelector, getTasksList} from "../../../../selectors";
import {getTaskAttributes} from "../../../../actions/taskDetails.actions";
import {getTroubleTickets} from "../../../../actions/accountDetailsActions/troubleTickets.actions";
import {getTasksByTroubleTicketId} from "../../../../actions/tasksList.actions";

export class Appointment extends Component {

    state = {
        tasksVisible: false,
        appointmentSelectedData: {},
        filters: {
            myTasks: '',
            myGroupTasks: '',
            unclaimedTask: '',
            createdBy: '',
            fromDate: '',
            toDate: '',
            limit: 20,
            taskId: '',
            searchId: '',
            complete: false
        },
        taskId: undefined,
        userTroubleTicketTasksData: []
    }


    renderTasks = async (appointment) => {
        //trouble ticket and orders have separate endpoints. fetches results based on appt type
        if(!this.props.appointmentSelected) {
            if(appointment.type === "troubleTicket") {
                await this.props.getTasksByTroubleTicketId(parseInt(appointment.id)).then((response) => {
                    this.setState({userTroubleTicketTasksData: response})
                })
            } else {
                this.state.filters.searchId = this.props.appointment.id;
                this.state.filters.myTasks = true
                let updatedFilter = {...this.state.filters}
                this.setState({filters: updatedFilter})
                await this.props.getFilteredTasks(getFormattedFilters(this.state.filters, "orderId"))
            }
        }
    }

    setTaskIdSelected = (taskId) => {
        this.setState({taskId: taskId})
    }


    render() {

        const {
            appointment,
            showDropdownById,
            accountAccessModal,
            handleAllTasksAssignee,
            usernameOptions,
            isAssigning,
            selectAppointment,
            appointmentSelected
        } = this.props;

        const {
            taskId,
            appointmentSelectedData
        } = this.state

        return (
            <>
                <div className="appointment"  style={appointmentSelected ? {border:'1px solid black'} : null}>
                    <div className="row">
                        <div className="col col-12 col-md-3 col-xl-2">

                            <div className="appointment-source">{appointment.source}</div>

                            <div className="appointment-info">
							<span className="font-weight-bold">
                                <i className="fas fa-fw fa-hashtag" /> {appointment.type === 'order' ? "O-" : "T-"}{appointment.id}
                            </span>
                                <span title={appointment.appointmentDetails.createdBy}>
                                <i className="fas fa-fw fa-user" /> {appointment.appointmentDetails.createdBy}
                            </span>
                                <span>
                                <i className="fas fa-fw fa-clock" /> {moment(appointment.created).fromNow()}
                            </span>
                            </div>

                        </div>
                        <div className="col col-12 col-md-5 col-xl-auto">

                            <div className="appointment-title">
                                {appointment.address.address && appointment.address.state &&
                                    <span className="appointment-title-address">
                                  <button className="appointment-address" style={{
                                      textDecoration: "underline", background: "transparent",
                                      cursor: "pointer", border: "none", padding: 0
                                  }}
                                          onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
                                              title: "Service Address Information",
                                              service: {
                                                  address1: appointment.address.address || '',
                                                  address2: appointment.address.address2 || '',
                                                  address3: appointment.address.address3 || '',
                                                  city: appointment.address.city || '',
                                                  latitude: appointment.address.latitude || '',
                                                  longitude: appointment.address.longitude || '',
                                                  mappingAddressId: appointment.address.mappingAddressId || '',
                                                  mappingAreaId: appointment.address.mappingAreaId || '',
                                                  mappingRefArea1: appointment.address.mappingRefArea1 || '',
                                                  mappingNote: appointment.address.mappingNote || '',
                                                  state: {
                                                      name: appointment.address.state || '',
                                                      id: appointment.address.stateId || '',
                                                  },
                                                  country: appointment.address.country || '',
                                                  zipcode: appointment.address.zipcode || '',
                                                  zip4: appointment.address.zip4 || '',
                                                  externalUrl: appointment.address.externalUrl || ''
                                              },
                                              canViewCSIGeocode: false,
                                              canEditCSIGeocode: false,
                                              isDisabled: true
                                          })}
                                          disabled={isAssigning}
                                  >
                                         <i className="fas fa-map"/>
                                      {appointment.address.address}, {appointment.address.city}, {appointment.address.state}, {appointment.address.zipcode}
                                  </button>
                                     <span className="appointment-title-date">
                                    <i className="fas fa-clock"/> {moment(appointment.appointmentDetails.date).format('MM/DD/YYYY')} {appointment.appointmentDetails.timeslot}
                                </span>
                            </span>
                                }


                                <span className="appointment-title-name">
                                <span className="font-weight-bold">{appointment.customerName}</span>
                                <br />
                                    {appointment.accountId
                                        ?
                                        <button
                                            onClick={() => accountAccessModal(appointment.accountId)}
                                            disabled={isAssigning}
                                        >
                                            Acct #: {appointment.accountNumber}
                                        </button>
                                        :
                                        <span>Acct #: {appointment.accountNumber}</span>
                                    }
                                    {' | '}
                                    <span>{appointment.accountType.name}</span>
                            </span>

                                {appointment.serviceOrders && appointment.serviceOrders.map(serviceOrder => (
                                    <p key={serviceOrder.id} className="appointment-title-service">
                                        {serviceOrder.icon && <><i className={serviceOrder.icon} />&nbsp;{serviceOrder.description}</>}
                                    </p>
                                ))}

                            </div>

                        </div>
                        <div className="col col-12 col-md-3 col-xl-2 col-xxl-3">

                            <div className="appointment-details">
                                {!isEmpty(appointment.appointmentDetails.areaDescription) &&
                                    <span className="appointment-details-area" title={appointment.appointmentDetails.areaDescription}>
                                <i className="fas fa-fw fa-paper-plane" /> {appointment.appointmentDetails.areaDescription}
                            </span>
                                }
                                {!isEmpty(appointment.taskIds) &&
                                    <span className="appointment-details-assignees">
                                <i className="fas fa-fw fa-user" />
                                        {appointment.taskIds && appointment.taskIds.length > 1
                                            ? "Assignees: "
                                            : "Assignee: "
                                        }
                                        {!isEmpty(appointment.taskAssignees)
                                            ? appointment.taskAssignees.map(taskAssignee => <span>{taskAssignee}</span>)
                                            : "unassigned"
                                        }
                            </span>
                                }
                            </div>

                        </div>
                        <div className="col col-12 col-md-auto">

                            <div className="appointment-controls">

                                <div className="dropdown">


                                    <button
                                        className="btn btn-circle"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded={showDropdownById === appointment.id}
                                        style={appointmentSelected ? {backgroundColor: '#1b8580'} : null}
                                        onClick={() => {
                                            selectAppointment(appointment.id)
                                            this.setState({appointmentSelectedData: appointment})
                                            this.renderTasks(appointment)
                                            if(appointmentSelected) this.setTaskIdSelected("")
                                        }}
                                        disabled={isAssigning}
                                    >
                                        {isAssigning === appointment.appointmentDetails.id
                                            ? <i className="fas fa-spinner fa-spin"/>
                                            : <i className="fas fa-2x fa-list-ul"/>
                                        }
                                    </button>



                                    {showDropdownById === appointment.appointmentDetails.id &&
                                        <div className="dropdown-menu dropdown-menu-with-select dropdown-menu-right show"
                                             aria-labelledby="dropdownMenuButton">
                                            <button
                                                onClick={() => handleAllTasksAssignee('claim', appointment.taskIds)}
                                                className="dropdown-item"
                                            >
                                                Claim
                                            </button>
                                            <button
                                                onClick={() => handleAllTasksAssignee('unassign', appointment.taskIds)}
                                                className="dropdown-item"
                                            >
                                                Unassign
                                            </button>
                                            <div className="dropdown-divider"/>
                                            <h6 className="dropdown-header">Assign To User</h6>
                                            <div className="dropdown-item">
                                                <Select
                                                    placeholder="Select"
                                                    styles={groupStyles}
                                                    options={usernameOptions}
                                                    onChange={(value) => handleAllTasksAssignee('username', appointment.taskIds, value)}
                                                />
                                            </div>
                                        </div>
                                    }

                                </div>

                            </div>

                        </div>
                    </div>
                    {appointmentSelected &&
                        <PersonalTasks appointmentSelectedData={this.state.appointmentSelectedData}
                                       orderId={this.state.orderId}
                                       taskId={taskId}
                                       setTaskIdSelected={this.setTaskIdSelected}
                                       getAppointmentsByFilters={this.props.getAppointmentsByFilters}
                                       usernameOptions={usernameOptions}
                                       userTroubleTicketTasksData={this.state.userTroubleTicketTasksData}
                                       filters={this.state.filters}
                        />
                    }
                </div>
            </>
        );
    }
}

const tasksListLoader = createLoadingSelector([ 'SET_TASKS_LIST' ]);

const mapStateToProps = (state) => {
    const tasksListData = getTasksList(state).tasksList

    return {
        tasksListData
    }

};

const mapDispatchToProps = {
    showModal,
    getFilteredTasks,
    getTroubleTickets,
    getTaskAttributes,
    getTasksByTroubleTicketId
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
