import { GET_PUBLIC_SYSTEM_CONFIG, SYSTEM_CONFIGURATIONS_ALL } from "../actions/systemConfig.actions";
import { AUTH_PROVIDER_AUTH0, AUTH_PROVIDER_CAMVIO } from "src/utils/const";

/**
 * These state is not changing regardless of authentication status of the user.
 * These will be the system-wide configuration values.
 */
let initialState = {
  publicSystemConfig: {
    authProviders: [AUTH_PROVIDER_CAMVIO],
  },
  isUsingAuth0: false,
  isUsingCamvioAuth: true,
  loadTs: null,
  allConfigurations: [],
};

export const systemConfigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PUBLIC_SYSTEM_CONFIG.SUCCESS:
      const isAuth0Configured = action.payload.auth0Domain && action.payload.auth0ClientId && action.payload.auth0OrgId;

      return {
        ...state,
        publicSystemConfig: action.payload,
        isUsingAuth0: isAuth0Configured && action.payload.authProviders.includes(AUTH_PROVIDER_AUTH0),
        isUsingCamvioAuth: action.payload.authProviders.includes(AUTH_PROVIDER_CAMVIO),
        isUsingAuth0Only:
          isAuth0Configured &&
          action.payload.authProviders.includes(AUTH_PROVIDER_AUTH0) &&
          action.payload.authProviders.length === 1,
        loadTs: new Date(),
      };

    case SYSTEM_CONFIGURATIONS_ALL.SUCCESS:
          return {
              ...state,
              allConfigurations: action.payload
          };
    case SYSTEM_CONFIGURATIONS_ALL.EMPTY:
    case SYSTEM_CONFIGURATIONS_ALL.CLEAR:
        return {
            ...state,
            allConfigurations: []
        };

    default:
      return state;
  }
};
