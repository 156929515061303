import React, { Component } from "react";
import NavPilsItem from "../../../../components/Common/NavPilsItem/NavPilsItem";

class NavTabs extends Component {
  render() {
    const { activeTab, changeTabHandler, handleExpandPanel } = this.props;

    return (
      <ul className="nav nav-pills nav-scrollable" id="clientProfileTab" role="tablist">
        <NavPilsItem pillName="account360" pillHeader="360" activeTab={activeTab} changeTabHandler={changeTabHandler} />
        <NavPilsItem
          pillName="serviceAndEquipment"
          pillHeader="Services &amp; Equipment"
          activeTab={activeTab}
          changeTabHandler={changeTabHandler}
        />
        <NavPilsItem
          pillName="billing"
          pillHeader="Billing"
          activeTab={activeTab}
          changeTabHandler={changeTabHandler}
        />
        <NavPilsItem
          pillName="ordersAndQuotes"
          pillHeader="Orders &amp; Quotes"
          activeTab={activeTab}
          changeTabHandler={changeTabHandler}
        />
        <NavPilsItem
          pillName="troubleTickets"
          pillHeader="Trouble Tickets"
          activeTab={activeTab}
          changeTabHandler={changeTabHandler}
        />
        <NavPilsItem
          pillName="salesOrders"
          pillHeader="Sales Orders"
          activeTab={activeTab}
          changeTabHandler={changeTabHandler}
        />
        <li className="ml-auto">
          <button
            onClick={() => handleExpandPanel("leftPanel")}
            className="nav-link btn btn-link btn-panel-right-toggler d-none d-xl-block"
            type="button"
          >
            <i className="fas fa-arrows-alt-h" />
          </button>
        </li>
      </ul>
    );
  }
}

export default NavTabs;
