import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';

import isEmpty from "../../../../utils/helpers";
import TextInput from "../../../../components/UI/TextInput";

import { hideModal } from '../../../../actions/modal.actions';
import { getAdmin, getModalData, getUser,  } from '../../../../selectors';
import { getSystemLocation, updateLocationProperties, clearSystemLocation } from '../../../../actions/admin.actions';
import { getUserSummary } from "../../../../actions/user.actions";
import LoadingModalContent from '../../../../components/Modal/LoadingModalContent';

class EditLocationPropertiesModal extends Component {
    state = {
        alertMessage: '',
        isLoading: true,
    };

    componentDidMount() {
        if (this.props.modalProps.value) {
            this.props.getLocation(this.props.modalProps.value)
                .then(() => {
                    this.setState({
                        isLoading: false,
                    })
                })
        } else {
            this.setState({
                isLoading: false,
            })
        }
    }

    componentWillUnmount() {
    }

    formSchema = () => {
        return Yup.object().shape({
            properties: Yup.array().of(
                Yup.object().shape({
                    id: Yup.number().nullable(),
                    key: Yup.string().required('Required'),
                    value: Yup.string().required('Required'),
                })
            )
        });
    }

    hideModal = () => {
        this.props.hideModal();
        this.props.clearLocation();
    }

    formSubmit = (values, actions) => {
        const { modalProps } = this.props;
        const locationId = modalProps.value;

        this.setState({ alertMessage: '' }, () => {
            const postData = {
                properties: Object.values(values.properties)
            };
            this.props.updateLocationProperties(locationId, postData).then((response) => {
                if (!response.data) {
                    actions.setSubmitting(false);
                    this.setState({ alertMessage: 'Unknown error. Please try again later' });
                    return;
                }

                if (!response.data.success) {
                    actions.setSubmitting(false);
                    this.setState({ alertMessage: response.data.error.message });
                    return;
                }

                this.hideModal();
                this.props.modalProps.reloadFunction();
            });
        });
    }

    render() {
        const { alertMessage } = this.state;
        const { location } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    {this.state.isLoading && (
                        <LoadingModalContent
                            modalTitle={'Location Properties'}
                            submitButtonLabel={'Save'}
                        />
                    )}
                    {!this.state.isLoading && (
                        <Formik
                            initialValues={{
                                properties: !location.properties || location.properties.length === 0 ? [{ id: '', key: '', value: '' }] : location.properties
                            }}
                            validationSchema={this.formSchema()}
                            onSubmit={this.formSubmit}
                            render={({
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">Location Properties</h5>
                                                <button onClick={this.hideModal} type="button" className="close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body cmv-form form-horizontal">
                                                <div className="order-service-order-wrapper">
                                                    <div><h4>{location.location.name}</h4>
                                                    <p className="billing-job-id">{location.location.description}<br/>#{location.location.id}</p></div>
                                                </div>

                                                {!isEmpty(alertMessage) && (
                                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                                        <p className="mb-0">{alertMessage}</p>
                                                    </div>
                                                )}

                                                <FieldArray
                                                    name="properties"                 
                                                    render={arrayHelpers => (
                                                    <div>
                                                        {values.properties.map((property, index) => (
                                                        <fieldset key={index}>
                                                            <legend className="as-label">Property { index + 1 }</legend>
                                                            <Field name={`properties.${index}.id`} type="hidden" />
                                                            <TextInput
                                                                label="Key"
                                                                name={`properties.${index}.key`}
                                                                value={property.key}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                errors={errors}
                                                                touched={touched}
                                                            />
                                                            <TextInput
                                                                label="Value"
                                                                name={`properties.${index}.value`}
                                                                value={property.value}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                errors={errors}
                                                                touched={touched}
                                                            />
                                                            <div className="form-group text-right">
                                                                <button type="button" className="btn btn-danger"
                                                                        onClick={() => arrayHelpers.remove(index)}>
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </fieldset>
                                                        ))}

                                                        <div className="form-group text-right">
                                                            <button type="button" className="btn btn-success"
                                                                onClick={() => arrayHelpers.push({ id: '', key: '', value: '' })} >
                                                                Add Another
                                                            </button>
                                                        </div>
                                                    </div>
                                                    )}
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button onClick={this.hideModal} className="btn" type="button">
                                                    Cancel
                                                </button>
                                                <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    )}
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const location = getAdmin(state).systemLocation,
        locationProperties = getAdmin(state).locationProperties,
        modalProps = getModalData(state).modalProps,
        currentUser = getUser(state).user;

    return {
        location,
        locationProperties,
        modalProps,
        currentUser
    };
};

const mapDispatchToProps = {
    hideModal,
    getLocation: getSystemLocation,
    clearLocation: clearSystemLocation,
    updateLocationProperties: updateLocationProperties,
    getUserSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLocationPropertiesModal);