import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { get } from 'lodash';
import { getModalData, getCreateServiceRequest, createLoadingSelector } from '../../../selectors';
import { hideModal } from '../../../actions/modal.actions';
import {
    getAccountTypes,
    getAccountTypeDetails,
    verifyAuthCode,
    sendAuthCode,
    createNewAccount,
    createNewOrder
} from '../../../actions/createServiceRequest.actions';
import { billingAddressValidation } from "../../../actions/createAccount.actions";
import isEmpty from "../../../utils/helpers";
import axiosInstance from "../../../utils/axios-instance";
import {toastr} from "react-redux-toastr";
import {generateFullZip} from "src/containers/CreateAccount/utils";
import AccountHolderLoader from "src/components/Loader/AccountHolderLoader";


const ProspectSchema = Yup.object().shape({
    accountHolder: Yup.object().shape({
        // accountType: Yup.number().typeError('Required').required('Required'),
        accountType: Yup.string().min(1, 'Required').required('Required'),
        firstName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Required'),
        lastName: Yup.string().min(1, 'Too Short!').max(50, 'Too Long!').required('Required'),
        personalEmail: Yup.string().email('Invalid email format').required('Required')
    }),
    accountTermsInfo: Yup.object().shape(
        {
            // accountTerm: Yup.string().required('Required')
        }
    ),
    // quoteInfo: Yup.object().shape({
    // 	quoteName: Yup.string().required('Required'),
    // 	quoteNote: Yup.string().required('Required')
    // })
});

class AccountModal extends Component {

    constructor(props, context) {
        super(props, context);

        let initialStatus = 'PROSPECT';

        if (props.modalProps.validatedAddress) {
            switch (props.modalProps.validatedAddress.allowedServiceOrderType) {
                case 'NEW_SURVEY':
                    initialStatus = 'SURVEY';
                    break;
                case 'NEW_SIGNUP':
                    initialStatus = 'SIGNUP';
                    break;
                case 'NEW_SERVICE':
                    initialStatus = 'ACTIVE';
                    break;
            }
        }

        this.state = {
            initialValues: {
                accountHolder: {
                    accountType: '',
                    firstName: '',
                    lastName: '',
                    mobileNumber: {
                        number: '',
                        verified: false
                    },
                    personalEmail: '',
                    company: ''
                },
                accountTermsInfo: {
                    accountTerm: '',
                    accountTier: '',
                    billCycle: ''
                },
                initialStatus: initialStatus,
                locationId: this.props.currentLocation.id,
                address: this.props.modalProps.validatedAddress.formattedAddress
            },
            authCode: '',
            showVerificationMobileNumber: false,
            showSuggestedAddress: true,
            useSuggestedAddress: false,
            isAuthCodeValid: null,
        };
    }

    componentDidMount() {
        this.props.getAccountTypes();
        this.handleValidateBillingAddress();
    }

    componentDidUpdate(prevProps) {
    }

    closeAddressSuggestion = () => {
        this.setState({
            addressValidationLoader: false,
            showSuggestedAddress: false,
        });
    };

    accountTypeHandler = (accountTypeId, setFieldValue) => {
        if (accountTypeId === '') {
            return null;
        }

        setFieldValue('accountHolder.accountType', accountTypeId);
        this.props.getAccountTypeDetails(accountTypeId).then((response) => {
            if (response) {

                let billCycles = response.allowedBillCycles.split(',');
                let defaultBillCycle = Number(
                    billCycles
                        .find((x) => {
                            return x.includes('*');
                        })
                        .replace('*', '')
                );
                setFieldValue('accountTermsInfo.billCycle', defaultBillCycle);
            }
        });
    };

    useSuggestedAddress = () => {
        this.setState({showSuggestedAddress: false, useSuggestedAddress: true});
    };

    handleValidateBillingAddress= () => {
        const { validatedAddress } = this.props.modalProps;
        const address = {
            address1: validatedAddress.address1,
            address2: validatedAddress.address2,
            address3: validatedAddress.address3,
            city: validatedAddress.city,
            state: validatedAddress.state.abbreviation,
            zip: validatedAddress.zip,
        }
        this.setState({addressValidationLoader: true});
        this.props.billingAddressValidation(address).then((response) => {
            if (response.data.status === 'OK') {
                this.setState({
                    addressValidationLoader: false,
                    suggestedAddress: response.data,
                    showSuggestedAddress: true
                });
            } else if (response.data.status === 'ERROR') {
                this.setState({
                    addressValidationLoader: false,
                    suggestedAddressError: response.data.errorDescription,
                    showSuggestedAddress: true
                });
            }
        });
    }

    resetAuthCodeState = (setFieldValue) => {
        this.setState({
            showVerificationMobileNumber: false,
            isAuthCodeValid: null,
            authCode: ''
        });
        setFieldValue('accountHolder.mobileNumber.verified', false);
    };

    onSubmitAuthCode = (number) => {
        let data = {
            phoneNumber: number,
            requester: 'alex'
        };
        // if there is no error - show field for verification
        this.props.sendAuthCode(data).then((response) => {
            // To Do - need to catch exeption - error
            this.setState({
                showVerificationMobileNumber: true,
                isAuthCodeValid: null,
                authCode: ''
            });
        });
    };

    onChangeAuthCodeHandler = (e) => {
        this.setState({
            authCode: e.target.value
        });
    };

    onSubmitVerifyAuthCode = (number, setFieldValue) => {
        let data = {
            authcode: this.state.authCode,
            phoneNumber: number,
            requester: 'alex'
        };
        this.props.verifyAuthCode(data).then((response) => {
            if (response.data.code === 200) {
                this.setState({ isAuthCodeValid: true });
                setFieldValue('accountHolder.mobileNumber.verified', true);
            }
            if (response.data.error) {
                this.setState({ isAuthCodeValid: false });
                setFieldValue('accountHolder.mobileNumber.verified', false);
            }
        });
    };

    formSubmit = (values, actions) => {
        let data = Object.keys(values).reduce((object, key) => {
            if (key !== 'validatedAddressWrapper' || key !== 'validatedAddress') {
                object[key] = values[key];
            }
            return object;
        }, {});
        let state = this.props.modalProps.validatedAddressWrapper.state;
        console.log(state);
        if (this.state.initialValues.initialStatus === 'PROSPECT') {
            data.accountHolder.serviceAddress = this.props.modalProps.validatedAddress.addressSearchFormated;
        }
        data["billingDetails"] = {};
        if(this.state.useSuggestedAddress){
            data.billingDetails["billingAddress"] = this.state.suggestedAddress;
            data.billingDetails.billingAddress["verified"] = true;
        }else{
            data.billingDetails["billingAddress"] = this.props.modalProps.validatedAddress;
            data.billingDetails.billingAddress.state = this.props.modalProps.validatedAddress.state.abbreviation
            data.billingDetails.billingAddress["verified"] = false;
            data.billingDetails.billingAddress["zip"] = this.props.modalProps.validatedAddress.zipcode;
            data.billingDetails.billingAddress["zip4"] = this.props.modalProps.validatedAddress.zip4;
        }

        data.accountHolder.serviceAddress = this.props.modalProps.validatedAddress.addressSearchFormated;

        this.props.createNewAccount(data).then((response) => {

            if (isEmpty(this.props.modalProps.validatedAddress.allowedServiceOrderType)) {
                actions.setSubmitting(false);
                return;
            }

            if (response.status === 201 || response.status === 200) {
                let quotesData = { ...this.props.modalProps };
                quotesData['accountId'] = response.data.id;
                quotesData['address'] = this.props.modalProps.validatedAddress.addressSearchFormated;
                quotesData['validatedAddress'] = true;
                quotesData['type'] = this.props.modalProps.validatedAddress.allowedServiceOrderType;
                quotesData.validatedAddressWrapper.state = state;
                this.props.createNewOrder(quotesData);
            } else {
                actions.setSubmitting(false);

                let errorMessage = 'Something went wrong';
                if (response && response.response && response.response.data && response.response.data.error && response.response.data.error.message) {
                    errorMessage = response.response.data.error.message;
                }
                toastr.error(errorMessage, { timeOut: 2000, position: 'top-center' });
            }
        });
    };

    getTitleText = () => {
        let titleText = 'Save Prospect';

        if (this.props.modalProps.validatedAddress) {
            switch (this.props.modalProps.validatedAddress.allowedServiceOrderType) {
                case 'NEW_SURVEY':
                    titleText = 'Save Survey';
                    break;
                case 'NEW_SIGNUP':
                    titleText = 'Save Signup';
                    break;
                case 'NEW_SERVICE':
                    titleText = 'Save Service';
                    break;
            }
        }

        return <h5 className="modal-title"> {titleText}</h5>;
    }

    checkEmail = (status, setStatus, field, value) => {
        var resetStatus = !isEmpty(status)? status: {};

        delete resetStatus[field];

        setStatus(resetStatus);

        axiosInstance.get('/verification/email/valid', {params: {email: value}}).catch((error) => {
            const newStatus = {
                [field]: error.response.data.error.message,
                ...resetStatus
            };
            setStatus(newStatus);
        });

    }

    render() {
        const { initialValues, authCode, isAuthCodeValid, showVerificationMobileNumber, addressValidationLoader, suggestedAddress, showSuggestedAddress, suggestedAddressError } = this.state;
        const { modalProps, accountTypesData, accountTermsData, authCodeLoader, authCodeVerifyLoader, hideModal } = this.props;

        return (
            <Fragment>
                <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={ProspectSchema}
                        onSubmit={this.formSubmit}
                        render={({
                            handleChange,
                            handleSubmit,
                            handleBlur,
                            values,
                            errors,
                            touched,
                            isSubmitting,
                                     setFieldValue,
                                     status,
                                     setStatus
                        }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-dialog modal-lg" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                {this.getTitleText()}
                                                <button type="button" className="close" onClick={hideModal}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body cmv-form">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label>Account Type</label>
                                                        <select
                                                            name="accountHolder.accountType"
                                                            className={
                                                                get(errors, `accountHolder.accountType`) &&
                                                                    get(touched, `accountHolder.accountType`) ? (
                                                                        'form-control is-invalid'
                                                                    ) : (
                                                                        'form-control'
                                                                    )
                                                            }
                                                            placeholder="Account Type"
                                                            value={values.accountHolder.accountType}
                                                            onChange={(e) =>
                                                                this.accountTypeHandler(e.target.value, setFieldValue)}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value="" disabled>
                                                                Select
                                                        </option>
                                                            {accountTypesData.map((type) => (
                                                                <option value={type.id} key={type.id}>
                                                                    {type.description}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {get(errors, `accountHolder.accountType`) &&
                                                            get(touched, `accountHolder.accountType`) && (
                                                                <div className="invalid-feedback">
                                                                    {get(errors, `accountHolder.accountType`)}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Account Term</label>
                                                        <select
                                                            name="accountTermsInfo.accountTerm"
                                                            className={
                                                                get(errors, `accountTermsInfo.accountTerm`) &&
                                                                    get(touched, `accountTermsInfo.accountTerm`) ? (
                                                                        'form-control is-invalid'
                                                                    ) : (
                                                                        'form-control'
                                                                    )
                                                            }
                                                            placeholder="Account Term"
                                                            value={values.accountTermsInfo.accountTerm}
                                                            // onChange={(e) =>
                                                            // 	this.accountTypeHandler(e.target.value, setFieldValue)}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            <option value="">Select</option>
                                                            {accountTermsData.map((type) => (
                                                                <option value={type.id} key={type.id}>
                                                                    {type.description}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {get(errors, `accountTermsInfo.accountTerm`) &&
                                                            get(touched, `accountTermsInfo.accountTerm`) && (
                                                                <div className="invalid-feedback">
                                                                    {get(errors, `accountTermsInfo.accountTerm`)}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                {this.state.initialValues.initialStatus === 'PROSPECT' && 
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="company">Company</label>
                                                            <input
                                                                className={
                                                                    get(errors, `accountHolder.company`) &&
                                                                        get(touched, `accountHolder.company`) ? (
                                                                            'form-control is-invalid'
                                                                        ) : (
                                                                            'form-control'
                                                                        )
                                                                }
                                                                name="accountHolder.company"
                                                                placeholder="Company (optional)"
                                                                type="text"
                                                                autoComplete="off"
                                                                value={values.accountHolder.company}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {get(errors, `accountHolder.company`) &&
                                                                get(touched, `accountHolder.company`) && (
                                                                    <div className="invalid-feedback">
                                                                        {get(errors, `accountHolder.company`)}
                                                                    </div>
                                                                )}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                        </div>
                                                    </div>
                                                }
                                                
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="firstName">First Name</label>
                                                        <input
                                                            className={
                                                                get(errors, `accountHolder.firstName`) &&
                                                                    get(touched, `accountHolder.firstName`) ? (
                                                                        'form-control is-invalid'
                                                                    ) : (
                                                                        'form-control'
                                                                    )
                                                            }
                                                            name="accountHolder.firstName"
                                                            placeholder="First Name"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={values.accountHolder.firstName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {get(errors, `accountHolder.firstName`) &&
                                                            get(touched, `accountHolder.firstName`) && (
                                                                <div className="invalid-feedback">
                                                                    {get(errors, `accountHolder.firstName`)}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="lastName">Last Name</label>
                                                        <input
                                                            className={
                                                                get(errors, `accountHolder.lastName`) &&
                                                                    get(touched, `accountHolder.lastName`) ? (
                                                                        'form-control is-invalid'
                                                                    ) : (
                                                                        'form-control'
                                                                    )
                                                            }
                                                            name="accountHolder.lastName"
                                                            placeholder="Last Name"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={values.accountHolder.lastName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {get(errors, `accountHolder.lastName`) &&
                                                            get(touched, `accountHolder.lastName`) && (
                                                                <div className="invalid-feedback">
                                                                    {get(errors, `accountHolder.lastName`)}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="personalEmail">Email address</label>
                                                        <input
                                                            className={
                                                                get(errors, `accountHolder.personalEmail`) &&
                                                                    get(touched, `accountHolder.personalEmail`) ? (
                                                                        'form-control is-invalid'
                                                                    ) : (
                                                                        'form-control'
                                                                    )
                                                            }
                                                            name="accountHolder.personalEmail"
                                                            placeholder="Email"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={values.accountHolder.personalEmail}
                                                            onChange={handleChange}
                                                        onBlur={(e) => {
                                                            handleBlur(e);
                                                            this.checkEmail(status, setStatus,'accountHolder.personalEmail', e.target.value);
                                                        }}
                                                        />
                                                        {get(errors, `accountHolder.personalEmail`) &&
                                                            get(touched, `accountHolder.personalEmail`) && (
                                                                <div className="invalid-feedback">
                                                                    {get(errors, `accountHolder.personalEmail`)}
                                                                </div>
                                                    )}
                                                    {get(status, `accountHolder.personalEmail`) && (
                                                        <div className="invalid-feedback">
                                                            {get(status, `accountHolder.personalEmail`)}
                                                        </div>
                                                            )}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="new-acc-phonemobile">Mobile Phone</label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                name="accountHolder.mobileNumber.number"
                                                                value={values.accountHolder.mobileNumber.number}
                                                                className={
                                                                    get(errors, `accountHolder.mobileNumber.number`) &&
                                                                        get(touched, `accountHolder.mobileNumber.number`) ? (
                                                                            'form-control is-invalid'
                                                                        ) : (
                                                                            'form-control'
                                                                        )
                                                                }
                                                                autoComplete="new-password"
                                                                placeholder="Mobile Phone  (optional)"
                                                                onChange={(values) => {
                                                                    handleChange(values);
                                                                    this.resetAuthCodeState(setFieldValue);
                                                                }}
                                                                onBlur={handleBlur}
                                                            />

                                                            <div
                                                                className={
                                                                    !showVerificationMobileNumber &&
                                                                        !authCodeLoader &&
                                                                        !authCodeVerifyLoader ? (
                                                                            'input-group-append input-authcode-send'
                                                                        ) : (
                                                                            'input-group-append input-authcode-send d-none'
                                                                        )
                                                                }
                                                            >
                                                                <button
                                                                    className="btn btn-outline-secondary btn-authcode-send"
                                                                    type="button"
                                                                    disabled={
                                                                        values.accountHolder.mobileNumber.number.length ===
                                                                        0
                                                                    }
                                                                    onClick={() =>
                                                                        this.onSubmitAuthCode(
                                                                            values.accountHolder.mobileNumber.number
                                                                        )}
                                                                >
                                                                    <i className="fas fa-paper-plane" /> Send
															</button>
                                                            </div>

                                                            {get(errors, `accountHolder.mobileNumber.number`) &&
                                                                get(touched, `accountHolder.mobileNumber.number`) && (
                                                                    <div className="invalid-feedback">
                                                                        {get(errors, `accountHolder.mobileNumber.number`)}
                                                                    </div>
                                                                )}


                                                        </div>


                                                        <div
                                                            className={
                                                                showVerificationMobileNumber &&
                                                                    !authCodeLoader &&
                                                                    !authCodeVerifyLoader &&
                                                                    isAuthCodeValid === null ? (
                                                                        'input-group-append input-authcode-validate justify-content-between mt-2'
                                                                    ) : (
                                                                        'input-group-append input-authcode-validate d-none'
                                                                    )
                                                            }
                                                        >

                                                            <div className='input-group-append input-authcode-input'>
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">
                                                                            <i className="fas fa-key" />
                                                                        </span>
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        name="authCode"
                                                                        value={authCode}
                                                                        onChange={this.onChangeAuthCodeHandler}
                                                                        className="form-control w-120px authcode-key-phone"
                                                                        placeholder="AuthCode"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <button
                                                                className="btn btn-outline-secondary btn-authcode-validate"
                                                                type="button"
                                                                onClick={() =>
                                                                    this.onSubmitVerifyAuthCode(
                                                                        values.accountHolder.mobileNumber.number,
                                                                        setFieldValue
                                                                    )}
                                                            >
                                                                <i className="fas fa-check" /> Verify
                                                        </button>

                                                            <button
                                                                className="btn btn-outline-secondary btn-authcode-send"
                                                                type="button"
                                                                disabled={
                                                                    values.accountHolder.mobileNumber.number.length ===
                                                                    0
                                                                }
                                                                onClick={() =>
                                                                    this.onSubmitAuthCode(
                                                                        values.accountHolder.mobileNumber.number
                                                                    )}
                                                            >
                                                                <i className="fas fa-paper-plane" /> Resend
                                                        </button>
                                                        </div>

                                                        <div className="input-group-append input-authcode-status mt-2">
                                                            {authCodeLoader && (
                                                                <span className="input-group-text">
                                                                    <i className="fas fa-circle-notch fa-spin ml-1 mr-1" />&nbsp;Requesting
																</span>
                                                            )}
                                                            {authCodeVerifyLoader && (
                                                                <span className="input-group-text">
                                                                    <i className="fas fa-circle-notch fa-spin ml-1 mr-1" />&nbsp;Verifying...
																</span>
                                                            )}
                                                            {isAuthCodeValid === true &&
                                                                !authCodeLoader && (
                                                                    <span className="input-group-text text-success">
                                                                        <i className="fas fa-check" />&nbsp;Valid
																</span>
                                                                )}

                                                            {isAuthCodeValid === false &&
                                                                !authCodeLoader && (
                                                                    <span className="input-group-text text-danger">
                                                                        <i className="fas fa-exclamation-triangle" />&nbsp;Invalid
																</span>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                                { showSuggestedAddress ?
                                                <div>
                                                {addressValidationLoader ? (
                                                        <AccountHolderLoader/>
                                                    ):(
                                                    <div className="form-row">
                                                        {suggestedAddress != undefined ? (
                                                    <div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
                                                    <h5>
                                                        <i className="fas fa-database"/> Update Billing Address
                                                    </h5>

                                                    <p>The following address was found in our database.</p>
                                                    <h6>
                                                        Validated Address
                                                    </h6>
                                                    <address>
                                                        {suggestedAddress.address1}{" "}  {suggestedAddress.address2}
                                                        <br/>
                                                        {suggestedAddress.city}, {suggestedAddress.state}{' '}
                                                        {generateFullZip(suggestedAddress)}
                                                    </address>
                                                    <h6>
                                                        Entered Address
                                                    </h6>
                                                    <address>
                                                        {this.props.modalProps.validatedAddress.address1}{" "}  {this.props.modalProps.validatedAddress.address2}
                                                        <br/>
                                                        {this.props.modalProps.validatedAddress.city}, {this.props.modalProps.validatedAddress.state.abbreviation}
                                                        {" "} {this.props.modalProps.validatedAddress.zipcode} {this.props.modalProps.validatedAddress.zip4 ? this.props.modalProps.validatedAddress.zip4 : ''}
                                                    </address>
                                                    <div className="d-flex justify-content-end mt-1">
                                                        <button
                                                            onClick={this.closeAddressSuggestion}
                                                            className="btn btn-outline-primary mr-1 btn-address-keep"
                                                            type="button"
                                                        >
                                                            Keep Entered
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={this.useSuggestedAddress.bind(this, setFieldValue)}
                                                            className="btn btn-primary btn-address-use-suggested"
                                                        >
                                                            Use Validated
                                                        </button>
                                                    </div>
                                                </div>
                                                        ):(
                                                            <div className="cmv-container-subpanel cmv-container-subpanel-suggested-address">
                                                                <h5>
                                                                    <i className="fas fa-database"/> Update Billing Address
                                                                </h5>

                                                                <p>We could not find this address in our database.</p>
                                                                <h6>
                                                                    Validated Address
                                                                </h6>
                                                                <address>
                                                                    Reason: {suggestedAddressError}
                                                                </address>
                                                                <h6>
                                                                    Entered Address
                                                                </h6>
                                                                <address>
                                                                    {this.props.modalProps.validatedAddress.address1}{" "}  {this.props.modalProps.validatedAddress.address2}
                                                                    <br/>
                                                                    {this.props.modalProps.validatedAddress.city}, {this.props.modalProps.validatedAddress.state.abbreviation}
                                                                    {" "} {this.props.modalProps.validatedAddress.zipcode} {this.props.modalProps.validatedAddress.zip4 ? this.props.modalProps.validatedAddress.zip4 : ''}
                                                                </address>
                                                                <div className="d-flex justify-content-end mt-1">
                                                                    <button
                                                                        onClick={this.closeAddressSuggestion}
                                                                        className="btn btn-outline-primary mr-1 btn-address-keep"
                                                                        type="button"
                                                                    >
                                                                        Keep Entered
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                                    )}
                                                </div> : ''
                                                }

                                            </div>
                                            <div className="modal-footer">
                                                <button className="btn" onClick={hideModal}>
                                                    Cancel
											</button>
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    Save {isSubmitting && <i className="fas fa-spin fa-circle-notch"></i>}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                    />
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

AccountModal.propTypes = {
    modalProps: PropTypes.object.isRequired,
    accountTypesData: PropTypes.array.isRequired,
    accountTermsData: PropTypes.array.isRequired,
    newOrderLoader: PropTypes.bool,
    authCodeLoader: PropTypes.bool,
    authCodeVerifyLoader: PropTypes.bool,
    hideModal: PropTypes.func,
    createNewOrder: PropTypes.func,
    getAccountTypes: PropTypes.func,
    getAccountTypeDetails: PropTypes.func,
    verifyAuthCode: PropTypes.func,
    sendAuthCode: PropTypes.func,
    createNewAccount: PropTypes.func,
    billingAddressValidation: PropTypes.func
}


const setNewOrderLoader = createLoadingSelector(['CREATE_ORDER']);
const setAuthCodeLoader = createLoadingSelector(['SEND_AUTH_CODE']);
const setAuthCodeVerify = createLoadingSelector(['VERIFY_AUTH_CODE']);

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        newOrderLoader = setNewOrderLoader(state),
        accountTypesData = getCreateServiceRequest(state).accountTypes,
        accountTermsData = getCreateServiceRequest(state).accountTerms,
        billCyclesData = getCreateServiceRequest(state).billCycles,
        authCodeLoader = setAuthCodeLoader(state),
        authCodeVerifyLoader = setAuthCodeVerify(state);

    return {
        modalProps,
        newOrderLoader,
        accountTypesData,
        accountTermsData,
        authCodeLoader,
        authCodeVerifyLoader,
        billCyclesData
    };
};
const mapDispatchToProps = {
    hideModal,
    getAccountTypes,
    getAccountTypeDetails,
    verifyAuthCode,
    sendAuthCode,
    createNewAccount,
    createNewOrder,
    billingAddressValidation
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountModal);
