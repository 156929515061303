import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import { createLoadingSelector, getModalData, getBillingJobs, getErrorMessage, getCreateAccount } from '../../../selectors/index';
import { getAccountTypes } from '../../../actions/createAccount.actions';
import { getAccounts, emptyAccounts } from "../../../actions/account-search";
import { getAllowedBillCycles, createBillingJob, loadBillingJobs, getAffectedAccountsByBillingJob } from '../../../actions/billingJobs.actions';
import { Formik, Form } from "formik";
import Select from 'react-select';
import isEmpty from '../../../utils/helpers';
import { groupStyles } from '../../../utils/SelectStyles';
import FormSelect from "../../../components/UI/FormSelect";
import LoadingModalContent from "../../../components/Modal/LoadingModalContent";
import DatePicker from "react-datepicker";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import * as Yup from "yup";

class AddBillingJobModal extends Component {

    state = {
        affectedAccounts: null,
        selectedAccountsOptions: [],
        loading: true
    }

    componentDidMount() {
        Promise.all([
            this.props.getAccountTypes(),
            this.props.getAllowedBillCycles()
        ]).finally(() => {
            this.setState({ loading: false })
        });
    }

    componentWillUnmount() {
        this.props.emptyAccounts();
    }

    closeModal = () => {
        this.props.hideModal();
        this.props.modalProps.onCloseCallback();
    }

    loadAccountInfo = (values, setFieldValue) => (value, option) => {

        if (option && option.action === 'input-change') {

            setFieldValue('selectedAccountsInput', value);

            if (this['timeout']) {
                clearTimeout(this['timeout']);
            }

            this['timeout'] = setTimeout(() => {
                this.props.getAccounts('ACCOUNT NUMBER', value).then(response => {
                    if (response && response.length > 0) {
                        let updatedSelectedAccountsOptions = [];

                        response.map(user => {
                            updatedSelectedAccountsOptions.push({
                                label: user.number + ' - ' + (user.systemUser ? user.systemUser.firstName + ' ' + user.systemUser.lastName : user.name),
                                value: user.id
                            });
                        });

                        this.setState({ selectedAccountsOptions: updatedSelectedAccountsOptions });
                    }
                });
            }, 1000);
        }
    }

    addAccountNumber = (values, setFieldValue) => (options, action) => {

        if (action.action === 'select-option') {
            setFieldValue("selectedAccountsInput", '');
            setFieldValue(
                "selectedAccountsArray",
                [...values.selectedAccountsArray, { label: action.option.label, value: action.option.value }]
            );
        }

        if (action.action === 'remove-value') {
            setFieldValue(
                "selectedAccountsArray",
                [...values.selectedAccountsArray.filter(option => option.value !== action.removedValue.value)]
            );
        }
    }

    _getPostDataFromValues(values) {
        return {
            billCycle: values.billCycle,
            startBillCycleMonth: moment(values.startBillCycleMonth).format('YYYY-MM'),
            invoiceDate: moment(values.invoiceDate).format('YYYY-MM-DD'),
            scheduledDateTime: moment(values.scheduledDateTime).format('YYYY-MM-DD hh:mm:ss'),
            generateTestInvoices: !!values.generateTestInvoices,
            accountIds: values.allAccounts ? [] : (values.selectedAccounts ? (values.selectedAccountsArray || []).map(a => a.value) : []),
            accountTypeIds: values.allAccounts ? [] : (values.selectedTypesToggle ? values.selectedTypes : []),
            dataUsageStartDate: values.dataUsageStartDate ? moment(values.dataUsageStartDate).format('YYYY-MM-DD') : null,
            dataUsageEndDate: values.dataUsageEndDate ? moment(values.dataUsageEndDate).format('YYYY-MM-DD') : null,
            note: values.note
        }
    }

    formChange = (values) => {
        const postData = this._getPostDataFromValues(values);
        this.props.getAffectedAccountsByBillingJob(postData)
            .then(data => {
                if (data.success) {
                    this.setState({
                        affectedAccounts: data.billingJob.processedAccounts
                    })
                } else {
                    this.setState({
                        affectedAccounts: null
                    })
                }
            });
    }

    formSubmit = (values, actions) => {
        actions.setSubmitting(true);
        const postData = this._getPostDataFromValues(values);
        this.props.createBillingJob(postData)
            .then(data => {
                actions.setSubmitting(false);
                if (data && data.id) {
                    toastr.success(`Billing Job #${data.id} has been created.`, { timeOut: 2000, position: 'top-center' });
                    this.props.loadBillingJobs();
                    this.props.hideModal();
                }
            })
    }

    render() {

        const {
            selectedAccountsOptions,
        } = this.state;

        const {
            modalProps,
            accountLoading,
            allowedBillCycles,
            accountTypes,
            submissionError
        } = this.props;

        const billCycleOptions = allowedBillCycles.map(billCycle => {
            return {
                label: billCycle,
                value: billCycle,
            }
        });

        const accountTypeOptions = accountTypes.map(accountType => {
            return {
                label: accountType.name,
                value: accountType.id,
            }
        });

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    {this.state.loading ? (
                        <LoadingModalContent hideModal={hideModal} submitButtonLabel="Save Billing Job" />
                    ) : (
                        <Formik
                            initialValues={{
                                billCycle: allowedBillCycles.length == 1 ? allowedBillCycles[0] : null,
                                startBillCycleMonth: moment().add(1, 'M').day(1).toDate(), // initialize as next month
                                invoiceDate: moment().toDate(),
                                scheduledDateTime: moment().format('YYYY-MM-DD hh:mm:ss'), // hidden for now, defaults to now()
                                generateTestInvoices: true,
                                allAccounts: true,
                                selectedTypesToggle: false,
                                selectedTypes: [],
                                selectedAccounts: false,
                                selectedAccountsInput: '',
                                selectedAccountsArray: [],
                                dataUsageStartDate: null,
                                dataUsageEndDate: null,
                                confirmAction: '',
                                note: '',
                            }}
                            validationSchema={Yup.object().shape({
                                billCycle: Yup.string().typeError('Required').required('Required'),
                                startBillCycleMonth: Yup.string().typeError('Required').required('Required'),
                                invoiceDate: Yup.string().typeError('Required').required('Required'),
                                generateTestInvoices: Yup.boolean().typeError('Required').required('Required'),
                                // dataUsageStartDate: Yup.string().typeError('Required').required('Required'),
                                // dataUsageEndDate: Yup.string().typeError('Required').required('Required'),
                                confirmAction: Yup.string().typeError('Required').required('Required')
                                    .equals(['billing'], 'Type "billing" to continue'),
                            })}
                            onSubmit={this.formSubmit}
                            render={({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">

                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    New Billing Job
                                                </h5>
                                                <button onClick={this.props.hideModal} type="button" className="close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>

                                            <div className="modal-body form-horizontal">
                                                {!isEmpty(submissionError) && (
                                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                                        <p className="mb-0">{submissionError}</p>
                                                    </div>
                                                )}
                                                <div className="form-group">
                                                    <FormSelect
                                                        title="Bill Cycle"
                                                        fieldName="billCycle"
                                                        placeholder="Select one..."
                                                        options={billCycleOptions}
                                                        value={values.billCycle}
                                                        setFieldValue={setFieldValue}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                    <p className='text-muted'><small>The first day of the billing cycle</small></p>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="date">Start of Bill Cycle</label>
                                                    <div className="customDatePickerWidth">
                                                        <DatePicker
                                                            className={"form-control text-left" + (touched.startBillCycleMonth && errors.startBillCycleMonth ? " is-invalid" : "")}
                                                            fieldName="startBillCycleMonth"
                                                            dateFormat="yyyy-MM"
                                                            placeholderText={"Select Date"}
                                                            autoFocus={false}
                                                            showMonthYearPicker
                                                            shouldCloseOnSelect={true}
                                                            popperPlacement={'auto'}
                                                            selected={values.startBillCycleMonth}
                                                            onChange={(date) => setFieldValue('startBillCycleMonth', date)}
                                                        />
                                                    </div>
                                                    {touched.startBillCycleMonth && errors.startBillCycleMonth &&
                                                        <div className="invalid-feedback">{errors.startBillCycleMonth}</div>
                                                    }
                                                    <p className='text-muted'><small>Month when the billing cycle starts</small></p>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="date">Invoice Date</label>
                                                    <div className="customDatePickerWidth">
                                                        <DatePicker
                                                            className={"form-control text-left" + (touched.invoiceDate && errors.invoiceDate ? " is-invalid" : "")}
                                                            fieldName="invoiceDate"
                                                            placeholderText="MM/DD/YYYY"
                                                            autoFocus={false}
                                                            shouldCloseOnSelect={true}
                                                            popperPlacement={'auto'}
                                                            selected={values.invoiceDate}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => setFieldValue('invoiceDate', date)}
                                                        />
                                                    </div>
                                                    {touched.invoiceDate && errors.invoiceDate &&
                                                        <div className="invalid-feedback">{errors.invoiceDate}</div>
                                                    }
                                                    <p className='text-muted'><small>Invoice due date calculated based on this date</small></p>
                                                </div>

                                                <div className="form-group">
                                                    <span className="h-check">
                                                        <label>Generate Invoice Files&nbsp;</label>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="generateTestInvoices"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={() => setFieldValue('generateTestInvoices', !values.generateTestInvoices)}
                                                                    checked={values.generateTestInvoices}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                </div>

                                                <div className="form-group">
                                                    <span className="h-check">
                                                        <label>All Accounts&nbsp;</label>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="allAccounts"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={() => setFieldValue('allAccounts', !values.allAccounts)}
                                                                    checked={values.allAccounts}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                </div>

                                                {!values.allAccounts && <>

                                                    <div className="form-group">
                                                        <span className="h-check">
                                                            <label>Selected Types&nbsp;</label>
                                                            <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                                <label>
                                                                    <input
                                                                        name="selectedTypesToggle"
                                                                        type="checkbox"
                                                                        onBlur={handleBlur}
                                                                        onChange={() => setFieldValue('selectedTypesToggle', !values.selectedTypesToggle)}
                                                                        checked={values.selectedTypesToggle}
                                                                    />
                                                                    <span>&nbsp;</span>
                                                                </label>
                                                            </div>
                                                        </span>
                                                    </div>

                                                    {values.selectedTypesToggle &&
                                                        <div className="form-group">
                                                            <FormSelect
                                                                fieldName="selectedTypes"
                                                                setFieldValue={setFieldValue}
                                                                value={values.selectedTypes}
                                                                isMulti={true}
                                                                onBlur={handleBlur}
                                                                errors={errors}
                                                                touched={touched}
                                                                options={accountTypeOptions}
                                                            />
                                                        </div>
                                                    }

                                                    <div className="form-group">
                                                        <span className="h-check">
                                                            <label>Selected Accounts&nbsp;</label>
                                                            <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                                <label>
                                                                    <input
                                                                        name="selectedAccounts"
                                                                        type="checkbox"
                                                                        onBlur={handleBlur}
                                                                        onChange={() => setFieldValue('selectedAccounts', !values.selectedAccounts)}
                                                                        checked={values.selectedAccounts}
                                                                    />
                                                                    <span>&nbsp;</span>
                                                                </label>
                                                            </div>
                                                        </span>
                                                    </div>

                                                    {values.selectedAccounts &&
                                                        <div className="form-group">
                                                            <Select
                                                                id="selectedAccountsInput"
                                                                name="selectedAccountsInput"
                                                                placeholder="Type account number..."
                                                                options={selectedAccountsOptions}
                                                                inputValue={values.selectedAccountsInput}
                                                                value={values.selectedAccountsArray}
                                                                onInputChange={this.loadAccountInfo(values, setFieldValue)}
                                                                onChange={this.addAccountNumber(values, setFieldValue)}
                                                                isMulti
                                                                isClearable={false}
                                                                isLoading={accountLoading}
                                                                isDisabled={isSubmitting}
                                                                styles={groupStyles}
                                                            />
                                                        </div>
                                                    }

                                                </>}

                                                <div className="form-group">
                                                    <label htmlFor="date">Unbilled Usage Start Date (optional)</label>
                                                    <div className="customDatePickerWidth">
                                                        <DatePicker
                                                            className={"form-control text-left" + (touched.dataUsageStartDate && errors.dataUsageStartDate ? " is-invalid" : "")}
                                                            fieldName="dataUsageStartDate"
                                                            placeholderText={"Select Date"}
                                                            autoFocus={false}
                                                            shouldCloseOnSelect={true}
                                                            popperPlacement={'auto'}
                                                            selected={values.dataUsageStartDate}
                                                            maxDate={values.dataUsageEndDate}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => setFieldValue('dataUsageStartDate', date)}
                                                        />
                                                    </div>
                                                    {touched.dataUsageStartDate && errors.dataUsageStartDate &&
                                                        <div className="invalid-feedback">{errors.dataUsageStartDate}</div>
                                                    }
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="date">Unbilled Usage End Date (optional)</label>
                                                    <div className="customDatePickerWidth">
                                                        <DatePicker
                                                            className={"form-control text-left" + (touched.dataUsageEndDate && errors.dataUsageEndDate ? " is-invalid" : "")}
                                                            fieldName="dataUsageEndDate"
                                                            placeholderText={"Select Date"}
                                                            autoFocus={false}
                                                            shouldCloseOnSelect={true}
                                                            popperPlacement={'auto'}
                                                            selected={values.dataUsageEndDate}
                                                            minDate={values.dataUsageStartDate}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => setFieldValue('dataUsageEndDate', date)}
                                                        />
                                                    </div>
                                                    {touched.dataUsageEndDate && errors.dataUsageEndDate &&
                                                        <div className="invalid-feedback">{errors.dataUsageEndDate}</div>
                                                    }
                                                </div>

                                                <div className="form-group">
                                                    <label>Type "billing" to continue</label>
                                                    <input
                                                        name="confirmAction"
                                                        className={"form-control" + (touched.confirmAction && errors.confirmAction ? " is-invalid" : "")}
                                                        onChange={handleChange}
                                                        onFocus={() => this.formChange(values)}
                                                        onBlur={handleBlur}
                                                        value={values.confirmAction}
                                                        placeholder="billing"
                                                        autoComplete="off"
                                                    />
                                                    {touched.confirmAction && errors.confirmAction &&
                                                        <div className="invalid-feedback">{errors.confirmAction}</div>
                                                    }
                                                    {this.state.affectedAccounts !== null && <div className="text-muted"><small>Found {this.state.affectedAccounts} billable accounts.</small></div>}
                                                </div>

                                                <div className="form-group">
                                                    <label>Note (optional)</label>
                                                    <textarea
                                                        name="note"
                                                        className={"form-control" + (touched.note && errors.note ? " is-invalid" : "")}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.note}
                                                    />
                                                    {touched.note && errors.note &&
                                                        <div className="invalid-feedback">{errors.note}</div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="modal-footer">
                                                <button onClick={this.props.hideModal} className="btn" type="button">
                                                    Cancel
                                                </button>
                                                <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                    Save Billing Job
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        />
                    )}
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const accountLoader = createLoadingSelector(['ACCOUNT_SEARCH']);
const creatingBillingJobLoader = createLoadingSelector(['CREATE_BILLING_JOB']);
const creatingBillingJobError = getErrorMessage(['CREATE_BILLING_JOB'])

const mapStateToProps = (state) => {

    const modalProps = getModalData(state).modalProps;
    const accountLoading = accountLoader(state);
    const allowedBillCycles = getBillingJobs(state).allowedBillCycles;
    const submitting = creatingBillingJobLoader(state);
    const submissionError = creatingBillingJobError(state);
    const accountTypes = getCreateAccount(state).accountTypes;

    return {
        modalProps,
        accountLoading,
        allowedBillCycles,
        accountTypes,
        submitting,
        submissionError,
    };
};

const mapDispatchToProps = {
    hideModal,
    getAccounts,
    emptyAccounts,
    getAllowedBillCycles,
    createBillingJob,
    loadBillingJobs,
    getAffectedAccountsByBillingJob,
    getAccountTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBillingJobModal);
