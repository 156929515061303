import { createRequestTypes } from "../utils/helpers";
import {
  dispatchClearAction,
  httpGetAndDispatchAction,
  httpPostAndDispatchAction,
  httpDeleteAndDispatchAction,
  httpPostAndDownloadAction,
} from "./_utils";

export const LIST_NOTIFICATION_CATEGORIES = createRequestTypes("LIST_NOTIFICATION_CATEGORIES");

export const LIST_NOTIFICATIONS_SIMPLE = createRequestTypes("LIST_NOTIFICATIONS_SIMPLE");
export const LIST_NOTIFICATIONS = createRequestTypes("LIST_NOTIFICATIONS");
export const GET_NOTIFICATION = createRequestTypes("GET_NOTIFICATION");
export const ADD_NOTIFICATION = createRequestTypes("ADD_NOTIFICATION");
export const UPDATE_NOTIFICATION = createRequestTypes("UPDATE_NOTIFICATION");
export const DELETE_NOTIFICATION = createRequestTypes("DELETE_NOTIFICATION");
export const DOWNLOAD_NOTIFICATIONS = createRequestTypes("DOWNLOAD_NOTIFICATIONS");

export const LIST_NOTIFICATION_TEMPLATES = createRequestTypes("LIST_NOTIFICATION_TEMPLATES");
export const GET_NOTIFICATION_TEMPLATE = createRequestTypes("GET_NOTIFICATION_TEMPLATE");
export const ADD_NOTIFICATION_TEMPLATE = createRequestTypes("ADD_NOTIFICATION_TEMPLATE");
export const UPDATE_NOTIFICATION_TEMPLATE = createRequestTypes("UPDATE_NOTIFICATION_TEMPLATE");
export const DELETE_NOTIFICATION_TEMPLATE = createRequestTypes("DELETE_NOTIFICATION_TEMPLATE");

export const LIST_NOTIFICATION_QUEUE = createRequestTypes("LIST_NOTIFICATION_QUEUE");
export const DOWNLOAD_NOTIFICATION_QUEUE = createRequestTypes("DOWNLOAD_NOTIFICATION_QUEUE");
export const RESEND_NOTIFICATION = createRequestTypes("RESEND_NOTIFICATION");
export const FORWARD_NOTIFICATION = createRequestTypes("FORWARD_NOTIFICATION");

//
// Notification Categories
//
export const clearNotificationCategories = () => dispatchClearAction(LIST_NOTIFICATION_CATEGORIES);
export const getNotificationCategories = () =>
  httpGetAndDispatchAction(LIST_NOTIFICATION_CATEGORIES, "/admin/notification-categories", "notificationCategories");

//
// Notifications
//
export const clearNotifications = () => dispatchClearAction(LIST_NOTIFICATIONS);
export const getAllNotifications = (offset = 0, limit = 0) =>
  httpGetAndDispatchAction(LIST_NOTIFICATIONS_SIMPLE, "/admin/notifications", "notifications");
export const getNotifications = (tableRequest) =>
  httpPostAndDispatchAction(LIST_NOTIFICATIONS, "/admin/notifications", tableRequest, "notifications");

export const clearNotification = () => dispatchClearAction(GET_NOTIFICATION);
export const getNotification = (id) =>
  httpGetAndDispatchAction(GET_NOTIFICATION, `/admin/notification/${id}`, "notification");
export const addNotification = (data) => httpPostAndDispatchAction(ADD_NOTIFICATION, `/admin/notification`, data);
export const updateNotification = (id, data) =>
  httpPostAndDispatchAction(UPDATE_NOTIFICATION, `/admin/notification/${id}`, data);
export const deleteNotification = (id) => httpDeleteAndDispatchAction(DELETE_NOTIFICATION, `/admin/notification/${id}`);
export const downloadNotifications = (fileType, tableRequest) => {
  let today = new Date();
  return httpPostAndDownloadAction(
    DOWNLOAD_NOTIFICATIONS,
    fileType,
    `/admin/notifications/${fileType}/download`,
    tableRequest,
    `notifications_${today.toISOString().split("T")[0]}.${fileType}`
  );
};

//
// Notification Templates
//
export const addNotificationTemplate = (data) =>
  httpPostAndDispatchAction(ADD_NOTIFICATION_TEMPLATE, "/admin/notification-template", data);
export const updateNotificationTemplate = (templateId, data) =>
  httpPostAndDispatchAction(UPDATE_NOTIFICATION_TEMPLATE, `/admin/notification-template/${templateId}`, data);
export const deleteNotificationTemplate = (templateId) =>
  httpDeleteAndDispatchAction(DELETE_NOTIFICATION_TEMPLATE, `/admin/notification-template/${templateId}`);

//
// Notification Queue Management
//

export const clearNotificationQueue = () => dispatchClearAction(LIST_NOTIFICATION_QUEUE);
export const getNotificationQueue = (tableRequest) =>
  httpPostAndDispatchAction(LIST_NOTIFICATION_QUEUE, `/notifications/list`, tableRequest, "notificationQueues");
export const resendNotification = (id) =>
  httpPostAndDispatchAction(RESEND_NOTIFICATION, `/notification/${id}/resend`, {}, "notificationQueue");
export const forwardNotification = (id, data) =>
  httpPostAndDispatchAction(FORWARD_NOTIFICATION, `/notification/${id}/forward`, data);
export const downloadNotificationQueue = (fileType, tableRequest) => {
  let today = new Date();
  return httpPostAndDownloadAction(
    DOWNLOAD_NOTIFICATION_QUEUE,
    fileType,
    `/notifications/${fileType}/download`,
    tableRequest,
    `notifications_${today.toISOString().split("T")[0]}.${fileType}`
  );
};
export const downloadNotificationAttachment = (id, idx) => {
  return httpPostAndDownloadAction(
    DOWNLOAD_NOTIFICATION_QUEUE,
    "",
    `/notification/${id}/attachment/${idx}`,
    {},
    `attachment_${idx}`
  );
};
