import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDashboardMenuCount, emptyDashboardMenuCount } from '../../actions/dashboardActions/customers.actions';
import {getDashboardData, getUser, getAuthData, createLoadingSelector} from '../../selectors';
import Tiles from './Tiles';
import Content from "./Content";


class CapacityConfiguration extends Component {

    state = {
        activeTile: ''
    };

    componentDidMount() {
        // Change navbar type and color
        document.title = 'Capacity Configuration - camvio.cloud';
        this.props.changeNavBarType('default', 'Capacity Configuration');

        if(!sessionStorage.getItem('activeTab')) {
            this.setState({activeTile: 'orders'})
        } else {
            window.location.hash = sessionStorage.getItem('activeTab')
            this.setState({activeTile: sessionStorage.getItem('activeTab')})
            sessionStorage.removeItem(('activeTab'))
        }
    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleTileChange = (tile) => {
        this.setState({
            activeTile: tile
        });
        window.location.hash = tile;
    };

    render() {
        const { activeTile } = this.state;

        return (
            <div className="cmv-dashboard-modern">
                <div className="cmv-container cmv-container-dashboard cmv-container-dashboard-tiles">
                    <Tiles
                        activeTile={activeTile}
                        handleTileChange={this.handleTileChange}
                    />
                    <Content
                        activeTile={activeTile}
                        {...this.props}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    const user = getUser(state).user,
        username = getAuthData(state).username;

    const activeTab = state.user.activeTab
    return {
        user,
        username,
        activeTab
    };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CapacityConfiguration);
