import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withAuth0 } from "@auth0/auth0-react";

import { getAuthData, getSystemConfigs } from '../../selectors/index';
import { getUserSummary } from '../../actions/user.actions';
import { getUser } from '../../selectors';
import { showModal } from '../../actions/modal.actions';
import { hasAccessPermission } from './routeConfig';

class PrivateRoute extends Component {
	render() {
		const {
			component: Component,
			userSummary,
			userPermission,
			userConfiguration,
			isLoggedIn,
			currentLocation,
			layout: Layout,
			permissions,
			...rest
		} = this.props;

		return (
			<Route
				{...rest}
				render={(props) => {
					if (!isLoggedIn) {
						return (
							<Redirect
								to={{
									pathname: '/login',
									state: { from: props.location }
								}}
							/>
						);
					}

					if (!hasAccessPermission(userPermission, permissions)) {
						return <Redirect to={{ pathname: '/login', state: { permissionError: true } }} />;
					}

					return (
						<Layout
							userSummary={userSummary}
							history={props.history}
							currentLocation={currentLocation}
							userPermission={userPermission}
							userConfiguration={userConfiguration}
						>
							<Component {...props} />
						</Layout>
					);
				}}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userSummary: getUser(state).user,
		isLoggedIn: getAuthData(state).isLoggedIn,
		isUsingAuth0: getSystemConfigs(state).isUsingAuth0,
		currentLocation: getUser(state).currentLocation,
		configuration: getUser(state).configuration
	};
};

const mapDispatchToProps = {
	getUserSummary,
	showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(PrivateRoute));
