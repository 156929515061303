import { createRequestTypes } from "../utils/helpers";
import {
  httpGetAndDispatchAction,
  dispatchEmptyAction,
  httpPatchAndDispatchAction,
  httpDeleteAndDispatchAction,
  httpPostAndDispatchAction,
} from "./_utils";

export const GET_PUBLIC_SYSTEM_CONFIG = createRequestTypes("GET_PUBLIC_SYSTEM_CONFIG");
export const SYSTEM_CONFIGURATIONS_ALL = createRequestTypes("SYSTEM_CONFIGURATIONS_ALL");
export const SYSTEM_CONFIGURATIONS_PATCH = createRequestTypes("SYSTEM_CONFIGURATIONS_PATCH");
export const SYSTEM_CONFIGURATION_CREATE = createRequestTypes("SYSTEM_CONFIGURATION_CREATE");
export const SYSTEM_CONFIGURATION_UPDATE_BY_ID = createRequestTypes("SYSTEM_CONFIGURATION_UPDATE_BY_ID");
export const SYSTEM_CONFIGURATION_DELETE_BY_ID = createRequestTypes("SYSTEM_CONFIGURATION_DELETE_BY_ID");

//getting providers

/**
 * Get Device Profiles (or Equipment Families)
 * @returns Promise<Response>
 */
export const getPublicSystemConfig = () =>
  httpGetAndDispatchAction(GET_PUBLIC_SYSTEM_CONFIG, "/public/system_configurations", null);

export const getAllSystemConfigurations = (mappingProviderId) =>
  httpGetAndDispatchAction(SYSTEM_CONFIGURATIONS_ALL, `/admin/system-configurations`, "properties");

//clear providers

export const clearAllSystemConfigurations = () => dispatchEmptyAction(SYSTEM_CONFIGURATIONS_ALL);

// patching providers

export const patchSystemConfigurations = (patchData) =>
  httpPatchAndDispatchAction(SYSTEM_CONFIGURATIONS_PATCH, `/admin/system-configurations`, patchData);

export const createSystemConfiguration = (configuration) =>
  httpPostAndDispatchAction(SYSTEM_CONFIGURATION_CREATE, `/admin/system-configuration`, configuration);

export const updateSystemConfiguration = (id, configuration) =>
  httpPatchAndDispatchAction(SYSTEM_CONFIGURATION_UPDATE_BY_ID, `/admin/system-configuration/${id}`, configuration);

export const deleteSystemConfiguration = (id) =>
  httpDeleteAndDispatchAction(SYSTEM_CONFIGURATION_DELETE_BY_ID, `/admin/system-configuration/${id}`);
