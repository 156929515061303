import {
    TROUBLE_TICKET_REPORTED_CODE,
    TROUBLE_TICKET_RESOLUTION_CODE,
} from "../actions/troubleTicketCodes.action";

const initialState = {
    reportedCode: '',
    reportedCodes: '',
    resolutionCode: '',
    resolutionCodes: '',
};

export const troubleTicketCodesReducer = (state = initialState, action) => {
    switch (action.type) {
        case TROUBLE_TICKET_REPORTED_CODE.SUCCESS:
            return {
                ...state,
                reportedCode: action.payload
            };
        case TROUBLE_TICKET_REPORTED_CODE.CLEAR:
            return {
                ...state,
                reportedCode: undefined
            };
        case TROUBLE_TICKET_RESOLUTION_CODE.SUCCESS:
            return {
                ...state,
                resolutionCode: action.payload
            };
        case TROUBLE_TICKET_RESOLUTION_CODE.CLEAR:
            return {
                ...state,
                resolutionCode: undefined
            };
        default:
            return state;
    }
};
