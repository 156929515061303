import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {hideModal} from '../../../actions/modal.actions';
import FormSelect from "../../../components/UI/FormSelect";
import TextInput from "../../../components/UI/TextInput";
import {getAdmin, getDomain, getModalData, getProducts} from '../../../selectors';
import { getServiceModels, getInstallmentPlans } from "../../../actions/domain.actions";
import {getProviders, getSystemLocations, getGeneralLedgers, getGeneralLedgerSubAccounts} from "../../../actions/admin.actions";
import {getAdjustment, updateAdjustment, getRateCodes, addAdjustment} from "../../../actions/products.action";
import {FieldArray, Formik} from 'formik';
import isEmpty from "../../../utils/helpers";
import Loader from "../../../components/Loader";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import DatePicker from "react-datepicker";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
};

const installmentPlansAsOptions = (installmentPlans) => asOptions(installmentPlans, installmentPlan => {
    return {
        label: installmentPlan.description,
        value: installmentPlan.id,
    }
});

const systemLocationAsOptions = (systemLocations) => asOptions(systemLocations, systemLocation => {
    return {
        label: systemLocation.description,
        value: systemLocation.id,
    }
});


const generalLedgersAsOptions = (generalLedgers) => asOptions(generalLedgers, generalLedger => {
    return {
        label: generalLedger.accountNumber,
        value: generalLedger.id,
    }
});

const generalLedgersSubAccountsAsOptions = (generalLedgerSubAccounts) => asOptions(generalLedgerSubAccounts, generalLedgerSubAccount => {
    return {
        label: generalLedgerSubAccount.subAccountNumber,
        value: generalLedgerSubAccount.id,
    }
});

const matchCurrentSelectedGeneralLedger = (generalLedgerSubAccounts, selectedId) => {
    if ((generalLedgerSubAccounts && generalLedgerSubAccounts != undefined)) {
        return generalLedgerSubAccounts.filter(generalLedgerSubAccount => generalLedgerSubAccount.generalLedgerId == selectedId);
    }
    return generalLedgerSubAccounts;
}


const adjustmentTypeOptions = (types) => asOptions(types, type => {
    return {
        label: type,
        value: type.toUpperCase(),
    }
});

const removeSelectedLocations = (locations, ignoreIndex, selectedGeneralLedgers) => {
    let currentLocations = [];
    if(locations && locations != undefined && !isEmpty(selectedGeneralLedgers)) {
        selectedGeneralLedgers.map((selectedLocation, index) => {
            if(ignoreIndex != index) {
                currentLocations.push(selectedLocation.glLocationId);
            }
        });
        return locations.filter(location => !currentLocations.includes(location.id));
    }
    return locations;
}

class EditAdjustmentModal extends Component {

    state = {
        alertMessage: '',
        isAdjustmentLoading: true,
        rateCodeRequired: false,
        generalLedgerDescription: '',
        generalLedgerSelected: false,
        selectedGeneralLedgers: [{
            glLocationId: '',
            glSubId: '',
            glId: '',
            glDescription: ''
        }],
        selectedLocations: [],
        generalLedgersToDelete: [],
        installmentPlansToDelete: []
    };


    componentDidMount() {
        this.props.getInstallmentPlans();
        this.props.getGeneralLedgers()
        this.props.getGeneralLedgerSubAccounts();
        this.props.getSystemLocations();
        const {modalProps} = this.props;
        let selectedGeneralLedgers = [{
            glLocationId: '',
            glSubId: '',
            glId: '',
            glDescription: ''
        }];
        if(modalProps.value) {
            this.props.getAdjustment(modalProps.value).then((response) => {
                let rateCodeRequired = false;
                if(response.selectedGeneralLedgers){
                    selectedGeneralLedgers = response.selectedGeneralLedgers;
                }
                this.setState({
                    isAdjustmentLoading: false,
                    rateCodeRequired: rateCodeRequired,
                    selectedGeneralLedgers: selectedGeneralLedgers
                })
            })
        }else{
            this.setState({
                isAdjustmentLoading: false,
                selectedGeneralLedgers: selectedGeneralLedgers
            })
        }

    }

    componentWillUnmount() {

    }

    addAnotherGeneralLedger = (selectedGeneralLedgers) => {
        selectedGeneralLedgers.push({
            glLocationId: '',
            glSubId: '',
            glId: '',
            glDescription: ''
        });
        this.setState({
            selectedGeneralLedgers: selectedGeneralLedgers
        });
    }

    setGeneralLedgerValue = (e, value, setFieldValue, index) => {
        setFieldValue(`selectedGeneralLedgers.${index}.glId`, value);
        if(this.props.generalLedgers != undefined){
            this.setGeneralLedgerDescription(value, setFieldValue, index);
            setFieldValue(`selectedGeneralLedgers.${index}.glSubId`, "");
        }
        this.setState({
            generalLedgerSelected: true
        })
    }

    setGeneralLedgerLocationValue = (e, value, setFieldValue, index) => {
        setFieldValue(`selectedGeneralLedgers.${index}.glLocationId`, value);
        this.setState({
            generalLedgerSelected: true
        })
    }

    setGeneralLedgerDescription = (value, setFieldValue, index) => {
        this.props.generalLedgers.map(ledger => {
            if(ledger.id == value){
                setFieldValue(`selectedGeneralLedgers.${index}.glDescription`, ledger.description);
            }
        })
    }

    setInstallmentPlans = (installmentPlans) => {
        let installmentPlanIds = [];
        if(!isEmpty(installmentPlans)){
            installmentPlans.map(installmentPlan => {
                installmentPlanIds.push(installmentPlan.id)
            })
        }
        return installmentPlanIds;
    }

    checkAdjustmentType = (type) => {
        if(type && type != 'CREDIT'){
            return true;
        }
        return false;
    }

    handleRemoveGeneralLedger = (formProps, remove, index, setFieldValue) => {
        let generalLedgersToDelete = this.state.generalLedgersToDelete;
        if(formProps.values.selectedGeneralLedgers != undefined){
            let values = {...formProps.values};
            if(values.selectedGeneralLedgers[index].glSubId != undefined) {
                generalLedgersToDelete.push(values.selectedGeneralLedgers[index].id);
                this.setState({
                    generalLedgersToDelete: generalLedgersToDelete
                })
            }
            values.selectedGeneralLedgers.splice(index, 1);
            if(values.selectedGeneralLedgers.length == 0){
                values.selectedGeneralLedgers =  [{
                    glLocationId: '',
                    glSubId: '',
                    glId: '',
                    glDescription: ''
                }];
                this.setState({
                    generalLedgerSelected: false
                })
            }
            setFieldValue('selectedGeneralLedgers', values.selectedGeneralLedgers);
        }
    }

    buildGeneralLedgerTitle(selectedGeneralLedger) {
        let glString = "";
        if(selectedGeneralLedger.glLocationId){
            if(this.props.systemLocations != undefined){
                this.props.systemLocations.map(location => {
                    if(location.id == selectedGeneralLedger.glLocationId){
                        glString += location.name;
                    }
                })
            }
        }
        if(selectedGeneralLedger.glId){
            if(this.props.generalLedgers != undefined){
                this.props.generalLedgers.map(generalLedger => {
                    if(generalLedger.id == selectedGeneralLedger.glId){
                        glString += (":" + generalLedger.accountNumber);
                    }
                })
            }
        }
        if(selectedGeneralLedger.glSubId){
            if(this.props.generalLedgerSubAccounts != undefined){
                this.props.generalLedgerSubAccounts.map(generalLedgerSubAccount => {
                    if(generalLedgerSubAccount.id == selectedGeneralLedger.glSubId){
                        glString += ( "." + generalLedgerSubAccount.subAccountNumber) ;
                    }
                })
            }
        }
        return glString;
    }


    adjustmentSchema = () => {
        let validationSchema = {
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            price: Yup.string().required('Required'),
            type: Yup.string().required('Required').typeError('Required'),
            selectedGeneralLedgers: this.state.generalLedgerSelected ? Yup.array(Yup.object().shape({
                glLocationId: Yup.number().required('Required').typeError('Required'),
                glId: Yup.number().required('Required').typeError('Required'),
                glSubId: Yup.number().required('Required').typeError('Required')
            })).min(1): '',
        };

        return Yup.object().shape(validationSchema);
    }



    formSubmit = (values, actions) => {
        if(!isEmpty(values.installmentPlans)){
            let installmentPlanValues = []
            values.installmentPlans.map(id => {
                installmentPlanValues.push(this.props.installmentPlanItems.filter(installmentPlanItem => installmentPlanItem.id == id)[0]);
            })
            values["installmentPlans"] = installmentPlanValues;
        }
        values["name"] = values["name"].toUpperCase();
        const submitData = {...values};
        this.setState({alertMessage: ''}, () => {
            const method = this.props.modalProps.type === 'create' ? this.props.addAdjustment : this.props.updateAdjustment;

            method(submitData).then((response) => {
                if (!response) {
                    return;
                }
                //If any failures happen on edit or create, show the error, only refresh if edit
                if (!response.success) {
                    toastr.error(response.data.error.message, {timeOut: 8000, position: 'top-center'});
                    if (this.props.modalProps.type != 'create') {
                        this.setState({
                            isAdjustmentLoading: true
                        })
                    this.getAdjustment(this.props.modalProps.value);
                    }
                    return response.data.error;
                } else {
                    this.props.hideModal();
                    this.props.modalProps.reloadFunction(true);
                    toastr.success(response.message, {timeOut: 4000, position: 'top-center'});
                }
            });
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    setActive = (status) => {
        console.log(status);
    }


    render() {
        const { alertMessage, isAdjustmentLoading } = this.state;
        const { modalProps, adjustment, generalLedgers, systemLocations, generalLedgerSubAccounts, installmentPlanItems} = this.props;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {isAdjustmentLoading &&
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {modalProps.type === 'create' ? 'Add Adjustment' : 'Edit Adjustment'}
                                </h5>
                                <button onClick={this.props.hideModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Loader />
                            <div className="modal-footer">
                                <button onClick={this.props.hideModal} className="btn" type="button">
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-primary">
                                    {modalProps.type === 'create' ? 'Add Adjustment' : 'Edit Adjustment'}
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                    {!isAdjustmentLoading &&
                    <Formik
                        initialValues={{
                            id: modalProps && modalProps.value,
                            active: modalProps.type === 'create' ? true : adjustment && adjustment.active,
                            name: modalProps.type === 'create' ? '' : adjustment && adjustment.name || '',
                            description: modalProps.type === 'create' ? '' : adjustment && adjustment.description || '',
                            type: modalProps.type === 'create' ? '' : adjustment && adjustment.type || '',
                            price: modalProps.type === 'create' ? '' : adjustment && adjustment.listPrice || '0.00',
                            showInstallmentPlan: modalProps.type === 'create' ? '' : adjustment && adjustment.installmentPlans ? true : false,
                            installmentPlans: modalProps.type === 'create' ? [] : adjustment && this.setInstallmentPlans(adjustment.installmentPlans) || [],
                            startDate: modalProps.type === 'create' ? '' : adjustment && adjustment.startDate || null,
                            endDate: modalProps.type === 'create' ? '' : adjustment && adjustment.endDate || null,
                            selectedGeneralLedgers: this.state.selectedGeneralLedgers,
                            generalLedgersToDelete: this.state.generalLedgersToDelete,
                            installmentPlansToDelete: this.state.installmentPlansToDelete,
                        }}
                        validationSchema={this.adjustmentSchema()}
                        onSubmit={this.formSubmit}
                        render={(props) => {
                            const {
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue
                            } = props;
                            return(
                                <form onSubmit={handleSubmit}>
                                    <div className="modal-dialog modal-lg">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    {modalProps.type === 'create' ? 'Add Adjustment' : 'Edit Adjustment'}
                                                </h5>
                                                <button onClick={this.props.hideModal} type="button" className="close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body form-horizontal">
                                                {!isEmpty(alertMessage) && (
                                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                                        <p className="mb-0">{alertMessage}</p>
                                                    </div>
                                                )}
                                                <div className="form-group">
												<span className="h-check">
													<label>Active&nbsp;</label>
													<div className="form-check checkbox-slider checkbox-slider--b-flat">
														<label>
															<input
                                                                name="active"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                checked={values.active}
                                                            />
															<span>&nbsp;</span>
														</label>
													</div>
												</span>
                                                </div>
                                                <div className="form-group">
                                                    <TextInput
                                                        label="Name"
                                                        name="name"
                                                        value={values.name.toUpperCase()}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <TextInput
                                                        label="Description"
                                                        name="description"
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        subtitle={'Description displayed to customer'}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Type</label>
                                                    <div
                                                        onClick={this.scrollDropdownIntoView.bind(this)}>
                                                        <FormSelect
                                                            fieldName={`type`}
                                                            placeholder="Select Type"
                                                            options={adjustmentTypeOptions(["Adj-Charges", "Credit", "Other"])}
                                                            setFieldValue={setFieldValue}
                                                            value={values.type}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </div>
                                                {(this.checkAdjustmentType(values.type)) &&
                                                <div className="form-group">
                                                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                        <label>
                                                            <input
                                                                name="showInstallmentPlan"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={event => {
                                                                    handleChange(event);
                                                                    if(!values.showInstallmentPlan){
                                                                        setFieldValue("installmentPlans", []);
                                                                    }
                                                                }}
                                                                setFieldValue={setFieldValue}
                                                                checked={values.showInstallmentPlan}
                                                            />
                                                            <span>&nbsp;</span>
                                                        </label>
                                                        <label>Allow Installment Plan</label>
                                                    </div>
                                                </div>
                                                }
                                                {values.showInstallmentPlan &&
                                                <div className="form-group">
                                                    <div
                                                        onClick={this.scrollDropdownIntoView.bind(this)}>
                                                        <FormSelect
                                                            fieldName={`installmentPlans`}
                                                            placeholder="Select one or many Installment Plans"
                                                            options={installmentPlansAsOptions(installmentPlanItems)}
                                                            setFieldValue={setFieldValue}
                                                            value={values.installmentPlans}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                            isMulti={true}
                                                        />
                                                    </div>
                                                </div>
                                                }
                                                <div className="form-group">
                                                    <label htmlFor="date">Start Date</label>
                                                    <div className="customDatePickerWidth">
                                                        <DatePicker
                                                            className={"form-control text-left" +
                                                            (errors.startDate && touched.startDate ? " is-invalid" : "")}
                                                            fieldName="startDate"
                                                            placeholderText={"Select Date"}
                                                            autoFocus={false}
                                                            shouldCloseOnSelect={true}
                                                            popperPlacement={'auto'}
                                                            selected={values.startDate}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => setFieldValue('startDate', date)}

                                                            isClearable={true}
                                                        />
                                                        {errors.startDate && touched.startDate &&
                                                        <div className="invalid-feedback">{errors.startDate}</div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="date">End Date (optional)</label>
                                                    <div className="customDatePickerWidth">
                                                        <DatePicker
                                                            className={"form-control text-left"}
                                                            fieldName="endDate"
                                                            placeholderText={"Select Date"}
                                                            autoFocus={false}
                                                            shouldCloseOnSelect={true}
                                                            popperPlacement={'auto'}
                                                            selected={values.endDate}
                                                            dateFormat="MM/dd/yyyy"
                                                            onChange={(date) => setFieldValue('endDate', date)}

                                                            isClearable={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Price</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">$</span>
                                                        </div>
                                                        <input
                                                            style={{flex: 'auto'}}
                                                            type="text"
                                                            name="price"
                                                            disabled={isSubmitting}
                                                            value={values.price}
                                                            data-amount={''}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            autoComplete={"off"}
                                                        />
                                                    </div>
                                                </div>

                                                <span>&nbsp;</span>
                                                <label>General Ledgers (optional)</label>
                                                <FieldArray name="generalLedgers"
                                                            render={({remove, push}) => <>
                                                                {values.selectedGeneralLedgers && values.selectedGeneralLedgers.map((selectedGeneralLedger, index) =>
                                                                    <div className="" key={selectedGeneralLedger.id} style={{paddingBottom: '10px'}}>
                                                                        <div className="card card-sm">
                                                                            <div className="card-header" style={{paddingRight: '0px'}}>
                                                                                <div className="col-md-9" style={{paddingLeft: '0px'}}>
                                                                                    <label style={{fontSize: "0.95rem"}}>
                                                                                        {(selectedGeneralLedger.glLocationId || selectedGeneralLedger.glId || selectedGeneralLedger.glSubId ?
                                                                                                this.buildGeneralLedgerTitle(selectedGeneralLedger)
                                                                                                :
                                                                                                "General Ledger"
                                                                                        )}
                                                                                    </label>
                                                                                </div>
                                                                                <div className="col-md-2" style={{textAlign: "end"}}>
                                                                                    {values.selectedGeneralLedgers.length > 1 || (
                                                                                        selectedGeneralLedger.glLocationId || selectedGeneralLedger.glId || selectedGeneralLedger.glSubId) ?
                                                                                        <button
                                                                                            onClick={() => this.handleRemoveGeneralLedger(props, remove, index, setFieldValue)}
                                                                                            className="btn btn-sm btn-danger"
                                                                                            type="button"
                                                                                        >
                                                                                            Remove
                                                                                        </button>
                                                                                        : ''
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <div className="row">
                                                                                    <div className="form-group col-sm-4">
                                                                                        <div
                                                                                            onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                                            <FormSelect
                                                                                                fieldName={`selectedGeneralLedgers.${index}.glLocationId`}
                                                                                                placeholder="Select Location"
                                                                                                options={systemLocationAsOptions(removeSelectedLocations(systemLocations, index, values.selectedGeneralLedgers))}
                                                                                                setFieldValue={(e, value) => this.setGeneralLedgerLocationValue(e, value, setFieldValue, index)}
                                                                                                value={selectedGeneralLedger.glLocationId}
                                                                                                onBlur={handleBlur}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="form-group col-sm-4">
                                                                                        <div
                                                                                            onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                                            <FormSelect
                                                                                                fieldName={`selectedGeneralLedgers.${index}.glId`}
                                                                                                placeholder="Select Account"
                                                                                                options={generalLedgersAsOptions(generalLedgers)}
                                                                                                setFieldValue={(e, value) => this.setGeneralLedgerValue(e, value, setFieldValue, index)}
                                                                                                value={selectedGeneralLedger.glId}
                                                                                                onBlur={handleBlur}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                            />
                                                                                        </div>
                                                                                        <span
                                                                                            className="text-muted">{selectedGeneralLedger.glDescription}</span>
                                                                                    </div>
                                                                                    <div className="form-group col-sm-4">
                                                                                        <div
                                                                                            onClick={this.scrollDropdownIntoView.bind(this)}>
                                                                                            <FormSelect
                                                                                                fieldName={`selectedGeneralLedgers.${index}.glSubId`}
                                                                                                placeholder="Select Sub Account"
                                                                                                options={generalLedgersSubAccountsAsOptions(matchCurrentSelectedGeneralLedger(generalLedgerSubAccounts, selectedGeneralLedger.glId))}
                                                                                                setFieldValue={setFieldValue}
                                                                                                value={selectedGeneralLedger.glSubId}
                                                                                                onBlur={handleBlur}
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <span>&nbsp;</span>
                                                                <div className="clearfix">
                                                                    <button
                                                                        onClick={() => this.addAnotherGeneralLedger(values.selectedGeneralLedgers)}
                                                                        className="btn btn-sm btn-outline-secondary btn-discount-add float-right"
                                                                        type="button"
                                                                    >
                                                                        Add Another
                                                                    </button>
                                                                </div>

                                                            </>
                                                            }
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button onClick={this.props.hideModal} className="btn" type="button">
                                                    Cancel
                                                </button>
                                                <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                    {modalProps.type === 'create' ? 'Add Adjustment' : 'Edit Adjustment'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}}
                    />}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        adjustment = getProducts(state).adjustment,
        installmentPlanItems = getDomain(state).installmentPlans,
        generalLedgers = getAdmin(state).generalLedgers,
        generalLedgerSubAccounts = getAdmin(state).generalLedgerSubAccounts,
        systemLocations = getAdmin(state).systemLocations;


    return {
        modalProps,
        adjustment,
        installmentPlanItems,
        generalLedgers,
        generalLedgerSubAccounts,
        systemLocations
    };
};

const mapDispatchToProps = {
    getAdjustment,
    getGeneralLedgers,
    getGeneralLedgerSubAccounts,
    getRateCodes,
    getServiceModels,
    getSystemLocations,
    addAdjustment,
    updateAdjustment,
    getProviders,
    getInstallmentPlans,
    hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAdjustmentModal);