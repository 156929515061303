import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal, showModal } from "../../actions/modal.actions";
import NotificationQueueList from "./NotificationQueueList";

/**
 * Page content wraper for Notification Queue management page
 */
class NotificationQueue extends Component {
  componentDidMount() {
    // Change navbar type and color
    document.title = "Notification Queue - camvio.cloud";
    this.props.changeNavBarType("default", "Notification Queue");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isShowAccessModal !== this.props.isShowAccessModal && this.props.isShowAccessModal === true) {
    }
  }

  componentWillUnmount() {
    this.props.hideModal();
  }

  render() {
    const { history, isShowAccessModal } = this.props;

    //If user is redirected from create-account page - show cpni pop-up
    if (isShowAccessModal === false) {
      return null;
    }

    return (
      <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
        <NotificationQueueList />
      </div>
    );
  }
}

// const loader = createLoadingSelector([ 'SET_ACCOUNT_DETAILS' ]);

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  showModal,
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationQueue);
