import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import {getOrderTimeslots, getTroubleTicketTimeslots} from "../../../actions/appointments.actions";
import Schedule from "./Schedule";
import {createLoadingSelector, getTechnicianAppointment, getTimeslots, getUser} from "../../../selectors";
import {
    createAppointmentCapacity,
    getOrderAppointmentAreas,
    getTroubleTicketAppointmentAreas,
    getAppointmentCapacities
} from "../../../actions/technicianAppointment.actions";
import {connect} from "react-redux";
import moment from "moment";

class Content extends Component {

    state = {
        currentOrderArea: undefined,
        currentTroubleTicketArea: undefined,
        initialOrderLoad: true,
        initialTroubleTicketLoad: true
    }

    updateCurrentArea = (currentArea) => {
        if(this.props.activeTile == 'orders'){
            this.setState({
                currentOrderArea: currentArea,
                initialOrderLoad: false
            })
        }

        if(this.props.activeTile == 'tickets'){
            this.setState({
                currentTroubleTicketArea: currentArea,
                initialTroubleTicketLoad: false
            })
        }
    }

    render() {


        const { activeTile } = this.props;

        return (
            <div className="tab-content" id="home-tiles-content">

                <div className={`tab-pane tab-pane-${activeTile}` + (activeTile === 'orders' ? ' active' : '')}>
                    {activeTile === 'orders' &&
                    <Schedule
                        getAppointmentAreas={this.props.getOrderAppointmentAreas}
                        getAppointmentCapacities={this.props.getAppointmentCapacities}
                        getAppointmentTimeSlots={this.props.getOrderTimeslots}
                        appointmentTimeSlots={this.props.orderTimeslots}
                        appointmentAreas={this.props.orderAppointmentAreas}
                        appointmentCapacity={this.props.appointmentCapacity}
                        updateCurrentArea={this.updateCurrentArea}
                        currentArea={this.state.currentOrderArea}
                        initialLoad={this.state.initialOrderLoad}
                        areasLoader={this.props.orderAreasLoader}
                        canEdit={this.props.canEdit}
                        {...this.props}
                    />}
                </div>

                <div className={`tab-pane tab-pane-${activeTile}` + (activeTile === 'tickets' ? ' active' : '')}>
                    {activeTile === 'tickets' &&
                    <Schedule
                        getAppointmentAreas={this.props.getTroubleTicketAppointmentAreas}
                        getAppointmentCapacities={this.props.getAppointmentCapacities}
                        getAppointmentTimeSlots={this.props.getTroubleTicketTimeslots}
                        appointmentTimeSlots={this.props.troubleTicketTimeslots}
                        appointmentAreas={this.props.troubleTicketAppointmentAreas}
                        appointmentCapacity={this.props.appointmentCapacity}
                        updateCurrentArea={this.updateCurrentArea}
                        currentArea={this.state.currentTroubleTicketArea}
                        initialLoad={this.state.initialTroubleTicketLoad}
                        areasLoader={this.props.troubleTicketAreasLoader}
                        canEdit={this.props.canEdit}
                    />

                    }
                </div>

            </div>
        );
    }
}

const getOrderLoaderAreas = createLoadingSelector(['GET_ORDER_APPOINTMENT_AREAS']);
const getTroubleTicketLoaderAreas = createLoadingSelector(['GET_TROUBLE_TICKET_APPOINTMENT_AREAS']);
const getLoaderOrderTimeslots = createLoadingSelector(['GET_ORDER_TIMESLOTS']);
const getLoaderTroubleTicketTimeslots = createLoadingSelector(['GET_TROUBLE_TICKET_TIMESLOTS']);
const getLoaderCapacities = createLoadingSelector(['GET_APPOINTMENT_CAPACITIES']);


const mapStateToProps = (state) => {

    const orderAreasLoader = getOrderLoaderAreas(state);
    const troubleTicketAreasLoader = getTroubleTicketLoaderAreas(state);
    const orderTimeslotsLoader = getLoaderOrderTimeslots(state);
    const troubleTicketTimeslotsLoader = getLoaderTroubleTicketTimeslots(state);
    const capacityLoader = getLoaderCapacities(state);

    const orderAppointmentAreas = getTechnicianAppointment(state).orderAppointmentAreas;
    const troubleTicketAppointmentAreas = getTechnicianAppointment(state).troubleTicketAppointmentAreas;
    // Reverse mutates original array, so we slice first to create a copy
    const orderTimeslots = getTimeslots(state).orderTimeslots.slice().reverse();
    const troubleTicketTimeslots = getTimeslots(state).troubleTicketTimeslots.slice().reverse();
    const appointmentCapacity = getTechnicianAppointment(state).appointmentCapacity;

    const userPermission = getUser(state).userPermission;

    const settingsPermissions =
        userPermission.find((x) => x.name === 'ADMN') &&
        userPermission.find((x) => x.name === 'ADMN').permissions;
    const canEdit = settingsPermissions && settingsPermissions.includes("APPT_CAPACITY_EDIT");
    const canManageTokens = settingsPermissions && settingsPermissions.includes("CREATE_API_TOKEN");
    const canViewPartners = settingsPermissions && settingsPermissions.includes("ADMN_PARTNER_DETAIL");

    return {
        orderAreasLoader,
        troubleTicketAreasLoader,
        orderTimeslotsLoader,
        troubleTicketTimeslotsLoader,
        orderAppointmentAreas,
        troubleTicketAppointmentAreas,
        orderTimeslots,
        troubleTicketTimeslots,
        capacityLoader,
        appointmentCapacity,
        canEdit,
        canManageTokens,
        canViewPartners
    };
};

const mapDispatchToProps = {
    getAppointmentCapacities,
    getOrderAppointmentAreas,
    getTroubleTicketAppointmentAreas,
    getOrderTimeslots,
    getTroubleTicketTimeslots,
    createAppointmentCapacity,
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
