import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes, getErrorMessage} from '../utils/helpers';

export const GET_PLAN_CATEGORIES = createRequestTypes('GET_PLAN_CATEGORIES');
export const GET_PLAN_LOCATIONS = createRequestTypes('GET_PLAN_LOCATIONS');
export const GET_SERVICE_MODELS = createRequestTypes('GET_SERVICE_MODELS');
export const GET_NUMBER_CATEGORIES = createRequestTypes('GET_NUMBER_CATEGORIES');
export const GET_INVENTORY_NUMBERS = createRequestTypes('GET_INVENTORY_NUMBERS');
export const GET_INSTALLMENT_PLANS = createRequestTypes('GET_INSTALLMENT_PLANS');
export const GET_SYSTEM_COUNTRIES = createRequestTypes('GET_SYSTEM_COUNTRIES');

export const getPlanCategories = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_PLAN_CATEGORIES));
	try {
		const response = await axiosInstance.get(`/domain/plancategories`);
		dispatch(actionCreator.success(GET_PLAN_CATEGORIES, response.data.categories));
		return response.data.user;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_PLAN_CATEGORIES, mockError));
		return err;
	}
}

export const getPlanLocations = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_PLAN_LOCATIONS));
	try {
		const response = await axiosInstance.get(`domain/planlocations`);
		dispatch(actionCreator.success(GET_PLAN_LOCATIONS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}

		dispatch(actionCreator.failure(GET_PLAN_LOCATIONS, mockError));
		return err;
	}
}

export const getServiceModels = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_SERVICE_MODELS));
	try {
		const response = await axiosInstance.get(`/domain/servicemodels`);
		dispatch(actionCreator.success(GET_SERVICE_MODELS, response.data));
		return response.data.user;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_SERVICE_MODELS, mockError));
		return err;
	}
}

export const getNumberCategories = (serviceModelId, locationId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_NUMBER_CATEGORIES));
	try {
		const params = { serviceModelId, locationId };
		const response = await axiosInstance.get(`/domain/numbercategories`, { params });

		dispatch(actionCreator.success(GET_NUMBER_CATEGORIES, response.data));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_NUMBER_CATEGORIES, getErrorMessage(err)));
		throw err;
	}
}

export const getInventoryNumbers = (params) => async (dispatch) => {
	if (typeof params.number == "undefined" || params.number === null) {
		delete params.number;
	}

	dispatch(actionCreator.request(GET_INVENTORY_NUMBERS));
	try {
		const response = await axiosInstance.get(`/domain/inventory-numbers/list`, { params });

		dispatch(actionCreator.success(GET_INVENTORY_NUMBERS, response.data));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_INVENTORY_NUMBERS, getErrorMessage(err)));
		throw err;
	}
}

export const getInstallmentPlans = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_INSTALLMENT_PLANS));
	try {
		const response = await axiosInstance.get(`domain/installmentPlans`);
		dispatch(actionCreator.success(GET_INSTALLMENT_PLANS, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_INSTALLMENT_PLANS, getErrorMessage(err)));
		throw err;
	}
}

//
// Addressing : States, Countries, etc
//

export const getSystemCountries = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_SYSTEM_COUNTRIES));
	try {
		const response = await axiosInstance.get(`/domain/countries/summary`);
		dispatch(actionCreator.success(GET_SYSTEM_COUNTRIES, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}

		dispatch(actionCreator.failure(GET_SYSTEM_COUNTRIES, mockError));
		return err;
	}
}
