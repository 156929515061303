import React, { Component } from 'react';
import {getServiceLineAddressInformation} from "src/actions/accountDetailsActions/accountDetails.actions";
import {connect} from "react-redux";
import LoaderPulse from "src/components/Loader/LoaderPulse";
import {showModal} from "src/actions/modal.actions";
import {getUserPermission} from "src/selectors";

export class TroubleTicketServiceLineSummary extends Component {

	state = {
		serviceLineData: {},
		isFetched: false,
	};

	componentDidMount() {
		this.fetchTroubleTicketData()
	}


	fetchTroubleTicketData = async () => {
		let result = await this.props.getServiceLineAddressInformation(this.props.serviceLine.serviceLineId)
		if(result) {
			this.setState({isFetched: true, serviceLineData: result})
		}
		else {
			this.setState({isFetched: false})
		}

	}


	render() {
		const {serviceLine, troubleTicketDetails, canEditServiceAddress} = this.props
		const {serviceLineData, isFetched} = this.state
		return (
					<div className="row" style={{width: '100%'}}>
						{isFetched ?
							<>
							<div className="col-12 col-sm-4 col-md-2">
								<div className="order-info">
									<span>
										{'Serviceline #: '}
										<span className="font-weight-bold">
											{serviceLine.serviceLineId}
										</span>
									</span>
								</div>
							</div>
							<div className="col-12 col-sm-8 col-md-6">
							<div className="order-title">
								<a href={`http://maps.google.com/maps?q=${serviceLineData.servicelineAddress?.latitude},${serviceLineData.servicelineAddress?.longitude}`}
								target="_blank" className="link-map">
								{serviceLineData.servicelineAddress?.address1}
								</a>
							</div>
										</div>
							<div className="col-12 col-md-4">
							<div className="order-toolbar">
							<div className="btn-group btn-group-sm">
								<button
									title="Show Mapping Information"
									className="btn btn-outline-secondary"
									onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
										title: "Service Address Information",
										type: "TROUBLE_TICKET",
										id: serviceLine.serviceLineId || '',
										addressId: serviceLineData.servicelineAddress.id || '',
										service: {
											address1: serviceLineData.servicelineAddress.address1 || '',
											address2: serviceLineData.servicelineAddress.address2 || '',
											address3: serviceLineData.servicelineAddress.address3 || '',
											city: serviceLineData.servicelineAddress.city || '',
											latitude: serviceLineData.servicelineAddress.latitude || '',
											longitude: serviceLineData.servicelineAddress.longitude || '',
											mappingAddressId: serviceLineData.servicelineAddress.mappingAddressId || '',
											mappingAreaId: serviceLineData.servicelineAddress.mappingAreaId || '',
											mappingRefArea1: serviceLineData.servicelineAddress.mappingRefArea1 || '',
											state: serviceLineData.servicelineAddress.state || '',
											country: serviceLineData.servicelineAddress.country || '',
											zipcode: serviceLineData.servicelineAddress.zipcode || '',
											mappingNote: serviceLineData.servicelineAddress.mappingNote || '',
											addressValues: serviceLineData.servicelineAddress.savedAddressZoneValues || []
										},
										canViewCSIGeocode: false,
										canEditCSIGeocode: false,
										isDisabled: !canEditServiceAddress,
										fetchTroubleTicketData: this.fetchTroubleTicketData
									})}
								>
									<i className="fas fa-map" />
								</button>
							</div>
							</div>
							</div>
							</>
							:
							<LoaderPulse/>
						}
					</div>

		)
	}
}

const mapStateToProps = (state) => {
	const canEditServiceAddress = getUserPermission(state, 'ADMN', 'ADMN_EDIT_SERVICE_ADDRESS');
	return {
		canEditServiceAddress
	};
};

const mapDispatchToProps = {
	getServiceLineAddressInformation,
	showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TroubleTicketServiceLineSummary);
