import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import Loader from '../../../components/Loader';
import Select from "react-select";
import {get} from 'lodash'
import {groupStyles} from "../../../utils/SelectStyles";
import {createLoadingSelector, getProvisioning, getUser} from "../../../selectors";
import {
    getServiceModels,
    clearServiceModels,
    getPlans,
    clearPlans,
    getPlanDetails,
    clearPlanDetails,
} from "../../../actions/provisioning.actions";
import {getErrorMessage, isEmpty} from "../../../utils/helpers";
import ProductsPlan from "../../../components/UI/NewOrder/ProductsPlan";
import {Form, Formik} from "formik";
import StepActions from "../../../camvio-wizard/StepActions";
import FormikValuesManager from "../../../camvio-wizard/FormikValuesManager";
import {toastr} from "react-redux-toastr";

const validationSchema = Yup.object().shape({
    serviceModelId: Yup.number().nullable().required("Required").positive("Required"),
    planId: Yup.number().nullable().required("Required").positive("Required"),
})

const findPlan = (planId, plans = []) => {
    return plans.find(plan => plan.id === planId);
}

const findPlanIndex = (planId, plans) => {
    return plans.findIndex((x) => x.id === planId);
}

class PlansAndProducts extends Component {

    state = {
    }

    componentDidMount() {
        const { serviceModels } = this.props.stepState;

        if (!serviceModels) {
            this.loadServiceModels();
        }
    }

    loadServiceModels = () => {
        const { getServiceModels, setStepState } = this.props;

        setStepState({loadingServiceModels: true});

        getServiceModels().then(resp => {
          if (resp && resp.data && resp.data.success) {
              setStepState({
                  serviceModels: resp.data.serviceModels || [],
                  loadingServiceModels: false,
              });
          } else {
              setStepState({
                  loadingServiceModels: false,
              })
              toastr.error(getErrorMessage(resp).message, { timeOut: 3000, position: 'top-center' });
          }
        }).catch(err => {
            setStepState({
                loadingServiceModels: false,
            })
            toastr.error(getErrorMessage(err).message, { timeOut: 3000, position: 'top-center' });
            console.error(err);
        });
    }

    onServiceModelSelectHandler = (option, formProps) => {
        const { currentLocation, address } = this.props;
        const { setFieldValue } = formProps;

        const serviceModelId = option.value;

        setFieldValue('serviceModelId', serviceModelId);
        setFieldValue('planId', null);

        this.loadPlans(serviceModelId, currentLocation.id, address, formProps);
    }

    renderServiceModelSelect = (formProps) => {
        const { serviceModels } = this.props.stepState;
        const { errors, values } = formProps;
        const { serviceModelId } = values;
        const options = [];

        if (serviceModels) {
            serviceModels.forEach(serviceModel => {
                options.push({
                    label: serviceModel.name + (serviceModel.description? ' ('  + serviceModel.description + ')' : ''),
                    value: serviceModel.id
                });
            });
        }

        const selectedServiceModelOption = options.find(opt => opt.value === serviceModelId);

        return (
            <div className="row">
                <div id="service-model" className="form-group col-sm-3">
                    <label>Service Model</label>
                    <Select
                        name='serviceModelId'
                        value={selectedServiceModelOption}
                        onChange={(option) => this.onServiceModelSelectHandler(option, formProps)}
                        options={options}
                        placeholder="Select Service Model"
                        styles={groupStyles}
                        {...formProps}
                    />
                    {errors.serviceModelId &&
                        <div className="invalid-feedback">{errors.serviceModelId}</div>
                    }
                </div>
            </div>
        );
    }

    loadPlans = (serviceModelId, locationId, address, {setFieldValue}) => {
        const { getPlans, setStepState, setStepFormValues } = this.props;

        setStepState({ loadingPlans: true });

        getPlans(serviceModelId, locationId, address).then(resp => {
            setStepState({ loadingPlans: false });

            if (resp && resp.data && resp.data.success) {
                const plans = resp.data.plans || [];

                setStepFormValues({ plans });
            }
        });
    }

    onPlanSelectHandler = (option, formProps) => {
        this.selectPlan(option.value, formProps);
    }

    renderPlanSelect = (formProps) => {
        const { loadingPlans, loadingServiceModels } = this.props.stepState;
        const { errors, submitCount, touched, values } = formProps;
        const { planId, plans } = values;

        const options = [];

        if (plans) {
            plans.forEach(plan => {
                options.push({
                    label: plan.name,
                    value: plan.id
                });
            });
        }

        const selectedPlanOption = options.find(opt => opt.value === planId) || null;

        return (
            <div className="row">
                <div id="plan-content" className="form-group col-sm-3">
                    <label>Plan</label>
                    <Select
                        name="planId"
                        value={selectedPlanOption}
                        onChange={(option) => this.onPlanSelectHandler(option, formProps)}
                        options={options}
                        isLoading={loadingPlans || loadingServiceModels}
                        isDisabled={loadingPlans || loadingServiceModels}
                        placeholder="Select Plan"
                        styles={groupStyles}
                    />
                    {errors.planId && (touched.planId || submitCount > 0) &&
                        <div className="invalid-feedback">{errors.planId}</div>
                    }
                </div>
            </div>
        );
    }

    renderSelectedPlan = (formProps) => {
        const { loadingPlanDetails } = this.props.stepState;
        const { planId, plans } = formProps.values;

        if (loadingPlanDetails) {
            return <Loader/>
        }

        const plan = findPlan(planId, plans);
        if (!plan) {
            return;
        }

        return (
            <ProductsPlan
                key={plan.id}
                planInfo={plan}
                planIndex={findPlanIndex(planId, plans)}
                {...formProps}
            />
        );
    }

    selectPlan = (planId, formProps) => {
        const { currentLocation, getPlanDetails, setStepState, setStepFormValues } = this.props;
        const { setFieldValue, values } = formProps;
        const { plans } = values;

        setFieldValue('planId', null);

        if (!planId) {
            console.log(`Invalid plan id '${planId}'`);
            return;
        }

        const planIndex = findPlanIndex(planId, plans);
        if (planIndex < 0) {
            console.log(`Plan with id ${planId} not found in plans`);
            return;
        }

        setStepState({ loadingPlanDetails: true });

        getPlanDetails('', currentLocation.id, planId).then((response) => {
            if (!response) {
                setStepState({ loadingPlanDetails: false });
                console.error(`Invalid response when loading planDetails for plan with id ${planId}`, response);
                return;
            }

            let servicelines = [];

            if (response.requiredLines > 2) {
                response.requiredLines = 2;
            }

            for (let index = 0; index < response.requiredLines; index++) {
                let currentServiceLine = {};
                if (
                    get(response, 'serviceModel.directorySystemNumType.defaultSource') &&
                    response.serviceModel.directorySystemNumType.defaultSource === 'number_inventory'
                ) {
                    currentServiceLine = {
                        features: [],
                        // devices: [],
                        number: '',
                        serviceLineRelation: index === 0 ? 'Main' : 'Required',
                        index: index
                    };
                } else {
                    currentServiceLine = {
                        features: [],
                        // devices: [],
                        serviceLineRelation: index === 0 ? 'Main' : 'Required',
                        index: index
                    };
                }

                // ADD DEVICES TO THE LINES
                if (!isEmpty(response.serviceModel.defaultEquipment)) {
                    response.serviceModel.defaultEquipment.deviceProfiles.forEach((element) => {
                        if (isEmpty(element.possibleItems)) {
                            return;
                        }

                        let currentDevice = {
                            deviceGroupId: response.serviceModel.defaultEquipment.deviceGroupId,
                            deviceProfileId: element.id,
                            itemId: element.possibleItems[0].id,
                            itemDescription: element.possibleItems[0].description,
                            itemPrice: element.possibleItems[0].itemProperties[0].listPrice
                        };

                        if (!Array.isArray(currentServiceLine.devices)) {
                            currentServiceLine.devices = [];
                        }

                        currentServiceLine.devices.push(currentDevice);
                    });
                }

                // ADD FEATURES TO THE LINES
                if (currentServiceLine.serviceLineRelation === 'Main') {
                    if (!isEmpty(response.mainIncludedFeatures)) {
                        response.mainIncludedFeatures.forEach((element) => {
                            let feature = {
                                ...element
                            };
                            currentServiceLine.features.push(feature);
                        });
                    }
                    if (!isEmpty(response.mainIncludedFeaturePackages)) {
                        response.mainIncludedFeaturePackages.forEach((element) => {
                            let feature;

                            if (isEmpty(element.featurePackageOptions)) {
                                feature = {
                                    featurePackageId: element.featurePackageId,
                                    featurePackageDescription: element.description,
                                    featurePackageName: element.name,
                                    featurePackageNote: element.note,
                                };
                            } else {
                                feature = {
                                    featurePackageId: element.featurePackageId,
                                    featurePackageDescription: element.description,
                                    featurePackageName: element.name,
                                    featurePackageNote: element.note,
                                    ...element.featurePackageOptions[0]
                                };
                            }

                            currentServiceLine.features.push(feature);
                        });
                    }
                    if (!isEmpty(response.mainMandatoryFeatures)) {
                        response.mainMandatoryFeatures.forEach((element) => {
                            let feature = {
                                ...element
                            };
                            currentServiceLine.features.push(feature);
                        });
                    }
                    if (!isEmpty(response.mainMandatoryFeaturePackages)) {
                        response.mainMandatoryFeaturePackages.forEach((element) => {
                            let feature = {
                                featurePackageId: element.featurePackageId,
                                featurePackageDescription: element.description,
                                featurePackageName: element.name,
                                featurePackageNote: element.note,
                                ...element.featurePackageOptions[0]
                            };

                            currentServiceLine.features.push(feature);
                        });
                    }
                } else if (currentServiceLine.serviceLineRelation === 'Required') {
                    if (!isEmpty(response.requiredIncludedFeatures)) {
                        response.requiredIncludedFeatures.forEach((element) => {
                            let feature = {
                                ...element
                            };
                            currentServiceLine.features.push(feature);
                        });
                    }
                    if (!isEmpty(response.requiredIncludedFeaturePackages)) {
                        response.requiredIncludedFeaturePackages.forEach((element) => {
                            let feature = {
                                featurePackageId: element.featurePackageId,
                                featurePackageDescription: element.description,
                                featurePackageName: element.name,
                                featurePackageNote: element.note,
                                ...element.featurePackageOptions[0]
                            };

                            currentServiceLine.features.push(feature);
                        });
                    }
                    if (!isEmpty(response.requiredMandatoryFeatures)) {
                        response.requiredMandatoryFeatures.forEach((element) => {
                            let feature = {
                                ...element
                            };
                            currentServiceLine.features.push(feature);
                        });
                    }
                    if (!isEmpty(response.requiredMandatoryFeaturePackages)) {
                        response.requiredMandatoryFeaturePackages.forEach((element) => {
                            let feature = {
                                featurePackageId: element.featurePackageId,
                                featurePackageDescription: element.description,
                                featurePackageName: element.name,
                                featurePackageNote: element.note,
                                ...element.featurePackageOptions[0]
                            };

                            currentServiceLine.features.push(feature);
                        });
                    }
                }

                servicelines.push(currentServiceLine);
            }


            const updatedPlans = [...plans];
            const updatedPlan = response;

            updatedPlan.servicelines = servicelines;
            updatedPlan.requiredLines = response.requiredLines;
            updatedPlan.additionalLines = response.additionalLines;
            // populatedPlan.defaultSource = response.serviceModel.directorySystemNumType.defaultSource;

            updatedPlans[planIndex] = updatedPlan;

            setStepFormValues({
                planId: planId,
                plans: updatedPlans
            });

            setStepState({
                loadingPlanDetails: false
            });
        }).catch(error => {
            setStepState({ loadingPlanDetails: false });
            console.error(`Invalid response when loading planDetails for plan with id ${planId}`, error);
        });
    }

    createValuesBundle = (values) => ({
        plan: findPlan(values.planId, values.plans)
    })

    createFormValuesBundle = (values) => ({
        ...values,
    })

    handleNext = (values) => {
        const { next } = this.props;
        if (!next) {
            return;
        }

        next({
            values: this.createValuesBundle(values),
            formValues: this.createFormValuesBundle(values),
        });
    }

    handlePrevious = (values) => {
        const { previous } = this.props;
        if (!previous) {
            return;
        }

        previous({
            formValues: this.createFormValuesBundle(values),
        });
    }

    handleOnSubmit = (values, actions) => {
        this.handleNext(values);
    }

    renderContent = (formProps) => {
        const {
            accumulatedValues,
            stepState,
        } = this.props;

        const { loadingServiceModels } = stepState;
        const { validatedAddress } = accumulatedValues;

        if (loadingServiceModels) {
            return <Loader/>
        }

        return (
            <div className="available-services" id="available-services">

                <h4 className="available-services-address">
                    <i className="fas fa-map-marked-alt" />&nbsp;{validatedAddress && validatedAddress.formattedAddress ||  'No address provided'}
                </h4>

                {this.renderServiceModelSelect(formProps)}
                <br />
                {this.renderPlanSelect(formProps)}
                <br />
                {this.renderSelectedPlan(formProps)}
            </div>
        )
    }

    render() {
        const { stepFormValues, previous, next } = this.props;

        return (
            <Formik
                validationSchema={validationSchema}
                onSubmit={this.handleOnSubmit}
                initialValues={stepFormValues}

                render={(formProps) => (
                    <>
                        <FormikValuesManager values={stepFormValues} formProps={formProps} />

                        <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">

                            {this.renderContent(formProps)}

                            <StepActions
                                previous={previous && (() => this.handlePrevious(formProps.values))}
                                next={next && formProps.submitForm} />
                        </Form>
                    </>
                )}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const
        currentLocation = getUser(state).currentLocation,
        address = props.accumulatedValues.selectedAddress || null;

    return {
        currentLocation,
        address,
    };
};

const mapDispatchToProps = {
    getServiceModels,
    clearServiceModels,
    getPlans,
    clearPlans,
    getPlanDetails,
    clearPlanDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlansAndProducts);