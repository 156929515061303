import React, { Component } from 'react';
import { isEmpty } from '../../../../../utils/helpers';
import ReactHtmlParser from 'react-html-parser';

export class ServiceLineSummary extends Component {

	render() {
		const { serviceline, serviceInfo } = this.props;
		let planRelation='';
		if(serviceline.planRelation != null){
			planRelation = serviceline.planRelation.toLowerCase();
			planRelation = planRelation.charAt(0).toUpperCase() + planRelation.slice(1);
		}


		return (
			<div className="serviceline-wrapper">
				<h5 className="clearfix">
					<span className="badge badge-line-type">{planRelation}</span>
					<span className="float-right">{!isEmpty(serviceline.numbers) && serviceline.numbers[0]}</span>
				</h5>
				{!isEmpty(serviceline.features) &&
					<div className="plan-section" data-opt-pmnt-type="pmnt-recurring">
						<h5>Recurring</h5>
						{!isEmpty(serviceline.features) &&
							serviceline.features.filter((x) => x.featureType !== 'ACTIVATION').map((feature, inx, arr) => (
								<div className="package-wrapper" key={inx}>
									<div className="package-option">
									<span className="po-title">
										{serviceInfo.description === "Internet Change Service" && feature.action === "ENABLE" &&
											<i className="far fa-check-circle" style={{paddingRight: '5px'}}></i>
										}
										{serviceInfo.description === "Internet Change Service" && feature.action === "DISABLE" &&
											<i className="fas fa-ban" style={{paddingRight:'5px'}}></i>
										}
										{feature.featurePackageDescription && (
											ReactHtmlParser(
												`${feature.featurePackageDescription}: ${feature.featurePackageOptionNote}`
											)
										)}
										{feature.featurePackageOptionDescription && (
											ReactHtmlParser(feature.featurePackageOptionDescription)
										)}
										{feature.featureDescription && (
											ReactHtmlParser(feature.featureDescription)
										)}
									</span>
										<span className="po-price">
										{(feature.featureQty && feature.featureQty !== 1) && ReactHtmlParser(feature.featureQty + ' &times; ')} ${feature.featurePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
									</span>
									</div>
								</div>
							))}

						{serviceInfo.description !== "Internet Change Service" &&
							<p className="services-price-subtotal">
								Subtotal:&nbsp;<span className="service-price">
                            ${!isEmpty(serviceline.features) ? (
								serviceline.features
									.reduce((acc, currentValue, index) => {
										if (currentValue.featureType !== 'ACTIVATION') {
											if (!currentValue.featureQty) {
												currentValue.featureQty = 1
											}
											acc += currentValue.featurePrice * currentValue.featureQty;
										}
										return acc;
									}, 0)
									.toFixed(2)
									.replace(/\d(?=(\d{3})+\.)/g, '$&,')
							) : (
								'0.00'
							)}/mo
                        </span>
							</p>
						}
					</div>
				}



					{!isEmpty(serviceline.features) && serviceline.features.filter((x) => x.featureType === 'ACTIVATION').length > 0 &&
					<div className="plan-section" data-opt-pmnt-type="pmnt-onetime">
						<h5>Onetime</h5>
				{!isEmpty(serviceline.features) &&
						serviceline.features.filter((x) => x.featureType === 'ACTIVATION').map((feature, inx, arr) => (
						<div className="package-wrapper" key={inx}>
							<div className="package-option">
									<span className="po-title">
										{feature.featurePackageDescription && (
											ReactHtmlParser(
												`${feature.featurePackageDescription}: ${feature.featurePackageOptionNote}`
											)
										)}
										{feature.featurePackageOptionDescription && (
											ReactHtmlParser(feature.featurePackageOptionDescription)
										)}
										{feature.featureDescription && (
											ReactHtmlParser(feature.featureDescription)
										)}
									</span>
								<span className="po-price">
										${feature.featurePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
									</span>
							</div>
						</div>
						))}
						<p className="services-price-subtotal">
							Subtotal:&nbsp;<span className="service-price">
                            ${!isEmpty(serviceline.features) ? (
							serviceline.features
								.reduce((acc, currentValue, index) => {
									if (currentValue.featureType === 'ACTIVATION') {
										acc += currentValue.featurePrice;
									}
									return acc;
								}, 0)
								.toFixed(2)
								.replace(/\d(?=(\d{3})+\.)/g, '$&,')
						) : (
							'0.00'
						)}
                        </span>
						</p>
					</div>
				}

				{!isEmpty(serviceline.devices) &&
					<div className="plan-section" data-opt-pmnt-type="pmnt-equipment">
						<h5>Equipment</h5>
						{!isEmpty(serviceline.devices) &&
							serviceline.devices.map((device, inx, arr) => (
								<div className="package-wrapper">
									<div className="package-option" key={inx}>
										<span className="po-title">{device.itemDescription}</span>
										<span className="po-price">{device.itemListPrice && `$${device.itemListPrice
											.toFixed(2)
											.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</span>
									</div>
								</div>
							))}
						<p className="services-price-subtotal">
							Subtotal:&nbsp;<span className="service-price">
							${!isEmpty(serviceline.devices) ? (
							serviceline.devices
								.reduce((acc, currentValue, index) => {
									return (acc += currentValue.itemListPrice);
								}, 0)
								.toFixed(2)
								.replace(/\d(?=(\d{3})+\.)/g, '$&,')
						) : (
							'0.00'
						)}
							</span>
						</p>
					</div>
				}
			</div>
		);
	}
}

export default ServiceLineSummary;




