import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import isEmpty from "src/utils/helpers";
import FormSelect from "src/components/UI/FormSelect";
import TextInput from "src/components/UI/TextInput";

import { hideModal } from "src/actions/modal.actions";
import { getModalData, getUser, getInventory } from "src/selectors";
import { addSerializedItemsBatch, getItemsSerializedTypeOnly, getSystemNumTypes } from "src/actions/inventory.actions";
import LoadingModalContent from "src/components/Modal/LoadingModalContent";

/**
 *
 * @param {any[]} modalProps.equipmentFamilies - Array of equipment families
 * @param {any[]} modalProps.locations - Array of locations
 */
class AddInventorySerializedModal extends Component {
  state = {
    alertMessage: "",
    isLoading: true,

    deviceProfileId: "",
    itemId: "",
    locationId: "",

    isSystemNumTypesLoaded: false,
  };

  componentDidMount() {
    this.props.getEquipmentModels({ status: "ACTIVE" }).then((items) => {
      this.setState({
        isLoading: false,
      });
    });
  }

  componentWillUnmount() {}

  formSchema = () => {
    let validationSchema = {
      deviceProfileId: Yup.string().required("Required"),
      itemId: Yup.string().required("Required"),
      locationId: Yup.string().required("Required"),
      items: Yup.array()
        .of(
          Yup.object().shape({
            numbers: Yup.array()
              .of(
                Yup.object().shape({
                  systemNumTypeId: Yup.string().required("Required"),
                  number: Yup.string(),
                })
              )
              .min(1, "At least one device number is required.")
              .required("Required"),
          })
        )
        .min(1, "At least one item is required.")
        .required("Required"),
    };

    return Yup.object().shape(validationSchema);
  };

  hideModal = () => {
    this.props.hideModal();
  };

  formSubmit = (values, actions) => {
    this.setState({ alertMessage: "" }, () => {
      const postData = {
        ...values,
      };

      this.props.addSerializedItemsBatch(postData).then((response) => {
        if (!response.data) {
          actions.setSubmitting(false);
          this.setState({
            alertMessage: "Unknown error. Please try again later",
          });
          return;
        }

        if (!response.data.success) {
          actions.setSubmitting(false);
          this.setState({ alertMessage: response.data.error.message });
          return;
        }

        this.hideModal();
        this.props.modalProps.reloadFunction();
      });
    });
  };

  scrollDropdownIntoView = (e) => {
    const elementClicked = e.target.nodeName;
    const boundingElement = e.currentTarget;
    const modalBody = document.getElementsByClassName("modal-body")[0];

    if (elementClicked !== "LABEL") {
      setTimeout(function () {
        // Scroll down if the bottom is hidden...
        if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {
          // ... and the top won't end up hidden by scrolling down
          if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {
            // Scroll down till bottom of element reaches bottom of screen
            boundingElement.scrollIntoView({ block: "end" });
          }
        }
      }, 200);
    }
  };

  setEquipmentFamily = (value) => {
    if (this.state.deviceProfileId !== value) {
      this.setState({
        deviceProfileId: value,
        itemId: "",
        isSystemNumTypesLoaded: false,
      });
      // update equipment model list
      // NOTE: we are only interested to the "ACTIVE" equipment models
      this.props.getEquipmentModels(value ? { deviceProfileId: value, status: "ACTIVE" } : { status: "ACTIVE" });
    }
  };

  setEquipmentModel = (value) => {
    if (this.state.itemId !== value) {
      this.setState({
        itemId: value,
        isSystemNumTypesLoaded: false,
      });
      this.props.getSystemNumTypes(value ? { itemId: value } : undefined).then((resp) => {
        this.setState({
          isSystemNumTypesLoaded: true,
        });
      });
    }
  };

  setLocation = (value) => {
    this.setState({
      locationId: value,
    });
  };

  singleEquipmentTemplate = () => {
    return {
      numbers: this.props.systemNumTypes.map((numType) => ({
        systemNumTypeId: numType.id,
        systemNumTypeDescription: numType.description,
        systemNumTypeName: numType.name,
        number: "",
      })),
    };
  };

  render() {
    const { alertMessage } = this.state;
    const { modalProps } = this.props;
    const { equipmentFamilies, locations } = modalProps;

    // select options
    const equipmentFamiliesOptions = (equipmentFamilies || []).map((p) => ({
      value: p.id,
      label: p.description || p.name,
    }));
    const equipmentModelOptions = (this.props.equipmentModels || []).map((p) => ({
      name: "item_id",
      value: p.id,
      label: p.description || p.name,
    }));
    const locationOptions = (locations || []).map((p) => ({
      value: p.id,
      label: p.name,
    }));

    return (
      <Fragment>
        <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
          {this.state.isLoading && (
            <LoadingModalContent modalTitle={"Receive Inventory"} submitButtonLabel={"Receive All"} />
          )}
          {!this.state.isLoading && (
            <Formik
              initialValues={{
                deviceProfileId: this.state.deviceProfileId,
                itemId: this.state.itemId,
                locationId: this.state.locationId,
                items: this.props.systemNumTypes.length > 0 ? [this.singleEquipmentTemplate()] : [],
              }}
              validationSchema={this.formSchema()}
              onSubmit={this.formSubmit}
              enableReinitialize={true}
            >
              {({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Receive Inventory</h5>
                        <button onClick={this.hideModal} type="button" className="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body cmv-form form-horizontal">
                        {!isEmpty(alertMessage) && (
                          <div className="alert alert-inline alert-danger alert-dismissible">
                            <p className="mb-0">{alertMessage}</p>
                          </div>
                        )}
                        <fieldset>
                          <legend className="as-label">General</legend>
                          <div
                            className="form-group position-relative"
                            onClick={this.scrollDropdownIntoView.bind(this)}
                          >
                            <FormSelect
                              title="Equipment Family"
                              fieldName="deviceProfileId"
                              value={values.deviceProfileId}
                              setFieldValue={(f, v) => {
                                setFieldValue(f, v);
                                this.setEquipmentFamily(v);
                              }}
                              errors={errors}
                              touched={touched}
                              isMulti={false}
                              options={equipmentFamiliesOptions}
                            />
                          </div>
                          <div
                            className="form-group position-relative"
                            onClick={this.scrollDropdownIntoView.bind(this)}
                          >
                            <FormSelect
                              title="Equipment Model"
                              fieldName="itemId"
                              value={values.itemId}
                              setFieldValue={(f, v) => {
                                setFieldValue(f, v);
                                this.setEquipmentModel(v);
                              }}
                              onBlur={handleBlur}
                              errors={errors}
                              touched={touched}
                              isMulti={false}
                              options={equipmentModelOptions}
                            />
                          </div>
                          <div
                            className="form-group position-relative"
                            onClick={this.scrollDropdownIntoView.bind(this)}
                          >
                            <FormSelect
                              title="Inventory Location"
                              fieldName="locationId"
                              value={values.locationId}
                              setFieldValue={(f, v) => {
                                setFieldValue(f, v);
                                this.setLocation(v);
                              }}
                              onBlur={handleBlur}
                              errors={errors}
                              touched={touched}
                              isMulti={false}
                              options={locationOptions}
                            />
                          </div>
                        </fieldset>

                        {this.state.isSystemNumTypesLoaded && this.props.systemNumTypes.length > 0 && (
                          <FieldArray
                            name="items"
                            render={(arrayHelpers) => (
                              <div>
                                {values.items.map((property, index) => (
                                  <fieldset key={`aism_item_${index}`} className="relative">
                                    <legend className="as-label">Equipment #{index + 1}</legend>

                                    <div className="form-group text-right" style={{ marginTop: "-30px" }}>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        Remove
                                      </button>
                                    </div>

                                    <FieldArray
                                      name={`items.${index}.numbers`}
                                      render={(arrayHelpers) => (
                                        <div>
                                          {property.numbers.map((n, index1) => (
                                            <div key={`aism_item_${index}_number_${index1}`} className="form-group">
                                              <TextInput
                                                label={n.systemNumTypeName}
                                                name={`items.${index}.numbers.${index1}.number}`}
                                                value={n.number}
                                                onChange={(evt) =>
                                                  setFieldValue(
                                                    `items.${index}.numbers.${index1}.number`,
                                                    evt.target.value
                                                  )
                                                }
                                                onBlur={handleBlur}
                                                errors={errors}
                                                touched={touched}
                                                divClass="mb-0 px-0"
                                              />
                                              <p className="text-muted">
                                                <small>{n.systemNumTypeDescription}</small>
                                              </p>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    />
                                  </fieldset>
                                ))}

                                <div className="form-group text-right">
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    onClick={() => arrayHelpers.push(this.singleEquipmentTemplate())}
                                  >
                                    Add Another
                                  </button>
                                </div>
                              </div>
                            )}
                          />
                        )}
                      </div>
                      <div className="modal-footer">
                        <button onClick={this.hideModal} className="btn" type="button">
                          Cancel
                        </button>
                        <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                          Receive All
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps,
    items = getInventory(state).items,
    systemNumTypes = getInventory(state).systemNumTypes,
    currentUser = getUser(state).user;

  return {
    modalProps,
    equipmentModels: items,
    systemNumTypes: systemNumTypes,
    currentUser,
  };
};

const mapDispatchToProps = {
  hideModal,
  addSerializedItemsBatch,
  getSystemNumTypes,
  getEquipmentModels: getItemsSerializedTypeOnly,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddInventorySerializedModal);
