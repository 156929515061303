import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const GET_SYSTEM_USERS_UI = createRequestTypes('GET_SYSTEM_USERS_UI');
export const GET_PRODUCT_PLANS_UI = createRequestTypes('GET_PRODUCT_PLANS_UI');
export const GET_INVOICING_UI = createRequestTypes('GET_INVOICING_UI');
export const GET_INSTALLMENT_PLANS_UI = createRequestTypes('GET_INSTALLMENT_PLANS_UI');
export const GET_AUTOPAY_JOBS_UI = createRequestTypes('GET_AUTOPAY_JOBS_UI');
export const GET_FEATURE_PACKAGES_UI = createRequestTypes('GET_FEATURE_PACKAGES_UI');
export const GET_PRODUCT_FEATURES_UI = createRequestTypes('GET_PRODUCT_FEATURES_UI');
export const GET_ADJUSTMENTS_UI = createRequestTypes('GET_ADJUSTMENTS');
export const GET_TROUBLE_TICKET_REPORTED_CODES_UI = createRequestTypes('GET_TROUBLE_TICKET_REPORTED_CODES_UI');
export const GET_TROUBLE_TICKET_RESOLUTION_CODES_UI = createRequestTypes('GET_TROUBLE_TICKET_RESOLUTION_CODES_UI');
export const GET_ADMIN_LOCATIONS_UI = createRequestTypes('GET_ADMIN_LOCATIONS_UI');

export const DOWNLOAD_SYSTEM_USERS = createRequestTypes('DOWNLOAD_SYSTEM_USERS');
export const DOWNLOAD_PRODUCT_PLANS = createRequestTypes('DOWNLOAD_PRODUCT_PLANS');
export const DOWNLOAD_AUTOPAY_JOBS = createRequestTypes('DOWNLOAD_AUTOPAY_JOBS');
export const DOWNLOAD_PRODUCT_FEATURES = createRequestTypes('DOWNLOAD_PRODUCT_FEATURES');
export const DOWNLOAD_ADJUSTMENTS = createRequestTypes('DOWNLOAD_ADJUSTMENTS');
export const DOWNLOAD_TROUBLE_TICKET_REPORTED_CODES = createRequestTypes('DOWNLOAD_TROUBLE_TICKET_REPORTED_CODES');
export const DOWNLOAD_TROUBLE_TICKET_RESOLUTION_CODES = createRequestTypes('DOWNLOAD_TROUBLE_TICKET_RESOLUTION_CODES');
export const DOWNLOAD_LOCATIONS = createRequestTypes('DOWNLOAD_LOCATIONS');


export const getSystemUsersUi = (tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(GET_SYSTEM_USERS_UI));

    try {
        const response = await axiosInstance.post(`/ui/system-users`, tableRequest);
        dispatch(actionCreator.success(GET_SYSTEM_USERS_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(GET_SYSTEM_USERS_UI, mockError));
    }
};

export const downloadSystemUsers = (fileType, tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(DOWNLOAD_SYSTEM_USERS));
    try {
        const response = await axiosInstance.post(`/ui/system-users/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        dispatch(actionCreator.success(DOWNLOAD_SYSTEM_USERS, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(DOWNLOAD_SYSTEM_USERS, mockError));
    }
};

export const getPlansUi = (tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(GET_PRODUCT_PLANS_UI));

    try {
        const response = await axiosInstance.post(`/ui/product-plans`, tableRequest);
        dispatch(actionCreator.success(GET_PRODUCT_PLANS_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(GET_PRODUCT_PLANS_UI, mockError));
    }
};
export const getInstallmentPlansUi = (tableRequest) => async (dispatch) => {
    // dispatch(actionCreator.request(GET_PRODUCT_PLANS_UI));

    try {
        const response = await axiosInstance.post(`/ui/installment-plans`, tableRequest);
        dispatch(actionCreator.success(GET_INSTALLMENT_PLANS_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        // dispatch(actionCreator.failure(GET_PRODUCT_PLANS_UI, mockError));
    }
};

export const downloadPlans = (fileType, tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(DOWNLOAD_PRODUCT_PLANS));
    try {
        const response = await axiosInstance.post(`/ui/product-plans/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        dispatch(actionCreator.success(DOWNLOAD_PRODUCT_PLANS, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(DOWNLOAD_PRODUCT_PLANS, mockError));
    }
};
export const downloadInstallments = (fileType, tableRequest) => async (dispatch) => {
    // dispatch(actionCreator.request(DOWNLOAD_PRODUCT_PLANS));
    try {
        const response = await axiosInstance.post(`/ui/installment-plans/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        // dispatch(actionCreator.success(DOWNLOAD_PRODUCT_PLANS, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        // dispatch(actionCreator.failure(DOWNLOAD_PRODUCT_PLANS, mockError));
    }
};

export const getAutoPayJobsUi = (filters) => async (dispatch) => {
    dispatch(actionCreator.request(GET_AUTOPAY_JOBS_UI));

    try {
        const response = await axiosInstance.post(`/autopay-jobs`, filters);
        dispatch(actionCreator.success(GET_AUTOPAY_JOBS_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(GET_AUTOPAY_JOBS_UI, mockError));
    }
};

export const downloadAutoPayJobs = (fileType, tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(DOWNLOAD_AUTOPAY_JOBS));
    try {
        const response = await axiosInstance.post(`/autopay-jobs/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        dispatch(actionCreator.success(DOWNLOAD_AUTOPAY_JOBS, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(DOWNLOAD_AUTOPAY_JOBS, mockError));
    }
};

export const getFeaturePackagesUi = (tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(GET_FEATURE_PACKAGES_UI));
    try {
        const response = await axiosInstance.post(`/ui/feature-packages`, tableRequest);
        dispatch(actionCreator.success(GET_FEATURE_PACKAGES_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(GET_FEATURE_PACKAGES_UI, mockError));
    }
};

export const getProductFeaturesUi = (tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(GET_PRODUCT_FEATURES_UI));
    try {
        const response = await axiosInstance.post(`/ui/product-features`, tableRequest);
        dispatch(actionCreator.success(GET_PRODUCT_FEATURES_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(GET_PRODUCT_FEATURES_UI, mockError));
    }
};

export const downloadProductFeatures = (fileType, tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(DOWNLOAD_PRODUCT_FEATURES));
    try {
        const response = await axiosInstance.post(`/ui/product-features/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        dispatch(actionCreator.success(DOWNLOAD_PRODUCT_FEATURES, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(DOWNLOAD_PRODUCT_FEATURES, mockError));
    }
};

export const getAdjustmentsUi = (tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(GET_ADJUSTMENTS_UI));
    try {
        const response = await axiosInstance.post(`/ui/adjustments`, tableRequest);
        dispatch(actionCreator.success(GET_ADJUSTMENTS_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(GET_ADJUSTMENTS_UI, mockError));
    }
};

export const downloadAdjustments = (fileType, tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(DOWNLOAD_ADJUSTMENTS));
    try {
        const response = await axiosInstance.post(`/ui/adjustments/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        dispatch(actionCreator.success(DOWNLOAD_ADJUSTMENTS, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(DOWNLOAD_ADJUSTMENTS, mockError));
    }
};

export const getTroubleTicketReportedCodesUi = (tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKET_REPORTED_CODES_UI));

    try {
        const response = await axiosInstance.post(`/ui/trouble-tickets/reported-codes`, tableRequest);
        dispatch(actionCreator.success(GET_TROUBLE_TICKET_REPORTED_CODES_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(GET_TROUBLE_TICKET_REPORTED_CODES_UI, mockError));
    }
};

export const downloadTroubleTicketReportedCodes = (fileType, tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(DOWNLOAD_TROUBLE_TICKET_REPORTED_CODES));
    try {
        const response = await axiosInstance.post(`/ui/trouble-tickets/reported-codes/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        dispatch(actionCreator.success(DOWNLOAD_TROUBLE_TICKET_REPORTED_CODES, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(DOWNLOAD_TROUBLE_TICKET_REPORTED_CODES, mockError));
    }
};

export const getTroubleTicketResolutionCodesUi = (tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(GET_TROUBLE_TICKET_RESOLUTION_CODES_UI));

    try {
        const response = await axiosInstance.post(`/ui/trouble-tickets/resolution-codes`, tableRequest);
        dispatch(actionCreator.success(GET_TROUBLE_TICKET_RESOLUTION_CODES_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(GET_TROUBLE_TICKET_RESOLUTION_CODES_UI, mockError));
    }
};

export const downloadTroubleTicketResolutionCodes = (fileType, tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(DOWNLOAD_TROUBLE_TICKET_RESOLUTION_CODES));
    try {
        const response = await axiosInstance.post(`/ui/trouble-tickets/resolution-codes/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        dispatch(actionCreator.success(DOWNLOAD_TROUBLE_TICKET_RESOLUTION_CODES, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(DOWNLOAD_TROUBLE_TICKET_RESOLUTION_CODES, mockError));
    }
};

//
// Locations Administration
//

export const getAdminLocationsUi = (tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(GET_ADMIN_LOCATIONS_UI));
    try {
        const response = await axiosInstance.post(`/ui/locations`, tableRequest);
        dispatch(actionCreator.success(GET_ADMIN_LOCATIONS_UI, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(GET_ADMIN_LOCATIONS_UI, mockError));
    }
};

export const downloadLocations = (fileType, tableRequest) => async (dispatch) => {
    dispatch(actionCreator.request(DOWNLOAD_LOCATIONS));
    try {
        const response = await axiosInstance.post(`/ui/locations/${fileType}/download`, tableRequest, {
            responseType: 'blob'
        });
        dispatch(actionCreator.success(DOWNLOAD_LOCATIONS, response));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(DOWNLOAD_LOCATIONS, mockError));
    }
};
