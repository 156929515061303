import React, { Component, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from '../../utils/helpers';
import ServiceAgreement from "./Fields/ServiceAgreement";
import ServiceLineDevices from "./Fields/ServiceLineDevices";
import TaskDateTimeSlot from "./Fields/TaskDateTimeSlot";
import Technician from './Fields/Technician';
import ResolutionCode from './Fields/ResolutionCode';
import ClearDate from './Fields/ClearDate';
import ClearTime from './Fields/ClearTime';

export class TaskForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			initialFormValues: this.prepareInitialValues(),
			taskId: this.props.formAtrributes.id // <--- remove this
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.formAtrributes !== this.props.formAtrributes) {
			this.setState({
				initialFormValues: this.prepareInitialValues(),
				taskId: this.props.formAtrributes.id
			});
		} else if (prevProps.serviceorderInfo !== this.props.serviceorderInfo) {
			this.setState({
				initialFormValues: this.prepareInitialValues()
			});
		}
	}

	componentWillUnmount() {
		this.props.emptyTaskAttributes();
	}

	prepareInitialValues = () => {
		let initialValues = {};

		if (this.props.formAtrributes.fields) {
			for (let fieldIndex = 0; fieldIndex < this.props.formAtrributes.fields.length; fieldIndex++) {

				const field = this.props.formAtrributes.fields[fieldIndex];

				if (field.id === '_camvio_service_agreement') {
					let fieldValue = {};
					if (this.props.serviceorderInfo.serviceLines) {
						this.props.serviceorderInfo.serviceLines.forEach((serviceLine) => {
							fieldValue = {
								...fieldValue,
								[serviceLine.id]: serviceLine.serviceAgreementId
							};
						});
					}
					initialValues[field.id] = fieldValue;
				}
				else if (field.id === "_camvio_serviceline_devices") {
					let fieldValue = [];
					if (this.props.serviceorderInfo.serviceLines) {
						this.props.serviceorderInfo.serviceLines.forEach((serviceLine) => {
							fieldValue.push({
								deviceGroupId: null,
								deviceProfileId: null,
								itemId: null,
								numbers: {},
							});
						});
					}
					initialValues[field.id] = fieldValue;
				}
				else if (
					field.id === 'task_date' ||
					field.id === 'time_slot' ||
					field.id === 'time_slot_id' ||
					field.id === 'technician_time_slot_id' ||
					field.id === 'is_overbooking' ||
					field.id === 'technician_overbooking' ||
					field.id === 'technician' ||
					field.id === 'resolution_code' ||
					field.id === 'clear_time' ||
					field.id === 'clear_date'
				) {
					continue;
				}
				else {
					initialValues[field.id] = field.defaultValue || '';
				}

			}
		}

		return initialValues;
	}

	handleOnSubmit = (values, actions) => {
		const {processInstanceId} = this.props;
		let data = {
			action: values.actions,
			context: this.normalizeFormData(values),
			taskId: this.state.taskId
		};

		this.props.setUserTask(processInstanceId, data).then((response) => {
			actions.setSubmitting(false);
			actions.resetForm();
		});
	}

	normalizeFormData = (values) => {
		if(values.technician) {
			return {...values, technician: JSON.parse(values.technician).username};
		}
		if(values.task_date) {
			return {...values, task_date: values.task_date.getFullYear() + "/" + (values.task_date.getMonth()+1) + "/" + values.task_date.getDate()};
		}
		if(values.actions == "BACK"){
			return
		}
		return values;
	}

	renderFormField = (formProps, formAttributes, field, handleChange, handleBlur, errors, touched, values, setFieldValue, isSubmitting, key) => {

		// Special fields
		if (field.id === '_camvio_service_agreement') {
			return (
				<ServiceAgreement field={field} handleChange={handleChange} errors={errors} touched={touched} key={key} handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} {...this.props}/>
			);
		}
		else if (field.id === '_camvio_serviceline_devices') {
			return (
				<ServiceLineDevices field={field} formProps={formProps} handleChange={handleChange} errors={errors} touched={touched} key={key} handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} isSubmitting={isSubmitting} {...this.props}/>
			);
		} else if (field.id === 'task_date') {
			return (
				<TaskDateTimeSlot formAttributes={formAttributes} field={field} handleChange={handleChange} errors={errors} touched={touched} key={key} handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} {...this.props}/>
			);
		}
		// TODO fix this later
		else if (field.id === 'time_slot' || field.id === 'time_slot_id' || field.id === 'technician_time_slot_id' || field.id === 'is_overbooking' || field.id === 'technician_overbooking') {
			return (<div></div>);
		}

		else if (field.id === 'technician') {
			return (
				<Technician formAttributes={formAttributes} field={field} handleChange={handleChange} errors={errors} touched={touched} key={key} handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} {...this.props}/>
			);
		} else if (field.id === 'resolution_code') {
			return (
				<ResolutionCode formAttributes={formAttributes} field={field} handleChange={handleChange} errors={errors} touched={touched} key={key} handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} {...this.props}/>
			);
		} else if (field.id === 'clear_time') {
			return (
				<ClearTime field={field} handleChange={handleChange} errors={errors} touched={touched} key={key} handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} {...this.props}/>
			);
		} else if (field.id === 'clear_date') {
			return (
				<ClearDate field={field} handleChange={handleChange} errors={errors} touched={touched} key={key} handleBlur={handleBlur} values={values} setFieldValue={setFieldValue} {...this.props}/>
			);
		}

		// Normal fields
		switch (field.type) {
			case 'string':
				return (
					<div className="form-group" key={key}>
						<label>{field.name}</label>
						<input
							className={
								errors[field.id] && touched[field.id] ? 'form-control is-invalid' : 'form-control'
							}
							value={values[field.id]}
							onChange={handleChange}
							onBlur={handleBlur}
							name={field.id}
							autoComplete="off"
							disabled = {field.readeable == true && field.writeable == false}
						/>
						{errors[field.id] &&
						touched[field.id] && <div className="invalid-feedback">{errors[field.id]}</div>}
					</div>
				);

			case 'enum':
				return (
					<div className="form-group" key={key}>
						<label>{field.name}</label>
						<select
							className={
								errors[field.id] && touched[field.id] ? (
									'selectize-generic form-control is-invalid'
								) : (
									'selectize-generic form-control'
								)
							}
							value={values[field.id] || ''}
							onChange={handleChange}
							onBlur={handleBlur}
							name={field.id}
							autoComplete="off"
						>
							<option value="">Select</option>
							{field.enumValues.map((enumValue, index) => (
								<option key={index} value={enumValue.name}>
									{enumValue.label}
								</option>
							))}
						</select>
						{errors[field.id] &&
						touched[field.id] && <div className="invalid-feedback">{errors[field.id]}</div>}
					</div>
				);
			case 'date':
				return (
					<div className="form-group" style={{ width: '100%' }} key={key}>
						<label>{field.name}</label>
						<DatePicker
							className={
								errors[field.id] && touched[field.id] ? 'form-control is-invalid' : 'form-control'
							}
							type="date"
							name={field.id}
							onBlur={handleBlur}
							selected={values[field.id] || ''}
							onChange={(values) => setFieldValue(`${field.id}`, values)}
							placeholderText={'MM/DD/YYYY'}
						/>
						{errors[field.id] &&
						touched[field.id] && (
							<p className="text-danger" style={{ fontSize: '80%', width: '100%', marginTop: '0.25rem' }}>
								{errors[field.id]}
							</p>
						)}
					</div>
				);
			case 'checkbox':
				return (
					<div className="form-check checkbox-slider checkbox-slider--b-flat" key={key}>
						<label>
							<input type="checkbox" />
							<span>Checkbox Slider</span>
						</label>
					</div>
				);
			default:
				return null;
		}
	};

	valueExists = (values, serviceLine, device, property) => {
		return values && values[serviceLine.id] && values[serviceLine.id][device.id]
			&& values[serviceLine.id][device.id][property.id];
	}

	handleButtonClick = (button, formProps) => {
		if(button.name == "BACK"){
			this.setState({
				isBack: true
			})
		}else{
			this.setState({
				isBack: false
			})
		}
		formProps.setFieldValue('actions', button.name);
	}

	validationSchema = (fields) => {
		if(this.state.isBack){
			return;
		}
		if (!isEmpty(fields)) {

			let validationRules = {};

			fields.forEach((field) => {
				if (field.id === '_camvio_serviceline_devices') {

					let fieldValidation = {[field.id]: Yup.array().of(Yup.object().shape({
							deviceGroupId:
								Yup.number().typeError('Required').required('Required'),
							deviceProfileId:
								Yup.number().typeError('Required').required('Required'),
							itemId:
								Yup.number().typeError('Required').required('Required'),
							numbers:
								Yup.object().test('numbers', 'Required', function (numbers) {
									if (isEmpty(numbers)) {
										return false;
									}
									else {
										return isEmpty(Object.keys(numbers).filter(key => isEmpty(numbers[key])));
									}
								}),
						}))
					}
					validationRules = { ...validationRules, ...fieldValidation };
				}
				else if (field.required) {
					let fieldValidation = {[field.id]: Yup.string().required('Required')};
					validationRules = { ...validationRules, ...fieldValidation };
				}
			});

			return Yup.object().shape({
				...validationRules
			});
		}
	};

	render() {
		const { formAtrributes, accountInfo, history } = this.props;

		let buttons = [];

		if (!isEmpty(formAtrributes.actions)) {
			buttons = [ ...formAtrributes.actions ].reverse();
		}

		let activeTile = {
			tile: 'tasks'
		};

		return (
			<div className="cmv-container-form">
				<Formik
					initialValues={this.state.initialFormValues}
					enableReinitialize={true}
					validationSchema={this.validationSchema(formAtrributes.fields)}
					onSubmit={this.handleOnSubmit}
					render={(formProps) => (
						<Form onSubmit={formProps.handleSubmit} className="cmv-form">

							<div className="cmv-form-header">
								<div className="container">
									<h3>{formAtrributes.name}</h3>
								</div>
							</div>

							{formAtrributes.fields && (
								<Fragment>
									<div className="cmv-form-body">
										<div className="container">
											{formAtrributes.fields &&
											formAtrributes.fields.map((field, index) =>
												this.renderFormField(
													formProps,
													formAtrributes,
													field,
													formProps.handleChange,
													formProps.handleBlur,
													formProps.errors,
													formProps.touched,
													formProps.values,
													formProps.setFieldValue,
													formProps.isSubmitting,
													index,
												)
											)}
										</div>
									</div>
								</Fragment>
							)}

							{this.props.tasksIsOver && (
								<Fragment>
									<p className="text-center text-success" style={{ marginTop: '50px' }}>
										<i className="far fa-4x fa-check-circle" />
									</p>
									<p className="text-center text-success lead">
										Task <span className="font-weight-bold">#{this.props.taskId}</span> completed successfuly.
									</p>
								</Fragment>
							)}

							<div className="cmv-form-footer">
								<div className="container d-flex justify-content-between">

									{this.props.tasksIsOver && <>
										{history.location.state && history.location.state.orderId && history.location.state.serviceOrderId
											?
											<button
												type="button"
												className="btn btn-outline-primary btn-next-tab ml-auto"
												onClick={() => history.push(`/account-details/${accountInfo.id}`, {
													activeTab: 'ordersAndQuotes',
													orderId: history.location.state.orderId,
												})}
											>
												Finish
											</button>
											:
											history.location.state && history.location.state.troubleTicketId
												?
												<button
													type="button"
													className="btn btn-outline-primary btn-next-tab ml-auto"
													onClick={() => history.push(`/account-details/${accountInfo.id}`, {
														activeTab: 'troubleTickets',
														troubleTicketId: history.location.state.troubleTicketId,
													})}
												>
													Finish
												</button>
												:
												<button
													type="button"
													className="btn btn-outline-primary btn-next-tab ml-auto"
													onClick={() => history.push('/home', { activeTile })}
												>
													Finish
												</button>
										}
									</> }

									{!isEmpty(formAtrributes.actions) && buttons.map((button, index, arr) => (
										<button
											key={index}
											type="submit"
											className={index === 0
												? 'btn btn-outline-primary mr-1 mr-auto'
												: arr.length - 1 !== index
													? 'btn btn-outline-primary mr-1'
													: 'btn btn-primary btn-next-tab' }
											onClick={() => this.handleButtonClick(button, formProps)}
											disabled={formProps.isSubmitting}
										>
											{button.label}
											{' '}
											{formProps.values.actions === button.name && formProps.isSubmitting && <i className="fas fa-spin fa-spinner" />}
										</button>
									))}

								</div>
							</div>

						</Form>
					)}
				/>
			</div>
		);
	}
}

export default TaskForm;
