import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAccountDetails } from '../../../../../../selectors';
import { showModal } from '../../../../../../actions/modal.actions';

class Tile extends Component {

	render() {

		const {
			icon,
			title,
			action,
		} = this.props;

		return (
			<div
				className={"account360-tile" + (action ? " account360-tile-clickable" : "")}
				onClick={action}
			>

				<h6 className="account360-tile-title">
					{icon && <i className={icon} />}
					{title}
				</h6>

					<div className="account360-tile-content">
						{this.props.children}
					</div>

			</div>
		);
	}
}

const mapStateToProps = (state) => {

	const servicesIconsData = getAccountDetails(state).account360.serviceIcons;

	return {
		servicesIconsData,
	};
};

const mapDispatchToProps = {
	showModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(Tile);
