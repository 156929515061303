import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';
import {string} from "yup";
import {apiTargetDateTimeFormat, parseDateTimeWithFormat} from "../utils/dateTimeParseUtils";

export const GET_SYSTEM_USERS = createRequestTypes('GET_SYSTEM_USERS');
export const GET_SYSTEM_USER = createRequestTypes('GET_SYSTEM_USER');
export const GET_SYSTEM_USERS_UI = createRequestTypes('GET_SYSTEM_USERS_UI');

export const GET_SYSTEM_LOCATIONS = createRequestTypes('GET_SYSTEM_LOCATIONS');
export const GET_SYSTEM_LOCATION = createRequestTypes('GET_SYSTEM_LOCATION');
export const ADD_SYSTEM_LOCATION = createRequestTypes('ADD_SYSTEM_LOCATION');
export const UPDATE_SYSTEM_LOCATION = createRequestTypes('UPDATE_SYSTEM_LOCATION');
export const DELETE_SYSTEM_LOCATION = createRequestTypes('DELETE_SYSTEM_LOCATION');
export const GET_LOCATION_PROPERTIES = createRequestTypes('GET_LOCATION_PROPERTIES');
export const UPDATE_LOCATION_PROPERTIES = createRequestTypes('UPDATE_LOCATION_PROPERTIES');

export const GET_SYSTEM_USER_ROLES = createRequestTypes('GET_SYSTEM_USER_ROLES');
export const GET_SYSTEM_USER_GROUPS = createRequestTypes('GET_SYSTEM_USER_GROUPS');
export const ADD_SYSTEM_USER = createRequestTypes('ADD_SYSTEM_USER');
export const UPDATE_SYSTEM_USER = createRequestTypes('UPDATE_SYSTEM_USER');
export const DELETE_SYSTEM_USER = createRequestTypes('DELETE_SYSTEM_USER');

export const GENERATE_TOKEN = createRequestTypes('GENERATE_TOKEN');
export const GET_SYSTEM_USER_ACCESS_TOKENS = createRequestTypes('GET_SYSTEM_USER_ACCESS_TOKENS');
export const ADD_SYSTEM_USER_ACCESS_TOKEN = createRequestTypes('ADD_SYSTEM_USER_ACCESS_TOKEN');
export const DELETE_SYSTEM_USER_ACCESS_TOKEN = createRequestTypes('DELETE_SYSTEM_USER_ACCESS_TOKEN');

export const GET_PARTNERS = createRequestTypes('GET_PARTNERS');
export const ADD_PARTNER = createRequestTypes('ADD_PARTNER');
export const UPDATE_PARTNER = createRequestTypes('UPDATE_PARTNER');
export const DELETE_PARTNER = createRequestTypes('DELETE_PARTNER');

export const PATCH_SERVICE_ORDER_TARGET_DATE_TIME = createRequestTypes('PATCH_SERVICE_ORDER_TARGET_DATE_TIME');

export const GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS = createRequestTypes('GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS');

export const GET_ACCOUNT_DISTRIBUTION_METHOD = createRequestTypes('GET_ACCOUNT_DISTRIBUTION_METHOD');
export const UPDATE_ACCOUNT_DISTRIBUTION_METHOD = createRequestTypes('UPDATE_ACCOUNT_DISTRIBUTION_METHOD');

export const GET_PRIMARY_CONTACT = createRequestTypes('GET_PRIMARY_CONTACT');
export const GET_PROVIDERS = createRequestTypes('GET_PROVIDERS');

export const GET_GENERAL_LEDGERS = createRequestTypes('GET_GENERAL_LEDGERS');
export const GET_GENERAL_LEDGER_SUB_ACCOUNTS  = createRequestTypes('GET_GENERAL_LEDGER_SUB_ACCOUNTS ');

export const getSystemUsers = (offset, limit, query) => async (dispatch) => {
	dispatch(actionCreator.request(GET_SYSTEM_USERS));

	const getParams = {
		offset,
		limit,
		query
	};

	try {
		const response = await axiosInstance.get(`/admin/system-users`, { params: getParams });
		dispatch(actionCreator.success(GET_SYSTEM_USERS, response.data.systemUsers));
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_SYSTEM_USERS, mockError));
	}
};

//
// System Locations
// 
export const clearSystemLocations = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_SYSTEM_LOCATIONS))
}

export const getSystemLocations = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_SYSTEM_LOCATIONS));

	try {
		const response = await axiosInstance.get('/admin/locations');
		dispatch(actionCreator.success(GET_SYSTEM_LOCATIONS, response.data.locations));

	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_SYSTEM_LOCATIONS, mockError));
	}
};

export const clearSystemLocation = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_SYSTEM_LOCATION))
}

export const getSystemLocation = (locationId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_SYSTEM_LOCATION));

	try {
		const response = await axiosInstance.get(`/admin/location/${locationId}`);
		dispatch(actionCreator.success(GET_SYSTEM_LOCATION, response.data));

	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_SYSTEM_LOCATION, mockError));
	}
};

export const addSystemLocation = (location) => async (dispatch) => {
	dispatch(actionCreator.request(ADD_SYSTEM_LOCATION));

	try {
		const response = await axiosInstance.post('/admin/location', location);
		dispatch(actionCreator.success(ADD_SYSTEM_LOCATION, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(ADD_SYSTEM_LOCATION, mockError));
		return err.response;
	}
};

export const updateSystemLocation = (location) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_SYSTEM_LOCATION));

	try {
		const response = await axiosInstance.patch('/admin/location', location);
		dispatch(actionCreator.success(UPDATE_SYSTEM_LOCATION, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(UPDATE_SYSTEM_LOCATION, mockError));
		return err.response;
	}
};

export const deleteSystemLocation = (locationId) => async (dispatch) => {
	dispatch(actionCreator.request(DELETE_SYSTEM_LOCATION));

	try {
		const response = await axiosInstance.delete(`/admin/location/${locationId}`);
		dispatch(actionCreator.success(DELETE_SYSTEM_LOCATION, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(DELETE_SYSTEM_LOCATION, mockError));
		return err;
	}
};

export const clearLocationProperties = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_LOCATION_PROPERTIES))
}

export const getLocationProperties = (locationId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_LOCATION_PROPERTIES));

	try {
		const response = await axiosInstance.get(`/admin/location/${locationId}/properties`);
		dispatch(actionCreator.success(GET_LOCATION_PROPERTIES, response.data.locations));
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_LOCATION_PROPERTIES, mockError));
	}
};

export const updateLocationProperties = (locationId, properties) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_LOCATION_PROPERTIES));

	try {
		const response = await axiosInstance.post(`/admin/location/${locationId}/properties`, properties);
		dispatch(actionCreator.success(UPDATE_LOCATION_PROPERTIES, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(UPDATE_LOCATION_PROPERTIES, mockError));
		return err.response;
	}
};

//
// System Users
// 
export const clearSystemUser = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_SYSTEM_USER))
}

export const clearSystemUsers = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_SYSTEM_USERS))
}

//
// System User Roles
// 

export const getSystemUserRoles = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_SYSTEM_USER_ROLES));

	try {
		const response = await axiosInstance.get('/admin/system-user-roles');
		dispatch(actionCreator.success(GET_SYSTEM_USER_ROLES, response.data.systemUserRoles));

	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_SYSTEM_USER_ROLES, mockError));
	}
};

//
// System User Groups
// 

export const getSystemUserGroups = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_SYSTEM_USER_GROUPS));

	try {
		const response = await axiosInstance.get('/domain/systemusergroups');
		dispatch(actionCreator.success(GET_SYSTEM_USER_GROUPS, response.data.groups));

	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_SYSTEM_USER_GROUPS, mockError));
	}
};

//
// System User
// 

export const addSystemUser = (systemUser) => async (dispatch) => {
	dispatch(actionCreator.request(ADD_SYSTEM_USER));

	try {
		const response = await axiosInstance.post('/admin/system-user', systemUser);
		dispatch(actionCreator.success(ADD_SYSTEM_USER, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(ADD_SYSTEM_USER, mockError));
		return err.response;
	}
};

export const updateSystemUser = (systemUser) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_SYSTEM_USER));

	try {
		const response = await axiosInstance.patch('/admin/system-user', systemUser);
		dispatch(actionCreator.success(UPDATE_SYSTEM_USER, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(UPDATE_SYSTEM_USER, mockError));
		return err.response;
	}
};

export const deleteSystemUser = (systemUserId) => async (dispatch) => {
	dispatch(actionCreator.request(DELETE_SYSTEM_USER));

	try {
		const response = await axiosInstance.delete(`/admin/system-user/${systemUserId}`);
		dispatch(actionCreator.success(DELETE_SYSTEM_USER, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(DELETE_SYSTEM_USER, mockError));
		return err;
	}
};

export const getSystemUser = (systemUserId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_SYSTEM_USER));
	try {
		const response = await axiosInstance.get(`/admin/system-user/${systemUserId}`);
		dispatch(actionCreator.success(GET_SYSTEM_USER, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_SYSTEM_USER, mockError));
		return err;
	}
};

//
// System User Access Tokens
// 

export const getSystemUserAccessTokens = (systemUserId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_SYSTEM_USER_ACCESS_TOKENS));

	try {
		const response = await axiosInstance.get(`/admin/system-user/${systemUserId}/access-tokens`);
		if (response.data.success) {
			dispatch(actionCreator.success(GET_SYSTEM_USER_ACCESS_TOKENS, response.data.accessTokens));
		} else {
			const { code, message } = response.data.error;
			dispatch(actionCreator.failure(GET_SYSTEM_USER_ACCESS_TOKENS, { message: `${code}: ${message}`}));
		}

	} catch (err) {
		let mockError = {
			message: 'Something went wrong'
		}
		dispatch(actionCreator.failure(GET_SYSTEM_USER_ACCESS_TOKENS, mockError));
	}
};

export const deleteSystemUserAccessToken = (systemUserId, tokenId) => async (dispatch) => {
	dispatch(actionCreator.request(DELETE_SYSTEM_USER_ACCESS_TOKEN));

	try {
		const response = await axiosInstance.delete(`/admin/system-user/${systemUserId}/access-token/${tokenId}`);

		dispatch(actionCreator.success(DELETE_SYSTEM_USER_ACCESS_TOKEN, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something went wrong'
		}
		dispatch(actionCreator.failure(DELETE_SYSTEM_USER_ACCESS_TOKEN, mockError));
		throw err;
	}
};

const tokenSchema = string()
	.ensure().required("Token must not be empty")
	.matches(/^[A-Za-z0-9_-]{40}$/, { message: "Invalid Token "});

export const addSystemUserAccessToken = (systemUserId, token) => async (dispatch) => {
	dispatch(actionCreator.request(ADD_SYSTEM_USER_ACCESS_TOKEN));

	try {
		tokenSchema.validateSync(token);
	} catch (validationError) {
		dispatch(actionCreator.failure(ADD_SYSTEM_USER_ACCESS_TOKEN, {message: validationError.message}));
		throw validationError;
	}

	try {
		const response = await axiosInstance.post(`/admin/system-user/${systemUserId}/access-token`, { token });

		dispatch(actionCreator.success(ADD_SYSTEM_USER_ACCESS_TOKEN, response.data));
		return response.data;
	} catch (err) {
		let mockError = {
			message: 'Something went wrong'
		}
		dispatch(actionCreator.failure(ADD_SYSTEM_USER_ACCESS_TOKEN, mockError));
		throw mockError;
	}
};

export const clearSystemUserAccessTokens = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_SYSTEM_USER_ACCESS_TOKENS));
};


//
// Partners
// 

export const getPartners = (limit, query) => async (dispatch) => {
	dispatch(actionCreator.request(GET_PARTNERS));

	const getParams = {};

	if (limit) {
		getParams.limit = limit;
	}

	if (query) {
		getParams.query = query;
	}

	try {
		const response = await axiosInstance.get(`/admin/partners`, { params: getParams });
		dispatch(actionCreator.success(GET_PARTNERS, response.data.partners));

	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GET_PARTNERS, mockError));
	}
};

export const clearPartners = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_PARTNERS))
}

export const addPartner = (partner) => async (dispatch) => {
	dispatch(actionCreator.request(ADD_PARTNER));

	try {
		const response = await axiosInstance.post('/admin/partner', partner);
		dispatch(actionCreator.success(ADD_PARTNER, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(ADD_PARTNER, mockError));
		return err.response;
	}
};

export const updatePartner = (partner) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_PARTNER));

	try {
		const response = await axiosInstance.patch('/admin/partner', partner);
		dispatch(actionCreator.success(UPDATE_PARTNER, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(UPDATE_PARTNER, mockError));
		return err.response;
	}
};

export const deletePartner = (partnerId) => async (dispatch) => {
	dispatch(actionCreator.request(DELETE_PARTNER));

	try {
		const response = await axiosInstance.delete(`/admin/partner/${partnerId}`);
		dispatch(actionCreator.success(DELETE_PARTNER, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(DELETE_PARTNER, mockError));
		return err;
	}
};

//
// Tokens
// 

export const generateToken = () => async (dispatch) => {
	dispatch(actionCreator.request(GENERATE_TOKEN));

	try {
		const response = await axiosInstance.get('/admin/generate-token');
		dispatch(actionCreator.success(GENERATE_TOKEN, response.data));
	} catch (err) {
		let mockError = {
			message: 'Something goes wrong'
		}
		dispatch(actionCreator.failure(GENERATE_TOKEN, mockError));
	}
};

export const clearGeneratedToken = () => async (dispatch) => {
	dispatch(actionCreator.clear(GENERATE_TOKEN));
};

export const patchServiceOrderTargetDateTime = (serviceOrderId, newTargetDateTime) => async (dispatch) => {
	dispatch(actionCreator.request(PATCH_SERVICE_ORDER_TARGET_DATE_TIME));

	const formattedTargetDateTime = newTargetDateTime? parseDateTimeWithFormat(newTargetDateTime, apiTargetDateTimeFormat) : null;

	let apiUrl = `/order/serviceorder/${serviceOrderId}/targetdatetime`;

	if(formattedTargetDateTime) {
		apiUrl += `?newTargetDateTime=${formattedTargetDateTime}`;
	}

	try {
		const response = await axiosInstance.patch(apiUrl);
		dispatch(actionCreator.success(PATCH_SERVICE_ORDER_TARGET_DATE_TIME, response.data));
		return response;
	} catch (err) {
		let mockError = {
			message: 'Something went wrong'
		}
		dispatch(actionCreator.failure(PATCH_SERVICE_ORDER_TARGET_DATE_TIME, mockError));
		return err.response;
	}
};



export const getPrimaryContacts = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_PRIMARY_CONTACT));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/contacts/primary`);
		dispatch(actionCreator.success(GET_PRIMARY_CONTACT, response.data.contacts));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_PRIMARY_CONTACT));
		return err.response;
	}
};

//
// Account Distribution Method
// 

export const getAccountDistributionMethod = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACCOUNT_DISTRIBUTION_METHOD));
	try {
		const response = await axiosInstance.get(`/admin/account/${accountId}/distribution-methods`);
		dispatch(actionCreator.success(GET_ACCOUNT_DISTRIBUTION_METHOD, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_ACCOUNT_DISTRIBUTION_METHOD));
		return err.response;
	}
}

export const updateAccountDistributionMethod = (accountId, distributionDetails) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_ACCOUNT_DISTRIBUTION_METHOD));
	try {
		const response = await axiosInstance.patch(`/admin/account/${accountId}/distribution-methods`, distributionDetails);
		dispatch(actionCreator.success(UPDATE_ACCOUNT_DISTRIBUTION_METHOD, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(UPDATE_ACCOUNT_DISTRIBUTION_METHOD));
		return err.response;
	}
}

export const getAccountTypeDistributionMethods = (accountType) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS));
	try {
		const response = await axiosInstance.get(`/admin/account-type/distribution-method?accountType=${accountType}`);
		dispatch(actionCreator.success(GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS, response.data.accountTypeDistributionMethods));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS));
		return err.response;
	}
};

export const emptyAccountTypeDistributionMethods = () => async (dispatch) => {
	dispatch(actionCreator.empty(GET_ACCOUNT_TYPE_DISTRIBUTION_METHODS));
};

//
// Providers
// 

export const getProviders = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_PROVIDERS));
	try {
		const response = await axiosInstance.get(`/admin/providers`);
		dispatch(actionCreator.success(GET_PROVIDERS, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_PROVIDERS));
		return err.response;
	}
};

//
// General Ledgers
// 

export const getGeneralLedgers = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_GENERAL_LEDGERS));
	try {
		const response = await axiosInstance.get(`admin/generalLedgers`);
		dispatch(actionCreator.success(GET_GENERAL_LEDGERS, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_GENERAL_LEDGERS));
		return err.response;
	}
};

export const getGeneralLedgerSubAccounts = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_GENERAL_LEDGER_SUB_ACCOUNTS ));
	try {
		const response = await axiosInstance.get(`/admin/generalLedger/subAccounts`);
		dispatch(actionCreator.success(GET_GENERAL_LEDGER_SUB_ACCOUNTS , response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_GENERAL_LEDGER_SUB_ACCOUNTS ));
		return err.response;
	}
};
