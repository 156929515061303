import React, {Component} from 'react';
import {connect} from "react-redux";
import {showModal} from "../../../../../actions/modal.actions";
import Select from "react-select";
import {groupStyles} from "../../../../../utils/SelectStyles";
import isEmpty from "../../../../../utils/helpers";

export class Appointment extends Component {

    render() {

        const {
            appointmentKey,
            appointment,
            showDropdownById,
            accountAccessModal,
            handleAllTasksAssignee,
            handleShowAssignDropdownById,
            usernameOptions,
            isAssigning,
        } = this.props;

        return (
            <div
                className="appointment"
                data-from={appointment.appointmentDetails.timeslot.substring(0, 2).replace('08', '8').replace('09', '9')}
                data-to={appointment.appointmentDetails.timeslot.substring(6, 8).replace('08', '8').replace('09', '9')}
                data-duration={appointment.appointmentDetails.timeslot.substring(6, 8) - appointment.appointmentDetails.timeslot.substring(0, 2)}
            >
                {appointment.type && appointment.type == "order" &&
                <div className="appointment-top">
                    <div className="appointment-top-left">
                        <span className="appointment-name">
                            <span className="font-weight-bold">{appointment.customerName}</span>
                            <br/>
                            <span style={{whiteSpace: 'nowrap'}}>
                            {appointment.accountId
                                ?
                                <button
                                    onClick={() => accountAccessModal(appointment.accountId)}
                                    disabled={isAssigning}
                                >
                                    {appointment.accountNumber}
                                </button>
                                :
                                <span>{appointment.accountNumber}</span>
                            }
                                {' | '}
                                <span>{appointment.accountType.name}</span>
                            </span>
                        </span>

                        {(appointment.address.address ||
                            appointment.address.address2 || appointment.address.address3 ||
                            appointment.address.city || appointment.address.latitude ||
                            appointment.address.longitude || appointment.address.state ||
                            appointment.address.mappingAddressId || appointment.address.mappingAreaId ||
                            appointment.address.mappingRefArea1 || appointment.address.mappingNote ||
                            appointment.address.zipcode) &&
                        <span className="appointment-address" style={{
                            textDecoration: "underline", background: "transparent",
                            cursor: "pointer", border: "none", padding: 0
                        }}
                              onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
                                  title: "Service Address Information",
                                  service: {
                                      address1: appointment.address.address || '',
                                      address2: appointment.address.address2 || '',
                                      address3: appointment.address.address3 || '',
                                      city: appointment.address.city || '',
                                      latitude: appointment.address.latitude || '',
                                      longitude: appointment.address.longitude || '',
                                      mappingAddressId: appointment.address.mappingAddressId || '',
                                      mappingAreaId: appointment.address.mappingAreaId || '',
                                      mappingRefArea1: appointment.address.mappingRefArea1 || '',
                                      mappingNote: appointment.address.mappingNote || '',
                                      state: {
                                          name: appointment.address.state || '',
                                          id: appointment.address.stateId || '',
                                      },
                                      country: appointment.address.country || '',
                                      zipcode: appointment.address.zipcode || '',
                                      zip4: appointment.address.zip4 || '',
                                      externalUrl: appointment.externalUrl || '',
                                      qualifiedServices: appointment.qualifiedServices || ''
                                  },
                                  canViewCSIGeocode: false,
                                  canEditCSIGeocode: false,
                                  isDisabled: true
                              })}>
                            <i className="fas fa-map"/>&nbsp;{appointment.address.address}, {appointment.address.city}, {appointment.address.state}, {appointment.address.zipcode}
                    </span>
                        }

                    </div>
                    <div className="appointment-top-right">

                        <span className="appointment-id">
                            {appointment.type === 'order' ? "O-" : "T-"}{appointment.id}
                        </span>

                        {!isEmpty(appointment.taskIds) &&
                        <div className="appointment-controls">

                            <div className="dropdown">

                                <button
                                    className="btn btn-circle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded={showDropdownById === appointment.id}
                                    onClick={() => handleShowAssignDropdownById(appointmentKey)}
                                    disabled={isAssigning}
                                >
                                    {isAssigning === appointment.appointmentDetails.id
                                        ? <i className="fas fa-spinner fa-spin"/>
                                        : <i className="fas fa-user-plus"/>
                                    }
                                </button>

                                {showDropdownById === appointmentKey &&
                                <div className="dropdown-menu dropdown-menu-with-select dropdown-menu-right show"
                                     aria-labelledby="dropdownMenuButton">
                                    <button
                                        onClick={() => handleAllTasksAssignee('claim', appointment.taskIds)}
                                        className="dropdown-item"
                                    >
                                        Claim
                                    </button>
                                    <button
                                        onClick={() => handleAllTasksAssignee('unassign', appointment.taskIds)}
                                        className="dropdown-item"
                                    >
                                        Unassign
                                    </button>
                                    <div className="dropdown-divider"/>
                                    <h6 className="dropdown-header">Assign To User</h6>
                                    <div className="dropdown-item">
                                        <Select
                                            placeholder="Select"
                                            styles={groupStyles}
                                            options={usernameOptions}
                                            onChange={(value) => handleAllTasksAssignee('username', appointment.taskIds, value)}
                                        />
                                    </div>
                                </div>
                                }

                            </div>

                        </div>
                        }

                    </div>
                </div>
                }
                {appointment.type && appointment.type == "troubleTicket" &&
                <div className="appointment-top">
                    <div className="appointment-top-left">
                        <span className="appointment-name">
                            <span className="font-weight-bold">{appointment.customerName}</span>
                            <br/>
                            <span>
                            {appointment.accountId
                                ?
                                <button
                                    onClick={() => accountAccessModal(appointment.accountId)}
                                    disabled={isAssigning}
                                >
                                    {appointment.accountNumber}
                                </button>
                                :
                                <span>{appointment.accountNumber}</span>
                            }
                                {' | '}
                                <span>{appointment.accountType.name}</span>
                            </span>
                            {(appointment.address.address ||
                                appointment.address.address2 || appointment.address.address3 ||
                                appointment.address.city || appointment.address.latitude ||
                                appointment.address.longitude || appointment.address.state ||
                                appointment.address.mappingAddressId || appointment.address.mappingAreaId ||
                                appointment.address.mappingRefArea1 || appointment.address.mappingNote ||
                                appointment.address.zipcode) &&
                            <span className="appointment-address" style={{
                                textDecoration: "underline", background: "transparent",
                                cursor: "pointer", border: "none", padding: 0
                            }}
                                  onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
                                      title: "Service Address Information",
                                      service: {
                                          address1: appointment.address.address || '',
                                          address2: appointment.address.address2 || '',
                                          address3: appointment.address.address3 || '',
                                          city: appointment.address.city || '',
                                          latitude: appointment.address.latitude || '',
                                          longitude: appointment.address.longitude || '',
                                          mappingAddressId: appointment.address.mappingAddressId || '',
                                          mappingAreaId: appointment.address.mappingAreaId || '',
                                          mappingRefArea1: appointment.address.mappingRefArea1 || '',
                                          mappingNote: appointment.address.mappingNote || '',
                                          state: {
                                              name: appointment.address.state || '',
                                              id: appointment.address.stateId || '',
                                          },
                                          country: appointment.address.country || '',
                                          zipcode: appointment.address.zipcode || '',
                                          zip4: appointment.address.zip4 || '',
                                          externalUrl: appointment.externalUrl || '',
                                          qualifiedServices: appointment.qualifiedServices || ''
                                      },
                                      canViewCSIGeocode: false,
                                      canEditCSIGeocode: false,
                                      isDisabled: true
                                  })}>
                            <i className="fas fa-map"/>&nbsp;{appointment.address.address}, {appointment.address.city}, {appointment.address.state}, {appointment.address.zipcode}
                    </span>
                            }
                        </span>
                        {!isEmpty(appointment.taskIds) &&
                        <div className="appointment-controls">

                            <div className="dropdown">

                                <button
                                    className="btn btn-circle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded={showDropdownById === appointment.id}
                                    onClick={() => handleShowAssignDropdownById(appointmentKey)}
                                    disabled={isAssigning}
                                >
                                    {isAssigning === appointment.appointmentDetails.id
                                        ? <i className="fas fa-spinner fa-spin"/>
                                        : <i className="fas fa-user-plus"/>
                                    }
                                </button>

                                {showDropdownById === appointmentKey &&
                                <div className="dropdown-menu dropdown-menu-with-select dropdown-menu-right show"
                                     aria-labelledby="dropdownMenuButton">
                                    <button
                                        onClick={() => handleAllTasksAssignee('claim', appointment.taskIds)}
                                        className="dropdown-item"
                                    >
                                        Claim
                                    </button>
                                    <button
                                        onClick={() => handleAllTasksAssignee('unassign', appointment.taskIds)}
                                        className="dropdown-item"
                                    >
                                        Unassign
                                    </button>
                                    <div className="dropdown-divider"/>
                                    <h6 className="dropdown-header">Assign To User</h6>
                                    <div className="dropdown-item">
                                        <Select
                                            placeholder="Select"
                                            styles={groupStyles}
                                            options={usernameOptions}
                                            onChange={(value) => handleAllTasksAssignee('username', appointment.taskIds, value)}
                                        />
                                    </div>
                                </div>
                                }

                            </div>

                        </div>
                        }
                        <span className="appointment-id">
                            {appointment.type === 'order' ? "O-" : "T-"}{appointment.id}
                        </span>

                    </div>
                </div>
                }
                <div className="appointment-bottom">
                    <div className="appointment-bottom-left">

                        <span className="appointment-services">
                            {appointment.serviceOrders && appointment.serviceOrders.map(serviceOrder =>
                                serviceOrder.icon && <i className={serviceOrder.icon} />
                            )}
                        </span>
                    </div>

                    <div className="appointment-bottom-right">

                        <span className="appointment-area" title={appointment.appointmentDetails.areaDescription}>
                            {appointment.appointmentDetails.areaDescription}
                        </span>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
