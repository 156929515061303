import React, { Component } from "react";
import Customers from "./Customers";
import Tasks from "./Tasks";
import Orders from "./Orders";
import Tickets from "./Tickets";
import Appointments from "./Appointments";
import MyDay from "./MyDay";

class Content extends Component {
  render() {
    const { activeTile, enabledTiles } = this.props;

    if (!(enabledTiles || []).includes(activeTile))
      return (
        <div className="tab-content" id="home-tiles-content">
          <div className={`tab-pane tab-pane-empty active`}>
			{/* TODO: add something here? */}
		  </div>
        </div>
      );

    return (
      <div className="tab-content" id="home-tiles-content">
        <div className={`tab-pane tab-pane-${activeTile}` + (activeTile === "myday" ? " active" : "")}>
          {activeTile === "myday" && <MyDay {...this.props} />}
        </div>

        <div className={`tab-pane tab-pane-${activeTile}` + (activeTile === "customers" ? " active" : "")}>
          {activeTile === "customers" && <Customers {...this.props} />}
        </div>

        <div className={`tab-pane tab-pane-${activeTile}` + (activeTile === "tasks" ? " active" : "")}>
          {activeTile === "tasks" && <Tasks {...this.props} />}
        </div>

        <div className={`tab-pane tab-pane-${activeTile}` + (activeTile === "orders" ? " active" : "")}>
          {activeTile === "orders" && <Orders {...this.props} />}
        </div>

        <div className={`tab-pane tab-pane-${activeTile}` + (activeTile === "tickets" ? " active" : "")}>
          {activeTile === "tickets" && <Tickets {...this.props} />}
        </div>

        <div className={`tab-pane tab-pane-${activeTile}` + (activeTile === "appointments" ? " active" : "")}>
          {activeTile === "appointments" && <Appointments {...this.props} />}
        </div>
      </div>
    );
  }
}

export default Content;
