import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDashboardMenuCount, emptyDashboardMenuCount } from "../../actions/dashboardActions/customers.actions";
import { getUsersActiveTab } from "../../actions/user.actions";
import { getDashboardData, getUser, getAuthData, createLoadingSelector, getUserPermission } from "../../selectors";
import Tiles from "./Tiles";
import Content from "./Content";
import isEmpty from "../../utils/helpers";

class Dashboard extends Component {
  state = {
    activeTile: "",
  };

  componentDidMount() {
    // Change navbar type and color
    document.title = "Home - camvio.cloud";
    this.props.changeNavBarType("default", "Home");

    // Load dashboard item counts
    if (isEmpty(this.props.dashboardMenuCount)) {
      this.props.getDashboardMenuCount();
    }
    // Load previously selected tab
    // if (this.props.history.location.state && this.props.history.location.state.activeTile) {
    // 	this.setState({
    // 		activeTile: this.props.history.location.state.activeTile.tile
    // 	});
    // }

    let activeTab;

    if (!sessionStorage.getItem("activeTab")) {
      activeTab = "customers";
    } else {
      activeTab = sessionStorage.getItem("activeTab");
      window.location.hash = activeTab;
      this.props.getUsersActiveTab({ activeTab: activeTab });
      sessionStorage.removeItem("activeTab");
    }
    if (!this.props.enabledTiles.includes(activeTab)) activeTab = this.props.enabledTiles[0];

    this.setState({ activeTile: activeTab });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleTileChange = (tile) => {
    this.setState({
      activeTile: tile,
    });
    this.props.getUsersActiveTab({ activeTab: tile });
    window.location.hash = tile;
  };

  render() {
    const { activeTile } = this.state;
    const { dashboardMenuCount, dashboardMenuCountLoading, enabledTiles } = this.props;

    return (
      <div className="cmv-dashboard-modern">
        <div className="cmv-container cmv-container-dashboard cmv-container-dashboard-tiles">
          <Tiles
            enabledTiles={enabledTiles}
            activeTile={activeTile}
            handleTileChange={this.handleTileChange}
            dashboardMenuCount={dashboardMenuCount}
            dashboardMenuCountLoading={dashboardMenuCountLoading}
          />
          <Content
            activeTile={activeTile}
            dashboardMenuCount={dashboardMenuCount}
            enabledTiles={enabledTiles}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  customersServedCount: PropTypes.number,
  getCustomersServedCount: PropTypes.func,
};

const dashboardMenuCountLoadingSelector = createLoadingSelector(["SET_DASHBOARD_MENU_COUNT"]);

const mapStateToProps = (state) => {
  const dashboardMenuCount = getDashboardData(state).dashboardMenuCount,
    dashboardMenuCountLoading = dashboardMenuCountLoadingSelector(state),
    user = getUser(state).user,
    username = getAuthData(state).username,
    tilePermissionMyDay = getUserPermission(state, "WEB_MENU", "SHOW_MENU_DASH_MYDAY"),
    tilePermissionCustomers = getUserPermission(state, "WEB_MENU", "SHOW_MENU_DASH_CUSTOMERS"),
    tilePermissionTasks = getUserPermission(state, "WEB_MENU", "SHOW_MENU_DASH_TASKS"),
    tilePermissionOrders = getUserPermission(state, "WEB_MENU", "SHOW_MENU_DASH_ORDERS"),
    tilePermissionTickets = getUserPermission(state, "WEB_MENU", "SHOW_MENU_DASH_TICKETS"),
    tilePermissionAppointments = getUserPermission(state, "WEB_MENU", "SHOW_MENU_DASH_APPOINTMENTS");

  const enabledTiles = [
    tilePermissionMyDay ? "myday" : null,
    tilePermissionCustomers ? "customers" : null,
    tilePermissionTasks ? "tasks" : null,
    tilePermissionOrders ? "orders" : null,
    tilePermissionTickets ? "tickets" : null,
    tilePermissionAppointments ? "appointments" : null,
  ].filter((tile) => tile !== null);


  const activeTab = state.user.activeTab;
  return {
    dashboardMenuCount,
    dashboardMenuCountLoading,
    user,
    username,
    activeTab,
    enabledTiles,
  };
};

const mapDispatchToProps = {
  getDashboardMenuCount,
  emptyDashboardMenuCount,
  getUsersActiveTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
