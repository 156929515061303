import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes} from '../utils/helpers';

export const LIST_MIGRATION_JOBS = createRequestTypes('MIGRATION_JOBS');
export const DOWNLOAD_CSV_FILE = createRequestTypes('MIGRATION_JOBS')
export const VALIDATE_MIGRATION_JOB = createRequestTypes('MIGRATION_JOBS');
export const MIGRATE_MIGRATION_JOB = createRequestTypes('MIGRATION_JOBS')

export const getMigrationJobs = (filters) => async (dispatch) => {
    dispatch(actionCreator.request(LIST_MIGRATION_JOBS));
    try {
        const response = await axiosInstance.get(`/migration-jobs`, {params: filters});
        dispatch(actionCreator.success(LIST_MIGRATION_JOBS, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(LIST_MIGRATION_JOBS, mockError));
    }
};

export const uploadMigrationJob = (file) => async (dispatch) => {
    try {
        const response = await axiosInstance.post(`/migration-jobs/inventory-devices/upload`, file, {
        headers: {
            "Content-Type": `multipart/form-data`
        }})
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
    }
}

export const validateMigrationJob = (migrationJobId) => async (dispatch) => {
    try {
        const response = await axiosInstance.post(`/migration-jobs/${migrationJobId}`, {"action": "validate"});
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
    }
}

export const migrateMigrationJob = (migrationJobId) => async (dispatch) => {
    try {
        const response = await axiosInstance.post(`/migration-jobs/${migrationJobId}`, {"action": "migrate"});
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
    }
}

export const cancelMigrationJob = (migrationJobId) => async (dispatch) => {
    try {
        const response = await axiosInstance.delete(`/migration-jobs/${migrationJobId}`);
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
    }
}

export const downloadMigrationJobsInventoryDevicesFile = () => async (dispatch) => {
    try {
        const response = await axiosInstance.get(`/migration-jobs/inventory-devices/sample`)
        return response.data
    } catch(err) {
        let mockError = {
            message: 'Something goes wrong'
        }
    }
}

