import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tile from '../../../components/UI/Tile/Tile';

export class Tiles extends Component {

    render() {

        const { activeTile, handleTileChange} = this.props;

        const urlTile = window.location.hash.split('#').slice(-1)[0];

        if (urlTile !== '' &&
            urlTile !== activeTile &&
            [ 'orders', 'tickets',].includes(urlTile)
        ) {
            handleTileChange(urlTile);
        }

        return (
            <div className="cmv-container-dashboard-stats" id="capacity-configuration-tiles" role="tablist">
                <Tile
                    tile="orders"
                    activeTile={activeTile}
                    handleTileChange={handleTileChange}
                    cardTitle={`Order`}
                    cardIcon="fas fa-2x fa-shopping-cart"
                />

                <Tile
                    tile="tickets"
                    activeTile={activeTile}
                    handleTileChange={handleTileChange}
                    cardTitle={`Trouble Ticket`}
                    cardIcon="fas fa-2x fa-check-square"
                />

            </div>
        );
    }
}

Tiles.propTypes = {
    activeTile: PropTypes.string,
    handleTileChange: PropTypes.func
};

export default Tiles;
