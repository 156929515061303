import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { getModalData } from "../../../../selectors";
import { hideModal } from "../../../../actions/modal.actions";
import { deleteSystemLocation } from "../../../../actions/admin.actions";
import { toastr } from "react-redux-toastr";

class DeleteLocationModal extends Component {
    closeModal = () => {
        this.props.hideModal();
    };

    goBack = () => {
        this.props.deleteLocation(this.props.modalProps.value).then((response) => {
            if (response && response.data && response.data.success) {
                toastr.success('Location has been deleted successfully!', { timeOut: 1000, position: 'top-center' });
            } else {
                const errorMessage = (
                    response && response.response && response.response.data
                    && response.response.data.error && response.response.data.error.message
                ) || 'Could not delete the location!';
                toastr.error(errorMessage, { timeOut: 3000, position: 'top-center' });
            }
            this.props.hideModal();
            this.props.modalProps.reloadFunction();
        })
    };

    render() {
        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">CONFIRM DELETING LOCATION</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="lead">Are you sure you want to delete this location?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn">
                                    No
                                </button>
                                <button onClick={this.goBack} className="btn btn-primary">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;

    return {
        modalProps
    };
};

const mapDispatchToProps = {
    hideModal,
    deleteLocation : deleteSystemLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLocationModal);