import React, { Component } from 'react';
import Loader from '../../components/Loader';
import ServiceRequest from './ServiceRequest';
import AccountInfoBox from '../../components/UI/AccountInfoBox';
import { connect } from 'react-redux';
import { getAccountById, emptyAccountDetails } from '../../actions/accountDetailsActions/accountDetails.actions';
import { showAccessModal } from '../../actions/modal.actions';
import { getAccountDetails, getModalData, createLoadingSelector } from '../../selectors';
import { isEmpty } from '../../utils/helpers';

class CreateServiceRequest extends Component {
	componentDidMount() {
		const { history, isShowAccessModal, showModal, getAccountById } = this.props;

		// Change navbar type and color
		document.title = 'Service Request - camvio.cloud';
		this.props.changeNavBarType('lock', 'createServiceRequest', 'Service Request');

		if (history.location.state && history.location.state.serviceOrderData) {
			const customerId = Number(history.location.state.serviceOrderData.formValues.accountId);
			if (isShowAccessModal === false) {
				showModal('ACCOUNT_ACCESS_MODAL', { accountId: customerId });
			} else {
				getAccountById(history.location.state.serviceOrderData.formValues.accountId);
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isShowAccessModal !== this.props.isShowAccessModal && this.props.isShowAccessModal === true) {
			const { history, getAccountById } = this.props;
			if (history.location.state && history.location.state.serviceOrderData) {
				getAccountById(Number(history.location.state.serviceOrderData.formValues.accountId));
			}
		}
	}

	componentWillUnmount() {
		const { emptyAccountDetails, showAccessModal } = this.props;
		emptyAccountDetails();
		showAccessModal(false);
	}

	render() {
		const { accountInfo, history, accountInfoLoader, isShowAccessModal } = this.props;

		//If user is redirected from create-account page - show cpni pop-up
		if (
			isShowAccessModal === false &&
			history.location.state &&
			history.location.state.serviceOrderData
		) {
			return null;
		}

		if (accountInfoLoader && isEmpty(accountInfo)) {
			return <Loader />;
		}

		return (
			<div className="cmv-container-client-selected cmv-container-client-selected-modern">
				<AccountInfoBox accountInfo={accountInfo} hideCardsContainer={true} />
				<ServiceRequest accountStatus={accountInfo.status} {...this.props} />
			</div>
		);
	}
}

const loader = createLoadingSelector([ 'SET_ACCOUNT_DETAILS' ]);

const mapStateToProps = (state) => {
	const accountInfo = getAccountDetails(state).accountDetails.accountInfo,
		showNewOrderWizard = getAccountDetails(state).accountDetails.showNewOrderWizard,
		isShowAccessModal = getModalData(state).showAccessModal,
		accountInfoLoader = loader(state);

	return {
		accountInfo,
		showNewOrderWizard,
		isShowAccessModal,
		accountInfoLoader
	};
};

const mapDispatchToProps = {
	getAccountById,
	emptyAccountDetails,
	showAccessModal
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateServiceRequest);
