import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import { isEmpty } from '../../utils/helpers';
import moment from 'moment';
import {connect} from "react-redux";
import Notes from "../../containers/AccountDetails/Panels/LeftPanel/Tabs/TroubleTickets/Notes";
import {getTroubleTickets} from "src/actions/accountDetailsActions/troubleTickets.actions";
import StackedBar from "src/components/UI/OrdersAndQuotes/StackedBar";
import {getServiceLineAddressInformation} from "src/actions/accountDetailsActions/accountDetails.actions";
import {getAccountDetails} from "src/selectors";
import {parseDateTimeFormat} from "src/containers/Dashboard/Content/Orders/utils";
import {showModal} from "src/actions/modal.actions";
import TroubleTicketServiceLineSummary from "src/containers/Dashboard/Content/Tickets/TroubleTicketServicelineSummary";
import Documents from "src/containers/AccountDetails/Panels/RightPanel/Tabs/Documents/Documents";


class TroubleTicketInfoBox extends Component {

	state = {
		isNotesVisible: false,
		isTicketExpanded: false,
		isSummaryVisible: false,
		isDocumentsExpanded: false
	};

	componentDidMount() {
		this.props.getTroubleTickets({accountId: this.props.accountInfo.id , troubleTicketId: this.props.troubleTicketInfo.id})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

	}

	componentWillUnmount() {
	}

	showHideNotes = () => {
		this.setState((prevState) => ({
			isNotesVisible: !prevState.isNotesVisible,
			isSummaryVisible: false,
			isDocumentsExpanded: false
		}));
	};

	handlerDocumentsExpand = () => {
		this.setState((prevState) => ({
			isNotesVisible: false,
			isDocumentsExpanded: !prevState.isDocumentsExpanded,
			isSummaryVisible: false
		}));
	};

	showHideSummary = () => {
		this.setState((prevState) => ({
			isSummaryVisible: !prevState.isSummaryVisible,
			isNotesVisible: false,
			isDocumentsExpanded: false
		}))
	}

	callPhoneNumber = (phoneNumber) => {
		if(phoneNumber) {
			return "tel:" + phoneNumber.toString()
		}
	}


	render() {

		const {
			isNotesVisible,
			isSummaryVisible,
			isDocumentsExpanded
		} = this.state;

		const {
			troubleTicketInfo,
			hideCardsContainer,
			hideCardsTaskDetails,
			addressData,
			troubleTicketDetails
		} = this.props;

		if (isEmpty(troubleTicketInfo)) {
			return null;
        }

		return (!hideCardsContainer &&
			<div className="cmv-container-client cmv-container-client-modern">
				<div className="cmv-client-data-extended trouble-ticket-wrapper">
					<div className="container">

						<Card
							header="Reported Code"
							body={troubleTicketInfo.reportedCode ? troubleTicketInfo.reportedCode : ''}
							icon="fas fa-fw fa-hashtag"
						/>

						<Card
							header="Contact phone number"
							body={troubleTicketInfo.contactPhoneNumber ? <a href={this.callPhoneNumber(troubleTicketInfo.contactPhoneNumber)}>{troubleTicketInfo.contactPhoneNumber}</a> : ""}
							icon="fas fa-fw fa-hashtag"
						/>

						<Card
							header="Due date"
							body={troubleTicketInfo.troubleDueDate? moment(troubleTicketInfo.troubleDueDate).format('MM/DD/YYYY') : ''}
							icon="fas fa-fw fa-hashtag"
						/>
					</div>


					<div className="order-service-order-status" style={{width:'100%', padding:'1rem'}}>
						<StackedBar statuses={troubleTicketDetails?.workflowStatusChain} isStarted={true} />
						<span className="d-flex text-muted">
							<small className="d-md-inline-block ml-auto">
							{troubleTicketDetails?.workflowDescription}{' '}
							</small>
						</span>
					</div>


					<div className="container">
					<div className="row" style={{width:'100%'}}>
						<div className="col-12 col-sm-4 col-md-2">

							<div className="order-info">
								<span>
									{'Updated: '}
									<span className="font-weight-bold">
										{troubleTicketDetails?.modifiedDateTime && parseDateTimeFormat(troubleTicketDetails?.modifiedDateTime)}
									</span>
								</span>
								<span className="font-weight-bold">TT#{troubleTicketDetails?.troubleTicketId}</span>
								&nbsp;{'| '}
								{troubleTicketDetails?.modifiedBy}
							</div>

						</div>
						<div className="col-12 col-sm-8 col-md-6">
							<div className="container">
								<div className="order-title">
									<p style={{fontSize:'1.25rem'}}>{troubleTicketDetails?.reportedIssueDescription}</p>
								</div>
							</div>

						</div>
						<div className="col-12 col-md-4">
							<div className="order-toolbar">
								<div className="btn-group btn-group-sm">
									<button
										className="btn btn-outline-secondary btn-block btn-order-summary-toggle"
										data-toggle="collapse"
										onClick={this.showHideNotes}
									>
										<i className="fas fa-file-signature" />
									</button>
									<button
										className="btn btn-outline-secondary"
										onClick={this.handlerDocumentsExpand}
										title={"Attach Document"}
									>
										<i className="fas fa-paperclip"/>
									</button>
									<button
										onClick={this.showHideSummary}
										className="btn btn-outline-secondary btn-order-substep-collapse-toggle"
									>
										<i className={isSummaryVisible === true ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'}/>
									</button>
								</div>
							</div>

						</div>
					</div>
					</div>

					<div style={{width:'100%'}}>
						{isNotesVisible &&
							<Notes troubleTicketId={troubleTicketInfo.id} />
						}
						{isDocumentsExpanded &&
							<Documents accountId={this.props.accountId} canDelete={false} />
						}
					</div>

						{isSummaryVisible &&
							troubleTicketDetails.serviceLines.map((serviceLine) => {
								return (
									<div className='container' style={{borderTop: "1px solid #dee2e6"}}>
									<TroubleTicketServiceLineSummary serviceLine={serviceLine} troubleTicketDetails={troubleTicketDetails}/>
									</div>
								)
							})
						}

				</div>
			</div>
		);
	}
}

TroubleTicketInfoBox.propTypes = {
	troubleTicketInfo: PropTypes.object.isRequired,
	hideCardsContainer: PropTypes.bool,
	showStatusDropdown: PropTypes.bool,
	handleStatusDropDown: PropTypes.func,
	isStatusDropDownOpen: PropTypes.bool,
	showModal: PropTypes.func,
	accountAllowedStatusTransitions: PropTypes.array,
	accountAllowedStatusTransitionsLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
	const addressData = getAccountDetails(state).accountDetails.addressInformation
	return {
		addressData
	};
};

const mapDispatchToProps = {
	getTroubleTickets,
	getServiceLineAddressInformation,
	showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(TroubleTicketInfoBox);
