import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {showModal} from '../../../actions/modal.actions';
import Loader from '../../../components/Loader';
import {isEmpty} from '../../../utils/helpers';
import { getTroubleTicketResolutionCodesUi, downloadTroubleTicketResolutionCodes } from "../../../actions/uiController.actions";
import { getServiceModels } from "../../../actions/domain.actions";
import {
    getDomain,
    getUiController,
    getUserPermission
} from "../../../selectors";
import { getTroubleTicketResolutionCode } from "../../../actions/troubleTicketCodes.action";
import CamvioTable from "../../../components/UI/CamvioTable/CamvioTable";
import {debounce} from "lodash";
import Button from "../../../components/Common/Buttons/Button";
import Select from "react-select";
import {groupStyles} from "../../../utils/SelectStyles";

class ResolutionCodes extends Component {

    state = {
        loadingSearch: true,
        isLoading: false,
        isDownloading: false,
        searchTerm: '',
        oldSearchTerm: '',
        showDownloadDropdown: false,
        tableRequest: {
            filterList: [{
                columnName: "ttrc.is_active",
                value: "true"
            }],
            pagination: {
                limit: 20,
                offset: 0
            },
            sorting: {
                columnName: "ttrc.id",
                sortParam: "asc"
            }
        },
        resultsNumber: 20,
        resolutionCodeList: [],
        sortColumn: '',
        sortDirection: '',
        noMoreResults: true,
        showAdditionalSearchOptions: false,
        serviceModelFilter: [{name: "ttrc.service_model_id", value: "All", label: "All Services"}],
        resolutionCodeStatusFilter: [{ name: "ttrc.is_active", value: "true", label: "Active" }],
    };

    componentDidMount() {
        this.props.getServiceModels();
        this.reloadResolutionCodes();
    }

    componentWillUnmount() {
        //  this.props.clearPlans();
    }

    searchTroubleTicketResolutionCodes = () => {
        let tableRequest = this.state.tableRequest;
        let pagination = tableRequest.pagination;
        pagination.offset = 0;

        this.setState({
            tableRequest: tableRequest,
            loadingSearch: true
        });
        this.reloadResolutionCodes(true);
    }

    reloadResolutionCodes = (clearList) => {
        if (clearList) {
            let pagination = this.state.tableRequest.pagination;
            pagination.limit += pagination.offset;
            pagination.offset = 0;
            this.setState({
                resolutionCodeList: []
            })
        }
        this.setState({isLoading: true, loadingSearch: true}, () => {
            this.props.getTroubleTicketResolutionCodesUi(this.state.tableRequest).then(response => {
                if (response.rows && !isEmpty(response.rows)) {
                    response.rows.map(resolutionCodes => {
                        this.state.resolutionCodeList.push(resolutionCodes);
                    })
                    this.checkResultsLength();
                }
                this.setState({isLoading: false, loadingSearch: false});
            });
        });
    }

    toggleDownloadDropdown = () => {
        this.setState({showDownloadDropdown: !this.state.showDownloadDropdown});
    }

    sortResolutionCodes = (name, order) => {
        let tableRequest = this.state.tableRequest;
        let sorting = tableRequest.sorting;
        let sortDirection = order;
        if (this.state.sortDirection != order) {
            sorting.columnName = name;
            sorting.sortParam = order;
        }else{
            sorting.columnName = name;
            if(sorting.sortParam == "asc"){
                sorting.sortParam = "desc";
                sortDirection = "desc"
            }else{
                sorting.sortParam = "asc";
                sortDirection = "asc"
            }
        }

        //Reset pagination
        let pagination = tableRequest.pagination;
        pagination.limit += pagination.offset;
        pagination.offset = 0;

        this.setState({
            tableRequest: tableRequest,
            loadingSearch: true,
            resolutionCodeList: [],
            sortColumn: name,
            sortDirection: sortDirection
        });
        this.reloadResolutionCodes(true);
    }

    showAdditional = () => {
        let tableRequest = this.state.tableRequest;
        let pagination = tableRequest.pagination;
        pagination.offset = pagination.limit + pagination.offset;
        pagination.limit = parseInt(this.state.resultsNumber);

        this.setState({tableRequest: tableRequest});
        this.reloadResolutionCodes(false);
    }

    checkResultsLength = () => {
        if (this.state.resolutionCodeList && this.state.resolutionCodeList.length < this.props.resolutionCodeSize) {
            this.setState({
                noMoreResults: false
            })
        } else {
            this.setState({
                noMoreResults: true
            })
        }
    }

    handleResultNumberChange = (e) => {
        let {value} = e.target;
        this.setState({
            resultsNumber: value,
        });
    };

    setServiceModelSearch = (value) => {
        this.setState({
            serviceModelFilter: [{
                name: "ttrc.service_model_id",
                value: value.value,
                label: value.label
            }]
        })
        this.debounceHandleInputChange(value);
    };

    setTroubleTicketResolutionCodeStatusSearch = (value) => {
        this.setState({
            resolutionCodeStatusFilter: [{
                name: "ttrc.status",
                value: value.value,
                label: value.label
            }]
        })
        this.debounceHandleInputChange(value);
    };

    setServiceModels = () => {
        let serviceModels = [{
            name: "ttrc.service_model_id",
            value: "All",
            label: "All Services"
        }]

        this.props.serviceModels && this.props.serviceModels.map((serviceModel) => {
            serviceModels.push({
                name: "ttrc.service_model_id",
                value: serviceModel.id,
                label: serviceModel.description
            });
        });
        return serviceModels;
    }

    debounceHandleInputChange = event => {
        let name = event.name || event.target.name;
        let value = event.value || event.target.value;
        this.handleInputChange(name, value);
    }

    handleInputChange = debounce((fieldName, value) => {
        let tableRequest = this.state.tableRequest;
        let filterList = tableRequest.filterList;
        let containsFilter = true;

        if (filterList.length == 0) {
            if (isNaN(value)) {
                if (value.replace(/\s/g, '').length != 0) {
                    if (value != "All") {
                        filterList.push({
                            columnName: fieldName,
                            value: value
                        })
                    }
                }
            } else {
                filterList.push({
                    columnName: fieldName,
                    value: value
                })
            }
        } else {
            for (let index = 0; index < filterList.length; index++) {
                if (filterList[index].columnName == fieldName) {
                    containsFilter = true;
                    if (value.length == 0 || value == "All") {
                        filterList.splice(index, 1);
                    } else {
                        filterList[index].value = value;
                    }
                    break;
                } else {
                    containsFilter = false;
                }
            }
        }
        if (!containsFilter) {
            filterList.push({
                columnName: fieldName,
                value: value
            })
        }
        tableRequest.filterList = filterList;

        this.setState({tableRequest: tableRequest});

    });

    setTroubleTicketResolutionCodeStatuses = () => {
        let resolutionCodeStatus = [{ name: "ttrc.is_active", value: "All", label: "All Statuses" },
        { name: "ttrc.is_active", value: "true", label: "Active" },
        { name: "ttrc.is_active", value: "false", label: "Not Active" }]
        return resolutionCodeStatus;
    };

    downloadTable = (value) => {
        let tableRequest = this.state.tableRequest;
        tableRequest.pagination = {};

        this.setState({
            tableRequest: tableRequest,
            isDownloading: true
        });

        this.props.downloadTroubleTicketResolutionCodes(value, this.state.tableRequest).then((response) => {
            if (response && response.status === 201 && response.data) {
                let name = "";
                let link = document.createElement('a');
                let fileUrl = URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('href', fileUrl);
                if (response.headers["content-disposition"] != undefined) {
                    name = response.headers["content-disposition"].split("=")[1];
                } else {
                    let today = new Date()
                    name = "trouble_ticket_resolution_codes" + today.toISOString().split('T')[0] + "." + value;
                }
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    isDownloading: false,
                    showDownloadDropdown: false
                });
            }
        });
    };

    toggleFilter = () => {
        this.setState({showAdditionalSearchOptions: !this.state.showAdditionalSearchOptions});
    }


    render() {

        const {
            resultsNumber,
            searchTerm,
            showDownloadDropdown,
            resolutionCodeList,
            isLoading,
            sortColumn,
            sortDirection,
            isDownloading,
            showAdditionalSearchOptions
        } = this.state;

        const {
            resolutionCodeHeaders,
            canAddTroubleTicketResolutionCode
        } = this.props;


        return (
            <Fragment>
                <div className="tab-pane camvio-table-search" style={{borderRadius: '0.5rem'}}>
                    <div className="cmv-container-dashboard-search">
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <input
                                                placeholder={"Name or Description..."}
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                id="name"
                                                name="ttrc.name"
                                                onChange={this.debounceHandleInputChange}
                                                onKeyPress={(event) => {
                                                    if (event.key === 'Enter') {
                                                        this.searchTroubleTicketResolutionCodes()
                                                    }
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <Button
                                                    type="button"
                                                    className="btn btn-primary btn-show-cam-search-results"
                                                    disabled={this.state.loadingSearch || isDownloading}
                                                    onClick={() => {
                                                        this.searchTroubleTicketResolutionCodes();
                                                    }}
                                                >
                                                    <i className="fas fa-search" />
                                                    <span>&nbsp;Search</span>
                                                </Button>
                                            </div>

                                            <div className="input-group-append">
                                                <div className="btn-group">
                                                    <Button
                                                        type="button"
                                                        onClick={this.toggleFilter}
                                                        className="btn btn-outline-secondary"
                                                    >
                                                        {showAdditionalSearchOptions ?
                                                            <i className="fas fa-solid fa-ban"/>
                                                            :
                                                            <i className="fas fa-solid fa-filter"/>
                                                        }
                                                        <span>&nbsp;Filters</span>
                                                    </Button>

                                                    {canAddTroubleTicketResolutionCode &&
                                                    <Button
                                                        type="button"
                                                        className="btn btn-outline-secondary btn-new-account-wizard"
                                                        onClick={() => this.props.showModal('EDIT_TROUBLE_TICKET_RESOLUTION_CODE_MODAL', {
                                                            type: 'create',
                                                            reloadFunction: this.reloadResolutionCodes
                                                        })}
                                                        disabled={this.state.loadingSearch || isDownloading}
                                                    >
                                                        <i className="fas fa-plus"/>
                                                        <span>&nbsp;New Resolution Code</span>
                                                    </Button>
                                                    }
                                                    <div className="dropdown">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary dropdown-toggle"
                                                            onClick={this.toggleDownloadDropdown}
                                                            style={{borderLeft: "none"}}
                                                            disabled={this.state.loadingSearch || isDownloading}
                                                        >
                                                            <i className="fas fa-download"/>
                                                            <span className="camvio-table-search-download">&nbsp;Download</span>
                                                        </button>
                                                        <div
                                                            className={"dropdown-menu dropdown-menu-right" + (showDownloadDropdown && !isDownloading ? " show" : "")}>
                                                            <button
                                                                type="button"
                                                                value={"XLS"}
                                                                className="dropdown-item"
                                                                onClick={(e) => {
                                                                    this.downloadTable("XLS")
                                                                }}
                                                            >
                                                                As XLS
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={(e) => {
                                                                    this.downloadTable("CSV")
                                                                }}
                                                            >
                                                                As CSV
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAdditionalSearchOptions &&
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="camvioTable-plan-extra-filters">
                                    <div className="search">
                                        <Select
                                            className="extra-filter-select"
                                            id="serviceModel"
                                            name="ttrc.service_model_id"
                                            onChange={this.setServiceModelSearch}
                                            styles={groupStyles}
                                            options={this.setServiceModels()}
                                            value={this.state.serviceModelFilter}
                                        />
                                        &nbsp;
                                        <Select
                                            className="extra-filter-select"
                                            id="status"
                                            name="ttrc.is_active"
                                            onChange={this.setTroubleTicketResolutionCodeStatusSearch}
                                            styles={groupStyles}
                                            options={this.setTroubleTicketResolutionCodeStatuses()}
                                            placeholder={"All Statuses"}
                                            value={this.state.resolutionCodeStatusFilter}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    {(this.state.loadingSearch || isDownloading) &&
                    <Loader/>
                    }

                    {!this.state.loadingSearch && isEmpty(resolutionCodeList) &&
                    <div className="container">
                        <p>No data found</p>
                    </div>
                    }
                    {!isEmpty(resolutionCodeList) && !this.state.loadingSearch && !isDownloading &&
                    <CamvioTable
                        headers={resolutionCodeHeaders}
                        rows={resolutionCodeList}
                        loader={isLoading}
                        reloadFunction={this.reloadResolutionCodes}
                        sortFunction={this.sortResolutionCodes}
                        showModal={this.props.showModal}
                        noteFunction={() => {}}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                    />
                    }
                </div>
                {!this.state.noMoreResults &&
                <div className="row" style={{marginTop: '2%'}}>
                    <div className="col-sm-12">
                        <div className="search" style={{float: "right"}}>
                            <button
                                className="btn btn-primary"
                                disabled={this.state.loadingSearch || this.state.noMoreResults || isDownloading}
                                onClick={this.showAdditional}
                            >
                                Show Additional
                            </button>
                            &nbsp;
                            &nbsp;
                            <select
                                className="custom-select"
                                value={resultsNumber}
                                onChange={this.handleResultNumberChange}
                                disabled={this.state.loadingSearch || this.state.noMoreResults || isDownloading}
                            >
                                <option label="20" value="20"/>
                                <option label="30" value="30"/>
                                <option label="40" value="40"/>
                                <option label="50" value="50"/>
                            </select>
                        </div>
                    </div>
                </div>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const resolutionCodeHeaders = getUiController(state).troubleTicketResolutionCodesUi.headers,
        resolutionCodeRow = getUiController(state).troubleTicketResolutionCodesUi.rows,
        resolutionCodeSize = getUiController(state).troubleTicketResolutionCodesUi.size,
        serviceModels = getDomain(state).serviceModels,
        canAddTroubleTicketResolutionCode = getUserPermission(state, 'ADMN', 'ADMN_TROUBLE_TICKET_RESOLUTION_CODE_ADD');

    // Add new actions to the IF statement in render(), enabling the "ACTIONS" table column for users with rights
    return {
        resolutionCodeHeaders,
        resolutionCodeRow,
        resolutionCodeSize,
        canAddTroubleTicketResolutionCode,
        serviceModels
    };
};

const mapDispatchToProps = {
    getTroubleTicketResolutionCodesUi,
    downloadTroubleTicketResolutionCodes,
    getServiceModels,
    downloadTroubleTicketResolutionCodes,
    showModal,
    getTroubleTicketResolutionCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResolutionCodes);
