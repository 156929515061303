import React, { Component, Fragment } from 'react';
import NumericInput from 'react-numeric-input';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {
	getAdjustmentTypes, emptyAdjustmentTypes, getAccountServicelines, emptyAccountServicelines,
	calculateTaxes, createImmediateAdjustments, emptyAccountCreditBalance, getAccountBalance,
	getAccountCreditBalance, getDueDetails
} from '../../../actions/accountDetailsActions/billing.actions';
import {
	getInstallmentPlans,
	getUnpaidInvoices,
	paymentByCredit,
} from '../../../actions/accountDetailsActions/payNow.actions';
import {getAccountDetails, getUser, getModalData, createLoadingSelector, getAdmin} from '../../../selectors';
import { FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import isEmpty, { generateId } from "../../../utils/helpers";
import _, {debounce, get} from 'lodash';
import PayNowTable from "./PayNowModal/PayNowTable";
import PayInstallmentPlanTable from "./PayNowModal/PayInstallmentPlanTable";
import {toastr} from "react-redux-toastr";

const ADJUSTMENT_TYPES = {
	CREDIT: 'CREDIT',
	ADJ_CHARGES: 'ADJ-CHARGES'
};

const UNPAID_INVOICES_STATE = {
	NOT_LOADED: 0,
	LOADING: 1,
	LOADED: 2,
}

const PAYMENT_OPTION = {
	ALL_UNPAID_INVOICES: 'ALL_UNPAID_INVOICES',
	SPECIFIC_INVOICES: 'SPECIFIC_INVOICES',
	CREDIT: 'CREDIT',
	DEPOSIT: 'DEPOSIT',
	INSTALLMENT_PLAN: 'INSTALLMENT_PLAN',
};

const AdjustmentsSchema = Yup.object().shape({
	adjustments: Yup.array(Yup.object().shape({
		amount: Yup.number().typeError('Please enter only digits').positive('Please enter a positive amount').required('Required'),
		itemId: Yup.number().typeError('Must be a number').positive('Please select adjustment type').required('Required'),
	})).min(1),
	note: Yup.string().typeError('Must be a string').min(10, 'Please type at least 10 characters').required('Required'),
	paymentOption: Yup.string().required('Required'),
	selectedInvoices: Yup.array().when('paymentOption', {
		is: (value) => value === PAYMENT_OPTION.SPECIFIC_INVOICES,
		then: Yup.array().required('Required').min(1)
	}),

	selectedInstallmentPlan: Yup.array().when('paymentOption', {
		is: (value) => value === PAYMENT_OPTION.INSTALLMENT_PLAN,
		then: Yup.array().required('Required').min(1)
	})
});

const testingHandle = (event, setFieldValue, values, index) => {
	setFieldValue(`adjustments.${index}.installmentPlan.upfront`, event.target.value);
};

class AddImmediateAdjustmentsModal extends Component {

	state = {
		unpaidInvoicesState: UNPAID_INVOICES_STATE.NOT_LOADED,
		showAlertMessage: false,
		alertMessage: '',
		showInstallmentPlansPrice: false,
		installmentPlanSize: 0,
		errors:'',
		isSubmitting:false,
		showInstallmentPlanToggle: false,
		accountInstallmentPlans: '',
		showDueDate: false
	};

	componentDidMount() {
		this.props.getAdjustmentTypes();
		this.props.getAccountServicelines(this.props.accountInfo.id);
		if (this.state.unpaidInvoicesState === UNPAID_INVOICES_STATE.NOT_LOADED) {
			this.setState({
					unpaidInvoicesState: UNPAID_INVOICES_STATE.LOADING
				},
				() => this.props.getUnpaidInvoices(this.props.accountInfo.id)
					.then(() => this.setState({unpaidInvoicesState: UNPAID_INVOICES_STATE.LOADED})));
		}
		this.props.getInstallmentPlans(this.props.accountInfo.id).then((installmentPlans) => {
			if(installmentPlans && !isEmpty(installmentPlans)){
				installmentPlans.map(installmentPlan => {
					if(installmentPlan.balance > 0){
						this.setState({
							showInstallmentPlanToggle: true,
						});
					}
				})
			}
		});
	}

	componentWillUnmount() {
		this.props.emptyAdjustmentTypes();
		this.props.emptyAccountServicelines();
	}

	formattedLabel = (itemId) => {
		const { adjustmentTypesData } = this.props;

		let adjustmentType = adjustmentTypesData.find((adjustment) => adjustment.id === Number(itemId));

		if (adjustmentType && adjustmentType.type === ADJUSTMENT_TYPES.CREDIT) {
			return 'Credit Amount';
		} else {
			return 'Amount';
		}
	};

	showInstallmentPlans = (itemId) => {
		const { adjustmentTypesData } = this.props;
		let adjustmentType = adjustmentTypesData.find((adjustment) => adjustment.id === Number(itemId));
		if(adjustmentType != undefined && !isEmpty(adjustmentType.installmentPlans)){
			return true;
		}
		return false;
	};

	getInstallmentPlans = (itemId,amount) => {
		const { adjustmentTypesData } = this.props;
		let adjustmentType = adjustmentTypesData.find((adjustment) => adjustment.id === Number(itemId));
		let installmentPlans = [];
		if(adjustmentType != undefined && !isEmpty(adjustmentType.installmentPlans)){
			adjustmentType.installmentPlans.map((installmentPlan) => {
				installmentPlans.push({
					id: installmentPlan.id,
					itemId: installmentPlan.itemId,
					description: installmentPlan.description,
					months: installmentPlan.months,
					maxMonths: installmentPlan.months,
					name: installmentPlan.name,
					upfrontPercentage: installmentPlan.upfrontPercentage,
					upfront:amount?this.calculateMinimum(amount, installmentPlan.upfrontPercentage):0
				})
			});
		}
		return installmentPlans;
	};


	sortArray = (property) => {

		return function(a, b) {

			if (a[property] < b[property]) {
				return -1;
			}
			else if (a[property] > b[property]) {
				return 1;
			}
			else {
				return 0;
			}
		}
	}

	sumFunction = (previousValue, adjustment) => {
		const amount = adjustment.adjustmentType === ADJUSTMENT_TYPES.CREDIT ?
			-Math.abs(adjustment.amount) : Math.abs(adjustment.amount);
		return previousValue + amount;
	}

	calculateTaxes = (values, setFieldValue) => {
		this.setState({errors:''})
		values.adjustments.map((adjustment)=>{
			if((adjustment.installmentPlanSelected&&adjustment.installmentTypeSelected)&&(adjustment.installmentPlan.months<1||adjustment.installmentPlan.months>adjustment.installmentPlan.maxMonths)){
				this.setState({errors:'Fill Up the details Correctly'})
			}
			else if((adjustment.installmentPlanSelected&&adjustment.installmentTypeSelected)&&(Number(adjustment.installmentPlan.upfront)<this.calculateMinimum(adjustment.amount, adjustment.installmentPlan.upfrontPercentage)||Number(adjustment.installmentPlan.upfront)>Number(adjustment.amount))){
				this.setState({errors:'Fill Up the details Correctly'})
			}
		})



		const payload = {
			locationId: this.props.currentLocationId,
			taxationItems: values.adjustments
		}
		let installTotal = 0;
		!this.state.errors&&values.adjustments.map((adjustment, index) => {
			if(adjustment.installmentPlan.upfront&& Number(adjustment.installmentPlan.upfront) >= 0){
				installTotal += (Number(adjustment.amount) - Number(adjustment.installmentPlan.upfront));
			}
		})
		this.props.calculateTaxes(this.props.accountInfo.id, payload).then((response) => {

			const subtotal = response.data && response.data.success ?
				response.data.taxData.subtotal : values.adjustments.reduce(this.sumFunction, 0);
			const taxes = response.data && response.data.success ?
				response.data.taxData.taxes : 0;
			const total = response.data && response.data.success ?
				response.data.taxData.total : subtotal;

			setFieldValue("subtotal", subtotal);
			setFieldValue("installmentPlansTotal", installTotal);
			setFieldValue("taxes", taxes);
			setFieldValue("total", total - installTotal);
		});
	}

	handlePaymentOptions = (type, values, setFieldValue) => {
		if (type === PAYMENT_OPTION.SPECIFIC_INVOICES) {
			setFieldValue('selectedInvoices', []);
		} else if (type === PAYMENT_OPTION.INSTALLMENT_PLAN) {
			delete values.selectedInvoices;
			setFieldValue('selectedInstallmentPlan', '');
			this.setState({loadingInstallmentPlans: true});
			this.props.getInstallmentPlans(this.props.accountInfo.id).then((response) => {
				this.setState({accountInstallmentPlans: response, loadingInstallmentPlans: false});
			});
		} else {
			delete values.selectedInvoices;
			delete values.selectedInstallmentPlan;
			delete values.selectedInstallmentPlanId;
		}

		setFieldValue('paymentOption', type);
	};


	handleAdjustmentsChange = (values, formProps,index) => {
		if (this.searchChangeTimeout) {
			clearTimeout(this.searchChangeTimeout);
		}

		formProps.validateForm(values).then(errors => {
			if (!errors.adjustments||!errors.adjustments[index]) {
				this.searchChangeTimeout = setTimeout(() => {
					this.calculateTaxes(values, formProps.setFieldValue);
				}, 1000);
			}
		}).catch(err => {
		});
	}

	handleAdjustmentItemTypeChange = (e, formProps, index, field) => {
		formProps.handleChange(e);
		const adjustments = [...formProps.values.adjustments];
		const adjustment = {...adjustments[index]};
		adjustment[`${field}`] = e.target.value;
		// if(e.target.value==''){
		formProps.setFieldValue(`adjustments.${index}.amount`,0);
		formProps.setFieldValue(`adjustments.${index}.installmentPlanSelected`, false);
		formProps.setFieldValue(`adjustments.${index}.installmentTypeSelected`, false);
		formProps.setFieldValue(`adjustments.${index}.installmentPlan.upfront`, 0);
		adjustment.amount=0;
		adjustment.installmentPlanSelected=false;
		adjustment.installmentTypeSelected=false;
		adjustment.installmentPlan={description: "",id: "",itemId: "",maxMonths: "",months: "",name: "",upfront: "0",upfrontPercentage: 0
		}
		// this.setState({showInstallmentPlans})
		// }
		adjustments[index] = adjustment;
		const newValues = {...formProps.values, adjustments: adjustments};
		let showInstallmentPlansPrice=false;
		newValues.adjustments.map((adjustment)=>{
			if(adjustment.installmentPlanSelected){
				showInstallmentPlansPrice=true;
			}
		})
		this.setState({
			showInstallmentPlansPrice: showInstallmentPlansPrice
		})
		// this.handleAdjustmentsChange(newValues, formProps,index);
		this.calculateTaxes(newValues, formProps.setFieldValue);
	}
	handleValueChange = (e, formProps, index, field) => {
		formProps.handleChange(e);
		const adjustments = [...formProps.values.adjustments];
		const adjustment = {...adjustments[index]};
		adjustment[`${field}`] = e.target.value;
		if(e.target.value==''){
			adjustment.amount=0;
			adjustment.serviceLineId=0;
		}
		adjustments[index] = adjustment;
		const newValues = {...formProps.values, adjustments: adjustments};
		this.handleAdjustmentsChange(newValues, formProps,index);
	}

	handleInstallmentPlanValueChange = (e, values, formProps, index, setFieldValue) => {
		setFieldValue(`adjustments.${index}.installmentPlan.upfront`, e.target.value);
		let installmentPlansTotal = 0;
		values.adjustments.map(adjustment => {
			if(adjustment.installmentPlan.upfront != undefined){
				installmentPlansTotal = installmentPlansTotal + Math.abs(adjustment.installmentPlan.upfront);
			}
		})
		setFieldValue("installmentPlansTotal", installmentPlansTotal);
		this.handleAdjustmentsChange(values, formProps);
	}

	calculateInstallmentPlanTotal = (values, setFieldValue) => {
		this.asyncInstallmentPlanTotal(values, setFieldValue);
	};

	asyncInstallmentPlanTotal = debounce((values, setFieldValue) => {
		this.calculateTaxes(values, setFieldValue)
	}, 1000);




	handleInstallmentPlanShow = (index, formProps, setFieldValue) => {
		const adjustments = [...formProps.values.adjustments];
		const adjustment = {...adjustments[index]};
		let showInstallmentPlansPrice=false;
		let installmentPlanSelected = !adjustment.installmentPlanSelected;
		setFieldValue(`adjustments.${index}.installmentPlanSelected`, installmentPlanSelected);
		setFieldValue(`adjustments.${index}.installmentTypeSelected`, false);
		// if(installmentPlanSelected == false){
		setFieldValue(`adjustments.${index}.installmentPlan`, {id: '', itemId: '', description: '', months: '',
			maxMonths: '', upfront: 0, upfrontPercentage: ''});
		// }
		// else{
		// 	setFieldValue(`adjustments.${index}.installmentPlan`, {id: '', itemId: '', description: '', months: '',
		// 		maxMonths: '', upfront: 0, upfrontPercentage: ''});
		// }
		formProps.values.adjustments[index].installmentPlanSelected=installmentPlanSelected;
		formProps.values.adjustments[index].installmentTypeSelected=false;
		formProps.values.adjustments[index].installmentPlan={id: '', itemId: '', description: '', months: '',
			maxMonths: '', upfront: 0, upfrontPercentage: ''};
		formProps.values.adjustments.map((adjustment)=>{
			if(adjustment.installmentPlanSelected){
				showInstallmentPlansPrice=true;
			}
		})
		this.setState({
			showInstallmentPlansPrice: showInstallmentPlansPrice
		})
		this.calculateInstallmentPlanTotal(formProps.values,setFieldValue);
	}

	handleInstallmentPlanChange = (e, formProps, index, field, adjustmentIds, setFieldValue) => {
		e.target.value!==''&&this.setState({showAlertMessage:false,alertMessage:''})

		const adjustments = [...formProps.values.adjustments];
		const adjustment = {...adjustments[index]};
		let installmentTypeSelected =e.target.value!==''?true:false;
		setFieldValue(`adjustments.${index}.installmentTypeSelected`, installmentTypeSelected);
		let installmentPlans = this.getInstallmentPlans(adjustmentIds,adjustment.amount);
		let installmentPlan = ''
		if(installmentPlans != undefined && !isEmpty(installmentPlans)&&e.target.value!==''){
			installmentPlans.map(plan => {
				if(plan.id == e.target.value){
					installmentPlan = plan;
					this.setState({
						showInstallmentPlansPrice: true
					})
				}
			})
		}
		if(!e.target.value){
			installmentPlan={
				id: '',
				itemId: '',
				description: '',
				months: '',
				maxMonths: '',
				upfront: 0,
				upfrontPercentage: '',
			}
		}
		const props=formProps.values;
		props.adjustments.map((ad)=>{
			if(ad.id===adjustment.id){
				ad.installmentPlan=installmentPlan;
			}

		})
		// props.adjustments[props.adjustments.length-1].installmentPlan=installmentPlan;
		setFieldValue(`adjustments.${index}.installmentPlan`, installmentPlan);
		this.calculateInstallmentPlanTotal(formProps.values,setFieldValue);

	}

	handleAdjustmentTypeChange = (formProps, index, adjustmentType) => {
		formProps.setFieldValue(`adjustments.${index}.itemId`, 0);
		formProps.setFieldValue(`adjustments.${index}.amount`, 0);
		formProps.setFieldValue(`adjustments.${index}.adjustmentType`, adjustmentType);
		formProps.setFieldValue(`adjustments.${index}.installmentPlanSelected`, false);
		formProps.setFieldValue(`adjustments.${index}.installmentTypeSelected`, false);
		if (get(formProps.errors, `adjustments.${index}.itemId`)) {
			formProps.setFieldError(`adjustments.${index}.itemId`, false);
		}
		if (get(formProps.touched, `adjustments.${index}.itemId`)) {
			const adjustments = [...formProps.touched.adjustments];
			adjustments[index].itemId = false;
			formProps.setTouched({...formProps.touched, adjustments: adjustments});
		}
		formProps.values.adjustments[index].adjustmentType=adjustmentType;
		formProps.values.adjustments[index].amount=0;
		formProps.values.adjustments[index].itemId=0;
		formProps.values.adjustments[index].installmentTypeSelected=false;
		formProps.values.adjustments[index].installmentPlanSelected=false;
		formProps.values.adjustments[index].installmentPlan={description: "",
			id: "",
			itemId: "",
			maxMonths: "",
			months: "",
			name: "",
			upfront: "0",
			upfrontPercentage: 0};
		let showInstallmentPlansPrice=false;
		formProps.values.adjustments.map((adjustment)=>{
			if(adjustment.installmentPlanSelected){
				showInstallmentPlansPrice=true;
			}
		})
		this.setState({showInstallmentPlansPrice:showInstallmentPlansPrice})
		this.calculateTaxes(formProps.values, formProps.setFieldValue);
		this.displayDueDate(formProps.values.adjustments, formProps.setFieldValue, formProps.values);
	}

	displayDueDate = (adjustments, setFieldValue, values) => {
		for(let i = 0; i < adjustments.length; i++){
			if(adjustments[i].adjustmentType === ADJUSTMENT_TYPES.ADJ_CHARGES){
				this.setState({
					showDueDate: true
				});
				if(values.dueDate == undefined) {
					setFieldValue("dueDate", "INVOICE_CREATION");
				}
				return;
			}
		}
		delete values.dueDate;
		this.setState({
			showDueDate: false
		});
	}

	handleRemoveAdjustment = (formProps, remove, index) => {
		let newValues = {...formProps.values};
		newValues.adjustments.splice(index, 1);
		this.handleAdjustmentsChange(newValues, formProps);
	}


	calculateMonthly = (amount, upfront, months) => {
		let estMonthly = 0;
		if(amount != undefined && upfront != undefined && months != undefined){
			estMonthly = (amount - upfront)/months;
		}
		return estMonthly.toFixed(2);
	}

	calculateMinimum = (amount, percent) => {
		let estMin = 0;
		if(amount != undefined && (percent != undefined && percent != 0)){
			estMin = amount * (percent/100);
		}
		return estMin.toFixed(2);
	}

	formSubmit = (values, actions) => {
		this.setState({showAlertMessage: false, alertMessage: '',});
		values.adjustments.map((adjustment) => {
			if (adjustment.installmentPlanSelected && !adjustment.installmentTypeSelected) {
				this.setState({
					showAlertMessage: true,
					alertMessage: 'Select the Installment Plan',
					errors: 'Fill Up the details Correctly'
				});
			}

			if ((adjustment.installmentPlanSelected && adjustment.installmentTypeSelected) && (adjustment.installmentPlan.months < 1 || adjustment.installmentPlan.months > adjustment.installmentPlan.maxMonths)) {
				this.setState({errors: 'Fill Up the details Correctly'})
			} else if ((adjustment.installmentPlanSelected && adjustment.installmentTypeSelected) && (Number(adjustment.installmentPlan.upfront) < this.calculateMinimum(adjustment.amount, adjustment.installmentPlan.upfrontPercentage) || Number(adjustment.installmentPlan.upfront) > Number(adjustment.amount))) {
				this.setState({errors: 'Fill Up the details Correctly'})
			} else {
				adjustment.installmentPlan["estimatedMonthly"] = this.calculateMonthly(adjustment.amount,
					adjustment.installmentPlan.upfront, adjustment.installmentPlan.months);
				// this.setState({errors:''})
			}
		})
		!this.state.errors && this.setState({isSubmitting: true})
		!this.state.errors && this.props.createImmediateAdjustments(this.props.accountInfo.id, values).then((response) => {
			if (response.data.success) {
				if((values.paymentOption == "SPECIFIC_INVOICES" || values.paymentOption == "ALL_UNPAID_INVOICES"
					|| values.paymentOption == "INSTALLMENT_PLAN") && values.total < 0){
					const { currentLocationId, accountInfo} = this.props;

					const data = {
						locationId: currentLocationId,
						selectedInvoices: values.selectedInvoices,
						paymentAmount: values.total * -1,
						paymentOption: values.paymentOption,
						accountInfo: accountInfo
					};
					if (values.selectedInstallmentPlan) {
						data["selectedInstallmentPlan"] = values.selectedInstallmentPlan[0] ? values.selectedInstallmentPlan[0].id : '';
						if(values.selectedInstallmentPlan[0] && values.selectedInstallmentPlan[0].balance < (values.total * -1)){
							data["paymentAmount"] = values.selectedInstallmentPlan[0].balance;
						}
					}

					this.props.paymentByCredit(accountInfo.id, data).then((response) => {
						if (response.success) {
							this.closeModal();
							this.setState({isSubmitting: false})
						} else {
							toastr.error("There was an issue crediting the account", {position: 'top-center' });
						}
					}).catch(e => {
						console.error(e);
						toastr.error("There was an issue crediting the account", {position: 'top-center' });
					});
				}else {
					this.closeModal();
					this.setState({isSubmitting: false})
				}
			} else {
				actions.setSubmitting(false);
				this.setState({
					showAlertMessage: true,
					alertMessage: 'Fill the Data correctly',
					isSubmitting: false
				});
			}
		})
	};

	handleDueDate = (e, setFieldValue) => {
		setFieldValue(`dueDate`, e.target.value);
	}

	closeModal = () => {
		this.props.hideModal();

		if (this.props.modalProps.onCloseCallback) {
			this.props.modalProps.onCloseCallback();
		}
	};

	render() {
		const { showAlertMessage, alertMessage, showInstallmentPlanToggle, accountInstallmentPlans, loadingInstallmentPlans, showDueDate} = this.state;
		const { modalProps, adjustmentTypesData, adjustmentServicelinesData, calculateTaxesLoading, unpaidInvoicesData, loadingInvoices } = this.props;

		adjustmentTypesData.sort(this.sortArray("description"));

		return (
			<Fragment>
				<div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<Formik
						initialValues={{
							adjustments: [{
								id: generateId(),
								adjustmentType: ADJUSTMENT_TYPES.CREDIT,
								amount: 0,
								itemId: 0,
								serviceLineId: '',
								installmentPlanSelected: false,
								installmentTypeSelected: false,
								installmentPlan: {
									id: '',
									itemId: '',
									description: '',
									months: '',
									maxMonths: '',
									upfront: 0,
									upfrontPercentage: '',
									estimatedMonthly: 0
								},
							}],
							locationId: this.props.currentLocationId,
							note: '',
							subtotal: 0,
							taxes: 0,
							installmentPlansTotal: 0,
							total: 0,
							paymentOption: PAYMENT_OPTION.ALL_UNPAID_INVOICES,
						}}
						validationSchema={AdjustmentsSchema}
						onSubmit={this.formSubmit}
						validateOnChange={false}
						render={(props) => {

							const {
								setFieldValue,
								setFieldError,
								setTouched,
								handleChange,
								handleSubmit,
								handleBlur,
								values,
								errors,
								touched,
								isSubmitting,
								isValid,
								dirty,
							} = props;

							return (
								<form onSubmit={handleSubmit}>
									<div className="modal-dialog modal-lg">
										<div className="modal-content">

											<div className="modal-header">
												<h5 className="modal-title">
													Add Immediate Adjustments
												</h5>
												<button onClick={this.props.hideModal} type="button" className="close">
													<span aria-hidden="true">&times;</span>
												</button>
											</div>

											<div className="modal-body form-horizontal">

												{showAlertMessage === true && (
													<div className="alert alert-inline alert-danger alert-dismissible">
														<p className="mb-0">{alertMessage}</p>
													</div>
												)}
												{showDueDate &&
												<div className="form-row form-group select-align-text-left">
													<div className="col-form-label">
														<span className="ml-2">Due Date</span>
													</div>
													<div className="col-lg-4">
														<select
															className={'form-control customer-adjustment-type'}
															value={values.dueDate}
															onChange={(e) => this.handleDueDate(e, setFieldValue)}
															onBlur={handleBlur}
															name={`dueDate`}
															disabled={calculateTaxesLoading}>
															<option value={"INVOICE_CREATION"}>Invoice Creation</option>
															<option value={"ACCOUNT_TERMS"}>Account Terms</option>
														</select>
													</div>
												</div>
												}
												<FieldArray
													name="adjustments"
													render={({remove, push}) => <>

														{values.adjustments.map((adjustment, index) => <>
															<div className="form-row d-flex justify-content align-items-center">
																<span className="h-check" style={{marginLeft: 'initial'}}>
																	<div className="form-check checkbox-slider checkbox-slider--b-flat">
																		<label className="mb-0">

																			<input
																				name={`adjustments.${index}.adjustmentType`}
																				type="radio"
																				onChange={() => { this.handleAdjustmentTypeChange(props, index, ADJUSTMENT_TYPES.CREDIT)}}
																				checked={adjustment.adjustmentType === ADJUSTMENT_TYPES.CREDIT}
																				disabled={calculateTaxesLoading}
																			/>
																			<span className="ml-2">Credit</span>
																		</label>
																	</div>
																</span>

																<span className="h-check ml-3">
																	<div className="form-check checkbox-slider checkbox-slider--b-flat">
																		<label className="mb-0">
																			<input
																				name={`adjustments.${index}.adjustmentType`}
																				type="radio"
																				onChange={() => { this.handleAdjustmentTypeChange(props, index, ADJUSTMENT_TYPES.ADJ_CHARGES)}}
																				checked={adjustment.adjustmentType === ADJUSTMENT_TYPES.ADJ_CHARGES}
																				disabled={calculateTaxesLoading}
																			/>
																			<span className="ml-2">Charge</span>
																		</label>
																	</div>
																</span>

																{(index !== 0 || values.adjustments.length > 1) && (
																	<button
																		onClick={() => this.handleRemoveAdjustment(props, remove, index)}
																		className="btn btn-sm btn-danger btn-discount-remove ml-2"
																		type="button"
																		disabled={calculateTaxesLoading}
																	>
																		Remove
																	</button>
																)}

															</div>
															<div className="form-row" key={adjustment.id}>

																<div className="form-group col-lg-4">
																	<label>Adjustment Type</label>
																	<select
																		className={'form-control customer-adjustment-type' + (
																			get(errors, `adjustments.${index}.itemId`) &&
																			get(touched, `adjustments.${index}.itemId`)
																				? ' is-invalid'
																				: ''
																		)}
																		placeholder="Please select Item"
																		value={adjustment.itemId}
																		onChange={e => {this.handleAdjustmentItemTypeChange(e, props, index, 'itemId');}}
																		onBlur={handleBlur}
																		name={`adjustments.${index}.itemId`}
																		disabled={calculateTaxesLoading}
																	>
																		<option value="">Please select Item</option>
																		{adjustmentTypesData.map((type) => (
																			((adjustment.adjustmentType === '' || type.type === adjustment.adjustmentType) &&
																				<option value={type.id} key={type.id}>
																					{type.description}
																				</option>
																			)
																		))}
																	</select>
																	{get(errors, `adjustments.${index}.itemId`) && (
																		get(touched, `adjustments.${index}.itemId`) ||
																		get(touched, `adjustments.${index}.amount`)
																	) && (
																		<div className="invalid-feedback">
																			{get(errors, `adjustments.${index}.itemId`)}
																		</div>
																	)}
																</div>

																<div className="form-group col-lg-4">
																	<label>Serviceline (Optional)</label>
																	<select
																		className={'form-control customer-adjustment-serviceline' + (
																			get(errors, `adjustments.${index}.serviceLineId`) &&
																			get(touched, `adjustments.${index}.serviceLineId`)
																				? ' is-invalid'
																				: ''
																		)}
																		placeholder="Please select serviceline"
																		value={adjustment.serviceLineId}
																		onChange={e => {this.handleValueChange(e, props, index, 'serviceLineId')}}
																		onBlur={handleBlur}
																		name={`adjustments.${index}.serviceLineId`}
																		disabled={calculateTaxesLoading}
																	>
																		<option value="">Please select serviceline</option>
																		{adjustmentServicelinesData.map((serviceline) => (
																			<option value={serviceline.id} key={serviceline.id}>
																				{serviceline.number}
																			</option>
																		))}
																	</select>
																</div>

																<div className="form-group col-lg-4">
																	<label>{this.formattedLabel(adjustment.itemId)}</label>
																	<div className="input-group">
																		<div className="input-group-prepend">
																			<div className="input-group-text">$</div>
																		</div>
																		<input
																			type="text"
																			className={'form-control' + (
																				get(errors, `adjustments.${index}.amount`) &&
																				get(touched, `adjustments.${index}.amount`)
																					? ' is-invalid'
																					: ''
																			)}
																			placeholder="Amount"
																			value={adjustment.amount}
																			onChange={e => {this.handleValueChange(e, props, index,'amount')}}
																			name={`adjustments.${index}.amount`}
																			disabled={calculateTaxesLoading}
																		/>
																		{get(errors, `adjustments.${index}.amount`) &&
																		get(touched, `adjustments.${index}.amount`) && (
																			<div className="invalid-feedback">
																				{get(errors, `adjustments.${index}.amount`)}
																			</div>
																		)}
																	</div>
																</div>

															</div>
															{this.showInstallmentPlans(adjustment.itemId) &&
															<div>
																<div
																	className={"form-row " + adjustment.showInstallmentPlan ? " " : "form-row-double"}
																	key={index}>
																	<div className="form-group">
																		<div
																			className="form-check checkbox-slider checkbox-slider--b-flat">
																			<label>
																				<input
																					name="includeSignup"
																					type="checkbox"
																					onChange={() => {this.handleInstallmentPlanShow(index,props, setFieldValue)}}
																					onBlur={handleBlur}
																					checked={adjustment.installmentPlanSelected}
																				/>
																				<span>&nbsp;</span>
																			</label>
																			<label>Installment Plan</label>
																		</div>
																	</div>
																</div>
																{adjustment.installmentPlanSelected &&
																<div className="form-row form-row-double"
																	 key={adjustment.id}>
																	<div className="form-group col-lg-4">
																		<label>Installment Plan</label>
																		<select
																			className={'form-control customer-adjustment-type' + (
																				get(errors, `adjustments.${index}.installmentPlan.id`) &&
																				get(touched, `adjustments.${index}.installmentPlan.id`)
																					? ' is-invalid'
																					: ''
																			)}
																			placeholder="Please select Installment Plan"
																			value={adjustment.installmentPlan.id}
																			onChange={e => {
																				this.handleInstallmentPlanChange(e, props, index, 'installmentPlan', adjustment.itemId, setFieldValue)
																			}}
																			onBlur={handleBlur}
																			name={`adjustments.${index}.installmentPlan`}
																			disabled={calculateTaxesLoading}
																		>
																			<option value="">Please select Item</option>
																			{this.getInstallmentPlans(adjustment.itemId).map((installmentPlan) => (
																				<option value={installmentPlan.id} key={installmentPlan.id}>
																					{installmentPlan.description}
																				</option>
																			))}
																		</select>
																		{get(errors, `adjustments.${index}.installmentPlan.id`) && (
																			get(touched, `adjustments.${index}.installmentPlan.id`)
																		) && (
																			<div className="invalid-feedback">
																				{get(errors, `adjustments.${index}.installmentPlan.id`)}
																			</div>
																		)}
																	</div>
																	<div className="form-group col-lg-4">
																		<label>Months</label>
																		<NumericInput
																			style={{
																				input: {
																					height: "calc(1.5em + 0.75rem + 2px)",
																					width: '250px'
																				}
																			}}
																			min={1}
																			max={adjustment.installmentPlan.maxMonths}
																			value={adjustment.installmentPlan.months}
																			disabled={!adjustment.installmentTypeSelected}
																			onChange={(e) => {
																				setFieldValue(`adjustments.${index}.installmentPlan.months`, e);
																			}}/>
																		{((adjustment.installmentPlanSelected&&adjustment.installmentTypeSelected)&&(adjustment.installmentPlan.months<1||adjustment.installmentPlan.months>adjustment.installmentPlan.maxMonths))&&<span className="invalid-feedback"
																																																																		  style={{fontSize: '11px'}}>{adjustment.installmentPlan.months<1?'Cannot be less than 1':`Cannot be greater than ${adjustment.installmentPlan.maxMonths}`}</span>}
																		{adjustment.installmentTypeSelected&&<span className="text-muted"
																												   style={{fontSize: '11px'}}>{"Max " + adjustment.installmentPlan.maxMonths + " months"}</span>}
																	</div>

																	<div className="form-group col-lg-4">
																		<label>Upfront</label>
																		<div className="input-group">
																			<div className="input-group-prepend">
																				<div className="input-group-text">$
																				</div>
																			</div>
																			<input
																				type="text"
																				className={'form-control' + (
																					get(errors, `adjustments.${index}.installmentPlan.upfront`) &&
																					get(touched, `adjustments.${index}.installmentPlan.upfront`)
																						? ' is-invalid'
																						: ''
																				)}
																				placeholder="0.00"
																				value={adjustment.installmentPlan.upfront}
																				autocomplete="off"
																				onChange={e => {
																					testingHandle(e, setFieldValue, values, index);
																				}}
																				onKeyUp={() => this.calculateInstallmentPlanTotal(values, setFieldValue)}
																				name={`adjustments.${index}.installmentPlan.upfront`}
																				disabled={!adjustment.installmentTypeSelected}
																			/>
																		</div>
																		{
																			(Number(adjustment.installmentPlan.upfront)<this.calculateMinimum(adjustment.amount, adjustment.installmentPlan.upfrontPercentage)||Number(adjustment.installmentPlan.upfront)>Number(adjustment.amount))&&<span className="invalid-feedback"
																																																																							 style={{fontSize: '11px'}}>{
																				Number(adjustment.installmentPlan.upfront)<this.calculateMinimum(adjustment.amount, adjustment.installmentPlan.upfrontPercentage)?`Must be greater than or equal to ${this.calculateMinimum(adjustment.amount, adjustment.installmentPlan.upfrontPercentage)}`:`Must be less then ${adjustment.amount}`
																			}</span>
																		}
																		{adjustment.installmentTypeSelected&&<div className="row">
																			<span className="text-muted col-lg-6"
																				  style={{fontSize: '11px'}}>{"Est. Monthly $ " + (this.calculateMonthly(adjustment.amount, adjustment.installmentPlan.upfront,
																				adjustment.installmentPlan.months, index, setFieldValue))}
																			</span>
																			<span className="text-muted col-lg-6"
																				  style={{fontSize: '11px'}}>{"Min Required $ " + (this.calculateMinimum(adjustment.amount, adjustment.installmentPlan.upfrontPercentage))}
																			</span>
																		</div>}
																	</div>

																</div>
																}
															</div>
															}
														</> )}

														<div className="clearfix">
															<button
																onClick={() =>
																	push({
																		id: generateId(),
																		adjustmentType: ADJUSTMENT_TYPES.CREDIT,
																		amount: 0,
																		itemId: 0,
																		serviceLineId: '',
																		installmentPlanSelected: false,
																		installmentTypeSelected:false,
																		installmentPlan: {
																			id: '',
																			itemId: '',
																			description: '',
																			months: '',
																			maxMonths: '',
																			upfront: 0,
																			upfrontPercentage: ''
																		}
																	})}
																className="btn btn-sm btn-outline-secondary btn-discount-add float-right"
																type="button"
																disabled={calculateTaxesLoading}
															>
																Add Another
															</button>
														</div>

													</> }
												/>

												<div className="form-section row bg-gray-200 mt-4 mb-2">

													<div className="col-5 col-sm-7 col-lg-8">
														<p className="text-right mb-0">Subtotal:</p>
													</div>
													<div className="col-7 col-sm-5 col-lg-4">
														<p className="font-weight-bold text-right mb-0">
															{calculateTaxesLoading ?
																<i className="fas fa-fw fa-spin fa-spinner"/>
																: ('$' + values.subtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
															}
														</p>
													</div>

													<div className="w-100" />

													<div className="col-5 col-sm-7 col-lg-8">
														<p className="text-right mb-0">Taxes:</p>
													</div>
													<div className="col-7 col-sm-5 col-lg-4">
														<p className="font-weight-bold text-right mb-0">
															{calculateTaxesLoading ?
																<i className="fas fa-fw fa-spin fa-spinner"/>
																: ('$' + values.taxes.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
															}
														</p>
													</div>
													{this.state.showInstallmentPlansPrice  &&
													<>
														<div className="col-5 col-sm-7 col-lg-8">
															<p className="text-right mb-0">Installment Plans:</p>
														</div>
														<div className="col-7 col-sm-5 col-lg-4">
															<p className="font-weight-bold text-right mb-0">
																{calculateTaxesLoading ?
																	<i className="fas fa-fw fa-spin fa-spinner"/>
																	: ('$' + values.installmentPlansTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
																}
															</p>
														</div>
													</>
													}

													<div className="w-100" />

													<div className="col-5 col-sm-7 col-lg-8">
														<p className="text-xl text-right mb-0">Total:</p>
													</div>
													<div className="col-7 col-sm-5 col-lg-4">
														<p className="font-weight-bold text-xl text-right mb-0">
															{calculateTaxesLoading ?
																<i className="fas fa-fw fa-spin fa-spinner"/>
																: ('$' + values.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
															}
														</p>
													</div>

												</div>


												{/*credit radio options*/}

												{ values.total < 0.00 && !this.NOT_LOADED && !isEmpty(unpaidInvoicesData)
													?
													<div className="form-section">
														<div className="form-check checkbox-slider checkbox-slider--b-flat">
															<label>
																<input
																	type="radio"
																	name="paymentOption"
																	disabled={isSubmitting}
																	onChange={() =>
																		this.handlePaymentOptions(
																			PAYMENT_OPTION.ALL_UNPAID_INVOICES,
																			values,
																			setFieldValue
																		)}
																	checked={values.paymentOption === PAYMENT_OPTION.ALL_UNPAID_INVOICES}
																/>
																<span>
																Apply credit to all unpaid invoices
																<p className="text-muted text-xs mb-0">
																	Apply credit amount to all unpaid invoice balance
																</p>
															</span>
															</label>
														</div>
														<div className="form-check checkbox-slider checkbox-slider--b-flat">
															<label>
																<input
																	type="radio"
																	name="paymentOption"
																	disabled={isSubmitting}
																	onChange={() =>
																		this.handlePaymentOptions(
																			PAYMENT_OPTION.SPECIFIC_INVOICES,
																			values,
																			setFieldValue
																		)}
																	checked={values.paymentOption === PAYMENT_OPTION.SPECIFIC_INVOICES}
																/>
																<span>
																Apply credit to specific invoices
																<p className="text-muted text-xs mb-0">
																	Choose to pay invoices, then pay as Account Credit
																</p>
															</span>
															</label>
														</div>
														{values.paymentOption === PAYMENT_OPTION.SPECIFIC_INVOICES && (
															<PayNowTable
																isSubmitting={isSubmitting}
																invoicesData={unpaidInvoicesData}
																setFieldValue={setFieldValue}
																values={values}
																loadingInvoices={loadingInvoices}
															/>
														)}
														{showInstallmentPlanToggle &&
														<div
															className="form-check checkbox-slider checkbox-slider--b-flat">
															<label>
																<input
																	type="radio"
																	name="paymentOption"
																	disabled={isSubmitting}
																	onChange={() =>
																		this.handlePaymentOptions(
																			PAYMENT_OPTION.INSTALLMENT_PLAN,
																			values,
																			setFieldValue
																		)}
																	checked={values.paymentOption === PAYMENT_OPTION.INSTALLMENT_PLAN}
																/>
																<span>
                                                                    Installment Plan
                                                                    <p className="text-muted text-xs mb-0">
                                                                        Pay off installment plan
                                                                    </p>
															</span>
															</label>
														</div>
														}
														{values.paymentOption === PAYMENT_OPTION.INSTALLMENT_PLAN && (
															<PayInstallmentPlanTable
																isSubmitting={isSubmitting}
																installmentPlans={accountInstallmentPlans}
																setFieldValue={setFieldValue}
																values={values}
																loadingInstallmentPlans={loadingInstallmentPlans}
															/>
														)}
														{errors.paymentOption &&
														touched.paymentOption && <div className="invalid-feedback">{errors.paymentOption}</div>}
													</div>

													:
													(
														<></>
													)}


												{/*credit radio options end*/}

												<div className="form-group mb-0">
													<label>Note</label>
													<textarea
														className={errors.note && touched.note
															? 'form-control is-invalid'
															: 'form-control'
														}
														value={values.note}
														onChange={handleChange}
														onBlur={handleBlur}
														name="note"
													/>
													{errors.note &&
													touched.note && <div className="invalid-feedback">{errors.note}</div>}
												</div>

											</div>

											<div className="modal-footer">
												<button onClick={this.props.hideModal} className="btn" type="button">
													Cancel
												</button>
												<button
													type="submit"
													className="btn btn-primary"
													disabled={this.state.isSubmitting || calculateTaxesLoading}
												>
													Add Adjustments	{this.state.isSubmitting && <i className="fas fa-fw fa-spin fa-spinner" />}
												</button>
											</div>

										</div>
									</div>
								</form>
							)}}
					/>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const calculateTaxesLoader = createLoadingSelector(['CALCULATE_TAXES']);
const invoicesLoadingSelector = createLoadingSelector(['SET_UNPAID_INVOICES']);

const mapStateToProps = (state) => {

	const accountInfo = getAccountDetails(state).accountDetails.accountInfo;
	const modalProps = getModalData(state).modalProps;
	const currentLocationId = getUser(state).currentLocation.id;
	const adjustmentTypesData = getAccountDetails(state).billing.adjustmentTypes;
	const adjustmentServicelinesData = getAccountDetails(state).billing.accountServicelines;
	const calculateTaxesLoading = calculateTaxesLoader(state);
	const unpaidInvoicesData = getAccountDetails(state).payNow.unpaidInvoices;
	const loadingInvoices = invoicesLoadingSelector(state);

	return {
		accountInfo,
		modalProps,
		currentLocationId,
		adjustmentTypesData,
		adjustmentServicelinesData,
		calculateTaxesLoading,
		unpaidInvoicesData,
		loadingInvoices,
	};
};

const mapDispatchToProps = {
	hideModal,
	getUnpaidInvoices,
	getAdjustmentTypes,
	emptyAdjustmentTypes,
	getAccountServicelines,
	emptyAccountServicelines,
	calculateTaxes,
	createImmediateAdjustments,
	getInstallmentPlans,
	paymentByCredit
};

export default connect(mapStateToProps, mapDispatchToProps)(AddImmediateAdjustmentsModal);
