import React, {Component, Fragment} from 'react';
import {Formik, Form} from 'formik';

import {connect} from 'react-redux';
import {hideModal} from 'src/actions/modal.actions'
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import {toastr} from "react-redux-toastr";
import {getModalData} from 'src/selectors'
import isEmpty from "src/utils/helpers";
import {groupStyles} from "src/utils/SelectStyles";
import Select from "react-select";
import {getMigrationJobs, uploadMigrationJob} from "src/actions/migrationJobs.actions";

const WorkflowScheme = Yup.object().shape({
    document: Yup.mixed().nullable().required('Required'),
});

class UploadMigrationJobModal extends Component {

    state = {
        isUploading: false,
        migrateWorkflows: false,
    }
    constructor(props) {
        super(props);
    }

    closeModal = () => {
        this.props.hideModal();
    };

    handleUploadMigration = (values) => {
        const formData = new FormData();
        formData.append('csvFile', values.document);
            this.props.uploadMigrationJob(formData)
            this.props.getMigrationJobs(this.props.modalProps)
            this.closeModal()

    }


    render() {
        const {
            isUploading,
        } = this.state;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} id="workflow-modal-upload" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">

                            <div className="modal-header">
                                <h5 className="modal-title">Upload Serialized Inventory</h5>
                                <button onClick={() => this.closeModal()} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <Formik
                                initialValues={{
                                    document: '',
                                    contents: ''
                                }}
                                validationSchema={WorkflowScheme}
                                onSubmit={this.handleUploadMigration}
                                render={({handleChange, handleSubmit, values, errors, touched, isSubmitting, setFieldValue}) => (
                                    <div className="modal-body">
                                        <form className="documents-panel-form" id="mainForm" onSubmit={handleSubmit} style={{marginBottom: '0px'}}>
                                            <div className="form-check checkbox-slider checkbox-slider--b-flat" style={{marginBottom: '1rem'}}>
                                                <Select
                                                    className="extra-filter-select"
                                                    styles={groupStyles}
                                                    options={[{label: "Serialized Inventory CSV file", value: "Serialized CSV"}]}
                                                    placeholder={"Migration Types"}
                                                    // value={this.state.locationStateFilter}
                                                    defaultValue={{label: "Serialized Inventory CSV file", value: "Serialized CSV"}}
                                                />
                                            </div>
                                            <Dropzone
                                                multiple={false}
                                                disabled={isUploading}
                                                accept=".csv"
                                                onDrop={async ([file]) => {
                                                    var reader = new FileReader();
                                                    setFieldValue("document", file);
                                                    reader.onload = function(e) {
                                                        setFieldValue("contents",e.target.result);
                                                    };
                                                    if(file != null) {
                                                        reader.readAsText(file);
                                                    }
                                                }}
                                            >
                                                {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject}) => (
                                                    <div {...getRootProps()}
                                                         className={"documents-panel-droparea" + (
                                                             isDragActive && isDragAccept ? " documents-panel-droparea-dragging-valid" :
                                                                 isDragActive && isDragReject ? " documents-panel-droparea-dragging-invalid" :
                                                                     !isEmpty(values.document) ? " documents-panel-droparea-accepted" :
                                                                         errors.document && touched.document ? " documents-panel-droparea-required" :
                                                                             ""
                                                         ) + (
                                                             isUploading ? " documents-panel-droparea-disabled" : ""
                                                         )}
                                                         style={{marginBottom: '0px'}}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <i className="fas fa-upload" />
                                                        <p data-text={!isEmpty(values.document) ? values.document.name : "Drag and Drop Inventory or Click to Upload"} />
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </form>
                                    </div>
                                )}
                            />
                            <div className="modal-footer">
                                <button
                                    disabled={isUploading}
                                    type="submit" form="mainForm" className="btn btn-primary"
                                    id="btn-pmnt-submit">
                                    Upload File
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    return {
        modalProps,
    };
};

const mapDispatchToProps = {
    hideModal,
    uploadMigrationJob,
    getMigrationJobs
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadMigrationJobModal);
