import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import DatePicker from "react-datepicker";

import Loader from "src/components/Loader";
import { getErrorMessage, isEmpty } from "src/utils/helpers";
import { showModal, showAccessModal } from "src/actions/modal.actions";
import {
  getAllNotifications,
  clearNotifications,
  getNotificationQueue,
  downloadNotificationQueue,
  resendNotification,
} from "src/actions/notifications.actions";
import { getUserPermission, selectNotificationsState } from "src/selectors";
import CamvioTable2, {
  CamvioTable2Context,
  LoadMore,
  DefaultDashboardSearch,
  utils,
} from "src/components/UI/CamvioTable2";
import { groupStyles } from "src/utils/SelectStyles";
import { notificationTypeIcons, notificationTypeOptions } from "../Notifications/notificationTypes";

/**
 * Actual content of the Notification Queue management page
 */
class NotificationQueueList extends Component {
  state = {
    isLoading: false, // loading data?
    offset: 0,
    pageSize: 20,
    sortField: "",
    sortDirection: "",
    itemsList: [],
    itemsTotal: 0,
    loadingSearch: true,
    noMoreResults: true,
    isDownloading: false,

    // filters
    searchTerm: "",
    notificationFromFilter: null,
    notificationToFilter: null,
    notificationFilter: null,
    notificationStatusFilter: null,
    notificationTypeFilter: null,
  };

  componentDidMount() {
    this.props.getAllNotifications();
    this.clearAndReloadList();
  }

  componentWillUnmount() {
    this.props.clearNotifications();
  }

  getTableRequest(offsetOverride, limitOverride) {
    let filters = [];
    if (this.state.searchTerm && this.state.searchTerm.trim())
      filters.push(utils.defineFilter("keyword", this.state.searchTerm.trim(), "like"));
    if (this.state.notificationFilter)
      filters.push(utils.defineFilter("notification_id", this.state.notificationFilter.value));
    if (this.state.notificationTypeFilter)
      filters.push(utils.defineFilter("type", this.state.notificationTypeFilter.value));
    if (this.state.notificationStatusFilter)
      filters.push(utils.defineFilter("status", this.state.notificationStatusFilter.value));
    if (this.state.notificationFromFilter) {
      let dt = this.state.notificationFromFilter;
      filters.push(
        utils.defineFilter("createdDate", `${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}`, "gte")
      );
    }
    if (this.state.notificationToFilter) {
      let dt = new Date(this.state.notificationToFilter.getTime() + 24 * 3600 * 1000); // plus 1 day
      filters.push(
        utils.defineFilter("createdDate", `${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}`, "lt")
      );
    }

    let sort = null;
    if (this.state.sortField && this.state.sortDirection) {
      sort = utils.defineSort(this.state.sortField, this.state.sortDirection === "asc");
    }

    return utils.defineTableRequest(
      filters,
      sort,
      offsetOverride !== undefined ? offsetOverride : this.state.offset,
      limitOverride !== undefined ? limitOverride : this.state.pageSize
    );
  }

  clearAndReloadList = () => {
    this.setState(
      {
        offset: 0,
        itemsList: [],
        loadingSearch: true,
      },
      () => this.reloadList(true)
    );
  };

  reloadList = (clearList) => {
    let tableRequest = this.getTableRequest();
    if (clearList) {
      tableRequest = this.getTableRequest(0, this.state.pageSize + this.state.offset);
    }
    this.setState({ isLoading: true, loadingSearch: true, itemsList: clearList ? [] : this.state.itemsList }, () => {
      this.props.getItems(tableRequest).then((response) => {
        if (response.data.notificationQueues && !isEmpty(response.data.notificationQueues)) {
          this.setState(
            {
              itemsList: [...this.state.itemsList, ...response.data.notificationQueues],
              itemsTotal: response.data.total || 0,
            },
            () => this.checkResultsLength()
          );
        }
        this.setState({ isLoading: false, loadingSearch: false });
      });
    });
  };

  sortList = (field, dir = null) => {
    if (field === null) dir = this.state.sortDirection === "asc" ? "desc" : "asc";

    this.setState(
      {
        sortField: field,
        sortDirection: dir,
      },
      () => this.clearAndReloadList()
    );
  };

  loadMore = () => {
    this.setState({ offset: +this.state.offset + +this.state.pageSize }, () => {
      this.reloadList(false);
    });
  };

  checkResultsLength = () => {
    if (this.state.itemsList && this.state.itemsList.length < this.state.itemsTotal) {
      this.setState({
        noMoreResults: false,
      });
    } else {
      this.setState({
        noMoreResults: true,
      });
    }
  };

  handlePageSizeChange = (value) => {
    this.setState({
      pageSize: value,
    });
  };

  setNotificationFilter = (value) => {
    this.setState(
      {
        notificationFilter: value,
      },
      () => this.clearAndReloadList()
    );
  };

  setNotificationTypeFilter = (value) => {
    this.setState(
      {
        notificationTypeFilter: value,
      },
      () => this.clearAndReloadList()
    );
  };

  setNotificationStatusFilter = (value) => {
    this.setState(
      {
        notificationStatusFilter: value,
      },
      () => this.clearAndReloadList()
    );
  };

  setDateFilter = (value, name) => {
    let newState = {};

    if (value === null) {
      newState[name] = "";
    } else {
      newState[name] = value;
    }

    this.setState(newState, () => this.clearAndReloadList());
  };

  debounceHandleInputChange = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  downloadTable = (value) => {
    const tableRequest = this.getTableRequest(0, 0);
    this.setState({
      isDownloading: true,
    });

    this.props.downloadItems(value, tableRequest).finally(() => {
      this.setState({
        isDownloading: false,
      });
    });
  };

  onDashboardAction = (action) => {
    switch (action) {
      case "SEARCH":
        this.clearAndReloadList();
        break;
      case "DOWNLOAD":
        // TODO;
        break;
      case "XLS":
        this.downloadTable("XLS");
        break;
      case "CSV":
        this.downloadTable("CSV");
        break;
      default:
        break;
    }
  };

  /**
   * Called after successful item status update on the "Edit Item status" modal
   * @param {*} item
   * @param {*} values
   */
  replaceItem = (item) => {
    let itemsList = [...this.state.itemsList];
    let idx = itemsList.findIndex((i) => item.id === i.id);

    if (idx >= 0) {
      itemsList[idx] = item;
    }

    this.setState({
      itemsList: itemsList,
    });
  };

  viewItem = (item) => {
    this.props.showModal("HTML_NOTIFICATION_MODAL", {
      notificationQueueId: item.id,
      notificationSubject: item.subject,
      notificationContent: item.text,
      notificationAttachments: item.attachments,
    });
  };

  forwardNotification = (item) => {
    this.props.showModal("FORWARD_NOTIFICATION_MODAL", {
      notificationQueue: item,
      afterSubmitted: (notificationsAdded) => {
        this.clearAndReloadList()
      },
    });
  };

  resendNotification = (item) => {
    this.props.showModal("CONFIRM_AND_ACTION_MODAL", {
      title: `Resend Notification #${item.id}`,
      message: (
        <p>
          You are about to resend <b>{item.type === "EMAIL" ? `“${item.subject}“` : `Notification #${item.id}`}</b> to{" "}
          <b>“{item.receiver}“</b>. Do you want to continue?
        </p>
      ),
      action: async () => {
        try {
          const resp = await this.props.resendNotification(item.id);
          toastr.success(`Resent notification! New notification #${resp.data.notificationQueue.id}`);
          this.clearAndReloadList()
        } catch (err) {
          toastr.error(`Failed to resend a notification #${item.id}: ${getErrorMessage(err).message}`);
        }
      },
    });
  };

  accountAccessModal = (id) => {
    this.props.showAccessModal(true);
    this.props.showModal("ACCOUNT_ACCESS_MODAL", { accountId: id });
  };

  statusToBadge = (status) => {
    if (status === "SENT") {
      return "badge-success";
    } else if (status === "FAILED") {
      return "badge-error";
    } else {
      return "badge-secondary";
    }
  };

  priorityNumToString = (priority) => {
    if (priority === 0) {
      return "Highest";
    } else if (priority === 3) {
      return "High";
    } else if (priority === 5) {
      return "Normal";
    } else if (priority === 8) {
      return "Low";
    } else if (priority === 10) {
      return "Lowest";
    }
    return `${priority}`;
  };

  render() {
    const { pageSize, itemsList, isLoading, sortField, sortDirection, isDownloading } = this.state;

    const notificationStatusOptions = [
      {
        name: "status",
        value: "PENDING",
        label: "Pending",
      },
      {
        name: "status",
        value: "IN_PROCESS",
        label: "In process",
      },
      {
        name: "status",
        value: "SENT",
        label: "Sent",
      },
      {
        name: "status",
        value: "FAILED",
        label: "Failed",
      },
      {
        name: "status",
        value: "NEW",
        label: "New",
      },
      {
        name: "status",
        value: "CANCELED",
        label: "Canceled",
      },
    ];
    const notificationOptions = (this.props.notifications || []).map((n) => {
      return {
        name: "notification_id",
        value: n.id,
        label: n.name,
      };
    });

    return (
      <Fragment>
        <CamvioTable2Context.Provider value={this.state}>
          <div className="tab-pane camvio-table-search" style={{ borderRadius: "0.5rem" }}>
            <DefaultDashboardSearch
              onAction={this.onDashboardAction}
              onInputChange={this.debounceHandleInputChange}
              disabled={isLoading || this.state.loadingSearch || isDownloading}
              inputPlaceholder="Name or Account #"
              actionButtons={{
                secondary: [
                  // defineActionButton("ADD", "New Notification", "fa-plus", !this.props.canResend)
                ],
              }}
            >
              <div className="search">
                <Select
                  className="extra-filter-select"
                  styles={groupStyles}
                  placeholder={"All Notifications"}
                  options={notificationOptions}
                  value={this.state.notificationFilter}
                  onChange={this.setNotificationFilter}
                  isClearable={true}
                  clearValue={() => this.setNotificationFilter(null)}
                />
                <span>&nbsp;&nbsp;</span>
                <Select
                  className="extra-filter-select"
                  styles={groupStyles}
                  placeholder={"All Channels"}
                  options={notificationTypeOptions}
                  value={this.state.notificationTypeFilter}
                  onChange={this.setNotificationTypeFilter}
                  isClearable={true}
                  clearValue={() => this.setNotificationTypeFilter(null)}
                />
                <span>&nbsp;&nbsp;</span>
                <Select
                  className="extra-filter-select"
                  onChange={this.setNotificationStatusFilter}
                  styles={groupStyles}
                  placeholder={"All Statuses"}
                  options={notificationStatusOptions}
                  value={this.state.notificationStatusFilter}
                  isClearable={true}
                  clearValue={() => this.setNotificationStatusFilter(null)}
                />
              </div>
              <div className="d-flex">
                <div className="form-group col">
                  <div
                    className="input-group input-group-sm date"
                    id="datetimepicker-notif-filter-1"
                    data-target-input="nearest"
                  >
                    <DatePicker
                      className="form-control datetimepicker-input"
                      selected={this.state.notificationFromFilter}
                      onChange={(value) => this.setDateFilter(value, "notificationFromFilter")}
                      placeholderText="MM/DD/YYYY"
                      name="notificationFromFilter"
                      popperPlacement="top-end"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px",
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport",
                        },
                      }}
                    />
                  </div>
                </div>

                <h6 className="dropdown-header">To</h6>

                <div className="form-group col">
                  <div
                    className="input-group input-group-sm date"
                    id="datetimepicker-notif-filter-2"
                    data-target-input="nearest"
                  >
                    <DatePicker
                      className="form-control datetimepicker-input"
                      selected={this.state.notificationToFilter}
                      onChange={(value) => this.setDateFilter(value, "notificationToFilter")}
                      placeholderText="MM/DD/YYYY"
                      name="notificationToFilter"
                      popperPlacement="top-end"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px",
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </DefaultDashboardSearch>

            {isDownloading && <Loader />}

            {!this.state.loadingSearch && isEmpty(itemsList) && (
              <div className="container">
                <p>No data found</p>
              </div>
            )}
            {!isEmpty(itemsList) && !isDownloading && (
              <CamvioTable2
                headerDefs={[
                  { name: "Channel", field: "type", sortable: false },
                  { name: "Customer", field: "accountPrimaryContact", sortable: false },
                  { name: "Date/Time", field: "created_date", sortable: false },
                  { name: "Notification", field: "notification", sortable: false },
                  { name: "Status", field: "status", sortable: false },
                  { name: "Sender", field: "sender", sortable: false },
                  { name: "Receiver", field: "receiver", sortable: false },
                  { name: "Action", field: "", sortable: false },
                ]}
                sortFunction={this.sortList}
                sortField={sortField}
                sortDirection={sortDirection}
              >
                <tbody>
                  {this.state.itemsList.map((item, index) => (
                    <tr key={`item_${item.id}`}>
                      <td>
                        <div className="flex">
                          <span className="mr-2">
                            {notificationTypeIcons[item.type] && <i className={notificationTypeIcons[item.type]}></i>}
                          </span>
                          <span>{item.type}</span>
                        </div>
                        <span className="td-subtitle">#{item.id}</span>
                      </td>
                      <td>
                        <span>{item.accountPrimaryContact?.name}</span>
                        {item.accountId && (
                          <span className="td-subtitle">
                            <button
                              className="btn btn-link mb-1 p-0 m-0"
                              style={{ margin: "0px 5px" }}
                              onClick={() => this.accountAccessModal(item.accountId)}
                            >
                              #{item.accountNumber}
                            </button>
                          </span>
                        )}
                      </td>
                      <td>
                        {item.createdDate && (
                          <span title="Created at">
                            {moment(item.createdDate).format("MM/DD/YYYY")}
                            <br />
                            {moment(item.createdDate).format("hh:mm:ss")}
                          </span>
                        )}
                      </td>
                      <td style={{ maxWidth: "10rem" }}>
                        <span className="d-block text-truncate">{item.notification?.name}</span>
                        <span className="td-subtitle">{this.priorityNumToString(item.priority)}</span>
                      </td>
                      <td>
                        <span className={`badge ${this.statusToBadge(item.status)}`} title={item.statusDetail}>
                          {item.status}
                        </span>
                        {item.status !== "PENDING" && (
                          <span className="td-subtitle">
                            {moment(item.executionDate).format("MM/DD/YYYY")}
                            <br />
                            {moment(item.executionDate).format("hh:mm:ss")}
                          </span>
                        )}
                      </td>
                      <td style={{ maxWidth: "10rem" }} className="text-truncate">
                        <span>{item.sender}</span>
                      </td>
                      <td style={{ maxWidth: "10rem" }} className="text-truncate">
                        <span>{item.receiver}</span>
                      </td>
                      <td>
                        <div className="tr-actions">
                          {this.props.canResend && (
                            <button
                              className="btn"
                              type="button"
                              title="Resend Notification"
                              onClick={() => this.resendNotification(item)}
                            >
                              <i className="fas fa-redo"></i>
                            </button>
                          )}
                          {this.props.canForward && (
                            <button
                              className="btn"
                              type="button"
                              title="Forward Notfication"
                              onClick={() => this.forwardNotification(item)}
                            >
                              <i className="fas fa-share-square"></i>
                            </button>
                          )}
                          {this.props.canView && (
                            <button
                              className="btn"
                              type="button"
                              title="View Notification"
                              onClick={() => this.viewItem(item)}
                            >
                              <i className="fas fa-eye"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </CamvioTable2>
            )}
          </div>
          {(isLoading || this.state.loadingSearch) && <Loader />}
          {!this.state.noMoreResults && (
            <LoadMore
              disabled={this.state.loadingSearch || this.state.noMoreResults || isDownloading}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => {
                this.handlePageSizeChange(pageSize);
              }}
              onLoadMore={() => this.loadMore()}
            />
          )}
        </CamvioTable2Context.Provider>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const canView = getUserPermission(state, "OPER", "OPER_NOTIFICATION_QUEUE_VIEW"),
    canResend = getUserPermission(state, "OPER", "OPER_NOTIFICATION_RESEND"),
    canForward = getUserPermission(state, "OPER", "OPER_NOTIFICATION_FORWARD"),
    notifications = selectNotificationsState(state).notifications;

  // Add new actions to the IF statement in render(), enabling the "ACTIONS" table column for users with rights
  return {
    canView,
    canResend,
    canForward,
    notifications,
  };
};

const mapDispatchToProps = {
  getItems: getNotificationQueue,
  downloadItems: downloadNotificationQueue,
  getAllNotifications,
  clearNotifications,
  resendNotification,
  showModal,
  showAccessModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationQueueList);
