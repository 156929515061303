import axiosInstance from '../utils/axios-instance';
import isEmpty, { actionCreator, createRequestTypes } from '../utils/helpers';

export const SET_USER_TASK_ATTRIBUTES = createRequestTypes('SET_USER_TASK_ATTRIBUTES');
export const SET_USER_TASK = createRequestTypes('SET_USER_TASK');
export const SET_SERVICE_ORDER_TASKS = createRequestTypes('SET_SERVICE_ORDER_TASKS');
export const SET_TASK_DETAILS_ACCOUNT_INFO = createRequestTypes('SET_TASK_DETAILS_ACCOUNT_INFO');
export const SET_TASK_DETAILS_SERVICEORDER_INFO = createRequestTypes('SET_TASK_DETAILS_SERVICEORDER_INFO');

export const getTaskAttributes = (userTaskId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_USER_TASK_ATTRIBUTES));
	try {
		const response = await axiosInstance.get(`/order/usertask/${userTaskId}`);
		dispatch(actionCreator.success(SET_USER_TASK_ATTRIBUTES, response.data.userTask));
		return response
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_USER_TASK_ATTRIBUTES, mockError));
	}
};

export const getServiceOrderTasks = (serviceOrderId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_SERVICE_ORDER_TASKS));
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${serviceOrderId}/tasks`);
		dispatch(actionCreator.success(SET_SERVICE_ORDER_TASKS, response.data));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_SERVICE_ORDER_TASKS, mockError));
	}
};

export const getProcessInstanceTasks = (processInstanceId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_SERVICE_ORDER_TASKS));
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${processInstanceId}/procinsttasks`);
		dispatch(actionCreator.success(SET_SERVICE_ORDER_TASKS, response.data));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_SERVICE_ORDER_TASKS, mockError));
	}
};


export const emptyServiceOrderTasks = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_SERVICE_ORDER_TASKS))
}

export const setUserTask = (processInstanceId, data) => async (dispatch) => {
	dispatch(actionCreator.request(SET_USER_TASK));

	try {
		const response = await axiosInstance.post(`/order/serviceorder/${processInstanceId}/task`, data);
		dispatch(actionCreator.success(SET_USER_TASK, response.data));
		dispatch(getProcessInstanceTasks(processInstanceId))
		return response.data
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_USER_TASK, mockError));
	}
};




export const getTaskDetailsAccountInfo = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TASK_DETAILS_ACCOUNT_INFO));
	try {
		const response = await axiosInstance.get(`/account/${accountId}`);
		dispatch(actionCreator.success(SET_TASK_DETAILS_ACCOUNT_INFO, response.data));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_TASK_DETAILS_ACCOUNT_INFO, mockError));
	}
};

export const getTaskDetailsServiceOrderInfo = (serviceorderId, locationId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TASK_DETAILS_SERVICEORDER_INFO));
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${serviceorderId}${!isEmpty(locationId)? '?locationId=' + locationId: ''}`);
		dispatch(actionCreator.success(SET_TASK_DETAILS_SERVICEORDER_INFO, response.data.serviceOrder));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_TASK_DETAILS_SERVICEORDER_INFO, mockError));
	}
};


export const emptyTaskAttributes = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_USER_TASK_ATTRIBUTES))
}

export const emptyTaskDetailsAccountInfo = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_TASK_DETAILS_ACCOUNT_INFO))
}

export const emptyTaskDetailsServiceOrderInfo = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_TASK_DETAILS_SERVICEORDER_INFO))
}