import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {clearAutoPayJobs, getAutoPayJobStatusList} from "../../actions/autoPayJobs-actions";
import isEmpty from "../../utils/helpers";
import {createLoadingSelector, getUser} from "../../selectors";
import { showModal } from '../../actions/modal.actions';
import { getAutoPayJobsUi } from "../../actions/uiController.actions";
import { downloadAutoPayJobs } from "../../actions/uiController.actions";
import Button from "../../components/Common/Buttons/Button";
import Select from "react-select";
import {groupStyles} from "../../utils/SelectStyles";
import DatePicker from "react-datepicker";
import moment from "moment";
import {getUserPermission} from "../../selectors";
import CamvioTable2 from "src/components/UI/CamvioTable2";

class AutoPayJobsDisplay extends Component {

    state = {
        loadingSearch: true,
        isLoading: false,
        isDownloading: false,
        searchTerm: '',
        oldSearchTerm: '',
        showDownloadDropdown: false,
        searchFrom: '', 	// yyyy-MM-dd hh:mm:ss
        searchTo: '', 		// yyyy-MM-dd hh:mm:ss
        filters: [
            /**
             * Example filter
             * {
             *   field: "scheduled_datetime",
             *   op: "gte",
             *   value: "",
             *   values: []
             * }
             */

        ],
        limit: 20,
        offset: 0,
        sort: {
            asc: false,
            field: "scheduled_datetime"
        },
        resultsNumber: 20,
        autoPayJobsList: [],
        autoPayHeadersList: [],
        sortColumn: '',
        sortDirection: '',
        noMoreResults: false,
        showAdditionalSearchOptions: false,
        autoPayJobStatusList: [],
        showAdditionalLimit: 20,
        autoPayJobStatusFilter: [],
    };


    componentDidMount() {
        // Load AutoPay Jobs
        this.reloadAutoPayJobs();
        this.props.getAutoPayJobStatusList().then(response => {
            if (response && response.statusList) {
                this.setState({autoPayJobStatusList: response.statusList})
            }
        });
    }

    componentWillUnmount() {
        this.props.clearAutoPayJobs();
    }

    setAutoPayJobStatusSearch = (value) => {
        let newFilterArray = this.state.filters
            newFilterArray = newFilterArray.filter((filterItem) => filterItem.field !== "status")

        if(value !== null) {
            newFilterArray = [...newFilterArray, {field: "status", op: "eq", value: value.value.toUpperCase()}]
            this.setState({autoPayJobStatusFilter: {name: 'apj.status', value:value.value, label: value.label}})
        } else {
            this.setState({autoPayJobStatusFilter: null})
        }

        this.setState({filters: newFilterArray})
    };

    searchAutoPayJobs = () => {
        this.reloadAutoPayJobs();
    }

    toggleFilter = () => {
        this.setState({showAdditionalSearchOptions: !this.state.showAdditionalSearchOptions});
    }

    reloadAutoPayJobs = (clearList) => {
        if (clearList) {
            this.setState({
                autoPayJobsList: [],
                autoPayHeadersList: []
            })
        }

        this.setState({isLoading: true, loadingSearch: true}, () => {
            let request = {filters: this.state.filters, limit: this.state.limit, offset: this.state.offset, sort: this.state.sort}
            this.props.getAutoPayJobsUi(request).then(response => {
                if(response.autoPayJobs.length < this.state.limit) {
                    this.setState({noMoreResults: true})
                } else {
                    this.setState({noMoreResults: false})
                }
                this.setState({isLoading: false, loadingSearch: false, autoPayJobsList: response.autoPayJobs})
            })
        });
    }

    toggleDownloadDropdown = () => {
        this.setState({showDownloadDropdown: !this.state.showDownloadDropdown});
    }


    columnSorting = (field, dir) => {
        if(this.state.sortDirection !== dir ||
            this.state.sortColumn !== field) {

            this.setState({
                loadingSearch: true,
                autoPayJobsList: [],
                autoPayHeadersList: [],
                sortColumn: field,
                sortDirection: dir,
                sort: {asc: dir === 'asc', field: field}
            });
            this.reloadAutoPayJobs();
        }
    }

    handleDateChange = (fieldName,date) => {

        switch(fieldName) {
            case 'searchFrom':
                if(date) {
                    let fromDate = {
                        field: "scheduled_datetime",
                        op: "gte",
                        value: date ? moment(date).format("YYYY-MM-DD") : "",
                        }
                    this.setState({filters: [...this.state.filters, fromDate], searchFrom: moment(date).format("YYYY-MM-DD")})
                } else {
                    let remainingFilterItems = this.state.filters.filter((filterItem) => filterItem.op !== "gte")
                    this.setState({filters: remainingFilterItems, searchFrom: null})
                }
                break;
            case 'searchTo':
                if(date) {
                    let toDate = {
                        field: "scheduled_datetime",
                        op: "lt",
                        value: date ? moment(date).add(1,"day").format("YYYY-MM-DD") : "",
                    }
                    this.setState({filters: [...this.state.filters, toDate], searchTo: moment(date).format("YYYY-MM-DD")})
                } else {
                    let remainingFilterItems = this.state.filters.filter((filterItem) => filterItem.op !== "lt")
                    this.setState({filters: remainingFilterItems, searchTo: null})
                }
                break;
        }
    };


    getJobStatusList = () => {
        let statusList = []

        this.state.autoPayJobStatusList && this.state.autoPayJobStatusList.map((status) => {
            statusList.push({
                name: "apj.status",
                value: status,
                label: status
            });
        });
        return statusList;
    }

    downloadTable = (value) => {
        const {filters, limit, offset, sort} = this.state
        let request = {filters: filters, limit: limit, offset: offset, sort: sort}

        this.setState({
            isDownloading: true
        });

        this.props.downloadAutoPayJobs(value, request).then((response) => {
            if (response && response.status === 201 && response.data) {
                let name = "";
                let link = document.createElement('a');
                let fileUrl = URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('href', fileUrl);
                if(response.headers["content-disposition"] != undefined) {
                    name = response.headers["content-disposition"].split("=")[1];
                }else{
                    let today = new Date()
                    name = "autopay-jobs-" +  today.toISOString().split('T')[0] + "." + value;
                }
                link.setAttribute('download',name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    isDownloading: false,
                    showDownloadDropdown: false
                });
            }
        });
    };

    renderStatusStyle = (status) => {
            switch (status) {
                case "SCHEDULED":
                    return "billing-job-progress-status bg-light";
                case "PROCESSING":
                    return "billing-job-progress-status bg-primary";
                case "FINISHED":
                    return "billing-job-progress-status bg-success";
                case "ABORTED":
                    return "billing-job-progress-status bg-danger";
                default:
                    return null;
            }
    }

    render() {

        const {
            autoPayJobsList,
            autoPayHeadersList,
            isLoading,
            isDownloading,
            showAdditionalSearchOptions,
            showDownloadDropdown,
            sortColumn,
            sortDirection,
        } = this.state;

        const {
            autoPayJobsLoader,
            canManageAutoPayJobs,
        } = this.props;



        return (
            <Fragment>
                <div className="tab-pane camvio-table-search" style={{borderRadius: '0.5rem'}}>
                    <div className="cmv-container-dashboard-search">
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <div className="input-group input-group-datepicker">
                                                <DatePicker
                                                    type="date"
                                                    className={"form-control"}
                                                    // className="form-control"
                                                    placeholderText="From MM/DD/YYYY"
                                                    id="searchFrom"
                                                    name="searchFrom"
                                                    isClearable={!isEmpty(this.state.searchFrom)}
                                                    selected={this.state.searchFrom && moment(this.state.searchFrom).toDate()}
                                                    onChange={(date) => this.handleDateChange('searchFrom', date)}
                                                    style={groupStyles}
                                                    autoComplete="off"
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            this.searchAutoPayJobs()
                                                        }
                                                    }}
                                                />
                                                <DatePicker
                                                    type="date"
                                                    // className="form-control"
                                                    className={"form-control"}
                                                    placeholderText="To MM/DD/YYYY"
                                                    id="searchTo"
                                                    name="searchTo"
                                                    isClearable={!isEmpty(this.state.searchTo)}
                                                    selected={this.state.searchTo && moment(this.state.searchTo).toDate()}
                                                    onChange={(date) => this.handleDateChange('searchTo', date)}
                                                    style={groupStyles}
                                                    autoComplete="off"
                                                    onKeyPress={(event) => {
                                                        if (event.key === 'Enter') {
                                                            this.searchAutoPayJobs()
                                                        }
                                                    }}
                                                />
                                                <div className="input-group-append">
                                                    <Button
                                                        type="button"
                                                        className="btn btn-primary btn-show-cam-search-results"
                                                        disabled={this.state.loadingSearch || isDownloading}
                                                        onClick={() => {
                                                            this.searchAutoPayJobs();
                                                        }}
                                                    >
                                                        <i className="fas fa-search"/>
                                                        <span>&nbsp;Search</span>
                                                    </Button>
                                                </div>

                                                <div className="input-group-append">
                                                    <div className="btn-group">
                                                        <Button
                                                            type="button"
                                                            onClick={this.toggleFilter}
                                                            className="btn btn-outline-secondary"
                                                        >
                                                            {showAdditionalSearchOptions ?
                                                                <i className="fas fa-solid fa-ban"/>
                                                                :
                                                                <i className="fas fa-solid fa-filter"/>
                                                            }
                                                            <span>&nbsp;Filters</span>
                                                        </Button>

                                                        <Button
                                                            type="button"
                                                            className="btn btn-outline-secondary btn-new-account-wizard"
                                                            onClick={() => this.props.showModal('AUTOPAY_JOBS_MODAL', {
                                                                type: 'create',
                                                                reloadFunction: this.reloadAutoPayJobs
                                                            })}
                                                            disabled={!canManageAutoPayJobs || autoPayJobsLoader || isDownloading}
                                                        >
                                                            <i className="fas fa-plus"/>
                                                            <span>&nbsp;New AutoPay Job</span>
                                                        </Button>
                                                        <div className="dropdown">
                                                            <Button
                                                                type="button"
                                                                className="btn btn-outline-secondary dropdown-toggle"
                                                                type="button"
                                                                onClick={this.toggleDownloadDropdown}
                                                                disabled={this.state.loadingSearch || isDownloading}
                                                            >
                                                                <i className="fas fa-download"/>
                                                                <span
                                                                    className="camvio-table-search-download">&nbsp;Download</span>
                                                            </Button>
                                                            <div
                                                                className={"dropdown-menu dropdown-menu-right" + (showDownloadDropdown && !isDownloading ? " show" : "")}>
                                                                <button
                                                                    type="button"
                                                                    value={"XLS"}
                                                                    className="dropdown-item"
                                                                    onClick={(e) => {
                                                                        this.downloadTable("XLS")
                                                                    }}
                                                                >
                                                                    As XLS
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="dropdown-item"
                                                                    onClick={(e) => {
                                                                        this.downloadTable("CSV")
                                                                    }}
                                                                >
                                                                    As CSV
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAdditionalSearchOptions &&
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="camvioTable-plan-extra-filters">
                                    <div className="search">
                                        <Select
                                            className="extra-filter-select"
                                            id="status"
                                            name="apj.status"
                                            onChange={this.setAutoPayJobStatusSearch}
                                            styles={groupStyles}
                                            options={this.getJobStatusList()}
                                            value={this.state.autoPayJobStatusFilter}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    {!isLoading && isEmpty(autoPayJobsList) &&
                    <div><p>No data found</p></div>
                    }

                    {!isEmpty(autoPayJobsList) &&
                        <CamvioTable2
                            headerDefs={[
                                {name: "Scheduled", field: "scheduled_datetime", sortable: true},
                                {name: "Status", field: "status", sortable: true},
                                {name: "Notes", field: "notes", sortable: true},
                                {name: "Actions", field: "", sortable: false},
                            ]}
                            sortFunction={this.columnSorting}
                            sortField={sortColumn}
                            sortDirection={sortDirection}
                        >
                            <tbody>
                            {autoPayJobsList.map((item, index) => (
                                <tr key={`item_${item.id}`}>
                                    <td style={{verticalAlign:'middle'}}>
                                        <span>{moment(item.scheduledDateString).format('MM/DD/YYYY@h:mm A')}</span>
                                        <p className="billing-job-id">
                                            #{item.id || "N/A"}
                                        </p>
                                    </td>
                                    <td style={{verticalAlign:'middle'}}>
                                        <span className={this.renderStatusStyle(item.status)}>
                                        {item.status}
                                        </span>
                                    </td>
                                    <td style={{verticalAlign:'middle'}}>
                                        <span>{item.notes}</span>
                                    </td>
                                    <td>
                                        <div style={{display:'flex', justifyContent:'flex-end'}}>
                                            <div className='billing-job' style={{backgroundColor:'transparent', border:'none', display:'flex', gap:'0.5vw'}}>
                                            <Button
                                                onClick={() => this.props.showModal('AUTOPAY_JOB_MONITOR_MODAL', {autoPayJobID: item.id})}
                                                className="btn btn-xs btn-order-log-toggle btn-circle"
                                                type="button"
                                                style={{borderRadius:'50%'}}
                                            >
                                                <i className="fa fa-heartbeat" style={{fontSize:'1.2rem'}} />
                                            </Button>
                                            <div style={{borderRight:'1px solid #ced4da'}}/>
                                            <Button
                                                onClick={() => this.props.showModal('AUTOPAY_JOBS_MODAL', {
                                                    type: 'edit',
                                                    reloadFunction: this.reloadAutoPayJobs,
                                                    value: item.id
                                                })}
                                                className="btn btn-xs btn-order-log-toggle btn-circle"
                                                type="button"
                                                style={{borderRadius:'50%'}}
                                                disabled={item.status === "FINISHED" || item.status === "PROCESSING"}
                                            >
                                                <i className="fas fa-edit" style={{fontSize:'1.2rem'}} />
                                            </Button>
                                            <div style={{borderRight:'1px solid #ced4da'}}/>
                                            <Button
                                            onClick={() => this.props.showModal('AUTOPAY_JOBS_MODAL', {
                                                type: 'delete',
                                                reloadFunction: this.reloadAutoPayJobs,
                                                value: item.id
                                            })}
                                            className="btn btn-xs btn-order-log-toggle btn-circle"
                                            type="button"
                                            style={{borderRadius:'50%'}}
                                            disabled={item.status !== "SCHEDULED"}
                                            >
                                                <i className="fas fa-ban" style={{fontSize:'1.2rem'}} />
                                            </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </CamvioTable2>

                    }
                </div>
                {this.state.autoPayJobsList.length > 0 && !this.state.noMoreResults &&

                <div className="row" style={{marginTop: '2%'}}>
                    <div className="col-sm-12">
                        <div className="search" style={{float: "right"}}>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    this.setState({limit: this.state.limit + this.state.showAdditionalLimit},
                                        () => this.reloadAutoPayJobs(true)
                                    )
                                }}
                            >
                                Show Additional
                            </button>
                            &nbsp;
                            &nbsp;
                            <select
                                className="custom-select"
                                value={this.state.showAdditionalLimit}
                                onChange={(e) => this.setState({showAdditionalLimit: parseInt(e.target.value)})}
                            >
                                <option label="20" value="20"/>
                                <option label="30" value="30"/>
                                <option label="40" value="40"/>
                                <option label="50" value="50"/>
                            </select>
                        </div>
                    </div>
                </div>
                }
            </Fragment>
        );
    }
}

const loaderJobs = createLoadingSelector(['LOAD_AUTOPAY_JOBS']);

const mapStateToProps = (state) => {

    const autoPayJobsLoader = loaderJobs(state),
        userPermission = getUser(state).userPermission,
        canManageAutoPayJobs = getUserPermission(state, 'ADMN', 'ADMN_MANAGE_AUTOPAY_JOBS');

    return {
        autoPayJobsLoader,
        userPermission,
        canManageAutoPayJobs,
    };
};

const mapDispatchToProps = {
    clearAutoPayJobs,
    showModal,
    getAutoPayJobsUi,
    downloadAutoPayJobs,
    getAutoPayJobStatusList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoPayJobsDisplay);
