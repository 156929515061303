import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../components/Loader';
import { isEmpty } from '../../../../utils/helpers';
import PersonalTableRow from "./PersonalTableRow";
import PersonalTaskDetails from "./PersonalTaskDetails";
import {
	getTaskAttributes
} from "../../../../actions/taskDetails.actions";
import {getTaskDetails} from "../../../../selectors";
import {connect} from "react-redux";

class PersonalTasksResults extends Component {

	state = {
		taskDetailsVisible: false,
		tasks: []
	}

	componentDidMount() {
		this.setState({tasks: this.props.userTasksDataSorted})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.userTasksDataSorted !== this.props.userTasksDataSorted) {
			this.setState({tasks: this.props.userTasksDataSorted})
		}
	}


	renderTaskDetails = (taskId) => {
		if(taskId === this.props.taskId) {
			this.setState({taskDetailsVisible: false})
			this.props.setTaskIdSelected("")
		} else {
			this.setState({taskDetailsVisible: true})
			this.props.setTaskIdSelected(taskId)
		}
	}


	updateTaskData = (oldTaskId, newTaskId) => {
		//updates task state after task form submission without re-rendering parent components
		let updatedTasks = this.state.tasks
		updatedTasks.map((task) => {
			if(task.id === oldTaskId) {
				task.id = newTaskId.toString();
				task.name = this.props.taskAttributesData.name
				this.props.setTaskIdSelected(task.id)
			}
		})
		this.setState({tasks: updatedTasks})
	}

	render() {
		const {
			searchType,
			userTasksDataSorted,
			taskLoader,
			isFetched,
			rowClasses,
			showOrderDetails,
			usernameOptions
		} = this.props;

		const {
			taskDetailsVisible
		} = this.state

		const tableHeadings = [
			'Description',
			(searchType === 'complete' ? '' : 'Priority'),
			'Assignee',
			'Action'
		];

		// Add Details Column only if we're not in Account Details Orders
		if (showOrderDetails !== false) {
			tableHeadings.splice(1, 0, 'Details')
		}

		return (
			<>

				{taskLoader &&
				<Loader />
				}

				{!taskLoader && isFetched && isEmpty(userTasksDataSorted) &&
				<div className="container">
					<p>No pending tasks</p>
				</div>
				}

				{!taskLoader && !isEmpty(userTasksDataSorted) && (
				<div className="cmv-container-table">
					<table className="table cmv-table cmv-table-mobile cmv-table-solid">
						<thead>
							<tr>
								{tableHeadings.map((item, i) => (
									<th key={i} className="">{item}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{this.state.tasks.map((task, i) =>
								<PersonalTableRow
									key={task.id}
									tableHeadings={tableHeadings}
									usernameOptions={usernameOptions}
									taskInfo={task}
									taskIdSelected={this.props.taskId}
									taskSelected={task.id === this.props.taskId ? true : false}
									rowClass={rowClasses ? rowClasses[i] : i % 2 ? '' : 'tr-dark'}
									renderTaskDetails={this.renderTaskDetails}
									getAppointmentsByFilters={this.props.getAppointmentsByFilters}
									{...this.props}
								/>
							)}
						</tbody>
					</table>
					{taskDetailsVisible &&
					<PersonalTaskDetails
						taskIdSelected={this.props.taskId}
						setTaskIdSelected={this.props.setTaskIdSelected}
						updateTaskData={this.updateTaskData}
						usernameOptions={usernameOptions}
						isFetched={isFetched}
					/>
					}
				</div>
				)}

			</>
		);
	}
}

PersonalTasksResults.propTypes = {
	tableHeadings: PropTypes.array,
	userTasksData: PropTypes.array.isRequired,
	taskLoader: PropTypes.bool,
	isFetched: PropTypes.bool
};

const mapStateToProps = (state) => {
	const taskAttributesData = getTaskDetails(state).taskAttributes;

	return {
		taskAttributesData
	};
};

const mapDispatchToProps = {
	getTaskAttributes
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalTasksResults);
