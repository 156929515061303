import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getModalData, createLoadingSelector } from '../../../selectors';
import { hideModal } from '../../../actions/modal.actions';
import { createNewOrder } from '../../../actions/accountDetailsActions/newOrder.actions';

class OrderModal extends Component {

    handleCreateOrder = () => {
		this.changeApplyOnValue(this.props.modalProps.applyOn)
		const orderData = this.props.modalProps;

		let updatedOrderData = { ...orderData };
		updatedOrderData['address'] = this.props.modalProps.validatedAddress.addressSearchFormated;
		updatedOrderData['type'] = this.props.modalProps.validatedAddress.allowedServiceOrderType;
		updatedOrderData['validatedAddress'] = true;
		updatedOrderData['validatedAddressWrapper'] = this.props.modalProps.validatedAddress;
        this.props.createNewOrder(updatedOrderData);
	}
	changeApplyOnValue(value) {
		if( value === 'ORDER COMPLETION' ){
			this.props.modalProps.applyOn = 'ORDER_COMPLETION'
		} else if ( value === 'CURRENT BILL CYCLE' ) {
			this.props.modalProps.applyOn = 'CURRENT_BILL_CYCLE'
		} else if ( value === 'NEXT BILL CYCLE' ) {
			this.props.modalProps.applyOn = 'NEXT_BILL_CYCLE'
		} else if ( value === 'SELECT DATE') {
			this.props.modalProps.applyOn = 'SELECT_DATE'
		}
	}
	

	render() {
		return (
			<Fragment>
				<div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Confirm Saving New Order</h5>
								<button type="button" className="close" onClick={this.props.hideModal}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<p className="lead">You are about to save the provided information as an order.</p>
								<p className="lead">Do you want to continue?</p>
							</div>
							<div className="modal-footer">
								<button className="btn" onClick={this.props.hideModal}>
									No
								</button>
								<button className="btn btn-primary" onClick={this.handleCreateOrder} disabled={this.props.newOrderLoader}>
									Yes
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

const setNewOrderLoader = createLoadingSelector([ 'CREATE_ORDER' ]);

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
            newOrderLoader = setNewOrderLoader(state)

	return {
        modalProps,
        newOrderLoader
	};
};
const mapDispatchToProps = {
    hideModal,
    createNewOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderModal);
