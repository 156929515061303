import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import BootstrapTable from "react-bootstrap-table-next";
import { Formik } from "formik";

import { createLoadingSelector, getSystemConfigs, getUserPermission } from "src/selectors";
import {
  getAllSystemConfigurations,
  patchSystemConfigurations,
  clearAllSystemConfigurations,
  deleteSystemConfiguration,
} from "src/actions/systemConfig.actions";
import { showModal } from "src/actions/modal.actions";
import Loader from "src/components/Loader";
import TextInput from "src/components/UI/TextInput";
import { DefaultDashboardSearch, defineActionButton } from "src/components/UI/CamvioTable2";
import { getErrorMessage } from "src/utils/helpers";
import ButtonIcon from "src/components/Common/Buttons/ButtonIcon";

class SystemConfigurations extends Component {
  state = {
    searchTerm: "",
    appliedSearchTerm: "",
  };

  configGroups = [
    {
      title: "Company Information",
      prefix: ["company_", "company."],
    },
    {
      title: "Auth0 Integration",
      prefix: ["auth0.", "auth.provider"],
    },
    {
      title: "Notification",
      prefix: ["notification."],
    },
    {
      title: "Autopay",
      prefix: ["autopay."],
    },
    {
      title: "Payment Providers",
      prefix: ["payment."],
    },
    {
      title: "Post Payment",
      prefix: ["post.payment."],
    },
    {
      title: "Billing",
      prefix: ["allowBillCycles", "billing.job.", "billingJob"],
    },

    {
      title: "Invoice",
      prefix: ["invoice.", "genInvoiceRootFolder"],
    },
    {
      title: "Tax Engine",
      prefix: ["csi.", "taxengine."],
    },
    {
      title: "Slack Integration",
      prefix: ["api.slack.", "batch.slack.", "billing.slack."],
    },
    {
      title: "Other",
      prefix: [""],
    },
  ];

  componentDidMount() {
    document.title = "System Configuration - camvio.cloud";
    this.props.changeNavBarType("default", "System Configuration");
    this.loadSystemConfigurations();
  }

  componentWillUnmount() {
    this.props.clearAllSystemConfigurations();
  }

  loadSystemConfigurations() {
    return this.props.getAllSystemConfigurations();
  }

  handleSubmit = (values, actions) => {
    actions.setSubmitting(false);
  };

  getChangedValues(formValues) {
    const originalConfigurations = this.props.allConfigurations;
    const mappingUpdatedProps = [];

    originalConfigurations &&
      formValues.systemConfigurations &&
      originalConfigurations.forEach((origProp) => {
        const currProp = formValues.systemConfigurations.find((currProp) => currProp.key === origProp.key);
        if (currProp && currProp.value !== origProp.value) {
          mappingUpdatedProps.push({
            key: currProp.key,
            oldVal: origProp.value,
            newVal: currProp.value,
          });
        }
      });

    return mappingUpdatedProps;
  }

  saveSystemConfigurations = ({ setSubmitting, values }) => {
    setSubmitting(true);

    const updatedProps = this.getChangedValues(values);
    const properties = values.systemConfigurations.filter((c) => updatedProps.find((u) => u.key === c.key));

    this.props
      .patchSystemConfigurations({
        properties,
      })
      .then((response) => {
        if (response.success === false) {
          console.error(response);
          toastr.error(getErrorMessage(response).message, { position: "top-center" });
        } else if (response.success === false) {
          console.error(response);
          toastr.error(getErrorMessage(response).message, { position: "top-center" });
        } else {
          const message = "System configurations are updated successfully";

          toastr.success(message, { timeOut: 3000, position: "top-center" });

          // reload the system configurations
          this.loadSystemConfigurations().finally(() => setSubmitting(false));
        }
      })
      .catch((e) => {
        toastr.error("Something went wrong", { timeOut: 5000, position: "top-center" });

        setSubmitting(false);
      });
  };

  oldValueFormatter = (cell, row) => {
    return <div style={{ maxWidth: "200px", wordBreak: "break-all" }}>{row.oldVal}</div>;
  };

  newValueFormatter = (cell, row) => {
    return <div style={{ maxWidth: "200px", wordBreak: "break-all" }}>{row.newVal}</div>;
  };

  onSaveButtonClick = (updatedProps, formProps) => {
    const columns = [
      {
        dataField: "key",
        text: "Name",
      },
      {
        dataField: "oldVal",
        text: "Old Value",
        formatter: this.oldValueFormatter,
      },
      {
        dataField: "newVal",
        text: "New Value",
        formatter: this.newValueFormatter,
      },
    ];

    this.props.showModal("GENERIC_MODAL", {
      title: "Save Configuration",
      text: "Are you sure you want to make these changes?",
      size: "lg",
      cancelText: "Cancel",
      okText: "Save",
      onOk: () => this.saveSystemConfigurations(formProps),
      component: () => (
        <>
          {updatedProps && updatedProps.length > 0 && (
            <BootstrapTable
              classes="table table-striped cmv-table"
              keyField="key"
              data={updatedProps}
              columns={columns}
            />
          )}
        </>
      ),
    });
  };

  onDashboardAction = (action) => {
    switch (action) {
      case "SEARCH":
        this.setState({
          appliedSearchTerm: this.state.searchTerm,
        });
        break;
      case "ADD":
        this.props.showModal("ADD_SYSTEM_CONFIGURATION_MODAL", {
          value: null,
          onSuccess: () => {
            // a new configuration has been added, reload full list of system configurations
            this.loadSystemConfigurations();
          },
        });
        break;
      default:
        break;
    }
  };

  debounceHandleInputChange = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  deleteConfiguration = (configuration) => {
    toastr.confirm(`Are you sure you want to delete system configuration: ${configuration.key}?`, {
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        this.props
          .deleteSystemConfiguration(configuration.id)
          .then(() => this.loadSystemConfigurations())
          .catch((e) => toastr.error("Failed to delete the configuration", { position: "top-center" }));
      },
    });
  };

  render() {
    const { isLoading, allConfigurations } = this.props;

    return (
      <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
        <div className="container">
          <DefaultDashboardSearch
            onAction={this.onDashboardAction}
            onInputChange={this.debounceHandleInputChange}
            disabled={isLoading}
            inputPlaceholder="Search by Key or Description"
            hasMoreOptions={false}
            actionButtons={{
              secondary: this.props.canAdd ? [defineActionButton("ADD", "New System Configuration", "fa-plus")] : [],
              dropdownItems: false,
              dropdown: [],
            }}
          ></DefaultDashboardSearch>

          <Formik
            initialValues={{
              systemConfigurations: allConfigurations,
            }}
            enableReinitialize={true}
            onSubmit={this.handleSubmit}
          >
            {(formProps) => {
              const { handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue } =
                formProps;

              const mappingUpdatedProps = this.getChangedValues(values);

              const sortedConfigurations = values.systemConfigurations.sort((a, b) => a.key.localeCompare(b.key));
              const appliedSearchTerm = this.state.appliedSearchTerm
                ? this.state.appliedSearchTerm.toLowerCase()
                : null;
              const filteredConfigurations = !appliedSearchTerm
                ? sortedConfigurations
                : sortedConfigurations.filter(
                    (c) =>
                      c.key.toLowerCase().includes(appliedSearchTerm) ||
                      (c.description && c.description.toLowerCase().includes(appliedSearchTerm))
                  );

              const groupedConfigurations = this.configGroups.map((g) => ({
                ...g,
                configurations: [],
              }));
              filteredConfigurations.forEach((c) => {
                const group = groupedConfigurations.find((g) =>
                  g.prefix.reduce((r, a) => r || c.key.startsWith(a), false)
                );
                if (group) {
                  group.configurations.push(c);
                }
              });

              return (
                <>
                  <form onSubmit={handleSubmit}>
                    <div className="form-vertical">
                      {isLoading && <Loader />}
                      {!isLoading && values.systemConfigurations && (
                        <>
                          {groupedConfigurations
                            .filter((g) => g.configurations.length > 0)
                            .map((group, idx) => (
                              <div key={`group_${group.prefix[0]}`} className="card-vertical card-plane">
                                <div className="card-body">
                                  <div className="plan-header plan-header-centered">
                                    <span className="plan-title">
                                      {group.title}
                                      <small className="ml-4 text-muted">
                                        {group.configurations.length} configuration(s)
                                      </small>
                                    </span>
                                  </div>
                                  <div className="plan-body">
                                    <div className="form-group service-wrapper">
                                      {group.configurations.map((prop) => {
                                        const idx = values.systemConfigurations.indexOf(prop);
                                        const fieldName = `systemConfigurations[${idx}].value`;

                                        return (
                                          <div key={`config_${prop.key}`} className="position-relative">
                                            <TextInput
                                              label={prop.description || prop.key}
                                              type="text"
                                              name={fieldName}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={prop.value || ""}
                                              disabled={isSubmitting}
                                              autocomplete={false}
                                              helperText={prop.key}
                                            />
                                            {this.props.canDelete && (
                                              <ButtonIcon
                                                onClick={() => this.deleteConfiguration(prop)}
                                                buttonClass="btn btn-lg text-danger position-absolute"
                                                iconClass="fa fa-trash"
                                                title="Delete system configuration"
                                                style={{ right: 0, top: 0 }}
                                              />
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}

                          <div className="plan-footer mt-2" style={{ textAlign: "right" }}>
                            <button
                              onClick={() => this.onSaveButtonClick(mappingUpdatedProps, formProps)}
                              disabled={isLoading || isSubmitting || mappingUpdatedProps.length < 1}
                              type="button"
                              className="btn btn-primary"
                            >
                              {isSubmitting && (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                  style={{ marginRight: "5px" }}
                                />
                              )}
                              Save
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

const selectLoadingAllSystemConfiguration = createLoadingSelector(["SYSTEM_CONFIGURATIONS_ALL"]);

const mapStateToProps = (state) => {
  const allConfigurations = getSystemConfigs(state).allConfigurations;
  const isLoadingAllSystemConfigurations = selectLoadingAllSystemConfiguration(state);
  const canAddSystemConfiguration = getUserPermission(state, "ADMN", "ADMN_ADD_SYSTEM_CONFIGURATION"),
    canDeleteSystemConfiguration = getUserPermission(state, "ADMN", "ADMN_DELETE_SYSTEM_CONFIGURATION");

  return {
    allConfigurations,
    isLoading: isLoadingAllSystemConfigurations,
    canAdd: canAddSystemConfiguration,
    canDelete: canDeleteSystemConfiguration,
  };
};

const mapDispatchToProps = {
  showModal,
  getAllSystemConfigurations,
  patchSystemConfigurations,
  clearAllSystemConfigurations,
  deleteSystemConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemConfigurations);
