import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../../actions/modal.actions';
import NotificationsList from "./NotificationsList";

/**
 * Page content wraper for Notification and Template management page
 */
class Notifications extends Component {

	componentDidMount() {

		// Change navbar type and color
		document.title = 'Admin - Notifications - camvio.cloud';
		this.props.changeNavBarType('default', 'Notifications');
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isShowAccessModal !== this.props.isShowAccessModal && this.props.isShowAccessModal === true) {
		}
	}

	componentWillUnmount() {
		this.props.hideModal();
	}

	render() {
		const { history, isShowAccessModal } = this.props;

		//If user is redirected from create-account page - show cpni pop-up
		if (isShowAccessModal === false) {
			return null;
		}

		return (
			<div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
				<NotificationsList />
			</div>
		);
	}
}

// const loader = createLoadingSelector([ 'SET_ACCOUNT_DETAILS' ]);

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = {
	showModal,
	hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
