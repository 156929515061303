import {
	GET_ORDER_AVAILABILITY,
	GET_TROUBLE_TICKET_AVAILABILITY,
	GET_TROUBLE_TICKET_APPOINTMENTS,
	GET_ORDER_APPOINTMENTS,
	GET_APPOINTMENT_AREAS,
	GET_APPOINTMENT_CAPACITIES,
	GET_ORDER_APPOINTMENT_AREAS, GET_TROUBLE_TICKET_APPOINTMENT_AREAS
} from '../actions/technicianAppointment.actions';

const initialState = {
	availability: [],
	troubleTicketAppointments: [],
	orderAppointments: [],
	orderAppointmentAreas: [],
	troubleTicketAppointmentAreas: [],
	appointmentCapacity: [],
};

export const technicianAppointmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_TROUBLE_TICKET_AVAILABILITY.CLEAR:
			return {
				...state,
				availability: [],
				geocodeError: undefined
			};
		case GET_TROUBLE_TICKET_AVAILABILITY.SUCCESS:
			return {
				...state,
				availability: action.payload.dates
			};
		case GET_TROUBLE_TICKET_AVAILABILITY.FAILURE:
			return {
				...state,
				geocodeError: action.payload.message
			};
		case GET_ORDER_AVAILABILITY.CLEAR:
			return {
				...state,
				availability: [],
				geocodeError: undefined
			};
		case GET_ORDER_AVAILABILITY.SUCCESS:
			return {
				...state,
				availability: action.payload.dates,
			};
		case GET_ORDER_AVAILABILITY.FAILURE:
			return {
				...state,
				geocodeError: action.payload.message
			};
		case GET_ORDER_AVAILABILITY.FAILURE:
			return {
				...state,
				geocodeError: action.payload.message
			};
		case GET_TROUBLE_TICKET_APPOINTMENTS.SUCCESS:
			return {
				...state,
				troubleTicketAppointments: action.payload
			};
		case GET_TROUBLE_TICKET_APPOINTMENTS.EMPTY:
			return {
				...state,
				troubleTicketAppointments: []
			};
		case GET_ORDER_APPOINTMENTS.SUCCESS:
			return {
				...state,
				orderAppointments: action.payload
			};
		case GET_ORDER_APPOINTMENTS.EMPTY:
			return {
				...state,
				orderAppointments: action.payload
			};
		case GET_APPOINTMENT_AREAS.SUCCESS:
			return {
				...state,
				appointmentAreas: action.payload
			};
		case GET_APPOINTMENT_CAPACITIES.SUCCESS:
			return {
				...state,
				appointmentCapacity: action.payload
			};
		case GET_ORDER_APPOINTMENT_AREAS.SUCCESS:
			return {
				...state,
				orderAppointmentAreas: action.payload
			};
		case GET_TROUBLE_TICKET_APPOINTMENT_AREAS.SUCCESS:
			return {
				...state,
				troubleTicketAppointmentAreas: action.payload
			};
		default:
			return state;
	}
};
