import React, { useState } from "react";

import Button from "src/components/Common/Buttons/Button";
import {isEmpty} from "lodash";

export function defineActionButton(
  action,
  label,
  faIcon = null,
  disabled = false,
  children = null
) {
  return { action, label, faIcon, disabled, children };
}

export default function DefaultDashboardSearch({
  disabled,
  onInputChange,
  actionButtons,
  onAction,
  children = undefined,
  inputPlaceholder = "Name or Description...",
  hasMoreOptions = true,
  bulkSelectionEnabled,
  canBulkSelect,
  setBulkSelectionEnabled,
  bulkEdit,
  handleBulkSelectionSelectAll,
  bulkSelectedItems
}) {
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showActionDropDown, setShowActionDropDown] = useState(false);

  const buttons = {
    default: defineActionButton("SEARCH", "Search", "fa-search"),
    secondary: [defineActionButton("ADD", "New", "fa-plus")],
    dropdown: defineActionButton("DOWNLOAD", "Download", "fa-download"),
    dropdownItems: [
      defineActionButton("XLS", "As XLS"),
      defineActionButton("CSV", "As CSV"),
    ],
    ...(actionButtons || {}),
  };

  return (
    <div className="cmv-container-dashboard-search">
      <div className="cmv-container-dashboard-filter">
        <div className="container">
          <div className="form-row">
            <div className="col-md-12">
              <div className="input-group">
                <input
                  placeholder={inputPlaceholder}
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  onChange={onInputChange}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      onAction("SEARCH");
                    }
                  }}
                />
                <div className="input-group-append">
                  <Button
                    type="button"
                    className="btn btn-primary btn-show-cam-search-results"
                    disabled={disabled || buttons.default.disabled}
                    onClick={() => onAction(buttons.default.action)}
                  >
                    {buttons.default.faIcon && (
                      <i className={`fas ${buttons.default.faIcon}`} />
                    )}
                    <span>&nbsp;{buttons.default.label}</span>
                  </Button>
                </div>

                <div className="input-group-append">
                  <div className="btn-group">
                    {hasMoreOptions && (
                      <Button
                        type="button"
                        onClick={() => setShowMoreOptions(!showMoreOptions)}
                        className="btn btn-outline-secondary"
                        title={"More Filters"}
                      >
                        <i
                          className={`fas fa-solid ${
                            showMoreOptions ? " fa-ban" : "fa-filter"
                          }`}
                        />
                        <span>&nbsp;Filters</span>
                      </Button>
                    )}

                    {buttons.secondary.map((btn) => (
                      <button
                        key={`ct2_ds_s_${btn.action}`}
                        type="button"
                        className="btn btn-outline-secondary btn-new-account-wizard"
                        disabled={disabled || btn.disabled}
                        onClick={(e) => onAction(btn.action)}
                        title={btn.label}
                      >
                        {btn.faIcon && <i className={`fas ${btn.faIcon}`} />}
                        <span>&nbsp;{btn.label}</span>
                      </button>
                    ))}

                    {buttons.dropdownItems && (
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-outline-secondary dropdown-toggle"
                          onClick={() =>
                            setShowActionDropDown(!showActionDropDown)
                          }
                          disabled={disabled}
                        >
                          {buttons.dropdown.faIcon && (
                            <i className={`fa ${buttons.dropdown.faIcon}`} />
                          )}
                          <span className="camvio-table-search-download">
                            &nbsp;{buttons.dropdown.label}
                          </span>
                        </button>
                        <div
                          className={
                            "dropdown-menu dropdown-menu-right" +
                            (showActionDropDown && !disabled ? " show" : "")
                          }
                        >
                          {buttons.dropdownItems.map((btn) => (
                            <button
                              key={`ct2_ds_drp_${btn.action}`}
                              type="button"
                              className="dropdown-item"
                              onClick={(e) => {
                                onAction(btn.action);
                                setShowActionDropDown(false);
                              }}
                            >
                              {btn.faIcon && (
                                <i className={`fas ${btn.faIcon}`} />
                              )}
                              <span>&nbsp;{btn.label}</span>
                            </button>
                          ))}
                        </div>
                      </div>
                        )}
                      {canBulkSelect &&
                          <button
                              type="button"
                              className={"btn " + (bulkSelectionEnabled ? "btn-outline-danger" : "btn-outline-secondary")}
                              onClick={() => setBulkSelectionEnabled()}
                          >
                            <i className="fas fa-tasks"/>
                            {bulkSelectionEnabled
                                ? <span>&nbsp;Cancel</span>
                                : <span>&nbsp;Bulk</span>
                            }
                          </button>
                      }
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
      {showMoreOptions && (
        <div className="cmv-container-dashboard-filter">
          <div className="container">
            <div className="camvioTable-plan-extra-filters">{children}</div>
          </div>
        </div>
      )}
      {bulkSelectionEnabled &&
          <div className="cmv-container-dashboard-filter">
            <div className="container">
              <div className="form-row">
                <div className="col-sm-6">

                  <label>Select inventory for editing and then press START</label>

                </div>
                <div className="col-sm-6 d-flex justify-content-end">

                  <div className="input-group-append">
                    <div className="btn-group">
                      <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary"
                          onClick={() => handleBulkSelectionSelectAll()}
                      >
                        <i className="fas fa-check-double"/>&nbsp;Select All
                      </button>

                      <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          disabled={isEmpty(bulkSelectedItems)}
                          onClick={() => bulkEdit()}
                      >
                        <i className="fas fa-tasks"/>&nbsp;Start
                      </button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
}
