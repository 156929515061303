import React, {Component, Fragment} from 'react';
import {toastr} from "react-redux-toastr";
import {createLoadingSelector, getAccountDetails} from "../../../../../selectors";
import {
    getAutoPayAvailableCC,
} from "../../../../../actions/accountDetailsActions/accountDetails.actions";
import {
    clearPaymentFormData,
    getPaymentFormData,
    getPaymentStatus,
    clearPaymentStatus,
    paymentByToken,
    createPaymentMethodForm
} from "../../../../../actions/accountDetailsActions/payNow.actions";
import {connect} from "react-redux";
import Loader from "../../../../../components/Loader";
import isEmpty, {getErrorMessage} from "../../../../../utils/helpers";
import PropTypes from 'prop-types';
import {paymentSubmitted} from "../../../../../actions/globalFlag.actions";

export class PaymentForm extends Component {
    state = {
        paymentFormSubmitted: false,
        submitFormData: false,
    }

    componentDidMount() {
        this.referenceId = '';
    }

    componentWillUnmount() {
        this.props.clearPaymentFormData();
        this.props.clearPaymentStatus();
    }

    componentDidUpdate(prevProps) {
        if (this.state.submitFormData && !isEmpty(this.props.formData)) {
            this.paymentForm.submit();
            this.setState( {
                paymentFormSubmitted: true
            });

            this.props.getPaymentStatus(this.referenceId);

            this.setState({submitFormData: false});
        }

        if (this.props.paymentMethod != 'MANAGE_PAYMENT_METHODS') {
            if (prevProps.formData !== this.props.formData && !isEmpty(this.props.formData)) {
                this.referenceId = this.props.formData.fields['ReferenceId'];
            }

            if (prevProps.paymentStatusCount !== this.props.paymentStatusCount) {
                if (this.props.paymentStatus.inProgress === true) {
                    this.props.getPaymentStatus(this.referenceId);
                }
                else {
                    if (this.props.paymentStatus.status === 'SUCCESS') {
                        this.props.successfulPayment(this.referenceId, this.props.paymentStatus);
                        this.props.paymentSubmitted();
                        this.props.getAutoPayAvailableCC(this.props.accountInfo.id);
                    }
                    else if (this.props.paymentStatus.status === 'USED') {
                        this.props.successfulPayment(this.referenceId, this.props.paymentStatus);
                        this.props.paymentSubmitted();
                        this.props.getAutoPayAvailableCC(this.props.accountInfo.id);
                    }
                    else if (this.props.paymentStatus.status === 'FAIL') {
                        this.props.failedPayment(this.props.paymentStatus);
                    }
                    else {
                        this.props.genericPaymentHandler(this.referenceId, this.props.paymentStatus);
                        this.props.paymentSubmitted();
                        this.props.getAutoPayAvailableCC(this.props.accountInfo.id);
                    }
                }
            }
        }
    }

    submitPaymentForm = (data) => {
        const {accountInfo, getPaymentFormData} = this.props;

        getPaymentFormData(accountInfo.id, data).then((response) => {
            if (response.success === false) {
                console.error(response);
                toastr.error(getErrorMessage(response).message, { timeOut: 5000, position: 'top-center' });
            } else {
                this.setState({submitFormData: true});
                return;
            }
        }).catch(e => {
            console.error(e);
            toastr.error("Something went wrong", { timeOut: 5000, position: 'top-center' });
        });
    }

    submitPaymentWithToken = (data) => {
        const {handlePaymentSuccess, handlePaymentFail, paymentByToken, handlePaymentGeneric} = this.props;

        paymentByToken(data).then(resp => {
            if (resp.success) {
                this.setState({submitFormData: true});
                if (resp.status === 'SUCCESS') {
                    return resp;
                } else if (resp.status === 'FAIL') {
                    return resp;
                } else {
                    handlePaymentGeneric(resp.status);
                }
                return;
            }

            handlePaymentFail({status: 'FAILED', responseText: "Invalid data"});
        });
    }

    renderManagePaymentMethodForm = (accountInfo, data) => {
        const {createPaymentMethodForm} = this.props;
        // Add tab close event listener
        window.addEventListener('beforeunload', this.preventTabClose);

        createPaymentMethodForm(accountInfo.id, data).then(resp => {
            if (resp.success === false) {
                console.error(resp);
                toastr.error(getErrorMessage(resp).message, { timeOut: 5000, position: 'top-center' });
            } else {
                this.setState({submitFormData: true});
                return;
            }

        });
    }

    render() {
        const {loading, formData} = this.props;

        if (loading === true) {
            return (
                <Loader/>
            );
        }

        if ((this.props.paymentMethod !== 'MANAGE_PAYMENT_METHODS') && (this.state.paymentFormSubmitted === true)) {
            return (
                <div className="form-section-customer-payment-management-message">
                    <Loader />
                    <p className="text-center">Finish payment in the new window.</p>
                </div>
            );
        } else if ((this.props.paymentMethod === 'MANAGE_PAYMENT_METHODS')  && (this.state.paymentFormSubmitted === true)) {
            return (
                <div className="form-section-customer-payment-management-message">
                    <button type="button" className="btn btn-sm btn-outline-secondary btn-pay-balance mt-1" onClick={() => this.props.refreshPayProviderStoredPaymentMethods()}>
                        Refresh Payment Methods
                    </button>
                    <p className="text-muted text-xs mb-0">Click Refresh Payment Methods button above after completing work on the other tab.</p>
                </div>
            );
        }

        if (isEmpty(formData)) {
            return null;
        }

            return (
                <Fragment>
                    <div className="cmv-container-customer-pmnt-card">
                        <form method="post" ref={(ref) => {this.paymentForm = ref}} action={formData.action} target={formData.target}>
                            {Object.keys(formData.fields).map((key) => (
                                <input key={key} type="hidden" name={key} value={formData.fields[key]} />
                            ))}
                        </form>
                    </div>
                </Fragment>
            );
    }
}

PaymentForm.propTypes = {
    accountInfo: PropTypes.object.isRequired,
    successfulPayment: PropTypes.func,
    failedPayment: PropTypes.func
};

const getFormDataSelector = createLoadingSelector(['GET_PAYMENT_FORM']);

const mapStateToProps = (state) => {
    const loading = getFormDataSelector(state),
        formData = getAccountDetails(state).payNow.formData,
        paymentStatus = getAccountDetails(state).payNow.paymentStatus,
        paymentStatusCount = getAccountDetails(state).payNow.paymentStatusCount;

    return {
        loading,
        formData,
        paymentStatus,
        paymentStatusCount
    };
};

const mapDispatchToProps = {
    getAutoPayAvailableCC,
    getPaymentFormData,
    clearPaymentFormData,
    getPaymentStatus,
    clearPaymentStatus,
    paymentSubmitted,
    paymentByToken,
    createPaymentMethodForm,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentForm);
