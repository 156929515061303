import axios from "axios";
import { API_ROOT } from "../utils/api-config";
import isEmpty, { actionCreator, createRequestTypes, setCurrentUser } from "../utils/helpers";
import axiosInstance from "../utils/axios-instance";
import { clearViewAs } from "./viewAs.actions";
import _ from "lodash";
import { AUTH_PROVIDER_AUTH0, AUTH_PROVIDER_CAMVIO } from "src/utils/const";
import { dispatchErrorAction } from "./_utils";
// import history from '../utils/history';

export const AUTH = createRequestTypes("AUTH");
export const AUTH_LOGOUT = createRequestTypes("AUTH_LOGOUT");
export const FORGOTTEN_PASSWORD = createRequestTypes("FORGOTTEN_PASSWORD");
export const RESET_FORGOTEN_PASSWORD_TOKEN = createRequestTypes("RESET_FORGOTEN_PASSWORD_TOKEN");
export const RESET_PASSWORD = createRequestTypes("RESET_PASSWORD");

const onAuthSuccess = async (dispatch, data, authProvider = AUTH_PROVIDER_CAMVIO) => {
  data.authProvider = authProvider;
  setCurrentUser(data);

  const summaryResponse = await axiosInstance.get(`/user/summary`);
  const landingUrl = _.get(summaryResponse, "data.user.systemUserRole.landingUrl");

  await dispatch(actionCreator.success(AUTH, data));

  if (!_.isEmpty(landingUrl) && landingUrl !== "/" && landingUrl !== "/home") {
    window.history.push(landingUrl);
  }
};

const onAuthFailure = (dispatch, error) => {
  let message =
    error && error.response && error.response.data && error.response.data.error && error.response.data.error.message;

  if (isEmpty(message)) {
    message = "Not enough permissions to access.";
  }

  const mockError = {
    message: message,
  };

  dispatch(actionCreator.failure(AUTH, mockError));
};

export const login = (username, password, history) => async (dispatch) => {
  await clearViewAs()(dispatch);

  dispatch(actionCreator.request(AUTH));

  let data = {
    j_username: username,
    j_password: password,
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${API_ROOT}/auth/login`,
    params: data,
  };

  await axios(headers)
    .then(async (response) => await onAuthSuccess(dispatch, response.data, AUTH_PROVIDER_CAMVIO))
    .catch((error) => onAuthFailure(dispatch, error));
};

export const forgottenPassword = (email) => async (dispatch) => {
  dispatch(actionCreator.request(FORGOTTEN_PASSWORD));

  let data = {
    email: email,
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${API_ROOT}/auth/password/forgotten`,
    params: data,
  };

  try {
    const response = await axios(headers);
    dispatch(actionCreator.success(FORGOTTEN_PASSWORD, response.data));
    return response.data;
  } catch (err) {
    let mockError = {
      message: err.response.data.message,
    };
    dispatch(actionCreator.failure(FORGOTTEN_PASSWORD, mockError));
    return err.response.data;
  }
};

export const resetTokenValidation = (token) => async (dispatch) => {
  dispatch(actionCreator.request(RESET_FORGOTEN_PASSWORD_TOKEN));

  let data = {
    token: token,
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
    url: `${API_ROOT}/auth/password/resettokenvalidation`,
    params: data,
  };

  try {
    const response = await axios(headers);
    dispatch(actionCreator.success(RESET_FORGOTEN_PASSWORD_TOKEN, response.data));
    return response.data;
  } catch (err) {
    let mockError = {
      message: (err.response && err.response.data && err.response.data.message) || "",
    };
    dispatch(actionCreator.failure(RESET_FORGOTEN_PASSWORD_TOKEN, mockError));
    return err.response.data;
  }
};

export const resetPassword = (newPassword, verificationToken) => async (dispatch) => {
  dispatch(actionCreator.request(RESET_PASSWORD));

  let data = {
    newPassword,
    verificationToken,
  };

  const headers = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    url: `${API_ROOT}/auth/password/reset`,
    data: data,
  };

  try {
    const response = await axios(headers);
    dispatch(actionCreator.success(RESET_PASSWORD, response.data));
    return response.data;
  } catch (err) {
    let mockError = {
      message: err.response.data.message,
    };
    dispatch(actionCreator.failure(RESET_PASSWORD, mockError));
    return err.response.data;
  }
};

export const verifyEmail = (verificationToken) => async (dispatch) => {
  let data = {
    verificationToken: verificationToken,
  };

  try {
    const response = await axiosInstance.post("/verification/email/tokenverify", data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const checkAuthStatus = (history) => {
  return (dispatch) => {
    const user = localStorage.getItem("currentUser");
    if (!user) {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("location");
      dispatch(actionCreator.success(AUTH_LOGOUT));
      // history.push('/')
    } else {
      dispatch(actionCreator.success(AUTH, JSON.parse(user)));
    }
  };
};

/**
 * 
 * @param {*} history 
 * @param {*} noRedirect  - use this param when you are using Auth0 logout
 * @returns 
 */
export const logout = (history, noRedirect = false) => {
  return (dispatch) => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("location");
    dispatch(actionCreator.success(AUTH_LOGOUT));

    if (history && !noRedirect) {
      history.push("/login");
    }
  };
};

/**
 * Login with Auth0 token
 * @param {*} oauthToken
 * @returns
 */
export const loginWithAuth0Token = (tokenDetails, user) => async (dispatch) => {
  const headers = {
    headers: {
      "Content-Type": "application/json",
      "X-API-OAUTH-TOKEN": tokenDetails.id_token,
    },
    method: "GET",
    url: `${API_ROOT}/auth/login/oauth_token`,
    params: {},
  };

  await axios(headers)
    .then(async (response) => await onAuthSuccess(dispatch, response.data, AUTH_PROVIDER_AUTH0))
    .catch((error) => onAuthFailure(dispatch, error));
};


/**
 * Login with Auth0 token
 * @param {*} oauthToken
 * @returns
 */
export const raiseAuthErrorManually = (error) => dispatchErrorAction(AUTH, error)
