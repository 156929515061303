import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import JoditEditor from "jodit-react";

import isEmpty from "src/utils/helpers";
import FormSelect from "src/components/UI/FormSelect";
import TextInput from "src/components/UI/TextInput";

import { hideModal } from "src/actions/modal.actions";
import { getModalData, getUser } from "src/selectors";
import { addNotificationTemplate, updateNotificationTemplate } from "src/actions/notifications.actions";
import LoadingModalContent from "src/components/Modal/LoadingModalContent";
import { notificationTypeOptions } from "src/containers/Notifications/notificationTypes";

/**
 * Add/Edit a notification template
 *
 * @param {any!} modalProps.notification - Notification object
 * @param {any*} modalProps.value - Notification template object, create if not given
 * @param {(item) => void} modalProps.onSuccess - callback function to be called on successful update, called with updated item
 */
class EditNotificationTemplateModal extends Component {
  state = {
    alertMessage: "",
    isLoading: false,
  };

  joditConfig = {
    iframe: true,
    iframeStyle: `html{margin:0px;padding:0;min-height:100%;} body{background:transparent;color:#000;position:relative;z-index:2;user-select:auto;margin:0px;overflow:hidden;box-sizing:border-box;} body:after{content:"";clear:both;display:block}`,
    minHeight: 400,
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "ul",
      "ol",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "superscript",
      "subscript",
      "image",
      "video",
      "file",
      "hr",
      "table",
      "link",
      "symbol",
      "indent",
      "source",
      "fullsize",
      "preview",
    ],
    readonly: false,
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  }

  componentDidMount() {}

  componentWillUnmount() {}

  formSchema = () => {
    let validationSchema = {
      isSystem: Yup.boolean(),
      type: Yup.string().required("Required"),
      sender: Yup.string().required("Required"),
      receiver: Yup.string().when("isSystem", {
        // required only if isSystem is true
        is: true,
        then: Yup.string().required("Required"),
      }),
      subject: Yup.string().when("type", {
        // required only if type == 'EMAIL'
        is: "EMAIL",
        then: Yup.string().required("Required"),
      }),
      template: Yup.string().required("Required"),
    };

    return Yup.object().shape(validationSchema);
  };

  hideModal = () => {
    this.props.hideModal();
  };

  canUseHtmlEditor = (notificationType) => {
    return notificationTypeOptions.find((option) => option.value === notificationType)?.useHtml;
  };

  formSubmit = (values, actions) => {
    this.setState({ alertMessage: "" }, () => {
      const postData = {
        ...values,
        notificationId: this.props.modalProps.notification.id,
      };

      const promise = this.props.modalProps.value?.id
        ? this.props.updateNotificationTemplate(this.props.modalProps.value.id, postData)
        : this.props.addNotificationTemplate(postData);

      promise.then(
        (response) => {
          if (!response.data?.success) {
            throw new Error("Unknown error.");
          }
          if (typeof this.props.modalProps.onSuccess === "function") {
            this.props.modalProps.onSuccess(response.data.notificationTemplate);
          }
          this.hideModal();
        },
        (err) => {
          if (!err.response.data) {
            actions.setSubmitting(false);
            this.setState({
              alertMessage: "Unknown error. Please try again later",
            });
            return;
          }

          if (!err.response.data.success) {
            actions.setSubmitting(false);
            this.setState({ alertMessage: err.response.data.error.message });
            return;
          }
        }
      );
    });
  };

  scrollDropdownIntoView = (e) => {
    const elementClicked = e.target.nodeName;
    const boundingElement = e.currentTarget;
    const modalBody = document.getElementsByClassName("modal-body")[0];

    if (elementClicked !== "LABEL") {
      setTimeout(function () {
        // Scroll down if the bottom is hidden...
        if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {
          // ... and the top won't end up hidden by scrolling down
          if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {
            // Scroll down till bottom of element reaches bottom of screen
            boundingElement.scrollIntoView({ block: "end" });
          }
        }
      }, 200);
    }
  };

  render() {
    const { alertMessage } = this.state;
    const { modalProps } = this.props;
    const { value: notificationTemplate = null, notification } = modalProps;
    const isEdit = modalProps?.value?.id;

    return (
      <Fragment>
        <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
          {this.state.isLoading && <LoadingModalContent modalTitle="Edit Notification Template" submitButtonLabel="Save" />}
          {!this.state.isLoading && (
            <Formik
              initialValues={{
                isSystem: notificationTemplate?.isSystem || false,
                type: notificationTemplate?.type || "",
                sender: notificationTemplate?.sender || "",
                receiver: notificationTemplate?.receiver || "",
                subject: notificationTemplate?.subject || "",
                template: notificationTemplate?.template || "",
              }}
              validationSchema={this.formSchema()}
              onSubmit={this.formSubmit}
            >
              {({
                handleChange,
                handleSubmit,
                handleBlur,
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">{isEdit ? "Edit" : "Add"} Notification Template</h5>
                        <button onClick={this.hideModal} type="button" className="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body cmv-form form-horizontal pt-0">
                        <div className="pt-3"></div>
                        {!isEmpty(alertMessage) && (
                          <div className="alert alert-inline alert-danger alert-dismissible">
                            <p className="mb-0">{alertMessage}</p>
                          </div>
                        )}
                        <div className="order-service-order-wrapper">
                          <div>
                            <h4 className="text-uppercase">{notification?.name}</h4>
                            <p className="billing-job-id">{notification?.description}</p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-xs order-md-6">
                            <span className="h-check ml-1 mr-2">
                              <label htmlFor="edit-notification-template-issystem">System&nbsp;&nbsp;</label>
                              <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                <label>
                                  <input
                                    id="edit-notification-template-issystem"
                                    type="checkbox"
                                    name="isSystem"
                                    checked={values.isSystem}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                  />
                                  <span>&nbsp;</span>
                                </label>
                              </div>
                            </span>
                          </div>
                          <div className="col-xs col-md-6 order-md-0">
                            <div
                              className="form-group position-relative"
                              onClick={this.scrollDropdownIntoView.bind(this)}
                            >
                              <FormSelect
                                title="Channel"
                                fieldName="type"
                                placeholder={"Select one ..."}
                                options={notificationTypeOptions}
                                value={values.type}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                                isLoading={this.state.isLoading}
                                isDisabled={isSubmitting}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <TextInput
                            divClass={values.isSystem ? "col-md-6" : "col-md-12"}
                            label="Sender"
                            name="sender"
                            value={values.sender}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                          />
                          {values.isSystem && (
                            <TextInput
                              divClass="col-md-6"
                              label="Receiver"
                              name="receiver"
                              value={values.receiver}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errors={errors}
                              touched={touched}
                            />
                          )}
                        </div>
                        {values.type === "EMAIL" && (
                          <TextInput
                            label="Subject"
                            name="subject"
                            value={values.subject}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                          />
                        )}
                        <div className="form-group">
                          <label>Body</label>

                          {this.canUseHtmlEditor(values.type) ? (
                            <div className="cmv-ui-component-jodit-raw">
                              <JoditEditor
                                name={"template"}
                                value={values.template}
                                config={this.joditConfig}
                                tabIndex={1} // tabIndex of textarea
                                onChange={(content) => {
                                  setFieldValue("template", content);
                                  setFieldTouched("template", true, true);
                                }}
                              />
                            </div>
                          ) : (
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                label="Template"
                                name="template"
                                value={values.template}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                                rows={8}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button onClick={this.hideModal} className="btn" type="button">
                          Cancel
                        </button>
                        <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps,
    currentUser = getUser(state).user;

  return {
    modalProps,
    currentUser,
  };
};

const mapDispatchToProps = {
  hideModal,
  addNotificationTemplate,
  updateNotificationTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditNotificationTemplateModal);
