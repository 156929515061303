import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { getModalData } from "src/selectors";
import { hideModal } from "src/actions/modal.actions";

/**
 * A common confirm and action modal
 *
 * modalProps.title {string} modal title
 * modalProps.message {string} message
 * modalProps.action {() => promise} action to be performed on confirmation
 */
class ConfirmAndActionModal extends Component {
  closeModal = () => {
    this.props.hideModal();
  };

  action = () => {
    if (typeof this.props.modalProps.action === "function")
      this.props.modalProps.action().then(() => this.props.hideModal());
  };

  render() {
    const { title, message } = this.props.modalProps;
    return (
      <Fragment>
        <div
          className="modal"
          style={{ display: "block" }}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                  type="button"
                  className="close"
                  onClick={this.closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="lead">{message}</div>
              </div>
              <div className="modal-footer">
                <button onClick={this.closeModal} className="btn">
                  No
                </button>
                <button onClick={this.action} className="btn btn-primary">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps;

  return {
    modalProps,
  };
};

const mapDispatchToProps = {
  hideModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmAndActionModal);
