import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import {getModalData} from '../../../selectors/index';
import FormSelect from "../../../components/UI/FormSelect";
import {Formik} from "formik";
import {createAppointmentArea, editAppointmentArea } from "../../../actions/appointmentAreas.actions";
import isEmpty from "../../../utils/helpers";
import TextInput from "../../../components/UI/TextInput";
import {toastr} from "react-redux-toastr";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    appointmentTypeId: Yup.number().required('Required')
});

class AppointmentMappingModal extends Component {

    state = {
        initialValues: {
        },
        dropdownOptions: undefined,
        appointmentTypeOptions: undefined
    }

    componentDidMount() {
        this.generateInitialValues();
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }

    generateInitialValues = () => {
        if(this.props.modalProps.type == "edit") {
            const {mappingAreas, name, description, appointmentTypeId} = this.props.modalProps.row;

            // Generate currently mapped areas
            let currentMappingAreas = [];

            Object.keys(mappingAreas).filter(mappingArea => mappingArea !== 'NOT FOUND').map(mappingAreaName => {
                if(mappingAreas[mappingAreaName] !== 'NOT FOUND') {
                    currentMappingAreas.push({
                        id: mappingAreaName,
                        name: mappingAreas[mappingAreaName],
                    })
                }
            });

            // Store initial values
            this.setState({
                initialValues: {
                    mappingAreas: [...currentMappingAreas],
                    name: name,
                    description: description,
                    appointmentTypeId: appointmentTypeId
                }
            }, () => {
                this.generateDropdownOptions();
            });
        }else{
            this.setState({
                initialValues: {
                    name: '',
                    description: '',
                    mappingAreas: [{
                        id: "",
                        name: ""
                    }]
                },
            }, () => {
                this.generateDropdownOptions();
            });
        }
    }

    generateDropdownOptions = () => {
        const {mappingAreas, availableMappingAreas,
            availableOrderMappingAreas, availableTroubleTicketMappingAreas, appointmentTypes} = this.props.modalProps;

        // Get available mapping areas
        let dropdownOptions = [];
        let appointmentTypeOptions = []

        if(appointmentTypes != undefined && !isEmpty(appointmentTypes)){
            appointmentTypes.map((appointmentType) => {
                appointmentTypeOptions.push({
                    label: appointmentType.description,
                    value: appointmentType.id
                });
            })
        }

        if(this.props.modalProps.type == "edit") {
            Object.keys(availableMappingAreas).map(mappingAreaName => {
                if(!isEmpty(availableMappingAreas[mappingAreaName])) {
                    dropdownOptions.push({
                        label: availableMappingAreas[mappingAreaName],
                        value: {id: mappingAreaName, name: availableMappingAreas[mappingAreaName]}
                    });
                }
            });

            // Get selected mapping areas
            this.state.initialValues.mappingAreas.map(mappingArea => {
                dropdownOptions.push({
                    label: mappingArea.name,
                    value: {id: mappingArea.id, name: mappingArea.name}
                });
            });
        }

        // Order options alphabetically
        dropdownOptions.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
        });

        // Store dropdown options
        this.setState({
            dropdownOptions: dropdownOptions,
            appointmentTypeOptions: appointmentTypeOptions
        });
    }

    formSubmit = (values, action) => {
        let mappingAreasIds = [];

        // Find all mapping area IDs for mapping areas with the selected names
        if(values.mappingAreas[0] && values.mappingAreas[0].id != "") {
            values.mappingAreas.forEach((mappingArea) => {
                mappingAreasIds.push(mappingArea.id);
            });
        }

        if (this.props.modalProps.type == "edit") {
            // Keep the not found mapping area IDs for compatibility
            Object.keys(this.props.modalProps.row.mappingAreas).filter(mappingArea => mappingArea === 'NOT FOUND').map(mappingAreaName => {
                mappingAreasIds.push(...this.props.modalProps.row.mappingAreas[mappingAreaName]);
            });
        }

        // Sort IDs
        mappingAreasIds.sort();

        // Save mapping areas
        if(this.props.modalProps.type == "edit") {
            this.props.editAppointmentArea({
                appointmentMappingIds: mappingAreasIds, id: this.props.appointmentArea.id,
                appointmentAreaDescription: values.description.trim(),
                appointmentAreaName: values.name.trim().toUpperCase(),
            }).then((response) => {
                action.setSubmitting(false);
                if (response && response.success) {
                    this.closeModal();
                } else {
                    toastr.error(response.error.message, {timeOut: 2000, position: 'top-center'});
                }
            })
        }else{
            this.props.createAppointmentArea({name: values.name.trim().toUpperCase(), description: values.description.trim(),
                appointmentMappingIds: mappingAreasIds,
                appointmentTypeId: values.appointmentTypeId}).then(response => {
                action.setSubmitting(false);

                if (response && response.success) {
                    this.closeModal();
                }
                else {
                    let errorMessage = 'Something went wrong';

                    if (response && response.error && response.error.code === 'C00239') {
                        errorMessage = 'Appointment area with this name already exists';
                    }
                    if (response && response.error && response.error.code === 'C00240') {
                        errorMessage = 'Appointment area name can not be empty';
                    }

                    toastr.error(errorMessage, {timeOut: 2000, position: 'top-center'});
                }
            }).catch(error => {
                action.setSubmitting(false);
                toastr.error('Something went wrong', {timeOut: 2000, position: 'top-center'});
            });
        }
    }

    generateDropDownOptions = (availableMappingAreas) => {

        // Get available mapping areas
        let dropdownOptions = [];

        Object.keys(availableMappingAreas).map(mappingAreaName => {
            if(!isEmpty(availableMappingAreas[mappingAreaName])) {
                dropdownOptions.push({
                    label: availableMappingAreas[mappingAreaName],
                    value: {id: mappingAreaName, name: availableMappingAreas[mappingAreaName]}
                });
            }
        });

        // Order options alphabetically
        dropdownOptions.sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
        });

        return dropdownOptions;
    }

    setMappingDropdownOptions = (appointmentTypeId, appointmentTypeOptions, setFieldValue) => {
        let appointmentTypeName = "";
        let dropdownOptions = [];
        appointmentTypeOptions.map(appointmentType => {
            if(appointmentType.value == appointmentTypeId){
                appointmentTypeName = appointmentType.label;
                setFieldValue("appointmentTypeId", appointmentType.value);
            }
        });

        if(appointmentTypeName == "Order"){
            dropdownOptions = this.generateDropDownOptions(this.props.modalProps.availableOrderMappingAreas);
        }

        if(appointmentTypeName == "Trouble Ticket"){
            dropdownOptions = this.generateDropDownOptions(this.props.modalProps.availableTroubleTicketMappingAreas);
        }

        console.log(dropdownOptions);

        // Store dropdown options
        this.setState({
            dropdownOptions: dropdownOptions,
        });
    }

    closeModal = () => {
        this.props.hideModal();

        if (this.props.modalProps.onCloseCallback) {
            this.props.modalProps.onCloseCallback();
        }
    }

    render() {

        const {
            initialValues,
            dropdownOptions,
            appointmentTypeOptions
        } = this.state;

        const {
            modalProps,
        } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">

                    {!isEmpty(initialValues) && dropdownOptions !== undefined &&
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.formSubmit}
                        validationSchema = {validationSchema}
                        render={({
                                     handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     setFieldValue,
                                     setTouched
                                 }) => (
                            <form onSubmit={handleSubmit}>

                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">

                                        <div className="modal-header">
                                            <h5 className="modal-title">{modalProps.type === 'create' ? 'Add Appointment Area' : 'Edit Appointment Area'}</h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <div className="modal-body">

                                            <div className="form-group">
                                                <TextInput
                                                    label="Name"
                                                    name="name"
                                                    value={(values.name.toUpperCase())}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                    disabled={isSubmitting}
                                                    autocomplete={false}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <TextInput
                                                    label="Description"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    errors={errors}
                                                    touched={touched}
                                                    disabled={isSubmitting}
                                                />
                                            </div>

                                            <div className="form-group position-relative">
                                                <div onClick={this.scrollDropdownIntoView}>
                                                    <FormSelect
                                                        title="Appointment Area Type"
                                                        fieldName="appointmentTypeId"
                                                        placeholder="Select Appointment Area Type"
                                                        setFieldValue={setFieldValue}
                                                        setTouched={setTouched}
                                                        value={values.appointmentTypeId}
                                                        options={appointmentTypeOptions}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        onChange={(e,value) => this.setMappingDropdownOptions(value, appointmentTypeOptions, setFieldValue)}
                                                        isMulti={false}
                                                        handleChange={handleChange}
                                                        handleSubmit={handleSubmit}
                                                        isDisabled={isSubmitting || this.props.modalProps.type == "edit"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group position-relative">
                                                <div onClick={this.scrollDropdownIntoView}>
                                                    <FormSelect
                                                        title="Mapping Areas"
                                                        fieldName="mappingAreas"
                                                        placeholder="Select Mapping Areas"
                                                        setFieldValue={setFieldValue}
                                                        value={values.mappingAreas}
                                                        options={dropdownOptions}
                                                        onBlur={handleBlur}
                                                        errors={errors}
                                                        touched={touched}
                                                        isMulti={true}
                                                        handleChange={handleChange}
                                                        handleSubmit={handleSubmit}
                                                        isDisabled={isSubmitting}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="modal-footer">

                                            <button disabled={isSubmitting} onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>

                                            <button disabled={isSubmitting} className="btn btn-primary" type="submit">
                                                Save
                                            </button>

                                        </div>

                                    </div>
                                </div>

                            </form>
                        )}
                    />
                    }

                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps
    const mappingAreas = modalProps.mappingAreas;
    const availableMappingAreas = modalProps.availableMappingAreas;
    const allAppointmentAreas = modalProps.allAppointmentAreas;
    const appointmentArea = modalProps.appointmentArea;

    return {
        modalProps,
        mappingAreas,
        availableMappingAreas,
        allAppointmentAreas,
        appointmentArea,
    };
};

const mapDispatchToProps = {
    hideModal,
    editAppointmentArea,
    createAppointmentArea
};

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentMappingModal);
