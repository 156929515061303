import React from "react";

export default function FormQuantityInput({ value, disabled, onChangeQuantity }) {
  return (
    <div className="form-number input-group input-group-sm" onClick={(e) => e.stopPropagation()}>
      <div className="input-group-prepend">
        <button
          className="btn btn-secondary"
          type="button"
          disabled={disabled}
          onClick={() => onChangeQuantity(value - 1)}
        >
          <i className="fas fa-minus" />
        </button>
      </div>
      <input
        className="form-control"
        type="number"
        value={value || "0"}
        disabled={disabled}
        onChange={(e) => e.target.value !== value && onChangeQuantity(e.target.value)}
      />
      <div className="input-group-append">
        <button
          className="btn btn-secondary"
          type="button"
          disabled={disabled}
          onClick={() => onChangeQuantity(value + 1)}
        >
          <i className="fas fa-plus" />
        </button>
      </div>
    </div>
  );
}
