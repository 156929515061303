import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const NotificationStatusIcon = ({status}) => {
    switch(status) {
        case 'SENT':
            return <i className="fas fa-tag text-success" />
        case 'FAILED':
            return <i className="fas fa-tag text-danger" />
        case 'SKIPPED':
            return <i className="fas fa-ban text-dead" />
        default:
            return <i className="fas fa-tag text-warning" />
    }
}

const Notification = ({notificationInfo, showModal}) => {
    return (
        <li className="client-note">
            <div className="client-note-body">

                <div className="header">

                    <span className="client-note-title">
                        <i className="far fa-clock" />&nbsp;{moment(notificationInfo.createdDate).format('MM/DD/YYYY')}
                        <span className="client-note-status">
                            <NotificationStatusIcon status={notificationInfo.status} />
                        </span>
                    </span>

                    <span className="client-note-toolbar" style={{display:'flex',flexDirection:'column'}}>
                        <span className="badge badge-outline-primary" style={{alignSelf:'flex-end',width:'fit-content'}}>{notificationInfo.type ? notificationInfo.type : ''}</span>
                        <span style={{fontSize: '0.75rem',color: '#6c757d',textAlign:'center',marginTop:'3px'}}>{notificationInfo.receiver}</span>
                    </span>

                </div>

                {notificationInfo.type === 'EMAIL'
                ?
                <div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <p>{notificationInfo.subject ? notificationInfo.subject : notificationInfo.text ? notificationInfo.text : "No data found."}</p>
                    </div>
                    <button
                        type="button"
                        className="btn btn-xs btn-link"
                        onClick={() => showModal('HTML_NOTIFICATION_MODAL', {
                                notificationQueueId: notificationInfo.id,
                                notificationSubject: notificationInfo.subject,
                                notificationContent: notificationInfo.text,
                            })
                        }
                    >
                        View Message
                    </button>
                </div>
                :
                    <p>{notificationInfo.subject ? notificationInfo.subject + ':' : ''} {notificationInfo.text}</p>
                }

            </div>
        </li>
    );
};

Notification.propTypes = {
    notificationInfo: PropTypes.object
};

export default Notification;