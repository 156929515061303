import React from 'react';
import PropTypes from 'prop-types';

const ButtonIcon = ({buttonClass, iconClass, description, ...props}) => {
	return (
		<button className={buttonClass} title={description} {...props}>
			<i className={iconClass} />
		</button>
	);
};

ButtonIcon.propTypes = {
    
};

export default ButtonIcon;
