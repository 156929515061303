import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { dashboardReducer } from './dashboard.reducer';
import { loadingReducer } from './loading.reducer';
import { errorReducer } from './error.reducer';
import { modalReducer } from './modal.reducer';
import { accountSearchReducer } from './accountSearch.reducer';
import { accountDetailsReducer } from './AccountDetailsReducer/index';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { userReducer } from './user.reducer';
import { createAccountReducer } from './createAccount.reducer';
import { globalErrorReducer } from './globalError.reducer';
import { createServiceRequestReducer } from './createServiceRequest.reducer';
import { taskDetailsReducer } from './taskDetails.reducer';
import { tasksListReducer } from './tasksList.reducer';
import { sideBarReducer } from './sideBar.reducer';
import { taskFormReducers } from './taskFormReducers/index';
import { adminReducer } from './admin.reducer';
import { uiControllerReducer } from "./uiController.reducer";
import { viewAsReducer } from './viewAsReducer';
import { domainReducer } from "./domain.reducer";
import { technicianAppointmentReducer } from "./technicianAppointment.reducer";
import { globalFlagsReducer } from "./globalFlags.reducer";
import { provisioningReducer } from "./provisioning.reducer";
import { wizardsReducer } from "../camvio-wizard/reducer";
import { appointmentAreasReducer } from './appointmentAreas.reducer';
import { billingJobsReducer } from "./billingJobs.reducer";
import { autoPayJobsReducer} from "./autoPayJobs.reducer";
import { workflowReducer } from './workflow.reducer';
import { appointmentsReducer } from './appointments.reducer';
import {accountPlanReducer} from "./accountPlan.reducer";
import { paymentProviderReducer } from "./paymentProvider.reducer";
import { workforceReducer } from "./workforce.reducer";
import { productsReducer } from "./products.reducer";
import { taxationReducer } from "./taxation.reducer";
import { troubleTicketCodesReducer } from './troubleTicketCodes.reducer';
import { inventoryReducer } from './inventory.reducer';
import { notificationsReducer } from './notifications.reducer';
import { migrationJobsReducer } from './migrationJobs.reducer'

import { AUTH_LOGOUT, AUTH } from '../actions/auth.actions';
import { systemConfigsReducer } from './systemConfigs.reducer';

const appReducer = combineReducers({
	toastr: toastrReducer,
	auth: authReducer,
	dashboard: dashboardReducer,
	errors: errorReducer,
	loading: loadingReducer,
	modals: modalReducer,
	accountSearch: accountSearchReducer,
	accountDetails: accountDetailsReducer,
	user: userReducer,
	createAccount: createAccountReducer,
	globalError: globalErrorReducer,
	createServiceRequest: createServiceRequestReducer,
	taskDetails: taskDetailsReducer,
	tasksList: tasksListReducer,
	sideBar: sideBarReducer,
	taskForm: taskFormReducers,
	admin: adminReducer,
	uiController: uiControllerReducer,
	products: productsReducer,
	viewAs: viewAsReducer,
	domain: domainReducer,
	technicianAppointment: technicianAppointmentReducer,
	globalFlags: globalFlagsReducer,
	appointment: appointmentAreasReducer,
	workflow: workflowReducer,
	provisioning: provisioningReducer,
	wizards: wizardsReducer,
	billingJobs: billingJobsReducer,
	autoPayJobs: autoPayJobsReducer,
	appointments: appointmentsReducer,
	accountPlan: accountPlanReducer,
	paymentProviders: paymentProviderReducer,
	mappingProviders: workforceReducer,
	workforceProviders: workforceReducer,
	taxation: taxationReducer,
	troubleTicketCodes: troubleTicketCodesReducer,
	inventory: inventoryReducer,
	notifications: notificationsReducer,
	migrationJobs: migrationJobsReducer,
	systemConfigs: systemConfigsReducer,
});

export const rootReducer = (state, action) => {
	if (action.type === AUTH_LOGOUT.SUCCESS) {
		let activeTab = state.user.activeTab
		sessionStorage.setItem('activeTab', activeTab)

		state = {
			systemConfigs: state.systemConfigs,
		};
	}

	// This prevent race conditions when user log-out and log-in with other account
	if (action.type === AUTH.SUCCESS) {
		state = {
			auth: state.auth,
			systemConfigs: state.systemConfigs,
		};
	}

	return appReducer(state, action);
};

