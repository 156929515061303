import React, {Component, Fragment} from "react";
import {getModalData} from "../../../selectors";
import {hideModal} from "../../../actions/modal.actions";
import {connect} from "react-redux";
import {loadBillingJobStatusSummary, loadBillingJobStatusDetails} from "../../../actions/billingJobs.actions";
import Button from "../../../components/Common/Buttons/Button";
import {getAutoPayJobStatusSummary} from "src/actions/autoPayJobs-actions";

class AutoPayJobMonitorModal extends Component {
    state = {
        statusDetails: null,
    }


    componentDidMount() {
        this.fetchAutoPayJobStatusSummary()
    }

    componentWillUnmount() {
        clearInterval(this.refreshSummaryData);
    }

    fetchAutoPayJobStatusSummary = () => {
        this.props.getAutoPayJobStatusSummary(this.props.modalProps.autoPayJobID).then((response) => this.setState({statusSummary: response}))
    }

    //refreshes summary data every 15 sec and removes timer on component unmount
    refreshSummaryData = setInterval(this.fetchAutoPayJobStatusSummary, 15000 )


    closeModal = () => {
        this.props.hideModal();
    };

    render() {
        const {modalProps} = this.props
        const {statusSummary} = this.state
        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">AutoPay Progress</h5>
                                <button type="button" className="close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='order-service-order-wrapper'>
                                    <h4>AutoPay Progress Job</h4>
                                            <p className="billing-job-id">Refreshing every 15 seconds</p>
                                    <div className="order-service-order-wrapper">
                                        <h5>Job #{modalProps.autoPayJobID}</h5>
                                        {statusSummary?.autoPayPercentComplete ?
                                            <>
                                        <span className="d-flex text-muted" title="Show Test Billing Log">
                                            <p className='billing-job-id'>{statusSummary?.autoPayLastMessage}</p>
                                            {/*<small className="d-md-inline-block ml-auto">*/}
                                            {/*    <Button*/}
                                            {/*        // onClick={this.handleShowBillingJobLog}*/}
                                            {/*        className="btn btn-xs btn-order-log-toggle btn-test-billing-log-display"*/}
                                            {/*        type="button"*/}

                                            {/*    >*/}
                                            {/*        <i className="fas fa-list-ul"/>*/}
                                            {/*    </Button>*/}
                                            {/*</small>*/}
                                        </span>
                                            <div className="progress">
                                                <div
                                                    className="progress-bar bg-success-with-pulse"
                                                    role="progressbar"
                                                    style={{width: `${statusSummary?.autoPayPercentComplete}%`}}
                                                    aria-valuenow="100"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                >
                                                    <span style={{whiteSpace: "nowrap"}}>{statusSummary?.autoPayPercentComplete}%</span>
                                                </div>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                            <span>Last Updated {statusSummary?.autoPayLastUpdatedDate}</span>
                                            <span>{statusSummary?.autoPayCompletedMessage}</span>
                                            </div>
                                            </>
                                            :
                                            <p className='billing-job-id'>Not started</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.closeModal} className="btn">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    return {
        modalProps,
    };
};

const mapDispatchToProps = {
    loadBillingJobStatusSummary,
    loadBillingJobStatusDetails,
    hideModal,
    getAutoPayJobStatusSummary,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoPayJobMonitorModal);

