import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import {
    getTroubleTickets,
    emptyTroubleTickets,
    getTroubleTicketsServiceIcons,
    emptyTroubleTicketsServiceIcons,
} from '../../../../actions/accountDetailsActions/troubleTickets.actions';
import {showModal, showAccessModal} from '../../../../actions/modal.actions';
import {createLoadingSelector, getAccountDetails} from '../../../../selectors';
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {groupStyles} from "../../../../utils/SelectStyles";
import Button from "../../../../components/Common/Buttons/Button";
import {getFormattedFilters} from "./utils";
import Loader from "../../../../components/Loader";
import {isEmpty} from "../../../../utils/helpers";
import Ticket from "../../../AccountDetails/Panels/LeftPanel/Tabs/TroubleTickets/Ticket";

class Tickets extends Component {

    state = {
        searchOptions: [
            {value: 'myTroubleTickets', label: 'My Tickets', placeholder: ''},
            {value: 'allTroubleTickets', label: 'All Tickets', placeholder: ''},
            {value: 'completed', label: 'Completed Tickets', placeholder: ''},
            {value: 'incomplete', label: 'Incomplete Tickets', placeholder: ''},
            {value: 'ticketId', label: 'Search by Ticket #', placeholder: 'Search by Ticket #'},
        ],
        searchType: {value: 'myTroubleTickets', label: 'My Tickets', placeholder: ''},
        filters: {
            myTroubleTickets: true,
            all: false,
            completed :false,
            incomplete:false,
            troubleTicketId: '',
            fromDate: '',
            toDate: '',
            limit: 20,
        },
        filterType: 'limit',
        filterValue: '20',
        filterButtonText: "Last 20 Tickets",
        filterButtonTextAdditional: "",
        isFilterDropDownOpen: false,
        isFetched: false,
        showTicketDropDownById: ''
    };

    componentDidMount() {
        const { user } = this.props;

        this.props.getTroubleTickets({myTroubleTickets: true, limit: 20});
        this.props.getTroubleTicketsServiceIcons(user.id);
    }

    componentWillUnmount() {
        this.props.emptyTroubleTickets();
        this.props.emptyTroubleTicketsServiceIcons();
    }

    closeFilterDropdown = () => {
        this.setState({isFilterDropDownOpen: false});
    }

    handleClickDropdown = () => {
        this.setState((prevState) => ({
            isFilterDropDownOpen: !prevState.isFilterDropDownOpen
        }));
    };

    handleLimitFilter = (limit) => {
        let updateFilter = { ...this.state.filters };
        updateFilter['limit'] = limit;
        updateFilter['toDate'] = '';
        updateFilter['fromDate'] = '';

        this.setState(
            {
                filters: updateFilter,
                filterType: 'limit',
                filterValue: limit,
                isFilterDropDownOpen: false,
                isFetched: false,
            }, () => {
                this.renderButtonText()
                this.getTicketsByFilters()
            }
        );
    };

    handleLastDaysFilter = (lastDays) => {
        let fromDate = moment().subtract(lastDays, 'days').toDate();
        let toDate = moment().toDate();

        let updateFilter = { ...this.state.filters };
        updateFilter['limit'] = '';
        updateFilter['fromDate'] = fromDate;
        updateFilter['toDate'] = toDate;

        this.setState(
            {
                filters: updateFilter,
                filterType: 'lastDays',
                filterValue: lastDays,
                isFilterDropDownOpen: false,
                isFetched: false,
            }, () => {
                this.renderButtonText()
                this.getTicketsByFilters()
            }
        );
    };

    handleDateFilter = (value, name) => {
        let updateFilter = { ...this.state.filters };
        updateFilter['limit'] = '';
        updateFilter[name] = value;

        this.setState(
            {
                filters: updateFilter,
                filterType: 'dateFromTo',
                isFilterDropDownOpen: false,
                isFetched: false,
            }, () => {
                this.renderButtonText()
                this.getTicketsByFilters()
            }
        );
    };

    renderButtonText = () => {
        const { filters, filterType, filterValue } = this.state;

        let filterButtonText = "";
        let filterButtonTextAdditional = "";

        if (filterType === 'dateFromTo') {
            filterButtonText = filters.fromDate && moment(filters.fromDate).format('MM/DD/YYYY');
            if (filters.fromDate.getTime() !== filters.toDate.getTime()) {
                filterButtonTextAdditional = filters.toDate && moment(filters.toDate).format('MM/DD/YYYY');
            }
        } else if (filterType === 'limit') {
            filterButtonText = `Last ${filterValue} Tickets`;
        } else if (filterType === 'lastDays') {
            filterButtonText = `Last ${filterValue} Days`;
        }

        this.setState({
            filterButtonText: filterButtonText,
            filterButtonTextAdditional: filterButtonTextAdditional,
        })
    };

    handleChangeSearchId = (e) => {
        this.setState({
            filters: {
                ...this.state.filters,
                troubleTicketId: e.target.value
            }
        });
    };

    changeSearchType = (searchValue) => {

        this.setState({
            searchType: searchValue,
            filters: {
                ...this.state.filters,
                myTroubleTickets: searchValue.value === 'myTroubleTickets',
                all: searchValue.value === 'allTroubleTickets',
                troubleTicketId: '',
            },
            showTicketDropDownById: '',
            isFilterDropDownOpen: false,
            isFetched: false,
        },
        () => {
            if (searchValue.value !== 'ticketId') {
                this.props.getTroubleTickets(getFormattedFilters(this.state.filters,searchValue.value));
            }
        });
    };

    getTicketsByFilters = () => {

        if (this.state.searchType.value === 'ticketId') {
            this.props.getTroubleTickets({troubleTicketId: this.state.filters.troubleTicketId});
        }
        else {
            this.props.getTroubleTickets(getFormattedFilters(this.state.filters,this.state.searchType.value));
        }
    };

    getTransformedSearchTypeParams = () => {
        const { searchType } = this.state;

        let params = {};

        if (searchType.label === 'My Tickets') {
            params = {
                username: searchType.value
            };
        }
        return params;
    };

    handleAssignTicketToUsername = (type, ticketId, dropdownValue) => {
        const { user } = this.props;

        if (type === 'claim') {
            const data = {
                troubleTicketId: ticketId,
                username: user.username
            };

            let searchTypeParams = this.getTransformedSearchTypeParams();
            // this.props.assignTicket(data, searchTypeParams);
        } else if (type === 'unassign') {
            const data = {
                troubleTicketId: ticketId
            };
            let searchTypeParams = this.getTransformedSearchTypeParams();

            // this.props.unassignTicket(data, searchTypeParams);
        } else if (type === 'username') {
            const data = {
                troubleTicketId: ticketId,
                username: dropdownValue.value
            };
            let searchTypeParams = this.getTransformedSearchTypeParams();

            // this.props.assignTicket(data, searchTypeParams);
        }

        this.setState({
            showTicketDropDownById: ''
        });
    };

    appointmentModalCallback = () => {
        this.getTicketsByFilters();
    }

    accountAccessModal = (id) => {
        this.props.showAccessModal(true);
        this.props.showModal('ACCOUNT_ACCESS_MODAL', { accountId: id });
    }

    render() {

        const {
            searchOptions,
            searchType,
            filters,
            filterButtonText,
            filterButtonTextAdditional,
            isFilterDropDownOpen,
            isFetched,
            showTicketDropDownById
        } = this.state;

        const {
            dashboardMenuCount,
            ticketsLoader,
            tickets,
            serviceIconsLoader,
            serviceIcons,
            user,
            username
        } = this.props;

        return (
            <Fragment>
                <div className="tab-pane-caption">
                    <h1 className="tab-pane-title">{dashboardMenuCount.pendingTroubleTickets || 0} Tickets</h1>
                    <p className="tab-pane-text">still pending</p>
                </div>
                <div className="cmv-container-dashboard-search">
                    <div className="cmv-container-dashboard-filter">
                        <div className="container">
                            <div className="form-row">
                                <div className="col-md-2">

                                    <div className="dropdown dropdown-cn-timefilter show">

                                        <button
                                            type="button"
                                            onClick={this.handleClickDropdown}
                                            className={'btn btn-outline-secondary dropdown-toggle' + (isFilterDropDownOpen ? ' show' : '') + (filterButtonTextAdditional ? ' dropdown-toggle-multiline' : '')}
                                            disabled={ticketsLoader || searchType.value === 'ticketId'}
                                        >
                                            {isEmpty(filterButtonTextAdditional)
                                                ? filterButtonText
                                                : <div><span>{filterButtonText}</span><span>{filterButtonTextAdditional}</span></div>
                                            }
                                        </button>

                                        <div className={'dropdown-menu dropdown-menu-right' + (isFilterDropDownOpen ? ' show' : '')}
                                             onBlur={this.onBlurChangeActiveDropDown}
                                        >

                                            <button className="dropdown-item" onClick={() => this.handleLimitFilter(20)}>Last 20 Tickets</button>
                                            <button className="dropdown-item" onClick={() => this.handleLimitFilter(50)}>Last 50 Tickets</button>
                                            <button className="dropdown-item" onClick={() => this.handleLimitFilter(100)}>Last 100 Tickets</button>

                                            <div className="dropdown-divider" />

                                            <button className="dropdown-item" onClick={() => this.handleLastDaysFilter(30)}>Last 30 Days</button>
                                            <button className="dropdown-item" onClick={() => this.handleLastDaysFilter(60)}>Last 60 Days</button>
                                            <button className="dropdown-item" onClick={() => this.handleLastDaysFilter(90)}>Last 90 Days</button>

                                            <div className="dropdown-divider" />

                                            <h6 className="dropdown-header">From</h6>

                                            <div className="form-group col">
                                                <div className="input-group input-group-sm date" id="datetimepicker1" data-target-input="nearest">
                                                    <DatePicker
                                                        className="form-control datetimepicker-input"
                                                        selected={filters.fromDate}
                                                        onChange={(value) => this.handleDateFilter(value, 'fromDate')}
                                                        placeholderText="MM/DD/YYYY"
                                                        name="fromDate"
                                                    />
                                                </div>
                                            </div>

                                            <h6 className="dropdown-header">To</h6>

                                            <div className="form-group col">
                                                <div className="input-group input-group-sm date" id="datetimepicker2" data-target-input="nearest">
                                                    <DatePicker
                                                        className="form-control datetimepicker-input"
                                                        selected={filters.toDate}
                                                        onChange={(value) => this.handleDateFilter(value, 'toDate')}
                                                        placeholderText="MM/DD/YYYY"
                                                        name="toDate"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-5">

                                    <Select
                                        id="searchType"
                                        name="searchType"
                                        value={searchType}
                                        onChange={this.changeSearchType}
                                        onFocus={this.closeFilterDropdown}
                                        options={searchOptions}
                                        defaultValue={searchOptions[0]}
                                        styles={groupStyles}
                                        isDisabled={ticketsLoader}
                                    />

                                </div>
                                <div className="col-md-5">

                                    <div className="input-group">

                                        <input
                                            type="text"
                                            id="term"
                                            name="term"
                                            className="form-control"
                                            placeholder={searchType.placeholder}
                                            autoComplete="off"
                                            value={filters.troubleTicketId}
                                            onChange={this.handleChangeSearchId}
                                            onFocus={this.closeFilterDropdown}
                                            disabled={ticketsLoader || ['myTroubleTickets', 'allTroubleTickets'].includes(searchType.value)}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {this.getTicketsByFilters()}
                                            }}
                                        />

                                        <div className="input-group-append">

                                            <Button
                                                type="button"
                                                className="btn btn-primary btn-show-cam-search-results"
                                                disabled={ticketsLoader || ['myTroubleTickets', 'allTroubleTickets'].includes(searchType.value)}
                                                onClick={() => {
                                                    this.closeFilterDropdown();
                                                    this.getTicketsByFilters();
                                                }}
                                            >
                                                <i className="fas fa-search" />
                                                <span>&nbsp;Search</span>
                                            </Button>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {(ticketsLoader || serviceIconsLoader) &&
                <Loader />
                }

                {!ticketsLoader && !serviceIconsLoader && isEmpty(tickets) &&
                <div className="container">
                    <p>No data found</p>
                </div>
                }

                {!ticketsLoader && !serviceIconsLoader && !isEmpty(tickets) &&
                <div className="cmv-container-ticket-search-results">
                    {tickets.map(ticket =>
                        <Ticket
                            troubleTicketId={ticket.troubleTicketDetails.troubleTicketId}
                            status={ticket.troubleTicketDetails.status}
                            createdById={ticket.troubleTicketDetails.createdById}
                            createdByName={ticket.troubleTicketDetails.createdByName}
                            createdAt={ticket.troubleTicketDetails.createdAt}
                            customerAccountId={ticket.troubleTicketDetails.customerAccountId}
                            reportedIssueId={ticket.troubleTicketDetails.reportedIssueId}
                            reportedIssueDescription={ticket.troubleTicketDetails.reportedIssueDescription}
                            contactPhoneNumber={ticket.troubleTicketDetails.contactPhoneNumber}
                            dueDate={ticket.dueDate}
                            latestNote={ticket.troubleTicketDetails.latestNote}
                            pendingTasks={ticket.troubleTicketDetails.pendingTasks}
                            serviceLines={ticket.troubleTicketDetails.serviceLines}
                            serviceIcons={serviceIcons}
                            serviceInfo={ticket}
                            troubleTicketDetails={ticket.troubleTicketDetails}
                            appointmentModalCallback={() => this.appointmentModalCallback()}
                            accountAccessModal={this.accountAccessModal}
                        />
                    )}
                </div>
                }

            </Fragment>
        )
    }
}

const getLoaderTroubleTickets = createLoadingSelector(['GET_TROUBLE_TICKETS']);
const getLoaderServiceIcons = createLoadingSelector(['GET_TROUBLE_TICKETS_SERVICE_ICONS']);

const mapStateToProps = (state) => {

    const ticketsLoader = getLoaderTroubleTickets(state);
    const tickets = getAccountDetails(state).troubleTickets.troubleTickets;
    const serviceIconsLoader = getLoaderServiceIcons(state);
    const serviceIcons = getAccountDetails(state).troubleTickets.troubleTicketsServiceIcons;

    return {
        ticketsLoader,
        tickets,
        serviceIconsLoader,
        serviceIcons,
    };
};

const mapDispatchToProps = {
    getTroubleTickets,
    emptyTroubleTickets,
    getTroubleTicketsServiceIcons,
    emptyTroubleTicketsServiceIcons,
    showModal,
    showAccessModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
