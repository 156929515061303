import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes, getErrorMessage} from '../utils/helpers';

export const GET_SERVICE_LINE_ALLOWED_STATUS_TRANSITIONS = createRequestTypes('GET_SERVICE_LINE_STATUS_TRANSITIONS');
export const TRIGGER_SERVICE_LINE_STATUS_TRANSITION = createRequestTypes('TRIGGER_SERVICE_LINE_STATUS_TRANSITION');
export const UPDATE_SERVICELINE_DEVICE_ATTRIBUTES = createRequestTypes('UPDATE_SERVICELINE_DEVICE_ATTRIBUTES');
export const VALIDATE_SERVICELINE = createRequestTypes('VALIDATE_SERVICELINE');

export const getAllowedServiceLineStatusTransitions = (accountId, serviceLineId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_SERVICE_LINE_ALLOWED_STATUS_TRANSITIONS));

	try {
		const response = await axiosInstance.get(`/account/${accountId}/service-line/${serviceLineId}/allowed-transitions`);
		dispatch(actionCreator.success(GET_SERVICE_LINE_ALLOWED_STATUS_TRANSITIONS, response.data.statusTransitions));

		return response;
	} catch(err) {
		dispatch(actionCreator.failure(GET_SERVICE_LINE_ALLOWED_STATUS_TRANSITIONS, getErrorMessage(err)));

		return err;
	}
};

export const triggerServiceLineStatusTransition = (accountId, serviceLineId, toStatus) => async (dispatch) => {
	dispatch(actionCreator.request(TRIGGER_SERVICE_LINE_STATUS_TRANSITION));

	try {
		const response = await axiosInstance.post(`/account/${accountId}/service-line/${serviceLineId}/status-transition?toStatus=${toStatus}`);
		dispatch(actionCreator.success(TRIGGER_SERVICE_LINE_STATUS_TRANSITION));

		return response;
	} catch(err) {
		dispatch(actionCreator.failure(TRIGGER_SERVICE_LINE_STATUS_TRANSITION, getErrorMessage(err)));

		return err;
	}
}

export const updateServiceLineDeviceAttributes = (serviceLineId, servicelineDeviceId, values) => async (dispatch) => {
	dispatch(actionCreator.request(UPDATE_SERVICELINE_DEVICE_ATTRIBUTES));

	try {
		let mapValue = {valuesMap: JSON.stringify(values)}
		const response = await axiosInstance.post(`/service-line-device/${serviceLineId}/${servicelineDeviceId}/update-attributes`, mapValue);
		dispatch(actionCreator.success(UPDATE_SERVICELINE_DEVICE_ATTRIBUTES));

		return response;
	} catch(err) {
		dispatch(actionCreator.failure(UPDATE_SERVICELINE_DEVICE_ATTRIBUTES, getErrorMessage(err)));

		return err;
	}
}

export const validateServiceLineStatus = (directoryNum, interfaceList) => async (dispatch) => {
	dispatch(actionCreator.request(VALIDATE_SERVICELINE));

	try {
		// let mapValue = {valuesMap: JSON.stringify(values)}
		let validateServiceRequest = {interfaces: interfaceList}
		const response = await axiosInstance.post(`/provisioning/validate-service/` + directoryNum, validateServiceRequest);
		dispatch(actionCreator.success(VALIDATE_SERVICELINE));
		return response.data;
	} catch(err) {
		dispatch(actionCreator.failure(VALIDATE_SERVICELINE, getErrorMessage(err)))
	}
}