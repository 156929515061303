import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {getAppInfo} from "../../../../actions/sideBar.actions";
import {getSideBar} from "../../../../selectors";
import isEmpty from "../../../../utils/helpers";
import {compact} from 'lodash'
import {getUser} from "../../../../selectors";

class OperationModuleMenu extends Component {

    state = {
        activeSubMenuItem: '',
    }

    changeSubMenuItem = (subMenuItem) => {
        if (this.state.activeSubMenuItem === subMenuItem) {
            this.setState({activeSubMenuItem: ''});
        } else {
            this.setState({activeSubMenuItem: subMenuItem});
        }
    }

    hasPermission = permission => !isEmpty(this.props.permissions) && this.props.permissions.includes(permission);

    hasMenuPermission = permission => !isEmpty(this.props.userPermissionMenu) && this.props.userPermissionMenu.permissions.includes(permission);

    renderAppointmentsMenu = () => {

        if (!this.hasMenuPermission('SHOW_MENU_ADMIN') || !this.hasPermission("APPT_CAPACITY_VIEW")) {
            return null;
        }

        const { activeMenu, appInfo, changeActiveMenu, permissions } = this.props;
        const { activeSubMenuItem } = this.state;

        return (
            <li key={"Appointments"} className={activeMenu === 'Appointments' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('Appointments')}>
                    <i className="fas fa-clock"/>
                    <span>Appointments</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'Appointments' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/capacity-configuration' }}>Capacity</Link>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    renderBillingMenu = () => {

        if (!this.hasMenuPermission('SHOW_MENU_ADMIN') || !this.hasMenuPermission("SHOW_BILLING_JOBS")) {
            return null;
        }

        const { activeMenu, appInfo, changeActiveMenu } = this.props;
        const { activeSubMenuItem } = this.state;

        return (
            <li key={"BillingJobs"} className={activeMenu === 'Billing' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('Billing')}>
                    <i className="fa fa-file-invoice-dollar"/>
                    <span>Billing</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'Billing' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/billing-jobs' }}>Billing Jobs</Link>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    renderAutoPayMenu = () => {

        if (!this.hasMenuPermission('SHOW_MENU_ADMIN') || !this.hasPermission("ADMN_VIEW_AUTOPAY_JOBS")) {
            return null;
        }

        const { activeMenu, appInfo, changeActiveMenu } = this.props;

        return (
            <li key={"AutoPayJobs"} className={activeMenu === 'AutoPay' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('AutoPay')}>
                    <i className="fa fa-file-invoice-dollar"/>
                    <span>AutoPay</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'AutoPay' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/autopay-jobs' }}>AutoPay Jobs</Link>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    renderInventoryMenu = () => {
        if (!this.hasMenuPermission("SHOW_MENU_OPR_INVENTORY")) {
            return null;
        }

        const { activeMenu, appInfo, changeActiveMenu } = this.props;
        const { activeSubMenuItem } = this.state;
        const canViewSerialized = this.hasPermission("OPER_INVENTORY_SER_VIEW")

        return (
            <li key={"Inventory"} className={activeMenu === 'Inventory' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('Inventory')}>
                    <i className="fas fa-stream"/>
                    <span>Inventory</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'Inventory' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        {canViewSerialized && (
                            <li className="sidebar-nav-link">
                                <Link to={{ pathname: '/inventory/serialized' }}>Serialized</Link>
                            </li>
                        )}
                    </ul>
                </div>
            </li>
        )
    }

    renderProvisioningMenu = () => {

        if (!this.hasMenuPermission('SHOW_MENU_ADMIN') || !this.hasMenuPermission("SHOW_MANUAL_PROVISIONING")) {
            return null;
        }

        const { activeMenu, appInfo, changeActiveMenu } = this.props;
        const { activeSubMenuItem } = this.state;

        return (
            <li key={"Provisioning"} className={activeMenu === 'Provisioning' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
                <a onClick={() => changeActiveMenu('Provisioning')}>
                    <i className="fas fa-stream"/>
                    <span>Provisioning</span>
                </a>

                <div className="sidebar-submenu"
                     style={activeMenu === 'Provisioning' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                    <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/provisioning-tool' }}>Provisioning Tool</Link>
                        </li>
                        <li className="sidebar-nav-link">
                            <Link to={{ pathname: '/provisioning-queue' }}>Provisioning Queue</Link>
                        </li>
                    </ul>
                </div>
            </li>
        )
    }

    renderMigrationMenu = () => {
        const { activeMenu, appInfo, changeActiveMenu, permissions, userInfo } = this.props;

        if(userInfo.systemUserRole.name !== "SUPER_ADMIN") {
            return null
        }

        return (
        <li key={"Migration"} className={activeMenu === 'Migration' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
            <a onClick={() => changeActiveMenu('Migration')}>
                <i className="fas fa-stream"/>
                <span>Migration</span>
            </a>

            <div className="sidebar-submenu"
                 style={activeMenu === 'Migration' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                <ul>
                        <li className="sidebar-nav-link">
                            <Link to={{pathname: '/migration'}}>
                                Migration
                            </Link>
                        </li>
                </ul>
            </div>
        </li>
        )
    }


    renderQueuesMenu = () => {
        const { activeMenu, appInfo, changeActiveMenu, permissions, userInfo } = this.props;
        if (!this.hasMenuPermission("SHOW_MENU_OPER_NOTIFICATION_QUEUE")) {
            return null;
        }

        const permNotificationQueueView = this.hasPermission("OPER_NOTIFICATION_QUEUE_VIEW")
        if (!permNotificationQueueView) {
            return null;
        }

        return (
        <li key={"Queues"} className={activeMenu === 'Queues' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}>
            <a onClick={() => changeActiveMenu('Queues')}>
                <i className="fas fa-grip-lines-vertical"/>
                <span>Queues</span>
            </a>

            <div className="sidebar-submenu"
                 style={activeMenu === 'Queues' && !isEmpty(appInfo) ? {display: 'block'} : {display: 'none'}}>
                <ul>
                    { permNotificationQueueView && (
                        <li className="sidebar-nav-link">
                            <Link to={{pathname: '/notification-queue'}}>
                                Notifications
                            </Link>
                        </li>
                    )}
                        
                </ul>
            </div>
        </li>
        )
    }

    renderSubMenuItems = () => {
        const subMenuItems = compact([
            this.renderAppointmentsMenu(),
            this.renderBillingMenu(),
            this.renderAutoPayMenu(),
            this.renderInventoryMenu(),
            this.renderProvisioningMenu(),
            this.renderMigrationMenu(),
            this.renderQueuesMenu(),
        ]);

        return subMenuItems;
    }

    render() {

        const {activeMenu} = this.props;

        const subMenuItems = this.renderSubMenuItems();

        if (isEmpty(subMenuItems)) {
            return null;
        }

        return (
            <ul>
                <li className="header-menu">
                    <span>Operation</span>
                </li>
                {subMenuItems}
            </ul>
        );
    }
}

OperationModuleMenu.propTypes = {
    activeMenu: PropTypes.string.isRequired,
    changeActiveMenu: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
};


const mapStateToProps = (state) => {

    const appInfo = getSideBar(state).appInfo;
    const userInfo = getUser(state).user

    return {
        appInfo,
        userInfo
    };
};

const mapDispatchToProps = {
    getAppInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationModuleMenu);
