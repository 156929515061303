import { createRequestTypes } from "../../utils/helpers";
import {
  dispatchClearAction,
  httpGetAndDispatchAction,
  httpPostAndDispatchAction,
  serializeParamsToQueryString,
} from "../_utils";

export const LIST_ACCOUNT_SALES_ORDERS = createRequestTypes("LIST_ACCOUNT_SALES_ORDERS");
export const PRESERVE_SALES_ORDER_NUM = createRequestTypes("PRESERVE_SALES_ORDER_NUM");
export const LOOKUP_SALES_ITEM = createRequestTypes("LOOKUP_SALES_ITEM");
export const CREATE_SALES_ORDER = createRequestTypes("CREATE_SALES_ORDER");
export const UPDATE_SALES_ORDER = createRequestTypes("UPDATE_SALES_ORDER");
export const GET_SALES_ORDER = createRequestTypes("GET_SALES_ORDER");

/**
 * Get Sales Orders of the current account
 * @returns Promise<Response>
 */
export const getNextSalesOrderNumber = () =>
  httpGetAndDispatchAction(PRESERVE_SALES_ORDER_NUM, "/salesorders/next-number", "nextNumber");

export const clearNextSalesOrderNumber = () => dispatchClearAction(PRESERVE_SALES_ORDER_NUM);

/**
 * Lookup sales items to add to a sales order
 * @returns Promise<Response>
 */
export const lookupSalesItems = (locationId, term) =>
  httpGetAndDispatchAction(
    LOOKUP_SALES_ITEM,
    `/salesorders/items/quick-lookup?${serializeParamsToQueryString({ locationId, term })}`,
    "items"
  );

/**
 * Get Sales Orders of the current account
 * @returns Promise<Response>
 */
export const getAccountSalesOrders = (accountId) =>
  httpGetAndDispatchAction(LIST_ACCOUNT_SALES_ORDERS, `/account/${accountId}/salesorders`, "salesorders");

export const clearAccountSalesOrders = () => dispatchClearAction(LIST_ACCOUNT_SALES_ORDERS);

/**
 * Create a new Sales Order
 * @returns Promise<Response>
 */
export const createSalesOrder = (params) => httpPostAndDispatchAction(CREATE_SALES_ORDER, "/salesorders", params);

/**
 * Update a new Sales Order
 * @returns Promise<Response>
 */
export const updateSalesOrder = (salesorderId, params) =>
  httpPostAndDispatchAction(UPDATE_SALES_ORDER, `/salesorder/${salesorderId}`, params);

/**
 * Get a Sales Order
 * @returns Promise<Response>
 */
export const getSalesOrder = (salesorderId) =>
  httpGetAndDispatchAction(GET_SALES_ORDER, `/salesorder/${salesorderId}`, "salesorder");
