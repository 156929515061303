import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";

import { getUser, getGlobalError, getAuthData, getModalData, getErrorMessage, getSystemConfigs } from "../../selectors";
import {
  getUserConfiguration,
  getUserSummary,
  getUserPermission,
  emptyUserPermission,
  emptyUserSummary,
  emptyErrorMessages,
} from "../../actions/user.actions";
import { logout, loginWithAuth0Token, raiseAuthErrorManually } from "../../actions/auth.actions";
import { showModal } from "../../actions/modal.actions";
import { showToastrError500 } from "./utils";
import { isEmpty, getAuth0ErrorMessage } from "../../utils/helpers";
import PrivateRoute from "./PrivateRoute";
import NotFound from "../NotFound/NotFound";
import Loader from "../../components/Loader";
import { routes } from "./routeConfig";

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: [],
    };
    this.timer = null;
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  afterLogin() {
    this.props.getUserPermission();
    this.props.getUserConfiguration();
    this.props.getUserSummary().then((response) => {
      if (response.isTempPassword === true) {
        this.props.showModal("PASSWORD_MODAL");
      }
    });
  }

  componentDidMount() {
    const { isLoggedIn, isUsingAuth0, auth0 } = this.props;

    if (isLoggedIn) {
      this.afterLogin();
    } else if (isUsingAuth0 && auth0.user) {
      this.loginWithAuth0Token();
    } else {
      // this is to extract the error codes coming from Auth0
      if (window.location.search) {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("error") && searchParams.has("error_description") && searchParams.has("state")) {
          this.props.raiseAuthErrorManually(getAuth0ErrorMessage({
            error: searchParams.get("error"),
            error_description: searchParams.get("error_description"),
            state: searchParams.get("state"),
          }));
        }
      }
      this.props.history.push({
        pathname: "/login",
        state: { from: this.props.location },
      });
    }
  }

  componentDidUpdate(prevProps) {
    // User just logged out
    if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn === false) {
      this.logout(this.props.history);
    }

    if (this.props.globalError !== prevProps.globalError) {
      showToastrError500(this.props.globalError);
    }
  }

  componentWillUnmount() {
    this.props.emptyUserPermission();
    this.props.emptyUserSummary();
    this.props.emptyErrorMessages();
  }

  _onAction(e) {}

  _onActive(e) {}

  _onIdle(e) {
    this.logout(this.props.history);
  }

  logout(history = undefined) {
    if (this.props.isUsingAuth0) {
      this.props.logout(history, true);
      this.props.auth0.logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }
    this.props.logout(history);
  }

  async loginWithAuth0Token() {
    const { user, getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently({ cacheMode: "cache-only", detailedResponse: true });
    // logged in through Auth0, but not yet logged in to CMV
    await this.props.loginWithAuth0Token(token, user);
    this.afterLogin();
  }

  render() {
    const { userConfiguration, userPermission, isShowModal, user, userSummaryError, userPermissionError } = this.props;

    let idleTime;

    if (!isEmpty(userConfiguration)) {
      let getIdleTime = userConfiguration.find((x) => x.key === "ui.idle.timeout");
      idleTime = 1000 * 60 * Number(getIdleTime.value);
    }

    if (!isEmpty(userSummaryError) || !isEmpty(userPermissionError)) {
      this.logout();
    }

    if (isEmpty(user) || isEmpty(userPermission)) {
      return <Loader />;
    }

    return (
      <div className={isShowModal ? "cmv-page modal-open" : "cmv-page"}>
        {localStorage.getItem("stayIn") !== true && (
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={idleTime}
            // stopOnIdle={true}
          />
        )}
        <Switch>
          <Route exact path={`/`} render={() => <Redirect to="/home" />} />
          {routes.map((route) => (
            <PrivateRoute
              key={route.url}
              path={route.url}
              component={route.component}
              layout={route.layout}
              permissions={route.permissions}
              {...this.props}
            />
          ))}
          <Route path="*" component={NotFound} {...this.props} />
        </Switch>
      </div>
    );
  }
}

const getUserSummaryError = getErrorMessage(["SET_USER_SUMMARY"]);
const getUserPermissionError = getErrorMessage(["SET_USER_PERMISSION"]);

const mapStateToProps = (state) => {
  return {
    isLoggedIn: getAuthData(state).isLoggedIn,
    isUsingAuth0: getSystemConfigs(state).isUsingAuth0,
    userPermission: getUser(state).userPermission,
    userConfiguration: getUser(state).configuration,
    globalError: getGlobalError(state).error,
    isShowModal: getModalData(state).showModal,
    user: getUser(state).user,
    userSummaryError: getUserSummaryError(state),
    userPermissionError: getUserPermissionError(state),
  };
};

const mapDispatchToProps = {
  loginWithAuth0Token,
  logout,
  showModal,
  getUserConfiguration,
  getUserSummary,
  getUserPermission,
  emptyUserPermission,
  emptyUserSummary,
  emptyErrorMessages,
  raiseAuthErrorManually,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(MainLayout));
