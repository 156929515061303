import React, {Component} from 'react';
import {connect} from "react-redux";
import {showModal} from "../../../../../actions/modal.actions";
import isEmpty from "../../../../../utils/helpers";
import _ from 'lodash'

export class Appointment extends Component {

    render() {

        const {
            appointmentKey,
            appointment,
            showDropdownById,
            accountAccessModal,
            handleAllTasksAssignee,
            handleShowAssignDropdownById,
            usernameOptions,
            isAssigning,
            mapVisible,
            serviceAddressAccessModal
        } = this.props;

        return (
            <div
                className="map-appointment-list-content"
                data-from={appointment.appointmentDetails.timeslot.substring(0, 2).replace('08', '8').replace('09', '9')}
                data-to={appointment.appointmentDetails.timeslot.substring(6, 8).replace('08', '8').replace('09', '9')}
                data-duration={appointment.appointmentDetails.timeslot.substring(6, 8) - appointment.appointmentDetails.timeslot.substring(0, 2)}
            >
                        <div className="map-appointment-header">
                            <span className="font-weight-bold">{appointment.customerName}</span>
                                {' | '}
                            {appointment.accountId
                                ?
                                <button
                                    onClick={() => accountAccessModal(appointment.accountId)}
                                    disabled={isAssigning}
                                >
                                    {appointment.accountNumber}
                                </button>
                                :
                                <span>{appointment.accountNumber}</span>
                            }
                        </div>

                    <div className="map-appointment-body">
                        {(appointment.address.address ||
                                appointment.address.address2 || appointment.address.address3 ||
                                appointment.address.city || appointment.address.latitude ||
                                appointment.address.longitude || appointment.address.state ||
                                appointment.address.mappingAddressId || appointment.address.mappingAreaId ||
                                appointment.address.mappingRefArea1 || appointment.address.mappingNote ||
                                appointment.address.zipcode) &&
                            <span className="appointment-address" style={{
                                textDecoration: "underline", background: "transparent",
                                cursor: "pointer", border: "none", padding: 0
                            }}
                                  onClick={() => serviceAddressAccessModal(appointment)}>

                            <i className="fas fa-map"/>&nbsp;{appointment.address.address}, {appointment.address.city}, {appointment.address.state}, {appointment.address.zipcode}
                    </span>
                        }
                    </div>
                    <div className="map-appointment-footer">
                        <div>
                            {!isEmpty(appointment.taskAssignees) ? <span>Assigned: {_.uniq(appointment.taskAssignees)}</span> : "Unassigned"}
                        </div>
                        <div>
                            {appointment.type === 'order' ? "O-" : "T-"}{appointment.id}
                        </div>


                    </div>
                <div className="appointment-bottom">
                    <div className="appointment-bottom-left">

                        <span className="appointment-services">
                            {appointment.serviceOrders && appointment.serviceOrders.map(serviceOrder =>
                                serviceOrder.icon && <i className={serviceOrder.icon} />
                            )}
                        </span>
                    </div>

                    <div className="appointment-bottom-right">

                        <span className="appointment-area" title={appointment.appointmentDetails.areaDescription}>
                            {appointment.appointmentDetails.areaDescription}
                        </span>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
