import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';
export const TROUBLE_TICKET_REPORTED_CODE = createRequestTypes('TROUBLE_TICKET_REPORTED_CODE');
export const TROUBLE_TICKET_DOWNLOAD_REPORTED_CODE = createRequestTypes('TROUBLE_TICKET_DOWNLOAD_REPORTED_CODE');
export const TROUBLE_TICKET_RESOLUTION_CODE = createRequestTypes('TROUBLE_TICKET_RESOLUTION_CODE');
export const TROUBLE_TICKET_DOWNLOAD_RESOLUTION_CODE = createRequestTypes('TROUBLE_TICKET_DOWNLOAD_RESOLUTION_CODE');


/** Reported Code Functions **/

export const getTroubleTicketReportedCode = (reportedCodeId) => async (dispatch) => {
    dispatch(actionCreator.request(TROUBLE_TICKET_REPORTED_CODE));
    try {
        const response = await axiosInstance.get(`trouble-ticket/reported-code/${reportedCodeId}`);
        dispatch(actionCreator.success(TROUBLE_TICKET_REPORTED_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(TROUBLE_TICKET_REPORTED_CODE, mockError));
    }
};

export const addTroubleTicketReportedCode = (reportedCodeId, reportedCode) => async (dispatch) => {
    dispatch(actionCreator.request(TROUBLE_TICKET_REPORTED_CODE));
    try {
        const response = await axiosInstance.post('trouble-ticket/reported-code', reportedCode);
        dispatch(actionCreator.success(TROUBLE_TICKET_REPORTED_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(TROUBLE_TICKET_REPORTED_CODE, mockError));
        return err.response.data;
    }
};


export const updateTroubleTicketReportedCode = (reportedCodeId, reportedCode) => async (dispatch) => {
    dispatch(actionCreator.request(TROUBLE_TICKET_REPORTED_CODE));
    try {
        const response = await axiosInstance.patch(`trouble-ticket/reported-code/${reportedCodeId}`, reportedCode);
        dispatch(actionCreator.success(TROUBLE_TICKET_REPORTED_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(TROUBLE_TICKET_REPORTED_CODE, mockError));
        return err.response.data;
    }
};

export const deleteTroubleTicketReportedCode = (reportedCodeId) => async (dispatch) => {
    dispatch(actionCreator.request(TROUBLE_TICKET_REPORTED_CODE));
    try {
        const response = await axiosInstance.delete(`trouble-ticket/reported-code/${reportedCodeId}`);
        dispatch(actionCreator.success(TROUBLE_TICKET_REPORTED_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(TROUBLE_TICKET_REPORTED_CODE, mockError));
    }
};


export const clearTroubleTicketReportedCode = () => async (dispatch) => {
    dispatch(actionCreator.clear(TROUBLE_TICKET_REPORTED_CODE))
}

/** Resolution Code Functions **/

export const getTroubleTicketResolutionCode = (resolutionCodeId) => async (dispatch) => {
    dispatch(actionCreator.request(TROUBLE_TICKET_RESOLUTION_CODE));
    try {
        const response = await axiosInstance.get(`trouble-ticket/resolution-code/${resolutionCodeId}`);
        dispatch(actionCreator.success(TROUBLE_TICKET_RESOLUTION_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(TROUBLE_TICKET_RESOLUTION_CODE, mockError));
    }
};

export const addTroubleTicketResolutionCode = (resolutionCodeId, resolutionCode) => async (dispatch) => {
    dispatch(actionCreator.request(TROUBLE_TICKET_RESOLUTION_CODE));
    try {
        const response = await axiosInstance.post('trouble-ticket/resolution-code', resolutionCode);
        dispatch(actionCreator.success(TROUBLE_TICKET_RESOLUTION_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(TROUBLE_TICKET_RESOLUTION_CODE, mockError));
        return err.response.data;
    }
};


export const updateTroubleTicketResolutionCode = (resolutionCodeId, resolutionCode) => async (dispatch) => {
    dispatch(actionCreator.request(TROUBLE_TICKET_RESOLUTION_CODE));
    try {
        const response = await axiosInstance.patch(`trouble-ticket/resolution-code/${resolutionCodeId}`, resolutionCode);
        dispatch(actionCreator.success(TROUBLE_TICKET_RESOLUTION_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(TROUBLE_TICKET_RESOLUTION_CODE, mockError));
        return err.response.data;
    }
};

export const deleteTroubleTicketResolutionCode = (resolutionCodeId) => async (dispatch) => {
    dispatch(actionCreator.request(TROUBLE_TICKET_RESOLUTION_CODE));
    try {
        const response = await axiosInstance.delete(`trouble-ticket/resolution-code/${resolutionCodeId}`);
        dispatch(actionCreator.success(TROUBLE_TICKET_RESOLUTION_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something goes wrong'
        }
        dispatch(actionCreator.failure(TROUBLE_TICKET_RESOLUTION_CODE, mockError));
    }
};

export const clearTroubleTicketResolutionCode = () => async (dispatch) => {
    dispatch(actionCreator.clear(TROUBLE_TICKET_RESOLUTION_CODE))
}
