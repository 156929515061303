import {
	GET_INSTALLMENT_PLANS,
	GET_PLAN_CATEGORIES,
	GET_PLAN_LOCATIONS,
	GET_SERVICE_MODELS,
	GET_SYSTEM_COUNTRIES,
} from "../actions/domain.actions";

let initialState = {
	planCategories: [],
	planLocations: [],
	serviceModels: [],
	installmentPlans: [],
	countries: [],
	states: [],
};

export const domainReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PLAN_LOCATIONS.SUCCESS:
			return {
				...state,
				planLocations: action.payload
			};
		case GET_PLAN_LOCATIONS.EMPTY:
			return {
				...state,
				planLocations: []
			}
		case GET_PLAN_CATEGORIES.SUCCESS:
			return {
				...state,
				planCategories: action.payload
			};
		case GET_PLAN_CATEGORIES.EMPTY:
			return {
				...state,
				planCategories: []
			}
		case GET_SERVICE_MODELS.SUCCESS:
			return {
				...state,
				serviceModels: action.payload
			};
		case GET_SERVICE_MODELS.EMPTY:
			return {
				...state,
				serviceModels: []
			};
		case GET_INSTALLMENT_PLANS.SUCCESS:
			return {
				...state,
				installmentPlans: action.payload.installmentPlans
			};
		case GET_INSTALLMENT_PLANS.EMPTY:
			return {
				...state,
				installmentPlans: []
			};
		case GET_SYSTEM_COUNTRIES.SUCCESS:
			return {
				...state,
				countries: action.payload.countries
			};
		case GET_SYSTEM_COUNTRIES.EMPTY:
			return {
				...state,
				countries: [ { code3: 'USA', name: "USA" } ]
			};
		default:
			return state;
	}
};
