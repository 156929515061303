import React, { Component } from "react";
import { connect } from "react-redux";
import { getAccountSalesOrders, clearAccountSalesOrders } from "src/actions/accountDetailsActions/salesOrders.actions";
import { createLoadingSelector, getAccountDetails, getUser, getUserPermission } from "src/selectors";
import { isEmpty } from "src/utils/helpers";
import { showAccountDetailsWizard } from "src/actions/accountDetailsActions/accountDetails.actions";
import { getNextSalesOrderNumber } from "src/actions/accountDetailsActions/salesOrders.actions";
import SalesOrderHistoryList from "./SalesOrderHistoryList";

/**
 * Account Details / Sales Orders tab content
 */
class SalesOrdersTabContent extends Component {
  state = {
    showHistoricalData: false,
  };

  componentDidMount() {
    const { showHistoricalData } = this.state;
    const { accountInfo } = this.props;

    this.props.clearAccountSalesOrders();
    this.props.getAccountSalesOrders(accountInfo.id, showHistoricalData).then((response) => {
      if (isEmpty(response.services)) {
        this.showHideHistoricalData();
      }
    });
  }

  componentWillUnmount() {
    this.props.clearAccountSalesOrders();
  }

  showHideHistoricalData = () => {
    const { showHistoricalData } = this.state;
    const { accountInfo } = this.props;

    // Clean data
    this.props.clearAccountSalesOrders();

    // Reload data
    this.props.getAccountSalesOrders(accountInfo.id, !showHistoricalData);

    this.setState({ showHistoricalData: !this.state.showHistoricalData });
  };

  /**
   * Start "New Sales Order" wizard
   */
  startNewSalesOrderWizard = async () => {
    try {
      const resp = await this.props.getNextSalesOrderNumber();

      this.props.showCreateSalesOrderWizardHandler({
        locationId: this.props.currentLocation?.id,
        accountId: this.props.accountInfo.id,
        orderNumber: resp.data.nextNumber,
      });
    } catch {
      // Can't preserve new sales order number
      // TODO: show error message
    }
  };

  render() {
    const { collapsedMenuItem, showHistoricalData } = this.state;
    const { accountInfo, salesOrdersLoading } = this.props;

    return (
      <div className="tab-pane card-body fade active show">
        <div className="tab-pane-toolbar">
          {/* <button
            type="button"
            title="Show Historical Data"
            className={"btn btn-xl" + (showHistoricalData ? " btn-success" : " btn-outline-secondary")}
            onClick={() => this.showHideHistoricalData()}
            disabled={salesOrdersLoading}
          >
            <i className="fas fa-history" />
          </button> */}
          {this.props.permAddSalesOrder && (
            <button
              type="button"
              className="btn btn-outline-success btn-xl btn-start-oq-wizard"
              onClick={this.startNewSalesOrderWizard}
            >
              <i className="fas fa-plus" /> Sales Order
            </button>
          )}
        </div>

        {(this.props.permEditSalesOrder || this.props.permViewSalesOrder) && (
          <div className="accordion" id="accordion-services">
            <div className="container-accordion-content container-client-services-overview">
              <div id="container-client-services-overview-content" className={"collapse-with-table collapse show"}>
                <SalesOrderHistoryList
                  accountInfo={accountInfo}
                  showHistoricalData={showHistoricalData}
                  showHideHistoricalData={this.showHideHistoricalData}
                  {...this.props}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const listSalesOrdersLoader = createLoadingSelector(["LIST_ACCOUNT_SALES_ORDERS"]);

const mapStateToProps = (state) => {
  const salesOrdersLoading = listSalesOrdersLoader(state);
  const salesOrders = getAccountDetails(state).salesOrders.salesOrders;
  const currentLocation = getUser(state).currentLocation;
  const permAddSalesOrder = getUserPermission(state, "CAM", "CAM_SALES_ORDER_ADD_NEW");
  const permEditSalesOrder = getUserPermission(state, "CAM", "CAM_SALES_ORDER_EDIT");
  const permViewSalesOrder = getUserPermission(state, "CAM", "CAM_SALES_ORDER_VIEW");

  return {
    salesOrdersLoading,
    salesOrders,
    currentLocation,
    permAddSalesOrder,
    permEditSalesOrder,
    permViewSalesOrder,
  };
};

const mapDispatchToProps = {
  getAccountSalesOrders,
  clearAccountSalesOrders,
  showAccountDetailsWizard,
  getNextSalesOrderNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrdersTabContent);
