import React, {useState, useEffect} from 'react'
import DatePicker from "react-datepicker";
import moment from "moment/moment";

export const GenericDatePicker = ({placeholderText, onDateSelection, deleteFilters, type}) => {


    const [dateRange, setDateRange] = useState({selectedDate: ''})
    const [filters, setFilters] = useState({})



    useEffect(() => {
        return () => {
            //deletes filters on cleanup
            if(deleteFilters)  deleteFilters()
        };
    }, []);




    const handleDateChange = (date) => {
        let updatedFilters = dateRange

            if (date === 'prev') {

                if(!dateRange.selectedDate)  updatedFilters.selectedDate = moment().toDate();
                updatedFilters.selectedDate = moment(updatedFilters.selectedDate).subtract('1', 'day').toDate();
                if(onDateSelection) onDateSelection(updatedFilters.selectedDate, type)
            }
            else if (date === 'next') {

                if(!dateRange.selectedDate)  updatedFilters.selectedDate = moment().toDate();
                updatedFilters.selectedDate = moment(updatedFilters.selectedDate).add('1', 'day').toDate();
                if(onDateSelection) onDateSelection(updatedFilters.selectedDate, type)

            }
            else {
                updatedFilters.selectedDate = date;
                if(onDateSelection) onDateSelection(updatedFilters.selectedDate, type)
            }

        // Store new date
        setDateRange((prevState) => ({...prevState, selectedDate: updatedFilters.selectedDate}))
    }

    return(
        <div className="input-group input-group-datepicker">
            <div className="input-group-prepend">
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-direction="prev"
                    onClick={() => handleDateChange('prev')}
                    // disabled={appointmentsLoader || isAssigning}
                >
                    <i className="fa fa-chevron-left" />
                </button>
            </div>
            <DatePicker
                className={"form-control"}
                id="date"
                name="date"
                fieldName="date"
                dateFormat="MM/dd/yyyy"
                placeholderText={placeholderText}
                shouldCloseOnSelect={true}
                popperPlacement={"bottom-center"}
                selected={dateRange.selectedDate}
                onChange={(date) => handleDateChange(date)}
                autoComplete={"off"}
                isClearable={dateRange.selectedDate}
                disabledKeyboardNavigation
                showDisabledMonthNavigation
                // disabled={appointmentsLoader || isAssigning}/>
            />
            <div className="input-group-append" style={{marginLeft:'-0.5rem'}}>
                <button
                    type="button"
                    className="btn btn-secondary"
                    data-direction="next"
                    onClick={() => handleDateChange('next')}
                    // disabled={appointmentsLoader || isAssigning}
                >
                    <i className="fa fa-chevron-right" />
                </button>
            </div>
        </div>
    )
}