import React, { Component } from 'react';
import Loader from "../../../components/Loader";
import TableRow from "./TableRow";
import isEmpty from "../../../utils/helpers";

class CapacityResults extends Component {

    componentDidMount() {
        // Load capacities initially
        this.props.fetchAppointmentCapacities(this.props.setFieldValue)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Reload capacities on area/date change
        if (prevProps.areaId !== this.props.areaId || prevProps.selectedDates !== this.props.selectedDates) {
            this.props.fetchAppointmentCapacities(this.props.setFieldValue)
        }
    }

    render() {

        const {
            appointmentTimeSlots,
            capacityLoader,
            appointmentCapacity,
            capacity,
            selectedDates,
            rowClasses,
            isSubmitting,
            canEdit
        } = this.props;

        return (
            <>
                {capacityLoader && isEmpty(appointmentTimeSlots) &&
                <Loader/>
                }

                {!capacityLoader && isEmpty(appointmentCapacity) && isEmpty(appointmentTimeSlots) &&
                <div className="container">
                    <p>No data found</p>
                </div>
                }

                {!capacityLoader && !isEmpty(selectedDates) && !isEmpty(appointmentTimeSlots) && (
                    <div className="cmv-container-table">
                        <div className="table-responsive">
                            <table className="table cmv-table cmv-table-solid cmv-table-capacity">

                                <thead>
                                    <tr>
                                        <th>Times</th>
                                        {selectedDates.map(selectedDate => (
                                            <th>{selectedDate}</th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    {appointmentTimeSlots.map((time, i) => <>
                                        <TableRow
                                            id={time.id}
                                            fromTime={time.fromTime}
                                            toTime={time.toTime}
                                            capacity={capacity}
                                            rowClass={rowClasses ? rowClasses[i] : i % 2 ? '' : 'tr-dark'}
                                            {...this.props}
                                        />
                                    </>)}
                                </tbody>

                            </table>
                        </div>
                    </div>
                )}
                <div className="d-flex justify-content-end" style={{marginTop: '2.5%'}}>
                    <button
                        type="submit"
                        className="btn btn-primary float-right"
                        disabled={(capacityLoader || isSubmitting || !canEdit)}
                    >
                        &nbsp; Save &nbsp;
                        {isSubmitting && <i className="fas fa-fw fa-spin fa-spinner" />}
                    </button>

                </div>
            </>
        );
    }
}

export default CapacityResults;
