import React, { Component } from 'react';
import Account from './Account';

export class AccountsList extends Component {
	state = {
		limit: 20
	}

	render() {
		const { accounts, isFetched, history, searchError, accountAccessModal, showAdditionalAccounts, noMoreResults } = this.props;

		if (isFetched && accounts.length === 0) {
			return <div className={'container'}><p>No results found</p></div>;
		}

		if (searchError.length !== 0) {
			return <div className={'container'}><p>No results found</p></div>;
		}

		const accountsData = accounts.map((account) => (
			<Account account={account} key={account.id} history={history} accountAccessModal={accountAccessModal} />
		));

		return (
			<div className="cmv-container-dashboard-recent">
				<div className="cmv-container-cam-search-results">{accounts.length > 0 && accountsData}</div>
				{accounts.length > 0 && noMoreResults === false &&
					<div className="row" style={{marginTop: '2%', paddingRight: '1rem'}}>
						<div className="col-sm-12">
							<div className="search" style={{float: "right"}}>
								<button
									className="btn btn-primary"
									onClick={() => showAdditionalAccounts(this.state.limit)}
								>
									Show Additional
								</button>
								&nbsp;
								&nbsp;
								<select
									className="custom-select"
									value={this.state.limit}
									onChange={(e) => this.setState({limit: parseInt(e.target.value)})}
								>
									<option label="20" value="20"/>
									<option label="30" value="30"/>
									<option label="40" value="40"/>
									<option label="50" value="50"/>
								</select>
							</div>
						</div>
					</div>
				}
			</div>


		);
	}
}

export default AccountsList;
