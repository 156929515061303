import { createRequestTypes } from "../utils/helpers";
import {
  dispatchClearAction,
  httpGetAndDispatchAction,
  httpPostAndDispatchAction,
  httpPatchAndDispatchAction,
  httpPostAndDownloadAction,
  serializeParamsToQueryString,
} from "./_utils";

export const LIST_DEVICE_PROFILES = createRequestTypes("LIST_DEVICE_PROFILES");
export const LIST_ITEMS = createRequestTypes("LIST_ITEMS");
export const LIST_SYSTEM_NUM_TYPES = createRequestTypes("LIST_SYSTEM_NUM_TYPES");
export const LIST_INVENTORY_LOCATIONS = createRequestTypes("LIST_INVENTORY_LOCATIONS");

export const LIST_SERIALIZED_ITEMS = createRequestTypes("LIST_SERIALIZED_ITEMS");
export const GET_SERIALIZED_ITEM = createRequestTypes("GET_SERIALIZED_ITEM");
export const ADD_SERIALIZED_ITEM = createRequestTypes("ADD_SERIALIZED_ITEM");
export const ADD_SERIALIZED_ITEMS_BATCH = createRequestTypes("ADD_SERIALIZED_ITEMS_BATCH");
export const UPDATE_SERIALIZED_ITEM_STATUS = createRequestTypes("UPDATE_SERIALIZED_ITEM_STATUS");
export const UPDATE_SERIALIZED_ITEM_NUMBERS = createRequestTypes("UPDATE_SERIALIZED_ITEM_NUMBERS");
export const DELETE_SERIALIZED_ITEM = createRequestTypes("DELETE_SERIALIZE_ITEM");
export const DOWNLOAD_SERIALIZED_ITEMS = createRequestTypes("DOWNLOAD_SERIALIZED_ITEMS");

/**
 * Get Device Profiles (or Equipment Families)
 * @returns Promise<Response>
 */
export const getDeviceProfiles = () =>
  httpGetAndDispatchAction(LIST_DEVICE_PROFILES, "/inventory/device-profiles", "deviceProfiles");

/**
 * Get Items (or Equipment Models)
 * @returns Promise<Response>
 */
export const getItems = (params) =>
  httpGetAndDispatchAction(LIST_ITEMS, `/inventory/items?${serializeParamsToQueryString(params)}`, "items");

/**
 * Get Items (or Equipment Models)
 * @returns Promise<Response>
 */
export const getItemsSerializedTypeOnly = (params) => {
  return getItems({
    itemType: "SERIALIZED",
    ...params,
  });
};

/**
 * Get Locations
 * @returns Promise<Response>
 */
export const getInventoryLocations = () =>
  httpGetAndDispatchAction(LIST_INVENTORY_LOCATIONS, "/inventory/locations", "locations");

/**
 *
 * @param {number} params.deviceProfileId
 * @param {string} params.deviceProfileId
 * @param {number} params.itemId
 *
 * @returns
 */
export const getSystemNumTypes = (params) =>
  httpGetAndDispatchAction(
    LIST_SYSTEM_NUM_TYPES,
    `/inventory/num-types?${serializeParamsToQueryString(params)}`,
    "systemNumTypes"
  );

//
// Serialized Items
//
export const clearSerializedItems = () => dispatchClearAction(LIST_SERIALIZED_ITEMS);

/**
 *
 * @returns Promise<Response>
 */
export const getSerializedItems = (tableRequest) =>
  httpPostAndDispatchAction(LIST_SERIALIZED_ITEMS, "/inventory/serialized-items", tableRequest, "serializedItems");
export const clearSerializedItem = () => dispatchClearAction(GET_SERIALIZED_ITEM);
export const getSerializedItem = (id) =>
  httpGetAndDispatchAction(GET_SERIALIZED_ITEM, `/inventory/serialized-item/${id}`, "serializedItem");
export const addSerializedItem = (data) =>
  httpPostAndDispatchAction(ADD_SERIALIZED_ITEM, "/inventory/serialized-item", data);
export const addSerializedItemsBatch = (data) =>
  httpPostAndDispatchAction(ADD_SERIALIZED_ITEMS_BATCH, "/inventory/serialized-item/batch", data);
export const updateSerializedItemStatus = (id, data) =>
  httpPatchAndDispatchAction(UPDATE_SERIALIZED_ITEM_STATUS, `/inventory/serialized-item/${id}/status`, data);
export const updateSerializedItemNumbers = (id, data) =>
  httpPostAndDispatchAction(UPDATE_SERIALIZED_ITEM_NUMBERS, `/inventory/serialized-item/${id}/numbers`, data);
export const downloadSerializedItems = (fileType, tableRequest) => {
  let today = new Date();
  return httpPostAndDownloadAction(
    DOWNLOAD_SERIALIZED_ITEMS,
    fileType,
    `/inventory/serialized-items/${fileType}/download`,
    tableRequest,
    `inventory_${today.toISOString().split("T")[0]}.${fileType}`
  );
};