import React, {Component} from 'react';
import moment from "moment";
import Button from "../../components/Common/Buttons/Button";

export class GroupDetails extends Component {
    state = {
        showProvQueueLogDisplayed: false,
        provQueueLogToDisplay: null,
    }

    handleShowProvisioningQueueLog = (e) => {
        const {
            showProvQueueLogDisplayed,
            provQueueLogToDisplay,
        } = this.state;
        this.setState({showProvQueueLogDisplayed: !this.state.showProvQueueLogDisplayed})

        // if (e.target.id !== provQueueLogToDisplay) {
        //     // If the target log to display changed, set showProvQueueLogDisplayed to true regardless of its previous value
        //     this.setState({showProvQueueLogDisplayed: true, provQueueLogToDisplay: e.target.id})
        // } else {
        //     this.setState({showProvQueueLogDisplayed: !this.state.showProvQueueLogDisplayed, provQueueLogToDisplay: e.target.id})
        // }
    }

    renderCommandDescriptionFromJson = (commandResponseDescription) => {
        const respMap = JSON.parse(commandResponseDescription);
        return(
            <>
                {
                    Object.keys(respMap).sort().map((key, index) => (
                        <>
                            <p>{key}: {respMap[key]}</p>
                        </>
                    ))
                }
            </>
        )
    }

    renderProvisioningLog = (provQueue) => {
        const {
            showProvQueueLogDisplayed,
            provQueueLogToDisplay,
        } = this.state;
        const mapKey = "ont-id";

        return(
            <>
                {provQueue.provisioningResponse && provQueue.provisioningResponse.commandResponses &&
                <>
                    {provQueue.provisioningResponse.commandResponses.map((cmdResp, index) => (
                        <>
                            {cmdResp && cmdResp.rawProvisoiningCommandResponsesList &&
                            <>
                                {showProvQueueLogDisplayed &&
                                    <>
                                        {cmdResp.rawProvisoiningCommandResponsesList.map((rawProvCmdResp, index) => (
                                            <div className={"row"}>
                                                <div className={"col"}>
                                                    <p>JSON Request - {rawProvCmdResp.requestId}</p>
                                                    <div className="cmv-container-log-viewer cmv-container-order-log-viewer">
                                                        <>
                                                            <span>{decodeURIComponent(rawProvCmdResp.requestRawBody)}</span><br/>
                                                        </>
                                                    </div>
                                                    <p>JSON Response - {rawProvCmdResp.requestId}</p>
                                                    <div className="cmv-container-log-viewer cmv-container-order-log-viewer">
                                                        <>
                                                            <span>{rawProvCmdResp.responseRawBody}</span><br/>
                                                        </>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                }
                                {/*{cmdResp.rawProvisoiningCommandResponsesList.map((rawProvCmdResp, index) => (*/}
                                {/*    <div className={"queue"}>*/}
                                {/*        <div className={"row"}>*/}
                                {/*            <div className={"col"}>*/}
                                {/*                <h6>{rawProvCmdResp.requestId}*/}
                                {/*                    <span className="d-flex text-muted">*/}
                                {/*                    <span className="d-flex text-muted"*/}
                                {/*                          title="Show Provisioning Queue Details">*/}
                                {/*                            <small className="d-md-inline-block ml-auto">*/}
                                {/*                                <Button*/}
                                {/*                                    onClick={this.handleShowProvisioningQueueLog}*/}
                                {/*                                    className="btn btn-xs btn-order-log-toggle btn-test-billing-log-display"*/}
                                {/*                                    type="button"*/}
                                {/*                                >*/}
                                {/*                                    <i id={rawProvCmdResp.requestId}*/}
                                {/*                                       className="fas fa-list-ul"/>*/}
                                {/*                                </Button>*/}
                                {/*                            </small>*/}
                                {/*                    </span>*/}
                                {/*                    <span*/}
                                {/*                        className={"provisioning-group-status " + (*/}
                                {/*                            rawProvCmdResp.status === "NEW" ? "bg-light border-success" :*/}
                                {/*                                rawProvCmdResp.status === "PROCESSING" ? "bg-light-with-pulse border-success" :*/}
                                {/*                                    rawProvCmdResp.status === "SUCCESS" ? "bg-success" :*/}
                                {/*                                        rawProvCmdResp.status === "WARNING" ? "bg-warning" :*/}
                                {/*                                            rawProvCmdResp.status === "ERROR" ? "bg-danger" :*/}
                                {/*                                                "bg-light"*/}
                                {/*                        )}*/}
                                {/*                    >*/}
                                {/*                        {rawProvCmdResp.status}*/}
                                {/*                    </span>*/}
                                {/*                    </span>*/}
                                {/*                </h6>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        {*/}
                                {/*            Object.entries(rawProvCmdResp.responseAttributesMap).map(([key, val]) => (*/}
                                {/*                <>*/}
                                {/*                    {key !== "rf-avo" && rawProvCmdResp.importantAttributeNames.includes(key) &&*/}
                                {/*                    <>*/}
                                {/*                        <p>{key}: {val}</p>*/}
                                {/*                    </>*/}
                                {/*                    }*/}
                                {/*                </>*/}
                                {/*            ))*/}
                                {/*        }*/}
                                {/*        {showProvQueueLogDisplayed && provQueueLogToDisplay === rawProvCmdResp.requestId &&*/}
                                {/*        <div className={"row"}>*/}
                                {/*            <div className={"col"}>*/}
                                {/*                <div className="cmv-container-log-viewer cmv-container-order-log-viewer">*/}
                                {/*                    {*/}
                                {/*                        Object.keys(rawProvCmdResp.responseAttributesMap).sort().map((key, index) => (*/}
                                {/*                            <>*/}
                                {/*                                {key !== "rf-avo" &&*/}
                                {/*                                <><span>{key}: {rawProvCmdResp.responseAttributesMap[key]}</span><br/></>*/}
                                {/*                                }*/}
                                {/*                            </>*/}
                                {/*                        ))*/}
                                {/*                    }*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        }*/}
                                {/*    </div>*/}
                                {/*))}*/}
                            </>
                            }
                        </>
                    ))}
                </>
                }
            </>
        )
    }

    render() {

        const {
            items,
        } = this.props;

        return (
            <div className="provisioning-group-details">

                {items.map(item =>
                    <>
                        <div>
                            <h6>
                                {item.provisioningInterfaceName}
                                <span className="d-flex text-muted">
                                    <span className="d-flex text-muted"
                                          title="Show Provisioning Queue Details">
                                            <small className="d-md-inline-block ml-auto">
                                                <Button
                                                    onClick={this.handleShowProvisioningQueueLog}
                                                    className="btn btn-xs btn-order-log-toggle btn-test-billing-log-display"
                                                    type="button"
                                                >
                                                    <i
                                                        // id={rawProvCmdResp.requestId}
                                                       className="fas fa-list-ul"/>
                                                </Button>
                                            </small>
                                    </span>
                                    <span
                                        className={"provisioning-group-status " + (
                                            item.status === "NEW" ? "bg-light border-success" :
                                                item.status === "PROCESSING" ? "bg-light-with-pulse border-success" :
                                                    item.status === "PROCESSED" ? "bg-success" :
                                                        item.status === "WARNING" ? "bg-warning" :
                                                            item.status === "ERROR" ? "bg-danger" :
                                                                "bg-light"
                                        )}
                                    >
                                        {item.status}
                                    </span>
                                </span>
                            </h6>

                            {item.type &&
                            <p>Type: {item.type}</p>
                            }

                            {item.serviceLineFeatureName &&
                            <p>Feature: {item.serviceLineFeatureName}</p>
                            }

                            {item.provisioningCmdName &&
                            <p>Provisioning Cmd: {item.provisioningCmdName}</p>
                            }

                            {item.id &&
                            <p>Provisioning Queue Id: {item.id}</p>
                            }

                            {item.entryDateTime &&
                            <p>Entry Date: {moment(item.entryDateTime).format("MM/DD/YYYY @ h:mmA")}</p>
                            }

                            {item.finishedDateTime &&
                            <p>Finished Date: {moment(item.finishedDateTime).format("MM/DD/YYYY @ h:mmA")}</p>
                            }
                        </div>
                        <div>
                            {item.provisioningResponse && item.provisioningResponse.commandResponses &&
                                <>
                                    <p>Status Description</p>
                                    <div className={"queue"}>
                                        {item.provisioningResponse.commandResponses.length > 0 ?
                                            <>
                                                {item.provisioningResponse.commandResponses.map((cmdResp, index) => (
                                                    <>
                                                        {cmdResp.commandResponseDescription && this.renderCommandDescriptionFromJson(cmdResp.commandResponseDescription)}
                                                    </>
                                                ))}
                                            </>
                                            :
                                            <>
                                                <p>{item.statusDescription}</p>
                                            </>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        {this.renderProvisioningLog(item)}
                    </>
                )}

            </div>
        );
    }
}

export default GroupDetails;
