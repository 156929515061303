import React, { Component } from "react";
import { connect } from "react-redux";
import Wizard from "../../../camvio-wizard/Wizard";
import Step from "../../../camvio-wizard/Step";

import { ItemSelection, Confirmation } from "./Steps";

const STEPS = {
  ITEM_SELECTION: "item-selection-step",
  CONFIRMATION: "confirmation-step",
};

class CreateSalesOrderWizard extends Component {
  componentDidMount() {
    if (this.props.accountDetailsWizardData.wizardData.salesorder) {
      document.title = "Edit Sales Order - camvio.cloud";
      this.props.changeNavBarType("lock", "createSalesOrderWizard", "Edit Sales Order");
    } else {
      document.title = "New Sales Order - camvio.cloud";
      this.props.changeNavBarType("lock", "createSalesOrderWizard", "New Sales Order");
    }
  }

  componentWillUnmount() {
    const { changeNavBarType, hideAccountDetailsWizard, documentTitle, navbarTitle } = this.props;

    // Change navbar type and color
    document.title = documentTitle;
    changeNavBarType("account", "accountDetails", navbarTitle);

    hideAccountDetailsWizard();
  }

  render() {
    const { accountDetailsWizardData, history, hideAccountDetailsWizard } = this.props;

    return (
      <Wizard name="CreateSalesOrderWizard">
        <Step
          wizardData={accountDetailsWizardData.wizardData}
          name={STEPS.ITEM_SELECTION}
          title="Item Selection"
          component={ItemSelection}
          next={STEPS.CONFIRMATION}
          history={history}
        />

        <Step
          wizardData={accountDetailsWizardData.wizardData}
          name={STEPS.CONFIRMATION}
          title="Confirmation"
          component={Confirmation}
          history={history}
          hideAccountDetailsWizard={hideAccountDetailsWizard}
        />
      </Wizard>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSalesOrderWizard);
