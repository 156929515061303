export const applyOnEnum = Object.freeze({
  ORDER_COMPLETION: "Order completion",
  START_OF_BILLCYCLE: "Start of bill cycle",
  NEXT_BILLCYCLE: "Next bill cycle",
  APPLY_ON_DATE: "Select a date",
});

export const provisioningActionTypeEnum = Object.freeze({
  ACTIVATE: "Activate",
  SUSPEND: "Suspend",
  RESTORE: "Restore",
  DEACTIVATE: "Deactivate",
  QUERY: "Query",
  RELEASE: "Release",
  DELETE: "Delete",
  ADD: "Add",
  PORT_OUT: "Port Out",
  CHANGE: "Change Device",
  CHANGE_NUMBER: "Change Number",
  ADD_DEVICE: "Add Device",
  ADD_SERVICE: "Add Service",
  DEACTIVATE_DEVICE: "Deactivate Device",
  DEACTIVATE_SERVICE: "Deactivate Service",
});

export const provisioningStatusEnum = Object.freeze({
  NEW: "New",
  PROCESSING: "Processing",
  PROCESSED: "Processed",
  WARNING: "Warning",
  ERROR: "Error",
  ON_HOLD: "On Hold",
  CANCELED: "Canceled",
  DEPRECATED: "Deprecated",
});

// Helper function to create simple action
export const action = (type, payload = {}) => {
  return { type, ...payload };
};

export const verboseAction = (type, payload = {}) => {
  return {
    type: type,
    payload: { ...payload },
  };
};

export const setCurrentUser = (authResponse) => {
  let userLocalStorage = { ...authResponse };
  localStorage.setItem("currentUser", JSON.stringify(userLocalStorage));
};

export const getToken = () => {
  return localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser"))["token"] : "";
};

export const getRefreshToken = () => {
  return localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser"))["refreshToken"] : "";
};

export const getLocation = () => {
  return localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")) : "";
};

const REQUEST = "REQUEST";
const SUCCESS = "SUCCESS";
const FAILURE = "FAILURE";
const EMPTY = "EMPTY";
const CLEAR = "CLEAR";

export const createRequestTypes = (base) => {
  return [REQUEST, SUCCESS, FAILURE, CLEAR, EMPTY].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc;
  }, {});
};

export const actionCreator = {
  request: (type) => action(type.REQUEST),
  success: (type, payload) => action(type.SUCCESS, { payload }),
  failure: (type, error) => verboseAction(type.FAILURE, error),
  empty: (type) => action(type.EMPTY),
  clear: (type) => action(type.CLEAR),
};

export const transformFeature = (feature, featurePackageOption) => {
  return {
    featurePackageId: feature.featurePackageId,
    featurePackageDescription: feature.description,
    featurePackageName: feature.name,
    featurePackageNote: feature.note,
    ...featurePackageOption,
  };
};

export const generateId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export default isEmpty;

export const getErrorMessage = (error) => {
  let mockErrorMessage = {};
  let data = error && error.response && error.response.data
  if (data && data.error) {
    if (data.error.code) {
      if (window.errorCodes && window.errorCodes.hasOwnProperty(data.error.code)) {
        if (window.errorCodes[data.error.code] !== "") {
          mockErrorMessage.message = window.errorCodes[data.error.code];
        } else {
          mockErrorMessage.message = data.error.message || "Something went wrong";
        }
      } else {
        mockErrorMessage.message = data.error.message || "Something went wrong";
      }
    }
  } else if (data && data.message) {
    mockErrorMessage.message = data.message;
  } else {
    mockErrorMessage.message = "Something went wrong";
  }

  return mockErrorMessage;
};

// export const getErrorMessage = (error, language) => {
// 	let mockErrorMessage = {};
// 	if (error.data.error) {
// 		if (error.data.error.code) {
// 			if (window.errorCodes[language].hasOwnProperty(error.data.error.code)) {
// 				if (window.errorCodes[language][error.data.error.code] !== '') {
// 					mockErrorMessage.message = window.errorCodes[language][error.data.error.code];
// 				} else {
// 					mockErrorMessage.message = error.data.error.message
// 						? error.data.error.message
// 						: 'Something goes wrong';
// 				}
// 			} else {
// 				mockErrorMessage.message = error.data.error.message ? error.data.error.message : 'Something goes wrong';
// 			}
// 		}
// 	} else {
// 		mockErrorMessage.message = 'Something goes wrong';
// 	}

// 	return mockErrorMessage;
// };

/**
 * Get user friendly error messages from auth0 error callback
 * https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
 *
 * @param callbackArgs - arguments from Auth0 callback (coming from query params)
 */
export const getAuth0ErrorMessage = (callbackArgs) => {
  let message = "";
  const { error, error_description } = callbackArgs;
  if (error === "access_denied") {
    message = "The server denied access per OAuth2 specifications (Access denied)";
  } else if (error === "invalid_user_password") {
    message = "The username and/or password used for authentication are invalid (Invalid user password)";
  } else if (error === "mfa_invalid_code") {
    message = "The multi-factor authentication (MFA) code provided by the user is invalid/expired (MFA invalid code)";
  } else if (error === "mfa_registration_required") {
    message =
      "The administrator has required multi-factor authentication, but the user has not enrolled (MFA registration required)";
  } else if (error === "mfa_required") {
    message = "The user must provide the multi-factor authentication code to authenticate (MFA required)";
  } else if (error === "password_leaked") {
    message = "If the password has been leaked and a different one needs to be used (Password leaked)";
  } else if (error === "PasswordHistoryError") {
    message = "The password provided for sign up/update has already been used (Password History Error)";
  } else if (error === "PasswordStrengthError") {
    message = "The password provided does not match the connection's strength requirements (Password Strength Error)";
  } else if (error === "too_many_attempts") {
    message = "The account is blocked due to too many attempts to sign in (Too many attempts)";
  } else if (error === "unauthorized") {
    message = "The user you are attempting to sign in with is blocked (Unauthorized)";
  } else if (error && error_description) {
    message = `${error_description} (${error})`;
  } else {
    message = error_description || error;
  }

  return {
    message: `Auth0: ${message}`,
  };
};
