import React, { Component } from 'react';
import { connect } from 'react-redux';

import { hideModal } from '../../actions/modal.actions';
import Loader from "../Loader";


class LoadingModalContent extends Component {
    componentDidMount() {}

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {this.props.modalTitle || ''}
                        </h5>
                        <button onClick={this.props.hideModal} type="button" className="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Loader />
                    <div className="modal-footer">
                        <button onClick={this.props.hideModal} className="btn" type="button">
                            {this.props.cancelButtonLabel || 'Cancel'}
                        </button>
                        <button type="submit" className="btn btn-primary">
                            {this.props.submitButtonLabel || 'OK'}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = { hideModal };

export default connect(mapStateToProps, mapDispatchToProps)(LoadingModalContent);