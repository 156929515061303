import React from "react";
import TableHeaders from "./TableHeaders";

export default function CamvioTable2({
  headerDefs,
  sortFunction,
  sortField,
  sortDirection,
  children,
}) {
  return (
    <div className="cmv-container-table">
      <table className="table cmv-table cmv-table-mobile cmv-table-solid">
        <TableHeaders
          headerDefs={headerDefs}
          onSortChange={sortFunction}
          sortField={sortField}
          sortDirection={sortDirection}
        />
        {children}
      </table>
    </div>
  );
}
