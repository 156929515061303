import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../../../components/Loader';
import {connect} from "react-redux";
import {getNotificationCategories} from "src/actions/notifications.actions";


class NotificationCategories extends Component {

	state = {
		isToggled: false,
		isDisabled: false,
		isLoading: false,
		communicationPreferencesToggled: false,
		allCommunicationsToggled: null,
		notificationCategoriesState: [],
	};

	componentDidMount() {
		this.checkNotificationsToggled()
		this.setState({notificationCategoriesState: this.props.notificationCategoriesData})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.notificationCategoriesData !== this.props.notificationCategoriesData) {

			this.setState({notificationCategoriesState: this.props.notificationCategoriesData}, () => this.checkNotificationsToggled())
		}
	}


	//checks if all communications toggle needs to be checked or not on load
	checkNotificationsToggled = () => {
		let optOutNotificationPresent = false
		this.state.notificationCategoriesState.map((category) => {
			if(category.optedOut.includes('EMAIL') === true || category.optedOut.includes('TWILIOMSG') === true) {
				optOutNotificationPresent = true
				this.setState({allCommunicationsToggled: false})
				return false
			}
		})
		if(optOutNotificationPresent === false) {
			this.setState({allCommunicationsToggled: true})
			return true
		}
	}

	handleToggle = () => {
		this.setState((prevState) => ({
			isToggled: !prevState.isToggled
		}));
	};

	handleAllNotificationOptInOut = async () => {
		//main toggle to optIn/Out for all categories
		const lastToggleValue = this.state.allCommunicationsToggled
		this.setState({allCommunicationsToggled: !this.state.allCommunicationsToggled})
		let newNotificationCategoriesState = this.state.notificationCategoriesState.map((category) => {
			category = {...category, optedIn: category.optedOut, optedOut: category.optedIn}
			return category
		})
		this.setState({notificationCategoriesState: newNotificationCategoriesState})

			this.props.notificationCategoriesData.map((notificationCategory => {
					if (lastToggleValue) {
						this.props.handleNotificationUnsubscribe({
							notificationCategoryId: notificationCategory.id
						})
					} else {
						this.props.handleNotificationSubscribe({
							notificationCategoryId: notificationCategory.id,
							type: "EMAIL"
						})
						this.props.handleNotificationSubscribe({
							notificationCategoryId: notificationCategory.id,
							type: "TWILIOMSG"
						})
					}
				})
			)
		await this.props.getNotificationCategories(this.props.accountId)
	}



	notificationOnClickHandler = (notificationCategory, type) => {
		let newNotificationCategory
		//checks if it includes EMAIL or TWILIOMSG to determine if opt in/out
		if(!notificationCategory.optedIn.includes(type)) {
			newNotificationCategory = {...notificationCategory,
				optedOut: notificationCategory.optedOut.filter((category) => category !== type),
				optedIn: [...notificationCategory.optedIn, type]}
			let newCategoryState = this.state.notificationCategoriesState.map((notification) => {
				if (notification.id === newNotificationCategory.id) {
					notification = newNotificationCategory
					return notification
				}
				return notification
			})
			this.setState({notificationCategoriesState: newCategoryState}, () => this.checkNotificationsToggled())
			this.props.handleNotificationSubscribe({
				notificationCategoryId: notificationCategory.id,
				type: type
			})
		} else {
			newNotificationCategory = {...notificationCategory,
				optedIn: notificationCategory.optedIn.filter((category) => category !== type),
				optedOut: [...notificationCategory.optedOut, type]}
			let newCategoryState = this.state.notificationCategoriesState.map((notification) => {
				if (notification.id === newNotificationCategory.id) {
					notification = newNotificationCategory
					return notification
				}
				return notification
			})
			this.setState({notificationCategoriesState: newCategoryState}, () => this.checkNotificationsToggled())
			this.props.handleNotificationUnsubscribe({
				notificationCategoryId: notificationCategory.id,
				type: type
			})
		}
		this.props.getNotificationCategories(this.props.accountId)
	}



	render() {
		const {
			isNotificationCategoriesLoaded,
			notificationCategoriesLoader
		} = this.props;

		const {
			communicationPreferencesToggled,
			allCommunicationsToggled,
			notificationCategoriesState,
			isLoading,
		} = this.state;

		if (!isNotificationCategoriesLoaded && notificationCategoriesLoader) {
			return <Loader />;
		}

		return (
			<div className="container-client-notifications-settings">
				<div className="card-toggable">
					<div className="card-toggable-header h-with-check">
					<span
						onClick={() => this.setState({communicationPreferencesToggled: !this.state.communicationPreferencesToggled})}
						className={communicationPreferencesToggled ? 'h-with-check-title toggled' : 'h-with-check-title'}
					>
						Communication Preferences
					</span>
					</div>
					<div style={{display: 'flex', flexDirection:'row', gap:'1vw', fontSize:'12px', lineHeight:'2px'}}>
						{communicationPreferencesToggled &&
							<>
								<span style={{paddingTop: '12px', paddingLeft: '25px'}}>All Communications</span>
							<div className="form-check checkbox-slider checkbox-slider--b-flat">
							<label>
							<input
							type="checkbox"
							// disabled={!this.checkIsCategoryActive() || isDisabled}
							checked={allCommunicationsToggled}
							onClick={() => {
								this.handleAllNotificationOptInOut()
							}}
							/>
							<span>&nbsp;</span>
							</label>
							</div>
							</>
						}
					</div>
					{communicationPreferencesToggled && allCommunicationsToggled === false &&
						<div style={{fontSize:'12px', marginTop:'2vh'}}>
							<div style={{display:'grid', gridTemplateColumns: '2fr 0.5fr 0.5fr'}}>
								<span>&nbsp;</span>
								<label>Email</label>
								<label>SMS</label>
							</div>
							{notificationCategoriesState.map((notificationCategory) => {
								return (
								<div style={{display:'grid', gridTemplateColumns: '2fr 0.5fr 0.5fr'}}>
									<div>{notificationCategory.description}</div>
									{notificationCategory.optedIn.includes("EMAIL") || notificationCategory.optedOut.includes("EMAIL") ?
										<div className="form-check checkbox-slider checkbox-slider--b-flat">
											<label>
												<input
													type="checkbox"
													// disabled={!this.checkIsCategoryActive() || isDisabled}
													checked={notificationCategory.optedIn.includes("EMAIL")}
													onClick={() => {
														this.notificationOnClickHandler(notificationCategory, "EMAIL")
													}}
												/>
												<span>&nbsp;</span>
											</label>
										</div>
										:
										null
									}
									{notificationCategory.optedIn.includes("TWILIOMSG") || notificationCategory.optedOut.includes("TWILIOMSG") ?
										<div className="form-check checkbox-slider checkbox-slider--b-flat">
											<label>
												<input
													type="checkbox"
													// disabled={!this.checkIsCategoryActive() || isDisabled}
													checked={notificationCategory.optedIn.includes("TWILIOMSG")}
													onClick={() => {
														this.notificationOnClickHandler(notificationCategory, "TWILIOMSG")
													}}
												/>
												<span>&nbsp;</span>
											</label>
										</div>
										:
										null
									}
									</div>
								)
							})}
						</div>
					}
				</div>
			</div>
		);
	}
}

NotificationCategories.propTypes = {
	notificationCategoriesData: PropTypes.array,
	notificationCategoriesLoader: PropTypes.bool,
	isNotificationCategoriesLoaded: PropTypes.bool,
	handleNotificationSubscribe: PropTypes.func,
	handleNotificationUnsubscribe: PropTypes.func,
};

NotificationCategories.defaultProps = {
	notificationCategoriesData: []
};



const mapDispatchToProps = {
	getNotificationCategories,
};

export default connect(null, mapDispatchToProps)(NotificationCategories);
