import React, { Component } from 'react';
import { connect } from 'react-redux';
import {DefaultDashboardSearch} from "src/components/UI/CamvioTable2";
import CamvioTable2, {
    defineActionButton,
} from "../../components/UI/CamvioTable2";
import {
    cancelMigrationJob, downloadMigrationJobsInventoryDevicesFile,
    getMigrationJobs,
    migrateMigrationJob, uploadMigrationJob,
    validateMigrationJob
} from "src/actions/migrationJobs.actions";
import {getMigrationJobsData} from "src/selectors";
import Select from "react-select";
import {groupStyles} from "src/utils/SelectStyles";
import {showModal} from "src/actions/modal.actions";
import {GenericDatePicker} from "src/components/UI/GenericDatePicker";
import moment from 'moment/moment'
import {isEmpty} from "lodash";
import {getUser} from "src/selectors";


class Migration extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        sortField: null,
        sortDirection: null,
        migrationJobs: [],
        filters: {
            jobType: '',
            status: "",
            uploadDate: '',
            migrateDate: '',
            filename: '',
            offset: 0,
            limit: 20,
            sortDesc: true
        },
        showAdditionalLimit: 20,
        intervalID: null,
        noMoreResults: false,
        isLoading: false,
        searchTerm: '',
        loadingItems: []
    }

    componentDidMount() {
        // Change navbar type and color
        document.title = 'Migration - camvio.cloud';
        this.props.changeNavBarType('default', 'Migration Jobs');
        this.props.getMigrationJobs(this.state.filters);

        // Refreshes data every 15sec
        const updateInterval = setInterval(this.updateData, 15000)
        this.setState({intervalID: updateInterval})
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.migrationJobs !== this.props.migrationJobs) {
            this.setState({migrationJobs: this.props.migrationJobs})

            if(this.state.loadingItems.length > 0) this.setLoading()

            if(this.props.migrationJobs.length < this.state.filters.limit) {
                this.setState({noMoreResults:true})
            } else {
                this.setState({noMoreResults: false})
            }

        }

        if(prevState.filters.status !== this.state.filters.status || prevState.filters.jobType !== this.state.filters.jobType) {
            this.props.getMigrationJobs(this.state.filters);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalID)
    }

    actionButtons = {
        default: defineActionButton("SEARCH", "Search", "fa-search"),
        secondary: [defineActionButton("UPLOAD", "Upload", "fa-plus")],
        dropdown: defineActionButton("DOWNLOAD", "Download", "fa-download"),
        dropdownItems: [
            defineActionButton("CSV", "Sample Serialized Inventory CSV file"),
        ]
    }

    statusColors = {
        UPLOADING: 'gray',
        UPLOADED: 'pink',
        PENDING_VALIDATE: 'gray',
        VALIDATING: 'gray',
        VALIDATED: 'blue',
        PENDING_MIGRATE: 'gray',
        MIGRATED: 'green',
        FAILED: 'red',
        CANCELED: 'gray',
        MIGRATING: 'gray',
        CANCELING: 'gray'
    }

    onDashBoardAction = (action) => {
        switch(action) {
            case "UPLOAD":
                this.props.showModal("UPLOAD_MIGRATION_JOB_MODAL", this.state.filters
                )
                break;
            case "CSV":
                this.downloadTable("csv")
                break;
            case "SEARCH":
                this.setState({filters: {...this.state.filters, filename: this.state.searchTerm}}, () => {
                    this.props.getMigrationJobs(this.state.filters)
                })
                break;
            default:
                break;
        }
    }

    downloadTable = (value) => {
        this.setState({
            isDownloading: true,
        });

        this.props.downloadMigrationJobsInventoryDevicesFile().then((response) => {
                let name = "";
                let link = document.createElement("a");
                let fileUrl = URL.createObjectURL(new Blob([response]));
                link.setAttribute("href", fileUrl);
                let today = new Date();
                name = "migration_sample_" + today.toISOString().split("T")[0] + "." + value;
                link.setAttribute("download", name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    isDownloading: false,
                });
        });
    };

    onTableAction = (action, value) => {
        switch(action) {
            case "VALIDATE":
                    this.props.validateMigrationJob(value)
                    this.props.getMigrationJobs(this.state.filters)
                break;
            case "MIGRATE":
                    this.props.migrateMigrationJob(value)
                    this.props.getMigrationJobs(this.state.filters)
                break;
            case "CANCEL":
                    this.props.cancelMigrationJob(value)
                    this.props.getMigrationJobs(this.state.filters)
                break;
        }
    }

    sortList = (field, dir = null) => {
    if(field) {
        this.setState({
            sortField: field,
            sortDirection: dir
        })

        let sortedItems = this.state.migrationJobs.sort((a ,b )=> {
            const firstItem = a[field].toUpperCase()
            const secondItem = b[field].toUpperCase()
            let comparison = 0

            if(firstItem < secondItem) {
                comparison = -1;
            }
            else if(firstItem > secondItem) {
                comparison = 1;
            }
            else {
                comparison = 0
            }
            return (dir === 'desc') ? (comparison * -1) : comparison

        })
        this.setState({migrationJobs: sortedItems})
    }

    };

    setStatusFilter = (value) => {
        if(value) {
            this.setState({statusValue: value, filters: {...this.state.filters, status: value.label}})
        } else {
            this.setState({statusValue: {label: ''}, filters: {...this.state.filters, status: ''}})
        }
    }

    setJobTypeFilter = (value) => {
        if(value) {
            this.setState({jobTypeValue: value, filters: {...this.state.filters, jobType: value.label}})
        } else {
            this.setState({jobTypeValue: {label: ''}, filters: {...this.state.filters, jobType: ''}})
        }
    }

    statusFilters = [
        {label: "UPLOADING"},
        {label: "UPLOADED"},
        {label: "PENDING_VALIDATE"},
        {label: "VALIDATING"},
        {label: "VALIDATED"},
        {label: "PENDING_MIGRATE"},
        {label: "MIGRATED"},
        {label: "MIGRATING"},
        {label: "FAILED"},
        {label: "CANCELED"},
        {label: "CANCELING"}
    ]


    onDateSelection = (date, type) => {
        if(date === null) {
            this.setState({filters: {...this.state.filters, [`${type}Date`]: ''}},() => this.props.getMigrationJobs(this.state.filters))
            return
        }
        date = moment(date).format('yyyy-MM-DD')

        if(type === "migrate") {
            this.setState({filters: {...this.state.filters, migrateDate: date.toString()}},() => this.props.getMigrationJobs(this.state.filters))
        } else {
            this.setState({filters: {...this.state.filters, uploadDate: date.toString()}}, () => this.props.getMigrationJobs(this.state.filters))
        }
    }

    updateData = async () => {
        if(this.state.migrationJobs.filter((job) => job.status !== 'FAILED' || 'CANCELED' || 'MIGRATED').length > 0) {
            await this.props.getMigrationJobs(this.state.filters);
            this.sortList(this.state.sortField, this.state.sortDirection)
        }
    }

    deleteFilters = () => {
        this.setState({filters: {...this.state.filters, status: '', migrateDate: '', uploadDate: ''}})

    }

    setDisabled = (item) => {
        const disabledStatuses = ['UPLOADING', 'VALIDATING', 'MIGRATING', 'CANCELING', 'CANCELED', 'FAILED']
        if(disabledStatuses.includes(item.status)) return true
    }

    setLoading = () => {
        const {migrationJobs} = this.props
        const {loadingItems} = this.state
        let tempLoadingItems = loadingItems
        loadingItems.map((item, i) => {
            migrationJobs.map((job) => {
                /**
                 * We map through new migrationJobs and items that are being processed for migration/validation/canceling,
                 * check to see if they have a new updated status,
                 * if they do, we remove them from loadingItems so spinner is no longer active
                 */
                if(job.id === item.item.id && job.status !== item.item.status) {
                    tempLoadingItems = tempLoadingItems.filter((tempItem) => tempItem.item.id !== job.id)
                }
            })
        })
        this.setState({loadingItems: tempLoadingItems})
    }

    additionalFilters = (
        <div style={{display:'flex', justifyContent:'space-evenly'}}>
            <GenericDatePicker placeholderText={"Upload Date"} onDateSelection={this.onDateSelection} deleteFilters={this.deleteFilters} type={'upload'} />
            <GenericDatePicker placeholderText={"Migrate Date"} onDateSelection={this.onDateSelection} deleteFilters={this.deleteFilters} type={'migrate'}/>

            <span>&nbsp;&nbsp;</span>
            <Select
                className="extra-filter-select"
                onChange={this.setStatusFilter}
                styles={groupStyles}
                options={this.statusFilters}
                placeholder={"All Statuses"}
                isClearable={true}
                value={this.state.statusValue}
            />
            <span>&nbsp;&nbsp;</span>
            <Select
                className="extra-filter-select"
                onChange={this.setJobTypeFilter}
                styles={groupStyles}
                options={[{label: "INVENTORY_DEVICES", value: "INVENTORY_DEVICES"}]}
                placeholder={"Job Type"}
                isClearable={true}
                value={this.state.jobTypeValue}
            />
        </div>
    )


    render() {
        const {sortField, sortDirection, filters, isLoading, loadingItems} = this.state
        {if(this.props.userInfo.systemUserRole.name !== "SUPER_ADMIN") return null}

        return (
            <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
                <div className='tab-pane camvio-table-search' style={{borderRadius:'0.5rem'}}>
                <DefaultDashboardSearch children={this.additionalFilters} actionButtons={this.actionButtons}
                                        inputPlaceholder={"Search by partial filename"} onAction={this.onDashBoardAction}
                                        onInputChange={(e) => this.setState({searchTerm: e.target.value})} />
                    {isEmpty(this.state.migrationJobs) ?
                        <div className="container">
                            <p>No data found</p>
                        </div>
                        :
                        <CamvioTable2
                            headerDefs={[
                                {name: "Filename", field: "filename", sortable: true},
                                {name: "Type", field: "item_type", sortable: false},
                                {name: "Status", field: "status", sortable: true},
                                {name: "Actions", field: "", sortable: false},
                            ]}
                            sortFunction={this.sortList}
                            sortField={sortField}
                            sortDirection={sortDirection}
                        >
                            <tbody>
                            {this.state.migrationJobs.map((item, index) => (
                                <tr key={`item_${item.id}`}>
                                    <td>
                                        <span>{item.filename}</span>
                                        <span className="td-subtitle">#{item?.id}</span>
                                        <span className="td-subtitle">{item?.numRecords} Records</span>
                                    </td>
                                    <td>
                                        <span>{item.migrationType}</span>
                                        <span className="td-subtitle"><i
                                            className="far fa-clock"/>{moment(item?.uploadedDatetime).calendar()}</span>
                                        {item.uploadedBy?.name ?
                                            <span className="td-subtitle"><i
                                                className="fas fa-user-circle"/>{item.uploadedBy?.name}</span>
                                            : null}
                                    </td>
                                    <td>
                        <span
                            className={"badge" + " badge-secondary"}
                            style={{backgroundColor: this.statusColors[item.status]}}
                        >
                          {item.status}
                        </span>
                                    </td>
                                    <td>
                                        <div className="tr-actions">
                                            <button
                                                className="btn"
                                                type="button"
                                                title="Validate"
                                                onClick={() => {
                                                    this.onTableAction("VALIDATE", item.id)
                                                    this.setState({loadingItems: [...this.state.loadingItems, {item: item, action: "validate"}]})
                                                }}
                                                disabled={item.status !== 'UPLOADED'}
                                            >
                                                {loadingItems.filter((i) => i.id === item.id && i.action === 'validate').length ?
                                                    <i className="fas fa-fw fa-spin fa-spinner"/>
                                                    :
                                                    <i className="fas fa-check"></i>
                                                }
                                            </button>
                                            <button
                                                className="btn"
                                                type="button"
                                                title="Migrate"
                                                onClick={() => {
                                                    this.onTableAction("MIGRATE", item.id)
                                                    this.setState({loadingItems: [...this.state.loadingItems, {item: item, action: "migrate"}]})
                                                }}
                                                disabled={item.status !== 'VALIDATED'}
                                            >
                                                {loadingItems && loadingItems.filter((i) => i.id === item.id && i.action === 'migrate').length ?
                                                    <i className="fas fa-fw fa-spin fa-spinner"/>
                                                    :
                                                    <i className="fas fa-file-import"></i>
                                                }
                                            </button>
                                            <button
                                                className="btn"
                                                type="button"
                                                title="Cancel"
                                                onClick={() => {
                                                    this.onTableAction("CANCEL", item.id)
                                                    this.setState({loadingItems: [...this.state.loadingItems, {item: item, action: "cancel"}]})
                                                }}
                                                disabled={this.setDisabled(item)}
                                            >
                                                {loadingItems && loadingItems.filter((i) => i.item.id === item.id && i.action === 'cancel').length ?
                                                    <i className="fas fa-fw fa-spin fa-spinner"/>
                                                    :
                                                    <i className="fas fa-ban"></i>
                                                }
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </CamvioTable2>
                    }
            </div>
                {!this.state.noMoreResults &&
                    <div className="row" style={{marginTop: '2%'}}>
                        <div className="col-sm-12">
                            <div className="search" style={{float: "right"}}>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.setState({
                                                filters: {
                                                    ...this.state.filters,
                                                    limit: this.state.filters.limit + this.state.showAdditionalLimit
                                                }
                                            },
                                            () => this.props.getMigrationJobs(this.state.filters)
                                        )
                                    }}
                                >
                                    Show Additional
                                </button>
                                &nbsp;
                                &nbsp;
                                <select
                                    className="custom-select"
                                    value={this.state.showAdditionalLimit}
                                    onChange={(e) => this.setState({showAdditionalLimit: parseInt(e.target.value)})}
                                >
                                    <option label="20" value="20"/>
                                    <option label="30" value="30"/>
                                    <option label="40" value="40"/>
                                    <option label="50" value="50"/>
                                </select>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    const migrationJobs = getMigrationJobsData(state).migrationJobs
    const userInfo = getUser(state).user

    return {
        migrationJobs,
        userInfo,
    };
};

const mapDispatchToProps = {
    getMigrationJobs,
    validateMigrationJob,
    migrateMigrationJob,
    cancelMigrationJob,
    uploadMigrationJob,
    downloadMigrationJobsInventoryDevicesFile,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Migration);
