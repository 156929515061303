import React from "react";

export default function LoadMore({ disabled, onLoadMore, pageSize, onPageSizeChange = (value) => {} }) {
  return (
    <div className="row" style={{ marginTop: "2%" }}>
      <div className="col-sm-12">
        <div className="search" style={{ float: "right" }}>
          <button
            className="btn btn-primary"
            disabled={disabled}
            onClick={onLoadMore}
          >
            Show Additional
          </button>
          &nbsp; &nbsp;
          <select
            className="custom-select"
            value={pageSize}
            onChange={(event) => onPageSizeChange(event.target.value)}
            disabled={disabled}
          >
            <option label="20" value="20" />
            <option label="30" value="30" />
            <option label="40" value="40" />
            <option label="50" value="50" />
          </select>
        </div>
      </div>
    </div>
  );
}
