import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';
import {paymentSubmitted} from "../globalFlag.actions";

export const SET_UNPAID_INVOICES = createRequestTypes('SET_UNPAID_INVOICES');
export const SET_ACCOUNT_AMOUNTS = createRequestTypes('SET_ACCOUNT_AMOUNT');
export const SET_PAYMENT_BY_CHECK = createRequestTypes('SET_PAYMENT_BY_CHECK');
export const SET_PAYMENT_BY_WIRE_TRANSFER = createRequestTypes('SET_PAYMENT_BY_WIRE_TRANSFER');
export const SUBMIT_EXTERNAL_PAYMENT = createRequestTypes('SUBMIT_EXTERNAL_PAYMENT');
export const SET_PAYMENT_BY_CASH = createRequestTypes('SET_PAYMENT_BY_CASH');
export const SET_PAYMENT_BY_CREDIT = createRequestTypes('SET_PAYMENT_BY_CREDIT');
export const GET_PAYMENT_FORM = createRequestTypes('GET_PAYMENT_FORM');
export const GET_PAYMENT_STATUS = createRequestTypes('GET_PAYMENT_STATUS');
export const MY_PAYMENT_METHODS = createRequestTypes('MY_PAYMENT_METHODS');
export const PAYMENT_PROVIDER_FIND_CURRENT_PROVIDER_ID = createRequestTypes('FIND_CURRENT_PROVIDER_ID');
export const SET_PAYMENT_BY_TOKEN = createRequestTypes('SET_PAYMENT_BY_TOKEN');
export const CREATE_PAYMENT_METHOD_FORM = createRequestTypes('CREATE_PAYMENT_METHOD_FORM');
export const CREATE_INSTALLMENT_PLAN_PAYMENT = createRequestTypes('CREATE_INSTALLMENT_PLAN_PAYMENT');
export const GET_INSTALLMENT_PLANS = createRequestTypes('GET_INSTALLMENT_PLANS');

export const getUnpaidInvoices = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(SET_UNPAID_INVOICES));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/invoices/unpaid`);
		dispatch(actionCreator.success(SET_UNPAID_INVOICES, response.data.invoices));
	} catch (err) {
		dispatch(actionCreator.failure(SET_UNPAID_INVOICES));
	}
};

export const emptyUnpaidInvoices = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_UNPAID_INVOICES));
};

export const getAccountAmounts = (accountId) => async (dispatch) => {

	dispatch(actionCreator.request(SET_ACCOUNT_AMOUNTS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/amounts`);
		dispatch(actionCreator.success(SET_ACCOUNT_AMOUNTS, response.data.amounts));
	} catch (err) {
		dispatch(actionCreator.failure(SET_ACCOUNT_AMOUNTS));
	}
};

export const emptyAccountAmounts = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_ACCOUNT_AMOUNTS));
};

export const paymentByCheck = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PAYMENT_BY_CHECK));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/payment/check`, data);
		dispatch(actionCreator.success(SET_PAYMENT_BY_CHECK, response.data));
		await paymentSubmitted()(dispatch);

		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_PAYMENT_BY_CHECK));
		return err.response;
	}
};

export const paymentByWireTransfer = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PAYMENT_BY_WIRE_TRANSFER));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/payment/wire-transfer`, data);
		dispatch(actionCreator.success(SET_PAYMENT_BY_WIRE_TRANSFER, response.data));
		await paymentSubmitted()(dispatch);

		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_PAYMENT_BY_WIRE_TRANSFER));
		return err.response;
	}
};

export const submitExternalPayment = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(SUBMIT_EXTERNAL_PAYMENT));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/payment/external`, data);
		dispatch(actionCreator.success(SUBMIT_EXTERNAL_PAYMENT, response.data));
		await paymentSubmitted()(dispatch);

		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SUBMIT_EXTERNAL_PAYMENT));
		return err.response;
	}
};

export const paymentByCash = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PAYMENT_BY_CASH));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/payment/cash`, data);
		dispatch(actionCreator.success(SET_PAYMENT_BY_CASH, response.data));
		await paymentSubmitted()(dispatch);
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_PAYMENT_BY_CASH));
		return err.response;
	}
};

export const paymentByCredit = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PAYMENT_BY_CREDIT));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/payment/account-credit`, data);
		dispatch(actionCreator.success(SET_PAYMENT_BY_CREDIT, response.data));
		await paymentSubmitted()(dispatch);

		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_PAYMENT_BY_CREDIT));
		return err.response;
	}
};

export const getPaymentFormData = (accountId, data) => async (dispatch) => {

	dispatch(actionCreator.request(GET_PAYMENT_FORM));
	try {
		const response = await axiosInstance.post(`/payment/${accountId}/form`, data);
		dispatch(actionCreator.success(GET_PAYMENT_FORM, response.data));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_PAYMENT_FORM));
		return err;
	}
};

export const getMyPaymentMethods = (id) => async (dispatch, getState) => {
	dispatch(actionCreator.request(MY_PAYMENT_METHODS));

	try {
		const response = await axiosInstance.get(`/account/${id}/paymentMethods/`)
		return response.data.paymentMethods;
	} catch (err) {
		return null;
	}
}

export const clearPaymentFormData = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_PAYMENT_FORM));
};

export const getPaymentStatus = (referenceId) => async (dispatch) => {

	dispatch(actionCreator.request(GET_PAYMENT_STATUS));
	try {
		const response = await axiosInstance.get(`/payment/${referenceId}/status`);
		dispatch(actionCreator.success(GET_PAYMENT_STATUS, response.data));

		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_PAYMENT_STATUS));
		return err;
	}
};

export const clearPaymentStatus = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_PAYMENT_STATUS));
};

export const paymentByToken = (data) => async (dispatch) => {
	dispatch(actionCreator.request(SET_PAYMENT_BY_TOKEN));
	try {
		const response = await axiosInstance.post(`/account/${data.accountInfo.id}/payment/token`, data);
		if (response.data.status === 'SUCCESS' || response.data.status === 'USED') {
			dispatch(actionCreator.success(SET_PAYMENT_BY_TOKEN, response.data));
			await paymentSubmitted()(dispatch);
		} else if (response.data.status === 'FAIL') {
			dispatch(actionCreator.failure(SET_PAYMENT_BY_TOKEN, response.data));
		} else {
			dispatch(actionCreator.failure(SET_PAYMENT_BY_TOKEN, response.data));
		}
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SET_PAYMENT_BY_TOKEN, null));
		return err.response.data;
	}
};

export const createPaymentMethodForm = (accountId, data) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_PAYMENT_METHOD_FORM));
	try {
		const response = await axiosInstance.post(`/account/${accountId}/managePaymentMethods`, data);
		dispatch(actionCreator.success(GET_PAYMENT_FORM, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_PAYMENT_METHOD_FORM));
		return err;
	}
};

export const getInstallmentPlans = (accountId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_INSTALLMENT_PLANS));
	try {
		const response = await axiosInstance.get(`/account/${accountId}/installmentPlan/payment`);
		dispatch(actionCreator.success(GET_INSTALLMENT_PLANS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(GET_INSTALLMENT_PLANS));
	}
};

export const payInstallmentPlan = (accountId, installmentPlanBalance) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_INSTALLMENT_PLAN_PAYMENT));
	try {
		const response = await axiosInstance.post(`/payment/${accountId}/installmentPlan`, installmentPlanBalance);
		dispatch(actionCreator.success(CREATE_INSTALLMENT_PLAN_PAYMENT, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_INSTALLMENT_PLAN_PAYMENT));
	}
};

