import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../../actions/modal.actions";
import { createLoadingSelector, getModalData, getErrorMessage } from "../../../selectors/index";
import { createSystemConfiguration } from "../../../actions/systemConfig.actions";
import { Formik, Form } from "formik";
import isEmpty from "../../../utils/helpers";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";
import TextInput from "src/components/UI/TextInput";

class AddSystemConfigurationModal extends Component {
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}

  closeModal = () => {
    this.props.hideModal();
    this.props.modalProps.onSuccess();
  };

  formChange = (values) => {
    this.props.getAffectedAccountsByBillingJob(values).then((data) => {
      if (data.success) {
        this.setState({
          affectedAccounts: data.billingJob.processedAccounts,
        });
      } else {
        this.setState({
          affectedAccounts: null,
        });
      }
    });
  };

  formSubmit = (values, actions) => {
    actions.setSubmitting(true);
    this.props.createSystemConfiguration(values).then((response) => {
      actions.setSubmitting(false);
      if (response.data && response.data.id) {
        toastr.success(`System Configuration #${response.data.id} has been created.`, { timeOut: 2000, position: "top-center" });
        this.closeModal();
      }
    });
  };

  render() {
    const { modalProps, submissionError } = this.props;

    return (
      <Fragment>
        <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
          <Formik
            initialValues={{
              key: "",
              description: "", // initialize as next month
              value: "",
            }}
            validationSchema={Yup.object().shape({
              key: Yup.string()
                .required("Required")
                .min(2, 'Must be at least 2 characters long')
                .matches(/^[0-9a-z._]+$/gi, "Key must contain only letters, numbers, underscore(_) and dot(.)"),
              description: Yup.string().required("Required"),
              value: Yup.string(),
            })}
            onSubmit={this.formSubmit}
          >
            {({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">New System Configuration</h5>
                      <button onClick={this.props.hideModal} type="button" className="close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body form-horizontal">
                      {!isEmpty(submissionError) && (
                        <div className="alert alert-inline alert-danger alert-dismissible">
                          <p className="mb-0">{submissionError}</p>
                        </div>
                      )}

                      <TextInput
                        label="Key"
                        name="key"
                        type="text"
                        placeholder="Key"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.key}
                        touched={touched}
                        errors={errors}
                      />

                      <TextInput
                        label="Description"
                        name="description"
                        type="text"
                        placeholder="Description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />

                      <TextInput
                        label="Value"
                        name="value"
                        type="text"
                        placeholder="Value"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />
                    </div>

                    <div className="modal-footer">
                      <button onClick={this.props.hideModal} className="btn" type="button">
                        Cancel
                      </button>
                      <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </Fragment>
    );
  }
}

const createSystemConfigurationLoader = createLoadingSelector(["SYSTEM_CONFIGURATION_CREATE"]);
const createSystemConfigurationError = getErrorMessage(["SYSTEM_CONFIGURATION_CREATE"]);

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps;
  const submitting = createSystemConfigurationLoader(state);
  const submissionError = createSystemConfigurationError(state);

  return {
    modalProps,
    submitting,
    submissionError,
  };
};

const mapDispatchToProps = {
  hideModal,
  createSystemConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemConfigurationModal);
