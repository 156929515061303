import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import FormSelect from "../../../components/UI/FormSelect";
import TextInput from "../../../components/UI/TextInput";
import { getModalData, getDomain, getTroubleTicketCodes } from '../../../selectors';
import { getServiceModels } from "../../../actions/domain.actions";
import { getSystemLocations } from "../../../actions/admin.actions";
import { addTroubleTicketReportedCode, updateTroubleTicketReportedCode, getTroubleTicketReportedCode, clearTroubleTicketReportedCode } from "../../../actions/troubleTicketCodes.action";
import { Formik } from 'formik';
import isEmpty from "../../../utils/helpers";
import Loader from "../../../components/Loader";
import * as Yup from "yup";
import { toastr } from "react-redux-toastr";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
};

const serviceModelsAsOptions = (serviceModels) => asOptions(serviceModels, serviceModel => {
    return {
        label: serviceModel.description,
        value: serviceModel.id
    };
});


class EditTroubleTicketReportedCodeModal extends Component {

    state = {
        alertMessage: '',
        isReportedCodeLoading: true,
    };

    componentDidMount() {
        this.props.clearTroubleTicketReportedCode();
        this.props.getServiceModels();

        const { modalProps } = this.props;

        if (modalProps.value) {
            this.props.getTroubleTicketReportedCode(modalProps.value).then((response) => {
                this.setState({
                    isReportedCodeLoading: false,
                })
            })
        } else {
            this.setState({
                isReportedCodeLoading: false,
            })
        }

    }

    componentWillUnmount() {
        this.props.clearTroubleTicketReportedCode();
    }

    reportedCodeSchema = () => {
        let validationSchema = {
            reportedCode: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            serviceModelId: Yup.number().required('Required').typeError('Required'),
            active: Yup.boolean(),
        };

        return Yup.object().shape(validationSchema);
    }



    formSubmit = (values, actions) => {
        const submitData = {
            ...values,
            reportedCode: (values.reportedCode || '').toUpperCase() // without this, often the last letter stays lowercase
        };

        this.setState({ alertMessage: '' }, () => {
            const method = this.props.modalProps.type === 'create' ? this.props.addTroubleTicketReportedCode : this.props.updateTroubleTicketReportedCode;

            method(submitData.id, submitData).then((response) => {
                if (!response) {
                    return;
                }
                //If any failures happen on edit or create, show the error, only refresh if edit
                if (!response.success) {
                    toastr.error(response.data.error.message, { timeOut: 8000, position: 'top-center' });
                    if (this.props.modalProps.type != 'create') {
                        this.setState({
                            isReportedCodeLoading: true
                        })
                        this.props.getTroubleTicketReportedCode(this.props.modalProps.value);
                    }
                    return response.data.error;
                } else {
                    this.props.hideModal();
                    this.props.modalProps.reloadFunction(true);
                    toastr.success(response.message, { timeOut: 4000, position: 'top-center' });
                }
            });
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({ block: "end" });
                    }
                }
            }, 200);
        }
    }


    render() {
        const { alertMessage, isReportedCodeLoading } = this.state;
        const { modalProps, reportedCode, serviceModels } = this.props;

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    {isReportedCodeLoading &&
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        {modalProps.type === 'create' ? 'Add Reported Code' : 'Edit Reported Code'}
                                    </h5>
                                    <button onClick={this.props.hideModal} type="button" className="close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <Loader />
                                <div className="modal-footer">
                                    <button onClick={this.props.hideModal} className="btn" type="button">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        {modalProps.type === 'create' ? 'Add Reported Code' : 'Edit Reported Code'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {!isReportedCodeLoading &&
                        <Formik
                            initialValues={{
                                id: reportedCode && reportedCode.id || null,
                                reportedCode: reportedCode && reportedCode.reportedCode || '',
                                description: reportedCode && reportedCode.description || '',
                                serviceModelId: reportedCode && reportedCode.serviceModelId || null,
                                active: reportedCode ? reportedCode.active : true,
                            }}
                            validationSchema={this.reportedCodeSchema()}
                            onSubmit={this.formSubmit}
                            render={(props) => {
                                const {
                                    handleChange,
                                    handleSubmit,
                                    handleBlur,
                                    values,
                                    errors,
                                    touched,
                                    isSubmitting,
                                    setFieldValue
                                } = props;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="modal-dialog modal-lg">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">
                                                        {modalProps.type === 'create' ? 'Add Reported Code' : 'Edit Reported Code'}
                                                    </h5>
                                                    <button onClick={this.props.hideModal} type="button" className="close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body form-horizontal">
                                                    {!isEmpty(alertMessage) && (
                                                        <div className="alert alert-inline alert-danger alert-dismissible">
                                                            <p className="mb-0">{alertMessage}</p>
                                                        </div>
                                                    )}
                                                    <div className="form-group">
                                                        <span className="h-check">
                                                            <label>Active&nbsp;</label>
                                                            <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                                <label>
                                                                    <input
                                                                        name="active"
                                                                        type="checkbox"
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        checked={values.active}
                                                                    />
                                                                    <span>&nbsp;</span>
                                                                </label>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div className="form-group">
                                                        <TextInput
                                                            label="Code"
                                                            name="reportedCode"
                                                            value={values.reportedCode.toUpperCase()}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <TextInput
                                                            label="Description"
                                                            name="description"
                                                            value={values.description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <div onClick={this.scrollDropdownIntoView.bind(this)}>
                                                            <FormSelect
                                                                title="Service Model"
                                                                fieldName="serviceModelId"
                                                                placeholder="Select one..."
                                                                options={serviceModelsAsOptions(serviceModels)}
                                                                setFieldValue={setFieldValue}
                                                                value={values.serviceModelId}
                                                                onBlur={handleBlur}
                                                                isDisabled={(this.props.modalProps.type === 'create' ? false : true)}
                                                                errors={errors}
                                                                touched={touched}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button onClick={this.props.hideModal} className="btn" type="button">
                                                        Cancel
                                                    </button>
                                                    <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                        {modalProps.type === 'create' ? 'Add Reported Code' : 'Edit Reported Code'}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        />}
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        reportedCode = getTroubleTicketCodes(state).reportedCode,
        serviceModels = getDomain(state).serviceModels

    return {
        modalProps,
        reportedCode,
        serviceModels,
    };
};

const mapDispatchToProps = {
    getTroubleTicketReportedCode,
    getServiceModels,
    getSystemLocations,
    clearTroubleTicketReportedCode,
    addTroubleTicketReportedCode,
    updateTroubleTicketReportedCode,
    hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTroubleTicketReportedCodeModal);