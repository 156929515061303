import React, {Component, Fragment} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {connect} from 'react-redux';
import {showModal} from '../../actions/modal.actions';
import {getAppointmentAreas, clearAppointmentAreas, getMappingAreas, getMappingZones} from '../../actions/appointmentAreas.actions'
import {getAppointmentTypes} from "../../actions/appointments.actions";
import {createLoadingSelector, getAreas} from "../../selectors";
import Loader from "../../components/Loader";
import isEmpty from "../../utils/helpers";
import {toastr} from "react-redux-toastr";

class AppointmentMapping extends Component {

    state = {
        resultsNumber: 20,
        searchTerm: '',
    }

    componentDidMount() {
        this.fetchAreas();
        this.fetchAppointmentTypes();
    }

    componentWillUnmount() {
        this.props.clearAppointmentAreas();
    }

    fetchAreas =  () => {
        this.props.getAppointmentAreas().then(() => {
                this.props.getMappingZones().then((response) => {
                    if(response.success != undefined && !response.success) {
                       toastr.error(response.error.message, {position: 'top-center' });
                    }else{
                        this.generateAreas();
                    }
                });
        });
    }

    fetchAppointmentTypes =  () => {
        this.props.getAppointmentTypes().then((response) => {
            this.setState({
                appointmentTypes: response
            })
        });
    }

    reloadAreas = () => {
        this.props.getAppointmentAreas().then(() => {
            this.generateAreas();
        });
    }

    generateAreas = () => {
        let mappingAreas = {};
        let availableOrderMappingAreas = {};
        let availableTroubleTicketMappingAreas = {};
        let appointmentAreas = [];

        this.props.mappingZones.forEach(mappingZone => {
            mappingAreas[mappingZone.id] = mappingZone.name;
        });

        // Generate appointment areas with mapping area names
        if (this.props.appointmentAreas && this.props.appointmentAreas.length > 0) {

            this.props.appointmentAreas.map(appointmentArea => {

                let appointmentAreaMappingAreas = {};

                // Go through all stored mapping area IDs
                appointmentArea.appointmentMappingIds.sort().forEach(mappingAreaId => {

                if(this.props.mappingZones && this.props.mappingZones.length > 0) {
                        if (this.props.mappingZones.filter(area => area.id === mappingAreaId)[0]) {
                            // Get mapping area name
                            appointmentAreaMappingAreas[mappingAreaId] = mappingAreas[mappingAreaId];
                        }

                        else {
                            // Keep the ID anyway for compatibility
                            appointmentAreaMappingAreas[mappingAreaId] = 'NOT FOUND';
                        }
                    }
                })

                // Sort mapping areas alphabetically
                let appointmentAreaMappingAreasNames = [...Object.keys(appointmentAreaMappingAreas)];
                let sortedAppointmentAreaMappingAreas = {}

                appointmentAreaMappingAreasNames.sort().forEach((key) => {
                    sortedAppointmentAreaMappingAreas[key] = appointmentAreaMappingAreas[key];
                });

                // Add appointment area
                appointmentAreas.push({
                    id: appointmentArea.id,
                    name: appointmentArea.name,
                    description: appointmentArea.description,
                    mappingAreas: sortedAppointmentAreaMappingAreas,
                    appointmentTypeId: appointmentArea.appointmentTypeId,
                    appointmentType: appointmentArea.appointmentType
                });
            });
        }

        // Copy mapping areas object
        Object.assign(availableOrderMappingAreas, mappingAreas);
        Object.assign(availableTroubleTicketMappingAreas, mappingAreas);

        // Get available mapping areas
        appointmentAreas.map(appointmentArea => {
            if(appointmentArea.appointmentType == "Order"){
                Object.keys(appointmentArea.mappingAreas).map(mappingAreaName => {
                    delete availableOrderMappingAreas[mappingAreaName];
                })
            }else if(appointmentArea.appointmentType == "Trouble Ticket"){
                Object.keys(appointmentArea.mappingAreas).map(mappingAreaName => {
                    delete availableTroubleTicketMappingAreas[mappingAreaName];
                })
            }
        });

        // Store areas
        this.setState({
            mappingAreas: mappingAreas,
            availableOrderMappingAreas: availableOrderMappingAreas,
            availableTroubleTicketMappingAreas: availableTroubleTicketMappingAreas,
            allAppointmentAreas: appointmentAreas,
        }, () => {
            this.getFilteredAreas();
        });
    }

    handleResultNumberChange = (e) => {
        let {value} = e.target;

        this.setState({
            resultsNumber: value || undefined
        }, () => {
            this.getFilteredAreas();
        });
    }

    handleSearchChange = (event) => {
        let updatedSearchTerm = event.target.value;

        if (this.state.searchTerm !== updatedSearchTerm) {

            this.setState({
                searchTerm: updatedSearchTerm
            }, () => {

                if (this.searchChangeTimeout) {
                    clearTimeout(this.searchChangeTimeout);
                }

                this.searchChangeTimeout = setTimeout(() => {
                    this.getFilteredAreas();
                }, 300);
            });
        }
    }

    getFilteredAreas = () => {
        let updatedFilteredAppointmentAreas = this.state.allAppointmentAreas;

        // Filter by current search term
        if (!isEmpty(this.state.searchTerm)) {
            updatedFilteredAppointmentAreas = updatedFilteredAppointmentAreas.filter(area =>
                area.description && area.description.toUpperCase().includes(this.state.searchTerm.trim().toUpperCase())
            );
        }

        // Get appropriate number of results
        if (!isEmpty(this.state.resultsNumber)) {
            updatedFilteredAppointmentAreas = updatedFilteredAppointmentAreas.slice(0, this.state.resultsNumber)
        }

        // Display filtered areas
        this.setState({
            filteredAppointmentAreas: updatedFilteredAppointmentAreas
        });
    }

    appointmentAreasFormatter = (cell, row, rowIndex) => {
        return (
            <div>
                {row.name}
                <span className="td-subtitle">{row.description}</span>
            </div>
        );
    }

    appointmentTypeFormatter = (cell, row, rowIndex) => {
        return (
            <div>
                {row.appointmentType.toUpperCase()}
            </div>
        );
    }

    mappingAreasFormatter = (cell, row, rowIndex) => {
        return (
            <span className="td-subtitle">
                {Object.values(row.mappingAreas).filter(mappingArea => mappingArea !== 'NOT FOUND').map(mappingAreaName =>
                    <div key={mappingAreaName}>{mappingAreaName}</div>
                )}
            </span>
        );
    }

    actionsFormatter = (cell, row, rowIndex) => {
        return (
            <div className="tr-actions">
                <button
                    onClick={() => this.props.showModal('APPOINTMENT_MAPPING_MODAL', {
                        row,
                        type: 'edit',
                        appointmentTypeId: this.state.appointmentTypeId,
                        appointmentTypes: this.state.appointmentTypes,
                        mappingAreas: this.state.mappingAreas,
                        availableMappingAreas: row.appointmentType == "Order" ? this.state.availableOrderMappingAreas : this.state.availableTroubleTicketMappingAreas,
                        allAppointmentAreas: this.state.allAppointmentAreas,
                        appointmentArea: this.state.filteredAppointmentAreas[rowIndex],
                        onCloseCallback: () => {this.reloadAreas()},
                    })}
                    disabled={!this.props.canEdit}
                    className="btn"
                    type="button"
                >
                    <i className="fas fa-edit" />
                </button>
            </div>
        );
    }

    render() {

        const {
            resultsNumber,
            searchTerm,
            filteredAppointmentAreas,
        } = this.state;

        const {
            loadingAppointmentAreas,
            loadingMappingZones,
            appointmentAreas,
        } = this.props;

        const rowStyle = (row, rowIndex) => {
            if (row) {
                let classes = '';

                if (row.note) {
                    classes = 'has-note ';
                }

                if (rowIndex % 2 === 0) {
                    classes += 'odd';
                } else {
                    classes += 'even';
                }

                return classes;
            }
        };

        return (
            <div className="cmv-container-table">

                <div className="row">
                    <div className="col-sm-5">

                        <div className="controls">
                            <label>Show</label>
                            <select
                                className="custom-select"
                                value={resultsNumber}
                                onChange={this.handleResultNumberChange}
                                disabled={loadingAppointmentAreas || loadingMappingZones}
                            >
                                <option label="20" value="20">20</option>
                                <option label="50" value="50">50</option>
                                <option label="100" value="100">100</option>
                                <option label="All" value="">All</option>
                            </select>
                            <label>entries</label>
                        </div>

                    </div>
                    <div className="col-sm-7">

                        <div className="search">

                            <label htmlFor="appointmentSearch" className="col-form-label">Search</label>

                            <input
                                type="text"
                                className="form-control"
                                id="appointmentSearch"
                                value={searchTerm}
                                onChange={this.handleSearchChange}
                                disabled={loadingAppointmentAreas || loadingMappingZones}
                                autoComplete='off'
                            />

                            <button
                                className="btn btn-primary"
                                disabled={loadingAppointmentAreas || loadingMappingZones || !this.props.canEdit}
                                onClick={() => this.props.showModal('APPOINTMENT_MAPPING_MODAL', {
                                    type: 'create',
                                    mappingAreas: this.state.mappingAreas,
                                    availableOrderMappingAreas: this.state.availableOrderMappingAreas,
                                    availableTroubleTicketMappingAreas: this.state.availableTroubleTicketMappingAreas,
                                    appointmentTypeId: this.state.appointmentTypeId,
                                    appointmentTypes: this.state.appointmentTypes,
                                    onCloseCallback: () => {this.reloadAreas()}
                                })}
                            >
                                <i className="fas fa-plus" /> New
                            </button>

                        </div>
                    </div>
                </div>

                {(loadingAppointmentAreas || loadingMappingZones || isEmpty(appointmentAreas)) &&
                <Loader/>
                }

                {!loadingAppointmentAreas && !loadingMappingZones && (
                    <ToolkitProvider
                        bootstrap4
                        keyField="id"
                        data={filteredAppointmentAreas || []}
                        columns={[{
                            text: 'Appointment Area',
                            dataField: 'appointmentAreas',
                            formatter: this.appointmentAreasFormatter,
                        }, {
                            text: 'Type',
                            dataField: 'appointmentType',
                            formatter: this.appointmentTypeFormatter,
                        },{
                            text: 'Mapping Areas',
                            dataField: 'mappingAreas',
                            formatter: this.mappingAreasFormatter,
                        }, {
                            text: 'Actions',
                            dataField: 'id',
                            formatter: this.actionsFormatter,
                            headerAlign: 'right',
                            align: 'right',
                        }]}
                        defaultSorted={{dataField: 'id', order: 'desc'}}
                    >
                        {(props) => (
                            <div className="row">
                                <BootstrapTable
                                    wrapperClasses="table-responsive react-table-layout"
                                    classes="table table-striped cmv-table"
                                    bordered={false}
                                    rowClasses={rowStyle}
                                    noDataIndication="No data found"
                                    {...props.baseProps}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                )}

            </div>
        );
    }
}

const appointmentAreasLoader = createLoadingSelector(['GET_APPOINTMENT_AREAS']);
const mappingZonesLoader = createLoadingSelector(['GET_MAPPING_ZONES']);

const mapStateToProps = (state) => {
    const loadingAppointmentAreas = appointmentAreasLoader(state);
    const loadingMappingZones = mappingZonesLoader(state);
    const mappingAreas = getAreas(state).mappingAreas;
    const appointmentAreas = getAreas(state).appointmentAreas;
    const mappingZones = getAreas(state).mappingZones;

    return {
        loadingAppointmentAreas,
        loadingMappingZones,
        mappingAreas,
        appointmentAreas,
        mappingZones
    };
}

const mapDispatchToProps = {
    showModal,
    getAppointmentAreas,
    getMappingZones,
    clearAppointmentAreas,
    getAppointmentTypes
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentMapping);
