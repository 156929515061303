import React, {useEffect} from 'react'
import {useState} from "react";
import {GoogleMap, useJsApiLoader, Marker, OverlayView} from '@react-google-maps/api';
import {Appointment} from "./Appointment"
import {isEmpty} from "lodash";
import Select from "react-select";
import {groupStyles} from "../../../../../utils/SelectStyles";
import {showModal} from "../../../../../actions/modal.actions";
import _ from 'lodash'

const Map = ({appointments, handleShowAssignDropdownById, serviceAddressAccessModal, showDropdownById,
                 handleAllTasksAssignee, isAssigning, accountAccessModal, usernameOptions, user}) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyC-iemfVGBxibxVbbTxgm813w_hYiur9DU"
    })
    const [map, setMap] = React.useState(null)
    const [stateAppointments, setStateAppointments] = useState([])
    const [selectedAppointments, setSelectedAppointments] = useState([])
    const [mapVisible, setMapVisible] = useState(true)
    const [bulkAssign, setBulkAssign] = useState(false)
    const [assignDropdownVisible, setAssignDropdownVisible] = useState(false)


   useEffect(() => {
       assignColorsToAssignees()
       setStateAppointments(appointments)
   })

    const colorMarkerIcon = {
        path: 'M 0 0 L -2 -5 H -11 C -19 -5 -19 -19 -11 -19 H 11 C 19 -19 19 -5 11 -5 L 2 -5 L 0 0',
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2.2
    };

    const selectedMarkerIcon = {
        path: 'M 0 0 L -2 -5 H -11 C -19 -5 -19 -19 -11 -19 H 11 C 19 -19 19 -5 11 -5 L 2 -5 L 0 0',
        fillColor:"black",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2.2,
    };

    const assignColorsToAssignees = () => {
        appointments.map((appt) => {
            if(appt.type === "order") {
             appt.taskAssignees.length > 0 ? appt.color = "#3C4A9C" : appt.color = "#48aad3"
            }
            else if(appt.type === "troubleTicket") {
             appt.taskAssignees.length > 0 ? appt.color = "#0d752e": appt.color = "#39c034"
            }
        })
    }



    const onLoad = React.useCallback(function callback(map) {
        //Finds max and min values for lat/lng to show all map pins
        let northBound = Math.max(...appointments.map(appt => appt.address.latitude))
        let southBound = Math.min(...appointments.map(appt => appt.address.latitude))
        let westBound = Math.min(...appointments.map(appt => appt.address.longitude))
        let eastBound = Math.max(...appointments.map(appt => appt.address.longitude))
        const bounds = {
            north: northBound + 0.5,
            south: southBound - 0.5,
            west: westBound - 0.5,
            east: eastBound + 0.5,
        };
        map.fitBounds(bounds);
        setMap(map)
    })

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])


    const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
        //Marker label is an overlay and this is responsible for offsetting to desired svg markers
        return {
            x: offsetWidth + labelAnchor.x,
            y: offsetHeight + labelAnchor.y,
        };
    };

    const appointmentSelectionHandler = (appointment) => {
        if(!selectedAppointments.includes(appointment) && bulkAssign)  {
            setSelectedAppointments((prevState) => [...selectedAppointments, appointment])
            appointment.marker = selectedMarkerIcon

        }
        else if(selectedAppointments.includes(appointment) && !bulkAssign) {
            setSelectedAppointments([])
        }
        else if(selectedAppointments.includes(appointment) && bulkAssign) {
            setSelectedAppointments(selectedAppointments.filter((appt) => appt.id !== appointment.id))
        }
        else {
            setSelectedAppointments([appointment])
            appointment.marker = selectedMarkerIcon
        }

    }

    const bulkSelectionHandler = () => {
        //If bulk assign toggle is set to false removes all selected appts except last
            setSelectedAppointments((prevState) => [selectedAppointments.pop()])
    }



    return isLoaded && mapVisible && (
        <div className="appointment-map-container">
        <GoogleMap
            mapContainerClassName='map-canvas'
            zoom={8}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            { /* Child components, such as markers, info windows, etc. */ }
            {stateAppointments.map((appointment) => {
                if(selectedAppointments.includes(appointment)) {
                    appointment.marker = selectedMarkerIcon
                }
                else {
                    /* else colorMarkerIcon object is spread into new object to not mutate original when iterating
                    and dynamically receives new color for every unique appt */
                    let colorIcon = {}
                    colorIcon = {...colorMarkerIcon}
                    colorIcon.fillColor = appointment.color
                    appointment.marker = colorIcon
                }
                return(
                    <>
                            <Marker key={appointment.id}
                                    position={{lat: appointment.address.latitude, lng: appointment.address.longitude}}
                                    icon={appointment.marker}
                                    onClick={() => appointmentSelectionHandler(appointment)}
                            >
                                <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                             position={{
                                                 lat: appointment.address.latitude,
                                                 lng: appointment.address.longitude
                                             }}
                                             getPixelPositionOffset={(x, y) => getPixelPositionOffset(x, y, {
                                                 x: -90,
                                                 y: -68
                                             })}
                                >
                                    <div style={{
                                        color: 'white', padding: '0px', margin: '0px',
                                        fontWeight: 'bold', zIndex: stateAppointments.indexOf(appointment)
                                    }}
                                         onClick={() => appointmentSelectionHandler(appointment)}
                                    >
                                        {!isEmpty(appointment.taskAssignees) ? _.uniq(appointment.taskAssignees)[0].slice(0,9) + "..." : "Unassigned"}
                                        <br/>
                                        {appointment.appointmentDetails.timeslot}
                                    </div>
                                </OverlayView>
                            </Marker>
                    </>
                )
                }
            )}
        </GoogleMap>




                        {!isEmpty(selectedAppointments) &&
                        <div className="map-appointment-list">

                    <div className="form-check checkbox-slider checkbox-slider--b-flat checklist" style={{display:'flex'}}>
                        <label>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    setBulkAssign(prevBulkAssign => !prevBulkAssign)
                                    if (bulkAssign) bulkSelectionHandler()
                                }}
                                name="name"
                            />
                            <span>&nbsp;</span>
                        </label>
                            <div className="checkbox-label" style={{whiteSpace:'nowrap'}}>Bulk Assignment</div>
                    </div>
                    <div>
                            <div className="cmv-container-appointment-list-search-results">
                                {bulkAssign ?
                                    <div className="appointment-list">
                                        {selectedAppointments.map(appointment =>
                                            <Appointment
                                                handleShowAssignDropdownById={handleShowAssignDropdownById}
                                                showDropdownById={showDropdownById}
                                                handleAllTasksAssignee={handleAllTasksAssignee}
                                                accountAccessModal={accountAccessModal}
                                                showModal={showModal}
                                                usernameOptions={usernameOptions}
                                                isAssigning={isAssigning}
                                                key={(appointment.id + "-" + appointment.assignee)}
                                                appointmentKey={(appointment.id + "-" + appointment.assignee)}
                                                appointment={appointment}
                                                mapVisible={mapVisible}
                                            />
                                        )}
                                    </div>
                                    :
                                    <div className="appointment-list">
                                    <Appointment
                                        handleShowAssignDropdownById={handleShowAssignDropdownById}
                                        showDropdownById={showDropdownById}
                                        handleAllTasksAssignee={handleAllTasksAssignee}
                                        accountAccessModal={accountAccessModal}
                                        serviceAddressAccessModal={serviceAddressAccessModal}
                                        usernameOptions={usernameOptions}
                                        isAssigning={isAssigning}
                                        key={(selectedAppointments.slice(-1)[0].id + "-" + selectedAppointments.slice(-1)[0].assignee)}
                                        appointmentKey={(selectedAppointments.slice(-1)[0].id + "-" + selectedAppointments.slice(-1)[0].assignee)}
                                        appointment={selectedAppointments.slice(-1)[0]}
                                        mapVisible={mapVisible}
                                    />
                                    </div>
                                }
                            </div>
                        </div>
                    <button
                        className="map-appointment btn-circle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                        onClick={() => {
                            setAssignDropdownVisible((prevState) => !prevState)
                        }}
                    >
                        <i className="fas fa-user-plus"/>
                    </button>

                    {assignDropdownVisible &&
                        <div className="dropdown-menu-with-select dropdown-menu-right show"
                             style={{backgroundColor:'#F8F9FAFF', borderRadius:'0.25rem', padding:'0.5rem 0',
                                 border:'1px solid #ced4da'}}
                        >
                        <button
                            onClick={() => {
                                let taskIdArray = []
                                selectedAppointments.map((appt) => taskIdArray.push(appt.taskIds))
                                handleAllTasksAssignee('claim', taskIdArray.flat())
                            }}
                            className="dropdown-item"
                        >
                            Claim
                        </button>
                        <button
                            onClick={() => {
                                let taskIdArray = []
                                selectedAppointments.map((appt) => taskIdArray.push(appt.taskIds))
                                handleAllTasksAssignee('unassign', taskIdArray.flat())
                            }}
                            className="dropdown-item"
                        >
                            Unassign
                        </button>
                        <div className="dropdown-divider"/>
                        <h6 className="dropdown-header">Assign To User</h6>
                        <div className="dropdown-item">
                            <Select
                                placeholder="Select"
                                styles={groupStyles}
                                options={usernameOptions}
                                onChange={(value) => {
                                    let taskIdArray = []
                                    selectedAppointments.map((appt) => taskIdArray.push(appt.taskIds))
                                    handleAllTasksAssignee('username', taskIdArray.flat(), value)
                                }}
                            />
                        </div>
                    </div>
                    }
                    </div>
            }

        </div>
    )
}

export default React.memo(Map)
