import {
    GET_SYSTEM_USERS_UI,
    GET_PRODUCT_PLANS_UI,
    GET_INSTALLMENT_PLANS_UI,
    GET_PRODUCT_FEATURES_UI,
    GET_AUTOPAY_JOBS_UI,
    GET_FEATURE_PACKAGES_UI,
    GET_ADJUSTMENTS_UI,
    GET_TROUBLE_TICKET_REPORTED_CODES_UI,
    GET_TROUBLE_TICKET_RESOLUTION_CODES_UI,
    GET_ADMIN_LOCATIONS_UI,
} from '../actions/uiController.actions';

let initialState = {
    systemUsersUi: [],
    plansUi: [],
    featuresUi: [],
    installmentPlansUi: [],
    autoPayJobsUi: [],
    featurePackagesUi: [],
    adjustmentsUi: [],
    troubleTicketReportedCodesUi: [],
    troubleTicketResolutionCodesUi: [],
    locationsUi: [],
};

export const uiControllerReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SYSTEM_USERS_UI.SUCCESS:
            return {
                ...state,
                systemUsersUi: action.payload
            }
        case GET_PRODUCT_PLANS_UI.SUCCESS:
            return {
                ...state,
                plansUi: action.payload
            }
        case GET_INSTALLMENT_PLANS_UI.SUCCESS:
            return {
                ...state,
                installmentPlansUi: action.payload
            }
        case GET_PRODUCT_FEATURES_UI.SUCCESS:
            return {
                ...state,
                featuresUi: action.payload
            }
        case GET_AUTOPAY_JOBS_UI.SUCCESS:
            return {
                ...state,
                autoPayJobsUi: action.payload
            }
        case GET_FEATURE_PACKAGES_UI.SUCCESS:
            return {
                ...state,
                featurePackagesUi: action.payload
            }
        case GET_ADJUSTMENTS_UI.SUCCESS:
            return {
                ...state,
                adjustmentsUi: action.payload
            }
        case GET_TROUBLE_TICKET_REPORTED_CODES_UI.SUCCESS:
            return {
                ...state,
                troubleTicketReportedCodesUi: action.payload
            }
        case GET_TROUBLE_TICKET_RESOLUTION_CODES_UI.SUCCESS:
            return {
                ...state,
                troubleTicketResolutionCodesUi: action.payload
            }
        case GET_ADMIN_LOCATIONS_UI.SUCCESS:
            return {
                ...state,
                locationsUi: action.payload
            }
        default:
            return state;
    }
};
