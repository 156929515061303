import { AUTH_PROVIDER_CAMVIO } from 'src/utils/const';
import { AUTH } from '../actions/auth.actions';

let initialState = {
	authProvider: AUTH_PROVIDER_CAMVIO,
	isLoggedIn: false,
	username: '',
	token: '',
	refreshToken: ''
};

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTH.SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				username: action.payload.username,
				token: action.payload.token,
				refreshToken: action.payload.refreshToken !== '' && action.payload.refreshToken,
				authProvider: action.payload.authProvider || AUTH_PROVIDER_CAMVIO,
			};
		case 'REFRESH_SIGN_IN': // This prevent race conditions when user log-out and log-in with other account
			return {
				...state,
				isLoggedIn: true,
				username: action.payload.username,
				token: action.payload.token,
				refreshToken: action.payload.refreshToken !== '' && action.payload.refreshToken
			}
		default:
			return state;
	}
};
