import {
	GET_APPOINTMENT_AREAS,
	GET_MAPPING_ZONES
} from '../actions/appointmentAreas.actions';

let initialState = {
	appointmentAreas: [],
	mappingAreas: [],
	mappingZones: [],
	timeslots: []
};

export const appointmentAreasReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_APPOINTMENT_AREAS.SUCCESS:
			return {
				...state,
				appointmentAreas: action.payload.appointmentAreas
			}
		case GET_APPOINTMENT_AREAS.CLEAR:
			return {
				...state,
				appointmentAreas: []
			}
		case GET_MAPPING_ZONES.SUCCESS:
			return {
				...state,
				mappingZones: action.payload
			}
		case GET_MAPPING_ZONES.CLEAR:
			return {
				...state,
				mappingZones: []
			}
		default:
			return state;
	}
};