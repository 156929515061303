import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getReports } from '../../../actions/sideBar.actions';
import { clearViewAs } from '../../../actions/viewAs.actions';
import {getDashboardData, getSideBar, getViewAs} from '../../../selectors';
import SideBar from './SideBar';
import Header from './Header';
import Footer from '../Footer';
import RootModal from '../../Modals/RootModal';

class SideBarLayout extends Component {

	state = {
		showSideBar: false,
		showAccountModal: false,
		navBarType: 'default',
		navbarTitle: ''
	};

	componentDidMount() {
		this.props.getReports();
	}

	sideBarToggleHandler = () => {
		this.setState((prevState) => {
			return { showSideBar: !prevState.showSideBar };
		});
	};

	showAccoutModalHandler = () => {
		this.setState({
			showAccountModal: true,
			showSideBar: false
		});
	};

	hideAccountModalHandler = () => {
		this.setState({
			showAccountModal: false
		});
	};

	changeNavBarType = (navBarType, title) => {
		this.setState({
			navBarType: navBarType,
			navbarTitle: title
		});
	};

	render() {
		const { showSideBar, showAccountModal, navbarTitle } = this.state;
		const { userPermission, userSummary, dashboardMenuCount, history, currentLocation, reportCategories, viewAsState, clearViewAs } = this.props;

		const childrenWithProps = React.Children.map(this.props.children, (child) =>
			React.cloneElement(child, {
				showAccountModal: this.state.showAccountModal,
				changeNavBarType: this.changeNavBarType
			})
		);

		return (
			// <div className="cmv-page cmv-page-dashboard modal-open">
			<Fragment>

				<div className={'page-wrapper page-wrapper-sidebar cmv-theme' + (showSideBar ? ' toggled' : '')}>

					<SideBar
						userPermission={userPermission}
						userSummary={userSummary}
						dashboardMenuCount={dashboardMenuCount}
						history={history}
						reportCategories={reportCategories}
					/>

					<div className={"page-content" + (viewAsState.partner ? " page-content-viewas" : "")}>

						<Header
							sideBarToggleHandler={this.sideBarToggleHandler}
							showAccoutModalHandler={this.showAccoutModalHandler}
							hideAccountModalHandler={this.hideAccountModalHandler}
							userSummary={userSummary}
							currentLocation={currentLocation}
							showAccountModal={showAccountModal}
							history={history}
							navbarTitle={navbarTitle}
						/>

						<main>
							{viewAsState.partner &&
							<div className="page-alert">
								<p>You're viewing this page as partner <span className="page-alert-text">{viewAsState.partner && viewAsState.partner.description}</span> at location <span className="page-alert-text">{viewAsState.location && viewAsState.location.description}</span>.</p>
								<button className="btn btn-sm btn-white"
										onClick={clearViewAs}
								>
									Exit
								</button>
							</div>
							}
							{childrenWithProps}
						</main>

						<Footer />

					</div>

				</div>

				<RootModal {...this.props} />

			</Fragment>
			// </div>
		);
	}
}

SideBarLayout.propTypes = {
	userPermission: PropTypes.array.isRequired,
	userSummary: PropTypes.object,
	dashboardMenuCount: PropTypes.object,
	history: PropTypes.object,
	currentLocation: PropTypes.object
};

const mapStateToProps = (state) => {
	const dashboardMenuCount = getDashboardData(state).dashboardMenuCount,
		reportCategories = getSideBar(state).reportCategories,
		viewAsState = getViewAs(state);

	return {
		dashboardMenuCount,
		reportCategories,
		viewAsState,
	};
};

const mapDispatchToProps = {
	getReports,
	clearViewAs
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarLayout);
