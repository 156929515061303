import React, {Component} from 'react';
import {connect} from "react-redux";
import {getTroubleTickets, getTroubleTicketsAccountInfo, getTroubleTicketsServiceLines} from "../../../../../../actions/accountDetailsActions/troubleTickets.actions";
import {createLoadingSelector, getAccountDetails} from "../../../../../../selectors";
import moment from "moment";
import isEmpty from "../../../../../../utils/helpers";
import Notes from "./Notes";
import BeatLoader from "react-spinners/BeatLoader";
import Loader from "../../../../../../components/Loader";
import ServiceWrapper from './ServiceWrapper';
import {generateAddressNew} from "../../../../../../utils/generateAddresses";
import {showModal} from "../../../../../../actions/modal.actions";
import StackedBar from "../../../../../../components/UI/OrdersAndQuotes/StackedBar";
import Tasks from "../OrdersAndQuotes/Orders/Tasks";
import {Link} from "react-router-dom";

export class Ticket extends Component {

	state = {
		isTicketClosed: false,
		isTicketExpanded: false,
		isTasksExpanded: false,
		isNotesExpanded: false,
		isLinesExpanded: false,
		expandedLineId: '',
		currentAccountInfo: {},
		affectedLines: {},
	};

	componentDidMount() {
		const {customerAccountId, customerAccountInfo, troubleTicketId, allServices, status, history} = this.props;

		let name, number, rawAddress, address;

		if (status.processingStatus === "CLOSED") {
			this.setState({isTicketClosed: true})
		}

		if (isEmpty(customerAccountInfo)) {
			this.setState({currentAccountInfo: undefined}, () => {

				this.props.getTroubleTicketsAccountInfo(customerAccountId).then(response => {

					name = response.primaryContact ? response.primaryContact.firstName+' '+response.primaryContact.lastName : '';
					number = response.number;
					rawAddress =
						response.primaryContact &&
						response.primaryContact.contactAddresses &&
						response.primaryContact.contactAddresses.find((x) => x.type === 'BILLING');

					if (rawAddress) {
						address = generateAddressNew(rawAddress);
					}

					this.setState({currentAccountInfo: {name: name, number: number, address: address}});
				});
			});
		}
		else if (!isEmpty(customerAccountInfo)) {

			name = customerAccountInfo.primaryContact.firstName+' '+customerAccountInfo.primaryContact.lastName;
			number = customerAccountInfo.number;
			rawAddress =
				customerAccountInfo.primaryContact &&
				customerAccountInfo.primaryContact.contactAddresses &&
				customerAccountInfo.primaryContact.contactAddresses.find((x) => x.type === 'BILLING');

			if (rawAddress) {
				address = generateAddressNew(rawAddress);
			}
		}

		if (!isEmpty(allServices)) {
			this.generateAffectedLines(allServices);
		}

		// Open ticket if coming here from New Ticket / Task Details
		if (history && history.location && history.location.state && history.location.state.troubleTicketId === troubleTicketId) {

			this.setState({isTasksExpanded: true});

			// Wait for state update
			setTimeout(() => {

				// Scroll service order into view
				document.querySelectorAll('[data-troubleticket-id="'+troubleTicketId+'"]')[0]
					.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});

				// Remove history state so we don't reopen/scroll again
				history.replace({state: undefined});

			}, 100);
		}
	}

	generateAffectedLines = (services) => {
		const {serviceLines} = this.props;

		if (!isEmpty(serviceLines)) {

			let affectedLines = {};

			serviceLines.map(serviceLine => {
				let affectedLineTitle = serviceLine.serviceLineId;

				if (!affectedLines.hasOwnProperty(serviceLine.serviceModelId)) {
					affectedLines[serviceLine.serviceModelId] = {};
				}

				services.map(service => {

					service.serviceLines.filter(item => item.serviceLineId === serviceLine.serviceLineId).map(item => {

						if (!affectedLines[serviceLine.serviceModelId].hasOwnProperty(item.serviceLineId)) {
							affectedLines[serviceLine.serviceModelId][item.serviceLineId] = item;
						}

						if (!isEmpty(item.number)) affectedLineTitle = item.number;
						if (!isEmpty(item.address)) affectedLineTitle = item.address;
						if (!isEmpty(item.number) && !isEmpty(item.address)) affectedLineTitle = item.number + ' - ' + item.address;

						affectedLines[serviceLine.serviceModelId][item.serviceLineId].formattedTitle = affectedLineTitle;
					})
				});

				this.setState({affectedLines: affectedLines});
			});
		}
	}

	handleExpandTicket = () => {
		this.setState((prevState) => ({
			isTicketExpanded: !prevState.isTicketExpanded,
			isTasksExpanded: false,
			isNotesExpanded: false,
			isLinesExpanded: false,
		}));
	};

	handlerServicelinesExpand = () => {
		this.setState((prevState) => ({
			isTicketExpanded: false,
			isTasksExpanded: false,
			isNotesExpanded: false,
			isLinesExpanded: !prevState.isLinesExpanded,
		}));
	};

	handlerNotesExpand = () => {
		this.setState((prevState) => ({
			isTicketExpanded: false,
			isTasksExpanded: false,
			isNotesExpanded: !prevState.isNotesExpanded,
			isLinesExpanded: false,
		}));
	};

	handleExpandTasks = () => {
		this.setState((prevState) => ({
			isTicketExpanded: false,
			isTasksExpanded: !prevState.isTasksExpanded,
			isNotesExpanded: false,
			isLinesExpanded: false,
		}));
	};

	showHideNotes = () => {
		this.setState((prevState) => ({
			isNotesExpanded: !prevState.isNotesExpanded,
		}));
	};

	showHideLines = () => {
		this.setState((prevState) => ({
			isLinesExpanded: !prevState.isLinesExpanded,
		}), () => {

			if (this.state.isLinesExpanded && isEmpty(this.state.affectedLines)) {

				this.props.getTroubleTicketsServiceLines(this.props.customerAccountId).then(response => {
					this.generateAffectedLines(response);
				});
			}
		});
	};

	showHideLineById = (id) => {
		this.setState((prevState) => ({
			expandedLineId: prevState.expandedLineId !== id ? id : '',
		}));
	};

	getAppointmentDateTime = (appointment) => {
		return moment().year(appointment.date.year).dayOfYear(appointment.date.dayOfYear).format("MM/DD/YYYY")
			+ ' @ ' +
			moment().hour(appointment.timeslot.fromTime.hour).minutes(appointment.timeslot.fromTime.minute).format('h:mmA')
			+ '-' +
			moment().hour(appointment.timeslot.toTime.hour).minutes(appointment.timeslot.fromTime.minute).format('h:mmA')
	};

	render() {

		const {
			isTicketClosed,
			isTicketExpanded,
			isTasksExpanded,
			isNotesExpanded,
			isLinesExpanded,
			expandedLineId,
			currentAccountInfo,
			affectedLines,
		} = this.state;

		const {
			ticketAccountInfoLoader,
			ticketAccountInfo,
			troubleTicketId,
			status,
			customerAccountId,
			createdById,
			createdByName,
			createdAt,
			reportedIssueId,
			reportedIssueDescription,
			contactPhoneNumber,
			dueDate,
			latestNote,
			pendingTasks,
			serviceLines,
			serviceIcons,
			serviceInfo,
			allServices,
			accountInfo,
			customerId,
			troubleTicketDetails,
			accountAccessModal,
			customerAccountInfo,
		} = this.props;

		const appointment = serviceInfo.troubleTicketDetails.appointments
			&& serviceInfo.troubleTicketDetails.appointments.length > 0
			&& serviceInfo.troubleTicketDetails.appointments[0];
		
		return (
			<div className="ticket-wrapper" data-troubleticket-id={troubleTicketId}>

				<div className="ticket-header">
					<div className="row">

						<div className="col-12 col-sm-5 col-md-3">
							<div className="ticket-source">CAMVIO-WEB</div>
							<div className="ticket-date">
								<span className="d-block text-muted">
									{troubleTicketId &&
									<span className="d-inline-block nowrap">
										<i className="fas fa-hashtag"/> <span className="font-weight-bold">{troubleTicketId}</span>
									</span>
									}
									{createdByName &&
									<span className="d-inline-block nowrap">
										&nbsp;&nbsp;<i className="fas fa-user"/> {createdByName}
									</span>
									}
								</span>
								{createdAt &&
								<span className="d-block text-muted nowrap">
									<i className="far fa-clock"/> Created: <span className="font-weight-bold">{moment(createdAt).format("MM/DD/YYYY")}</span>
								</span>
								}
							</div>
						</div>

						<div className="col-12 col-sm-7 col-md-5">
							<div className="ticket-details">

								{reportedIssueDescription && serviceLines &&
								<h6>
									{serviceLines && serviceLines.map(serviceLine => serviceIcons.map(serviceIcon =>
										serviceIcon.id === serviceLine.serviceModelId &&
										<i className={serviceIcon.icon} title={serviceIcon.description}/>
									))}
									{reportedIssueDescription}
								</h6>
								}

								{customerAccountId && (currentAccountInfo === undefined
								?
								<p>
									<BeatLoader size={5} color={'#6c757d'}/>
								</p>
								:
								!ticketAccountInfoLoader && !isEmpty(currentAccountInfo) &&
								<p className="ticket-details-name">
									<span>
										{!isEmpty(currentAccountInfo.name) && currentAccountInfo.name}
										{!isEmpty(currentAccountInfo.name) && !isEmpty(currentAccountInfo.number) && " | "}
										{!isEmpty(currentAccountInfo.number) && accountAccessModal && customerAccountId ?
											<button onClick={() => accountAccessModal(customerAccountId)}>
												Acct #: {currentAccountInfo.number}
											</button>
											:
											<span>Acct #: {currentAccountInfo.number}</span>
										}

									</span>
									{currentAccountInfo.address &&
									<span><i className="fas fa-fw fa-file-invoice-dollar"/> {currentAccountInfo.address}</span>
									}
								</p>
								)}

								<p>
									{dueDate && !appointment &&
										<span><i className="far fa-fw fa-calendar-check" /> {moment(dueDate).format("MM/DD/YYYY")}</span>
									}
									{contactPhoneNumber &&
										<span><i className="fas fa-fw fa-phone" /> {contactPhoneNumber}</span>
									}
									{dueDate && appointment &&
										<span><i className="fas fa-fw fa-clock" /> {this.getAppointmentDateTime(appointment)}</span>
									}
								</p>

								{latestNote !== "Could not find any note" &&
								<p><i className="fas fa-fw fa-edit" />  {latestNote}</p>
								}

							</div>
						</div>

						<div className="col-7 col-md-3">
							<div className="ticket-progress">

								<div className="progress">
									<div className="progress-bar bg-success" role="progressbar">
										{status.name}
									</div>
								</div>

								<div className="order-toolbar">
									<div className="btn-group btn-group-sm">

										{isEmpty(customerAccountInfo) && (pendingTasks > 0 && status.name !== "CLOSED") && (
											<a className="btn btn-sm btn-warning" href={"/task-list?troubleTicketId=" + troubleTicketId}>
												<i className="fas fa-tools"/> {pendingTasks} Task{(pendingTasks > 1 ? 's' : '')}
											</a>
										)}

										{!isEmpty(customerAccountInfo) && (pendingTasks > 0 && status.name !== "CLOSED") &&
										<button className="btn btn-sm btn-warning" onClick={this.handleExpandTasks}>
											<i className={isTasksExpanded === true ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'} />
											&nbsp;{pendingTasks} Task{(pendingTasks > 1 ? 's' : '')}
										</button>
										}

										<button
											className="btn btn-outline-secondary"
											onClick={this.handlerNotesExpand}
										>
											<i className="fas fa-file-signature"/>
										</button>

										<button
											onClick={this.handlerServicelinesExpand}
											className="btn btn-outline-secondary btn-order-substep-collapse-toggle"
										>
											<i className="fas fa-list-ul"/>
										</button>

									</div>
								</div>

								<span className="ticket-modified text-muted">
									{!isEmpty(troubleTicketDetails.modifiedDateTime) && (
										moment(troubleTicketDetails.modifiedDateTime).format('MM/DD/YYYY @ h:mmA')
									)}
									{!isEmpty(troubleTicketDetails.modifiedBy) && (
										<span><i className="fas fa-user" />{troubleTicketDetails.modifiedBy}</span>
									)}
								</span>

							</div>
						</div>

						<div className="col-5 col-md-1">
							<div className="ticket-controls">

								<button
									className={"btn btn-circle" + (appointment ? " active" : "")}
									disabled={isTicketClosed}
									onClick={() =>
										this.props.showModal('TECHNICIAN_APPOINTMENT_MODAL', {
											appointment_type: "TROUBLE_TICKET",
											id: serviceInfo.troubleTicketDetails.troubleTicketId,
											onCloseCallback: this.props.appointmentModalCallback
										})}
								>
									<i className="far fa-clock"/>
								</button>

								{!isEmpty(status) &&
								<button
										className="btn btn-circle"
										onClick={this.handleExpandTicket}
									>
										<i className={"fas" + (isTicketExpanded ? " fa-angle-double-up" : " fa-angle-double-down")}/>
									</button>
								}

							</div>
						</div>

					</div>
				</div>

				{isTasksExpanded &&
				<Tasks
					accountId={customerAccountId}
					serviceInfo={serviceInfo}
					troubleTicketId={troubleTicketId}
					history={this.props.history}
				/>
				}

				{isTicketExpanded &&
				<StackedBar statuses={serviceInfo.troubleTicketDetails.workflowStatusChain} isStarted={true} />
				}

				{isNotesExpanded &&
				<Notes troubleTicketId={troubleTicketId} />
				}

				{isLinesExpanded &&
				<div>
					{isEmpty(affectedLines)
						?
						<Loader/>
						:
						Object.keys(affectedLines).map(serviceModelId => Object.keys(affectedLines[serviceModelId]).map(serviceLineId =>
							<ServiceWrapper
								serviceLine={affectedLines[serviceModelId][serviceLineId]}
								showHideLineById={this.showHideLineById}
								expandedLineId={expandedLineId}
							/>
						))}
				</div>
				}

			</div>
		);
	}
}

const getLoaderTroubleTicketAccountInfo = createLoadingSelector(['GET_TROUBLE_TICKET_ACCOUNT_INFO']);

const mapStateToProps = (state) => {
	const ticketAccountInfoLoader = getLoaderTroubleTicketAccountInfo(state);
	const ticketAccountInfo = getAccountDetails(state).troubleTickets.troubleTicketsAccountInfo;

	return {
		ticketAccountInfoLoader,
		ticketAccountInfo,
	};
};

const mapDispatchToProps = {
	getTroubleTickets,
	getTroubleTicketsAccountInfo,
	getTroubleTicketsServiceLines,
	showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
