export const notificationTypeIcons = {
  EMAIL: "fas fa-envelope",
  TWILIOMSG: "fas fa-comment",
  SMS: "fas fa-comment",
  SLACK: "fab fa-slack",
};

export const notificationTypeOptions = [
  // {
  //   name: "type",
  //   value: "SMS",
  //   label: "SMS",
  //   useHtml: false,
  // },
  {
    name: "type",
    value: "EMAIL",
    label: "Email",
    useHtml: true,
  },
  // {
  //   name: "type",
  //   value: "APNS",
  //   label: "APNS",
  //   useHtml: false,
  // },
  {
    name: "type",
    value: "TWILIOMSG",
    label: "Twilio Message",
    useHtml: false,
  },
  // {
  //   name: "type",
  //   value: "SLACK",
  //   label: "Slack",
  //   useHtml: false,
  // },
];
