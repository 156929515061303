import React, { Component } from 'react';
import TaskForm from './TaskForm';
import Loader from '../../components/Loader';
import AlertBox from '../../components/UI/AlertBox';
import { connect } from 'react-redux';
import {
	getTaskAttributes,
	emptyTaskAttributes,
	setUserTask,
	getTaskDetailsAccountInfo,
	emptyTaskDetailsAccountInfo,
	getTaskDetailsServiceOrderInfo,
	emptyTaskDetailsServiceOrderInfo,
	emptyServiceOrderTasks
} from '../../actions/taskDetails.actions';
import {getTaskDetails, createLoadingSelector, getUser, getAccountDetails} from '../../selectors/index';
import { isEmpty } from '../../utils/helpers';
import AccountInfoBox from '../../components/UI/AccountInfoBox';
import TroubleTicketInfoBox from '../../components/UI/TroubleTicketInfoBox';
import ServiceOrder from '../Dashboard/Content/Orders/ServiceOrder';
import {getTroubleTickets} from "src/actions/accountDetailsActions/troubleTickets.actions";

class TaskDetails extends Component {

	state = {
		tasksIsOver: false,
		isAlertBoxShowing: true
	};

	componentDidMount() {

		// Change navbar type and color
		document.title = 'Task Details - camvio.cloud';
		this.props.changeNavBarType('tasks', 'taskDetails', 'Task Details');

		const taskId = Number(this.props.match.params.id);
		this.props.getTaskAttributes(taskId);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.taskAttributesData !== this.props.taskAttributesData) {
			const { taskAttributesData } = this.props;

			if (taskAttributesData.serviceOrderInfo) {
				if (taskAttributesData.serviceOrderInfo.accountId) {
					this.props.getTaskDetailsAccountInfo(taskAttributesData.serviceOrderInfo.accountId);
				}

				if (taskAttributesData.serviceOrderInfo.serviceOrderId) {
					this.props.getTaskDetailsServiceOrderInfo(taskAttributesData.serviceOrderInfo.serviceOrderId,
						this.props.user.currentLocation.id);
				}
			}

			if (taskAttributesData.accountDetails) {
				if (taskAttributesData.accountDetails.accountId) {
					this.props.getTaskDetailsAccountInfo(taskAttributesData.accountDetails.accountId);
				}
			}

			if(taskAttributesData?.troubleTicketDetails) {
				this.props.getTroubleTickets({accountId: this.props.accountInfo.id , troubleTicketId: this.props.taskAttributesData?.troubleTicketDetails?.id})
			}
		}
		/**
		 * If we receive a new taskId we update the URL
		 */
		if(prevProps?.taskAttributesData?.id && this.props?.taskAttributesData?.id) {
			if(prevProps?.taskAttributesData?.id !== this.props?.taskAttributesData?.id) {
				let historyState = this.props.history.location.state
				this.props.history.replace({pathname: `/task-details/${this.props?.taskAttributesData?.id}`, state: historyState})
			}
		}

		if (prevProps.serviceOrderTasks !== this.props.serviceOrderTasks) {
			if (!isEmpty(this.props.serviceOrderTasks)) {
				let firstOrderTaskId = this.props.serviceOrderTasks[0].id;
				this.props.getTaskAttributes(firstOrderTaskId);
			} else {
				if (this.props.taskAttributesData.serviceOrderInfo) {
					this.props.getTaskDetailsServiceOrderInfo(
						this.props.taskAttributesData.serviceOrderInfo.serviceOrderId
					);
				}
				this.setState({
					tasksIsOver: true
				});
				this.props.emptyTaskAttributes();
			}
		}
	}

	componentWillUnmount() {
		this.props.emptyTaskDetailsAccountInfo();
		this.props.emptyTaskDetailsServiceOrderInfo();
		this.props.emptyServiceOrderTasks()
	}

	handleShowAlert = () => {
		this.setState({
			isAlertBoxShowing: false
		});
	};

	handleServiceInfo = (serviceInfo) => {
		if(this.props.accountInfo.serviceOrderAddressesList &&
			this.props.accountInfo.serviceOrderAddressesList.length > 0) {
			let serviceOrderAddress = this.props.accountInfo.serviceOrderAddressesList[0];
			serviceInfo["address1"] = serviceOrderAddress.address1;
			serviceInfo["address2"] = serviceOrderAddress.address2;
			serviceInfo["address3"] = serviceOrderAddress.address3;
			serviceInfo["city"] = serviceOrderAddress.city;
			serviceInfo["latitude"] = serviceOrderAddress.latitude;
			serviceInfo["longitude"] = serviceOrderAddress.longitude;
			serviceInfo["mappingAddressId"] = serviceOrderAddress.mappingAddressId;
			serviceInfo["mappingAreaId"] = serviceOrderAddress.mappingAreaId;
			serviceInfo["mappingRefArea1"] = serviceOrderAddress.mappingRefArea1;
			serviceInfo["mappingNote"] = serviceOrderAddress.mappingNote;
			serviceInfo["state"] = serviceOrderAddress.state;
			serviceInfo["zipcode"] = serviceOrderAddress.zipcode;
		}
		return serviceInfo;
	};

	render() {
		const { isAlertBoxShowing } = this.state;
		const { taskAttributesData, taskLoader, accountInfo, serviceorderInfo } = this.props;

		if(isEmpty(accountInfo)){
			return <Loader />
		}

		return (
			<div className="cmv-container-client-selected cmv-container-client-selected-modern cmv-container-client-selected-task-details">

				{isAlertBoxShowing &&
				accountInfo.alert &&
				accountInfo.type !== 'NONE' &&
				accountInfo.alert.message && (
				<AlertBox
					handleShowAlert={this.handleShowAlert}
					message={accountInfo.alert.message}
				/>
				)}

				<AccountInfoBox
					accountInfo={accountInfo}
					hideCardsContainer={false}
					hideCardsTaskDetails={true}
				/>
				
				{taskAttributesData.troubleTicketDetails &&
				<TroubleTicketInfoBox
					accountInfo={accountInfo}
					troubleTicketInfo={taskAttributesData.troubleTicketDetails}
					hideCardsContainer={false}
					hideCardsTaskDetails={true}
					accountId={accountInfo.id}
					troubleTicketDetails={this.props.troubleTicketDetails?.troubleTickets[0]?.troubleTicketDetails}
				/>
				}

				{!isEmpty(serviceorderInfo) && (
				<ServiceOrder
					accountId={accountInfo.id}
					serviceInfo={this.handleServiceInfo(serviceorderInfo)}
					serviceOrderType="taskDetails"
				/>
				)}

				<TaskForm
					formAtrributes={taskAttributesData}
					emptyTaskAttributes={this.props.emptyTaskAttributes}
					taskId={this.props.match.params.id}
					serviceOrderId={taskAttributesData.serviceOrderInfo && taskAttributesData.serviceOrderInfo.serviceOrderId}
					setUserTask={this.props.setUserTask}
					tasksIsOver={this.state.tasksIsOver}
					processInstanceId={taskAttributesData.processInstanceId}
					{...this.props}
				/>

			</div>
		);
	}
}

const taskAttributesLoader = createLoadingSelector([ 'SET_USER_TASK_ATTRIBUTES' ]);

const mapStateToProps = (state) => {
	const taskAttributesData = getTaskDetails(state).taskAttributes,
		serviceOrderTasks = getTaskDetails(state).serviceOrderTasks,
		taskLoader = taskAttributesLoader(state),
		accountInfo = getTaskDetails(state).accountInfo,
		serviceorderInfo = getTaskDetails(state).serviceorderInfo,
		troubleTicketDetails = getAccountDetails(state).troubleTickets,
		user = getUser(state);

	return {
		taskAttributesData,
		taskLoader,
		serviceOrderTasks,
		accountInfo,
		serviceorderInfo,
		troubleTicketDetails,
		user
	};
};

const mapDispatchToProps = {
	getTaskAttributes,
	getTaskDetailsAccountInfo,
	getTaskDetailsServiceOrderInfo,
	emptyTaskAttributes,
	emptyTaskDetailsAccountInfo,
	emptyTaskDetailsServiceOrderInfo,
	setUserTask,
	emptyServiceOrderTasks,
	getTroubleTickets
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
