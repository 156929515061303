import {
	PRESERVE_SALES_ORDER_NUM,
	LIST_ACCOUNT_SALES_ORDERS,
} from 'src/actions/accountDetailsActions/salesOrders.actions';

let initialState = {
	salesOrders: [],
};

export const salesOrders = (state = initialState, action) => {
	switch (action.type) {
		case PRESERVE_SALES_ORDER_NUM.SUCCESS:
			return {
				...state,
				nextNumber: action.payload
			};
		case PRESERVE_SALES_ORDER_NUM.CLEAR:
			return {
				...state,
				nextNumber: undefined
			};
		case LIST_ACCOUNT_SALES_ORDERS.CLEAR:
			return {
				...state,
				salesOrders: []
			};
		case LIST_ACCOUNT_SALES_ORDERS.SUCCESS:
			return {
				...state,
				salesOrders: action.payload
			};
		default:
			return state;
	}
};
