import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { hideModal } from "src/actions/modal.actions";
import {getInventoryLocations, updateSerializedItemStatus} from "src/actions/inventory.actions";
import {getInventory, getModalData, getUser} from "src/selectors";
import Select from "react-select";
import {groupStyles} from "src/utils/SelectStyles";
import {isEmpty} from "lodash";

class BulkEditInventorySerializedModal extends Component {

    state = {
        updateLocationToggled: false,
        updateStatusToggled: false,
        isUpdating: false,
        percentageComplete: 0,
        itemsCompleted: 0,
        alertMessage: ""
    }

    componentDidMount() {
        this.props.getInventoryLocations()
    }

    changeHandler = (e, type, setFieldValue) => {
        setFieldValue(type, e)
    }

    formSubmit = (values, actions) => {
        const {modalProps, updateSerializedItemStatus, hideModal} = this.props
        this.setState({isUpdating: true})
        let itemsCompleted = 0
        modalProps.bulkSelectedItems.map((itemId, index) => {
            updateSerializedItemStatus(itemId, {locationId: values.location.value, status: values.status.value}).then((response) => {
                if (!response.data) {
                    actions.setSubmitting(false);
                    this.setState({
                        alertMessage: "Unknown error. Please try again later",
                    });
                    return;
                }

                if (!response.data.success) {
                    actions.setSubmitting(false);
                    this.setState({ alertMessage: response.data.error.message });
                    return;
                }
                
                itemsCompleted += 1
                this.setState({percentageComplete: ((itemsCompleted)/modalProps.bulkSelectedItems.length).toFixed(2) * 100, itemsCompleted: `${itemsCompleted}/${modalProps.bulkSelectedItems.length}`})
            })
        })
    }

    render() {
        const {updateLocationToggled, updateStatusToggled, isUpdating, percentageComplete, alertMessage, itemsCompleted} = this.state
        const {modalProps, hideModal} = this.props
       return (
           <Fragment>
               <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
                       <Formik
                           initialValues={{
                               location: "",
                               status: "",
                           }}
                           onSubmit={this.formSubmit}
                       >
                           {({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
                               <form onSubmit={handleSubmit}>
                                   <div className="modal-dialog">
                                       <div className="modal-content">
                                           <div className="modal-header">
                                               <h5 className="modal-title">Bulk Update Inventory</h5>
                                               <button onClick={() => hideModal()} type="button" className="close">
                                                   <span aria-hidden="true">&times;</span>
                                               </button>
                                           </div>
                                           <div className="modal-body cmv-form form-horizontal">
                                               {!isEmpty(alertMessage) && (
                                                   <div className="alert alert-inline alert-danger alert-dismissible">
                                                       <p className="mb-0">{alertMessage}</p>
                                                   </div>
                                               )}
                                               <p>{modalProps.bulkSelectedItems.length} rows selected</p>
                                               <div>
                                                   <span className="h-check" style={{display:'flex'}}>
                                                    <label>Update Location&nbsp;</label>
                                                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                        <label>
                                                            <input
                                                                name="location"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={() => {
                                                                    this.setState((prevState) => ({updateLocationToggled: !prevState.updateLocationToggled}), () => {
                                                                        if(updateLocationToggled) setFieldValue("location", "")
                                                                    })
                                                                }}
                                                                checked={updateLocationToggled}
                                                            />
                                                            <span>&nbsp;</span>
                                                        </label>
                                                    </div>
                                                    </span>
                                                   {updateLocationToggled &&
                                                       <div className="form-group">
                                                           <Select
                                                               id="location"
                                                               name="location"
                                                               placeholder="Select New Location"
                                                               options={this.props.locations.map((location) => ({label: location.name, value: location.id, name: location.name}))}
                                                               value={values.location}
                                                               isClearable={false}
                                                               onChange={(e) => this.changeHandler(e, "location", setFieldValue)}
                                                               // isLoading={}
                                                               isDisabled={isSubmitting}
                                                               styles={groupStyles}
                                                           />
                                                           {/*{touched.accountIds && errors.accountIds &&*/}
                                                           {/*    <div className="invalid-feedback">At least one account must be selected</div>*/}
                                                           {/*}*/}
                                                       </div>
                                                   }

                                                   <span className="h-check" style={{display:'flex'}}>
                                                    <label>Update Status&nbsp;</label>
                                                    <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                        <label>
                                                            <input
                                                                name="allAccounts"
                                                                type="checkbox"
                                                                onBlur={handleBlur}
                                                                onChange={() => {
                                                                    this.setState((prevState) => ({updateStatusToggled: !prevState.updateStatusToggled}), () => {
                                                                        if(updateStatusToggled) setFieldValue("status", "")
                                                                    })
                                                                }}
                                                                checked={updateStatusToggled}
                                                            />
                                                            <span>&nbsp;</span>
                                                        </label>
                                                    </div>
                                                    </span>
                                                   {updateStatusToggled &&
                                                       <div className="form-group">
                                                           <Select
                                                               id="status"
                                                               name="status"
                                                               placeholder="Select New Status"
                                                               options={this.props.statuses.map((status) => ({label: status, name: status, value: status}))}
                                                               value={values.status}
                                                               onChange={(e) => this.changeHandler(e, "status", setFieldValue)}
                                                               isClearable={false}
                                                               isDisabled={isSubmitting}
                                                               styles={groupStyles}
                                                           />
                                                       </div>
                                                   }
                                               </div>
                                               {isUpdating &&
                                                   <>
                                                   <span>{percentageComplete === 100 ? "Update Complete" : "Updating"}</span>
                                                   <div className="progress">
                                                       <div
                                                       className="progress-bar bg-success-with-pulse"
                                                       role="progressbar"
                                                       style={{width: `${percentageComplete}%`}}
                                                       aria-valuenow="100"
                                                       aria-valuemin="0"
                                                       aria-valuemax="100"
                                                       >
                                                           <span style={{whiteSpace: "nowrap"}}>
                                                                {itemsCompleted}
                                                           </span>
                                                       </div>
                                                   </div>
                                                   </>
                                               }
                                           </div>
                                           <div className="modal-footer">
                                               {isUpdating ?
                                                   <button onClick={() => {
                                                       modalProps.clearAndReloadList()
                                                       hideModal()
                                                   }} className="btn btn-primary">
                                                       Close
                                                   </button>
                                                   :
                                                   <button disabled={isSubmitting || (!values.location && !values.status)} type="submit" className="btn btn-primary">
                                                       Start
                                                   </button>
                                               }
                                           </div>
                                       </div>
                                   </div>
                               </form>
                           )}
                       </Formik>
               </div>
               <div className="modal-backdrop show" tabIndex="1" />
           </Fragment>
       )
   }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        statuses = getInventory(state).statuses,
        locations = getInventory(state).locations;

    return {
        modalProps,
        statuses,
        locations,
    };
};

const mapDispatchToProps = {
    hideModal,
    updateSerializedItemStatus,
    getInventoryLocations
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkEditInventorySerializedModal);