import {
    MAPPING_SYSTEM_FIND_PROVIDER_BY_ID, MAPPING_SYSTEM_ALL_PROVIDERS , MAPPING_SYSTEM_ALL_PROVIDERS_IDS, MAPPING_SYSTEM_CURRENT_ID,
    WORKFORCE_FIND_PROVIDER_BY_ID , WORKFORCE_FIND_CURRENT_PROVIDER_ID, WORKFORCE_ALL_PROVIDERS_IDS, WORKFORCE_FIND_ALL_PROVIDERS,
} from "../actions/workforce.actions";

const initialState = {
    currentMappingProviderId: undefined,
    mappingProviderIds: undefined,
    mappingProviders: undefined,
    currentWorkforceProviderId: undefined,
    workforceProviderIds: undefined,
    workforceProviders: undefined,
};

export const workforceReducer = (state = initialState, action) => {
    switch (action.type) {
        case MAPPING_SYSTEM_CURRENT_ID.SUCCESS:
            return {
                ...state,
                currentMappingProviderId: action.payload
            };
        case MAPPING_SYSTEM_CURRENT_ID.EMPTY:
            return {
                ...state,
                currentMappingProviderId: undefined
            };
        case MAPPING_SYSTEM_ALL_PROVIDERS_IDS.SUCCESS:
            return {
                ...state,
                mappingProviderIds: action.payload
            };
        case MAPPING_SYSTEM_ALL_PROVIDERS_IDS.EMPTY:
            return {
                ...state,
                mappingProviderIds: undefined
            };
        case MAPPING_SYSTEM_FIND_PROVIDER_BY_ID.SUCCESS:
            return {
                ...state,
                mappingProviders: {
                    ...state.mappingProviders,
                    [action.payload.id]: action.payload
                }
            };
        case MAPPING_SYSTEM_FIND_PROVIDER_BY_ID.EMPTY:
            if (!state.mappingProviders) {
                return state;
            }

            const mappingProviders = { ...state.mappingProviders };
            delete mappingProviders[action.payload];

            return {
                ...state,
                mappingProviders
            };
        case MAPPING_SYSTEM_ALL_PROVIDERS.EMPTY:
            return {
                ...state,
                mappingProviders: undefined
            }


            // Workforce


        case WORKFORCE_FIND_CURRENT_PROVIDER_ID.SUCCESS:
            return {
                ...state,
                currentWorkforceProviderId: action.payload
            };
        case WORKFORCE_FIND_CURRENT_PROVIDER_ID.EMPTY:
            return {
                ...state,
                currentWorkforceProviderId: undefined
            };
        case WORKFORCE_ALL_PROVIDERS_IDS.SUCCESS:
            return {
                ...state,
                workforceProviderIds: action.payload
            };
        case WORKFORCE_ALL_PROVIDERS_IDS.EMPTY:
            return {
                ...state,
                workforceProviderIds: undefined
            };
        case WORKFORCE_FIND_PROVIDER_BY_ID.SUCCESS:
            return {
                ...state,
                workforceProviders: {
                    ...state.workforceProviders,
                    [action.payload.id]: action.payload
                }
            };
        case WORKFORCE_FIND_PROVIDER_BY_ID.EMPTY:
            if (!state.workforceProviders) {
                return state;
            }

            const workforceProviders = { ...state.workforceProviders };
            delete workforceProviders[action.payload];

            return {
                ...state,
                workforceProviders
            };
        case WORKFORCE_FIND_ALL_PROVIDERS.EMPTY:
            return {
                ...state,
                workforceProviders: undefined
            }


        default:
            return state;
    }
};
