import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {createLoadingSelector, getAccountDetails, getAdmin, getDashboardData} from '../../../../../../selectors';
import {getBalancesInfo} from "../../../../../../actions/accountDetailsActions/billing.actions";
import {getMyAutoPayInfo, emptyMyAutoPayInfo} from "../../../../../../actions/accountDetailsActions/accountDetails.actions";
import {getAccountDistributionMethod, getAccountTypeDistributionMethods} from "../../../../../../actions/admin.actions";
import {getPrimaryContacts} from "../../../../../../actions/admin.actions";
import {getAllContacts} from "../../../../../../actions/accountDetailsActions/security.actions";
import {getAdjustments} from '../../../../../../actions/accountDetailsActions/billing.actions';
import {getFilteredTasks} from '../../../../../../actions/dashboardActions/tasks.actions';
import {getFilteredOrders} from '../../../../../../actions/dashboardActions/orders.actions';
import {getTroubleTickets} from '../../../../../../actions/accountDetailsActions/troubleTickets.actions';
import {getAccount360Services} from '../../../../../../actions/accountDetailsActions/account360.actions';
import {checkInvoicesCenter, checkInvoicesChart} from '../Billing/Chart/utils';
import Loader from "../../../../../../components/Loader";
import Tile from "./Tile";
import {Chart} from 'react-chartjs-2';
import GenericChart from '../Billing/Chart/GenericChart';
import Service from "../Account360/Service";
import isEmpty from "../../../../../../utils/helpers";
import {statusColor} from "../../../../../../utils/ui";
import moment from "moment";

Chart.pluginService.register({
	beforeDraw: function(chart) {
		if (chart.config.options.elements.center) {
			//Get ctx from string
			var ctx = chart.chart.ctx;

			//Get options from the center object in options
			var centerConfig = chart.config.options.elements.center;
			var fontStyle = centerConfig.fontStyle || 'Fira Sans';
			var txt = centerConfig.text;
			// var txtSubtitle = centerConfig.textSubtitle;
			var color = centerConfig.color || '#000';
			var sidePadding = centerConfig.sidePadding || 20;
			var sidePaddingCalculated = sidePadding / 100 * (chart.innerRadius * 2);
			//Start with a base font of 30px
			ctx.font = '24px ' + fontStyle;

			//Get the width of the string and also the width of the element minus 10 to give it 5px side padding
			var stringWidth = ctx.measureText(txt).width;
			var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

			// Find out how much the font can grow in width.
			var widthRatio = elementWidth / stringWidth;
			var newFontSize = Math.floor(30 * widthRatio);
			var elementHeight = chart.innerRadius * 2;

			// Pick a new font size so it will not be larger than the height of label.
			var fontSizeToUse = Math.min(newFontSize, elementHeight);

			//Set font settings to draw it correctly.
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
			var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
			// ctx.font = fontSizeToUse+"px " + fontStyle;
			ctx.font = fontStyle;
			ctx.fillStyle = color;

			//Draw text in center
			ctx.fillText(txt, centerX, centerY);
			// ctx.fillText(txtSubtitle, centerX, centerY + 25);
		}
	}
});

class Account360 extends Component {

	state = {
		isLoaded: false,
		customerPortalStatus: 0,
		charges: {},
		showAllCharges: false,
		showAllTasks: false,
		showAllOrders: false,
		showAllTickets: false,
		isPaperlessActive: false,
		myAutoPayInfoLoaded: false,
		myDistributionMethodsLoaded: false,
		customerPortalStatusLoaded: false,
	};


	componentDidMount() {
		const { accountInfo } = this.props;

		// Load balances
		this.props.getBalancesInfo(accountInfo.id);

		// Load Paperless
		this.props.getAccountDistributionMethod(accountInfo.id);
		this.checkIsPaperlessActive();


		this.props.getAccountTypeDistributionMethods(accountInfo.accountType.name).then(response => {
			if (response.status === 200) {
				this.setState({myDistributionMethodsLoaded: true})
			}
		});

		// Load autopay
		this.props.getMyAutoPayInfo(accountInfo.id);
		this.checkIsAutoPayActive();


		// Load customer portal
		this.props.getPrimaryContacts(accountInfo.id).then((response) => {
			this.setCustomerPortalColor(response.data.contacts[0].userAccess);
		});
		this.loadCustomerPortalStatus();


		// Load contacts
		this.props.getAllContacts(accountInfo.id);

		// Load pending charges
		this.props.getAdjustments(accountInfo.id).then(response => {

			if (response && response.success) {

				let charges = {};

				response.pendingAdjustments.map(adjustment => {

					if (!charges.hasOwnProperty(adjustment.itemDescription)) {
						charges[adjustment.itemDescription] = adjustment.amount;
					}
					else {
						charges[adjustment.itemDescription] = charges[adjustment.itemDescription] + adjustment.amount;
					}
				});

				this.setState({charges: charges})
			}
		});



		// Load tasks
		this.props.getFilteredTasks({accountNumber: accountInfo.id, complete: false, limit: 10});

		// Load orders
		this.props.getFilteredOrders({accountNumber: accountInfo.number, limit: 10, incomplete: true});

		// Load tickets
		this.props.getTroubleTickets({accountId: accountInfo.id, incomplete: true,  completed: false});

		// Load services
		this.props.getAccount360Services(accountInfo.id);

	}

	checkIsAutoPayActive = () => {
		this.setState({myAutoPayInfoLoaded: false});
		this.props.getMyAutoPayInfo(this.props.accountInfo.id).then(response =>
			this.setState({myAutoPayInfoLoaded: true})
		);
	}

	checkIsPaperlessActive = () => {
		this.props.getAccountDistributionMethod(this.props.accountInfo.id).then(response => {

			this.setState({
				isPaperlessActive: response.data.distributionMethods[0]
					? response.data.distributionMethods[0].name !== 'paper'
					: false
			});
		});
	}

	setCustomerPortalColor = (userAccess) => {

		// Secondary - gray
		let color = 0;

		if (!isEmpty(userAccess)) {
			let customerPortalActive = userAccess.isActive;
			let loginAttempts = userAccess.invalidLoginAttempts;

			if (customerPortalActive) {
				// Success - green
				color = 4;
			}
			else {
				if (loginAttempts > 1) {
					// Danger - red
					color = 1;
				}
				else {
					// Warning - yellow
					color = 3;
				}
			}
		}

		this.setState({
			customerPortalStatus: color,
			customerPortalStatusLoaded: true
		})
	}

	toggleShowAll = (tile) => {
		this.setState({
			showAllCharges: tile === 'charges' ? !this.state.showAllCharges : this.state.showAllCharges,
			showAllTasks: tile === 'tasks' ? !this.state.showAllTasks : this.state.showAllTasks,
			showAllOrders: tile === 'orders' ? !this.state.showAllOrders : this.state.showAllOrders,
			showAllTickets: tile === 'tickets' ? !this.state.showAllTickets : this.state.showAllTickets,
		});
	}

	balancesAction = () => {
		this.props.changeTabHandler('billing');
	}

	billingAction = () => {
		this.props.handleSwitchTabInOtherPanel('security');
	}

	chargesAction = (e) => {
		if (e.target.classList.contains("btn") === false) {
			this.props.history.replace({state: {billingTabActiveItem: 'adjustments'}})
			this.props.changeTabHandler('billing');
		}
	}

	tasksAction = (e) => {
		if (e.target.classList.contains("btn") === false) {
			this.props.changeTabHandler('ordersAndQuotes');
		}
	}

	ordersAction = (e) => {
		if (e.target.classList.contains("btn") === false) {
			this.props.changeTabHandler('ordersAndQuotes');
		}
	}

	troublesAction = (e) => {
		if (e.target.classList.contains("btn") === false) {
			this.props.changeTabHandler('troubleTickets');
		}
	}

	servicesAction = (e) => {
		if (e.target.classList.contains("btn") === false) {
			this.props.changeTabHandler('serviceAndEquipment');
		}
	}

	loadCustomerPortalStatus = () => {
		this.setState({
			customerPortalStatus: 0,
			customerPortalStatusLoaded: false,
		});

		this.props.getPrimaryContacts(this.props.accountInfo.id).then((response) => {
			this.setCustomerPortalColor(response.data.contacts[0].userAccess);
		});
	}

	render() {

		const {
			customerPortalStatus,
			showAllCharges,
			showAllTasks,
			showAllOrders,
			showAllTickets,
			isPaperlessActive,
			myDistributionMethodsLoaded,
			myAutoPayInfoLoaded,
			customerPortalStatusLoaded
		} = this.state;

		const {
			accountInfo,
			loadingBalances, balances,
			loadingPaperless, paperless,
			loadingAutopay, autopay,
			loadingContacts, contacts,
			loadingCharges, charges,
			loadingTasks, tasks,
			loadingOrders, orders,
			loadingTickets, tickets,
			loadingServices, services,
			myDistributionMethods,
			primaryContacts
		} = this.props;

		return (
			<div className="tab-pane tab-pane-full-size card-body fade show active">
				<div className="account360">

					<Tile
						title={"Unpaid Invoices"}
						loading={loadingBalances}
						action={this.balancesAction}
						{...this.props}
					>
						{!isEmpty(balances) &&
							<GenericChart
								info={{
									labels: [ 'Default', 'Current', '30-59 Days Old', '60-89 Days Old', '90-119 Days Old', '120+ Days Old' ],
									data: checkInvoicesChart(balances.invoicesAging),
									backgroundColor: [ '#ccc', '#95ba55', '#f6e954', '#e5b93b', '#bf433d', '#564484' ],
									center: checkInvoicesCenter(balances.invoicesAging),
									postPayment: balances.postPaymentAmount
								}}
							/>
						}
					</Tile>

					<Tile
						title={"Billing & Contacts"}
						loading={loadingPaperless || loadingAutopay || loadingContacts}
						action={this.billingAction}
						{...this.props}
					>

						<div className="row text-center">

							<div className="col">
								<button
									className={"btn btn-icon" + (isPaperlessActive ? " btn-icon-success" : "")}
									onClick={() => this.props.showModal('PAPERLESS_MODAL', {
											myDistributionMethods,
											onCloseCallback: () => this.checkIsPaperlessActive()
										}
									)}
									title="Paperless"
									type="button"
									disabled={!myDistributionMethodsLoaded}
									style={!myDistributionMethodsLoaded ? {cursor: "progress"} : {}}
								>
									{myDistributionMethodsLoaded
										?
										<i className="fab fa-fw fa-envira" />
										:
										<i className="fas fa-fw fa-spin fa-spinner"/>
									}
								</button>
							</div>

							<div className="col">
								<button className={"btn btn-icon" +
									((this.props.myAutoPayInfo && this.props.myAutoPayInfo.length !== 0)
											? " btn-icon-success" : ""
									)}
										onClick={() => {
											this.props.emptyMyAutoPayInfo();
											this.props.showModal('AUTOPAY_MODAL', {onCloseCallback: () => {this.checkIsAutoPayActive()}})
										}}
										title="AutoPay"
										type="button"
										disabled={!myAutoPayInfoLoaded}
										style={!myAutoPayInfoLoaded ? {cursor: "progress"} : {}}
								>
									{myAutoPayInfoLoaded
										?
										<span className="fa-stack fa-1x">
								<i className="fas fa-fw fa-sync-alt fa-stack-2x" />
								<i className="fas fa-fw fa-dollar-sign fa-stack-1x" />
							</span>
										:
										<i className="fas fa-fw fa-spin fa-spinner"/>
									}
								</button>
							</div>

							<div className="col">
								<button
									className={`btn btn-customer-portal btn-icon btn-icon-${statusColor(this.state.customerPortalStatus)}`}
									onClick={() => {
										this.props.showModal('CUSTOMER_PORTAL_MODAL', {
											primaryContact: primaryContacts[0],
											onCloseCallback: () => {this.loadCustomerPortalStatus()}
										})
									}}
									title="Customer Portal"
									type="button"
									disabled={!customerPortalStatusLoaded}
									style={!customerPortalStatusLoaded ? {cursor: "progress"} : {}}
								>
									{customerPortalStatusLoaded
										?
										<span className="fa-stack">
								<i className="fas fa-user fa-stack-2x" />
								<i className="fas fa-desktop fa-stack-1x" />
							</span>
										:
										<i className="fas fa-fw fa-spin fa-spinner"/>
									}
								</button>
							</div>

						</div>

						<hr/>

						{contacts && <>

							<div className="row">
								<div className="col-sm-4">
									<h6>{accountInfo.primaryContact.firstName} {accountInfo.primaryContact.lastName} (Primary)</h6>
								</div>
								<div className="col-sm-8">
									{!isEmpty(accountInfo.primaryContact.contactNumbers)
										? accountInfo.primaryContact.contactNumbers.map((number, i) => <p key={`apcn_${i}`}>{number.numberType}: {number.number}</p>)
										: <p>No numbers found.</p>
									}
								</div>
							</div>

							<hr/>

							{contacts.filter(contact => contact.id !== accountInfo.primaryContact.id && contact.authorized).map(contact => <>
									<div className="row">
										<div className="col-sm-4">
											<h6>{contact.firstName} {contact.lastName} {contact.authorized ? "Authorized" : ""}</h6>
										</div>
										<div className="col-sm-8">
											{!isEmpty(contact.numbers)
												? contact.numbers.map(number => <p>{number.numberType}: {number.number}</p>)
												: <p>No numbers found.</p>
											}
										</div>
									</div>
									<hr/>
								</>
							)}

						</>}

						{accountInfo.primaryContact && accountInfo.primaryContact.company
							?
							<div className="row">
								<div className="col-sm-4"><h6>Company</h6></div>
								<div className="col-sm-8">{accountInfo.primaryContact.company}</div>
							</div>
							:
							<div className="row">
								<div className="col-sm-4"><h6>Name</h6></div>
								<div className="col-sm-8">{accountInfo.primaryContact.firstName} {accountInfo.primaryContact.lastName}</div>
							</div>
						}

					</Tile>

					<Tile
						title={"Pending Charges & Credits"}
						loading={loadingCharges}
						action={e => this.chargesAction(e)}
						{...this.props}
					>
						{!isEmpty(charges)
							?
							/*Object.keys(charges).map(adjustment =>
                            <div className="row">
                                <div className="col-sm-8">
                                    <h6>{adjustment}</h6>
                                </div>
                                <div className="col-sm-4 text-right">
                                    ${charges[adjustment].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                </div>
                            </div>*/
							<>

								{charges.map((charge, index) => (showAllCharges || (!showAllCharges && index < 5)) &&
									<div className="line-top">
										<div className="row">
											<div className="col-sm-8">
												<h6>{charge.itemDescription}</h6>
											</div>
											<div className="col-sm-4 text-right">
												${charge.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
											</div>
										</div>
									</div>
								)}

								{charges.length > 5 &&
									<div className="text-center">
										<button
											className="btn btn-link"
											onClick={() => this.toggleShowAll('charges')}
										>
											Show {showAllCharges ? "Less" : "More"}
										</button>
									</div>
								}

							</>
							:
							"No data found."
						}
					</Tile>

					<Tile
						title={"Pending Tasks"}
						loading={loadingTasks}
						action={e => this.tasksAction(e)}
						{...this.props}
					>
						{!isEmpty(tasks)
							?
							<>
								{tasks.map((task, index) => (showAllTasks || (!showAllTasks && index < 5)) &&
									<div className="line-top">
										<h6>{task.name}</h6>
									</div>
								)}
								{tasks.length > 5 &&
									<div className="text-center">
										<button
											className="btn btn-link"
											onClick={() => this.toggleShowAll('tasks')}
										>
											Show {showAllTasks ? "Less" : "More"}
										</button>
									</div>
								}
							</>
							:
							"No data found."
						}
					</Tile>

					<Tile
						title={"Open Orders"}
						loading={loadingOrders}
						action={e => this.ordersAction(e)}
						{...this.props}
					>
						{!isEmpty(orders)
							?
							<>
								{orders.map((order, index) => (showAllOrders || (!showAllOrders && index < 5)) &&
									<div className="line-top">
										<div className="row">
											<div className="col-sm-8">
												<h6>
													<span className="text-muted">{order.id}</span> {order.serviceOrdersInfo[0].description}
												</h6>
												<small className="text-muted">{order.status}, {order.progressPercentage}%</small>
											</div>
											<div className="col-sm-4 text-right">
												<p>{moment(order.modifiedDateTime).format(' MM/DD/YYYY ')}</p>
												<small className="text-muted">
													{isEmpty(order.appointments)
														? "No appts"
														: order.appointments.length === 1 ? "1 appt"
															: order.appointments.length > 1 && (order.appointments.length + " appts")
													}
												</small>
											</div>
										</div>
									</div>
								)}
								{orders.length > 5 &&
									<div className="text-center">
										<button
											className="btn btn-link"
											onClick={() => this.toggleShowAll('orders')}
										>
											Show {showAllOrders ? "Less" : "More"}
										</button>
									</div>
								}
							</>
							:
							"No data found."
						}
					</Tile>

					<Tile
						title={"Open Troubles"}
						loading={loadingTickets}
						action={e => this.troublesAction(e)}
						{...this.props}
					>
						{!isEmpty(tickets)
							?
							<>
								{tickets.map((ticket, index) => (showAllTickets || (!showAllTickets && index < 5)) &&
									<div className="line-top">
										<div className="row">
											<div className="col-sm-8">
												<h6>
													<span className="text-muted">{ticket.troubleTicketDetails.troubleTicketId}</span> {ticket.troubleTicketDetails.reportedIssueDescription}
												</h6>
												<small className="text-muted">{ticket.troubleTicketDetails.status.name} {ticket.troubleTicketDetails.status.processingStatus}</small>
											</div>
											<div className="col-sm-4 text-right">
												<p>{moment(ticket.troubleTicketDetails.modifiedDateTime).format(' MM/DD/YYYY ')}</p>
												<small className="text-muted">
													{isEmpty(ticket.troubleTicketDetails.appointments)
														? "No appts"
														: ticket.troubleTicketDetails.appointments.length === 1 ? "1 appt"
															: ticket.troubleTicketDetails.appointments.length > 1 && (ticket.troubleTicketDetails.appointments.length + " appts")
													}
												</small>
											</div>
										</div>
									</div>
								)}
								{tickets.length > 5 &&
									<div className="text-center">
										<button
											className="btn btn-link"
											onClick={() => this.toggleShowAll('tickets')}
										>
											Show {showAllTickets ? "Less" : "More"}
										</button>
									</div>
								}
							</>
							:
							"No data found."
						}

					</Tile>

					{!isEmpty(services) && services.map(service =>
						<Tile
							icon={service.serviceModel.icon}
							title={service.planDescription}
							loading={loadingServices}
							action={e => this.servicesAction(e)}
							{...this.props}
						>
							${service.recurringCharge.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo {service.startDate && " | Started "+moment(service.startDate).format('MM/DD/YYYY')}
							<Service serviceInfo={service} {...this.props} />
						</Tile>
					)}

				</div>

				{loadingServices &&
					<div className="text-center border">
						<Loader />
					</div>
				}

			</div>
		);
	}
}

Account360.propTypes = {
	accountInfo: PropTypes.object.isRequired,
	changeTabHandler: PropTypes.func,
};

const loaderBalances = createLoadingSelector([ 'SET_BALANCES_INFO' ]);
const loaderPaperless = createLoadingSelector(['GET_ACCOUNT_DISTRIBUTION_METHOD']);
const loaderAutopay = createLoadingSelector(['AUTOPAY']);
const loaderContacts = createLoadingSelector(['GET_PRIMARY_CONTACT']);
const loaderCharges = createLoadingSelector(['SET_ADJUSTMENTS']);
const loaderTasks = createLoadingSelector(['SET_TASKS']);
const loaderOrders = createLoadingSelector(['SET_DASHBOARD_ORDERS']);
const loaderTickets = createLoadingSelector(['GET_TROUBLE_TICKETS']);
const loaderServices = createLoadingSelector(['SET_SERVICES']);

const mapStateToProps = (state) => {

	const loadingBalances = loaderBalances(state);
	const balances = getAccountDetails(state).billing.balancesInfo;
	const loadingPaperless = loaderPaperless(state);
	const paperless = getAdmin(state).distributionMethod;
	const loadingAutopay = loaderAutopay(state);
	const autopay = getAccountDetails(state).accountDetails.myAutoPayInfo;
	const loadingContacts = loaderContacts(state);
	const contacts = getAdmin(state).primaryContacts;
	const loadingCharges = loaderCharges(state);
	const charges = getAccountDetails(state).billing.adjustments;
	const loadingTasks = loaderTasks(state);
	const tasks = getDashboardData(state).userTasks;
	const loadingOrders = loaderOrders(state);
	const orders = getDashboardData(state).userOrders;
	const loadingTickets = loaderTickets(state);
	const tickets = getAccountDetails(state).troubleTickets.troubleTickets;
	const loadingServices = loaderServices(state);
	const services = getAccountDetails(state).account360.services;
	const billingBadjesData = getAccountDetails(state).billing.billingBadjes;
	const myAutoPayInfo = getAccountDetails(state).accountDetails.myAutoPayInfo;
	const myDistributionMethods = getAccountDetails(state).billing.distributionMethods;
	const primaryContacts = getAdmin(state).primaryContacts;

	return {
		loadingBalances,
		balances,
		loadingPaperless,
		paperless,
		loadingAutopay,
		autopay,
		loadingContacts,
		contacts,
		loadingCharges,
		charges,
		loadingTasks,
		tasks,
		loadingOrders,
		orders,
		loadingTickets,
		tickets,
		loadingServices,
		services,
		billingBadjesData,
		myAutoPayInfo,
		myDistributionMethods,
		primaryContacts,
	};
};

const mapDispatchToProps = {
	getBalancesInfo,
	getMyAutoPayInfo,
	emptyMyAutoPayInfo,
	getAccountDistributionMethod,
	getAccountTypeDistributionMethods,
	getPrimaryContacts,
	getAllContacts,
	getAdjustments,
	getFilteredTasks,
	getFilteredOrders,
	getTroubleTickets,
	getAccount360Services,
};

export default connect(mapStateToProps, mapDispatchToProps)(Account360);
