import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { showModal } from "../../../actions/modal.actions";
import RootModal from "../../Modals/RootModal";
import Footer from "../Footer";

class NoSideBarLayout extends Component {
  state = {
    navBarType: "default",
    componentName: "",
    title: "",
    dropdown: "",
    isOpen: false,
  };

  onFocuschangeActiveDropDown = (e, name) => {
    this.setState((prevProps) => ({
      dropdown: name,
      isOpen: !prevProps.isOpen,
    }));
  };

  onBlurchangeActiveDropDown = (e, name) => {
    this.setState((prevProps) => ({
      dropdown: "",
      isOpen: false,
    }));
  };

  componentDidMount() {}

  changeNavBarType = (navBarType, componentName, title) => {
    this.setState({
      navBarType: navBarType,
      componentName: componentName,
      navbarTitle: title,
    });
  };

  confirmCloseCreateAccountWizard = () => {
    return this.props.showModal("CLOSE_WIZARD_MODAL", {
      message: "Are you sure you want to cancel the new account creation wizard?",
    });
  };

  confirmCloseAccountDetailWizard = () => {
    return this.props.showModal("CLOSE_WIZARD_MODAL", { isAccountDetailWizard: true });
  };

  confirmCloseWizard = () => {
    return this.props.showModal("CLOSE_WIZARD_MODAL");
  };

  confirmCloseCreateSalesOrderWizard = () => {
    return this.props.showModal("CLOSE_WIZARD_MODAL", {
      message: "Are you sure you want to cancel the new sales order wizard?",
      isAccountDetailWizard: true,
    });
  };

  confirmCloseGeneric = (modalProps) => {
    return this.props.showModal("GENERIC_CLOSE_MODAL", modalProps);
  };

  render() {
    const { navBarType, componentName, navbarTitle } = this.state;
    const { userSummary, currentLocation } = this.props;

    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, { changeNavBarType: this.changeNavBarType, ...this.props })
    );

    return (
      <Fragment>
        <div className="page-wrapper page-wrapper-nosidebar cmv-theme">
          <div className="page-content">
            <header className="cmv-header cmv-header-nosidebar">
              <nav
                className={
                  "navbar navbar-expand cmv-navbar" + (navBarType === "default" ? "" : ` cmv-navbar-${navBarType}`)
                }
              >
                {componentName === "accountDetails" && (
                  <a className="btn-circle" onClick={() => this.props.showModal("CLOSE_ACCOUNT_DETAILS_MODAL")}>
                    <i className="fas fa-angle-left" />
                  </a>
                )}

                {componentName === "createAccount" && (
                  <a className="btn-circle" onClick={this.confirmCloseCreateAccountWizard}>
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "newOrder" && (
                  <a className="btn-circle" onClick={this.confirmCloseAccountDetailWizard}>
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "addEquipmentWizard" && (
                  <a className="btn-circle" onClick={this.confirmCloseAccountDetailWizard}>
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "removeEquipmentWizard" && (
                  <a className="btn-circle" onClick={this.confirmCloseAccountDetailWizard}>
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "swapEquipmentWizard" && (
                  <a className="btn-circle" onClick={this.confirmCloseAccountDetailWizard}>
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "removeServiceLines" && (
                  <a className="btn-circle" onClick={this.confirmCloseAccountDetailWizard}>
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "validateServiceWizard" && (
                  <a className="btn-circle" onClick={this.confirmCloseAccountDetailWizard}>
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "createServiceRequest" && (
                  <a className="btn-circle" onClick={this.confirmCloseWizard}>
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "createSalesOrderWizard" && (
                  <a className="btn-circle" onClick={this.confirmCloseCreateSalesOrderWizard}>
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "taskDetails" && (
                  <a
                    className="btn-circle"
                    onClick={() =>
                      this.confirmCloseGeneric({
                        title: "CONFIRM EXIT TASK DETAILS",
                        text: "Are you sure you want to exit this task?",
                      })
                    }
                  >
                    <i className="fas fa-angle-left" />
                  </a>
                )}

                {componentName === "showUsers" && (
                  <a
                    className="btn-circle"
                    onClick={() =>
                      this.confirmCloseGeneric({
                        title: "CONFIRM EXIT USER MANAGEMENT",
                        text: "Are you sure you want to exit user management?",
                      })
                    }
                  >
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "showPartners" && (
                  <a
                    className="btn-circle"
                    onClick={() =>
                      this.confirmCloseGeneric({
                        title: "CONFIRM EXIT PARTNER MANAGEMENT",
                        text: "Are you sure you want to exit partner management?",
                      })
                    }
                  >
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "manualProvisioningWizard" && (
                  <a
                    className="btn-circle"
                    onClick={() =>
                      this.confirmCloseGeneric({
                        title: "CONFIRM EXIT MANUAL PROVISIONING",
                        text: "Are you sure you want to exit manual provisioning?",
                      })
                    }
                  >
                    <i className="fas fa-times" />
                  </a>
                )}

                {componentName === "report" && (
                  <a
                    className="btn-circle"
                    onClick={() =>
                      this.confirmCloseGeneric({
                        title: "CONFIRM EXIT REPORT",
                        text: "Are you sure you want to exit the report?",
                        returnUrl:
                          this.props.userSummary.systemUserRole && this.props.userSummary.systemUserRole.landingUrl,
                      })
                    }
                  >
                    <i className="fas fa-times" />
                  </a>
                )}

                <span className="navbar-text navbar-title">{navbarTitle}</span>

                <ul className="navbar-nav">
                  <li
                    className="nav-item dropdown"
                    onClick={(e) => this.onFocuschangeActiveDropDown(e, "second")}
                    onBlur={(e) => this.onBlurchangeActiveDropDown(e, "second")}
                  >
                    <a
                      className="nav-link nav-link-disabled dropdown-toggle"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      tabIndex="2"
                    >
                      <i className="fas fa-map-marker-alt" />
                      <span className="nav-link-caption">
                        <span className="nav-link-title">{currentLocation.value}</span>
                      </span>
                    </a>
                    <div
                      className={
                        "dropdown-menu dropdown-menu-right" +
                        (this.state.dropdown === "second" && this.state.isOpen ? " show" : "")
                      }
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <h6 className="dropdown-header">{currentLocation.label}</h6>
                    </div>
                  </li>

                  <li
                    className="nav-item dropdown"
                    onClick={(e) => this.onFocuschangeActiveDropDown(e, "third")}
                    onBlur={(e) => this.onBlurchangeActiveDropDown(e, "third")}
                  >
                    <a
                      className="nav-link nav-link-disabled dropdown-toggle"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      tabIndex="3"
                    >
                      <i className="fas fa-user" />
                      <span className="nav-link-caption">
                        <span className="nav-link-title">
                          {userSummary.firstName ? userSummary.firstName : ""}{" "}
                          {userSummary.lastName ? userSummary.lastName : ""}
                        </span>
                        <span className="nav-link-text">
                          {userSummary.systemUserRole.description ? userSummary.systemUserRole.description : ""}
                        </span>
                      </span>
                    </a>
                    <div
                      className={
                        "dropdown-menu dropdown-menu-right" +
                        (this.state.dropdown === "third" && this.state.isOpen ? " show" : "")
                      }
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <h6 className="dropdown-header">
                        {userSummary.firstName ? userSummary.firstName : ""}{" "}
                        {userSummary.lastName ? userSummary.lastName : ""}
                        {", "}
                        {userSummary.systemUserRole.description ? userSummary.systemUserRole.description : ""}
                      </h6>
                    </div>
                  </li>
                </ul>
              </nav>
            </header>

            <main>{childrenWithProps}</main>

            <Footer />
          </div>
        </div>

        <RootModal {...this.props} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  showModal,
};

export default connect(null, mapDispatchToProps)(NoSideBarLayout);
