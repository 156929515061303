import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { isEmpty } from "src/utils/helpers";
import Loader from "src/components/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import moment from "moment";
import { formatCurrency } from "src/utils/ui";
import { getAccountDetails, getUserPermission } from "src/selectors";
import { getSalesOrder } from "src/actions/accountDetailsActions/salesOrders.actions";

const { SearchBar } = Search;

/**
 * Account Details / Sales Orders / Sales Order List
 */
export class SalesOrderHistoryList extends Component {
  state = {};

  /**
   * Start "Edit Sales Order" wizard
   */
  startEditSalesOrderWizard = async (salesorderId) => {
    try {
      const resp = await this.props.getSalesOrder(salesorderId);

      this.props.showCreateSalesOrderWizardHandler({
        locationId: this.props.currentLocation?.id,
        accountId: this.props.accountInfo.id,
        orderNumber: resp.data.salesorder.salesOrderNumber,
        salesorder: resp.data.salesorder,
      });
    } catch {
      // Can't preserve new sales order number
      // TODO: show error message
    }
  };

  dateFormater = (cell, row, rowIndex) => {
    if (row) {
      return <Fragment>{moment(cell).format("MM/DD/YYYY")}</Fragment>;
    }
  };

  currencyFormatter = (cell, row, rowIndex) => {
    if (row) {
      return <Fragment>{formatCurrency(cell)}</Fragment>;
    }
  };

  statusFormatter = (cell, row, rowIndex) => {
    if (row) {
      let styleClass;
      switch (cell) {
        case "NEW":
          styleClass = "warning";
          break;
        default:
          styleClass = "";
      }

      return <span className={`badge-status ${styleClass}`}>{cell}</span>;
    }
  };

  actionFormater = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="tr-actions">
        {row.status === "NEW" && this.props.permEditSalesOrder && (
          <button className="btn" onClick={() => this.startEditSalesOrderWizard(row.id)} title="Edit Sales Order">
            <i className="fas fa-eye" />
          </button>
        )}
      </div>
    );
  };

  noteFormatter = (cell, row, rowIndex) => {
    if (cell !== "undefined") {
      return <div className="cmv-table tr has-note td td-note">{cell}</div>;
    }
  };

  render() {
    const { salesOrdersLoading, salesOrders, history, showHistoricalData } = this.props;

    if (salesOrdersLoading) {
      return <Loader />;
    }

    if (!salesOrdersLoading && isEmpty(salesOrders)) {
      return <p className="tab-pane-message-empty">No data found</p>;
    }

    const options = {
      paginationSize: 4,
      custom: true,
      totalSize: salesOrders.length,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: "First",
      prePageText: "Previous",
      nextPageText: "Next",
      lastPageText: "Last",
      // nextPageTitle: 'First page',
      // prePageTitle: 'Pre page',
      // firstPageTitle: 'Next page',
      // lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: this.customTotal,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
      ],
    };

    const columns = [
      {
        dataField: "salesOrderNumber",
        text: "Order #",
        sort: true,
      },
      {
        dataField: "orderDate",
        text: "Order Date",
        formatter: this.dateFormater,
      },
      {
        dataField: "total",
        text: "Total",
        sort: true,
        formatter: this.currencyFormatter,
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: this.statusFormatter,
      },
      {
        dataField: "actions",
        text: "Actions",
        isDummyField: true,
        formatter: (cell, row, rowIndex, formatExtraData) => this.actionFormater(cell, row, rowIndex, formatExtraData),
        formatExtraData: {},
        headerClasses: "h-align-r",
      },
      {
        dataField: "note",
        text: "",
        formatter: this.noteFormatter,
        classes: (cell, row, rowIndex, colIndex) => {
          if (row.note !== "undefined") return "with-note";
          return "";
        },
      },
    ];

    const defaultSorted = [
      {
        dataField: "salesOrderNumber",
        order: "desc",
      },
    ];

    const rowStyle = (row, rowIndex) => {
      if (row) {
        let classes = null;

        if (row.note) {
          classes = "has-note";
        }

        if (rowIndex % 2 === 0) {
          classes += " odd";
        } else {
          classes += " even";
        }

        return classes;
      }
    };

    return (
      <Fragment>
        <div className="cmv-container-table cmv-container-table-invoices">
          <PaginationProvider pagination={paginationFactory(options)}>
            {({ paginationProps, paginationTableProps }) => (
              <Fragment>
                <ToolkitProvider
                  bootstrap4
                  keyField="id"
                  data={salesOrders}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  search
                >
                  {(props) => (
                    <Fragment>
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <label>
                            Show <SizePerPageDropdownStandalone {...paginationProps} /> entries
                          </label>
                        </div>
                        <div className="col-sm-12 col-md-6" style={{ textAlign: "right" }}>
                          <label>
                            Search: <SearchBar className="form-control form-control-sm" {...props.searchProps} />
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <BootstrapTable
                          wrapperClasses="table-responsive react-table-layout"
                          rowClasses={rowStyle}
                          classes="table table-striped cmv-table"
                          bordered={false}
                          noDataIndication="No data found"
                          {...paginationTableProps}
                          {...props.baseProps}
                        />
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-sm-12 col-md-5">
                          <PaginationTotalStandalone {...paginationProps} />
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </div>
                    </Fragment>
                  )}
                </ToolkitProvider>
              </Fragment>
            )}
          </PaginationProvider>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const accountInfo = getAccountDetails(state).accountDetails.accountInfo;
  const permEditSalesOrder = getUserPermission(state, "CAM", "CAM_SALES_ORDER_EDIT");

  return {
    accountInfo,
    permEditSalesOrder,
  };
};

const mapDispatchToProps = {
  getSalesOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderHistoryList);
