import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";

import { getAuthData, getErrorMessage, getSystemConfigs } from "src/selectors";
import { hideModal } from "src/actions/modal.actions";
import { login, logout, forgottenPassword, loginWithAuth0Token } from "src/actions/auth.actions";
import { isEmpty } from "src/utils/helpers";
import Footer from "src/containers/Layout/Footer";
import Loader from "src/components/Loader";

const loginSchema = Yup.object().shape({
  username: Yup.string().min(3, "Min 3 characters").required("Required"),
  password: Yup.string().min(6, "Min 6 characters").required("Required"),
});

class LoginLayout extends Component {
  render() {
    return (
      <div className="cmv-page cmv-page-login">
        <div className="page-wrapper">
          <div className="page-content">
            <main>
              <div className="col col-sm-8 col-md-6 col-lg-5 col-xl-3">
                <div className="card-login">{this.props.children}</div>
              </div>
            </main>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

class LoginLoader extends Component {
  render() {
    return (
      <LoginLayout>
        <div className="card-login-header">
          <div className="brand-container" />
          <h3>Sign In</h3>
          <div className="lds-dual-ring" />
        </div>
        <Loader />
      </LoginLayout>
    );
  }
}

class Login extends Component {
  state = {
    showSuccessfullPasswordReset: false,
    showPermissionErrorMessage: false,
    isStaySignIn: false,
    passwordShown: false,
  };

  // Password toggle handler
  togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    this.setState({ passwordShown: !this.state.passwordShown });
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.permissionError) {
      this.setState({
        showPermissionErrorMessage: true,
      });
    }

    // logout if already logged in
    if (this.props.isLoggedIn) {
      if (this.props.isUsingAuth0) {
        this.props.logout(this.props.history, true);
        this.props.auth0.logout({
          logoutParams: { returnTo: window.location.origin },
        });
      }
      this.props.logout(this.props.history);
    }

    if (this.props.isUsingAuth0) {
      if (this.props.auth0.isAuthenticated) {
        // Logged in with Auth0, get internal token and login to CMV
        this.loginWithAuth0Token();
      } else if (this.props.isUsingAuth0Only) {
        // if using Auth0 only, automatically redirect to the Auth0 login page
        this.props.auth0.loginWithRedirect();
      }
    }

    this.props.hideModal();
    if (!isEmpty(this.props.location.state)) {
      if (this.props.location.state.succesfulResetPassword === true) {
        this.setState({
          showSuccessfullPasswordReset: true,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn === true) {
      // Just logged in, redirect to the targetted page
      const location = this.props.history.location;

      if (location.state && location.state.from) {
        this.props.history.push(location.state.from);
      } else {
        this.props.history.push("/home");
      }
    }

    if (
      this.props.isUsingAuth0 &&
      this.props.auth0.isAuthenticated &&
      prevProps.auth0.isAuthenticated !== this.props.auth0.isAuthenticated
    ) {
      // Just logged in through Auth0, redirect to the targetted page
      this.loginWithAuth0Token();
    }
  }

  handleStaySignIn = (e) => {
    this.setState({
      isStaySignIn: e.target.checked,
    });
  };

  handleOnSubmit = (values, actions) => {
    if (this.state.isStaySignIn) {
      localStorage.setItem("stayIn", true);
    } else {
      localStorage.removeItem("stayIn");
    }

    this.props.login(values.username, values.password, this.props.history).then(() => {
      actions.setSubmitting(false);
    });
  };

  async loginWithAuth0Token() {
    const { user, getAccessTokenSilently } = this.props.auth0;
    const token = await getAccessTokenSilently({ cacheMode: "cache-only", detailedResponse: true });
    // logged in through Auth0, but not yet logged in to CMV
    await this.props.loginWithAuth0Token(token, user);
  }

  render() {
    const { showSuccessfullPasswordReset, showPermissionErrorMessage, isStaySignIn, passwordShown } = this.state;
    const { authErrorMessage, auth0ErrorMessage, isUsingAuth0, isUsingCamvioAuth, auth0 } = this.props;

    if (isUsingAuth0 && (auth0.isAuthenticated || auth0.isLoading)) {
      // Wait until we call the CamvIO Backend for token validation and authentication
      return <LoginLoader />;
    }

    return (
      <LoginLayout>
        <div className="card-login-header">
          <div className="brand-container" />
          <h3>Sign In</h3>
          {showSuccessfullPasswordReset && <p>Your new password has been saved.</p>}
          {showPermissionErrorMessage && <p style={{ color: "red" }}>Not enough permissions to access.</p>}
          <div className="lds-dual-ring" />
        </div>

        {isUsingCamvioAuth && (
          <div className="card-login-body">
            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={loginSchema}
              onSubmit={this.handleOnSubmit}
            >
              {({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting }) => (
                <Form onSubmit={handleSubmit} className="needs-validation">
                  <div className="form-label-group">
                    <input
                      type="text"
                      className={errors.username && touched.username ? "form-control is-invalid" : "form-control"}
                      id="username"
                      placeholder="Username or Email"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="username">Username or Email</label>

                    {errors.username && touched.username && <div className="invalid-feedback">{errors.username}</div>}
                  </div>
                  <div className="form-label-group">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className={errors.password && touched.password ? "form-control is-invalid" : "form-control"}
                      id="password"
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <label htmlFor="password">Password</label>
                    <label
                      className="d-inline-block position-absolute"
                      style={{
                        width: "32px",
                        right: errors.password && touched.password && !values.password ? "34px" : "4px",
                        left: "initial",
                      }}
                    >
                      <input type="checkbox" onClick={this.togglePassword} className="d-none" />
                      <span className={`fas fa-fw ${this.state.passwordShown ? "fa-eye-slash" : "fa-eye"}`}></span>
                    </label>
                    {errors.password && touched.password && <div className="invalid-feedback">{errors.password}</div>}
                  </div>

                  {authErrorMessage.length > 0 && <p style={{ color: "red" }}>{authErrorMessage}</p>}

                  <div className="form-group d-flex align-content-between flex-wrap">
                    <Link
                      to={{ pathname: "/forgotenpassword" }}
                      className="mt-1 mb-1 p-0"
                      style={{ margin: "0px 5px" }}
                    >
                      Forgot password?
                    </Link>
                  </div>

                  <div className="form-group">
                    <button type="submit" id="sendlogin" className="btn btn-block btn-primary" disabled={isSubmitting}>
                      Sign In {isSubmitting && <i className="fas fa-fw fa-spin fa-spinner" />}
                    </button>
                  </div>

                  {isMobile && (
                    <div className="form-check checkbox-slider checkbox-slider-sm checkbox-slider--b-flat">
                      <label>
                        <input
                          type="checkbox"
                          name="noAddress"
                          checked={isStaySignIn}
                          onChange={this.handleStaySignIn}
                        />
                        <span className="text-xs text-muted">Stay signed in</span>
                      </label>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        )}

        {isUsingAuth0 && isUsingCamvioAuth && <p className="text-center">- OR -</p>}

        {isUsingAuth0 && (
          <div className="mb-4">
            <button
              type="submit"
              className="btn btn-block btn-default mb-2"
              onClick={() => auth0.loginWithRedirect()}
              style={{ backgroundColor: "#000000", color: "#ffffff" }}
            >
              Continue with Auth0
            </button>
            {auth0ErrorMessage.length > 0 && <p style={{ color: "red" }}>{auth0ErrorMessage}</p>}  
          </div>
        )}
      </LoginLayout>
    );
  }
}

Login.propTypes = {
  isLoggedIn: PropTypes.bool,
  authErrorMessage: PropTypes.string,
};

const getAuthError = getErrorMessage(["AUTH"]);

const mapStateToProps = (state) => {
  const errorMessage = getAuthError(state);
  let authErrorMessage = '', auth0ErrorMessage = '';
  if ((errorMessage || "").startsWith("Auth0:")) {
    auth0ErrorMessage = errorMessage.replace("Auth0:", "").trim();
  } else {
    authErrorMessage = errorMessage;
  }

  return {
    isLoggedIn: getAuthData(state).isLoggedIn,
    isUsingAuth0: getSystemConfigs(state).isUsingAuth0,
    isUsingAuth0Only: getSystemConfigs(state).isUsingAuth0Only,
    isUsingCamvioAuth: getSystemConfigs(state).isUsingCamvioAuth,
    authErrorMessage: authErrorMessage,
    auth0ErrorMessage: auth0ErrorMessage,
  };
};

const mapDispatchToProps = {
  loginWithAuth0Token,
  login,
  logout,
  hideModal,
  forgottenPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(Login));
