import {DOWNLOAD_CSV_FILE, LIST_MIGRATION_JOBS} from "src/actions/migrationJobs.actions";

let initialState = {
    migrationJobs: []
}


export const migrationJobsReducer = (state = initialState,action) => {
    switch(action.type) {
        case LIST_MIGRATION_JOBS.SUCCESS:
            return {
                ...state,
                migrationJobs: action.payload
            };
        default:
            return state
    }

}