import React, { Fragment, useState } from "react";

/**
 * A component that defines a state that can be toggled on and off.
 * This can be used to implement the expandable detail row for the table.
 * 
 * @param {*} props 
 * @returns 
 */
export default function ExpandableContent(props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Fragment>
      {props.children({
        ...props,
        expanded,
        setExpanded
      })}
    </Fragment>
  );
}
