import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { hideModal } from "src/actions/modal.actions";
import { getModalData, getUser, getInventory } from "src/selectors";
import { getSerializedItem, updateSerializedItemNumbers, clearSerializedItem } from "src/actions/inventory.actions";
import TextInput from "src/components/UI/TextInput";

class ViewInventorySerializedNumbersModal extends Component {
  state = {
    alertMessage: "",
    isLoading: true,
  };

  componentDidMount() {}

  componentWillUnmount() {}

  hideModal = () => {
    this.props.clearSerializedItem();
    this.props.hideModal();
  };

  render() {
    const { modalProps } = this.props;
    const { value: serializedItem } = modalProps;

    return (
      <Fragment>
        <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">View Inventory</h5>
                <button onClick={this.hideModal} type="button" className="close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body cmv-form form-horizontal">
                <div className="order-service-order-wrapper">
                  <div>
                    <h4 className="text-uppercase">{serializedItem?.item.name}</h4>
                    <p className="billing-job-id">{serializedItem?.item.description}</p>
                  </div>
                </div>
                <div>
                  {serializedItem?.numbers.map((num, idx) => {
                    return (
                      <div key={idx} className="form-group">
                        <TextInput label={num.systemNumType.name} value={num.number} disabled={true} divClass="mb-1" />
                        <p className="text-xs text-muted">{num.systemNumType.description}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="modal-footer">
                <button onClick={this.hideModal} className="btn" type="button">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps,
    serializedItem = getInventory(state).serializedItem,
    currentUser = getUser(state).user;

  return {
    modalProps,
    serializedItem,
    currentUser,
  };
};

const mapDispatchToProps = {
  hideModal,
  updateSerializedItemNumbers,
  getSerializedItem,
  clearSerializedItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewInventorySerializedNumbersModal);
