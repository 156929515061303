import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Loader from "../../../components/Loader";
import {Formik} from "formik";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import DatePicker from "react-datepicker";
import moment from "moment";
import CapacityResults from "./CapacityResults";
import isEmpty from "../../../utils/helpers";
import {getFormattedFilters} from "../../Dashboard/Content/Tasks/utils";
import {
    createAppointmentCapacity
} from "../../../actions/technicianAppointment.actions";
import {toastr} from "react-redux-toastr";
import Select from "react-select";
import {groupStyles} from "../../../utils/SelectStyles";

const asOptions = (source, mapper) => {
    if (!Array.isArray(source)) {
        return [];
    }

    return source.map(mapper);
};

const appointmentAreasAsOptions = (appointmentAreas) => asOptions(appointmentAreas, area => {
    return {
        label: area[2],
        value: area[0]
    };
});

class Schedule extends Component {

    state = {
        appointmentAreas: [],
        initialValues: {
            area: {},
            capacity: {},
        },
        capacityValues: {
            fromDate: moment().startOf('week').toDate(),
            toDate: moment().endOf('week').toDate(),
            area: {},
        },
        datepickerText: '',
        selectedDates: [],
        capacitiesSet: false,
        loadingAppointmentArea:true,
        canEdit: false,
    }

    componentDidMount() {
        // Set default selected date range
        this.handleDateChange(new Date());

        // Load areas
        this.props.getAppointmentAreas().then((response) => {
            let initialValues = {...this.state.initialValues}
            this.props.getAppointmentTimeSlots().then(response => {
                response.map(time => {
                    initialValues.capacity["slot"+time.id] =
                        {day1: '', day2: '', day3: '', day4: '', day5: '', day6: '', day7: ''}
                });
            });
            this.setState({
                loadingAppointmentArea: false,
                initialValues: initialValues
            })
        });

    }

    handleDateChange = (date) => {

        // Update only if new date is not one of selected dates
        if (!this.state.selectedDates.includes(moment(date).format("MM/DD/YYYY"))) {

            let updatedCapacityValues = this.state.capacityValues;
            updatedCapacityValues['fromDate'] = moment(date).startOf('week').toDate();
            updatedCapacityValues['toDate'] = moment(date).endOf('week').toDate();
            let updatedDatepickerText = moment(updatedCapacityValues['fromDate']).format("MM/DD/YYYY") + " - "
                + moment(updatedCapacityValues['toDate']).format("MM/DD/YYYY");

            this.setState({
                    capacityValues: updatedCapacityValues,
                    datepickerText: updatedDatepickerText,
                }, () => {
                    let updatedSelectedDates = this.getDateRange(updatedCapacityValues['fromDate'], updatedCapacityValues['toDate']);

                    this.setState({
                        selectedDates: updatedSelectedDates,
                    });
                }
            );
        }
    }

    getDateRange(startDate, endDate) {
        let currentDate = startDate;
        let dateArray = [];

        Date.prototype.addDays = function (days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        while (currentDate <= endDate) {
            new Date(currentDate);
            dateArray.push(moment(currentDate).format('MM/DD/YYYY'));
            currentDate = currentDate.addDays(1);
        }

        return dateArray;
    }

    fetchAppointmentCapacities = (setFieldValue) => {
        this.setState({
            capacitiesSet: false
        })
        // If its the initial load and the user has not switched between tabs
        if(this.props.currentArea == undefined) {
            if (this.props.appointmentAreas != undefined && !isEmpty(this.props.appointmentAreas)) {
                let defaultArea = this.props.appointmentAreas[0];
                let capacityValues = this.state.capacityValues;
                capacityValues['area'] = {
                    label: defaultArea[2],
                    value: defaultArea[0]
                };
                this.props.updateCurrentArea({
                    label: defaultArea[2],
                    value: defaultArea[0]
                });
                this.setState({
                    capacityValues: capacityValues
                })
            }
        }else{
            let capacityValues = this.state.capacityValues;
            capacityValues['area'] = this.props.currentArea;
            setFieldValue('area', this.props.currentArea);
            this.setState({
                capacityValues: capacityValues
            })
        }

        let payload = this.state.capacityValues;
        payload["areaId"] = this.state.capacityValues.area.value;
        this.props.getAppointmentCapacities(getFormattedFilters(payload)).then((response) => {
            if (response && response.data.capacities.length) {
                this.setCapacities(setFieldValue, response.data.capacities);
                this.setState({
                    capacitiesSet: true
                });
            } else {
                let zeroArray = new Array(28);
                for (let i = 0; i < 28; ++i) zeroArray[i] = 0;
                this.setCapacities(setFieldValue, zeroArray);
                this.setState({
                    capacitiesSet: true
                })
            }
        });
    }

    setCapacities = (setFieldValue, capacities) => {
        let index = 0;
        let updatedCapacity = this.state.initialValues.capacity;
        this.props.appointmentTimeSlots.map((time, i) => {
            updatedCapacity[("slot" + (time.id))] = {
                day1: capacities[index] && capacities[index].capacity || 0,
                day2: capacities[index + 1] && capacities[index + 1].capacity || 0,
                day3: capacities[index + 2] && capacities[index + 2].capacity || 0,
                day4: capacities[index + 3] && capacities[index + 3].capacity || 0,
                day5: capacities[index + 4] && capacities[index + 4].capacity || 0,
                day6: capacities[index + 5] && capacities[index + 5].capacity || 0,
                day7: capacities[index + 6] && capacities[index + 6].capacity || 0,
            };
            index += 7;
        });
        setFieldValue("capacity", updatedCapacity);
    }

    handleAreaChange = (area, setFieldValue) => {
        let updatedCapacityValues = this.state.capacityValues;
        updatedCapacityValues['area'] = area;
        this.props.updateCurrentArea(area);
        setFieldValue('area', area);
        this.setState({
            capacityValues: updatedCapacityValues,
        });
    }

    handleFormSubmit = (values, actions) => {
        if (this.props.currentArea == undefined) {
            values["areaId"] = this.props.appointmentAreas[0][0] || '';
        } else {
            values["areaId"] = this.props.currentArea.value;
        }
        values["dates"] = this.state.selectedDates;

        this.props.createAppointmentCapacity(values).then((response) => {
            if (response.data === "success") {
                toastr.success("Successfully updated capacities", {timeOut: 2000, position: 'top-center'});
            } else {
                toastr.error("Failed to update capacities", {timeOut: 2000, position: 'top-center'});
            }

            actions.setSubmitting(false)
        });
    }

    copyFromPreviousWeek = (setFieldValue) => {
        let previousWeek = {
            fromDate: moment(this.state.capacityValues.fromDate).format('YYYY-MM-DD'),
            toDate: moment(this.state.capacityValues.toDate).format('YYYY-MM-DD'),
            areaId: this.state.capacityValues.area.value,
        };

        previousWeek.fromDate = moment(previousWeek.fromDate).subtract(7, 'days').toDate();
        previousWeek.toDate = moment(previousWeek.toDate).subtract(7, 'days').toDate();

        // Load previous week capacity
            this.props.getAppointmentCapacities(getFormattedFilters(previousWeek)).then((response) => {
                if (response && response.data.capacities.length % 7 === 0) {
                    this.setCapacities(setFieldValue, response.data.capacities);
                }
            });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {
                        boundingElement.scrollIntoView({block: "end"});
                    }
                }
            }, 200);
        }
    }


    render() {

        const {
            initialValues,
            capacityValues,
            selectedDates,
            capacitiesSet,
            loadingAppointmentArea
        } = this.state;

        const {
            timeslotLoader,
            capacityLoader,
            areasLoader,
            initialLoad
        } = this.props;

        const DateRangeInput = ({ value, onClick, isSubmitting }) => (
            <button
                type="button"
                className="form-control"
                disabled={isSubmitting}
                onClick={onClick}
            >
                {this.state.datepickerText}
            </button>
        );


        return (
            <Fragment>
                {areasLoader  && loadingAppointmentArea &&
                <div className="cmv-container-table" style={{padding: '2.5%'}}>
                     <Loader/>
                </div>
                }
                {!areasLoader  && !loadingAppointmentArea &&
                <div className="cmv-container-table" style={{padding: '2.5%'}}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={this.handleFormSubmit}
                        render={({handleChange, handleSubmit, values, errors,
                                     touched, isSubmitting, setFieldValue, handleBlur}) => (
                            <form onSubmit={handleSubmit} ref={this.FormikElement}>

                                <ToolkitProvider
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    isSubmitting={isSubmitting}
                                    values={values}
                                    bootstrap4
                                    keyField="id"
                                    search
                                >
                                    {(props) => <>
                                        <div className="row" >
                                            <div className="col-sm-6 col-md-4 col-xl-3">
                                                <div className="form-group">
                                                    <div className="customDatePickerWidth">
                                                        <DatePicker
                                                            className={"form-control text-left"}
                                                            fieldName="date"
                                                            dateFormat="MM/dd/yyyy"
                                                            placeholderText="Choose Date"
                                                            shouldCloseOnSelect={true}
                                                            popperPlacement={"bottom-center"}
                                                            customInput={<DateRangeInput />}
                                                            selected={capacityValues.fromDate}
                                                            onChange={(date) => this.handleDateChange(date)}
                                                            disabledKeyboardNavigation
                                                            showDisabledMonthNavigation
                                                            highlightDates={capacityValues.fromDate ? [
                                                                moment(capacityValues.fromDate).startOf('week').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(1, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(2, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(3, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(4, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(5, 'days').toDate(),
                                                                moment(capacityValues.fromDate).startOf('week').add(6, 'days').toDate(),
                                                            ] : [] }
                                                        />
                                                    </div>
                                                    {errors.date && touched.date &&
                                                    <div className="invalid-feedback">{errors.date}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-3">
                                                <div className="form-group">
                                                    <div className="form-group position-relative">
                                                        <Select
                                                            name="area"
                                                            value={values.area}
                                                            onChange={(value) => {
                                                                this.handleAreaChange(value, setFieldValue);
                                                            }}
                                                            options={appointmentAreasAsOptions(this.props.appointmentAreas)}
                                                            styles={groupStyles}
                                                            isMulti={false}
                                                        />
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-md-5 col-lg-5 col-xl-6">
                                                <div className="search">
                                                    <button
                                                        className="btn btn-secondary"
                                                        type="button"
                                                        disabled={capacityLoader || isSubmitting}
                                                        onClick={() => this.copyFromPreviousWeek(setFieldValue, values)}
                                                    >
                                                        <i className="far fa-clone"/>
                                                        <span> Copy From Previous Week</span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                        <CapacityResults
                                            fetchAppointmentCapacities={this.fetchAppointmentCapacities}
                                            appointmentTimeSlots={this.props.appointmentTimeSlots}
                                            capacityLoader={this.props.capacityLoader}
                                            capacitiesSet={capacitiesSet}
                                            appointmentCapacity={this.props.appointmentCapacity}
                                            selectedDates={selectedDates}
                                            setFieldValue={setFieldValue}
                                            handleChange={handleChange}
                                            isSubmitting={isSubmitting}
                                            values={values}
                                            capacity={values.capacity}
                                            areaId={this.state.capacityValues.area.value}
                                            canEdit={this.props.canEdit}
                                        />
                                    </>
                                    }
                                </ToolkitProvider>


                            </form>
                        )}
                    />
                </div>
                }
            </Fragment>
        );
    }
}



const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    createAppointmentCapacity
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);