import {
  LIST_DEVICE_PROFILES,
  LIST_ITEMS,
  LIST_SYSTEM_NUM_TYPES,
  LIST_INVENTORY_LOCATIONS,
  LIST_SERIALIZED_ITEMS,
  GET_SERIALIZED_ITEM,
} from "../actions/inventory.actions";

let initialState = {
  deviceProfiles: [],
  items: [],
  systemNumTypes: [],
  locations: [],
  serializedItems: [],
  serializedItem: [],
  statuses: [
    // now a constant
    "ASSIGNED",
    "UNASSIGNED",
    "PENDING",
    "RETURNED_TO_VENDOR",
    "RE_STOCKED",
    "DAMAGE",
    "SHORTAGE",
    "OVERAGE",
    "RETURNED",
    "DELETED",
    "IN_TRANSIT",
    "REPLACED",
  ],
};

const compareSystemNumTypes = (snt1, snt2) => {
  let cmp = (snt1?.viewOrder || 0) - (snt2?.viewOrder || 0)
  if (cmp === 0)
    cmp = (snt1?.id || 0) - (snt2?.id || 0)
  if (cmp === 0)
    cmp = snt1?.name?.localeCompare(snt2?.name) || 0

  return cmp;
}

/**
 * Helper to keep the serialized item numbers to be sorted by their view orders
 * @param {*} item
 * @returns
 */
const sortSerializedItemNumbers = (item) => {
  if (item.numbers)
    item.numbers.sort((n1, n2) => {
      return compareSystemNumTypes(n1?.systemNumType, n2?.systemNumType);
    });
  return item;
};

export const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DEVICE_PROFILES.SUCCESS:
      return {
        ...state,
        deviceProfiles: action.payload,
      };
    case LIST_ITEMS.SUCCESS:
      return {
        ...state,
        items: action.payload,
      };
    case LIST_SYSTEM_NUM_TYPES.SUCCESS:
      return {
        ...state,
        systemNumTypes: action.payload,
      };
    case LIST_INVENTORY_LOCATIONS.SUCCESS:
      return {
        ...state,
        locations: action.payload,
      };
    case LIST_SERIALIZED_ITEMS.SUCCESS:
      const serializedItems = action.payload.map(sortSerializedItemNumbers)
      return {
        ...state,
        serializedItems
      };
    case LIST_SERIALIZED_ITEMS.CLEAR:
      return {
        ...state,
        serializedItems: [],
      };
    case GET_SERIALIZED_ITEM.SUCCESS:
      const serializedItem = action.payload
      sortSerializedItemNumbers(serializedItem)
      return {
        ...state,
        serializedItem,
      };
    case GET_SERIALIZED_ITEM.CLEAR:
      return {
        ...state,
        serializedItem: [],
      };
    default:
      return state;
  }
};
