import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Field, Formik} from 'formik';
import { hideModal } from '../../actions/modal.actions';
import JoditEditor from "jodit-react";
import {getModalData} from "../../selectors";
import Loader from "../../components/Loader";
class DetailedDescriptionModal extends Component {

    state = {
        isDescriptionLoading: false,
        note: '',
        name: '',
        description: '',
        serviceModelIcon: '',
        submitForm: false
    };

    componentDidMount() {
        this.getDetailedDescription();
    }



    formSubmit = (values, actions) => {
        let { submitForm } = this.state;
        if(submitForm) {
            let dataValues = {
                id: this.props.modalProps.value,
                note: this.state.note
            }
            this.props.modalProps.noteFunction(true, dataValues);
            this.props.hideModal();
            this.props.modalProps.reloadFunction(true);
        }
    }

    getDetailedDescription = () => {
        this.setState({
            isDescriptionLoading: true
        });
        this.props.modalProps.noteFunction(false, this.props.modalProps.value).then((response) => {
            this.setState({
                isDescriptionLoading: false,
                note: response.note,
                name: response.name,
                description: response.description,
                serviceModelIcon: response.serviceModelIcon
            })
        })
    }

    updateSubmitFormState = () => {
        this.setState({
            submitForm: true
        }, () => {
            this.formSubmit();
        });
    }


    render() {
        const { isDescriptionLoading } = this.state;

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {isDescriptionLoading &&
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Detail Description
                                </h5>
                                <button onClick={this.props.hideModal} type="button" className="close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Loader />
                            <div className="modal-footer">
                                <button onClick={this.props.hideModal} className="btn" type="button">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                    {!isDescriptionLoading &&
                    <Formik
                        initialValues={{
                            note: this.state.note|| ''
                        }}
                        onSubmit={this.formSubmit}
                        render={({
                                     handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     setFieldValue
                                 }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                Detail Description
                                            </h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body form-horizontal">
                                            <div className="order-service-order-wrapper">
                                                <div><h4><i className={this.state.serviceModelIcon}/>{this.state.serviceModelIcon?' ':''}{this.state.name}</h4>
                                                    <p className="billing-job-id">{this.state.description}</p>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <JoditEditor
                                                    //    ref={editor}
                                                    name={"note"}
                                                    value={values.note}
                                                    config={{
                                                        minHeight: 400,
                                                        buttons: ['bold','italic','underline','strikethrough',
                                                        'ul','ol','font','fontsize','brush','paragraph','superscript','subscript',
                                                            'image','video','file','hr','table','link','symbol','indent',
                                                            'source','fullsize','preview'
                                                        ],
                                                        readonly: false,
                                                        toolbarAdaptive: false,
                                                        showCharsCounter: false,
                                                        showWordsCounter: false,
                                                        showXPathInStatusbar: false,
                                                    }}
                                                    //  config={config}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => {this.setState({
                                                        note: newContent
                                                    })}}
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={this.state.submitForm} onClick={this.updateSubmitFormState} type="submit" className="btn btn-primary">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />}
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;

    return {
        modalProps
    };
};

const mapDispatchToProps = {
    hideModal
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedDescriptionModal);

