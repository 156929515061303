import {actionCreator, createRequestTypes} from "../utils/helpers";
import axiosInstance from "../utils/axios-instance";

export const LOAD_AUTOPAY_JOBS = createRequestTypes('LOAD_AUTOPAY_JOBS');
export const ADD_AUTOPAY_JOBS = createRequestTypes("ADD_AUTOPAY_JOBS");
export const UPDATE_AUTOPAY_JOBS = createRequestTypes("UPDATE_AUTOPAY_JOBS");
export const GET_AUTOPAY_JOB_BY_ID = createRequestTypes("GET_AUTOPAY_JOB_BY_ID");
export const ABORT_AUTOPAY_JOB = createRequestTypes("ABORT_AUTOPAY_JOB");
export const GET_AUTOPAY_JOB_STATUS_LIST = createRequestTypes("GET_AUTOPAY_JOB_STATUS_LIST");

export const clearAutoPayJobs = () => async (dispatch) => {
    dispatch(actionCreator.clear(LOAD_AUTOPAY_JOBS))
}

export const getAutoPayJob = (autoPayJobId) => async (dispatch) => {
    dispatch(actionCreator.request(GET_AUTOPAY_JOB_BY_ID));
    try {
        const response = await axiosInstance.get('/autopay-job/' + autoPayJobId)
        dispatch(actionCreator.success(GET_AUTOPAY_JOB_BY_ID, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
        dispatch(actionCreator.failure(GET_AUTOPAY_JOB_BY_ID, mockError));
        return err.response;
    }
}

export const addAutoPayJob = (autoPayJob) => async (dispatch) => {
    dispatch(actionCreator.request(ADD_AUTOPAY_JOBS));

    try {
            const response = await axiosInstance.post('/admin/autopay-job', autoPayJob);
            dispatch(actionCreator.success(ADD_AUTOPAY_JOBS, response.data));
            return response;
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
        dispatch(actionCreator.failure(ADD_AUTOPAY_JOBS, mockError));
        return err.response;
    }
};

export const updateAutoPayJob = (autoPayJob) => async (dispatch) => {
    dispatch(actionCreator.request(UPDATE_AUTOPAY_JOBS));

    try {
        const response = await axiosInstance.put('/autopay-job/update', autoPayJob);
        dispatch(actionCreator.success(UPDATE_AUTOPAY_JOBS, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
        dispatch(actionCreator.failure(UPDATE_AUTOPAY_JOBS, mockError));
        return err.response;
    }
};

export const abortAutoPayJob = (autoPayJobId) => async (dispatch) => {
    dispatch(actionCreator.request(ABORT_AUTOPAY_JOB));

    try {
        const response = await axiosInstance.get('/autopay-job/' + autoPayJobId + '/abort');
        dispatch(actionCreator.success(ABORT_AUTOPAY_JOB, response.data));
        return response;
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
        dispatch(actionCreator.failure(ABORT_AUTOPAY_JOB, mockError));
        return err.response;
    }
};

export const getAutoPayJobStatusList = () => async (dispatch) => {
    dispatch(actionCreator.request(GET_AUTOPAY_JOB_STATUS_LIST));
    try {
        const response = await axiosInstance.get('/autopay-job/statuslist')
        dispatch(actionCreator.success(GET_AUTOPAY_JOB_STATUS_LIST, response.data));
        return response.data;
    } catch (err) {
        let mockError = {
            message: 'Something went wrong'
        }
        dispatch(actionCreator.failure(GET_AUTOPAY_JOB_STATUS_LIST, mockError));
        return err.response;
    }
}

export const getAutoPayJobStatusSummary = (autoPayJobID) => async (dispatch) => {
    try {
        const response = await axiosInstance.get(`/autopay-job/${autoPayJobID}/status-summary`)
        return response.data
    } catch(err) {
        let mockError = {
            message: 'Something went wrong'
        }
        return err.response
    }
}

