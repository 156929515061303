import { createRequestTypes } from "../utils/helpers";
import { httpGetAndDispatchAction, dispatchEmptyAction, httpPatchAndDispatchAction } from "./_utils";

export const MAPPING_SYSTEM_FIND_PROVIDER_BY_ID = createRequestTypes("@mappingSystem/FIND_PROVIDER_BY_ID");
export const MAPPING_SYSTEM_PATCH_PROVIDER = createRequestTypes("@mappingSystem/PATCH_PROVIDER");
export const MAPPING_SYSTEM_CURRENT_ID = createRequestTypes("@mappingSystem/CURRENT_ID");
export const MAPPING_SYSTEM_ALL_PROVIDERS = createRequestTypes("@mappingSystem/ALL_PROVIDERS");
export const MAPPING_SYSTEM_ALL_PROVIDERS_IDS = createRequestTypes("@mappingSystem/ALL_PROVIDERS_IDS");
export const WORKFORCE_FIND_PROVIDER_BY_ID = createRequestTypes("@workforce/FIND_PROVIDER_BY_ID");
export const WORKFORCE_PATCH_PROVIDER_ID = createRequestTypes("@workforce/PATCH_PROVIDER_ID");
export const WORKFORCE_FIND_CURRENT_PROVIDER_ID = createRequestTypes("@workforce/FIND_CURRENT_PROVIDER_ID");
export const WORKFORCE_FIND_ALL_PROVIDERS = createRequestTypes("@workforce/FIND_ALL_PROVIDERS");
export const WORKFORCE_ALL_PROVIDERS_IDS = createRequestTypes("@workforce/ALL_PROVIDERS_IDS");

//getting providers

export const getMappingSystemProviderById = (mappingProviderId) =>
  httpGetAndDispatchAction(MAPPING_SYSTEM_FIND_PROVIDER_BY_ID, `/mapping-system/${mappingProviderId}`);

export const getMappingSystemCurrentId = () =>
  httpGetAndDispatchAction(MAPPING_SYSTEM_CURRENT_ID, "/mapping-system/current/id", "id");

export const getMappingSystemAllProviders = () =>
  httpGetAndDispatchAction(MAPPING_SYSTEM_ALL_PROVIDERS, "/mapping-systems", "id");

export const getMappingSystemAllProviderIDS = () =>
  httpGetAndDispatchAction(MAPPING_SYSTEM_ALL_PROVIDERS_IDS, "/mapping-systems/id", "ids");

export const getWorkforceProviderByID = (providerId) =>
  httpGetAndDispatchAction(WORKFORCE_FIND_PROVIDER_BY_ID, `/workforce/map/${providerId}`);

export const getWorkforceCurrentProviderID = () =>
  httpGetAndDispatchAction(WORKFORCE_FIND_CURRENT_PROVIDER_ID, "/workforce/map/current/id", "id");

export const getWorkforceAllProviders = () =>
  httpGetAndDispatchAction(WORKFORCE_FIND_ALL_PROVIDERS, "/workforce/maps", "id");

export const getWorkforceAllProvidersIDS = () =>
  httpGetAndDispatchAction(WORKFORCE_ALL_PROVIDERS_IDS, "/workforce/maps/id", "ids");

//clear providers

export const clearWorkforceProviderById = () => dispatchEmptyAction(WORKFORCE_FIND_PROVIDER_BY_ID);

export const clearWorkforceProvidersAll = () => dispatchEmptyAction(WORKFORCE_FIND_ALL_PROVIDERS);

export const clearWorkforceProviderIds = () => dispatchEmptyAction(WORKFORCE_ALL_PROVIDERS_IDS);

export const clearMappingProviderById = () => dispatchEmptyAction(MAPPING_SYSTEM_FIND_PROVIDER_BY_ID);

export const clearMappingProvidersAll = () => dispatchEmptyAction(MAPPING_SYSTEM_ALL_PROVIDERS);

export const clearMappingProviderIds = () => dispatchEmptyAction(MAPPING_SYSTEM_ALL_PROVIDERS_IDS);

// patching providers

export const patchMappingSystem = (mappingProviderId, providerPatch, setCurrent = true) =>
  httpPatchAndDispatchAction(MAPPING_SYSTEM_PATCH_PROVIDER, `/mapping-system/${mappingProviderId}?setCurrent=${setCurrent ? 'true' : 'false'}`, providerPatch);

export const patchWorkforce = (providerId, providerPatch, setCurrent = true) =>
  httpPatchAndDispatchAction(WORKFORCE_PATCH_PROVIDER_ID, `/workforce/map/${providerId}?setCurrent=${setCurrent ? 'true' : 'false'}`, providerPatch);
