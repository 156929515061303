import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import isEmpty from "src/utils/helpers";
import TextInput from "src/components/UI/TextInput";
import { notificationTypeIcons } from "src/containers/Notifications/notificationTypes";

import { hideModal } from "src/actions/modal.actions";
import { forwardNotification } from "src/actions/notifications.actions";
import { getModalData, getUser, getErrorMessage } from "src/selectors";

/**
 * View a notification queue item
 *
 * @param {any*} modalProps.value - Notification queue object
 * @param {(item) => void} modalProps.onSuccess - callback function to be called on successful update, called with updated item
 */
class ForwardNotificationModal extends Component {
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}

  formSchema = () => {
    let validationSchema = {
      receivers: Yup.array(Yup.string().required("Required")).required("Required"),
    };

    return Yup.object().shape(validationSchema);
  };

  hideModal = () => {
    this.props.hideModal();
  };

  formSubmit = async (values, actions) => {
    try {
      const { notificationQueue, afterSubmitted } = this.props.modalProps;
      let addedNotifications = [];
      if (values.receivers.length > 0) {
        const resp = await this.props.forwardNotification(notificationQueue.id, { receivers: values.receivers });
        addedNotifications = resp.data.notificationQueues;
      }
      if (typeof afterSubmitted === "function") {
        afterSubmitted(addedNotifications);
      }
      this.props.hideModal();
    } catch {
      // error will be catched by redux state
    }
  };

  scrollDropdownIntoView = (e) => {
    const elementClicked = e.target.nodeName;
    const boundingElement = e.currentTarget;
    const modalBody = document.getElementsByClassName("modal-body")[0];

    if (elementClicked !== "LABEL") {
      setTimeout(function () {
        // Scroll down if the bottom is hidden...
        if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {
          // ... and the top won't end up hidden by scrolling down
          if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {
            // Scroll down till bottom of element reaches bottom of screen
            boundingElement.scrollIntoView({ block: "end" });
          }
        }
      }, 200);
    }
  };

  render() {
    const { modalProps, alertMessage } = this.props;
    const { notificationQueue } = modalProps;

    return (
      <Fragment>
        <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
          <Formik
            initialValues={{
              receivers: [notificationQueue.receiver],
            }}
            validationSchema={this.formSchema()}
            onSubmit={this.formSubmit}
          >
            {({
              handleChange,
              handleSubmit,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Forward Notification #{notificationQueue.id}</h5>
                      <button onClick={this.hideModal} type="button" className="close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body cmv-form form-horizontal pt-0">
                      <div className="pt-3"></div>
                      {!isEmpty(alertMessage) && (
                        <div className="alert alert-inline alert-danger alert-dismissible">
                          <p className="mb-0">{alertMessage}</p>
                        </div>
                      )}
                      <p>You are about to forward notification:</p>
                      <div className="order-service-order-wrapper">
                        <div>
                          <p className="text-uppercase">
                            {notificationTypeIcons[notificationQueue.type] && (
                              <i className={notificationTypeIcons[notificationQueue.type]}></i>
                            )}
                            {notificationQueue.type}
                          </p>
                          {notificationQueue.type === "EMAIL" ? (
                            <p>
                              <b>Subject:</b> {notificationQueue.subject}
                            </p>
                          ) : (
                            <p>{notificationQueue.text}</p>
                          )}
                        </div>
                      </div>

                      <label>To:</label>
                      <FieldArray
                        name="receivers"
                        render={({ insert, remove, push, replace }) => (
                          <Fragment>
                            {values.receivers.map((receiver, index) => (
                              <div className="d-flex justify-content-between align-items-start">
                                <TextInput
                                  placeholder={values.type === "EMAIL" ? "Receiver's Email Address" : "Receiver"}
                                  name={`receivers.${index}`}
                                  type={values.type === "EMAIL" ? "email" : "text"}
                                  value={receiver}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errors={errors}
                                  touched={touched}
                                />
                                <button
                                  onClick={() => remove(index)}
                                  className="btn btn-danger btn-discount-remove ml-1"
                                  type="button"
                                  disabled={values.receivers.length <= 1}
                                >
                                  <i className="fas fa-trash" />
                                </button>
                              </div>
                            ))}
                            <div className="d-flex justify-content-between">
                              <div></div>
                              <button
                                onClick={() => push("")}
                                className="btn btn-outline-secondary btn-discount-add"
                                type="button"
                              >
                                Add Another
                              </button>
                            </div>
                          </Fragment>
                        )}
                      />
                    </div>
                    <div className="modal-footer">
                      <button onClick={this.hideModal} className="btn" type="button">
                        Cancel
                      </button>
                      <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </Fragment>
    );
  }
}

const getForwardNotificationError = getErrorMessage(["FORWARD_NOTIFICATION"]);

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps,
    forwardError = getForwardNotificationError(state),
    currentUser = getUser(state).user;

  return {
    modalProps,
    alertMessage: forwardError,
    currentUser,
  };
};

const mapDispatchToProps = {
  hideModal,
  forwardNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForwardNotificationModal);
