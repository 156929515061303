export function defineFilter(field, value, op = "eq") {
  return {
    field,
    value,
    op,
  };
}

export function defineSort(field, ascending = true) {
  return {
    field: field,
    asc: ascending,
  };
}

export function defineTableRequest(filters, sort, offset, limit) {
  return {
    filters,
    sort,
    limit,
    offset,
  };
}

export function updateSort(tableRequest, sort) {
  return {
    ...tableRequest,
    sort,
  };
}

export function updatePage(tableRequest, offset, limit) {
  return {
    ...tableRequest,
    offset,
    limit,
  };
}

export function updateFilters(tableRequest, filters) {
  return {
    ...tableRequest,
    filters,
  };
}

export function addFilter(tableRequest, filter) {
  return {
    ...tableRequest,
    filters: [...tableRequest.filters, filter],
  };
}
