import {
	SET_USER_SUMMARY,
	SET_USER_CONFIGURATION,
	SET_USER_LOCATION,
	SET_USER_PERMISSION,
	SET_USER_TAB
} from '../actions/user.actions';
import { getLocation} from '../utils/helpers';

let initialState = {
	user: {
		// locations: []
	},
	currentLocation: {},
	configuration: [],
	userPermission: [],
	activeTab: ''
};

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_SUMMARY.SUCCESS:
			return {
				...state,
				user: action.payload,
				currentLocation: action.payload.locations ? getLocation() || action.payload.locations.map((location) => {
						return {
							id: location.id,
							value: location.name,
							label: location.description,
							main: location.main
						};
					})
					.find((x) => x.main === true) : {}
			};
		case SET_USER_SUMMARY.EMPTY:
			return {
				...state,
				user: {}
			};
		case SET_USER_LOCATION.SUCCESS:
			return {
				...state,
				currentLocation: action.payload
			};
		case SET_USER_CONFIGURATION.SUCCESS:
			return{
				...state,
				configuration: action.payload
			}
		case SET_USER_PERMISSION.SUCCESS:
			return{
				...state,
				userPermission: action.payload
			}
		case SET_USER_PERMISSION.EMPTY:
			return {
				...state,
				userPermission: []
			}
		case SET_USER_TAB.SUCCESS:
			return {
				...state,
				activeTab: action.payload.activeTab
			}
		default:
			return state;
	}
};
