import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Loader from '../../components/Loader';
import { isEmpty } from '../../utils/helpers';
import { showModal } from '../../actions/modal.actions';
import { getAdminLocationsUi } from "../../actions/uiController.actions";
import { downloadLocations } from '../../actions/uiController.actions';
import { getCountryStates } from "../../actions/createAccount.actions";
import {
    getUiController,
    getUserPermission,
    getCreateAccount,
} from "../../selectors";

import CamvioTable from "../../components/UI/CamvioTable/CamvioTable";
import Select from "react-select";
import { groupStyles } from "../../utils/SelectStyles";
import { debounce } from "lodash";
import Button from "../../components/Common/Buttons/Button";
import { toastr } from "react-redux-toastr";

class Locations extends Component {

    state = {
        loadingSearch: true,
        isLoading: false,
        isDownloading: false,
        searchTerm: '',
        oldSearchTerm: '',
        showDownloadDropdown: false,
        tableRequest: {
            filterList: [],
            pagination: {
                limit: 20,
                offset: 0
            },
            sorting: {
                columnName: "l.id",
                sortParam: "asc"
            }
        },
        resultsNumber: 20,
        locationsList: [],
        sortColumn: '',
        sortDirection: '',
        noMoreResults: true,
        showAdditionalSearchOptions: false,
        locationPosFilter: [{ name: "l.posAccount", value: "All", label: "POS or non-POS" }],
        locationStateFilter: [{ name: "l.state", value: "All", label: "All States" }],
    };

    componentDidMount() {
        this.props.getCountryStates();
        this.reloadLocations();
    }

    componentWillUnmount() {
        //  this.props.clearPlans();
    }

    searchLocations = () => {
        let tableRequest = this.state.tableRequest;
        let pagination = tableRequest.pagination;
        pagination.offset = 0;

        this.setState({
            tableRequest: tableRequest,
            loadingSearch: true
        });
        this.reloadLocations(true);
    }

    reloadLocations = (clearList) => {
        if (clearList) {
            let pagination = this.state.tableRequest.pagination;
            pagination.limit += pagination.offset;
            pagination.offset = 0;
            this.setState({
                locationsList: [],
            })
        }
        this.setState({ isLoading: true, loadingSearch: true }, () => {
            this.props.getAdminLocationsUi(this.state.tableRequest).then(response => {
                if (response.rows && !isEmpty(response.rows)) {
                    response.rows.map(plans => {
                        this.state.locationsList.push(plans);
                    })
                    this.checkResultsLength();
                }
                this.setState({ isLoading: false, loadingSearch: false });
            });
        });
    }

    toggleDownloadDropdown = () => {
        this.setState({ showDownloadDropdown: !this.state.showDownloadDropdown });
    }

    sortLocations = (name, order) => {
        let tableRequest = this.state.tableRequest;
        let sorting = tableRequest.sorting;
        let sortDirection = order;
        if (this.state.sortDirection != order) {
            sorting.columnName = name;
            sorting.sortParam = order;
        } else {
            sorting.columnName = name;
            if (sorting.sortParam == "asc") {
                sorting.sortParam = "desc";
                sortDirection = "desc"
            } else {
                sorting.sortParam = "asc";
                sortDirection = "asc"
            }
        }

        //Reset pagination
        let pagination = tableRequest.pagination;
        pagination.limit += pagination.offset;
        pagination.offset = 0;

        this.setState({
            tableRequest: tableRequest,
            loadingSearch: true,
            featurePackageList: [],
            sortColumn: name,
            sortDirection: sortDirection
        });
        this.reloadLocations(true);
    }

    showAdditional = () => {
        let tableRequest = this.state.tableRequest;
        let pagination = tableRequest.pagination;
        pagination.offset = pagination.limit + pagination.offset;
        pagination.limit = parseInt(this.state.resultsNumber);

        this.setState({ tableRequest: tableRequest });
        this.reloadLocations(false);
    }

    checkResultsLength = () => {
        if (this.state.locationsList && this.state.locationsList.length < this.props.locationsSize) {
            this.setState({
                noMoreResults: false
            })
        } else {
            this.setState({
                noMoreResults: true
            })
        }
    }

    handleResultNumberChange = (e) => {
        let { value } = e.target;
        this.setState({
            resultsNumber: value,
        });
    };

    setLocationPosSearch = (value) => {
        this.setState({
            locationPosFilter: [{
                name: "l.posAccount",
                value: value.value,
                label: value.label
            }]
        })
        this.debounceHandleInputChange(value);
    };

    setLocationStateSearch = (value) => {
        this.setState({
            locationStateFilter: [{
                name: "l.state",
                value: value.value,
                label: value.label
            }]
        })
        this.debounceHandleInputChange(value);
    };

    debounceHandleInputChange = event => {
        let name = event.name || event.target.name;
        let value = event.value || event.target.value;
        this.handleInputChange(name, value);
    }

    handleInputChange = debounce((fieldName, value) => {
        let tableRequest = this.state.tableRequest;
        let filterList = tableRequest.filterList;
        let containsFilter = true;

        if (filterList.length == 0) {
            if (isNaN(value)) {
                if (value.replace(/\s/g, '').length != 0) {
                    if (value != "All") {
                        filterList.push({
                            columnName: fieldName,
                            value: value
                        })
                    }
                }
            } else {
                filterList.push({
                    columnName: fieldName,
                    value: value
                })
            }
        } else {
            for (let index = 0; index < filterList.length; index++) {
                if (filterList[index].columnName == fieldName) {
                    containsFilter = true;
                    if (value.length == 0 || value == "All") {
                        filterList.splice(index, 1);
                    } else {
                        filterList[index].value = value;
                    }
                    break;
                } else {
                    containsFilter = false;
                }
            }
        }
        if (!containsFilter) {
            filterList.push({
                columnName: fieldName,
                value: value
            })
        }
        tableRequest.filterList = filterList;

        this.setState({ tableRequest: tableRequest });

    });


    getLocationPosFilterOptions = () => {
        let locationTypes = [
            { name: "l.posAccount", value: "All", label: "POS and non-POS" },
            { name: "l.posAccount", value: "POS", label: "POS" },
            { name: "l.posAccount", value: "non-POS", label: "non-POS" },
        ]
        return locationTypes;
    }

    getLocationStateFilterOptions = () => {
        let locationStates = [
            { name: "l.state", value: "All", label: "All States" },
        ];
        (this.props.countryStates || []).forEach((s) => {
            locationStates.push({ name: "l.state", value: s.abbreviation, label: s.name })
        })
        return locationStates;
    }

    downloadTable = (value) => {
        let tableRequest = this.state.tableRequest;
        tableRequest.pagination = {};

        this.setState({
            tableRequest: tableRequest,
            isDownloading: true
        });

        this.props.downloadLocations(value, this.state.tableRequest).then((response) => {
            if (response && response.status === 201 && response.data) {
                let name = "";
                let link = document.createElement('a');
                let fileUrl = URL.createObjectURL(new Blob([response.data]));
                link.setAttribute('href', fileUrl);
                if (response.headers["content-disposition"] != undefined) {
                    name = response.headers["content-disposition"].split("=")[1];
                } else {
                    let today = new Date()
                    name = "locations_" + today.toISOString().split('T')[0] + "." + value;
                }
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.setState({
                    isDownloading: false,
                    showDownloadDropdown: false
                });
            }
        });
    };

    toggleFilter = () => {
        this.setState({ showAdditionalSearchOptions: !this.state.showAdditionalSearchOptions });
    }


    render() {

        const {
            resultsNumber,
            showDownloadDropdown,
            locationsList,
            isLoading,
            sortColumn,
            sortDirection,
            isDownloading,
            showAdditionalSearchOptions
        } = this.state;

        const {
            locationHeaders,
            canAdd,
        } = this.props;


        return (
            <Fragment>
                <div className="tab-pane camvio-table-search" style={{ borderRadius: '0.5rem' }}>
                    <div className="cmv-container-dashboard-search">
                        <div className="cmv-container-dashboard-filter">
                            <div className="container">
                                <div className="form-row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <input
                                                placeholder={"Name or Description..."}
                                                type="text"
                                                className="form-control"
                                                autoComplete="off"
                                                id="name"
                                                name="l.name"
                                                onChange={this.debounceHandleInputChange}
                                                onKeyPress={(event) => {
                                                    if (event.key === 'Enter') {
                                                        this.searchLocations()
                                                    }
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <Button
                                                    type="button"
                                                    className="btn btn-primary btn-show-cam-search-results"
                                                    disabled={this.state.loadingSearch || isDownloading}
                                                    onClick={() => {
                                                        this.searchLocations();
                                                    }}
                                                >
                                                    <i className="fas fa-search" />
                                                    <span>&nbsp;Search</span>
                                                </Button>
                                            </div>

                                            <div className="input-group-append">
                                                <div className="btn-group">
                                                    <Button
                                                        type="button"
                                                        onClick={this.toggleFilter}
                                                        className="btn btn-outline-secondary"
                                                    >
                                                        {showAdditionalSearchOptions ?
                                                            <i className="fas fa-solid fa-ban" />
                                                            :
                                                            <i className="fas fa-solid fa-filter" />
                                                        }
                                                        <span>&nbsp;Filters</span>
                                                    </Button>

                                                    {canAdd &&
                                                        <Button
                                                            type="button"
                                                            className="btn btn-outline-secondary btn-new-account-wizard"
                                                            onClick={() => this.props.showModal('ADD_LOCATION_MODAL', {
                                                                type: 'create',
                                                                reloadFunction: () => this.reloadLocations(true)
                                                            })}
                                                            disabled={this.state.loadingSearch || isDownloading}
                                                        >
                                                            <i className="fas fa-plus" />
                                                            <span>&nbsp;New Location</span>
                                                        </Button>
                                                    }
                                                    <div className="dropdown">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary dropdown-toggle"
                                                            onClick={this.toggleDownloadDropdown}
                                                            disabled={this.state.loadingSearch || isDownloading}
                                                        >
                                                            <i className="fas fa-download" />
                                                            <span className="camvio-table-search-download">&nbsp;Download</span>
                                                        </button>
                                                        <div
                                                            className={"dropdown-menu dropdown-menu-right" + (showDownloadDropdown && !isDownloading ? " show" : "")}>
                                                            <button
                                                                type="button"
                                                                value={"XLS"}
                                                                className="dropdown-item"
                                                                onClick={(e) => {
                                                                    this.downloadTable("XLS")
                                                                }}
                                                            >
                                                                As XLS
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={(e) => {
                                                                    this.downloadTable("CSV")
                                                                }}
                                                            >
                                                                As CSV
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAdditionalSearchOptions &&
                            <div className="cmv-container-dashboard-filter">
                                <div className="container">
                                    <div className="camvioTable-plan-extra-filters">
                                        <div className="search">
                                            <Select
                                                className="extra-filter-select"
                                                id="serviceModel"
                                                name="it.name"
                                                onChange={this.setLocationPosSearch}
                                                styles={groupStyles}
                                                options={this.getLocationPosFilterOptions()}
                                                value={this.state.locationPosFilter}
                                            />
                                            <span>&nbsp;&nbsp;</span>
                                            <Select
                                                className="extra-filter-select"
                                                id="state"
                                                name="l.state"
                                                onChange={this.setLocationStateSearch}
                                                styles={groupStyles}
                                                options={this.getLocationStateFilterOptions()}
                                                placeholder={"All Statuses"}
                                                value={this.state.locationStateFilter}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {(this.state.loadingSearch || isDownloading) &&
                        <Loader />
                    }

                    {!this.state.loadingSearch && isEmpty(locationsList) &&
                        <div className="container">
                            <p>No data found</p>
                        </div>
                    }
                    {!isEmpty(locationsList) && !this.state.loadingSearch && !isDownloading &&
                        <CamvioTable
                            headers={locationHeaders}
                            rows={locationsList}
                            loader={isLoading}
                            reloadFunction={() => this.reloadLocations(true)}
                            sortFunction={this.sortLocations}
                            showModal={this.props.showModal}
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                        />
                    }
                </div>
                {!this.state.noMoreResults &&
                    <div className="row" style={{ marginTop: '2%' }}>
                        <div className="col-sm-12">
                            <div className="search" style={{ float: "right" }}>
                                <button
                                    className="btn btn-primary"
                                    disabled={this.state.loadingSearch || this.state.noMoreResults || isDownloading}
                                    onClick={this.showAdditional}
                                >
                                    Show Additional
                                </button>
                                &nbsp;
                                &nbsp;
                                <select
                                    className="custom-select"
                                    value={resultsNumber}
                                    onChange={this.handleResultNumberChange}
                                    disabled={this.state.loadingSearch || this.state.noMoreResults || isDownloading}
                                >
                                    <option label="20" value="20" />
                                    <option label="30" value="30" />
                                    <option label="40" value="40" />
                                    <option label="50" value="50" />
                                </select>
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const locationHeaders = getUiController(state).locationsUi.headers,
        locationRows = getUiController(state).locationsUi.rows,
        locationsSize = getUiController(state).locationsUi.size,
        countryStates = getCreateAccount(state).countryStates,
        canAdd = getUserPermission(state, 'ADMN', 'ADMN_LOCATION_ADD');

    // Add new actions to the IF statement in render(), enabling the "ACTIONS" table column for users with rights
    return {
        locationHeaders,
        locationRows,
        locationsSize,
        countryStates,
        canAdd,
    };
};

const mapDispatchToProps = {
    getAdminLocationsUi,
    downloadLocations,
    showModal,
    getCountryStates,
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
