import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Loader from "../../components/Loader";
import {Formik} from "formik";
import isEmpty from "../../utils/helpers";
import TextInput from "../../components/UI/TextInput";
import {hideModal} from "../../actions/modal.actions";
import {getAdmin, getModalData, createLoadingSelector } from "../../selectors";
import { getAutoPayJob, addAutoPayJob, updateAutoPayJob, abortAutoPayJob } from "../../actions/autoPayJobs-actions";
import moment from "moment";
import DatePicker from "react-datepicker";
import * as Yup from "yup";
import {groupStyles} from "src/utils/SelectStyles";
import {getAccounts} from "src/actions/account-search";
import Select from "react-select";

class AutoPayJobModal extends Component {

    state = {
        alertMessage: '',
        autoPayJobsLoader: false,
        autoPayJob: null,
        selectedDate: null,
        allAccountsToggled: true,
        accountIds: [],
        immediateAutoPayToggled: true,
    };

    componentDidMount() {
        const {modalProps} = this.props;
        if(modalProps.type === "edit") {
            this.setState({immediateAutoPayToggled: false})
        }
        if(modalProps.value) {
            this.props.getAutoPayJob(modalProps.value).then((response) => {
                if (response.accounts.length) {
                    let selectedAccounts = response.accounts.map((account) =>
                        account =  {label: account.number + ' - ' + account.name,
                            value: account.id}
                    )
                    this.setState({allAccountsToggled: false, accountIds: selectedAccounts})
                }

                this.setState({
                    autoPayJobsLoader: true,
                    autoPayJob: response,
                    selectedDate: moment(response.scheduledDateString).local().toDate()
                })
            })
        }else{
            this.setState({
                autoPayJobsLoader: true,
                autoPayJob: null
            })
        }
    }


    handleDateTimeChange = (newDateTime) => {
        let today = moment(moment().toDate())
        let roundedUpDate = today.minute() || today.second() || today.millisecond() ? today.add(1, 'hour').startOf('hour') : today.startOf('hour');

        if(moment(newDateTime).toDate().toDateString() === moment().startOf("day").toDate().toDateString() && moment(newDateTime).hours() <= moment().hours()) {
            this.setState({selectedDate: roundedUpDate.toDate()})
            return
        } else if(moment(newDateTime).day() !== moment(this.state.selectedDate).day()) {
            this.setState({selectedDate: moment(newDateTime).startOf("day").toDate()})
            return
        } else {
            this.setState({selectedDate: newDateTime});

        }
    }


    loadAccountInfo = (values, setFieldValue) => (value, option) => {
        if (option && option.action === 'input-change') {
            setFieldValue('selectedAccountsInput', value);


            if (this['timeout']) {
                clearTimeout(this['timeout']);
            }

            this['timeout'] = setTimeout(() => {
                this.props.getAccounts('ACCOUNT NUMBER', value).then(response => {
                    if (response && response.length > 0) {
                        let updatedSelectedAccountsOptions = [];

                        response.map(user => {
                            updatedSelectedAccountsOptions.push({
                                label: user.number + ' - ' + (user.systemUser ? user.systemUser.firstName + ' ' + user.systemUser.lastName : user.name),
                                value: user.id
                            });
                        });

                        this.setState({ selectedAccountsOptions: updatedSelectedAccountsOptions });
                    }
                });
            }, 1000);
        }
    }



    addAccountNumber = (values, setFieldValue) => (options, action) => {
        if (action.action === 'select-option') {
            setFieldValue("selectedAccountsInput", '');
            setFieldValue(
                "accountIds",
                [...values.accountIds, { label: action.option.label, value: action.option.value }]
            );
        }

        if (action.action === 'remove-value') {
            setFieldValue(
                "accountIds",
                [...values.accountIds.filter(option => option.value !== action.removedValue.value)]
            );
        }

    }


    formSubmit = (values, actions) => {
        const { selectedDate, immediateAutoPayToggled } = this.state;
        const submitData = {...values};

        submitData.accountIds = submitData.accountIds.map((account) => account = {id: account.value.toString()})

        if(immediateAutoPayToggled) {
            submitData.scheduledDate = moment().toDate()
        }

        this.setState({alertMessage: ''});
        if ((selectedDate === null) && ((submitData.scheduledDate === null) || (submitData.scheduledDate === ""))) {
            this.setState({alertMessage: "You must select a date and time"});
            actions.setSubmitting(false);
        } else {
            this.setState({alertMessage: ''}, () => {
                const method = this.props.modalProps.type === 'create' ? this.props.addAutoPayJob : this.props.updateAutoPayJob;

                if (selectedDate != null) {
                    submitData.scheduledDate = selectedDate;
                }
                method(submitData).then((response) => {
                    if (!response.data) {
                        actions.setSubmitting(false);
                        this.setState({alertMessage: 'Unknown error. Please try again later'});
                        return;
                    }

                    if (!response.data.success) {
                        actions.setSubmitting(false);
                        this.setState({alertMessage: response.data.message});
                        return;
                    }

                    this.props.hideModal();
                    this.props.modalProps.reloadFunction(true);
                });
            });
        }
    }


    render() {
        const { alertMessage, autoPayJobsLoader, autoPayJob, selectedDate } = this.state;
        const { modalProps, accountLoading } = this.props;
        const filterPassedTime = (time) => {
            const currentDate = new Date();
            const selectedDateTime = new Date(time);
            return currentDate.getTime() < selectedDateTime.getTime();
        };


        if(modalProps.type === "create" || modalProps.type === "edit") {
            return (
                <Fragment>
                    <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                        {!autoPayJobsLoader &&
                            <Loader/>
                        }
                        {autoPayJobsLoader &&
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    id: autoPayJob && autoPayJob.id || null,
                                    scheduledDate: autoPayJob && autoPayJob.scheduledDate || '',
                                    notes: autoPayJob && autoPayJob.notes || "",
                                    createdDate: autoPayJob && autoPayJob.createdDate || null,
                                    createdBy: autoPayJob && autoPayJob.createdBy || null,
                                    selectedAccountsInput: '',
                                    accountIds: autoPayJob && this.state.accountIds || [],
                                    confirmAction:''
                                }}
                                validationSchema={Yup.object().shape({
                                    confirmAction: Yup.string().typeError('Required').required('Required')
                                        .equals(['autopay'], 'Type "autopay" to continue'),
                                    accountIds: !this.state.allAccountsToggled ? Yup.array().min(1) : null
                                })}
                                onSubmit={this.formSubmit}
                                render={({
                                             handleChange,
                                             handleSubmit,
                                             handleBlur,
                                             values,
                                             errors,
                                             touched,
                                             isSubmitting,
                                             setFieldValue
                                         }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">
                                                        {modalProps.type === 'create' ? 'Add AutoPay Job' : 'Edit AutoPay Job'}
                                                    </h5>
                                                    <button onClick={this.props.hideModal} type="button"
                                                            className="close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body form-horizontal">
                                                    {!isEmpty(alertMessage) && (
                                                        <div
                                                            className="alert alert-inline alert-danger alert-dismissible">
                                                            <p className="mb-0">{alertMessage}</p>
                                                        </div>
                                                    )}
                                                    <div className="form-group">
                                                    <span className="h-check" style={{display:'flex'}}>
                                                        <label>Start Immediately&nbsp;</label>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="startImmediateAutopay"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={() => this.setState({immediateAutoPayToggled: !this.state.immediateAutoPayToggled, selectedDate: moment().toDate()})}
                                                                    checked={this.state.immediateAutoPayToggled}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                    </div>
                                                    {!this.state.immediateAutoPayToggled &&
                                                        <>
                                                            <div className="form-group">
                                                                <div className="customDatePickerWidth">
                                                                    <DatePicker
                                                                        className={"form-control text-left" + (touched.error && errors.error ? " is-invalid" : "")}
                                                                        autoFocus={false}
                                                                        shouldCloseOnSelect={true}
                                                                        popperPlacement={'auto'}
                                                                        disabledKeyboardNavigation
                                                                        showTimeSelect
                                                                        placeholderText={"Select Auto Pay Job Scheduled Date and Time"}
                                                                        selected={selectedDate ? Date.parse(selectedDate) : null}
                                                                        onChange={(date) => this.handleDateTimeChange(date)}
                                                                        includeDates={null}
                                                                        includeTimes={null}
                                                                        timeIntervals={60}
                                                                        minDate={moment().toDate()}
                                                                        filterTime={filterPassedTime}
                                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                                    />
                                                                </div>
                                                                {touched.error && errors.error &&
                                                                    <div className="invalid-feedback">{errors.error}</div>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    <div className="form-group">
                                                    <span className="h-check" style={{display:'flex'}}>
                                                        <label>All Accounts&nbsp;</label>
                                                        <div className="form-check checkbox-slider checkbox-slider--b-flat">
                                                            <label>
                                                                <input
                                                                    name="allAccounts"
                                                                    type="checkbox"
                                                                    onBlur={handleBlur}
                                                                    onChange={() => {
                                                                        this.setState({allAccountsToggled: !this.state.allAccountsToggled}, () => {
                                                                            if(this.state.allAccountsToggled) {
                                                                                setFieldValue("accountIds", [])
                                                                                setFieldValue("selectedAccountsInput", "")
                                                                            }
                                                                        })
                                                                    }}
                                                                    checked={this.state.allAccountsToggled}
                                                                />
                                                                <span>&nbsp;</span>
                                                            </label>
                                                        </div>
                                                    </span>
                                                        {!this.state.allAccountsToggled &&
                                                            <div className="form-group">
                                                                <Select
                                                                    id="accountIds"
                                                                    name="accountIds"
                                                                    placeholder="Type account number..."
                                                                    options={this.state.selectedAccountsOptions}
                                                                    inputValue={values.selectedAccountsInput}
                                                                    value={values.accountIds}
                                                                    onInputChange={this.loadAccountInfo(values, setFieldValue)}
                                                                    onChange={this.addAccountNumber(values, setFieldValue)}
                                                                    isMulti
                                                                    isClearable={false}
                                                                    isLoading={accountLoading}
                                                                    isDisabled={isSubmitting}
                                                                    styles={groupStyles}
                                                                />
                                                                {touched.accountIds && errors.accountIds &&
                                                                    <div className="invalid-feedback">At least one account must be selected</div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Type "autopay" to continue</label>
                                                        <input
                                                            name="confirmAction"
                                                            className={"form-control" + (touched.confirmAction && errors.confirmAction ? " is-invalid" : "")}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.confirmAction}
                                                            placeholder="autopay"
                                                            autoComplete="off"
                                                        />
                                                        {touched.confirmAction && errors.confirmAction &&
                                                            <div className="invalid-feedback">{errors.confirmAction}</div>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Note (optional)</label>
                                                        <textarea
                                                            name="notes"
                                                            className={"form-control" + (touched.notes && errors.notes ? " is-invalid" : "")}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.notes}
                                                        />
                                                        {touched.notes && errors.notes &&
                                                            <div className="invalid-feedback">{errors.notes}</div>
                                                        }
                                                    </div>
                                                    {modalProps.type === 'edit' &&
                                                        <>
                                                            <div className="form-group">
                                                                <TextInput
                                                                    label="Id"
                                                                    name="id"
                                                                    value={values.id}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <TextInput
                                                                    label="Created Date"
                                                                    name="createdDate"
                                                                    value={String(values.createdDate.monthValue).padStart(2, '0') + "/" + String(values.createdDate.dayOfMonth).padStart(2, "0") + "/" + values.createdDate.year}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <TextInput
                                                                    label="Created By"
                                                                    name="createdBy"
                                                                    value={values.createdBy}
                                                                    disabled={true}
                                                                />
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                                <div className="modal-footer">
                                                    <button onClick={this.props.hideModal} className="btn"
                                                            type="button">
                                                        Cancel
                                                    </button>
                                                    <button disabled={isSubmitting} type="submit"
                                                            className="btn btn-primary">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            />}}
                    </div>
                    <div className="modal-backdrop show" tabIndex="1"/>
                </Fragment>
            );
        }
        /**
         * If modal.type === "delete" we render this
         */
        else {
            return (
                <>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    {!autoPayJobsLoader &&
                        <Loader/>
                    }
                    {autoPayJobsLoader &&
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Abort AutoPay Job
                                    </h5>
                                    <button onClick={this.props.hideModal} type="button"
                                            className="close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="lead">Are you sure you want to abort this AutoPay Job?</p>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.props.hideModal} className="btn"
                                            type="button">
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary" onClick={() => {
                                       this.props.abortAutoPayJob(modalProps.value).then((response) => {
                                           modalProps.reloadFunction();
                                           this.props.hideModal();
                                       })
                                    }}
                                    >
                                        Abort AutoPay Job
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                    <div className="modal-backdrop show" tabIndex="1"/>
                </>
                )
        }
    }
}

const accountLoader = createLoadingSelector(['ACCOUNT_SEARCH']);

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        accountLoading = accountLoader(state),
        autoPayJob = getAdmin(state).autoPayJob;


    return {
        modalProps,
        accountLoading,
        autoPayJob,
    };
};

const mapDispatchToProps = {
    hideModal,
    addAutoPayJob,
    getAutoPayJob,
    updateAutoPayJob,
    abortAutoPayJob,
    getAccounts
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoPayJobModal);