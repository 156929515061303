import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toastr } from "react-redux-toastr";
import isEmpty from "../../../utils/helpers";
import { hideModal } from '../../../actions/modal.actions';
import { loadBillingJobs, rejectBillingJob } from '../../../actions/billingJobs.actions';
import { getModalData } from '../../../selectors/index';
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

class BillingJobRejectModal extends Component {

    state = {
        alertMessage: '',
    }

    componentWillUnmount() {
    }

    closeModal = () => {
        this.props.hideModal();
        this.props.modalProps.onCloseCallback();
    }


    formSubmit = (values, actions) => {
        const { billingJob } = this.props.modalProps;

        this.props.rejectBillingJob(billingJob.id, values.note)
            .then(data => {
                if (data && data.status === 'TESTBILLING_REJECTED') {
                    toastr.success(`Billing Job #${billingJob.id} has been rejected.`, { timeOut: 2000, position: 'top-center' });
                    this.props.loadBillingJobs();
                    this.props.hideModal();
                } else {
                    this.setState({ alertMessage: `The billing job #${billingJob.id} cannot be rejected. Only billing jobs with the status “Testbilling Finished” are allowed to be rejected` });
                }
            });
    }

    render() {
        const { alertMessage } = this.state;
        const { modalProps } = this.props;
        const billingJob = (modalProps && modalProps.billingJob) || {};

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <Formik
                        initialValues={{
                            action: '',
                            note: '',
                        }}
                        validationSchema={Yup.object().shape({
                            action: Yup.string().typeError('Required').required('Required').equals(["reject"]),
                            note: Yup.string(),
                        })}
                        onSubmit={this.formSubmit}
                        render={({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Reject Billing Job</h5>
                                            <button onClick={this.props.hideModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>

                                        <div className="modal-body form-horizontal">
                                            {!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )}

                                            <p>You are about to reject the billing job #{billingJob.id || "N/A"} with invoice date {billingJob.invoiceDate
                                                ? moment(billingJob.invoiceDate).format("MM/DD/YYYY")
                                                : "N/A"
                                            }.
                                                To continue enter "reject" in the field below.
                                                Please note once the job is rejected there no way to rollback.</p>
                                            <div className="form-group">
                                                <label htmlFor="action">Type "reject" to continue</label>
                                                <input
                                                    name="action"
                                                    className={"form-control text-left" + (touched.action && errors.action ? " is-invalid" : "")}
                                                    placeholder="reject"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.action}
                                                />
                                                {touched.action && errors.action &&
                                                    <div className="invalid-feedback">{errors.action}</div>
                                                }
                                            </div>
                                            <div className="form-group">
                                                <label>Note (optional)</label>
                                                <textarea
                                                    name="note"
                                                    className={"form-control" + (touched.note && errors.note ? " is-invalid" : "")}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.note}
                                                />
                                                {touched.note && errors.note &&
                                                    <div className="invalid-feedback">{errors.note}</div>
                                                }
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <button onClick={this.props.hideModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                Reject Billing Job
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;

    return {
        modalProps,
    };
};

const mapDispatchToProps = {
    hideModal,
    rejectBillingJob,
    loadBillingJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingJobRejectModal);
