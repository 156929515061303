import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { groupStyles } from '../../../../utils/SelectStyles';
import Button from '../../../../components/Common/Buttons/Button';

const option = [
	{ label: 'Serviceline', value: 'SERVICELINE', placeholder: 'Search account by Serviceline' },
	{ label: 'Account Number', value: 'ACCOUNT NUMBER', placeholder: 'Search account by Account Number' },
	{ label: 'Name', value: 'NAME', placeholder: 'Search account by Name' },
	{ label: 'Address', value: 'ADDRESS', placeholder: 'Search account by Address' },
	{ label: 'Equipment', value: 'EQUIPMENT', placeholder: 'Search account by Equipment' },
	{ label: 'Company', value: 'COMPANY', placeholder: 'Search account by Company' },
	{ label: 'Contact number', value: 'CONTACT NUMBER', placeholder: 'Search account by Contact Number' },
	{ label: 'Email', value: 'EMAIL', placeholder: 'Search account by Email' },
	{ label: 'Account Id', value: 'ACCOUNT ID', placeholder: 'Search account by Account Id' },
	{ label: 'Order Id', value: 'ORDER ID', placeholder: 'Search account by Order Id' },
	{ label: 'Service Order Id', value: 'SERVICE ORDER ID', placeholder: 'Search account by Service Order Id' }
];
const SearchBar = ({
   searchAccounts,
   changeSearchType,
   changeTerm,
   term,
   searchType,
   placeholder,
   getCustomersServedHistory,
   history,
   newAccountPermission,
   searchHistoryPermissions,
   isLoading,
   updateFilters,
   accountStatusOptions,
   accountTermsFilterOptions,
   accountTypesFilterOptions
}) => {

	const [showFilters, setShowFilters] = useState(false)
	const [accountType, setAccountType] = useState('')
	const [accountStatus, setAccountStatus] = useState('')
	const [accountTerm, setAccountTerm] = useState('')

	useEffect(() => {
		updateFilters(accountType, accountStatus, accountTerm)
	}, [accountType, accountStatus, accountTerm]);

	const filterOnChangeHandler = (e, type) => {
		if(e) {
			switch(type) {
				case "accountStatus":
					setAccountStatus(e.value)
					break;

				case "accountType":
					setAccountType(e.value)
					break;

				case "accountTerm":
					setAccountTerm(e.value)
					break;
			}

		} else {
			switch (type) {
				case "accountStatus":
					setAccountStatus('')
					break;

				case "accountType":
					setAccountType('')
					break;

				case "accountTerm":
					setAccountTerm('')
					break;

				default:
					setAccountType('')
					setAccountStatus('')
					setAccountTerm('')
			}
		}
	}



	return (
		<div className="cmv-container-dashboard-search">
			<div className="cmv-container-dashboard-filter">
				<div className="container">
					<div className="form-row">
						<div className="col-md-3">

							<Select
								id="searchType"
								name="searchType"
								value={searchType}
								onChange={changeSearchType}
								options={option}
								placeholder="Search"
								styles={groupStyles}
								isDisabled={isLoading}
							/>

						</div>
						<div className="col-md-9">
							<div className="input-group">

								<input
									type="text"
									id="term"
									name="term"
									className="form-control"
									placeholder={placeholder}
									onChange={changeTerm}
									value={term}
									autoComplete="off"
									disabled={isLoading}
									onKeyPress={(event) => {
										if (event.key === 'Enter') {searchAccounts()}
									}}
								/>

								<div className="input-group-append">
									{searchHistoryPermissions &&
										<Button
											type="button"
											className="btn btn-primary btn-show-cam-search-results"
											disabled={isLoading}
											onClick={searchAccounts}
										>
											<i className="fas fa-search" />
											<span>&nbsp;Search</span>
										</Button>
									}
								</div>

								<div className="input-group-append">
									<div className="btn-group">
										<Button
											type="button"
											onClick={() => {
												setAccountStatus('')
												setAccountTerm('')
												setAccountType('')
												setShowFilters(!showFilters)
											}}
											className="btn btn-outline-secondary"
										>
											{showFilters ?
												<i className="fas fa-solid fa-ban" />
												:
												<i className="fas fa-solid fa-filter" />
											}
											<span>&nbsp;Filters</span>
										</Button>
										{searchHistoryPermissions &&
											<Button
												type="button"
												onClick={getCustomersServedHistory}
												className="btn btn-outline-secondary btn-show-cam-search-history"
												disabled={isLoading}
											>
												<i className="fas fa-history" />
												<span>&nbsp;History</span>
											</Button>
										}

										{newAccountPermission &&
											<Button
												type="button"
												onClick={() => history.push('/create-account')}
												className="btn btn-outline-secondary btn-new-account-wizard"
											>
												<i className="fas fa-plus" />
												<span>&nbsp;New Account</span>
											</Button>
										}
									</div>
								</div>
							</div>
						</div>
						{showFilters &&
							<>
								<div className='col-md-3' style={{paddingTop: '15px'}}>
									<Select
										className="extra-filter-select"
										onChange={(e) => filterOnChangeHandler(e, "accountStatus")}
										styles={groupStyles}
										options={accountStatusOptions.map((option, index) => option = {label: option, value: option, placeholder: option})}
										placeholder={"Account Status"}
										value={accountStatus.length !== 0 ? {label: accountStatus, value: accountStatus} : ''}
										isClearable={accountStatus.length}
									/>
								</div>
								<div className='col-md-3' style={{paddingTop: '15px', color:'black'}}>
									<Select
										className="extra-filter-select"
										onChange={(e) => filterOnChangeHandler(e, "accountType")}
										styles={groupStyles}
										options={accountTypesFilterOptions.map((option) => option = {label: option.description, placeholder: option.description, value: option.name})}
										placeholder={"Account Type"}
										value={accountType.length !== 0 ? {label: accountType, value: accountType} : ''}
										isClearable={true}
									/>
								</div>
								<div className='col-md-3' style={{paddingTop: '15px', color:'black'}}>
									<Select
										className="extra-filter-select"
										onChange={(e) => filterOnChangeHandler(e, "accountTerm")}
										styles={groupStyles}
										options={accountTermsFilterOptions.map((option) => option = {label: option.description, placeholder: option.description, value: option.name})}
										placeholder={"Account Terms"}
										value={accountTerm.length !== 0 ? {label: accountTerm, value: accountTerm} : ''}
										isClearable={true}
									/>
								</div>
							</>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

SearchBar.propTypes = {
	searchAccounts: PropTypes.func,
	changeSearchType: PropTypes.func,
	changeTerm: PropTypes.func,
	searchType: PropTypes.object,
	term: PropTypes.string,
	placeholder: PropTypes.string,
	getCustomersServedHistory: PropTypes.func,
	history: PropTypes.object,
	newAccountPermission: PropTypes.bool
};

export default SearchBar;