import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes, getErrorMessage } from '../utils/helpers';

export const GET_ALLOWED_BILL_CYCLES = createRequestTypes('GET_ALLOWED_BILL_CYCLES');
export const LOAD_BILLING_JOBS = createRequestTypes('LOAD_BILLING_JOBS');
export const LOAD_BILLING_JOB = createRequestTypes('LOAD_BILLING_JOB');
export const LOAD_BILLING_JOB_STATUS_SUMMARY = createRequestTypes('LOAD_BILLING_JOB_STATUS_SUMMARY');
export const LOAD_BILLING_JOB_STATUS_DETAILS = createRequestTypes('LOAD_BILLING_JOB_STATUS_DETAILS');
export const CREATE_BILLING_JOB = createRequestTypes('CREATE_BILLING_JOB');
export const GET_BILLING_JOB_AFFECTED_ACCOUNTS = createRequestTypes('GET_BILLING_JOB_AFFECTED_ACCOUNTS');
export const REJECT_BILLING_JOB = createRequestTypes('REJECT_BILLING_JOB');


export const getAllowedBillCycles = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_ALLOWED_BILL_CYCLES));
	try {
        const response = await axiosInstance.get(`/billing-jobs/allowed-bill-cycles`);
		dispatch(actionCreator.success(GET_ALLOWED_BILL_CYCLES, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(GET_ALLOWED_BILL_CYCLES, getErrorMessage(err)));
		return err.response.data;
	}
}

export const loadBillingJobs = () => async (dispatch) => {
	dispatch(actionCreator.request(LOAD_BILLING_JOBS));
	try {
        const response = await axiosInstance.get(`/billing-jobs/list`);
		dispatch(actionCreator.success(LOAD_BILLING_JOBS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(LOAD_BILLING_JOBS, getErrorMessage(err)));
		return err.response.data;
	}
}

export const clearBillingJobs = () => async (dispatch) => {
	dispatch(actionCreator.clear(LOAD_BILLING_JOBS))
}

export const loadBillingJob = (billingJobId) => async (dispatch) => {
	dispatch(actionCreator.request(LOAD_BILLING_JOB));
	try {
		const response = await axiosInstance.get(`/billing-jobs/${billingJobId}`);
		dispatch(actionCreator.success(LOAD_BILLING_JOB, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(LOAD_BILLING_JOB, getErrorMessage(err)));
		return err.response.data;
	}
}

export const loadBillingJobStatusSummary = (billingJobId) => async (dispatch) => {
	dispatch(actionCreator.request(LOAD_BILLING_JOB_STATUS_SUMMARY));
	try {
		const response = await axiosInstance.get(`/billing-jobs/${billingJobId}/status-summary`);
		dispatch(actionCreator.success(LOAD_BILLING_JOB_STATUS_SUMMARY, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(LOAD_BILLING_JOB_STATUS_SUMMARY, getErrorMessage(err)));
		return err.response.data;
	}
}

export const loadBillingJobStatusDetails = (billingJobId, billingStep) => async (dispatch) => {
	dispatch(actionCreator.request(LOAD_BILLING_JOB_STATUS_DETAILS));
	try {
		const response = await axiosInstance.get(`/billing-jobs/${billingJobId}/status-details/${billingStep}`);
		dispatch(actionCreator.success(LOAD_BILLING_JOB_STATUS_DETAILS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(LOAD_BILLING_JOB_STATUS_DETAILS, getErrorMessage(err)));
		return err.response.data;
	}
}

export const createBillingJob = (values) => async (dispatch) => {
	dispatch(actionCreator.request(CREATE_BILLING_JOB));
	try {
		const response = await axiosInstance.post(`/billing-job`, values);
		dispatch(actionCreator.success(CREATE_BILLING_JOB, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_BILLING_JOB, getErrorMessage(err)));
		return err.response.data;
	}
}

export const getAffectedAccountsByBillingJob = (values) => async (dispatch) => {
	dispatch(actionCreator.request(GET_BILLING_JOB_AFFECTED_ACCOUNTS));
	try {
		const response = await axiosInstance.post(`/billing-job/affected-accounts`, values);
		dispatch(actionCreator.success(GET_BILLING_JOB_AFFECTED_ACCOUNTS, response.data));
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(GET_BILLING_JOB_AFFECTED_ACCOUNTS, getErrorMessage(err)));
		return err.response.data;
	}
}

export const rejectBillingJob = (billingJobId, note) => async (dispatch) => {
	dispatch(actionCreator.request(REJECT_BILLING_JOB));
	try {
		let action = {
			action: 'REJECT',
			note
		}
		const response = await axiosInstance.post(`/billing-jobs/${billingJobId}`, action);
		dispatch(actionCreator.success(REJECT_BILLING_JOB, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something went wrong'}
		dispatch(actionCreator.failure(REJECT_BILLING_JOB, getErrorMessage(err)));
		return err.response.data;
	}
}

