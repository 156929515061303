import React, { useContext } from "react";

const CamvioTable2Context = React.createContext({
  resultsNumber: 20,
  sortColumn: "",
  sortDirection: "",
  searchTerm: "",
  oldSearchTerm: "",
  showAdditionalSearchOptions: false,
  showDownloadDropdown: false,

  filters: {},
  itemsList: [],

  loadingSearch: true,
  noMoreResults: true,
  isDownloading: false,
});

const useCamvioTable2Context = () => useContext(CamvioTable2Context);

export default CamvioTable2Context;
export { useCamvioTable2Context };
