import React, {Component} from 'react';
import moment from "moment";
import ReactHtmlParser from 'react-html-parser';
import ServiceWrapper from './ServiceWrapper';
import {getAccountDetails} from "../../../selectors";
import {connect} from "react-redux";

class ServiceOrder extends Component {

	state = {
		showPlanNote: false,
	};

	handleShowHidePlanNote = () => {
		this.setState((prevState) => ({
			showPlanNote: !prevState.showPlanNote
		}));
	};

	render() {

		const {
			showPlanNote,
		} = this.state;

		const {
			serviceInfo,
			changeServiceLineLoading,
		} = this.props;

		return (
			<div className="plan-wrapper plan-wrapper-expanded">
				<div className="plan-header">
					<span className="plan-title">
						<i className={serviceInfo.serviceModelIcon} /> {serviceInfo.planDescription}
						<p className="plan-subtitle plan-subtitle-aligned">
							<span>
								{serviceInfo.startDate && "Started "+moment(serviceInfo.startDate).format('MM/DD/YYYY')}
							</span>
							<br/>
							{serviceInfo.address && serviceInfo.address}
						</p>
					</span>
					<span className="plan-toolbar">
						{serviceInfo.additionalLines + serviceInfo.requiredLines > 1 && (
						<span className="badge badge-outline-secondary">
							{serviceInfo.requiredLines} &#9679;{' '}
							{serviceInfo.additionalLines + serviceInfo.requiredLines} Max
						</span>
						)}
						<span className="badge badge-outline-success">{serviceInfo.planCategory}</span>
						{serviceInfo.planType !== "POSTPAID" &&
						<span className="badge badge-outline-info">{serviceInfo.planType}</span>
						}
						<span>
							<button
								type="button"
								onClick={this.handleShowHidePlanNote}
								className="btn btn-outline-secondary btn-sm btn-plan-details-toggler"
							>
								<i className="fas fa-info" />
							</button>
						</span>
					</span>
				</div>
				<div className="plan-body">

					<div className={showPlanNote ? 'plan-details' : 'plan-details collapse'}>
						<div className="plan-description">{ReactHtmlParser(serviceInfo.planNote)}</div>
					</div>

					<div className="services-wrapper">

						<ServiceWrapper
							key={serviceInfo.serviceLineId}
							service={serviceInfo}
							planId={serviceInfo.planId}
							toggleLine={this.props.toggleLine}
							serviceLinesCount={1}
							changeServiceLineLoading={changeServiceLineLoading}
						/>

					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const accountInfo = getAccountDetails(state).accountDetails.accountInfo;

	return {
		accountInfo,
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrder);
