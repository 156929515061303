import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Loader from "../../components/Loader";
import { isEmpty } from "../../utils/helpers";
import { showModal } from "../../actions/modal.actions";
import {
  getNotifications,
  downloadNotifications,
  getNotificationCategories,
  deleteNotification,
} from "../../actions/notifications.actions";
import { selectNotificationsState, getUserPermission } from "../../selectors";

import CamvioTable2, {
  CamvioTable2Context,
  LoadMore,
  DefaultDashboardSearch,
  defineActionButton,
  utils,
  ExpandableContent,
} from "../../components/UI/CamvioTable2";
import Select from "react-select";
import { groupStyles } from "../../utils/SelectStyles";
import NotificationTemplatesList from "./NotificationTemplatesList";

/**
 * Actual content of the Notification s management page
 */
class NotificationsList extends Component {
  state = {
    isLoading: false, // loading data?
    offset: 0,
    pageSize: 50,
    sortField: "",
    sortDirection: "",
    itemsList: [],
    itemsTotal: 0,
    loadingSearch: true,
    noMoreResults: true,
    isDownloading: false,

    // filters
    searchTerm: "",
    notificationStatusFilter: null,
    notificationCategoryFilter: null,
  };

  componentDidMount() {
    (!this.props.notificationCategories || this.props.notificationCategories.length === 0) &&
      this.props.getNotificationCategories();

    this.clearAndReloadList();
  }

  componentWillUnmount() {}

  getTableRequest(offsetOverride, limitOverride) {
    let filters = [];
    if (this.state.searchTerm && this.state.searchTerm.trim())
      filters.push(utils.defineFilter("keyword", this.state.searchTerm.trim(), "like"));
    if (this.state.notificationCategoryFilter)
      filters.push(utils.defineFilter("notification_category_id", this.state.notificationCategoryFilter.value));
    if (this.state.notificationStatusFilter)
      filters.push(utils.defineFilter("notification_is_active", this.state.notificationStatusFilter.value));

    let sort = null;
    if (this.state.sortField && this.state.sortDirection) {
      sort = utils.defineSort(this.state.sortField, this.state.sortDirection === "asc");
    }

    return utils.defineTableRequest(
      filters,
      sort,
      offsetOverride !== undefined ? offsetOverride : this.state.offset,
      limitOverride !== undefined ? limitOverride : this.state.pageSize
    );
  }

  clearAndReloadList = () => {
    this.setState(
      {
        offset: 0,
        itemsList: [],
        loadingSearch: true,
      },
      () => this.reloadList(true)
    );
  };

  reloadList = (clearList) => {
    let tableRequest = this.getTableRequest();
    if (clearList) {
      tableRequest = this.getTableRequest(0, this.state.pageSize + this.state.offset);
    }
    this.setState({ isLoading: true, loadingSearch: true, itemsList: clearList ? [] : this.state.itemsList }, () => {
      this.props.getItems(tableRequest).then((response) => {
        if (response.data.notifications && !isEmpty(response.data.notifications)) {
          response.data.notifications.forEach((item) => {
            this.state.itemsList.push(item);
          });

          // const itemsList = [
          //   ...this.state.itemsList,
          //   ...response.data.notifications,
          // ];
          this.setState(
            {
              itemsList: this.state.itemsList,
              itemsTotal: response.data.total || 0,
            },
            () => this.checkResultsLength()
          );
        }
        this.setState({ isLoading: false, loadingSearch: false });
      });
    });
  };

  sortList = (field, dir = null) => {
    if (field === null) dir = this.state.sortDirection === "asc" ? "desc" : "asc";

    this.setState(
      {
        sortField: field,
        sortDirection: dir,
      },
      () => this.clearAndReloadList()
    );
  };

  loadMore = () => {
    this.setState({ offset: +this.state.offset + +this.state.pageSize }, () => {
      this.reloadList(false);
    });
  };

  checkResultsLength = () => {
    if (this.state.itemsList && this.state.itemsList.length < this.state.itemsTotal) {
      this.setState({
        noMoreResults: false,
      });
    } else {
      this.setState({
        noMoreResults: true,
      });
    }
  };

  handlePageSizeChange = (value) => {
    this.setState({
      pageSize: value,
    });
  };

  setNotificationCategoryFilter = (value) => {
    this.setState(
      {
        notificationCategoryFilter: value,
      },
      () => this.clearAndReloadList()
    );
  };

  setNotificationStatusFilter = (value) => {
    this.setState(
      {
        notificationStatusFilter: value,
      },
      () => this.clearAndReloadList()
    );
  };

  debounceHandleInputChange = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  downloadTable = (value) => {
    const tableRequest = this.getTableRequest(0, 0);
    this.setState({
      isDownloading: true,
    });

    this.props.downloadItems(value, tableRequest).finally(() => {
      this.setState({
        isDownloading: false,
      });
    });
  };

  onDashboardAction = (action) => {
    switch (action) {
      case "SEARCH":
        this.clearAndReloadList();
        break;
      case "ADD":
        this.props.showModal("EDIT_NOTIFICATION_MODAL", {
          value: null,
          notificationCategories: this.props.notificationCategories,
          reloadFunction: () => this.reloadList(true),
          onSuccess: () => this.reloadList(true),
        });
        break;
      case "XLS":
        this.downloadTable("XLS");
        break;
      case "CSV":
        this.downloadTable("CSV");
        break;
      default:
        break;
    }
  };

  /**
   * Called after successful item status update on the "Edit Item status" modal
   * @param {*} item
   * @param {*} values
   */
  replaceItem = (item) => {
    let itemsList = [...this.state.itemsList];
    let idx = itemsList.findIndex((i) => item.id === i.id);

    if (idx >= 0) {
      itemsList[idx] = item;
    }

    this.setState({
      itemsList: itemsList,
    });
  };

  /**
   * Called after successful item status update on the "Edit Item status" modal
   * @param {*} item
   * @param {*} values
   */
  replaceTemplate = (notification, template) => {
    const templates = notification.notificationTemplates || [];
    let idx = templates.findIndex((i) => template.id === i.id);

    if (idx >= 0) {
      templates[idx] = template;
    } else {
      templates.push(template);
      notification.templatesCount++;
    }

    notification.notificationTemplates = templates;
    this.replaceItem(notification);
  };

  addNotificationTemplate = (notification) => {
    this.props.showModal("EDIT_NOTIFICATION_TEMPLATE_MODAL", {
      notification: notification,
      value: null,
      onSuccess: (template) => this.replaceTemplate(notification, template),
    });
  };

  editItem = (item) => {
    this.props.showModal("EDIT_NOTIFICATION_MODAL", {
      value: item,
      notificationCategories: this.props.notificationCategories,
      onSuccess: (item) => {
        this.replaceItem(item);
      },
    });
  };

  deleteItem = (item) => {
    this.props.showModal("CONFIRM_AND_ACTION_MODAL", {
      title: "Deleting Notification Template",
      message: <p>Are you sure to delete this notification?</p>,
      action: async () => {
        await this.props.deleteNotification(item.id);
        const itemsList = [...this.state.itemsList];
        itemsList.splice(itemsList.indexOf(item), 1);
        this.setState({
          itemsList,
        });
      },
    });
  };

  render() {
    const { pageSize, itemsList, isLoading, sortField, sortDirection, isDownloading } = this.state;

    const { notificationCategories } = this.props;

    const notificationCategoryOptions = (notificationCategories || []).map((p) => ({
      name: "notification_category_id",
      value: p.id,
      label: p.name,
    }));

    const notificationStatusOptions = [
      {
        name: "notification_is_active",
        value: "TRUE",
        label: "Active Notifications",
      },
      {
        name: "notification_is_active",
        value: "FALSE",
        label: "Inactive Notifications",
      },
    ];

    return (
      <Fragment>
        <CamvioTable2Context.Provider value={this.state}>
          <div className="tab-pane camvio-table-search" style={{ borderRadius: "0.5rem" }}>
            <DefaultDashboardSearch
              onAction={this.onDashboardAction}
              onInputChange={this.debounceHandleInputChange}
              disabled={isLoading || this.state.loadingSearch || isDownloading}
              actionButtons={{
                secondary: [defineActionButton("ADD", "New Notification", "fa-plus", !this.props.canAdd)],
              }}
            >
              <div className="search">
                <Select
                  className="extra-filter-select"
                  styles={groupStyles}
                  placeholder={"All Categories"}
                  options={notificationCategoryOptions}
                  value={this.state.notificationCategoryFilter}
                  onChange={this.setNotificationCategoryFilter}
                  isClearable={true}
                  clearValue={() => this.setNotificationCategoryFilter(null)}
                />
                <span>&nbsp;&nbsp;</span>
                <Select
                  className="extra-filter-select"
                  onChange={this.setNotificationStatusFilter}
                  styles={groupStyles}
                  placeholder={"All Statuses"}
                  options={notificationStatusOptions}
                  value={this.state.notificationStatusFilter}
                  isClearable={true}
                  clearValue={() => this.setNotificationStatusFilter(null)}
                />
              </div>
            </DefaultDashboardSearch>

            {isDownloading && <Loader />}

            {!this.state.loadingSearch && isEmpty(itemsList) && (
              <div className="container">
                <p>No data found</p>
              </div>
            )}
            {!isEmpty(itemsList) && !isDownloading && (
              <CamvioTable2
                headerDefs={[
                  { name: "Name", field: "notification_name", sortable: true },
                  { name: "Status", field: "notification_is_active", sortable: true },
                  { name: "Category", field: "notification_category", sortable: true },
                  { name: "Action", field: "", sortable: false },
                ]}
                sortFunction={this.sortList}
                sortField={sortField}
                sortDirection={sortDirection}
              >
                <tbody>
                  {this.state.itemsList.map((item, index) => (
                    <ExpandableContent key={`item_${item.id}`}>
                      {({ expanded, setExpanded }) => (
                        <Fragment>
                          <tr>
                            <td>
                              <span>{item.name}</span>
                              <span className="td-subtitle">{item.description}</span>
                            </td>
                            <td>
                              <span className={"badge" + (item.isActive ? " badge-green" : " badge-secondary")}>
                                {item.isActive ? "Active" : "Inactive"}
                              </span>
                              <span className="td-subtitle">{`${item.templatesCount} Template${
                                item.templatesCount > 1 ? "s" : ""
                              }`}</span>
                            </td>
                            <td>
                              {item.notificationCategory && (
                                <span title={item.notificationCategory.description}>
                                  {item.notificationCategory.name}
                                </span>
                              )}
                            </td>
                            <td>
                              <div className="tr-actions">
                                {this.props.canAddTemplate && (
                                  <button
                                    className="btn"
                                    type="button"
                                    title="Add a new Template"
                                    onClick={() => this.addNotificationTemplate(item)}
                                  >
                                    <i className="fas fa-plus-circle"></i>
                                  </button>
                                )}
                                {this.props.canEdit && (
                                  <button
                                    className="btn"
                                    type="button"
                                    title="Edit"
                                    onClick={() => this.editItem(item)}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </button>
                                )}
                                {/* {this.props.canDelete && (
                                  <button
                                    className="btn"
                                    type="button"
                                    title="Delete Notification"
                                    onClick={() => this.deleteItem(item)}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                )} */}
                                {(this.props.canViewTemplate || this.props.canEditTemplate) && (
                                  <button
                                    className="btn"
                                    type="button"
                                    title="View Templates"
                                    disabled={!item.templatesCount}
                                    onClick={() => setExpanded(!expanded)}
                                  >
                                    <i
                                      className={`fas ${expanded ? "fa-angle-double-up" : "fa-angle-double-down"}`}
                                    ></i>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                          {expanded && (
                            <tr>
                              <td colSpan="4" className="pl-5">
                                <NotificationTemplatesList
                                  notification={item}
                                  onTemplateUpdated={(template, notification) => {
                                    this.replaceItem(notification);
                                  }}
                                />
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      )}
                    </ExpandableContent>
                  ))}
                </tbody>
              </CamvioTable2>
            )}
          </div>
          {(isLoading || this.state.loadingSearch) && <Loader />}
          {!this.state.noMoreResults && (
            <LoadMore
              disabled={this.state.loadingSearch || this.state.noMoreResults || isDownloading}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => {
                this.handlePageSizeChange(pageSize);
              }}
              onLoadMore={() => this.loadMore()}
            />
          )}
        </CamvioTable2Context.Provider>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const notifications = selectNotificationsState(state).notifications,
    notificationCategories = selectNotificationsState(state).notificationCategories,
    canView = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_DETAIL"),
    canAdd = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_ADD"),
    canEdit = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_EDIT"),
    canDelete = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_DEL"),
    canViewTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_TEMPLATE_READ"),
    canAddTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_TEMPLATE_ADD"),
    canEditTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_TEMPLATE_EDIT");

  // Add new actions to the IF statement in render(), enabling the "ACTIONS" table column for users with rights
  return {
    items: notifications,
    notificationCategories: notificationCategories,
    canView,
    canAdd,
    canEdit,
    canDelete,
    canViewTemplate,
    canAddTemplate,
    canEditTemplate,
  };
};

const mapDispatchToProps = {
  showModal,
  getItems: getNotifications,
  downloadItems: downloadNotifications,
  getNotificationCategories: getNotificationCategories,
  deleteNotification: deleteNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
