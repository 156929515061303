import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	getTasks,
	getFilteredTasks,
	emptyTasks,
	getSystemUsers,
	emptySystemUsers,
	assignTask,
	unassignTask
} from '../../../../actions/dashboardActions/tasks.actions';
import {getDashboardData, createLoadingSelector, getUserPermission, getTaskDetails} from '../../../../selectors';
import { isEmpty } from '../../../../utils/helpers';
import TasksResults from '../Tasks/TasksResults'
import moment from "moment";
import {getFormattedFilters} from '../Tasks/utils'
import PersonalTasksResults from "./PersonalTasksResults";
import PersonalTaskDetails from "./PersonalTaskDetails";
import {getTaskAttributes} from "../../../../actions/taskDetails.actions";
import {getTroubleTickets} from "../../../../actions/accountDetailsActions/troubleTickets.actions";
import {getTasksByTroubleTicketId} from "../../../../actions/tasksList.actions";


class PersonalTasks extends Component {

	state = {
		userTasksDataSorted: [],
		rowClasses: [],
		searchType: {
			value: this.props.username,
			label: 'My Tasks',
			placeholder: ''
		},
		filters: {
			myTasks: '',
			myGroupTasks: '',
			unclaimedTask: '',
			createdBy: '',
			fromDate: '',
			toDate: '',
			limit: 20,
			taskId: '',
			searchId: '',
			complete: false
		},
		filterType: 'limit',
		filterValue: '20',
		filterButtonText: "Last 20 Tasks",
		filterButtonTextAdditional: "",
		isFilterDropDownOpen: false,
		isFetched: false,
		showTaskDropDownById: '',
	};

	componentDidMount() {
		const { history, user, username, appointmentSelectedData, userTroubleTicketTasksData } = this.props;
		this.setState({filters: {...this.state.filters, searchId: this.props.orderId}})
		let updatedFilter = {...this.state.filters}
		this.setState({filters: updatedFilter})
		if(userTroubleTicketTasksData.length > 0) {
			this.setState({userTasksDataSorted: userTroubleTicketTasksData})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {userTasksData, appointmentSelectedData, tasksListData, taskId} = this.props;

		// If tasks have changed
		if (prevProps.userTasksData !== userTasksData || prevProps.userTroubleTicketTasksData !== this.props.userTroubleTicketTasksData) {
			let userTasksDataSorted
			if(this.props.userTroubleTicketTasksData.length > 0) {
				userTasksDataSorted = this.props.userTroubleTicketTasksData
			} else {
				userTasksDataSorted = userTasksData.filter((task) => !task.hasOwnProperty('endDateTime'));

			}

			if(userTasksDataSorted.length === 1) {
				this.setState({taskId: userTasksDataSorted[0].id})
				this.props.getTaskAttributes(userTasksDataSorted[0].id)
			}

			if(appointmentSelectedData.type === "order") {
				userTasksDataSorted = userTasksDataSorted.filter((task) => !task.hasOwnProperty('troubleTicketDetails')).filter((task) =>
					task.serviceOrderInfo.orderId === parseInt(appointmentSelectedData.id));
			} else {
				userTasksDataSorted = userTasksDataSorted.filter((task) => !task.hasOwnProperty('serviceOrderInfo')).filter((task) =>
					task.troubleTicketDetails.id === parseInt(appointmentSelectedData.id))
			}

			// Compare every Task with the next Tasks
			userTasksDataSorted.map((task, i) => userTasksDataSorted.map((task, j) => { if (j > i) {

				// If both Tasks belong to the same Order
				if (
					!isEmpty(userTasksDataSorted[i].serviceOrderInfo) && !isEmpty(userTasksDataSorted[j].serviceOrderInfo) &&
					userTasksDataSorted[i].serviceOrderInfo.orderId === userTasksDataSorted[j].serviceOrderInfo.orderId
				) {

					// Copy second Task after the first and delete the original
					userTasksDataSorted.splice(i + 1, 0, userTasksDataSorted[j]);
					userTasksDataSorted.splice(j + 1, 1);
				}
			}}));

			// Generate table row striped classes, starting with darker
			let rowClasses = ['tr-dark'];
			let darkRow = true;

			// Go through all following tasks
			userTasksDataSorted.map((task, i) => { if (i > 0) {

				// If two adjacent Tasks belong to the same Order
				if (
					!isEmpty(userTasksDataSorted[i].serviceOrderInfo) && !isEmpty(userTasksDataSorted[i - 1].serviceOrderInfo) &&
					userTasksDataSorted[i].serviceOrderInfo.orderId === userTasksDataSorted[i - 1].serviceOrderInfo.orderId
				) {

					// Update previous task class
					rowClasses[i - 1] = 'tr-grouped ' + rowClasses[i - 1];

					// Use the same background and lighter border
					rowClasses.push((darkRow ? 'tr-grouped tr-dark ' : '') + 'tr-grouped tr-border');
				}
				// Otherwise - alternate the background
				else {
					darkRow = !darkRow;
					rowClasses.push((darkRow ? 'tr-dark ' : ''));
				}
			}});
			this.setState({
				userTasksDataSorted: userTasksDataSorted,
				rowClasses: rowClasses,
				isFetched: true
			});
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	closeFilterDropdown = () => {
		this.setState({isFilterDropDownOpen: false});
	}

	handleShowTaskDetails = (taskInfo) => {
		const {history} = this.props;
		let serviceOrderId = taskInfo.serviceOrderInfo && taskInfo.serviceOrderInfo.serviceOrderId;

		let activeTile = {
			tile: 'myday',
			activeFilters: {
				searchType: this.state.searchType,
				filters: this.state.filters,
				filterType: this.state.filterType,
				filterValue: this.state.filterValue
			},
			scrollPosition: document.body.scrollTop
		};

		// Edit history entry to enable back button functionality
		let state = { activeTile, serviceOrderId };
		history.replace({ ...history.location, state });

		// Show Task Details
		history.push('/task-details/'+taskInfo.id, { activeTile, serviceOrderId });
	};

	handleScrollToPreviousPosition = () => {
		const { history } = this.props;

		if (history.location.state && history.location.state.activeTile && history.location.state.activeTile.scrollPosition !== undefined) {
			document.body.scrollTo({top: history.location.state.activeTile.scrollPosition, behavior: 'smooth'});

			// Clear stored search and scroll position
			let state = undefined;
			history.replace({ ...history.location, state });
		}
	}

	handleClickDropdown = () => {
		let updateFilter = { ...this.state.filters };

		if (this.state.filterType !== 'dateFromTo') {
			updateFilter['fromDate'] = '';
			updateFilter['toDate'] = '';
		}

		this.setState((prevState) => ({
			filters: updateFilter,
			isFilterDropDownOpen: !prevState.isFilterDropDownOpen,
		}));
	};

	handleLimitFilter = (limit) => {

		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = limit;
		updateFilter['fromDate'] = '';
		updateFilter['toDate'] = '';

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'limit',
				filterValue: limit,
				isFetched: false
			}, () => {
				this.renderButtonText()
				this.getTasksByFilters()
			}
		);
	};

	handleLastDaysFilter = (lastDays) => {
		let fromDate = moment().subtract(lastDays, 'days').toDate();
		let toDate = moment().toDate();

		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = '';
		updateFilter['fromDate'] = fromDate;
		updateFilter['toDate'] = toDate;

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'lastDays',
				filterValue: lastDays,
				isFetched: false
			}, () => {
				this.renderButtonText()
				this.getTasksByFilters()
			}
		);
	};

	handleDayFilter = (value) => {
		let fromDate, toDate = '';

		if (value === 'today') {
			fromDate = moment().toDate();
			toDate = moment().toDate();
		}
		else if (value === 'tomorrow'){
			fromDate = moment().add(1,'days').toDate();
			toDate = moment().add(1,'days').toDate();
		}

		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = '';
		updateFilter['fromDate'] = fromDate;
		updateFilter['toDate'] = toDate;

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: value,
				filterValue: value,
				isFetched: false
			}, () => {
				this.renderButtonText()
				this.getTasksByFilters()
			}
		);
	};

	handleDateFilter = (dates) => {
		const [startDate, endDate] = dates;

		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = '';

		updateFilter['fromDate'] = startDate;
		updateFilter['toDate'] = endDate;

		// Update Dates
		this.setState({
				filters: updateFilter,
				filterType: 'dateFromTo',
			}, () => {

				// If both Start and End dates are selected
				if (startDate && endDate) {

					// Close Dropdown
					this.setState({
						isFilterDropDownOpen: false,
						isFetched: false,
					});

					this.renderButtonText()
					this.getTasksByFilters()
				}
			}
		);
	};

	renderButtonText = () => {
		const { filters, filterType, filterValue } = this.state;

		let filterButtonText = "";
		let filterButtonTextAdditional = "";

		if (filterType === 'dateFromTo') {
			filterButtonText = filters.fromDate && moment(filters.fromDate).format('MM/DD/YYYY');
			if (filters.fromDate.getTime() !== filters.toDate.getTime()) {
				filterButtonTextAdditional = filters.toDate && moment(filters.toDate).format('MM/DD/YYYY');
			}
		} else if (filterType === 'limit') {
			filterButtonText = `Last ${filterValue} Tasks`;
		} else if (filterType === 'lastDays') {
			filterButtonText = `Last ${filterValue} Days`;
		} else if (filterType === 'today') {
			filterButtonText = `Today`;
		} else if (filterType === 'tomorrow') {
			filterButtonText = `Tomorrow`;
		}

		this.setState({
			filterButtonText: filterButtonText,
			filterButtonTextAdditional: filterButtonTextAdditional,
		})
	};

	handleShowHideDropdown = (id) => {
		this.setState({
			showTaskDropDownById: this.state.showTaskDropDownById === id ? '' : id
		});
	};

	handleAssignTaskToUsername = (type, taskId, dropdownValue) => {
		const { user } = this.props;

		if (type === 'claim') {
			const data = {
				taskId,
				username: user.username
			};

			this.props.assignTask(data, {myTasks: true, limit: 20, orderId: this.props.filters.searchId}).then((response) => {
				if(response.success) {
					this.props.getAppointmentsByFilters()
				}
			})
		} else if (type === 'unassign') {
			const data = {
				taskId
			};

			this.props.unassignTask(data, {myTasks: true, limit: 20, orderId: this.props.filters.searchId}).then((response) => {
				if(response.success) {
					this.props.getAppointmentsByFilters()
				}
			})
		} else if (type === 'username') {
			const data = {
				taskId,
				username: dropdownValue.value
			};

			this.props.assignTask(data, {myTasks: true, limit: 20, orderId: this.props.filters.searchId}).then((response) => {
				if(response.success) {
					this.props.getAppointmentsByFilters()

				}
			})
		}
		this.setState({showTaskDropDownById: ''});
	};

	handleSearchById = (e) => {
		this.setState({
			filters: {
				...this.state.filters,
				searchId: e.target.value
			}
		});
	};

	changeSearchType = (searchValue) => {
		const { filters } = this.state;

		if (['My Tasks', 'My Group Tasks', 'Unclaimed Tasks', 'Completed Tasks'].includes(searchValue.label)) {
			let completed = searchValue.label === 'Completed Tasks';
			this.setState({
					searchType: searchValue,
					isFetched: false,
					showTaskDropDownById: '',
					placeholder: searchValue.placeholder,
					filters: { ...this.state.filters, searchId: '', complete: completed },
					isFilterDropDownOpen: false
				},
				() => {
					this.renderButtonText();
					this.props.getFilteredTasks(getFormattedFilters(filters, searchValue, searchValue.label))
						.then(() => this.handleScrollToPreviousPosition());
				});
		}
		else if (['taskId', 'description', 'accountId', 'orderId', 'username'].includes(searchValue.value)) {
			this.setState({filters: { ...this.state.filters, complete: '' }});
			// Reset search ID if selected search type is different
			if (searchValue.value !== this.state.searchType.value) {
				this.setState({filters: { ...this.state.filters, searchId: '' }});
			}

			this.setState({
				searchType: searchValue,
				isFetched: false,
				showTaskDropDownById: '',
				placeholder: searchValue.placeholder,
				isFilterDropDownOpen: false
			}, () => {
				this.renderButtonText();
			});
		}
	};

	getTransformedSearchTypeParams = () => {
		const { searchType } = this.state;
		const { filters } = this.props

		let params = {};

		if (searchType.label === 'My Tasks') {
			params = {
				username: searchType.value
			};
		} else if (searchType.label === 'My Group Tasks') {
			params = {
				group: searchType.value
			};
		}

		if (searchType.value === 'taskId') {
			return {taskId: filters.searchId};
		}
		else {
			return getFormattedFilters(filters, searchType.value, searchType.label);
		}
	};

	getTasksByFilters = () => {
		const { filters, searchType } = this.state;
		const { user } = this.props;

		if (searchType.value === 'taskId') {
			this.props.getFilteredTasks({taskId: filters.searchId})
				.then(() => this.handleScrollToPreviousPosition());
		}
		else {
			this.props.getFilteredTasks(getFormattedFilters(filters, searchType.value, searchType.label))
				.then(() => this.handleScrollToPreviousPosition());
		}
	};

	updateTaskData = (oldTaskId, newTaskId) => {
		//updates task state after task form submission without re-rendering parent components
		let updatedTasks = this.state.userTasksDataSorted
		updatedTasks.map((task) => {
			if(task.id === oldTaskId) {
				task.id = newTaskId.toString();
				task.name = this.props.taskAttributesData.name
				this.props.setTaskIdSelected(task.id)
			}
		})
		this.setState({userTasksDataSorted: updatedTasks})
	}


	render() {

		const {
			userTasksDataSorted,
			searchType,
			isFetched,
			showTaskDropDownById
		} = this.state;

		const {
			systemUsersData,
			taskLoader,
			taskAttributesData,
			usernameOptions,
			appointmentSelectedData
		} = this.props;
		return (
			<div>
				{userTasksDataSorted.length === 1 ?
					<PersonalTaskDetails taskIdSelected={userTasksDataSorted[0].id}
										 setTaskIdSelected={this.props.setTaskIdSelected}
										 usernameOptions={usernameOptions}
										 taskLoader={taskLoader}
										 isFetched={isFetched}
										 updateTaskData={this.updateTaskData}
					/>
					:
					<PersonalTasksResults
						key={'TasksResults'}
						searchType={searchType.value}
						userTasksDataSorted={userTasksDataSorted}
						appointmentSelectedData={appointmentSelectedData}
						systemUsersData={systemUsersData}
						taskLoader={taskLoader}
						isFetched={isFetched}
						rowClasses={this.state.rowClasses}
						showTaskDropDownById={showTaskDropDownById}
						handleShowHideDropdown={this.handleShowHideDropdown}
						handleAssignTaskToUsername={this.handleAssignTaskToUsername}
						handleShowTaskDetails={this.handleShowTaskDetails}
						setTaskIdSelected={this.props.setTaskIdSelected}
						taskId={this.props.taskId}
						taskAttributesData={taskAttributesData}
						getTaskAttributes={this.props.getTaskAttributes}
						usernameOptions={usernameOptions}
						getAppointmentsByFilters={this.props.getAppointmentsByFilters}
						updateTaskData={this.updateTaskData}
					/>
				}
			</div>
		);
	}
}

PersonalTasks.propTypes = {
	userTasksData: PropTypes.array.isRequired,
	taskLoader: PropTypes.bool,
	getTasks: PropTypes.func,
	getFilteredTasks: PropTypes.func,
	assignTask: PropTypes.func,
	unassignTask: PropTypes.func,
	emptyTasks: PropTypes.func,
	getSystemUsers: PropTypes.func,
	emptySystemUsers: PropTypes.func
};

const userTasksLoader = createLoadingSelector([ 'SET_TASKS' ]);

const mapStateToProps = (state) => {
	const userTasksData = getDashboardData(state).userTasks,
		systemUsersData = getDashboardData(state).systemUsers,
		taskLoader = userTasksLoader(state),
		viewMyGroupedTaskPermission = getUserPermission(state, 'OPER', 'OPER_VIEW_GRP_MY_TASKS'),
		viewUnclaimedTaskPermission = getUserPermission(state, 'OPER', 'OPER_VIEW_UNC_MY_TASKS'),
		taskAttributesData = getTaskDetails(state).taskAttributes;

	return {
		userTasksData,
		systemUsersData,
		taskLoader,
		viewMyGroupedTaskPermission,
		viewUnclaimedTaskPermission,
		taskAttributesData
	};
};

const mapDispatchToProps = {
	getTasks,
	getFilteredTasks,
	assignTask,
	unassignTask,
	emptyTasks,
	getSystemUsers,
	emptySystemUsers,
	getTaskAttributes,
	getTroubleTickets,
	getTasksByTroubleTicketId
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalTasks);
