import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
	PaginationListStandalone,
	SizePerPageDropdownStandalone,
	PaginationProvider,
	PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import Loader from "../../../../components/Loader";
import isEmpty from "../../../../utils/helpers";

const { SearchBar } = Search;

export class PayNowTable extends Component {
	state = {
		checked: []
	};

	// handleChecked = (id) => {
	// 	if (this.state.checked.includes(id)) {
	// 		this.setState({
	// 			checked: this.state.checked.filter((x) => x !== id)
	// 		});
	// 	} else {
	// 		this.setState({
	// 			checked: [ ...this.state.checked, id ]
	// 		});
	// 	}
	// };

	handleChecked = (id) => {

		if (this.props.values.selectedInvoices.includes(id)) {
			let temporary = this.props.values.selectedInvoices.filter((x) => x !== id);
			this.props.setFieldValue('selectedInvoices', temporary);
		} else {
			let temporary = [ ...this.props.values.selectedInvoices, id ];
			this.props.setFieldValue('selectedInvoices', temporary);
		}
	};

	checkFormateer = (cell, row, rowIndex, formatExtraData) => {
		if (row) {

			return (
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input
							disabled={this.props.isSubmitting}
							type="checkbox"
							onChange={() => this.handleChecked(row.id)}
							checked={formatExtraData.checked.includes(row.id)}
						/>
						<span />
					</label>
				</div>
			);
		}
	};

	typeFormater = (cell, row, rowIndex) => {
		if (row) {
			return (
				<Fragment>
					{cell}
					<span className="td-subtitle">#{row.number}</span>
				</Fragment>
			);
		}
	};

	invoiceDateFormater = (cell, row, rowIndex) => {
		if (row) {
			return (
				<Fragment>
					{moment(cell).format('MM/DD/YYYY')}{' '}
					{row.type === 'SERVICE' && (
						<span className="td-subtitle">
							{moment(row.periodFrom).format('MM/DD')}-{moment(row.periodTo).format('MM/DD')}
						</span>
					)}
				</Fragment>
			);
		}
	};

	amountFormater = (cell, row, rowIndex) => {
		if (row) {
			return <Fragment>${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Fragment>;
		}
	};

	dueDateFormater = (cell, row, rowIndex) => {
		if (row) {
			return <Fragment>{moment(cell).format('MM/DD/YYYY')}</Fragment>;
		}
	};

	lastPaymentFormater = (cell, row, rowIndex) => {
		if (row) {
			return (
				<Fragment>
					{moment(cell).format('MM/DD/YYYY')}
					<span className="td-subtitle">
						{row.lastPaymentAmount &&
							`$${row.lastPaymentAmount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
					</span>
				</Fragment>
			);
		}
	};

	balanceFormater = (cell, row) => {
		if (row) {
			if (cell <= 0) {
				return <span className="badge badge-success">Paid</span>;
			} else if (cell < row.amount) {
				return (
					<Fragment>
						<span className="badge badge-warning">Partially unpaid</span>
						<span className="td-subtitle">
							Balance: ${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
						</span>
					</Fragment>
				);
			} else {
				return (
					<Fragment>
						<span className="badge badge-warning">Unpaid</span>
						<span className="td-subtitle">
							Balance: ${cell.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
						</span>
					</Fragment>
				);
			}
		}
	};

	customTotal = (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} entries
		</span>
	);

	render() {
		const { isSubmitting, invoicesData, loadingInvoices } = this.props;

		if (loadingInvoices) {
			return <Loader/>;
		}

		const options = {
			paginationSize: 4,
			custom: true,
			totalSize: this.props.invoicesData.length,
			pageStartIndex: 1,
			// alwaysShowAllBtns: true, // Always show next and previous button
			// withFirstAndLast: false, // Hide the going to First and Last page button
			// hideSizePerPage: true, // Hide the sizePerPage dropdown always
			hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
			firstPageText: false,
			prePageText: 'Previous',
			nextPageText: 'Next',
			lastPageText: false,
			// nextPageTitle: 'First page',
			// prePageTitle: 'Pre page',
			// firstPageTitle: 'Next page',
			// lastPageTitle: 'Last page',
			showTotal: true,
			paginationTotalRenderer: this.customTotal,
			sizePerPageList: [
				{
					text: '2',
					value: 2
				},
				{
					text: '5',
					value: 5
				}
			] // A numeric array is also available. the purpose of above example is custom the text
		};

		const columns = [
			{
				dataField: '',
				text: '',
				formatter: this.checkFormateer,
				classes: 'td-with-check',
				formatExtraData: {
					checked: this.props.values.selectedInvoices
				}
			},
			{
				dataField: 'type',
				text: 'Type',
				sort: true,
				formatter: this.typeFormater
			},
			{
				dataField: 'number',
				text: '',
				hidden: true
			},
			{
				dataField: 'invoiceDate',
				text: 'Date',
				sort: true,
				formatter: this.invoiceDateFormater
			},

			{
				dataField: 'amount',
				text: 'Amount',
				sort: true,
				formatter: this.amountFormater,
				classes: 'h-align-r'
			},
			{
				dataField: 'dueDate',
				text: 'Due Date',
				sort: true,
				formatter: this.dueDateFormater
			},
			// {
			// 	dataField: 'lastPaymentDate',
			// 	text: 'LAST PMNT',
			// 	sort: true,
			// 	formatter: this.lastPaymentFormater
			// },
			{
				dataField: 'balance',
				text: '',
				// sort: true,
				formatter: this.balanceFormater
			}
		];

		const defaultSorted = [
			{
				dataField: 'invoiceDate',
				order: 'desc'
			}
		];

		return (
			<div className={"form-section form-section-expanded form-section-customer-payment-invoices" + (isSubmitting ? " form-section-customer-payment-invoices-disabled" : "")}>
				<div className="cmv-container-table table-responsive">
					<div className="container">
						<PaginationProvider pagination={paginationFactory(options)}>
							{({ paginationProps, paginationTableProps }) => (
								<Fragment>
									<ToolkitProvider
										bootstrap4
										keyField="id"
										data={invoicesData}
										columns={columns}
										defaultSorted={defaultSorted}
										search
									>
										{(props) => (
											<Fragment>
												<div className="row">
													<div className="col-sm-12 col-md-6">
														<label>
															Show <SizePerPageDropdownStandalone {...paginationProps} />{' '}
															entries
														</label>
													</div>
													<div
														className="col-sm-12 col-md-6"
														style={{
															textAlign: 'right'
														}}
													>
														<label>
															Search:{' '}
															<SearchBar
																className="form-control form-control-sm"
																{...props.searchProps}
															/>
														</label>
													</div>
												</div>
												<div className="row">
													<BootstrapTable
														wrapperClasses="table-responsive react-table-layout"
														classes="table table-striped cmv-table"
														bordered={false}
														noDataIndication="No data found"
														{...paginationTableProps}
														{...props.baseProps}
													/>
												</div>
												<div className="row" style={{ marginTop: '10px' }}>
													<div className="col-sm-12 col-md-5">
														<PaginationTotalStandalone {...paginationProps} />
													</div>
													<div className="col-sm-12 col-md-7">
														<PaginationListStandalone {...paginationProps} />
													</div>
												</div>
											</Fragment>
										)}
									</ToolkitProvider>
								</Fragment>
							)}
						</PaginationProvider>
					</div>
				</div>
			</div>
		);
	}
}

export default PayNowTable;
