import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";

import isEmpty from "src/utils/helpers";
import TextInput from "src/components/UI/TextInput";

import { hideModal } from "src/actions/modal.actions";
import { getModalData, getUser, getInventory } from "src/selectors";
import {
  getSerializedItem,
  getSystemNumTypes,
  updateSerializedItemNumbers,
  clearSerializedItem,
} from "src/actions/inventory.actions";
import LoadingModalContent from "src/components/Modal/LoadingModalContent";

/**
 * Edit Serialized Item Numbers modal
 *
 * @property {any} props.modalProps.value - the serialized item
 * @property {(item) => void} props.modalProps.onSuccess - callback function to be called when the item is successfully updated
 */
class EditInventorySerializedNumbersModal extends Component {
  state = {
    alertMessage: "",
    isLoading: true,
  };

  componentDidMount() {
    const { modalProps } = this.props;
    const { value: item } = modalProps;

    // get the list of system number types available for the item
    this.props.getSystemNumTypes({ itemId: item.item.id }).then((response) => {
      this.setState({
        isLoading: false,
      });
    });
  }

  componentWillUnmount() {}

  formSchema = () => {
    let validationSchema = {
      numbers: Yup.array(
        Yup.object().shape({
          id: Yup.number().nullable(),
          systemNumTypeId: Yup.number().required("Required"),
          number: Yup.string(),
        })
      )
        .min(1)
        .required("Required"),
    };

    return Yup.object().shape(validationSchema);
  };

  hideModal = () => {
    this.props.clearSerializedItem();
    this.props.hideModal();
  };

  formSubmit = (values, actions) => {
    this.setState({ alertMessage: "" }, () => {
      const postData = {
        numbers: values.numbers.filter((num) => !!num.number),
      };

      this.props.updateSerializedItemNumbers(this.props.modalProps.value.id, postData).then((response) => {
        if (!response.data) {
          actions.setSubmitting(false);
          this.setState({
            alertMessage: "Unknown error. Please try again later",
          });
          return;
        }

        if (!response.data.success) {
          actions.setSubmitting(false);
          this.setState({ alertMessage: response.data.error.message });
          return;
        }

        this.hideModal();

        // call back
        this.props.modalProps.onSuccess(response.data.serializedItem);
      });
    });
  };

  render() {
    const { alertMessage } = this.state;
    const { modalProps, systemNumTypes } = this.props;

    return (
      <Fragment>
        <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
          {this.state.isLoading && (
            <LoadingModalContent modalTitle="Edit Serialized Item Numbers" submitButtonLabel="Save" />
          )}
          {!this.state.isLoading && (
            <Formik
              initialValues={{
                numbers: systemNumTypes.map((numType) => {
                  let number = modalProps.value.numbers.find((num) => num.systemNumType.id === numType.id);
                  return {
                    id: number ? number.id : null,
                    systemNumTypeId: numType.id,
                    systemNumTypeName: numType.name,
                    systemNumTypeDesc: numType.description,
                    number: number ? number.number : numType.defaultValue || "",
                  };
                }),
              }}
              validationSchema={this.formSchema()}
              onSubmit={this.formSubmit}
            >
              {({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Edit Inventory</h5>
                        <button onClick={this.hideModal} type="button" className="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body cmv-form form-horizontal">
                        {!isEmpty(alertMessage) && (
                          <div className="alert alert-inline alert-danger alert-dismissible">
                            <p className="mb-0">{alertMessage}</p>
                          </div>
                        )}
                        <div className="order-service-order-wrapper">
                          <div>
                            <h4 className="text-uppercase">{modalProps.value.item.name}</h4>
                            <p className="billing-job-id">{modalProps.value.item.description}</p>
                          </div>
                        </div>
                        <FieldArray
                          name="numbers"
                          render={(arrayHelpers) => (
                            <div>
                              {values.numbers.map((property, index) => (
                                <div key={index} className="form-group">
                                  <Field name={`numbers.${index}.id`} type="hidden" value={property.systemNumTypeId} />
                                  <TextInput
                                    divClass="mb-1"
                                    label={property.systemNumTypeName}
                                    name={`numbers.${index}.number`}
                                    value={property.number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errors={errors}
                                    touched={touched}
                                  />
                                  <p className="text-xs text-muted">{property.systemNumTypeDesc}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        />
                      </div>
                      <div className="modal-footer">
                        <button onClick={this.hideModal} className="btn" type="button">
                          Cancel
                        </button>
                        {modalProps.mode !== "view" && (
                          <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                            Save
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps,
    systemNumTypes = getInventory(state).systemNumTypes,
    currentUser = getUser(state).user;

  return {
    modalProps,
    systemNumTypes,
    currentUser,
  };
};

const mapDispatchToProps = {
  hideModal,
  getSystemNumTypes,
  updateSerializedItemNumbers,
  getSerializedItem,
  clearSerializedItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditInventorySerializedNumbersModal);
