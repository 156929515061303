import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import LocationSearchInput from "../../../../CreateServiceRequest/ServiceRequest/Tabs/Addresses/LocationSearchInput";
import ManualAddressInput from "../../../../CreateServiceRequest/ServiceRequest/Tabs/Addresses/ManualAddressInput";
import Select from 'react-select';
import {groupStyles} from '../../../../../utils/SelectStyles';
import {generateFormatedAddress, generateFullFormatedAddress} from '../../../../../utils/generateAddresses';
import Iframe from 'react-iframe';
import isEmpty from '../../../../../utils/helpers';

import LoaderPulse from '../../../../../components/Loader/LoaderPulse';
import {Formik} from "formik";
import TextInput from "../../../../../components/UI/TextInput";
import {getTransformCountryStates} from "../../../../CreateAccount/utils";
import AddressInput from "../../../Panels/RightPanel/Tabs/Security/Inputs/AddressInput";
import FormSelect from "../../../../../components/UI/FormSelect";
import {isEqual} from "lodash";

class Addresses extends Component {

	render() {
		const {
			contactAddresses,
			coordinates,
			onAddressHandleChange,
			addressType,
			onChangeAddressType,
			values,
			validatedAddress,
			loaderMapLinkLocation,
			selectedAddress,
			selectedUnit,
			serviceOrderAddresses,
			countryStatesData,
			canEnterManualAddress
		} = this.props;

		const stateOptions = [];
		let defaultState = "";
		if (!isEmpty(countryStatesData)) {
			countryStatesData.map((states) => {
				if (states.default) {
					defaultState = states;
				}
				stateOptions.push({label: states.name, value: states.id, abbreviation: states.abbreviation, country: states.countryCode});
			})
		}
		let formattedSerivceOrderAddresses = [];



		if (serviceOrderAddresses) {
			const uniqueAddresses = Array.from(new Set(serviceOrderAddresses.map(a => a.address1)))
				.map(address1 => {
					return serviceOrderAddresses.find(a => a.address1 === address1)
				})

			formattedSerivceOrderAddresses = uniqueAddresses.filter((address) => address.address1 != "No Address required").map((address) => {
				return {
					value: generateFullFormatedAddress(address),
					label: `Use Service address - ${generateFormatedAddress(address)}`
				}
			})
		}

		return (
			<Fragment>
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input
							type="checkbox"
							name="validateAddress"
							checked={addressType === 'validateAddress'}
							onChange={() => onChangeAddressType('validateAddress', this.props.setFieldValue)}
						/>
						<span>Validate Address</span>
					</label>
				</div>
				{addressType === 'validateAddress' && (
						<LocationSearchInput
							// selectedAddress={values.address}
							selectedAddress={selectedAddress}
							selectedUnit={selectedUnit}
							onAddressHandleChange={onAddressHandleChange}
							{...this.props}
						/>
				)}
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input
							type="checkbox"
							name="customerAddress"
							checked={addressType === 'customerAddress'}
							onChange={() => onChangeAddressType('customerAddress', this.props.setFieldValue)}
						/>
						<span>Use customer address</span>
					</label>
				</div>
				{addressType === 'customerAddress' && (
					<Fragment>
						<div className="form-group" style={{marginBottom: '0px'}}>
							<Select
								name="accessRequested"
								value={formattedSerivceOrderAddresses.find((address) => !isEmpty(selectedAddress) && address.label.endsWith(selectedAddress)) || ''}
								onChange={(values) => onAddressHandleChange(values.value, '', this.props.setFieldValue)}
								options={formattedSerivceOrderAddresses}
								styles={groupStyles}
								placeholder="Select from customer addresses"
							/>
						</div>
						{this.props.validatedAddress &&
							this.props.validatedAddress.error && (
								<div className="row">
									<div className="col-md-9">
										<div className="invalid-feedback">
											{this.props.validatedAddress.error.message}
										</div>
									</div>
								</div>
							)}
							{this.props.validatedAddress &&
							!this.props.validatedAddress.error &&
							!this.props.validatedAddress.allowedServiceOrderType && (
								<div className="row">
									<div className="col-md-9">
										<div className="invalid-feedback">
											We are not currently offering service at this location.
										</div>
									</div>
								</div>
							)}
							{this.props.validatedAddress &&
							!this.props.validatedAddress.error &&
							'NEW_SURVEY' == this.props.validatedAddress.allowedServiceOrderType && (
								<div className="row">
									<div className="col-md-9">
										<div className="valid-feedback">
											We are currently collecting surveys for this address.
										</div>
									</div>
								</div>
							)}
							{this.props.validatedAddress &&
							!this.props.validatedAddress.error &&
							'NEW_SIGNUP' == this.props.validatedAddress.allowedServiceOrderType && (
								<div className="row">
									<div className="col-md-9">
										<div className="valid-feedback">
											We are currently collecting sign-ups for this address.
										</div>
									</div>
								</div>
							)}
					</Fragment>
				)}

				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input
							type="checkbox"
							name="noAddress"
							checked={addressType === 'noAddress'}
							disabled={true}
							onChange={() => onChangeAddressType('noAddress', this.props.setFieldValue)}
						/>
						<span>No address required</span>
					</label>
				</div>
				<div className="form-check checkbox-slider checkbox-slider--b-flat">
					<label>
						<input
							type="checkbox"
							name="manualAddress"
							checked={addressType === 'manualAddress'}
							disabled={!canEnterManualAddress}
							onChange={() => onChangeAddressType('manualAddress', this.props.setFieldValue)}
						/>
						<span>Enter Manually</span>
					</label>
				</div>
				{addressType === 'manualAddress' && (
					<ManualAddressInput
						onAddressHandleChange={onAddressHandleChange}
						stateOptions={stateOptions}
						defaultState={defaultState}
						{...this.props}
					/>
				)}
				{loaderMapLinkLocation && <LoaderPulse />}
				{addressType != 'manualAddress' &&
				!loaderMapLinkLocation && !isEmpty(validatedAddress) &&
				isEmpty(validatedAddress.error) && (
					<Fragment>
						<div className="form-group" style={{ display: 'flex', flexDirection: 'row-reverse'}}>
							<button
								style={{
									marginBottom: '0.25rem',
								}}
								title="Show Mapping Information"
								type="button"
								className="btn btn-outline-secondary"
								onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
									title: "Service Address Information",
									service: {
										address1: this.props.validatedAddress.address1 || '',
										address2: this.props.validatedAddress.address2 || '',
										address3: this.props.validatedAddress.address3 || '',
										city: this.props.validatedAddress.city || '',
										latitude: this.props.validatedAddress.latitude || '',
										longitude: this.props.validatedAddress.longitude || '',
										mappingAddressId: this.props.validatedAddress.mappingAddressId || '',
										mappingAreaId: this.props.validatedAddress.mappingAreaId || '',
										mappingRefArea1: this.props.validatedAddress.mappingRefArea1 || '',
										state: this.props.validatedAddress.state || '',
										country: this.props.validatedAddress.country || '',
										zipcode: this.props.validatedAddress.zipcode || '',
										zip4: this.props.validatedAddress.zip4 || '',
										mappingNote: this.props.validatedAddress.mappingNote || '',
										externalUrl: this.props.validatedAddress.externalUrl || '',
										qualifiedServices: this.props.validatedAddress.qualifiedServices || '',
										savedAddressZoneValues: this.props.validatedAddress.savedAddressZoneValues || []
									},
									canViewCSIGeocode: false,
									canEditCSIGeocode: false,
									isDisabled: true
								})}
							>
								<i className="fas fa-map" />
							</button>
						</div>
						<div className="customer-new-service-location-map">
							<Iframe
								url={validatedAddress.embedUrl}
								width="100%"
								height="100%"
								id="myId"
								className="location-map"
								display="initial"
								position="relative"
								style={{ height: `30vh` }}
							/>
						</div>
					</Fragment>

				)}
			</Fragment>
		);
	}
}

Addresses.propTypes = {
	contactAddresses: PropTypes.array.isRequired,
	coordinates: PropTypes.object.isRequired,
	onAddressHandleChange: PropTypes.func,
	onSubmitAddresses: PropTypes.func,
	addressType: PropTypes.string.isRequired,
	onChangeAddressType: PropTypes.func,
	validatedAddress: PropTypes.object.isRequired
};

export default Addresses;
