import React from "react";

export default function FormCheckboxSlider({ checked, onChange = (evt) => {}, label, ...otherProps }) {
  return (
    <div className="form-check checkbox-slider checkbox-slider--b-flat">
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} {...otherProps} />
        <span>{label}</span>
      </label>
    </div>
  );
}
