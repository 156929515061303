import React, {Component} from "react";
import {connect} from 'react-redux';
import Wizard from "../../../camvio-wizard/Wizard";
import Step from "../../../camvio-wizard/Step";

import {
    Validate,
    Status
} from "./Steps";

const STEPS = {
    VALIDATE_SELECTION: 'validate-selection-step',
    STATUS: 'status-step',
}

class ValidateService extends Component {

    componentDidMount() {
        document.title = 'Validate Service - camvio.cloud';
        this.props.changeNavBarType('lock', 'validateServiceWizard', 'Validate Service');
    }

    componentWillUnmount() {
        const {changeNavBarType, hideAccountDetailsWizard, documentTitle, navbarTitle} = this.props;

        // Change navbar type and color
        document.title = documentTitle;
        changeNavBarType('account', 'accountDetails', navbarTitle);

        hideAccountDetailsWizard();
    }

    render() {
        const { accountDetailsWizardData, history, hideAccountDetailsWizard } = this.props;

        return (
            <Wizard name='ValidateServiceWizard'>

                <Step
                    wizardData={accountDetailsWizardData.wizardData}
                    name={STEPS.VALIDATE_SELECTION}
                    title='Validate'
                    component={Validate}
                    next={STEPS.STATUS}
                    history={history}
                    nextBtnName='Validate'
                />

                <Step
                    wizardData={accountDetailsWizardData.wizardData}
                    name={STEPS.STATUS}
                    title='Status'
                    component={Status}
                    history={history}
                    hideAccountDetailsWizard={hideAccountDetailsWizard}
                />

            </Wizard>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateService);