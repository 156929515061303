export const getAuthData = (state) => state.auth || {};
export const getModalData = (state) => state.modals || {};
export const getAccountSearch = (state) => state.accountSearch || {};
export const getDashboardData = (state) => state.dashboard || {};
export const getAccountDetails = (state) => state.accountDetails || {};
export const getUser = (state) => state.user || {};
export const getCreateAccount = (state) => state.createAccount || {};
export const getCreateServiceRequest = (state) => state.createServiceRequest || {};
export const getGlobalError = (state) => state.globalError || {};
export const getTaskDetails = (state) => state.taskDetails || {};
export const getTasksList = (state) => state.tasksList || {};
export const getSideBar = (state) => state.sideBar || {};
export const getViewAs = (state) => state.viewAs || {};
export const getDomain = (state) => state.domain || {};
export const getTechnicianAppointment = (state) => state.technicianAppointment || {};
export const getGlobalFlags = (state) => state.globalFlags || {};
export const getProvisioning = (state) => state.provisioning || {};
export const getPaymentProviders = (state) => state.paymentProviders || {};
export const getMappingProviders = (state) => state.mappingProviders || {};
export const getWorkforceProviders = (state) => state.workforceProviders || {};
export const getErrorMessage = (actions) => (state) => {
	const errors = actions.map((action) => state.errors[action]);
	return errors && errors[0] ? errors[0] : '';
};
export const getTaskForm = (state) => state.taskForm || {};

export const createLoadingSelector = (actions) => (state) => actions.some((action) => state.loading[action]);

export const getNewOrder = (state) => state.accountDetails.newOrder;
export const getEditServiceRequest = (state) => state.accountDetails.editServiceRequest;

export const getUserPermission = (state, moduleName, permissionName) => {
	const findPermission = state.user.userPermission.find((x) => x.name === moduleName)
		? state.user.userPermission.find((x) => x.name === moduleName).permissions.includes(permissionName)
		: false;

	return findPermission;
};

export const getAdmin = (state) => state.admin || {};
export const getUiController = (state) => state.uiController || {};
export const getProducts = (state) => state.products || {};
export const getTaxation = (state) => state.taxation || {};
export const getTroubleTicketCodes = (state) => state.troubleTicketCodes || {};

export const getAreas = (state) => state.appointment || {};
export const getAppointments = (state) => state.appointments || {};
export const getTimeslots = (state) => state.appointments || {};
export const getWorkflows = (state) => state.workflow || {};
export const getBillingJobs = (state) => state.billingJobs || {};
export const getAccountPlan = (state) => state.accountPlan || {};
export const getAutoPayJobs = (state) => state.autoPayJobs || {};
export const getInventory = (state) => state.inventory || {};
export const selectNotificationsState = (state) => state.notifications || {};
export const getMigrationJobsData = (state) => state.migrationJobs || {}
export const getSystemConfigs = (state) => state.systemConfigs || {}
