import React, {Component, Fragment} from 'react'
import {connect} from "react-redux";
import {loadAppointments, clearAppointments} from "../../../../actions/appointments.actions";
import {getAppointmentAreas} from "../../../../actions/appointmentAreas.actions";
import {showModal, showAccessModal} from '../../../../actions/modal.actions';
import {getSystemUsers, emptySystemUsers, assignTask, unassignTask} from '../../../../actions/dashboardActions/tasks.actions';
import {createLoadingSelector, getAppointments} from '../../../../selectors';
import moment from "moment";
import {getFormattedFilters} from "./utils";
import {isEmpty} from "../../../../utils/helpers";
import Loader from "../../../../components/Loader";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {groupStyles} from '../../../../utils/SelectStyles';
import Calendar from "./Calendar";
import Map from "./Map/Map";
import List from "./List";
import {Link} from "react-router-dom";

class Appointments extends Component {

    state = {
        appointmentsSorted: undefined,
        timeslotOptions: [
            {value: '0', label: '08:00-10:00', placeholder: ''},
            {value: '1', label: '10:00-12:00', placeholder: ''},
            {value: '2', label: '13:00-15:00', placeholder: ''},
            {value: '3', label: '15:00-17:00', placeholder: ''},
            {value: '4', label: '17:00-19:00', placeholder: ''},
        ],
        assigneeOptions: [],
        areaOptions: [],
        typeOptions: [
            {value: 'ORDERS', label: 'Orders', placeholder: ''},
            {value: 'TROUBLE_TICKETS', label: 'Trouble Tickets', placeholder: ''},
        ],
        usernameOptions: [],
        filters: {
            limit: 20,
            fromDate: moment().toDate(),
            toDate: moment().toDate(),
            timeslot: undefined,
            assignee: undefined,
            area: undefined,
            type: undefined,
        },
        filterType: 'limit',
        filterValue: '20',
        filterButtonText: 'Last 20',
        filterButtonTextAdditional: '',
        isFilterDropDownOpen: false,
        isAssigning: false,
        showDropdownById: '',
        viewAs: 'calendar',
        currentFromDate: moment().toDate(),
        currentToDate: moment().toDate()
    }

    componentDidMount() {
        // Get appointments
        this.getAppointmentsByFilters();

        // Get appointment areas
        this.props.getAppointmentAreas().then(response => {
            this.generateAreaOptions(response);
        });

        // Get first name and last name for the assign in dropdown
        if (!isEmpty(this.props.user.user.userGroups)) {
            this.props.getSystemUsers({
                userGroups: this.props.user.user.userGroups.map((userGroup) => userGroup.name).join(',')
            }).then(response => {
                this.generateUsernameOptions(response);
            });
        }
    }


    componentWillUnmount() {
        this.props.clearAppointments();
        this.props.emptySystemUsers();
    }

    generateAppointments = (appointments) => {
        let updatedAppointment;
        let appointmentsSorted = [];
        let tasksIds = [];
        let taskAssignees = [];
        let assigneeOptions = [];

        // Generate for Orders
        if (!isEmpty(appointments.orders)) {
            appointments.orders.map(appointment => {
                tasksIds = [];
                taskAssignees = [];

                // Collect Tasks IDs and Assignees
                if (!isEmpty(appointment.serviceOrders)) {
                    appointment.serviceOrders.map(serviceOrder => {
                        if (!isEmpty(serviceOrder.task) && !isEmpty(serviceOrder.task.id)) {

                            // Collect all task IDs
                            tasksIds.push(serviceOrder.task.id);

                            // Collect task assignees
                            if (serviceOrder.task.assignee) {

                                // Once per user - for dropdown options
                                if (isEmpty(assigneeOptions.filter((option) => option.value === serviceOrder.task.assignee))) {
                                    assigneeOptions.push({value: serviceOrder.task.assignee, label: serviceOrder.task.assignee, placeholder: ''});
                                }

                                // Every time - for display purposes
                                taskAssignees.push(serviceOrder.task.assignee);
                            }
                        }
                    });
                }

                // Add appointment Type and Tasks IDs then store
                updatedAppointment = appointment;
                updatedAppointment['type'] = 'order';
                updatedAppointment['taskIds'] = tasksIds;
                updatedAppointment['taskAssignees'] = taskAssignees;
                appointmentsSorted.push(updatedAppointment);
            })
        }

        // Generate for Tickets
        if (!isEmpty(appointments.troubleTickets)) {
            appointments.troubleTickets.map(appointment => {
                tasksIds = [];
                taskAssignees = [];

                // Collect Tasks IDs and Assignees
                if (!isEmpty(appointment.tasks)) {

                    appointment.tasks.map(task => {
                        if (!isEmpty(task) && !isEmpty(task.id)) {

                            // Collect all task IDs
                            tasksIds.push(task.id);

                            // Collect task assignees
                            if (task.assignee) {

                                // Once per user - for dropdown options
                                if (isEmpty(assigneeOptions.filter((option) => option.value === task.assignee))) {
                                    assigneeOptions.push({value: task.assignee, label: task.assignee, placeholder: ''});
                                }

                                // Every time - for display purposes
                                taskAssignees.push(task.assignee);
                            }
                        }
                    });
                }

                // Add appointment Type, Tasks IDs, Task Assignees, and store
                updatedAppointment = appointment;
                updatedAppointment['type'] = 'troubleTicket';
                updatedAppointment['taskIds'] = tasksIds;
                updatedAppointment['taskAssignees'] = taskAssignees;
                appointmentsSorted.push(updatedAppointment);
            });
        }

        // Sort Appointments according to completion date
        appointmentsSorted.sort((a, b) => {
            let appointmentMomentA = a.appointmentDetails.date + ' ' + a.appointmentDetails.timeslot.substring(0, 5);
            let appointmentMomentB = b.appointmentDetails.date + ' ' + b.appointmentDetails.timeslot.substring(0, 5);

            if (moment(appointmentMomentA).isAfter(appointmentMomentB))
                return 1;
            else
                return -1;
        });

        // Store generated Appointments
        this.setState({
            appointmentsSorted: appointmentsSorted,
            assigneeOptions: assigneeOptions,
        });
    }

    generateAreaOptions = (areas) => {
        let areaOptions = [];

        if (!isEmpty(areas.appointmentAreas)) {

            areas.appointmentAreas.map(area => {
                areaOptions.push({value: area.id, label: area.description, placeholder: ''});
            });

            this.setState({areaOptions: areaOptions})
        }
    }

    generateUsernameOptions = (users) => {
        if (users.length > 0) {
            let usernameOptions = users.map((systemUser) => {
                return {
                    value: systemUser.username,
                    label: systemUser.firstName + ' ' + systemUser.lastName
                };
            });

            this.setState({usernameOptions: usernameOptions})
        }
    }

    changeView = (type) => {
        if (type !==  this.state.viewAs) {
            let updatedFilters = this.state.filters;
            this.setState({
                appointmentsSorted: undefined
            })

            if (type === 'calendar') {
                updatedFilters['fromDate'] = this.state.currentFromDate;
                updatedFilters['toDate'] = this.state.currentToDate;
                updatedFilters['limit'] = -1;
            }
            else if (type === 'list') {
                updatedFilters['limit'] = 20;
                updatedFilters['fromDate'] = moment().toDate();
                updatedFilters['toDate'] = moment().add('10', 'year').toDate();

                this.setState({
                    filterType: 'limit',
                    filterValue: '20',
                    filterButtonText: 'Last 20',
                    filterButtonTextAdditional: '',
                })
            }
            else if (type === 'map') {
                updatedFilters['fromDate'] = this.state.currentFromDate;
                updatedFilters['toDate'] = this.state.currentToDate;
                updatedFilters['limit'] = -1;
            }

            this.setState({
                filters: updatedFilters,
                viewAs: type,
                showDropdownById: '',
                isFilterDropDownOpen: false,
            }, () => {
                this.getAppointmentsByFilters();
            });
        }
    }

    showHideFilter = () => {
        this.setState((prevState) => ({
            isFilterDropDownOpen: !prevState.isFilterDropDownOpen,
            showDropdownById: '',
        }));
    }

    handleLimitFilter = (limit) => {
        let updatedFilters = { ...this.state.filters };

        updatedFilters['limit'] = limit;

        this.setState(
            {
                filters: updatedFilters,
                filterType: 'limit',
                filterValue: limit,
                isFilterDropDownOpen: false,
                showDropdownById: '',
            }, () => {
                this.renderButtonText()
                this.getAppointmentsByFilters()
            }
        );
    }

    handleDateChange = (date) => {
        let updatedFilters = this.state.filters;

        if (date === 'prev') {
            updatedFilters.fromDate = moment(updatedFilters.fromDate).subtract('1', 'day').toDate();
            updatedFilters.toDate = moment(updatedFilters.toDate).subtract('1', 'day').toDate();
        }
        else if (date === 'next') {
            updatedFilters.fromDate = moment(updatedFilters.fromDate).add('1', 'day').toDate();
            updatedFilters.toDate = moment(updatedFilters.toDate).add('1', 'day').toDate();
        }
        else {
            updatedFilters.fromDate = date;
            updatedFilters.toDate = date;
            // updatedFilters.toDate = moment(date).add('1', 'day').toDate();
        }

        // Store new date
        this.setState({
            filters: updatedFilters,
            currentFromDate: updatedFilters.fromDate,
            currentToDate: updatedFilters.toDate,
            showDropdownById: '',
        });

        // Fetch appointments
        this.getAppointmentsByFilters();
    }

    handleLastDaysFilter = (lastDays) => {
        let fromDate = moment().subtract(lastDays, 'days').toDate();
        let toDate = moment().toDate();

        let updatedFilters = { ...this.state.filters };
        updatedFilters['limit'] = '';
        updatedFilters['fromDate'] = fromDate;
        updatedFilters['toDate'] = toDate;

        this.setState(
            {
                filters: updatedFilters,
                filterType: 'lastDays',
                filterValue: lastDays,
                isFilterDropDownOpen: false,
                showDropdownById: '',
            }, () => {
                this.renderButtonText()
                this.getAppointmentsByFilters()
            }
        );
    }

    handleDateFilter = (value, name) => {
        let updatedFilters = { ...this.state.filters };
        updatedFilters['limit'] = '';
        updatedFilters[name] = value;

        this.setState(
            {
                filters: updatedFilters,
                filterType: 'dateFromTo',
                isFilterDropDownOpen: false,
                showDropdownById: '',
            }, () => {
                this.renderButtonText()
                this.getAppointmentsByFilters()
            }
        );
    }

    renderButtonText = () => {
        const { filters, filterType, filterValue } = this.state;

        let filterButtonText = "";
        let filterButtonTextAdditional = "";

        if (filterType === 'date') {
            filterButtonText = filters.fromDate && moment(filters.fromDate).format('MM/DD/YYYY');
            if (filters.fromDate.getTime() !== filters.toDate.getTime()) {
                filterButtonTextAdditional = filters.toDate && moment(filters.toDate).format('MM/DD/YYYY');
            }
        } else if (filterType === 'limit') {
            filterButtonText = `Last ${filterValue}`;
        }

        this.setState({
            filterButtonText: filterButtonText,
            filterButtonTextAdditional: filterButtonTextAdditional,
        })
    }

    changeTimeslotSelected = (searchValue) => {
        let updatedFilters = { ...this.state.filters };
        updatedFilters['timeslot'] = searchValue;

        this.setState({
            filters: updatedFilters,
            isFilterDropDownOpen: false,
        },
        () => {
            // filter already loaded appointments in front end
        });
    }

    changeAssigneeSelected = (searchValue) => {
        let updatedFilters = { ...this.state.filters };
        updatedFilters['assignee'] = searchValue;

        this.setState({
            filters: updatedFilters,
            isFilterDropDownOpen: false,
        },
        () => {
            this.getAppointmentsByFilters();
        });
    }

    changeAreaSelected = (searchValue) => {
        let updatedFilters = { ...this.state.filters };
        updatedFilters['area'] = searchValue;

        this.setState({
            filters: updatedFilters,
            isFilterDropDownOpen: false,
        },
        () => {
            this.getAppointmentsByFilters();
        });
    }

    changeTypeSelected = (searchValue) => {
        let updatedFilters = { ...this.state.filters };
        updatedFilters['type'] = searchValue;

        this.setState({
            filters: updatedFilters,
            isFilterDropDownOpen: false,
        },
        () => {
            this.getAppointmentsByFilters();
        });
    }

    getAppointmentsByFilters = () => {
        this.props.loadAppointments(getFormattedFilters(this.state.filters)).then(response => {
            this.generateAppointments(response);
        });
    }

    handleShowAssignDropdownById = (id) => {
        this.setState((prevState) => ({
            showDropdownById: prevState.showDropdownById !== id ? id : '',
            isFilterDropDownOpen: false,
        }));
    }

    handleAllTasksAssignee = (type, taskIds, dropdownValue) => {
        let appointmentId = this.state.showDropdownById;

        // Close menu
        this.setState({showDropdownById: ''});

        if (!isEmpty(taskIds)) {

            // Assign all tasks
            taskIds.map((taskId, index) => {

                // Indicate loading
                this.setState({isAssigning: appointmentId});

                if (type === 'claim') {
                    this.props.assignTask({taskId: taskId, username: this.props.username}).then((response) => {
                        if (index+1 === taskIds.length && response && response.success)
                            this.getAppointmentsByFilters();
                            this.setState({isAssigning: false});
                    });
                }
                else if (type === 'unassign') {
                    this.props.unassignTask({taskId: taskId}).then((response) => {
                        if (index+1 === taskIds.length && response && response.success)
                            this.getAppointmentsByFilters();
                            this.setState({isAssigning: false});
                    });
                }
                else if (type === 'username') {
                    this.props.assignTask({taskId: taskId, username: dropdownValue.value}).then((response) => {
                        if (index+1 === taskIds.length && response && response.success)
                            this.getAppointmentsByFilters();
                            this.setState({isAssigning: false});
                    });
                }
            });
        }
    }

    accountAccessModal = (id) => {
        this.props.showAccessModal(true);
        this.props.showModal('ACCOUNT_ACCESS_MODAL', { accountId: id });
    }

    serviceAddressAccessModal = (appointment) => {
        // this.props.showAccessModal(true);
        this.props.showModal('SERVICE_ADDRESS_MODAL', {
            title: "Service Address Information",
            service: {
                address1: appointment.address.address || '',
                address2:appointment.address.address2 || '',
                address3: appointment.address.address3 || '',
                city: appointment.address.city || '',
                latitude: appointment.address.latitude || '',
                longitude: appointment.address.longitude ||  '',
                mappingAddressId: appointment.address.mappingAddressId ||  '',
                mappingAreaId:  appointment.address.mappingAreaId ||  '',
                mappingRefArea1: appointment.address.mappingRefArea1 ||  '',
                mappingNote: appointment.address.mappingNote ||  '',
                state:{
                    name: appointment.address.state || '',
                    id: appointment.address.stateId || '',
                },
                country: appointment.address.country || '',
                zipcode: appointment.address.zipcode || '',
                zip4: appointment.address.zip4 || '',
                externalUrl: appointment.externalUrl || '',
                qualifiedServices: appointment.qualifiedServices || ''
            },
            canViewCSIGeocode: false,
            canEditCSIGeocode: false,
            isDisabled: true
        })
    }

    render() {
        const {
            appointmentsSorted,
            timeslotOptions,
            assigneeOptions,
            areaOptions,
            typeOptions,
            usernameOptions,
            filters,
            filterButtonText,
            filterButtonTextAdditional,
            isFilterDropDownOpen,
            showDropdownById,
            isAssigning,
            viewAs,
        } = this.state;

        const {
            dashboardMenuCount,
            appointmentsLoader,
            appointments,
            appointmentAreasLoader,
            appointmentAreas,
            user,
            changeNavBarType,
        } = this.props;

        return (
            <Fragment>

                <div className="tab-pane-caption">
                    {/*<h1 className="tab-pane-title">{dashboardMenuCount.pendingAppointments || 0} Appointments</h1>*/}
                    <h1 className="tab-pane-title">Appointments</h1>
                    <p className="tab-pane-text">upcoming</p>
                </div>

                <div className="cmv-container-dashboard-search">
                    <div className="cmv-container-dashboard-filter">
                        <div className="container">
                            <div className="form-row">

                                <div className="col-md-2">

                                    {viewAs === 'calendar' || viewAs === 'map' ?
                                    <div className="input-group input-group-datepicker">
                                        <div className="input-group-prepend">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-direction="prev"
                                                onClick={() => this.handleDateChange('prev')}
                                                disabled={appointmentsLoader || isAssigning}
                                            >
                                                <i className="fa fa-chevron-left" />
                                            </button>
                                        </div>
                                        <DatePicker
                                            className={"form-control"}
                                            id="date"
                                            name="date"
                                            fieldName="date"
                                            dateFormat="MM/dd/yyyy"
                                            placeholderText="Choose Date"
                                            shouldCloseOnSelect={true}
                                            popperPlacement={"bottom-center"}
                                            selected={filters.fromDate}
                                            onChange={(date) => this.handleDateChange(date)}
                                            disabledKeyboardNavigation
                                            showDisabledMonthNavigation
                                            disabled={appointmentsLoader || isAssigning}
                                        />
                                        <div className="input-group-append">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-direction="next"
                                                onClick={() => this.handleDateChange('next')}
                                                disabled={appointmentsLoader || isAssigning}
                                            >
                                                <i className="fa fa-chevron-right" />
                                            </button>
                                        </div>
                                    </div>
                                    : (viewAs === 'list') &&
                                    <div className="dropdown dropdown-cn-timefilter show">
                                            <button
                                                type="button"
                                                onClick={this.showHideFilter}
                                                className={'btn btn-outline-secondary dropdown-toggle' + (isFilterDropDownOpen ? ' show' : '') +
                                                    (filterButtonTextAdditional ? ' dropdown-toggle-multiline' : '')}
                                                disabled={appointmentsLoader || isAssigning}
                                            >
                                                {isEmpty(filterButtonTextAdditional)
                                                    ? filterButtonText
                                                    : <div>
                                                        <span>{filterButtonText}</span><span>{filterButtonTextAdditional}</span>
                                                    </div>
                                                }
                                            </button>
                                            <div
                                                className={'dropdown-menu dropdown-menu-right' + (isFilterDropDownOpen ? ' show' : '')}
                                                onBlur={this.onBlurChangeActiveDropDown}
                                            >
                                                <button className="dropdown-item"
                                                        onClick={() => this.handleLimitFilter(20)}>Last 20
                                                </button>
                                                <button className="dropdown-item"
                                                        onClick={() => this.handleLimitFilter(50)}>Last 50
                                                </button>
                                                <button className="dropdown-item"
                                                        onClick={() => this.handleLimitFilter(100)}>Last 100
                                                </button>
                                            </div>
                                        </div>
                                    }

                                </div>


                                <div className="col-md-2">
                                    {/*{viewAs === 'list' ?*/}
                                    {/*<Select*/}
                                    {/*    inputId={'appointmentTimeslot'}*/}
                                    {/*    name={'appointmentTimeslot'}*/}
                                    {/*    value={filters.timeslot}*/}
                                    {/*    placeholder={'Timeslot'}*/}
                                    {/*    onChange={this.changeTimeslotSelected}*/}
                                    {/*    options={timeslotOptions}*/}
                                    {/*    styles={groupStyles}*/}
                                    {/*    isDisabled={appointmentsLoader || isAssigning}*/}
                                    {/*/>*/}
                                    {/*:*/}
                                    <Select
                                        inputId={'appointmentAssignee'}
                                        name={'appointmentAssignee'}
                                        value={filters.assignee}
                                        placeholder={'Assignee'}
                                        onChange={this.changeAssigneeSelected}
                                        options={assigneeOptions}
                                        styles={groupStyles}
                                        isDisabled={appointmentsLoader || isAssigning}
                                        isClearable={true}
                                    />
                                    {/*}*/}
                                </div>

                                <div className="col-md-4 col-xxl-5">
                                    <Select
                                        inputId={'appointmentArea'}
                                        name={'appointmentArea'}
                                        value={filters.area}
                                        placeholder={'Area(s)'}
                                        onChange={this.changeAreaSelected}
                                        options={areaOptions}
                                        styles={groupStyles}
                                        isLoading={appointmentAreasLoader}
                                        isDisabled={appointmentsLoader || appointmentAreasLoader || isAssigning}
                                        isClearable={true}
                                        // isMulti={true}
                                    />
                                </div>

                                <div className="col-md-4 col-xxl-3">
                                    <div className="input-group">
                                        <Select
                                            inputId={'appointmentType'}
                                            name={'appointmentType'}
                                            value={filters.type}
                                            placeholder={'Type'}
                                            onChange={this.changeTypeSelected}
                                            options={typeOptions}
                                            styles={groupStyles}
                                            isDisabled={appointmentsLoader || isAssigning}
                                            isClearable={true}
                                        />
                                        <div className="input-group-append">
                                            <div className="btn-group">
                                                <button
                                                    type="button"
                                                    data-view="calendar"
                                                    className={"btn btn-" + (viewAs === 'calendar' ? 'primary' : 'outline-secondary')}
                                                    onClick={() => this.changeView('calendar')}
                                                    disabled={appointmentsLoader || isAssigning}
                                                >
                                                    <i className="fa fa-calendar-alt" />
                                                </button>
                                                <button
                                                    type="button"
                                                    data-view="list"
                                                    className={"btn btn-" + (viewAs === 'list' ? 'primary' : 'outline-secondary')}
                                                    onClick={() => this.changeView('list')}
                                                    disabled={appointmentsLoader || isAssigning}
                                                >
                                                    <i className="fa fa-list" />
                                                </button>
                                                <button
                                                    type="button"
                                                    data-view="map"
                                                    className={"btn btn-" + (viewAs === 'map' ? 'primary' : 'outline-secondary')}
                                                    onClick={() => this.changeView('map')}
                                                    disabled={appointmentsLoader || isAssigning}
                                                >
                                                    <i className="fa fa-map-marked-alt" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {appointmentsLoader &&
                <Loader />
                }

                {!appointmentsLoader && isEmpty(appointmentsSorted) &&
                <div className="container">
                    <p>No data found</p>
                </div>
                }

                {!appointmentsLoader && !isEmpty(appointmentsSorted) && (
                    viewAs === 'list' ?
                        <List
                            appointments={appointmentsSorted}
                            handleShowAssignDropdownById={this.handleShowAssignDropdownById}
                            showDropdownById={showDropdownById}
                            handleAllTasksAssignee={this.handleAllTasksAssignee}
                            accountAccessModal={this.accountAccessModal}
                            usernameOptions={usernameOptions}
                            isAssigning={isAssigning}
                            user={user}
                        />
                    : viewAs === 'calendar' ?
                        <Calendar
                            appointments={appointmentsSorted.reverse()}
                            handleShowAssignDropdownById={this.handleShowAssignDropdownById}
                            showDropdownById={showDropdownById}
                            handleAllTasksAssignee={this.handleAllTasksAssignee}
                            accountAccessModal={this.accountAccessModal}
                            usernameOptions={usernameOptions}
                            isAssigning={isAssigning}
                            date={filters.fromDate}
                            user={user}
                        />
                    : viewAs === 'map' &&
                            <Map
                                user={user}
                                appointments={appointmentsSorted}
                                handleShowAssignDropdownById={this.handleShowAssignDropdownById}
                                showDropdownById={showDropdownById}
                                handleAllTasksAssignee={this.handleAllTasksAssignee}
                                accountAccessModal={this.accountAccessModal}
                                serviceAddressAccessModal={this.serviceAddressAccessModal}
                                usernameOptions={usernameOptions}
                                isAssigning={isAssigning}
                            />
                )}

            </Fragment>
        )
    }
}

const getLoaderAppointments = createLoadingSelector(['GET_APPOINTMENTS']);
const getLoaderAppointmentAreas = createLoadingSelector(['GET_APPOINTMENT_AREAS']);

const mapStateToProps = (state) => {
    const appointmentsLoader = getLoaderAppointments(state);
    const appointments = getAppointments(state).appointments;
    const appointmentAreasLoader = getLoaderAppointmentAreas(state);
    const appointmentAreas = getAppointments(state).appointmentAreas;

    return {
        appointmentsLoader,
        appointments,
        appointmentAreasLoader,
        appointmentAreas,
        user: state.user
    };
};

const mapDispatchToProps = {
    loadAppointments,
    clearAppointments,
    getAppointmentAreas,
    showModal,
    showAccessModal,
    getSystemUsers,
    emptySystemUsers,
    assignTask,
    unassignTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
