import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes} from '../utils/helpers';

export const CREATE_ACCOUNT = createRequestTypes('CREATE_ACCOUNT');
export const ACCOUNT_TYPES = createRequestTypes('ACCOUNT_TYPES');
export const SECURITY_QUESTIONS = createRequestTypes('SECURITY_QUESTIONS');
export const COUNTRY_STATES = createRequestTypes('COUNTRY_STATES');
export const ACCOUNT_TYPE_DETAILS = createRequestTypes('ACCOUNT_TYPE_DETAILS');
export const ACCOUNT_TERM_DETAILS = createRequestTypes('ACCOUNT_TERM_DETAILS');
export const SET_ACCOUNT_TYPE = createRequestTypes('SET_ACCOUNT_TYPE');
export const MAILING_ADDRESS_VALIDATION = createRequestTypes('MAILING_ADDRESS_VALIDATION');
export const BILLING_ADDRESS_VALIDATION = createRequestTypes('BILLING_ADDRESS_VALIDATION');
export const EXISTING_ACCOUNT_VALIDATION = createRequestTypes('EXISTING_ACCOUNT_VALIDATION');
export const GET_DISCOUNTS = createRequestTypes('GET_DISCOUNTS');
export const GET_RECURRING_CREDITS = createRequestTypes('GET_RECURRING_CREDITS');
export const GET_CREATE_ACCOUNT_TAX_CODES = createRequestTypes('GET_CREATE_ACCOUNT_TAX_CODES');

export const SEND_AUTH_CODE = createRequestTypes('SEND_AUTH_CODE');
export const VERIFY_AUTH_CODE = createRequestTypes('VERIFY_AUTH_CODE');
export const SSN_EVALUATE = createRequestTypes('SSN_EVALUATE');

export const GET_ACCOUNT_CONTACT_MEMBER_NUMBER = createRequestTypes('GET_ACCOUNT_CONTACT_MEMBER_NUMBER');

export const createNewAccount = (data) => async (dispatch) => {
    dispatch(actionCreator.request(CREATE_ACCOUNT))
	try {
		const response = await axiosInstance.post(`/account`, data);
		dispatch(actionCreator.success(CREATE_ACCOUNT, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(CREATE_ACCOUNT))
		return err;
    }
};


export const getAccountTypes = () => async (dispatch) => {
    dispatch(actionCreator.request(ACCOUNT_TYPES))
	try {
		const response = await axiosInstance.get(`/domain/accounttypes`);
		dispatch(actionCreator.success(ACCOUNT_TYPES, response.data.account_types || []));
	} catch (err) {
        dispatch(actionCreator.failure(ACCOUNT_TYPES))
    }
};


export const getSecurityQuestions = () => async (dispatch) => {
    dispatch(actionCreator.request(SECURITY_QUESTIONS))
	try {
		const response = await axiosInstance.get(`/domain/securityquestions`);
		dispatch(actionCreator.success(SECURITY_QUESTIONS, response.data.security_questions || []));
	} catch (err) {
        dispatch(actionCreator.failure(SECURITY_QUESTIONS))
    }
};

// Notes: Duplicate in security.actions
// TODO: Remove duplication, and maybe move to a more common place ?
export const getCountryStates = () => async (dispatch) => {
    dispatch(actionCreator.request(COUNTRY_STATES))
	try {
		const response = await axiosInstance.get(`/domain/states`);
		dispatch(actionCreator.success(COUNTRY_STATES, response.data.states));
	} catch (err) {
        dispatch(actionCreator.failure(COUNTRY_STATES))
    }
};
//--

export const getAccountTypeDetails = (accountType) => async (dispatch) => {
	dispatch(actionCreator.request(ACCOUNT_TYPE_DETAILS))
	try {
		const response = await axiosInstance.get(`/domain/accounttype/${accountType}`);
		dispatch(actionCreator.success(ACCOUNT_TYPE_DETAILS, response.data.account_type));
		return response;
	} catch (err) {
        dispatch(actionCreator.failure(ACCOUNT_TYPE_DETAILS))
    }
}

export const getAccountTermDetails = (accountTerm) => async (dispatch) => {
	dispatch(actionCreator.request(ACCOUNT_TERM_DETAILS))
	try {
		const response = await axiosInstance.get(`/domain/accountterm/${accountTerm}`);
		dispatch(actionCreator.success(ACCOUNT_TERM_DETAILS, response.data.account_term));
		return response.data.account_term
	} catch (err) {
        dispatch(actionCreator.failure(ACCOUNT_TERM_DETAILS))
    }
}


export const setAccountType = (accountType) => async (dispatch) => {
	dispatch(actionCreator.request(SET_ACCOUNT_TYPE))
	try {
		const response = await axiosInstance.get(`/domain/accounttype/${accountType}`);
		dispatch(actionCreator.success(SET_ACCOUNT_TYPE, response.data.account_type));
		return response.data.account_type
	} catch (err) {
        dispatch(actionCreator.failure(SET_ACCOUNT_TYPE))
    }
}


export const existingAccountValidation = (accountInfo) => async (dispatch) => {
	dispatch(actionCreator.request(EXISTING_ACCOUNT_VALIDATION))
	try {
		const response = await axiosInstance.post(`/accounts/match`, accountInfo);
		dispatch(actionCreator.success(EXISTING_ACCOUNT_VALIDATION));
		return response
	} catch (err) {
		dispatch(actionCreator.failure(EXISTING_ACCOUNT_VALIDATION))
		return err.response
    }
}

export const mailingAddressValidation = (address) => async (dispatch) => {
	dispatch(actionCreator.request(MAILING_ADDRESS_VALIDATION))
	try {
		const response = await axiosInstance.post(`/account/addressvalidation`, address);
		dispatch(actionCreator.success(MAILING_ADDRESS_VALIDATION));
		return response
	} catch (err) {
        dispatch(actionCreator.failure(MAILING_ADDRESS_VALIDATION))
    }
}

export const billingAddressValidation = (address) => async (dispatch) => {
	dispatch(actionCreator.request(BILLING_ADDRESS_VALIDATION))
	try {
		const response = await axiosInstance.post(`/account/addressvalidation`, address);
		dispatch(actionCreator.success(BILLING_ADDRESS_VALIDATION));
		return response
	} catch (err) {
        dispatch(actionCreator.failure(BILLING_ADDRESS_VALIDATION))
    }
}


export const sendAuthCode =  (number) => async (dispatch) => {
	dispatch(actionCreator.request(SEND_AUTH_CODE))
	try {
		const response = await axiosInstance.post(`/common/authcode`, number);
		dispatch(actionCreator.success(SEND_AUTH_CODE));
		return response;
	} catch (err) {
		// console.log(err.response.data.error.message);
		dispatch(actionCreator.failure(SEND_AUTH_CODE))
	}
}

export const verifyAuthCode = (authCode) => async (dispatch) => {
	dispatch(actionCreator.request(VERIFY_AUTH_CODE))
	try {
		const response = await axiosInstance.post(`/common/authcode/verify`, authCode)
		dispatch(actionCreator.success(VERIFY_AUTH_CODE));
		return response;
	} catch(err) {
		dispatch(actionCreator.failure(VERIFY_AUTH_CODE))
		return err.response;
	}
}


export const getAccountDiscounts = (accountTypeId, locationId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_DISCOUNTS))
	try {
		const response = await axiosInstance.get(`/domain/discounts/${accountTypeId}/${locationId}`)
		dispatch(actionCreator.success(GET_DISCOUNTS, response.data))
	} catch (err) {
		dispatch(actionCreator.failure(GET_DISCOUNTS))
	}
}

export const getAccountRecurringCredits = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_RECURRING_CREDITS))
	try {
		const response = await axiosInstance.get(`/domain/recurringcredits`)
		dispatch(actionCreator.success(GET_RECURRING_CREDITS, response.data))
	} catch (err) {
		dispatch(actionCreator.failure(GET_RECURRING_CREDITS))
	}
}


export const getAccountTaxCodes = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_CREATE_ACCOUNT_TAX_CODES))
	try {
		const response = await axiosInstance.get(`/domain/taxcodes`)
		dispatch(actionCreator.success(GET_CREATE_ACCOUNT_TAX_CODES, response.data.taxCodes))
	} catch (err) {
		dispatch(actionCreator.failure(GET_CREATE_ACCOUNT_TAX_CODES))
	}
}



export const ssnEvaluation = (ssn, accountTermId ) => async (dispatch) => {
	dispatch(actionCreator.request(SSN_EVALUATE))
	let params = {
		ssn,
		accountTermId
	}
	try {
		const response = await axiosInstance.get(`/account/ssn/evaluate`, {params: params})
		dispatch(actionCreator.success(SSN_EVALUATE, response.data))
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(SSN_EVALUATE))
	}
}

export const getAccountContactMemberNumber = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_ACCOUNT_CONTACT_MEMBER_NUMBER))
	try {
		const response = await axiosInstance.get(`/account/capital-credit/member-number/next-available`)
		dispatch(actionCreator.success(GET_ACCOUNT_CONTACT_MEMBER_NUMBER, response.data))
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(GET_ACCOUNT_CONTACT_MEMBER_NUMBER))
	}
}

export const emptyAccounts = () => (dispatch) => {
	dispatch(actionCreator.empty(ACCOUNT_TYPES))
}

export const clearCreateAccount = () => (dispatch) => {
	dispatch(actionCreator.empty(CREATE_ACCOUNT))
}