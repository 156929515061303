import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
	getTasks,
	getFilteredTasks,
	emptyTasks,
	getSystemUsers,
	emptySystemUsers,
	assignTask,
	unassignTask
} from '../../../../actions/dashboardActions/tasks.actions';
import { getDashboardData, createLoadingSelector, getUserPermission } from '../../../../selectors';
import { isEmpty } from '../../../../utils/helpers';
import { groupStyles } from '../../../../utils/SelectStyles';
import TasksResults from './TasksResults';
import Button from "../../../../components/Common/Buttons/Button";
import DatePicker from "react-datepicker";
import moment from "moment";
import {getFormattedFilters} from "./utils";

class Tasks extends Component {

	state = {
		userTasksDataSorted: [],
		rowClasses: [],
		searchType: {
			value: this.props.username,
			label: 'My Tasks',
			placeholder: ''
		},
		filters: {
			myTasks: '',
			myGroupTasks: '',
			unclaimedTask: '',
			createdBy: '',
			fromDate: '',
			toDate: '',
			limit: 20,
			taskId: '',
			searchId: '',
			complete: false
		},
		filterType: 'limit',
		filterValue: '20',
		filterButtonText: "Last 20 Tasks",
		filterButtonTextAdditional: "",
		isFilterDropDownOpen: false,
		isFetched: false,
		showTaskDropDownById: '',
	};

	componentDidMount() {
		const { history, user, username } = this.props;

		// Apply previously selected task filters
		if (history.location.state && history.location.state.activeTile && history.location.state.activeTile.activeFilters) {

			this.setState({
				...this.state,
				...history.location.state.activeTile.activeFilters
			}, () => {
				this.changeSearchType(this.state.searchType);
				this.renderButtonText();
				if (['taskId', 'description', 'accountId', 'orderId', 'username'].includes(this.state.searchType.value)) {
					this.getTasksByFilters()
				}
			});
		}
		// Or load My Tasks by default
		else {
			this.props.getFilteredTasks({myTasks: true, limit: 20, complete: false});
		}

		// Get first name and last name for the assign in dropdown
		if (!isEmpty(user.userGroups)) {
			let userGroups = user.userGroups.map((userGroup) => userGroup.name).join(',');

			let systemUsersGroups = {
				userGroups: userGroups
			};

			this.props.getSystemUsers(systemUsersGroups);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {userTasksData} = this.props;

		// If tasks have changed
		if (prevProps.userTasksData !== userTasksData) {

			let userTasksDataSorted = userTasksData;

			// Compare every Task with the next Tasks
			userTasksDataSorted.map((task, i) => userTasksDataSorted.map((task, j) => { if (j > i) {

				// If both Tasks belong to the same Order
				if (
					!isEmpty(userTasksDataSorted[i].serviceOrderInfo) && !isEmpty(userTasksDataSorted[j].serviceOrderInfo) &&
					userTasksDataSorted[i].serviceOrderInfo.orderId === userTasksDataSorted[j].serviceOrderInfo.orderId
				) {

					// Copy second Task after the first and delete the original
					userTasksDataSorted.splice(i + 1, 0, userTasksDataSorted[j]);
					userTasksDataSorted.splice(j + 1, 1);
				}
			}}));

			// Generate table row striped classes, starting with darker
			let rowClasses = ['tr-dark'];
			let darkRow = true;

			// Go through all following tasks
			userTasksDataSorted.map((task, i) => { if (i > 0) {

				// If two adjacent Tasks belong to the same Order
				if (
					!isEmpty(userTasksDataSorted[i].serviceOrderInfo) && !isEmpty(userTasksDataSorted[i - 1].serviceOrderInfo) &&
					userTasksDataSorted[i].serviceOrderInfo.orderId === userTasksDataSorted[i - 1].serviceOrderInfo.orderId
				) {

					// Update previous task class
					rowClasses[i - 1] = 'tr-grouped ' + rowClasses[i - 1];

					// Use the same background and lighter border
					rowClasses.push((darkRow ? 'tr-grouped tr-dark ' : '') + 'tr-grouped tr-border');
				}
				// Otherwise - alternate the background
				else {
					darkRow = !darkRow;
					rowClasses.push((darkRow ? 'tr-dark ' : ''));
				}
			}});

			this.setState({
				userTasksDataSorted: userTasksDataSorted,
				rowClasses: rowClasses,
				isFetched: true
			});
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		this.props.emptySystemUsers();
		this.props.emptyTasks();
	}

	closeFilterDropdown = () => {
		this.setState({isFilterDropDownOpen: false});
	}

	handleShowTaskDetails = (taskInfo) => {
		const {history} = this.props;
		let serviceOrderId = taskInfo.serviceOrderInfo && taskInfo.serviceOrderInfo.serviceOrderId;

		let activeTile = {
			tile: 'tasks',
			activeFilters: {
				searchType: this.state.searchType,
				filters: this.state.filters,
				filterType: this.state.filterType,
				filterValue: this.state.filterValue
			},
			scrollPosition: document.body.scrollTop
		};

		// Edit history entry to enable back button functionality
		let state = { activeTile, serviceOrderId };
		history.replace({ ...history.location, state });

		// Show Task Details
		history.push('/task-details/'+taskInfo.id, { activeTile, serviceOrderId });
	};

	handleScrollToPreviousPosition = () => {
		const { history } = this.props;

		if (history.location.state && history.location.state.activeTile && history.location.state.activeTile.scrollPosition !== undefined) {
			document.body.scrollTo({top: history.location.state.activeTile.scrollPosition, behavior: 'smooth'});

			// Clear stored search and scroll position
			let state = undefined;
			history.replace({ ...history.location, state });
		}
	}

	handleClickDropdown = () => {
		let updateFilter = { ...this.state.filters };

		if (this.state.filterType !== 'dateFromTo') {
			updateFilter['fromDate'] = '';
			updateFilter['toDate'] = '';
		}

		this.setState((prevState) => ({
			filters: updateFilter,
			isFilterDropDownOpen: !prevState.isFilterDropDownOpen,
		}));
	};

	handleLimitFilter = (limit) => {

		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = limit;
		updateFilter['fromDate'] = '';
		updateFilter['toDate'] = '';

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'limit',
				filterValue: limit,
				isFetched: false
			}, () => {
				this.renderButtonText()
				this.getTasksByFilters()
			}
		);
	};

	handleLastDaysFilter = (lastDays) => {
		let fromDate = moment().subtract(lastDays, 'days').toDate();
		let toDate = moment().toDate();

		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = '';
		updateFilter['fromDate'] = fromDate;
		updateFilter['toDate'] = toDate;

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'lastDays',
				filterValue: lastDays,
				isFetched: false
			}, () => {
				this.renderButtonText()
				this.getTasksByFilters()
			}
		);
	};

	handleDayFilter = (value) => {
		let fromDate, toDate = '';

		if (value === 'today') {
			fromDate = moment().toDate();
			toDate = moment().toDate();
		}
		else if (value === 'tomorrow'){
			fromDate = moment().add(1,'days').toDate();
			toDate = moment().add(1,'days').toDate();
		}

		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = '';
		updateFilter['fromDate'] = fromDate;
		updateFilter['toDate'] = toDate;

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: value,
				filterValue: value,
				isFetched: false
			}, () => {
				this.renderButtonText()
				this.getTasksByFilters()
			}
		);
	};

	handleDateFilter = (dates) => {
		const [startDate, endDate] = dates;

		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = '';

		updateFilter['fromDate'] = startDate;
		updateFilter['toDate'] = endDate;

		// Update Dates
		this.setState({
				filters: updateFilter,
				filterType: 'dateFromTo',
			}, () => {

				// If both Start and End dates are selected
				if (startDate && endDate) {

					// Close Dropdown
					this.setState({
						isFilterDropDownOpen: false,
						isFetched: false,
					});

					this.renderButtonText()
					this.getTasksByFilters()
				}
			}
		);
	};

	renderButtonText = () => {
		const { filters, filterType, filterValue } = this.state;

		let filterButtonText = "";
		let filterButtonTextAdditional = "";

		if (filterType === 'dateFromTo') {
			filterButtonText = filters.fromDate && moment(filters.fromDate).format('MM/DD/YYYY');
			if (filters.fromDate.getTime() !== filters.toDate.getTime()) {
				filterButtonTextAdditional = filters.toDate && moment(filters.toDate).format('MM/DD/YYYY');
			}
		} else if (filterType === 'limit') {
			filterButtonText = `Last ${filterValue} Tasks`;
		} else if (filterType === 'lastDays') {
			filterButtonText = `Last ${filterValue} Days`;
		} else if (filterType === 'today') {
			filterButtonText = `Today`;
		} else if (filterType === 'tomorrow') {
			filterButtonText = `Tomorrow`;
		}

		this.setState({
			filterButtonText: filterButtonText,
			filterButtonTextAdditional: filterButtonTextAdditional,
		})
	};

	handleShowHideDropdown = (id) => {
		this.setState({
			showTaskDropDownById: this.state.showTaskDropDownById === id ? '' : id
		});
	};

	handleAssignTaskToUsername = (type, taskId, dropdownValue) => {
		const { user } = this.props;

		if (type === 'claim') {
			const data = {
				taskId,
				username: user.username
			};

			let searchTypeParams = this.getTransformedSearchTypeParams();
			this.props.assignTask(data, searchTypeParams);
		} else if (type === 'unassign') {
			const data = {
				taskId
			};
			let searchTypeParams = this.getTransformedSearchTypeParams();

			this.props.unassignTask(data, searchTypeParams);
		} else if (type === 'username') {
			const data = {
				taskId,
				username: dropdownValue.value
			};
			let searchTypeParams = this.getTransformedSearchTypeParams();

			this.props.assignTask(data, searchTypeParams);
		}

		this.setState({showTaskDropDownById: ''});
	};

	handleSearchById = (e) => {
		this.setState({
			filters: {
				...this.state.filters,
				searchId: e.target.value
			}
		});
	};

	changeSearchType = (searchValue) => {
		const { filters } = this.state;

		if (['My Tasks', 'My Group Tasks', 'Unclaimed Tasks', 'Completed Tasks'].includes(searchValue.label)) {
			let completed = searchValue.label === 'Completed Tasks';
			this.setState({
				searchType: searchValue,
				isFetched: false,
				showTaskDropDownById: '',
				placeholder: searchValue.placeholder,
				filters: { ...this.state.filters, searchId: '', complete: completed },
				isFilterDropDownOpen: false
			},
			() => {
				this.renderButtonText();
				this.props.getFilteredTasks(getFormattedFilters(filters, searchValue, searchValue.label))
					.then(() => this.handleScrollToPreviousPosition());
			});
		}
		else if (['taskId', 'description', 'accountId', 'orderId', 'username'].includes(searchValue.value)) {
			this.setState({filters: { ...this.state.filters, complete: '' }});
			// Reset search ID if selected search type is different
			if (searchValue.value !== this.state.searchType.value) {
				this.setState({filters: { ...this.state.filters, searchId: '' }});
			}

			this.setState({
				searchType: searchValue,
				isFetched: false,
				showTaskDropDownById: '',
				placeholder: searchValue.placeholder,
				isFilterDropDownOpen: false
			}, () => {
				this.renderButtonText();
			});
		}
	};

	getTransformedSearchTypeParams = () => {
		const { filters, searchType } = this.state;

		let params = {};

		if (searchType.label === 'My Tasks') {
			params = {
				username: searchType.value
			};
		} else if (searchType.label === 'My Group Tasks') {
			params = {
				group: searchType.value
			};
		}

		if (searchType.value === 'taskId') {
			return {taskId: filters.searchId};
		}
		else {
			return getFormattedFilters(filters, searchType.value, searchType.label);
		}
	};

	getTasksByFilters = () => {
		const { filters, searchType } = this.state;
		const { user } = this.props;
		if (searchType.value === 'taskId') {
			this.props.getFilteredTasks({taskId: filters.searchId})
				.then(() => this.handleScrollToPreviousPosition());
		}
		else {
			this.props.getFilteredTasks(getFormattedFilters(filters, searchType.value, searchType.label))
				.then(() => this.handleScrollToPreviousPosition());
		}
	};

	getOptions = () => {
		const { user, viewMyGroupedTaskPermission, viewUnclaimedTaskPermission } = this.props;
		// Maping username and user groups to appropriate format for react select - [label: '', value: '']

		let optionData = [];

		const myTasks = {value: user.username, label: 'My Tasks'};

		let userGroupNames = '';

		if (user.userGroups) {userGroupNames = user.userGroups.map((element) => {return element.name;}).join(',');}

		optionData.push(myTasks);

		// check for permission for view this
		if (viewMyGroupedTaskPermission) {optionData.push({value: userGroupNames, label: 'My Group Tasks'})}
		if (viewUnclaimedTaskPermission) {optionData.push({value: 'unclaimed', label: 'Unclaimed Tasks'})}

		// add search types
		optionData.push(
			{ value: 'description', label: 'Search by Task Description', placeholder: 'Search by Task Description' },
			{ value: 'taskId', label: 'Search by Task #', placeholder: 'Search by Task Number' },
			{ value: 'accountId', label: 'Search by Account #', placeholder: 'Search by Account Number' },
			{ value: 'orderId', label: 'Search by Order #', placeholder: 'Search by Order Number' },
			{ value: 'username', label: 'Search by Username', placeholder: 'Search by Username' },
			{ value: 'complete', label: 'Completed Tasks'}
		);

		return optionData;
	};

	render() {

		const {
			userTasksDataSorted,
			searchType,
			filters,
			filterType,
			filterValue,
			filterButtonText,
			filterButtonTextAdditional,
			isFilterDropDownOpen,
			isFetched,
			showTaskDropDownById
		} = this.state;

		const {
			dashboardMenuCount,
			userTasksData,
			systemUsersData,
			taskLoader
		} = this.props;

		const selectOptions = this.getOptions();

		return (
			<Fragment>
				<div className="tab-pane-caption">
					<h1 className="tab-pane-title">{dashboardMenuCount.pendingTasks || 0} Tasks</h1>
					<p className="tab-pane-text">still incomplete</p>
				</div>
				<div className="cmv-container-dashboard-search">
					<div className="cmv-container-dashboard-filter">
						<div className="container">
							<div className="form-row">
								<div className="col-md-2">

									<div className="dropdown dropdown-cn-timefilter show">

										<button
											type="button"
											onClick={this.handleClickDropdown}
											className={'btn btn-outline-secondary dropdown-toggle' + (isFilterDropDownOpen ? ' show' : '') + (filterButtonTextAdditional ? ' dropdown-toggle-multiline' : '')}
											disabled={taskLoader || searchType.value === 'taskId'}
										>
											{isEmpty(filterButtonTextAdditional)
											? filterButtonText
											: <div><span>{filterButtonText}</span><span>{filterButtonTextAdditional}</span></div>
											}
										</button>

										<div className={'dropdown-menu dropdown-menu-right' + (isFilterDropDownOpen ? ' show' : '')}
											onBlur={this.onBlurChangeActiveDropDown}
										>

											<button className="dropdown-item" onClick={() => this.handleLimitFilter(20)}>Last 20 Tasks</button>
											<button className="dropdown-item" onClick={() => this.handleLimitFilter(50)}>Last 50 Tasks</button>
											<button className="dropdown-item" onClick={() => this.handleLimitFilter(100)}>Last 100 Tasks</button>

											<div className="dropdown-divider" />

											<button className="dropdown-item" onClick={() => this.handleLastDaysFilter(30)}>Last 30 Days</button>
											<button className="dropdown-item" onClick={() => this.handleLastDaysFilter(60)}>Last 60 Days</button>
											<button className="dropdown-item" onClick={() => this.handleLastDaysFilter(90)}>Last 90 Days</button>

											<div className="dropdown-divider" />

											<button className="dropdown-item" onClick={() => this.handleDayFilter("today")}>Today’s Appointments</button>
											<button className="dropdown-item" onClick={() => this.handleDayFilter("tomorrow")}>Tomorrow’s Appointments</button>

											<div className="dropdown-divider" />

											<h6 className="dropdown-header">Filter By Date</h6>

											<div className="col">
												<div className="input-group input-group-sm date" id="datetimepicker1" data-target-input="nearest">
													<DatePicker
														className="form-control datetimepicker-input"
														selected={filters.fromDate}
														onChange={(date) => this.handleDateFilter(date)}
														startDate={filters.fromDate}
														endDate={filters.toDate}
														selectsRange
														inline
													/>
												</div>
											</div>

										</div>
									</div>

								</div>
								<div className="col-md-5">

									<Select
										id="searchType"
										name="searchType"
										value={searchType}
										onChange={this.changeSearchType}
										onFocus={this.closeFilterDropdown}
										options={selectOptions}
										defaultValue={selectOptions[0]}
										styles={groupStyles}
										isDisabled={taskLoader}
									/>

								</div>
								<div className="col-md-5">

									<div className="input-group">

										<input
											type="text"
											id={searchType.value}
											name="term"
											className="form-control"
											placeholder={searchType.placeholder}
											autoComplete="off"
											value={filters.searchId}
											onChange={this.handleSearchById}
											onFocus={this.closeFilterDropdown}
											disabled={taskLoader || ['My Tasks', 'My Group Tasks', 'Unclaimed Tasks', 'Completed Tasks'].includes(searchType.label)}
											onKeyPress={(event) => {
												if (event.key === 'Enter') {this.getTasksByFilters()}
											}}
										/>

										<div className="input-group-append">

											<Button
												type="button"
												className="btn btn-primary btn-show-cam-search-results"
												disabled={taskLoader || ['My Tasks', 'My Group Tasks', 'Unclaimed Tasks'].includes(searchType.label)}
												onClick={() => {
													this.closeFilterDropdown();
													this.getTasksByFilters();
												}}
											>
												<i className="fas fa-search" />
												<span>&nbsp;Search</span>
											</Button>

										</div>

									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

				<TasksResults
					key={'TasksResults'}
					searchType={searchType.value}
					userTasksData={userTasksDataSorted}
					systemUsersData={systemUsersData}
					taskLoader={taskLoader}
					isFetched={isFetched}
					rowClasses={this.state.rowClasses}
					showTaskDropDownById={showTaskDropDownById}
					handleShowHideDropdown={this.handleShowHideDropdown}
					handleAssignTaskToUsername={this.handleAssignTaskToUsername}
					handleShowTaskDetails={this.handleShowTaskDetails}
				/>

			</Fragment>
		);
	}
}

Tasks.propTypes = {
	user: PropTypes.object.isRequired,
	userTasksData: PropTypes.array.isRequired,
	taskLoader: PropTypes.bool,
	getTasks: PropTypes.func,
	getFilteredTasks: PropTypes.func,
	assignTask: PropTypes.func,
	unassignTask: PropTypes.func,
	emptyTasks: PropTypes.func,
	getSystemUsers: PropTypes.func,
	emptySystemUsers: PropTypes.func
};

const userTasksLoader = createLoadingSelector([ 'SET_TASKS' ]);

const mapStateToProps = (state) => {
	const userTasksData = getDashboardData(state).userTasks,
		systemUsersData = getDashboardData(state).systemUsers,
		taskLoader = userTasksLoader(state),
		viewMyGroupedTaskPermission = getUserPermission(state, 'OPER', 'OPER_VIEW_GRP_MY_TASKS'),
		viewUnclaimedTaskPermission = getUserPermission(state, 'OPER', 'OPER_VIEW_UNC_MY_TASKS');

	return {
		userTasksData,
		systemUsersData,
		taskLoader,
		viewMyGroupedTaskPermission,
		viewUnclaimedTaskPermission
	};
};

const mapDispatchToProps = {
	getTasks,
	getFilteredTasks,
	assignTask,
	unassignTask,
	emptyTasks,
	getSystemUsers,
	emptySystemUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
