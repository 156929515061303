import axiosInstance from '../utils/axios-instance';
import {actionCreator, createRequestTypes} from '../utils/helpers';
import {LOAD_BILLING_JOBS} from "./billingJobs.actions";

export const GET_TROUBLE_TICKET_AVAILABILITY = createRequestTypes('GET_TROUBLE_TICKET_AVAILABILITY');
export const GET_TROUBLE_TICKET_APPOINTMENTS = createRequestTypes('GET_TROUBLE_TICKET_APPOINTMENTS');
export const POST_TROUBLE_TICKET_APPOINTMENT = createRequestTypes('POST_TROUBLE_TICKET_APPOINTMENT');
export const PATCH_TROUBLE_TICKET_APPOINTMENT = createRequestTypes('PATCH_TROUBLE_TICKET_APPOINTMENT');
export const DELETE_TROUBLE_TICKET_APPOINTMENT = createRequestTypes('DELETE_TROUBLE_TICKET_APPOINTMENT');

export const GET_ORDER_AVAILABILITY = createRequestTypes('GET_ORDER_AVAILABILITY');
export const GET_ORDER_APPOINTMENTS = createRequestTypes('GET_ORDER_APPOINTMENTS');
export const POST_ORDER_APPOINTMENT = createRequestTypes('POST_ORDER_APPOINTMENT');
export const PATCH_ORDER_APPOINTMENT = createRequestTypes('PATCH_ORDER_APPOINTMENT');
export const DELETE_ORDER_APPOINTMENT = createRequestTypes('DELETE_ORDER_APPOINTMENT');

export const GET_APPOINTMENT_AREAS = createRequestTypes('GET_APPOINTMENT_AREAS');
export const GET_ORDER_APPOINTMENT_AREAS = createRequestTypes('GET_ORDER_APPOINTMENT_AREAS');
export const GET_TROUBLE_TICKET_APPOINTMENT_AREAS = createRequestTypes('GET_TROUBLE_TICKET_APPOINTMENT_AREAS');
export const GET_APPOINTMENT_CAPACITIES = createRequestTypes('GET_APPOINTMENT_CAPACITIES');
export const POST_APPOINTMENT_CAPACITY = createRequestTypes('POST_APPOINTMENT_CAPACITY');

export const getTroubleTicketAvailability = (troubleTicketId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_TROUBLE_TICKET_AVAILABILITY));
	try {
		const response = await axiosInstance.get(`/appointments/trouble-ticket/${troubleTicketId}/availability`);
		dispatch(actionCreator.success(GET_TROUBLE_TICKET_AVAILABILITY, response.data));
	} catch (err) {
		let mockError = {message: 'No mapping area found for given geo coordinate.'};
		dispatch(actionCreator.failure(GET_TROUBLE_TICKET_AVAILABILITY, mockError));
	}
};

export const getOrderAvailability = (orderId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ORDER_AVAILABILITY));
	try {
		const response = await axiosInstance.get(`/appointments/service-order/${orderId}/availability`);
		dispatch(actionCreator.success(GET_ORDER_AVAILABILITY, response.data));
	} catch (err) {
		let mockError = {message: 'No mapping area found for given geo coordinate.'};
		dispatch(actionCreator.failure(GET_ORDER_AVAILABILITY, mockError));
	}
};

export const getTroubleTicketAppointments = (troubleTicketId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_TROUBLE_TICKET_APPOINTMENTS));
	try {
		const response = await axiosInstance.get(`/trouble-ticket/${troubleTicketId}/appointments`);
		dispatch(actionCreator.success(GET_TROUBLE_TICKET_APPOINTMENTS, response.data.appointments));
	} catch (err) {
		let mockError = {message: 'Not found'};
		dispatch(actionCreator.failure(GET_TROUBLE_TICKET_APPOINTMENTS, mockError));
	}
};

export const getOrderAppointments = (orderId) => async (dispatch) => {
	dispatch(actionCreator.request(GET_ORDER_APPOINTMENTS));
	try {
		const response = await axiosInstance.get(`/order/${orderId}/appointments`);
		dispatch(actionCreator.success(GET_ORDER_APPOINTMENTS, response.data.appointments));
	} catch (err) {
		let mockError = {message: 'Not found'};
		dispatch(actionCreator.failure(GET_ORDER_APPOINTMENTS, mockError));
	}
};

export const emptyTroubleTicketAppointments = () => async (dispatch) => {
	dispatch(actionCreator.empty(GET_TROUBLE_TICKET_APPOINTMENTS));
};

export const emptyOrderAppointments = () => async (dispatch) => {
	dispatch(actionCreator.empty(GET_ORDER_APPOINTMENTS));
};

export const createTroubleTicketAppointment = (troubleTicketId, data) => async (dispatch) => {
	dispatch(actionCreator.request(POST_TROUBLE_TICKET_APPOINTMENT));
	try {
		const response = await axiosInstance.post(`/trouble-ticket/${troubleTicketId}/appointment`, data);
		dispatch(actionCreator.success(POST_TROUBLE_TICKET_APPOINTMENT, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(POST_TROUBLE_TICKET_APPOINTMENT));
		return err;
	}
};

export const createOrderAppointment = (orderId, data) => async (dispatch) => {
	dispatch(actionCreator.request(POST_ORDER_APPOINTMENT));
	try {
		const response = await axiosInstance.post(`/order/${orderId}/appointment`, data);
		dispatch(actionCreator.success(POST_ORDER_APPOINTMENT, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(POST_ORDER_APPOINTMENT));
		return err;
	}
};

export const getAppointmentAreas = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_APPOINTMENT_AREAS));
	try {
		const response = await axiosInstance.get(`/appointments/areas`);
		dispatch(actionCreator.success(GET_APPOINTMENT_AREAS, response.data));
		return response;
	} catch (err) {
		let mockError = {message: 'Not found'};
		dispatch(actionCreator.failure(GET_APPOINTMENT_AREAS, mockError));
	}
};

export const getOrderAppointmentAreas = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_ORDER_APPOINTMENT_AREAS));
	try {
		const response = await axiosInstance.get(`/appointments/order/areas`);
		dispatch(actionCreator.success(GET_ORDER_APPOINTMENT_AREAS, response.data));
		return response;
	} catch (err) {
		let mockError = {message: 'Not found'};
		dispatch(actionCreator.failure(GET_ORDER_APPOINTMENT_AREAS, mockError));
	}
};

export const getTroubleTicketAppointmentAreas = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_TROUBLE_TICKET_APPOINTMENT_AREAS));
	try {
		const response = await axiosInstance.get(`/appointments/trouble-ticket/areas`);
		dispatch(actionCreator.success(GET_TROUBLE_TICKET_APPOINTMENT_AREAS, response.data));
		return response;
	} catch (err) {
		let mockError = {message: 'Not found'};
		dispatch(actionCreator.failure(GET_TROUBLE_TICKET_APPOINTMENT_AREAS, mockError));
	}
};

export const getAppointmentCapacities = (params) => async (dispatch) => {
	dispatch(actionCreator.request(GET_APPOINTMENT_CAPACITIES));
	try {
		const response = await axiosInstance.get(`/appointments/queryCapacities`, {params: params});
		dispatch(actionCreator.success(GET_APPOINTMENT_CAPACITIES, response.data));
		return response;
	} catch (err) {
		let mockError = {message: 'Not found'};
		dispatch(actionCreator.failure(GET_APPOINTMENT_CAPACITIES, mockError));
	}
};

export const createAppointmentCapacity = (data) => async (dispatch) => {
	dispatch(actionCreator.request(POST_APPOINTMENT_CAPACITY));
	try {
		const response = await axiosInstance.post(`/appointments/capacity`, data);
		dispatch(actionCreator.success(POST_APPOINTMENT_CAPACITY, response.data));
		return response;
	} catch (err) {
		dispatch(actionCreator.failure(POST_APPOINTMENT_CAPACITY));
		return err;
	}
};

export const clearOrderAvailability = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_TROUBLE_TICKET_AVAILABILITY));
}

export const clearTroubleTicketAvailability = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_TROUBLE_TICKET_AVAILABILITY));
}
