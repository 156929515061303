import React, { Component, Fragment } from 'react';
import { hideModal } from '../../../actions/modal.actions';
import { changeAccountStatus } from '../../../actions/accountDetailsActions/accountStatusChange.actions';
import { connect } from 'react-redux';
import {createLoadingSelector, getModalData} from '../../../selectors';
import isEmpty, {applyOnEnum} from "src/utils/helpers";
import Select from "react-select";
import {groupStyles} from "src/utils/SelectStyles";
import {Formik} from "formik";
import moment from "moment";
import DatePicker from "react-datepicker";

class AccountStatusChangeModal extends Component {

    closeModal = () => {
        this.props.hideModal()
    }

    onAccountStatusChangeClickHandler = (values) => {
        const { accountInfo, statusTransition, onAccountStatusChange } = this.props.modalProps;

        this.props.changeAccountStatus(accountInfo.id, statusTransition.toStatus, values.applyOnType, values.applyOnDate).then(response => {
            if (response.data && response.data.success) {
                onAccountStatusChange();
                this.closeModal();
            }
        })
    }


    formSubmit = (values) => {
        this.onAccountStatusChangeClickHandler(values);
    };

    render() {
        const { statusTransition } = this.props.modalProps;
        const { changeAccountStatusLoader } = this.props;
        const applyOnOptions = [
            {label: applyOnEnum.ORDER_COMPLETION,
                value: Object.keys(applyOnEnum)[0]},
            {label: applyOnEnum.START_OF_BILLCYCLE,
                value: Object.keys(applyOnEnum)[1]},
            {label: applyOnEnum.NEXT_BILLCYCLE,
                value: Object.keys(applyOnEnum)[2]},
            {label: applyOnEnum.APPLY_ON_DATE,
                value: Object.keys(applyOnEnum)[3]}];

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <Formik
                        initialValues={{
                            applyOnType: Object.keys(applyOnEnum)[0], // ORDER_COMPLETION
                            applyOnDate: null,
                        }}
                      //  validationSchema={NoteSchema}
                        onSubmit={this.formSubmit}
                        render={({ handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     formProps,
                                     isSubmitting,
                                     setFieldValue}) => (
                            <form className="cmv-form" onSubmit={handleSubmit}>
                                <div className="modal-dialog modal-md" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                {statusTransition.name} ACCOUNT
                                            </h5>
                                            <button type="button" className="close" onClick={this.closeModal}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body form-horizontal" style={{overflowY:'visible'}}>
                                            <div className="form-group">
                                                <div>
                                                    <span>You are about to update the account status from {statusTransition.fromStatus} to {statusTransition.toStatus}</span>
                                                </div>
                                                &nbsp;
                                                <div>
                                                    <label>Apply On</label>
                                                    <select
                                                        className={
                                                            errors.applyOnType && touched.applyOnType ? (
                                                                'form-control is-invalid'
                                                            ) : (
                                                                'form-control customer-notes-cat'
                                                            )
                                                        }
                                                        value={values.applyOnType}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="applyOnType"
                                                    >
                                                        {applyOnOptions.map((type) => (
                                                            <option value={type.value}>
                                                                {type.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    &nbsp;
                                                    {values.applyOnType === Object.keys(applyOnEnum)[3] &&
                                                    <div className="input-group mr-2">
                                                        <label>Date</label>
                                                        <DatePicker
                                                            className={"form-control datetimepicker-input" + (touched.applyOnDate && errors.applyOnDate ? " is-invalid" : "")}
                                                            fieldName="applyOnDate"
                                                            dateFormat="MM/dd/yyyy"
                                                            placeholderText="MM/DD/YYYY"
                                                            autoFocus={false}
                                                            showTimeSelect={false}
                                                            shouldCloseOnSelect={true}
                                                            popperPlacement={'bottom'}
                                                            selected={values.applyOnDate !== '' ? values.applyOnDate : null}
                                                            onChange={(applyOnDate) => setFieldValue('applyOnDate', new Date(applyOnDate.getTime()))}
                                                            minDate={moment().toDate()}
                                                        />
                                                    </div>
                                                    }
                                                    {errors.applyOnType &&
                                                    touched.applyOnType && (
                                                        <div className="invalid-feedback">{errors.applyOnType}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button disabled={isSubmitting} onClick={this.closeModal} className="btn">
                                                CANCEL
                                            </button>
                                            <button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="btn btn-primary">
                                                CONTINUE
                                                {changeAccountStatusLoader &&
                                                <i className="fas fa-fw fa-spin fa-spinner" style={{marginLeft: '5px'}}/>}
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        )
    }

}

const changeAccountStatusLoaderSelector = createLoadingSelector(['CHANGE_ACCOUNT_STATUS']);

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;
    const changeAccountStatusLoader = changeAccountStatusLoaderSelector(state);
    return {
        modalProps,
        changeAccountStatusLoader
    }
};

const mapDispatchToProps = {
    changeAccountStatus,
    hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountStatusChangeModal);