import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {getProvisioningQueueGroup} from "../../../../actions/provisioning.actions";
import {toastr} from "react-redux-toastr";
import isEmpty, {getErrorMessage} from "../../../../utils/helpers";
import Group from "../../../ProvisioningQueue/Group";
import Loader from "../../../../components/Loader";

class Status extends Component {

    state = {
        provQueueGroup: null,
        numIncompleteProvQueueEntries: 1,
    }

    componentDidMount() {
        const {
            accumulatedValues,
        } = this.props;

        this.fetchProvisioningQueueGroupDetails(accumulatedValues.provisioningQueueGroupId);
    }

    componentWillUnmount() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    }

    fetchProvisioningQueueGroupDetails = (provisioningQueueGroupId) => {
        console.log("Fetching ProvisioningQueueGroup Details");
        this.props.getProvisioningQueueGroup(provisioningQueueGroupId).then(resp => {
            if (resp.success || resp.data.success) {
                let provisioningQueueGroup = null;
                if (resp.data.provisioningQueueGroup !== undefined) {
                    provisioningQueueGroup = resp.data.provisioningQueueGroup;
                }
                if ((resp.data.status !== "NEW") && (!isEmpty(provisioningQueueGroup.groupItems))) {
                    // Determine if there are outstanding prov queue group entries still to be processed and set state
                    let totalEntries = 0;
                    let numComplete = 0;
                    for (const groupEntry of provisioningQueueGroup.groupItems) {
                        totalEntries = totalEntries + 1;
                        if ((groupEntry.status !== "PROCESSING") && (groupEntry.status !== "NEW")) {
                            numComplete = numComplete + 1;
                        }
                    }
                    this.setState({numIncompleteProvQueueEntries: (totalEntries-numComplete)});
                    if ((totalEntries-numComplete) > 0) {
                        this.timerHandle = setTimeout(this.fetchProvisioningQueueGroupDetails, 500, provisioningQueueGroupId);
                    }
                } else {
                    this.timerHandle = setTimeout(this.fetchProvisioningQueueGroupDetails, 500, provisioningQueueGroupId);
                }
                this.setState({provQueueGroup: provisioningQueueGroup})
            } else {
                toastr.error(getErrorMessage(resp).message, {timeOut: 5000, position: 'top-center'});
            }
        }).catch(error => {
            toastr.error(getErrorMessage(error).message, { timeOut: 5000, position: 'top-center' });
        })

    }

    render() {
        const {
            provQueueGroup,
            numIncompleteProvQueueEntries
        } = this.state;

        const {
            accumulatedValues,
        } = this.props;

        return (
            // <div className="text-center">
            <div className="provisioning">

                {numIncompleteProvQueueEntries > 0 &&
                    <>
                        <Loader/>
                        <p className="text-success lead">This may take a few minutes for the validation to process.</p>
                    </>
                }

                {provQueueGroup !== null &&
                    <Group
                        key={provQueueGroup.id}
                        provisioningQueueGroup={provQueueGroup}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const provisioningQueueGroupId = props.accumulatedValues.provisioningQueueGroupId;
    return {
        provisioningQueueGroupId,
    };
};

const mapDispatchToProps = {
    getProvisioningQueueGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);