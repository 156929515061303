import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import { hideModal } from "../../../actions/modal.actions";
import { getErrorMessage, getModalData } from "../../../selectors";
import { downloadNotificationAttachment } from "src/actions/notifications.actions";


/**
 * View the details of notification
 */
class HtmlNotificationModal extends Component {
  closeModal = () => {
    this.props.hideModal();
  };

  componentDidUpdate(prevProps) {
    if (this.props.downloadError && !prevProps.downloadError) {
      toastr.error(this.props.downloadError);
    }
  }

  downloadAttachment = (idx) => {
    this.props.downloadNotificationAttachment(this.props.modalProps.notificationQueueId, idx);
  };

  render() {
    const { notificationSubject, notificationContent, notificationAttachments } = this.props.modalProps;

    return (
      <>
        <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-wide" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{notificationSubject}</h5>
                <button onClick={this.closeModal} type="button" className="close">
                  <span>&times;</span>
                </button>
              </div>

              <div className="modal-body modal-body-full">
                <iframe
                  ref={"iframe"}
                  title="Notification Content"
                  srcDoc={notificationContent}
                  onLoad={() => {
                    this.refs.iframe.style.height =
                      this.refs.iframe.contentWindow.document.body.parentElement.getBoundingClientRect().height + "px";
                  }}
                />

                {notificationAttachments && (
                  <div class="container">
                    <hr />
                    <h5>Attachments:</h5>
                    {notificationAttachments.map((attachment, i) => {
                      return attachment.startsWith("/") ? (
                        <button
                          className="btn btn-link"
                          key={`notification_attachment_${i}`}
                          onClick={() => this.downloadAttachment(i)}
                        >
                          Attachment #{i + 1}
                        </button>
                      ) : (
                        <a key={`notification_attachment_${i}`} href={attachment} target="_blank" rel="noreferrer">
                          Attachment #{i + 1}
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="modal-footer">
                <button onClick={this.closeModal} className="btn btn-primary">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </>
    );
  }
}

const getDownloadError = getErrorMessage(['DOWNLOAD_NOTIFICATION_QUEUE'])

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps,
    downloadError = getDownloadError(state);

  return {
    modalProps,
    downloadError,
  };
};

const mapDispatchToProps = {
  hideModal,
  downloadNotificationAttachment,
};

export default connect(mapStateToProps, mapDispatchToProps)(HtmlNotificationModal);
