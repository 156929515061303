import React, {Component, Fragment} from 'react';
import Select from 'react-select';
import {formSelectStyles} from '../../utils/SelectStyles';
import {get} from 'lodash';
import isEmpty from "../../utils/helpers";

/**
 * @property {any[]} props.options
 * @property {bool} props.isMulti
 * @property {any} props.value
 * @property {string} props.placeholder
 * @property {string} props.title
 * @property {string} props.info
 * @property {string} props.errors
 * @property {boolean} props.touched
 * @property {string} props.fieldName
 * @property {bool} props.isDisabled
 * @property {bool} props.isLoading
 * @property {bool} props.isClearable,
 * @property {func} props.setFieldValue
 * @property {func} props.onChange
 * @property {func} props.onInputChange
 * @property {func} props.onPostChange
 */
class FormSelect extends Component {

    state = {
        isClearable: false
    }

    handleChange = value => {
            // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.fieldName, value.value);
    };

    handleBlur = (...args) => {
        // this is going to call setFieldTouched and manually update touched.topcis
        if (typeof this.props.onBlur === 'function')
            this.props.onBlur.call(this, ...args);
    };

    // Change type with name like TextInput
    render() {
        const {options, isMulti, value, placeholder, title, info, errors, touched, fieldName, isDisabled, isLoading, isClearable,
            setFieldValue, onChange, onInputChange, onPostChange} = this.props;

        const getValue = () => {
            if (!isEmpty(options)) {
                return  isMulti
                    ? options.filter(option => option.value.id ? value.find(curVal => curVal.id === option.value.id) : value.indexOf(option.value) >= 0)
                    : options.find(option => option.value === value) || "";
            } else {
                return isMulti ? [] : ("");
            }
        };

        const onChangeInternal = (option) => {
            if(option !== null){
                if (!isEmpty(onChange)) {
                    onChange(fieldName, option.value);
                    return;
                }

                const newValue = isMulti
                    ? (option).map((item: Option) => item.value)
                    : (option).value;

                setFieldValue(
                    fieldName,
                    newValue
                );

                if (!isEmpty(onPostChange)) {
                    onPostChange(options, newValue);
                }
            } else {
                setFieldValue(
                    fieldName,
                    null
                )
            }
            }

        return (
            <Fragment>

                {title &&
                <label htmlFor={fieldName}>{title}</label>
                }

                {info &&
                <i className="fas fa-fw fa-info-circle text-info" data-toggle="tooltip" data-html="true" title={info} />
                }

                <Select
                    inputId={fieldName}
                    name={fieldName}
                    options={options}
                    isMulti={isMulti}
                    onChange={onChangeInternal}
                    onInputChange={onInputChange}
                    onBlur={this.handleBlur}
                    value={getValue()}
                    placeholder={placeholder}
                    styles={formSelectStyles}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    className={get(errors, `${fieldName}`) && "is-invalid" || ""}
                />

                {get(errors, `${fieldName}`) && (
                    <div className="invalid-feedback">{get(errors, `${fieldName}`)}</div>
                )}

            </Fragment>
        );
    }
}


export default FormSelect