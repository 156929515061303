import {
  LIST_NOTIFICATION_CATEGORIES,
  LIST_NOTIFICATIONS_SIMPLE,
  LIST_NOTIFICATIONS,
  GET_NOTIFICATION,
  LIST_NOTIFICATION_TEMPLATES,
  GET_NOTIFICATION_TEMPLATE,
} from "../actions/notifications.actions";

let initialState = {
  notificationCategories: [],
  notifications: [],
  notification: [],
  notificationTemplates: [],
  notificationTemplate: [],
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_NOTIFICATION_CATEGORIES.SUCCESS:
      return {
        ...state,
        notificationCategories: action.payload,
      };
    case LIST_NOTIFICATION_CATEGORIES.CLEAR:
      return {
        ...state,
        notificationCategories: [],
      };
    case LIST_NOTIFICATIONS_SIMPLE.SUCCESS:
    case LIST_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case LIST_NOTIFICATIONS_SIMPLE.CLEAR:
    case LIST_NOTIFICATIONS.CLEAR:
      return {
        ...state,
        notifications: [],
      };
    case GET_NOTIFICATION.SUCCESS:
      return {
        ...state,
        notification: action.payload,
      };
    case GET_NOTIFICATION.CLEAR:
      return {
        ...state,
        notification: [],
      };
    case LIST_NOTIFICATION_TEMPLATES.SUCCESS:
      return {
        ...state,
        notificationTemplates: action.payload,
      };
    case LIST_NOTIFICATION_TEMPLATES.CLEAR:
      return {
        ...state,
        notificationTemplates: [],
      };
    case GET_NOTIFICATION_TEMPLATE.SUCCESS:
      return {
        ...state,
        notificationTemplate: action.payload,
      };
    case GET_NOTIFICATION_TEMPLATE.CLEAR:
      return {
        ...state,
        notificanotificationTemplatetion: [],
      };
    default:
      return state;
  }
};
