import React, {Component, Fragment, useState} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import Card from './Card';
import { generateAddressNew } from '../../utils/generateAddresses';
import { statusColor } from '../../utils/ui';
import { isEmpty } from '../../utils/helpers';
import moment from 'moment';
import {createLoadingSelector, getAccountDetails, getGlobalFlags} from "../../selectors";
import {
	emptyAccountBalance,
	emptyDueDetails,
	getAccountBalance,
	getDueDetails,
	getBalancesInfo,
	getBillingSettings
} from "../../actions/accountDetailsActions/billing.actions";
import {getAllContacts, emptyAllContacts} from "../../actions/accountDetailsActions/security.actions";
import {toastr} from "react-redux-toastr";
import {cancelAccountStatusTransition} from "src/actions/accountDetailsActions/accountStatusChange.actions";
import {apiTargetDateTimeFormat, defaultDateFormat, parseDateTimeWithFormat} from "src/utils/dateTimeParseUtils";
import {showModal} from '../../actions/modal.actions';

const disabledBtnStyle = {
	opacity: '.5',
	cursor: 'not-allowed',
}

class AccountInfoBox extends Component {

	state = {
		isContactsExpanded: false,
		accountBalanceFetchAttemptCount: 0,
		fetchBalanceTimer: null
	}


	componentDidMount() {
		this.init();
	}

	init = () => {
		const { accountInfo, hideCardsContainer, hideCardsTaskDetails } = this.props;

		if (accountInfo && !hideCardsContainer && !hideCardsTaskDetails) {
			this.props.getDueDetails(accountInfo.id);
			this.props.getAccountBalance(accountInfo.id);
		}

		if (!isEmpty(accountInfo)) {
			this.props.getAllContacts(accountInfo.id);
			this.props.getBillingSettings(accountInfo.id);
		}


		const fetchBalanceTimer = setInterval(this.fetchUpdatedBalance, 6000)
		this.setState({fetchBalanceTimer: fetchBalanceTimer})
	}

	toggleContacts = () => {
		this.setState({
			isContactsExpanded: !this.state.isContactsExpanded,
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { getAccountBalance, getDueDetails } = this.props
		const { accountPostPayment, accountBalanceFetchAttemptCount} = this.state
		const wasTaskDetailsVisible = !prevProps.hideCardsContainer && !prevProps.hideCardsTaskDetails;
		const taskDetailsVisibleNow = !this.props.hideCardsContainer && !this.props.hideCardsTaskDetails;
		const currentAccountId = this.props.accountInfo && this.props.accountInfo.id;

		if (!wasTaskDetailsVisible && taskDetailsVisibleNow && currentAccountId) {
			const prevAccountId = prevProps.accountInfo && prevProps.accountInfo.id;

			if (currentAccountId !== prevAccountId || (!prevProps.dueDate || !prevProps.accountBalance)) {
				getDueDetails(this.props.accountInfo.id);
				getAccountBalance(this.props.accountInfo.id);
			}
		}

		if (prevProps.paymentSubmittedFlag !== this.props.paymentSubmittedFlag) {
			this.init();
		}

		if(prevProps.accountPostPayment !== this.props.accountPostPayment) {
			this.setState({accountBalanceFetchAttemptCount: 0})
			getAccountBalance(this.props.accountInfo.id);
		}
	}


	componentWillUnmount() {
		this.props.emptyDueDetails();
		this.props.emptyDueDetails();
		this.props.emptyAccountBalance();
		this.props.emptyAllContacts();
		clearInterval(this.state.fetchBalanceTimer)
	}

	handleCancelAccountStatusTransition = (accountInfo, onAccountStatusChange) => {
		this.props.showModal('GENERIC_MODAL', {
			title: "Cancel Account Status Transition",
			text: "Cancel " + accountInfo.accountTransition.toStatus + " on \n" + parseDateTimeWithFormat(accountInfo.accountTransition.applyOnDate, apiTargetDateTimeFormat),
			size: 'md',
			cancelText: "No",
			okText: "Yes",
			onOk: () => {this.props.cancelAccountStatusTransition(accountInfo.accountTransition.accountId,
				accountInfo.accountTransition.processInstanceId).then(response => {
					onAccountStatusChange();
			})}
		});
	};


	renderTotalBalance = (accountBalance, dueDate) => {
		const postPayment = this.props.accountPostPayment

		const dueDateText = dueDate? `Due on ${moment(dueDate).format('MM/DD/YYYY')}` : ''
		if(accountBalance) {
			let balanceText = `$${accountBalance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} ${dueDateText}`
			if(postPayment) {
				return (
					<>
						<p>{balanceText}</p>
						<p>{`(Payment of $${postPayment.toFixed(2)} is pending)`}</p>
					</>
				)
			}
			return (
				<div>
					{balanceText}
				</div>
			)
		} else {
			return '$0.00'
		}
	}

	/**
	 * We fetch an updated balance if there's a postPayment every 6 sec for three attempts
	 */
	fetchUpdatedBalance = () => {
		if(!this.props.accountPostPayment || this.state.accountBalanceFetchAttemptCount >= 3) {
			clearInterval(this.state.fetchBalanceTimer)
			return
		}
		if(this.props.accountPostPayment) {
			this.props.getBalancesInfo(this.props.accountInfo.id);
			this.setState({accountBalanceFetchAttemptCount: this.state.accountBalanceFetchAttemptCount + 1})
		}
	}

	callPhoneNumber = (phoneNumber) => {
		if(phoneNumber) {
			return "tel:" + phoneNumber.toString()
		}
	}

	render() {

		const {
			isContactsExpanded,
		} = this.state;

		const {
			accountInfo,
			hideCardsContainer,
			hideCardsTaskDetails,
			showStatusDropdown,
			accountPromises,
			handleStatusDropDown,
			isStatusDropDownOpen,
			handleShowAccountStatusChangeModal,
			accountAllowedStatusTransitions,
			accountAllowedStatusTransitionsLoading,
			width,
			dueDate,
			dueDateLoading,
			accountBalance,
			accountBalanceLoading,
			accountContacts,
			accountContactsLoading,
			accountBillingSettings,
			onAccountStatusChange
		} = this.props;

		if (isEmpty(accountInfo)) {
			return null;
		}

		const address =
			accountInfo.primaryContact &&
			accountInfo.primaryContact.contactAddresses &&
			accountInfo.primaryContact.contactAddresses.find((x) => x.type === 'BILLING');

		let addressInfo = '';

		const maxWidthScreen = 767; //px

		if (address) {
			addressInfo = generateAddressNew(address);
		}

		return (
			<div className="cmv-container-client cmv-container-client-modern">

				<div className="cmv-client-data-basic col-sm-5 col-md-4 col-lg-5 col-xl-4">
					<div className="container">
						<div className="cmv-client">

							<div className="cmv-client-header">

								<div className="cmv-client-details">

									<h2 className="cmv-client-name">
										{accountInfo.primaryContact && accountInfo.primaryContact.company ? (
											`${accountInfo.primaryContact.company}`
										) : (
											`${accountInfo.primaryContact.firstName} ${accountInfo.primaryContact.lastName}`
										)}
									</h2>

									{addressInfo && (
										<p className="cmv-client-address">
											{/*<i className="fas fa-file-invoice-dollar"/>*/}
											{addressInfo}
										</p>
									)}

									<button
										className={'cmv-client-status cmv-client-status-' + statusColor(accountInfo.tierLevel) + (hideCardsContainer || !showStatusDropdown ? '' : ' dropdown')}
										onClick={() => handleStatusDropDown(false)}
										onBlur={() => handleStatusDropDown(true)}
									>

										{accountAllowedStatusTransitionsLoading &&
											<i className="fas fa-fw fa-spin fa-spinner" style={{marginLeft: '5px'}}/>
										}

										{!accountAllowedStatusTransitionsLoading &&
											<span className="status-text">{accountInfo.status} {accountInfo.wfStatus ? '(' + accountInfo.wfStatus + ')' : null}</span>
										}

										<div className={`status-bar status-bar-${accountInfo.tierLevel * 20}`}></div>

										{!accountAllowedStatusTransitionsLoading &&
											<div
												className={isStatusDropDownOpen && !hideCardsContainer && !isEmpty(accountAllowedStatusTransitions) ? ('dropdown-menu dropdown-menu-right show') : ('dropdown-menu dropdown-menu')}
												style={width <= maxWidthScreen ? {
													position: 'absolute',
													willChange: 'transform',
													top: '0px',
													left: '0px',
													transform: 'translate3d(-14px, 18px, 0px)',
													right: 'auto'
												} : {}}>
												{accountAllowedStatusTransitions && accountAllowedStatusTransitions
													.sort((a, b) => {
														let compRes = a.order - b.order;

														if (compRes === 0) {
															compRes = a.description.localeCompare(b.description);
														}

													return compRes;
												})
												.map(statusTransition =>
													<a onClick={() => !statusTransition.disabled && handleShowAccountStatusChangeModal(statusTransition)}
													   key={statusTransition.name}
													   disabled={statusTransition.disabled}
													   style={statusTransition.disabled && disabledBtnStyle}
													   className="dropdown-item">{statusTransition.description}</a>
												)}
										</div>
										}
									</button>
									{accountInfo && accountInfo.accountTransition &&
									<div>
										<button
											className={'cmv-client-status cmv-client-status-' + statusColor(accountInfo.tierLevel) + (hideCardsContainer || !showStatusDropdown ? '' : ' dropdown')}
											onClick={() => this.handleCancelAccountStatusTransition(accountInfo, onAccountStatusChange)}

										>
											<i style={{marginLeft: "10px"}}className="fas fa-ban text-danger"/>
											<p className="cmv-client-address text-danger"> ({accountInfo.accountTransition.toStatus} on {parseDateTimeWithFormat(accountInfo.accountTransition.applyOnDate, defaultDateFormat)} )</p>
										</button>
									</div>
									}
								</div>


							</div>

						</div>
					</div>
				</div>

				<div className="cmv-client-data-basic col-sm-5 col-md-4 col-lg-5 col-xl-4">


					{!hideCardsContainer && (
						<>
							<div className="container">

								<Card
									header="Account #"
									body={accountInfo.number ? accountInfo.number : ''}
									icon="fas fa-fw fa-hashtag"
								/>
								<p style={{whiteSpace:'pre-wrap'}}>
									{accountBillingSettings?.type?.name && `${accountBillingSettings?.type?.name} / `}
									{accountBillingSettings?.terms?.name && `${accountBillingSettings?.terms?.name} / `}
									{accountBillingSettings?.billCycle?.billCycle && `Bill Cycle ${accountBillingSettings.billCycle.billCycle}`}
								</p>
								<Card
									header="Since"
									body={accountInfo.createdDateTime
										? moment(accountInfo.createdDateTime).format('MM/DD/YYYY')
										: ''
									}
									icon="fas fa-fw fa-clock"
								/>

							</div>
						</>
					)}

				</div>

				<div className="cmv-client-data-basic col-sm-5 col-md-4 col-lg-5 col-xl-4" style={{alignItems:'flex-start'}}>

					{!hideCardsTaskDetails && (
						<div className='container' style={{display:'flex', flexWrap:'wrap', flexDirection:'row', gap:'1vw', alignItems:'center'}}>
							<p>Total Balance: </p>
							{accountBalanceLoading ?
								<i className="fas fa-fw fa-spin fa-spinner"/> :
								<p className={new Date() > new Date(dueDate) ? 'text-danger' : ''} style={{flexWrap:'wrap'}}>{this.renderTotalBalance(accountBalance, dueDate)}</p>}
						</div>
					)}
				</div>

				{hideCardsTaskDetails && <>

					<div className={"cmv-client-data-contact cmv-client-data-contact-primary visible"} style={{borderBottom:"none", borderTop:"none"}}>

						<div className="cmv-client-data-basic col-sm-4 col-md-4 col-lg-3 col-xl-4">
							<div className="container">
								<h5>
									<strong>{accountInfo.primaryContact.firstName} {accountInfo.primaryContact.lastName}</strong>
									{" (Primary)"}
								</h5>
							</div>
						</div>

						<div className="cmv-client-data-extended col-sm-8 col-md-8 col-lg-9 col-xl-8">
							<div className="container">
								{!isEmpty(accountInfo.primaryContact.contactNumbers)
									? accountInfo.primaryContact.contactNumbers.map(number => <p>{number.numberType}: <a href={this.callPhoneNumber(number.number)}>{number.number}</a></p>)
									: <p>No numbers found.</p>
								}
							</div>
						</div>

					</div>

					{accountContacts.filter(contact => contact.id !== accountInfo.primaryContact.id && contact.authorized).map(contact =>
						<div className={"cmv-client-data-contact cmv-client-data-contact-authorized" + (isContactsExpanded ? " visible" : "")}>

							<div className="cmv-client-data-basic col-sm-4 col-md-4 col-lg-3 col-xl-4">
								<div className="container">
									<h5>
										<strong>{contact.firstName} {contact.lastName}</strong>
										{contact.authorized ? " (Authorized)" : ""}
									</h5>
								</div>
							</div>

							<div className="cmv-client-data-extended col-sm-8 col-md-8 col-lg-9 col-xl-8">
								<div className="container">
									{!isEmpty(contact.numbers)
										? contact.numbers.map(number => <p>{number.numberType}: {number.number}</p>)
										: <p>No numbers found.</p>
									}
								</div>
							</div>

						</div>
					)}

					{accountContacts.filter(contact => contact.id !== accountInfo.primaryContact.id && contact.authorized).length > 0 &&
						<div
							className={"cmv-client-data-toggle" + (isContactsExpanded ? " cmv-client-data-toggle-expanded" : "")}
							onClick={this.toggleContacts}
						>
							Show All Contacts
							<i className="fas fa-angle-double-down"/>
						</div>
					}

				</> }

			</div>
		);
	}
}

AccountInfoBox.propTypes = {
	accountInfo: PropTypes.object.isRequired,
	hideCardsContainer: PropTypes.bool,
	showStatusDropdown: PropTypes.bool,
	handleStatusDropDown: PropTypes.func,
	isStatusDropDownOpen: PropTypes.bool,
	accountStatus: PropTypes.object,
	accountAllowedStatusTransitions: PropTypes.array,
	accountAllowedStatusTransitionsLoading: PropTypes.bool,
};

const billingSettingsLoaderSelector = createLoadingSelector(['GET_BILLING_SETTINGS']);
const accountBalanceLoadingSelector = createLoadingSelector([ 'GET_ACCOUNT_BALANCE' ]);
const dueDateLoadingSelector = createLoadingSelector([ 'GET_DUE_DATE' ]);
const accountContactsLoadingSelector = createLoadingSelector(['SET_ALL_CONTACTS'])

const mapStateToProps = (state) => {
	const dueDate = getAccountDetails(state).billing.dueDate,
		dueDateLoading = dueDateLoadingSelector(state),
		accountBalance = getAccountDetails(state).billing.balance,
		accountPostPayment = getAccountDetails(state).billing.balancesInfo.postPaymentAmount,
		accountBalanceLoading = accountBalanceLoadingSelector(state),
		accountContacts = getAccountDetails(state).security.allContacts,
		accountContactsLoading = accountContactsLoadingSelector(state),
		paymentSubmittedFlag = getGlobalFlags(state).paymentSubmittedFlag,
		accountBillingSettings = getAccountDetails(state).billing.billingSettings;


	return {
		dueDate,
		dueDateLoading,
		accountBalance,
		accountPostPayment,
		accountBalanceLoading,
		accountContacts,
		accountContactsLoading,
		paymentSubmittedFlag,
		accountBillingSettings,
	};
};

const mapDispatchToProps = {
	getAccountBalance,
	emptyAccountBalance,
	getDueDetails,
	emptyDueDetails,
	getAllContacts,
	emptyAllContacts,
	getBalancesInfo,
	getBillingSettings,
	cancelAccountStatusTransition,
	showModal
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountInfoBox);