import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Field, Formik} from 'formik';
import { showModal, hideModal } from '../../../actions/modal.actions';
import JoditEditor from "jodit-react";
import {getModalData} from "../../../selectors";
import Loader from "../../../components/Loader";
class PackageOptionDetailedDescriptionModal extends Component {

    state = {
        note: this.props.modalProps.packageOption.note,
    };


    closeModal = () => {
        this.props.hideModal();
        this.props.showModal('ADD_FEATURE_PACKAGE_MODAL', {
            value: this.props.modalProps.featurePackageId
        })};


    formSubmit = (values) => {
        this.closeModal();
        if (this.props.modalProps.onCloseCallback) {
            this.props.modalProps.onCloseCallback(this.props.modalProps.packageOptions,
                this.props.modalProps.packageOption,this.state.note, this.props.modalProps.setFieldValue);
        }
        this.props.showModal('ADD_FEATURE_PACKAGE_MODAL', {
            value: this.props.modalProps.featurePackageId
        })};


    render() {

        return (
            <Fragment>
                <div className="modal" style={{display: 'block'}} tabIndex="-1" role="dialog">
                    <Formik
                        initialValues={{
                            note: this.state.note|| ''
                        }}
                        // validationSchema={this.systemUserSchema()}
                        onSubmit={this.formSubmit}
                        render={({
                                     handleChange,
                                     handleSubmit,
                                     handleBlur,
                                     values,
                                     errors,
                                     touched,
                                     isSubmitting,
                                     setFieldValue
                                 }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">
                                                Detail Description
                                            </h5>
                                            <button onClick={this.closeModal} type="button" className="close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body form-horizontal">
                                            {/*!isEmpty(alertMessage) && (
                                                <div className="alert alert-inline alert-danger alert-dismissible">
                                                    <p className="mb-0">{alertMessage}</p>
                                                </div>
                                            )*/}
                                            <div className="form-group">
                                                <JoditEditor
                                                    name={"note"}
                                                    value={values.note}
                                                    config={{
                                                        minHeight: 400,
                                                        buttons: ['bold','italic','underline','strikethrough',
                                                            'ul','ol','font','fontsize','brush','paragraph','superscript','subscript',
                                                            'image','video','file','hr','table','link','symbol','indent',
                                                            'source','fullsize','preview'
                                                        ],
                                                        readonly: false,
                                                        toolbarAdaptive: false,
                                                        showCharsCounter: false,
                                                        showWordsCounter: false,
                                                        showXPathInStatusbar: false,
                                                    }}
                                                    //  config={config}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onBlur={newContent => {this.setState({
                                                        note: newContent
                                                    })}}
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.closeModal} className="btn" type="button">
                                                Cancel
                                            </button>
                                            <button disabled={this.state.submitForm} onClick={this.updateSubmitFormState} type="submit" className="btn btn-primary">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
                <div className="modal-backdrop show" tabIndex="1"/>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps;

    return {
        modalProps
    };
};

const mapDispatchToProps = {
    hideModal,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(PackageOptionDetailedDescriptionModal);

