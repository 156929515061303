import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import isEmpty from "src/utils/helpers";
import FormSelect from "src/components/UI/FormSelect";
import TextInput from "src/components/UI/TextInput";

import { hideModal } from "src/actions/modal.actions";
import { getModalData, getUser, selectNotificationsState } from "src/selectors";
import {
  getNotificationCategories,
  getNotification,
  addNotification,
  updateNotification,
  clearNotification,
} from "src/actions/notifications.actions";
import LoadingModalContent from "src/components/Modal/LoadingModalContent";

/**
 * Add/Edit a notification
 *
 * @param {any} modalProps.value - Notification object
 * @param {(item) => void} modalProps.onSuccess - callback function to be called on successful update, called with updated item
 * @param {any*} modalProps.notificationCategries - Notification categories list
 */
class EditNotificationModal extends Component {
  state = {
    alertMessage: "",
    isLoading: true,
  };

  componentDidMount() {
    const { modalProps } = this.props;
    const notificationCategories = modalProps.notificationCategories || this.props.notificationCategories

    Promise.all([
      notificationCategories ? notificationCategories : this.props.getNotificationCategories(),
      modalProps.value ? this.props.getNotification(modalProps.value.id) : this.props.clearNotification(),
    ]).then(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  componentWillUnmount() {}

  formSchema = () => {
    let validationSchema = {
      name: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      notificationCategoryId: Yup.string().required("Required"),
    };

    return Yup.object().shape(validationSchema);
  };

  hideModal = () => {
    this.props.clearNotification();
    this.props.hideModal();
  };

  formSubmit = (values, actions) => {
    this.setState({ alertMessage: "" }, () => {
      const postData = {
        ...values,
        name: values.name.toUpperCase(),
      };

      const promise = this.props.modalProps.value?.id
        ? this.props.updateNotification(this.props.modalProps.value.id, postData)
        : this.props.addNotification(postData);

      promise.then(
        (response) => {
          if (!response.data?.success) {
            throw new Error("Unknown error.");
          }
          if (typeof this.props.modalProps.onSuccess === 'function')
            this.props.modalProps.onSuccess(response.data?.notification);
          this.hideModal();
        },
        (err) => {
          if (!err.response.data) {
            actions.setSubmitting(false);
            this.setState({
              alertMessage: "Unknown error. Please try again later",
            });
            return;
          }

          if (!err.response.data.success) {
            actions.setSubmitting(false);
            this.setState({ alertMessage: err.response.data.error.message });
            return;
          }
        }
      );
    });
  };

  scrollDropdownIntoView = (e) => {
    const elementClicked = e.target.nodeName;
    const boundingElement = e.currentTarget;
    const modalBody = document.getElementsByClassName("modal-body")[0];

    if (elementClicked !== "LABEL") {
      setTimeout(function () {
        // Scroll down if the bottom is hidden...
        if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {
          // ... and the top won't end up hidden by scrolling down
          if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {
            // Scroll down till bottom of element reaches bottom of screen
            boundingElement.scrollIntoView({ block: "end" });
          }
        }
      }, 200);
    }
  };

  render() {
    const { alertMessage } = this.state;
    const { modalProps } = this.props;
    const { value: notification } = modalProps;
    const notificationCategories = modalProps.notificationCategories  || this.props.notificationCategories;

    const notificationCategoryOptions = (notificationCategories || []).map((p) => ({
      name: "notificationCategoryId",
      value: p.id,
      label: p.description,
    }));

    const priorityOptions = [{name: "Highest", value: 0, label: "Highest"}, {name: "High", value: 3, label: "High"}, {name: "Normal", value: 5, label: "Normal"},
      {name: "Low", value: 8, label: "Low"}, {name: "Lowest", value: 10, label: "Lowest"}]

    const isEdit = modalProps.value?.id;

    return (
      <Fragment>
        <div className="modal" style={{ display: "block" }} tabIndex="-1" role="dialog">
          {this.state.isLoading && <LoadingModalContent modalTitle={`${isEdit ? 'Edit' : 'Add'} Notification`} submitButtonLabel="Save" />}
          {!this.state.isLoading && (
            <Formik
              initialValues={{
                isActive: isEdit ? notification.isActive : true, // for creation: "active" by default
                name: notification?.name || "",
                description: notification?.description || "",
                notificationCategoryId: notification?.notificationCategory?.id || "",
                priority: Number.isInteger(notification?.priority) ? notification?.priority : 5
              }}
              validationSchema={this.formSchema()}
              onSubmit={this.formSubmit}
            >
              {({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">{`${isEdit ? 'Edit' : 'Add'} Notification`}</h5>
                        <button onClick={this.hideModal} type="button" className="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body cmv-form form-horizontal">
                        {!isEmpty(alertMessage) && (
                          <div className="alert alert-inline alert-danger alert-dismissible">
                            <p className="mb-0">{alertMessage}</p>
                          </div>
                        )}

                        <span className="h-check ml-3">
                          <label htmlFor="edit-notification-isactive">Active&nbsp;&nbsp;</label>
                          <div className="form-check checkbox-slider checkbox-slider--b-flat">
                            <label>
                              <input
                                id="edit-notification-isactive"
                                type="checkbox"
                                name="isActive"
                                checked={values.isActive}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                              />
                              <span>&nbsp;</span>
                            </label>
                          </div>
                        </span>

                        <div className="form-group">
                          <TextInput
                            label="Name"
                            name="name"
                            value={values.name.toUpperCase()}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className="form-group">
                          <TextInput
                            label="Description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className="form-group position-relative" onClick={this.scrollDropdownIntoView.bind(this)}>
                          <FormSelect
                            title="Notification Category"
                            fieldName="notificationCategoryId"
                            placeholder={"Select one ..."}
                            options={notificationCategoryOptions}
                            value={values.notificationCategoryId}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            errors={errors}
                            touched={touched}
                            isLoading={this.state.isLoading}
                            isDisabled={isSubmitting}
                          />
                        </div>
                        <div className="form-group position-relative" onClick={this.scrollDropdownIntoView.bind(this)}>
                          <FormSelect
                              title="Priority"
                              fieldName="priority"
                              placeholder={"Select one ..."}
                              options={priorityOptions}
                              value={values.priority}
                              setFieldValue={setFieldValue}
                              onBlur={handleBlur}
                              errors={errors}
                              touched={touched}
                              isLoading={this.state.isLoading}
                              isDisabled={isSubmitting}
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button onClick={this.hideModal} className="btn" type="button">
                          Cancel
                        </button>
                        <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
        <div className="modal-backdrop show" tabIndex="1" />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const modalProps = getModalData(state).modalProps,
    notification = selectNotificationsState(state).notification,
    notificationCategories = selectNotificationsState(state).notificationCategories,
    currentUser = getUser(state).user;

  return {
    modalProps,
    notification,
    notificationCategories,
    currentUser,
  };
};

const mapDispatchToProps = {
  hideModal,
  addNotification,
  updateNotification,
  getNotification,
  clearNotification,
  getNotificationCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditNotificationModal);
