import React, {Component, Fragment} from 'react';
import {getServiceOrderInformation} from '../../../../../actions/dashboardActions/orders.actions';
import isEmpty, {applyOnEnum} from '../../../../../utils/helpers';
import ServiceLineInformation from './ServiceLineSummary';
import Loader from '../../../../../components/Loader';
import moment from "moment";

class ServicesSummary extends Component {
    state = {
        serviceOrderInfoData: {},
        isFetched: false,
    };

    componentDidMount() {
        this.fetchServiceOrderInformation();
    }

    fetchServiceOrderInformation = async () => {
        const {serviceOrderId} = this.props;
        let result = await getServiceOrderInformation(serviceOrderId);

        if (result) {
            this.setState({
                serviceOrderInfoData: result,
                isFetched: true
            });
        } else {
            this.setState({
                isFetched: true
            });
        }
    };

    applyOnDateText = (applyOn, applyDate) => {
        if (isEmpty(applyOn)) {
            return null;
        }
        if ('APPLY_ON_DATE' === applyOn) {
            if (isEmpty(applyDate)) {
                return null;
            }
            return `Apply on: ${moment(applyDate).format("MM/DD/YYYY")}`
        }
        return `Apply on: ${applyOnEnum[applyOn]}`;
    }



    render() {
        const {serviceOrderInfoData, isFetched} = this.state;

        return (
            <div class="services-summary collapse show">
                {!isFetched && <Loader/>}
                {isFetched &&
                !isEmpty(serviceOrderInfoData) && (
                    <Fragment>
                        <div class="services-summary-body">
                            <div class="plan-wrapper">
                                <h4><div style={{width: "100%", display: "flex", alignItems: "center"}}>
                                    <div style={{width: "50%"}}>{serviceOrderInfoData.planDescription}</div>
                                    <div style={{width: "50%", textAlign: "right", fontSize: "small"}}>
                                        {this.applyOnDateText(serviceOrderInfoData.applyOn, serviceOrderInfoData.applyDate)}
                                    </div>
                                </div></h4>
                                {serviceOrderInfoData.serviceLines.map((serviceline) => (
                                    <ServiceLineInformation serviceline={serviceline} key={serviceline.id} serviceInfo={serviceOrderInfoData}/>
                                ))}
                            </div>
                        </div>
                        {this.props.showGrandTotal !== false && serviceOrderInfoData.description !== "Internet Change Service" && (
                            <div className="services-summary-footer">
                                <p>Grand Total</p>
                                <p className="services-price-total">
                                    Recurring:{' '}
                                    <span className="service-price">
									${serviceOrderInfoData.serviceLines
                                        .reduce((acc, serviceline, index) => {
                                            !isEmpty(serviceline.features) &&
                                            serviceline.features.map((feature) => {
                                                if (feature.featureType !== 'ACTIVATION') {
                                                    acc += feature.featurePrice;
                                                }
                                            });

                                            return acc;
                                        }, 0)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}/mo
								</span>
                                </p>
                                {!isEmpty(serviceOrderInfoData.serviceLines[0].features) &&
                                    serviceOrderInfoData.serviceLines[0].features.filter((feature) => feature.featureType === 'ACTIVATION').length > 0 &&
                                <p className="services-price-total">
                                    OneTime:{' '}
                                    <span className="service-price">
									${serviceOrderInfoData.serviceLines
                                        .reduce((acc, serviceline, index) => {
                                            !isEmpty(serviceline.features) &&
                                            serviceline.features.map((feature) => {
                                                if (feature.featureType === 'ACTIVATION') {
                                                    acc += feature.featurePrice;
                                                }
                                            });

                                            return acc;
                                        }, 0)
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
								</span>
                                </p>
                                    }

                                {!isEmpty(serviceOrderInfoData.serviceLines[0].devices) &&
                                    <p className="services-price-total">
                                        Equipment:{' '}
                                        <span className="service-price">
									${serviceOrderInfoData.serviceLines
                                            .reduce((acc, serviceline, index) => {
                                                if (!isEmpty(serviceline.devices)) {
                                                    serviceline.devices.map((device) => {
                                                        acc += device.itemListPrice;
                                                    });
                                                }
                                                return acc;
                                            }, 0)
                                            .toFixed(2)
                                            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
								</span>
                                    </p>
                                }
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default ServicesSummary;
