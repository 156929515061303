import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { showModal } from "../../actions/modal.actions";
import { getUserPermission } from "../../selectors";
import { notificationTypeIcons } from "./notificationTypes";

import { deleteNotificationTemplate } from "../../actions/notifications.actions";

/**
 * Notification Templates List
 *
 * @param props.notification - notification object
 */
class NotificationTemplatesList extends Component {
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}

  /**
   * Called after successful item status update on the "Edit Item status" modal
   * @param {*} item
   * @param {*} values
   */
  replaceTemplate = (template) => {
    const notification = this.props.notification;
    const templates = notification.notificationTemplates || [];
    let idx = templates.findIndex((i) => template.id === i.id);

    if (idx >= 0) {
      templates[idx] = template;
    }
    notification.notificationTemplates = templates;

    //
    if (typeof this.props.onTemplateUpdated === "function") {
      this.props.onTemplateUpdated(template, notification);
    }
  };

  editItem = (item, notification) => {
    this.props.showModal("EDIT_NOTIFICATION_TEMPLATE_MODAL", {
      notification: notification,
      value: item,
      onSuccess: (template) => this.replaceTemplate(template),
    });
  };

  deleteItem = (item) => {
    const notification = this.props.notification;

    this.props.showModal("CONFIRM_AND_ACTION_MODAL", {
      title: "Deleting Notification Template",
      message: <p>Are you sure to delete this notification template?</p>,
      action: async () => {
        await this.props.deleteNotificationTemplate(item.id);
        const notificationTemplates = [...notification.notificationTemplates];
        notificationTemplates.splice(notificationTemplates.indexOf(item), 1);

        if (typeof this.props.onTemplateUpdated === "function") {
          this.props.onTemplateUpdated(item, {
            ...notification,
            templatesCount: notification.templatesCount - 1,
            notificationTemplates,
          });
        }
      },
    });
  };

  render() {
    const { notification, showModal } = this.props;

    return (
      <Fragment>
        <table className="table cmv-table cmv-table-mobile cmv-table-solid cmv-table-nested">
          <tbody>
            {(notification.notificationTemplates || []).map((template) => (
              <tr key={`notify_template_${template.id}`}>
                <td>
                  <div className="flex">
                    <span className="mr-2">
                      {notificationTypeIcons[template.type] && <i className={notificationTypeIcons[template.type]}></i>}
                    </span>
                    <span>{template.type}</span>
                  </div>
                  <span className="td-subtitle">{template.isSystem ? "Internal" : "External"}</span>
                </td>
                <td>{template.sender}</td>
                <td>
                  {template.type === "EMAIL" && <span>{template.subject}</span>}
                  {template.type !== "EMAIL" && <span>{template.template?.replace(/<[^>]+>/g, '').substr(0, 40)}...</span>}
                </td>
                <td>
                  <div className="tr-actions">
                    {((template.isSystem && this.props.canEditSystemTemplate) ||
                      (!template.isSystem && this.props.canEditTemplate)) && (
                      <button
                        className="btn"
                        type="button"
                        title="Edit Template"
                        onClick={() => this.editItem(template, notification)}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                    )}
                    {((template.isSystem && this.props.canDeleteSystemTemplate) ||
                      (!template.isSystem && this.props.canDeleteTemplate)) && (
                      <button
                        className="btn"
                        type="button"
                        title="Delete Template"
                        onClick={() => this.deleteItem(template)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const canViewTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_TEMPLATE_READ"),
    canAddTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_TEMPLATE_ADD"),
    canEditTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_TEMPLATE_EDIT"),
    canDeleteTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_TEMPLATE_DEL"),
    canViewSystemTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_SYSTEM_TEMPLATE_READ"),
    canAddSystemTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_SYSTEM_TEMPLATE_ADD"),
    canEditSystemTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_SYSTEM_TEMPLATE_EDIT"),
    canDeleteSystemTemplate = getUserPermission(state, "ADMN", "ADMN_NOTIFICATION_SYSTEM_TEMPLATE_DEL");

  // Add new actions to the IF statement in render(), enabling the "ACTIONS" table column for users with rights
  return {
    canViewTemplate,
    canAddTemplate,
    canEditTemplate,
    canDeleteTemplate,
    canViewSystemTemplate,
    canAddSystemTemplate,
    canEditSystemTemplate,
    canDeleteSystemTemplate,
  };
};

const mapDispatchToProps = {
  showModal,
  deleteNotificationTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTemplatesList);
