import React, { Component } from "react";
import { connect } from "react-redux";

class Confirmation extends Component {
  render() {
    const { wizardData, history, hideAccountDetailsWizard } = this.props;

    return (
      <div className="text-center">
        <p className="text-success">
          <i className="far fa-4x fa-check-circle" />
        </p>
        <p className="text-success lead">Sales order has been created successfully.</p>

        <button
          type="button"
          className="btn btn-outline-success btn-lg mb-1"
          style={{ margin: "0px 5px" }}
          onClick={() => {
            hideAccountDetailsWizard();
          }}
        >
          Go to Account Details
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
