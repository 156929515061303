import axiosInstance from '../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../utils/helpers';

export const GET_APPOINTMENTS = createRequestTypes('GET_APPOINTMENTS');
export const GET_ORDER_TIMESLOTS = createRequestTypes('GET_ORDER_TIMESLOTS');
export const GET_TROUBLE_TICKET_TIMESLOTS = createRequestTypes('GET_TROUBLE_TICKET_TIMESLOTS');
export const GET_APPOINTMENT_TYPES = createRequestTypes('GET_APPOINTMENT_TYPES');

export const loadAppointments = (params) => async (dispatch) => {
	dispatch(actionCreator.request(GET_APPOINTMENTS));
	try {
        const response = await axiosInstance.get(`/appointments/list`, {params: params});
		dispatch(actionCreator.success(GET_APPOINTMENTS, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_APPOINTMENTS, mockError));
		return err.response.data;
	}
}

export const getOrderTimeslots = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_ORDER_TIMESLOTS));
	try {
		const response = await axiosInstance.get(`/appointments/orders/timeslots`);
		dispatch(actionCreator.success(GET_ORDER_TIMESLOTS, response.data.timeslots));
		return response.data.timeslots;
	} catch (err) {
		dispatch(actionCreator.failure(GET_ORDER_TIMESLOTS), err.response.data.error);
		return err.response.data;
	}
};

export const getTroubleTicketTimeslots = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_TROUBLE_TICKET_TIMESLOTS));
	try {
		const response = await axiosInstance.get(`/appointments/trouble-tickets/timeslots`);
		dispatch(actionCreator.success(GET_TROUBLE_TICKET_TIMESLOTS, response.data.timeslots));
		return response.data.timeslots;
	} catch (err) {
		dispatch(actionCreator.failure(GET_TROUBLE_TICKET_TIMESLOTS), err.response.data.error);
		return err.response.data;
	}
};

export const clearAppointments = () => async (dispatch) => {
	dispatch(actionCreator.clear(GET_APPOINTMENTS))
}

export const getAppointmentTypes = () => async (dispatch) => {
	dispatch(actionCreator.request(GET_APPOINTMENT_TYPES));
	try {
		const response = await axiosInstance.get(`/appointments/types`);
		dispatch(actionCreator.success(GET_APPOINTMENT_TYPES, response.data));
		return response.data;
	} catch (err) {
		let mockError = {message: 'Something goes wrong'}
		dispatch(actionCreator.failure(GET_APPOINTMENT_TYPES, mockError));
		return err.response.data;
	}
}
