import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hideModal } from '../../../actions/modal.actions';
import { getTroubleTicketResolutionCode, deleteTroubleTicketResolutionCode } from "../../../actions/troubleTicketCodes.action"
import { getModalData, getTroubleTicketCodes } from "../../../selectors";
import Loader from "../../../components/Loader";
import { toastr } from "react-redux-toastr";

class DeleteTroubleTicketResolutionCodeModal extends Component {
    state = {
        isTroubleTicketResolutionCodeLoading: true
    };

    componentDidMount() {
        const { modalProps } = this.props;
        if (modalProps.value) {
            this.props.getTroubleTicketResolutionCode(modalProps.value).then((response) => {
                this.setState({
                    isTroubleTicketResolutionCodeLoading: false
                })
            })
        } else {
            this.setState({
                isTroubleTicketResolutionCodeLoading: false
            })
        }
    }

    closeModal = () => {
        this.props.hideModal();
    };

    deleteTroubleTicketResolutionCode = () => {
        const { modalProps } = this.props;
        this.props.deleteTroubleTicketResolutionCode(modalProps.value).then((response) => {
            this.props.hideModal();
            modalProps.reloadFunction(true);
            if (response && response.success) {
                toastr.success(response.message, { timeOut: 4000, position: 'top-center' });
            } else {
                toastr.error(response.error.message, { timeOut: 4000, position: 'top-center' });
            }
        })
    };

    render() {
        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    {this.state.isTroubleTicketResolutionCodeLoading &&
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button onClick={this.props.hideModal} type="button" className="close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <Loader />
                                <div className="modal-footer">
                                </div>
                            </div>
                        </div>
                    }
                    {!this.state.isTroubleTicketResolutionCodeLoading &&
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">CONFIRM DELETING TROUBLE TICKET RESOLUTION CODE</h5>
                                    <button type="button" className="close" onClick={this.closeModal}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p className="lead">You are about to delete the trouble ticket resolution code</p>
                                    <div style={{
                                        backgroundColor: '#e9ecef',
                                        padding: '10px', borderRadius: '5px'
                                    }}>
                                        <span><i
                                            className={this.props.resolutionCode.serviceModelIcon ?
                                                this.props.resolutionCode.serviceModelIcon : ''} />&nbsp;{this.props.resolutionCode.resolutionCode}</span>
                                        <br />
                                        <span style={{ fontSize: ".75rem" }}>{this.props.resolutionCode.description}</span>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button onClick={this.closeModal} className="btn">
                                        CANCEL
                                    </button>
                                    <button onClick={this.deleteTroubleTicketResolutionCode} className="btn btn-danger">
                                        DELETE
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const modalProps = getModalData(state).modalProps,
        resolutionCode = getTroubleTicketCodes(state).resolutionCode;

    return {
        modalProps,
        resolutionCode
    };
};

const mapDispatchToProps = {
    hideModal,
    getTroubleTicketResolutionCode,
    deleteTroubleTicketResolutionCode
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTroubleTicketResolutionCodeModal);
