import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";

import { getSystemConfigs } from "src/selectors";
import { getPublicSystemConfig } from "src/actions/systemConfig.actions";
import Loader from "src/components/Loader";

class SystemConfigLoader extends Component {
  componentDidMount() {
    this.props.getPublicSystemConfig(); // start loading public system configuration
  }

  render() {
    const { isPublicSystemConfigLoaded, isUsingAuth0, publicSystemConfig } = this.props;

    if (!isPublicSystemConfigLoaded) return <Loader />;

    // prevent rendering of main app components, until pub
    if (isUsingAuth0) {
      return (
        <Auth0Provider
          domain={publicSystemConfig.auth0Domain}
          clientId={publicSystemConfig.auth0ClientId}
          authorizationParams={{
            organization: publicSystemConfig.auth0OrgId,
            redirect_uri: window.location.origin,
          }}
        >
          {this.props.children}
        </Auth0Provider>
      );
    } else {
      return <Fragment>{this.props.children}</Fragment>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isPublicSystemConfigLoaded: !!getSystemConfigs(state).loadTs,
    publicSystemConfig: getSystemConfigs(state).publicSystemConfig,
    isUsingAuth0: getSystemConfigs(state).isUsingAuth0,
  };
};

const mapDispatchToProps = {
  getPublicSystemConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemConfigLoader);
