/**
 * A component to display a placeholder for an item in the sales order.
 * You can select an item and adjust amounts.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { getAccountDetails } from "src/selectors";
import Select from "react-select";
import { groupStyles } from "src/utils/SelectStyles";
import { getInstallmentPlans } from "src/actions/accountDetailsActions/payNow.actions";
import { lookupSalesItems } from "src/actions/accountDetailsActions/salesOrders.actions";
import { showModal } from "src/actions/modal.actions";
import { isEmpty } from "src/utils/helpers";

import FormCheckboxSlider from "src/components/UI/FormCheckboxSlider";
import FormQuantityInput from "src/components/UI/FormQuantityInput";
import { debounce } from "lodash";
import { formatCurrency } from "src/utils/ui";

/**
 * @param {any} props.itemLine - Item selected for the placeholder
 * @param {func} props.removeItem - callback to remove the item from the sales order
 * @param {func} props.onChange - callback to notify about the item selection change
 */
class ItemPlaceholder extends Component {
  state = {
    // item select component status
    readonly: false,
    searchText: "",
    menuIsOpen: false,
    searchTextLast: "",
    options: [],
    selected: null,

    // barcode scanning
    scanningBarcode: false,
    barcodeValue: null,

    //
    lookingUpItems: false,
    salesItemsLookup: [],
  };

  componentDidMount() {
    if (this.props.itemLine.item && this.props.itemLine.item.id) {
      this.updateOptions();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.itemLine.item !== prevProps.itemLine.item) {
      this.updateOptions();
    }
  }

  updateOptions(salesItemsLookup = null) {
    const options = (salesItemsLookup || this.state.salesItemsLookup || []).map((item) => ({
      value: item.id,
      label: `${item.name} (${item.description})`,
    }));
    let selected = this.props.itemLine.item?.id
      ? options.find((opt) => opt.value === this.props.itemLine.item.id)
      : null;
    if (this.props.itemLine.item?.id && !selected) {
      selected = {
        value: this.props.itemLine.item.id,
        label: `${this.props.itemLine.item.name} (${this.props.itemLine.item.description})`,
      };
      options.push(selected);
    }

    console.log('updateOptions', options, selected)

    if (salesItemsLookup) {
      this.setState({
        lookingUpItems: false,
        salesItemsLookup,
        options,
        selected,
      });
    } else {
      this.setState({
        options,
        selected,
        // if the line is saved, do now allow to change the item
        readonly: this.props.itemLine.id && this.props.itemLine.item.id,
      });
    }
  }

  removeItem = () => {
    if (typeof this.props.removeItem === "function") this.props.removeItem(this.props.itemLine);
  };

  lookupSalesItems = (inputValue) => {
    this.setState({ lookingUpItems: true }, () => {
      this.props.lookupSalesItems(this.props.wizardData.locationId, inputValue).then(
        (resp) => {
          const salesItemsLookup = resp.data.items;
          this.updateOptions(salesItemsLookup);

          if (salesItemsLookup?.length === 1) {
            // if we got just one item from the lookup - select it automatically
            this.props.onChange({
              item: salesItemsLookup[0],
              quantity: 1,
            });
          } else {
            this.setState({ menuIsOpen: true });
          }
        },
        (err) => {
          this.setState({ lookingUpItems: false });
        }
      );
    });
  };

  /**
   * Search items by quick look-up, single text term.
   * We debounce it not to bombard our server with the frequent API calls.
   *
   */
  onInputChangeDebounced = debounce((inputValue) => {
    if (!!inputValue && inputValue.length >= 3) {
      // Require at least 3 characters to start searching
      this.lookupSalesItems(this.props.wizardData.locationId, inputValue);
    }
  }, 200);

  /**
   * User selected an item from the lookup list.
   * @param {*} selectedOption
   */
  onSelectItem = (selectedOption) => {
    if (typeof this.props.onChange !== "function") return;

    if (selectedOption.value === this.props.itemLine.item?.id) return;

    if (selectedOption) {
      const item = this.state.salesItemsLookup.find((item) => item.id === selectedOption.value);
      this.props.onChange({
        item: item,
        quantity: 1,
      });
    } else {
      this.props.onChange({
        item: null,
        quantity: 0,
      });
    }
  };

  /**
   * User selected an item from the lookup list.
   * @param {*} selectedOption
   */
  onChangeQuantity = (quantity) => {
    if (typeof this.props.onChange === "function") {
      this.props.onChange({ quantity: quantity });
    }
  };

  //
  // Barcode scanner
  //

  launchBarcodeScanner = () => {
    this.props.showModal("BARCODE_SCANNER_MODAL", {
      onCloseCallback: (value) => {
        if (value) {
          this.setState({ barcodeValue: value, searchText: value, scanningBarcode: false }, () => {
            // let's do the lookup with barcodeValue
            this.lookupSalesItems(this.state.barcodeValue);
          });
        } else {
          this.setState({ barcodeValue: null, scanningBarcode: false });
        }
      },
    });

    this.setState({ scanningBarcode: true });
  };

  //
  // Installment Plans
  //

  getInstallmentPlans = (itemId, amount) => {
    const { adjustmentTypesData } = this.props;
    let adjustmentType = adjustmentTypesData.find((adjustment) => adjustment.id === Number(itemId));
    if (adjustmentType !== undefined && !isEmpty(adjustmentType.installmentPlans)) {
      return adjustmentType.installmentPlans.map((installmentPlan) => ({
        id: installmentPlan.id,
        itemId: installmentPlan.itemId,
        description: installmentPlan.description,
        months: installmentPlan.months,
        maxMonths: installmentPlan.months,
        name: installmentPlan.name,
        upfrontPercentage: installmentPlan.upfrontPercentage,
        upfront: amount ? this.calculateMinimum(amount, installmentPlan.upfrontPercentage) : 0,
      }));
    } else {
      return [];
    }
  };

  handleInstallmentPlanChange = (e, formProps, index, field, adjustmentIds, setFieldValue) => {
    e.target.value !== "" && this.setState({ showAlertMessage: false, alertMessage: "" });

    const adjustments = [...formProps.values.adjustments];
    const adjustment = { ...adjustments[index] };
    let installmentTypeSelected = e.target.value !== "" ? true : false;
    setFieldValue(`adjustments.${index}.installmentTypeSelected`, installmentTypeSelected);
    let installmentPlans = this.getInstallmentPlans(adjustmentIds, adjustment.amount);
    let installmentPlan = "";
    if (installmentPlans != undefined && !isEmpty(installmentPlans) && e.target.value !== "") {
      installmentPlans.map((plan) => {
        if (plan.id == e.target.value) {
          installmentPlan = plan;
          this.setState({
            showInstallmentPlansPrice: true,
          });
        }
      });
    }
    if (!e.target.value) {
      installmentPlan = {
        id: "",
        itemId: "",
        description: "",
        months: "",
        maxMonths: "",
        upfront: 0,
        upfrontPercentage: "",
      };
    }
    const props = formProps.values;
    props.adjustments.map((ad) => {
      if (ad.id === adjustment.id) {
        ad.installmentPlan = installmentPlan;
      }
    });
    // props.adjustments[props.adjustments.length-1].installmentPlan=installmentPlan;
    setFieldValue(`adjustments.${index}.installmentPlan`, installmentPlan);
    this.calculateInstallmentPlanTotal(formProps.values, setFieldValue);
  };

  //
  //
  //

  render() {
    const errors =
      this.props.index >= 0 &&
      this.props.formProps?.errors?.lines &&
      this.props.formProps?.errors?.lines[this.props.index];

    return (
      <fieldset className="salesorder-item-placeholder">
        <div className="row">
          <div className="col-9">
            <div className="form-inline">
              <div style={{ flexDirection: "row", flex: 1, maxWidth: "320px" }}>
                <Select
                  name="deviceGroupId"
                  value={this.state.selected}
                  onChange={this.onSelectItem}
                  options={this.state.options}
                  // onInputChange={this.onInputChangeDebounced}
                  inputValue={this.state.searchText}
                  onInputChange={(inputValue, options) => {
                    if (options.action === "input-change") this.setState({ searchText: inputValue });
                  }}
                  menuIsOpen={this.state.menuIsOpen}
                  onMenuOpen={() => this.setState({ menuIsOpen: true })}
                  onMenuClose={() => {
                    // NOTE: when this select loses focus and the menu closes, the inputValue becomes empty.
                    //     This is a problem when the user clicks on the search button, because the search text is empty.
                    //     We store the last search text in the state, and use that when the user clicks on the search button.
                    this.setState({ menuIsOpen: false, searchTextLast: this.state.searchText, searchText: "" });
                  }}
                  placeholder="Search item by numbers"
                  styles={groupStyles}
                  className={this.props.itemLine.item?.id && !errors ? "is-valid" : "is-invalid"}
                  isDisabled={this.state.readonly}
                />
              </div>
              <button
                type="button"
                className="btn btn-outline-primary ml-1"
                onClick={() => {
                  if (this.state.searchTextLast && this.state.searchTextLast.length >= 3) {
                    this.lookupSalesItems(this.state.searchTextLast);
                    // once search is done, clear the search text, to prevent repeated loading of the same search
                    this.setState({ searchTextLast: "" });
                  } else {
                    // TODO: display some error message? to enter at least 3 characters to search
                    //      OR just disable the button until user enters at least 3 characters?
                  }
                }}
                disabled={this.state.lookingUpItems || (!this.state.searchText && !this.state.searchTextLast)}
              >
                <i className="fas fa-search" />
                Search
              </button>
              <button
                type="button"
                className="btn btn-outline-primary ml-1"
                title="Scan Barcode"
                onClick={this.launchBarcodeScanner}
              >
                <i className="fas fa-barcode" />
              </button>

              {this.props.itemLine.item?.id && this.props.itemLine.item.type !== "SERIALIZED" && (
                <div className="ml-1" style={{ maxWidth: "8rem" }}>
                  <FormQuantityInput
                    value={this.props.itemLine.quantity || 1}
                    onChangeQuantity={this.onChangeQuantity}
                  />
                </div>
              )}
            </div>
            {this.props.itemLine.item?.id && Array.isArray(this.props.itemLine.item.numbers) && (
              <p className="text-muted small mw-100 d-block pl-2">
                {this.props.itemLine.item.sku && <span className="mr-1">SKU:{this.props.itemLine.item.sku},</span>}
                <span className="mr-1">
                  {(this.props.itemLine.item.numbers || []).map((n, nidx) => `${n.name}:${n.number}`).join(", ")}
                </span>
              </p>
            )}

            {this.props.itemLine.item?.installmentPlans && (
              <FormCheckboxSlider
                checked={this.props.itemLine.useInstallmentPlan || false}
                onChange={(evt) => {
                  this.props.itemLine.useInstallmentPlan = evt.target.value;
                  this.props.onChangeItem(this.props.itemLine);
                }}
                label="Installment Plan"
              />
            )}

            {/* {this.props.itemLine.useInstallmentPlan && (
              <div className="form-row form-row-double">
                <div className="form-group col-lg-4">
                  <label>Installment Plan</label>
                  <select
                    className={
                      "form-control customer-adjustment-type" +
                      (get(errors, `adjustments.${index}.installmentPlan.id`) &&
                      get(touched, `adjustments.${index}.installmentPlan.id`)
                        ? " is-invalid"
                        : "")
                    }
                    placeholder="Please select Installment Plan"
                    value={adjustment.installmentPlan.id}
                    onChange={(e) => {
                      this.handleInstallmentPlanChange(
                        e,
                        props,
                        index,
                        "installmentPlan",
                        adjustment.itemId,
                        setFieldValue
                      );
                    }}
                    onBlur={handleBlur}
                    name={`adjustments.${index}.installmentPlan`}
                    disabled={calculateTaxesLoading}
                  >
                    <option value="">Please select Item</option>
                    {this.getInstallmentPlans(this.props.itemLine.id).map((installmentPlan) => (
                      <option value={installmentPlan.id} key={installmentPlan.id}>
                        {installmentPlan.description}
                      </option>
                    ))}
                  </select>
                  {get(errors, `adjustments.${index}.installmentPlan.id`) &&
                    get(touched, `adjustments.${index}.installmentPlan.id`) && (
                      <div className="invalid-feedback">{get(errors, `adjustments.${index}.installmentPlan.id`)}</div>
                    )}
                </div>
                <div className="form-group col-lg-4">
                  <label>Months</label>
                  <NumericInput
                    style={{
                      input: {
                        height: "calc(1.5em + 0.75rem + 2px)",
                        width: "250px",
                      },
                    }}
                    min={1}
                    max={adjustment.installmentPlan.maxMonths}
                    value={adjustment.installmentPlan.months}
                    disabled={!adjustment.installmentTypeSelected}
                    onChange={(e) => {
                      setFieldValue(`adjustments.${index}.installmentPlan.months`, e);
                    }}
                  />
                  {adjustment.installmentPlanSelected &&
                    adjustment.installmentTypeSelected &&
                    (adjustment.installmentPlan.months < 1 ||
                      adjustment.installmentPlan.months > adjustment.installmentPlan.maxMonths) && (
                      <span className="invalid-feedback" style={{ fontSize: "11px" }}>
                        {adjustment.installmentPlan.months < 1
                          ? "Cannot be less than 1"
                          : `Cannot be greater than ${adjustment.installmentPlan.maxMonths}`}
                      </span>
                    )}
                  {adjustment.installmentTypeSelected && (
                    <span className="text-muted" style={{ fontSize: "11px" }}>
                      {"Max " + adjustment.installmentPlan.maxMonths + " months"}
                    </span>
                  )}
                </div>

                <div className="form-group col-lg-4">
                  <label>Upfront</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">$</div>
                    </div>
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (get(errors, `adjustments.${index}.installmentPlan.upfront`) &&
                        get(touched, `adjustments.${index}.installmentPlan.upfront`)
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="0.00"
                      value={adjustment.installmentPlan.upfront}
                      autocomplete="off"
                      onChange={(e) => {
                        testingHandle(e, setFieldValue, values, index);
                      }}
                      onKeyUp={() => this.calculateInstallmentPlanTotal(values, setFieldValue)}
                      name={`adjustments.${index}.installmentPlan.upfront`}
                      disabled={!adjustment.installmentTypeSelected}
                    />
                  </div>
                  {(Number(adjustment.installmentPlan.upfront) <
                    this.calculateMinimum(adjustment.amount, adjustment.installmentPlan.upfrontPercentage) ||
                    Number(adjustment.installmentPlan.upfront) > Number(adjustment.amount)) && (
                    <span className="invalid-feedback" style={{ fontSize: "11px" }}>
                      {Number(adjustment.installmentPlan.upfront) <
                      this.calculateMinimum(adjustment.amount, adjustment.installmentPlan.upfrontPercentage)
                        ? `Must be greater than or equal to ${this.calculateMinimum(
                            adjustment.amount,
                            adjustment.installmentPlan.upfrontPercentage
                          )}`
                        : `Must be less then ${adjustment.amount}`}
                    </span>
                  )}
                  {adjustment.installmentTypeSelected && (
                    <div className="row">
                      <span className="text-muted col-lg-6" style={{ fontSize: "11px" }}>
                        {"Est. Monthly $ " +
                          this.calculateMonthly(
                            adjustment.amount,
                            adjustment.installmentPlan.upfront,
                            adjustment.installmentPlan.months,
                            index,
                            setFieldValue
                          )}
                      </span>
                      <span className="text-muted col-lg-6" style={{ fontSize: "11px" }}>
                        {"Min Required $ " +
                          this.calculateMinimum(adjustment.amount, adjustment.installmentPlan.upfrontPercentage)}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )} */}
          </div>
          <div className="col-3 text-right">
            <button
              type="button"
              className="btn btn-danger btn-salesorder-item-remove"
              onClick={this.removeItem}
              disabled={!this.props.canRemove}
            >
              Remove
            </button>
            <p className="mt-1">
              <span>{this.props.itemLine.quantity || 1}</span> &times;{" "}
              <span>{formatCurrency(this.props.itemLine.item?.price)}</span>
            </p>
          </div>
        </div>
      </fieldset>
    );
  }
}

const mapStateToProps = (state) => {
  const adjustmentTypesData = getAccountDetails(state).billing.adjustmentTypes;

  return {
    adjustmentTypesData,
  };
};

const mapDispatchToProps = {
  lookupSalesItems,
  getInstallmentPlans,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemPlaceholder);
