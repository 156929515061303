import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Loader from "../../components/Loader";
import { isEmpty } from "../../utils/helpers";
import { showModal } from "../../actions/modal.actions";
import {
  getDeviceProfiles,
  getItemsSerializedTypeOnly,
  getInventoryLocations,
  getSerializedItems,
  downloadSerializedItems,
} from "../../actions/inventory.actions";
import { getInventory, getUserPermission } from "../../selectors";

import CamvioTable2, {
  CamvioTable2Context,
  LoadMore,
  DefaultDashboardSearch,
  defineActionButton,
  utils,
} from "../../components/UI/CamvioTable2";
import Select from "react-select";
import { groupStyles } from "../../utils/SelectStyles";

/**
 * Actual content of the Inventory (serialized) management page
 */
class InventorySerializedList extends Component {
  state = {
    isLoading: false, // loading data?
    offset: 0,
    pageSize: 20,
    sortField: "",
    sortDirection: "",
    itemsList: [],
    itemsTotal: 0,
    loadingSearch: true,
    noMoreResults: true,
    isDownloading: false,
    bulkSelectionEnabled: false,
    bulkSelectedItems: [],

    // filters
    searchTerm: "",
    equipmentFamilyFilter: null,
    equipmentModelFilter: null,
    locationFilter: null,
    inventoryStatusFilter: null,
  };

  componentDidMount() {
    // load some of the prerequisites
    (!this.props.equipmentFamilies || this.props.equipmentFamilies.length === 0) && this.props.getEquipmentFamilies();
    (!this.props.locations || this.props.locations.length === 0) && this.props.getLocations();
    // this will load the equipment models and reload list
    this.setEquipmentFamilyFilter(null);
  }


  componentWillUnmount() {
    //  this.props.clearPlans();
  }

  getTableRequest(offsetOverride, limitOverride) {
    let filters = [];
    filters.push(utils.defineFilter("item_status", "ACTIVE"));
    if (this.state.searchTerm && this.state.searchTerm.trim())
      filters.push(utils.defineFilter("keyword", this.state.searchTerm.trim(), "like"));
    if (this.state.equipmentFamilyFilter)
      filters.push(utils.defineFilter("device_profile_id", this.state.equipmentFamilyFilter.value));
    if (this.state.equipmentModelFilter)
      filters.push(utils.defineFilter("item_id", this.state.equipmentModelFilter.value));
    if (this.state.locationFilter) filters.push(utils.defineFilter("location_id", this.state.locationFilter.value));
    if (this.state.inventoryStatusFilter)
      filters.push(utils.defineFilter("status", this.state.inventoryStatusFilter.value));

    let sort = null;
    if (this.state.sortField && this.state.sortDirection) {
      sort = utils.defineSort(this.state.sortField, this.state.sortDirection === "asc");
    }

    return utils.defineTableRequest(
      filters,
      sort,
      offsetOverride !== undefined ? offsetOverride : this.state.offset,
      limitOverride !== undefined ? limitOverride : this.state.pageSize
    );
  }

  clearAndReloadList = () => {
    this.setState(
      {
        offset: 0,
        itemsList: [],
        loadingSearch: true,
      },
      () => this.reloadList(true)
    );
  };

  reloadList = (clearList) => {
    let tableRequest = this.getTableRequest();
    if (clearList) {
      tableRequest = this.getTableRequest(0, this.state.pageSize + this.state.offset);
    }
    this.setState({ isLoading: true, loadingSearch: true, itemsList: clearList ? [] : this.state.itemsList }, () => {
      this.props.getItems(tableRequest).then((response) => {
        if (response.data.serializedItems && !isEmpty(response.data.serializedItems)) {
          response.data.serializedItems.forEach((item) => {
            this.state.itemsList.push(item);
          });

          // const itemsList = [
          //   ...this.state.itemsList,
          //   ...response.data.serializedItems,
          // ];
          this.setState(
            {
              itemsList: this.state.itemsList,
              itemsTotal: response.data.total || 0,
            },
            () => this.checkResultsLength()
          );
        }
        this.setState({ isLoading: false, loadingSearch: false });
      });
    });
  };

  sortList = (field, dir = null) => {
    if (field === null) dir = this.state.sortDirection === "asc" ? "desc" : "asc";

    this.setState(
      {
        sortField: field,
        sortDirection: dir,
      },
      () => this.clearAndReloadList()
    );
  };

  loadMore = () => {
    this.setState({ offset: +this.state.offset + +this.state.pageSize }, () => {
      this.reloadList(false);
    });
  };

  checkResultsLength = () => {
    if (this.state.itemsList && this.state.itemsList.length < this.state.itemsTotal) {
      this.setState({
        noMoreResults: false,
      });
    } else {
      this.setState({
        noMoreResults: true,
      });
    }
  };

  handlePageSizeChange = (value) => {
    this.setState({
      pageSize: value,
    });
  };

  setEquipmentFamilyFilter = (value) => {
    this.setState(
      {
        equipmentFamilyFilter: value,
      },
      () => this.clearAndReloadList()
    );
    // update equipment model list
    this.props.getEquipmentModels(
      value?.value ? { deviceProfileId: value?.value, status: "ACTIVE" } : { status: "ACTIVE" }
    );
  };

  setEquipmentModelFilter = (value) => {
    this.setState(
      {
        equipmentModelFilter: value,
      },
      () => this.clearAndReloadList()
    );
  };

  setLocationFilter = (value) => {
    this.setState(
      {
        locationFilter: value,
      },
      () => this.clearAndReloadList()
    );
  };

  setInventoryStatusFilter = (value) => {
    this.setState(
      {
        inventoryStatusFilter: value,
      },
      () => this.clearAndReloadList()
    );
  };

  debounceHandleInputChange = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  downloadTable = (value) => {
    const tableRequest = this.getTableRequest(0, 0);
    this.setState({
      isDownloading: true,
    });

    this.props.downloadItems(value, tableRequest).finally(() => {
      this.setState({
        isDownloading: false,
      });
    });
  };

  onDashboardAction = (action) => {
    switch (action) {
      case "SEARCH":
        this.clearAndReloadList();
        break;
      case "ADD":
        this.props.showModal("ADD_INVENTORY_SERIALIZED_MODAL", {
          equipmentFamilies: this.props.equipmentFamilies,
          locations: this.props.locations,
          reloadFunction: () => this.reloadList(true),
        });
        break;
      case "XLS":
        this.downloadTable("XLS");
        break;
      case "CSV":
        this.downloadTable("CSV");
        break;
      default:
        break;
    }
  };

  /**
   * Called after successful item status update on the "Edit Item status" modal
   * @param {*} item
   * @param {*} values
   */
  replaceItem = (item) => {
    let itemsList = [...this.state.itemsList];
    let idx = itemsList.findIndex((i) => item.id === i.id);

    if (idx >= 0) {
      itemsList[idx] = item;
    }

    this.setState({
      itemsList: itemsList,
    });
  };

  setBulkSelectionEnabled = () => {
    this.setState((prevState) => ({bulkSelectionEnabled: !prevState.bulkSelectionEnabled, bulkSelectedItems: []}))
  }

  handleBulkSelectionAddRemove = (id) => {
    if(this.state.bulkSelectionEnabled) {
      let updatedBulkSelectedItems = this.state.bulkSelectedItems;

      if (updatedBulkSelectedItems.includes(id)) {
        updatedBulkSelectedItems.splice(
            updatedBulkSelectedItems.findIndex(element => element === id), 1);
      }
      else {
        updatedBulkSelectedItems.push(id)
      }

      this.setState({bulkSelectedItems: updatedBulkSelectedItems});
    }
  }

  handleBulkSelectionSelectAll = () => {
    let itemsListIds = this.state.itemsList.map((item) => item.id)
    if(JSON.stringify(this.state.bulkSelectedItems) === JSON.stringify(itemsListIds)) {
      this.setState({bulkSelectedItems: []})
    } else {
      this.setState({bulkSelectedItems: itemsListIds})
    }
  }

  bulkEdit = () => {
    this.props.showModal("BULK_EDIT_SERIALIZED_INVENTORY_MODAL", {bulkSelectedItems: this.state.bulkSelectedItems,
      clearAndReloadList: () => {
      this.clearAndReloadList();
      this.setState({bulkSelectedItems: [], bulkSelectionEnabled: false})
      }
    })
  }

  render() {
    const { pageSize, itemsList, isLoading, sortField, sortDirection, isDownloading, bulkSelectionEnabled, bulkSelectedItems } = this.state;

    const { equipmentFamilies, equipmentModels, locations, statuses, canAdd } = this.props;

    const equipmentFamiliesOptions = (equipmentFamilies || []).map((p) => ({
      name: "device_profile_id",
      value: p.id,
      label: p.description || p.name,
    }));
    const equipmentModelOptions = (equipmentModels || []).map((p) => ({
      name: "item_id",
      value: p.id,
      label: p.description || p.name,
    }));

    const locationOptions = (locations || []).map((p) => ({
      name: "location_id",
      value: p.id,
      label: p.name,
    }));
    const inventoryStatusOptions = (statuses || []).map((p) => ({
      name: "status",
      value: p,
      label: p,
    }));

    return (
      <Fragment>
        <CamvioTable2Context.Provider value={this.state}>
          <div className="tab-pane camvio-table-search" style={{ borderRadius: "0.5rem" }}>
            <DefaultDashboardSearch
              onAction={this.onDashboardAction}
              onInputChange={this.debounceHandleInputChange}
              disabled={isLoading || this.state.loadingSearch || isDownloading}
              inputPlaceholder="Number, Name or Description"
              actionButtons={{
                secondary: [defineActionButton("ADD", "Receive", "fa-plus", !canAdd)],
              }}
              canBulkSelect={this.props.canEdit}
              bulkSelectionEnabled={bulkSelectionEnabled}
              setBulkSelectionEnabled={this.setBulkSelectionEnabled}
              bulkEdit={this.bulkEdit}
              handleBulkSelectionSelectAll={this.handleBulkSelectionSelectAll}
              bulkSelectedItems={bulkSelectedItems}
            >
              <div className="search">
                <Select
                  className="extra-filter-select"
                  styles={groupStyles}
                  placeholder={"All Equipment Families"}
                  options={equipmentFamiliesOptions}
                  value={this.state.equipmentFamilyFilter}
                  onChange={this.setEquipmentFamilyFilter}
                  isClearable={true}
                  clearValue={() => this.setEquipmentFamilyFilter(null)}
                />
                <span>&nbsp;&nbsp;</span>
                <Select
                  className="extra-filter-select"
                  onChange={this.setEquipmentModelFilter}
                  styles={groupStyles}
                  placeholder={"All Equipment Models"}
                  options={equipmentModelOptions}
                  value={this.state.equipmentModelFilter}
                  isClearable={true}
                  clearValue={() => this.setEquipmentModelFilter(null)}
                />
                <span>&nbsp;&nbsp;</span>
                <Select
                  className="extra-filter-select"
                  styles={groupStyles}
                  placeholder={"All Inventory Locations"}
                  options={locationOptions}
                  value={this.state.locationFilter}
                  onChange={this.setLocationFilter}
                  isClearable={true}
                  clearValue={() => this.setLocationFilter(null)}
                />
                <span>&nbsp;&nbsp;</span>
                <Select
                  className="extra-filter-select"
                  styles={groupStyles}
                  placeholder={"All Inventory Statuses"}
                  options={inventoryStatusOptions}
                  value={this.state.inventoryStatusFilter}
                  onChange={this.setInventoryStatusFilter}
                  isClearable={true}
                  clearValue={() => this.setInventoryStatusFilter(null)}
                />
              </div>
            </DefaultDashboardSearch>

            {isDownloading && <Loader />}

            {!this.state.loadingSearch && isEmpty(itemsList) && (
              <div className="container">
                <p>No data found</p>
              </div>
            )}
            {!isEmpty(itemsList) && !isDownloading && (
              <CamvioTable2
                headerDefs={[
                  { name: "Model", field: "item_name", sortable: true },
                  { name: "Numbers", field: "numbers", sortable: false },
                  { name: "Status", field: "status", sortable: true },
                  {
                    name: "Location",
                    field: "location_name",
                    sortable: true,
                  },
                  { name: "Action", field: "", sortable: false },
                ]}
                sortFunction={this.sortList}
                sortField={sortField}
                sortDirection={sortDirection}
              >
                <tbody>
                  {this.state.itemsList.map((item, index) => (
                    <tr className={bulkSelectedItems.includes(item.id) ? "bulk-selected" : ""} key={`item_${item.id}`} onClick={() => this.handleBulkSelectionAddRemove(item.id)}>
                      <td>
                        {bulkSelectionEnabled &&
                            <>
                        {bulkSelectedItems.includes(item.id)
                            ? <i className="order-select fas fa-check-square" style={{fontSize:'1rem', color:'#6ab3c1', cursor:'pointer'}}/>
                            : <i className="order-select far fa-square" style={{fontSize:'1rem', cursor:'pointer'}}/>
                        }
                            </>
                        }
                        <span title="Equipment Model">{item.item?.name}</span>
                        <span className="td-subtitle">{item.item?.description}</span>
                        {item.item?.deviceProfiles && item.item?.deviceProfiles.length > 0 && (
                          <span className="td-subtitle" title="Equipment Family">
                            {item.item?.deviceProfiles[0].description}
                          </span>
                        )}
                      </td>
                      <td>
                        {item.numbers?.slice(0, 3).map((n, i) => (
                          <span
                            className="td-subtitle"
                            key={`item_${index}_num_${i}`}
                            title={n.systemNumType?.description}
                          >
                            {n.systemNumType?.name}={n.number}
                          </span>
                        ))}
                        {item.numbers?.length > 3 && (
                          <div style={{ display: "none" }}>
                            {item.numbers.slice(3).map((n, i) => (
                              <span
                                className="td-subtitle"
                                key={`item_${index}_num_${i}`}
                                title={n.systemNumType?.description}
                              >
                                {n.systemNumType?.name}={n.number}
                              </span>
                            ))}
                          </div>
                        )}
                      </td>
                      <td>
                        <span
                          className={"badge" + (item.status === "UNASSIGNED" ? " badge-green" : " badge-secondary")}
                        >
                          {item.status}
                        </span>
                        <span className="td-subtitle">
                          {item.createdDate && (
                            <span title="Created at">
                              <i className="far fa-clock"></i>
                              {moment(item.createdDate).format("MM/DD/YYYY")}
                            </span>
                          )}{" "}
                          {item.createdByName && (
                            <span title="Created by">
                              <i className="fas fa-user"></i>
                              {item.createdByName}
                            </span>
                          )}
                        </span>
                      </td>
                      <td>
                        <span
                          className={
                            "badge" +
                            (item.location?.name === "WAREHOUSE"
                              ? " badge-primary"
                              : item.location?.name === "FIELD"
                              ? " badge-warning"
                              : " badge-secondary")
                          }
                        >
                          {item.location?.name}
                        </span>
                        {item.status === "IN_TRANSIT" && (
                          <span className="td-subtitle" title="In Transit">
                            <i className="fa fa-truck"></i>
                            {item.itemTransit && <span>Transit# {item.itemTransit.id}</span>}
                            {item.itemTransit?.toLocationName && <span>, To: {item.itemTransit.toLocationName}</span>}
                          </span>
                        )}
                        <span className="td-subtitle">
                          {item.modifiedDateTime && (
                            <span title="Last updated at">
                              <i className="far fa-clock"></i>
                              {moment(item.modifiedDateTime).format("MM/DD/YYYY")}
                            </span>
                          )}{" "}
                          {item.modifiedByName && (
                            <span title="Last updated by">
                              <i className="fas fa-user"></i>
                              {item.modifiedByName}
                            </span>
                          )}
                        </span>
                      </td>
                      <td>
                        <div className="tr-actions">
                          {this.props.canEdit && (
                            <button
                              className="btn"
                              type="button"
                              title="Edit Status and Location"
                              onClick={() =>
                                this.props.showModal("EDIT_INVENTORY_SERIALIZED_STATUS_MODAL", {
                                  value: item,
                                  locations: locations,
                                  onSuccess: (item) => {
                                    this.replaceItem(item);
                                  },
                                })
                              }
                            >
                              <i className="fas fa-heartbeat"></i>
                            </button>
                          )}
                          {!this.props.canEdit && this.props.canView && (
                            <button
                              className="btn"
                              type="button"
                              title="View"
                              onClick={() =>
                                this.props.showModal("VIEW_INVENTORY_SERIALIZED_NUMBERS_MODAL", { value: item })
                              }
                            >
                              <i className="fas fa-eye"></i>
                            </button>
                          )}
                          {this.props.canEdit && (
                            <button
                              className="btn"
                              type="button"
                              title="Edit Numbers"
                              onClick={() =>
                                this.props.showModal("EDIT_INVENTORY_SERIALIZED_NUMBERS_MODAL", {
                                  value: item,
                                  onSuccess: (item) => {
                                    this.replaceItem(item);
                                  },
                                })
                              }
                            >
                              <i className="fas fa-hashtag"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </CamvioTable2>
            )}
          </div>
          {(isLoading || this.state.loadingSearch) && <Loader />}
          {!this.state.noMoreResults && (
            <LoadMore
              disabled={this.state.loadingSearch || this.state.noMoreResults || isDownloading}
              pageSize={pageSize}
              onPageSizeChange={(pageSize) => {
                this.handlePageSizeChange(pageSize);
              }}
              onLoadMore={() => this.loadMore()}
            />
          )}
        </CamvioTable2Context.Provider>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const locationRows = [],
    equipmentFamilies = getInventory(state).deviceProfiles,
    equipmentModels = getInventory(state).items,
    locations = getInventory(state).locations,
    serializedItems = getInventory(state).serializedItems,
    statuses = getInventory(state).statuses,
    canView = getUserPermission(state, "OPER", "OPER_INVENTORY_SER_VIEW"),
    canAdd = getUserPermission(state, "OPER", "OPER_INVENTORY_SER_EDIT"),
    canEdit = getUserPermission(state, "OPER", "OPER_INVENTORY_SER_EDIT"),
    canDelete = getUserPermission(state, "OPER", "OPER_INVENTORY_SER_DEL");

  // Add new actions to the IF statement in render(), enabling the "ACTIONS" table column for users with rights
  return {
    items: serializedItems,
    equipmentFamilies,
    equipmentModels,
    locations,
    statuses,
    canView,
    canAdd,
    canEdit,
    canDelete,
  };
};

const mapDispatchToProps = {
  showModal,
  getItems: getSerializedItems,
  downloadItems: downloadSerializedItems,
  getEquipmentFamilies: getDeviceProfiles,
  getEquipmentModels: getItemsSerializedTypeOnly,
  getLocations: getInventoryLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventorySerializedList);
