/**
 * Account Details / New Sales Order wizard / Item Selection step
 *
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { createLoadingSelector } from "src/selectors";
import { showModal } from "src/actions/modal.actions";
import { Form, Formik, FieldArray } from "formik";
import { createSalesOrder, updateSalesOrder } from "src/actions/accountDetailsActions/salesOrders.actions";

import * as Yup from "yup";

import ItemPlaceholder from "../components/ItemPlaceholder";
import ItemSeletionSummaryPane from "../components/ItemSeletionSummaryPane";

const validationSchema = Yup.object().shape({
  lines: Yup.array()
    .of(
      Yup.object().shape({
        item: Yup.object().shape({
          id: Yup.number().typeError("Required").required("Required"),
          type: Yup.string().required("Required"),
          name: Yup.string().required("Required"),
          price: Yup.number().typeError("Invalid price").required("Required"),
        }),
        useInstallmentPlan: Yup.boolean().optional(),
        installmentPlan: Yup.object()
          .shape({
            id: Yup.number(),
            months: Yup.number(),
            upfront: Yup.number(),
          })
          .nullable(),
        quantity: Yup.number()
          .typeError("Required")
          .required("Required")
          .positive("The quantity must be greater than 0"),
      })
    )
    .min(1, "At least one item is required"),
  note: Yup.string(),
});

export class ItemSelection extends Component {
  componentDidMount() {}

  componentWillUnmount() {}

  newItemLine() {
    return {
      item: {
        id: null,
        type: null,
        name: null,
        price: 0.0,
        numbers: {},
      },
      quantity: 1,
      useInstallmentPlan: false,
      installmentPlan: null,
    };
  }

  handleOnSubmit = (values, actions) => {
    if (this.props.wizardData.salesorder) {
      this.props.updateSalesOrder(this.props.wizardData.salesorder.id, values).then((response) => {
        if (response && response.data?.success) {
          this.handleNext(response.data.salesorder);
        }
      });
    } else {
      this.props.createSalesOrder(values).then((response) => {
        if (response && response.data?.success) {
          this.handleNext({ ...values, id: response.data.id });
        }
      });
    }
  };

  handleNext = (values) => {
    if (!this.props.next) {
      return;
    }

    this.props.next({
      values: values,
    });
  };

  render() {
    const { isCreatingSalesOrder, wizardData } = this.props;
    const initValues = wizardData.salesorder || {
      id: null,
      salesOrderNumber: wizardData.orderNumber,
      accountId: wizardData.accountId,
      locationId: wizardData.locationId,
      lines: [this.newItemLine()],
      note: "",
    }

    return (
      <>
        <Formik
          onSubmit={this.handleOnSubmit}
          validationSchema={validationSchema}
          initialValues={initValues}
        >
          {(formProps) => (
            <Form onSubmit={formProps.handleSubmit} className="cmv-form" autoComplete="off">
              <h4>Sales Order #{formProps.values.salesOrderNumber}</h4>

              <div className="cmv-container-panels p-0">
                <div className="row">
                  <div className="col-xl-8">
                    <div className="panel-left p-4">
                      <FieldArray
                        name="lines"
                        render={(arrayHelpers) => (
                          <div>
                            {formProps.values.lines.map((line, index) => (
                              <ItemPlaceholder
                                key={index}
                                index={index}
                                wizardData={this.props.wizardData}
                                itemLine={line}
                                formProps={formProps}
                                onChange={(changes) => {
                                  const lines = [...formProps.values.lines];
                                  lines[index] = {
                                    ...formProps.values.lines[index],
                                    ...changes,
                                  };
                                  formProps.setValues({ ...formProps.values, lines: lines });
                                }}
                                canRemove={formProps.values.lines.length > 1}
                                removeItem={() => {
                                  formProps.values.lines.length > 1 && arrayHelpers.remove(index);
                                }}
                              />
                            ))}
                            <div className="text-right">
                              <button
                                type="button"
                                className="btn btn-primary ml-auto"
                                onClick={() => arrayHelpers.push(this.newItemLine())}
                                disabled={this.props.isCreatingSalesOrder}
                              >
                                ADD ANOTHER
                              </button>
                            </div>
                          </div>
                        )}
                      />

                      <div className="form-group position-relative mb-0">
                        <label htmlFor="salessorder-note">Note</label>
                        <textarea
                          className="form-control"
                          id="salessorder-note"
                          name="note"
                          value={formProps.values.note || ''}
                          onChange={formProps.handleChange}
                          placeholder=""
                        />
                        {formProps.errors.note && formProps.touched.note && (
                          <div className="invalid-feedback">{formProps.errors.note}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="panel-right p-4">
                      <ItemSeletionSummaryPane values={formProps.values} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="wizard-footer d-flex justify-content-between">
                <button
                  type="submit"
                  className="btn btn-primary btn-next-tab ml-auto"
                  disabled={this.props.isCreatingSalesOrder || !formProps.isValid}
                >
                  {isCreatingSalesOrder ? (
                    <i className="fas fa-fw fa-spin fa-spinner" style={{ marginLeft: "5px" }} />
                  ) : (
                    "NEXT"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const orderCreationSelector = createLoadingSelector(["CREATE_SALES_ORDER"]);

const mapStateToProps = (state) => {
  const isCreatingSalesOrder = orderCreationSelector(state);

  return {
    isCreatingSalesOrder,
  };
};

const mapDispatchToProps = {
  createSalesOrder,
  updateSalesOrder,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemSelection);
