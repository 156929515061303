import React from "react";

/**
 *
 * @param {*} param0
 * @param {{ field: string, name: string, sortable: boolean}[]} param0.headerDefs
 * @returns
 */
export default function TableHeaders({
  headerDefs,
  onSortChange = (_col, _dir) => {},
  sortField = undefined,
  sortDirection = null,
}) {
  const triggerSortFunction = (e, field) => {
    let dir = "asc";
    if (field === sortField) {
      dir = sortDirection === null ? "asc" : sortDirection === "asc" ? "desc" : null;
    }
    onSortChange(field, dir);
  };

  return (
    <thead>
      <tr>
        {headerDefs.map((header, i) => (
          <th key={`ct2_th_${i}`} style={{ verticalAlign: "middle" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{header.name}</span>
              &nbsp;
              {header.sortable ? (
                <button
                  className="btn btn-link btn-sm"
                  style={{ color: "grey", whiteSpace: "nowrap" }}
                  onClick={(event) => triggerSortFunction(event, header.field || header.name)}
                >
                  <i
                    style={{
                      opacity: header.field === sortField && sortDirection === "asc" ? "1.0" : "0.4",
                    }}
                    className="fa fa-fw fa-arrow-up"
                  ></i>
                  <i
                    style={{
                      opacity: header.field === sortField && sortDirection === "desc" ? "1.0" : "0.4",
                    }}
                    className="fa fa-fw fa-arrow-down"
                  ></i>
                </button>
              ) : (
                ""
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
}
