import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { forgottenPassword } from "src/actions/auth.actions";
import { isEmpty } from "src/utils/helpers";

const emailSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

class ForgotenPassword extends Component {
  state = {
    infoMessageType: "forgotenpassword",
  };

  componentDidMount() {
    if (!isEmpty(this.props.location.state)) {
      if (this.props.location.state.message.length > 0) {
        this.setState({
          infoMessageType: "expireToken",
        });
      }
    }
  }

  handleForrgotenPasswordSubmit = (values, actions) => {
    this.props.forgottenPassword(values.email).then((response) => {
      if (response.success) {
        this.setState({ infoMessageType: "successfullReset" });
        actions.resetForm({ values: { email: "" } });
      }
    });
  };

  renderCamvioForm() {
    const { infoMessageType } = this.state;
    return (
      <div className="card-login-body">
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={emailSchema}
          onSubmit={this.handleForrgotenPasswordSubmit}
          render={({ handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="needs-validation">
              {infoMessageType === "forgotenpassword" && (
                <p>Please enter your email address below to receive a link to reset your password.</p>
              )}

              {infoMessageType === "successfullReset" && (
                <p>
                  Thanks! If there's an account associated with this email, we'll send the password reset instructions
                  immediately.
                </p>
              )}

              {infoMessageType === "expireToken" && (
                <p style={{ color: "red" }}>
                  The password reset link has expired. Please enter your email so we send you a new link.
                </p>
              )}
              {infoMessageType !== "successfullReset" && (
                <div className="form-label-group">
                  <input
                    type="text"
                    className={touched.email && errors.email ? "form-control is-invalid" : "form-control"}
                    name="email"
                    id="email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={values.email}
                  />
                  <label htmlFor="email">Email</label>
                  {errors.email && touched.email && <div className="invalid-feedback">{errors.email}</div>}
                </div>
              )}

              <div className="d-flex justify-content-between">
                <button onClick={() => this.props.history.push("/login")} className="btn btn-outline-secondary">
                  Go back
                </button>
                {infoMessageType !== "successfullReset" && (
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                    Reset password
                  </button>
                )}
              </div>
            </Form>
          )}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="cmv-page cmv-page-recover-pwd">
        <div className="page-wrapper">
          <div className="page-content">
            <main>
              <div className="col col-sm-6 col-md-5 col-xl-3">
                <div className="card-login">
                  <div className="card-login-header">
                    <div className="brand-container" />
                    <h3>RESET PASSWORD</h3>
                  </div>

                  {this.renderCamvioForm()}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  forgottenPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotenPassword);
