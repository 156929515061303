import React, {Component} from 'react'
import Appointment from "./Appointment";

class PersonalList extends Component {

    state = {
        appointmentSelectedId: undefined
    }

    selectAppointment = (appointmentId) => {
        if(appointmentId === this.state.appointmentSelectedId) {
            this.setState({appointmentSelectedId: undefined})
        } else {
            this.setState({appointmentSelectedId: appointmentId.toString()})
        }
    }

    render() {

        const {
            appointments,
            usernameOptions
        } = this.props;

        return (
            <div className="cmv-container-appointment-list-search-results">
                <div className="appointment-list">
                    {appointments.map(appointment =>
                        <Appointment
                            key={appointment.id}
                            appointment={appointment}
                            appointmentSelected={appointment.id === this.state.appointmentSelectedId}
                            selectAppointment={this.selectAppointment}
                            getAppointmentsByFilters={this.props.getAppointmentsByFilters}
                            usernameOptions={usernameOptions}
                            {...this.props}
                        />
                    )}
                </div>

            </div>
        )
    }
}

export default PersonalList;
