import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const SET_TASKS = createRequestTypes('SET_TASKS');
export const SET_SYSTEM_USERS = createRequestTypes('SET_SYSTEM_USERS');
export const ASSIGN_TASK = createRequestTypes('ASSIGN_TASK');
export const UNASSIGN_TASK = createRequestTypes('UNASSIGN_TASK');

export const getTasks = (params) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TASKS));
	try {
		const response = await axiosInstance.get(`/order/usertasks`, { params: params });
		dispatch(actionCreator.success(SET_TASKS, response.data.userTasks));
	} catch (err) {
		dispatch(actionCreator.failure(SET_TASKS, 'error'));
	}
};


export const getFilteredTasks = (params) => async (dispatch) => {
	dispatch(actionCreator.request(SET_TASKS));
	try {
		const response = await axiosInstance.get(`/order/queryTasks`, { params: params });
		dispatch(actionCreator.success(SET_TASKS, response.data.userTasks));
	} catch (err) {
		dispatch(actionCreator.failure(SET_TASKS, 'error'));
	}
};

export const getSystemUsers = (params) => async (dispatch) => {
	dispatch(actionCreator.request(SET_SYSTEM_USERS));
	try {
		const response = await axiosInstance.get(`/domain/systemusers`, { params: params });
		dispatch(actionCreator.success(SET_SYSTEM_USERS, response.data.users));
		return response.data.users;
	} catch (err) {
		dispatch(actionCreator.failure(SET_SYSTEM_USERS));
	}
};

export const assignTask = (data, searchTypeParams) => async (dispatch) => {
	dispatch(actionCreator.request(ASSIGN_TASK));
	try {
		const response = await axiosInstance.post(`/order/usertask/assign`, data);
		dispatch(actionCreator.success(ASSIGN_TASK, response.data.users));
		if (searchTypeParams) {dispatch(getFilteredTasks(searchTypeParams));}
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(ASSIGN_TASK));
	}
};

export const unassignTask = (data, searchTypeParams) => async (dispatch) => {
	dispatch(actionCreator.request(UNASSIGN_TASK));
	try {
		const response = await axiosInstance.post(`/order/usertask/unassign`, data);
		dispatch(actionCreator.success(UNASSIGN_TASK, response.data.users));
		if (searchTypeParams) {dispatch(getFilteredTasks(searchTypeParams));}
		return response.data;
	} catch (err) {
		dispatch(actionCreator.failure(UNASSIGN_TASK));
	}
};

export const getLogs = async (serviceOrderId) => {
	try {
		const response = await axiosInstance.get(`/order/serviceorder/${serviceOrderId}/workflow/state`);
		return response.data;
	} catch (err) {
		return err.response.data;
	}
};

export const emptyTasks = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_TASKS));
};

export const emptySystemUsers = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_SYSTEM_USERS));
};
