/**
 * Defines utility functions for action definitions
 */
import axiosInstance from "../utils/axios-instance";
import { actionCreator, getErrorMessage } from "../utils/helpers";

export const serializeParamsToQueryString = (params) => {
  // Serialize params to query string
  let queryString = "";
  if (params) {
    for (var p in params)
      if (params.hasOwnProperty(p)) {
        queryString += `${queryString.length > 0 ? "&" : ""}${p}=${encodeURIComponent(params[p])}`;
      }
  }
  return queryString;
};

export const dispatchClearAction = (actionType) => async (dispatch) => {
  dispatch(actionCreator.clear(actionType));
};

export const dispatchEmptyAction = (actionType) => async (dispatch) => {
  dispatch(actionCreator.empty(actionType));
};

/**
 * This will be used to raise manual error
 * @param {*} actionType
 * @param {*} error
 * @returns
 */
export const dispatchErrorAction = (actionType, error) => async (dispatch) => {
  dispatch(actionCreator.failure(actionType, error));
};

/**
 * Make HTTP Post request to the API and dispatch the response
 * @param {*} actionType
 * @param {*} postUrl
 * @param {*} params
 * @param {*} dataKey
 * @returns
 */
export const httpPostAndDispatchAction =
  (actionType, postUrl, params, dataKey = null) =>
  async (dispatch) => {
    dispatch(actionCreator.request(actionType));

    try {
      const response = await axiosInstance.post(postUrl, params);
      dispatch(actionCreator.success(actionType, dataKey ? response.data[dataKey] : response.data));
      return response;
    } catch (err) {
      let mockError = getErrorMessage(err);
      dispatch(actionCreator.failure(actionType, mockError));
      throw err;
    }
  };

/**
 * Make HTTP Put request to the API and dispatch the response
 * @param {*} actionType
 * @param {*} putUrl
 * @param {*} params
 * @param {*} dataKey
 * @returns
 */
export const httpPutAndDispatchAction =
  (actionType, putUrl, params, dataKey = null) =>
  async (dispatch) => {
    dispatch(actionCreator.request(actionType));

    try {
      const response = await axiosInstance.put(putUrl, params);
      dispatch(actionCreator.success(actionType, dataKey ? response.data[dataKey] : response.data));
      return response;
    } catch (err) {
      let mockError = getErrorMessage(err);
      dispatch(actionCreator.failure(actionType, mockError));
      throw err;
    }
  };

/**
 * Make HTTP Path request to the API and dispatch the response
 * @param {*} actionType
 * @param {*} postUrl
 * @param {*} params
 * @param {*} dataKey
 * @returns
 */
export const httpPatchAndDispatchAction =
  (actionType, postUrl, params, dataKey = null) =>
  async (dispatch) => {
    dispatch(actionCreator.request(actionType));

    try {
      const response = await axiosInstance.patch(postUrl, params);
      dispatch(actionCreator.success(actionType, dataKey ? response.data[dataKey] : response.data));
      return response;
    } catch (err) {
      let mockError = getErrorMessage(err);
      dispatch(actionCreator.failure(actionType, mockError));
      throw err;
    }
  };

/**
 * Make HTTP GET request to the API and dispatch the response
 * @param {*} actionType
 * @param {*} getUrl
 * @param {*} dataKey
 * @returns
 */
export const httpGetAndDispatchAction =
  (actionType, getUrl, dataKey = null) =>
  async (dispatch) => {
    dispatch(actionCreator.request(actionType));

    try {
      const response = await axiosInstance.get(getUrl);
      dispatch(actionCreator.success(actionType, dataKey ? response.data[dataKey] : response.data));
      return response;
    } catch (err) {
      let mockError = getErrorMessage(err);
      dispatch(actionCreator.failure(actionType, mockError));
      throw err;
    }
  };

/**
 * Make HTTP Delete request to the API and dispatch the response
 * @param {*} actionType
 * @param {*} getUrl
 * @param {*} dataKey
 * @returns
 */
export const httpDeleteAndDispatchAction =
  (actionType, getUrl, dataKey = null) =>
  async (dispatch) => {
    dispatch(actionCreator.request(actionType));

    try {
      const response = await axiosInstance.delete(getUrl);
      dispatch(actionCreator.success(actionType, dataKey ? response.data[dataKey] : response.data));
      return response;
    } catch (err) {
      let mockError = getErrorMessage(err);
      dispatch(actionCreator.failure(actionType, mockError));
      throw err;
    }
  };

/**
 * Initiate a file download from the API
 * @param {*} actionType - Redux Action
 * @param {string} fileType - xls | csv
 * @param {*} downloadUrl
 * @param {*} tableRequest
 * @returns
 */
export const httpPostAndDownloadAction =
  (actionType, fileType, downloadUrl, postData, filepath = "") =>
  async (dispatch) => {
    dispatch(actionCreator.request(actionType));
    try {
      const response = await axiosInstance.post(downloadUrl, postData, {
        responseType: "blob",
      });
      dispatch(actionCreator.success(actionType, response));

      if (response && response.status === 201 && response.data) {
        let name = "";
        let link = document.createElement("a");
        let fileUrl = URL.createObjectURL(new Blob([response.data]));
        link.setAttribute("href", fileUrl);
        if (response.headers["content-disposition"] !== undefined) {
          name = response.headers["content-disposition"].split("=")[1];
        } else if (filepath) {
          name = filepath;
        } else {
          let today = new Date();
          name = "file_" + today.toISOString().split("T")[0] + "." + fileType;
        }
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      return response;
    } catch (err) {
      let errMessage = getErrorMessage(err);
      if (err && err.response && err.response.data && err.response.data instanceof Blob) {
        try {
          const data = JSON.parse(await err.response.data.text());
          errMessage = getErrorMessage({ response: { data } });
        } catch {}
      }

      dispatch(actionCreator.failure(actionType, errMessage));
    }
  };
