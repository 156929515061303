import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {getDashboardData, createLoadingSelector, getErrorMessage, getUserPermission} from '../../../../selectors';
import {
	getAccounts,
	emptyAccounts,
	getCustomersServedHistory,
	getAccountStatusTypes,
	getAccountTerms,
	getAccountTypes
} from '../../../../actions/dashboardActions/customers.actions';
import { showModal, showAccessModal } from '../../../../actions/modal.actions';
import Loader from '../../../../components/Loader';
import SearchBar from './SearchBar';
import AccountsList from './AccountsList';

class Customers extends Component {

	state = {
		searchType: {
			label: 'Serviceline',
			value: 'SERVICELINE'
		},
		placeholder: 'Search account by Serviceline',
		term: '',
		isFetched: false,
		filters: {
			accountType: '',
			accountStatus: '',
			accountTerm: '',
			limit: 20,
			searchType: 'SERVICELINE',
			term: ''
		},
		limit: 20,
		noMoreResults: false,
		accountTermsFilterOptions: []
	}

	componentDidMount() {
		const searchTypeCustomers = localStorage.getItem('searchTypeCustomers');

		if (searchTypeCustomers) {
			this.changeSearchType(JSON.parse(searchTypeCustomers));
		}
		this.props.getAccountTerms().then((response) => this.setState({accountTermsFilterOptions: response}))
		this.props.getAccountTypes().then((response) => this.setState({accountTypesFilterOptions: response}))
		this.props.getAccountStatusTypes()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.accountsData !== this.props.accountsData) {
			this.setState({
				isFetched: true,
			});

			if(this.props.accountsData.length < this.state.limit) {
				this.setState({noMoreResults: true})
			} else {
				this.setState({noMoreResults: false})
			}
		}

		// Store search type if changed
		if (prevState.searchType !== this.state.searchType) {
			localStorage.setItem("searchTypeCustomers", JSON.stringify({
				label: this.state.searchType.label,
				value: this.state.searchType.value,
				placeholder: this.state.placeholder,
			}));
			this.setState({filters: {...this.state.filters, searchType: this.state.searchType.value}})
		}
	}

	componentWillUnmount() {
		this.props.emptyAccounts();
	}

	searchAccounts = () => {
		const {filters} = this.state
		this.props.getAccounts(filters)
	};

	changeSearchType = (type) => {
		this.setState({
			searchType: {
				value: type.value,
				label: type.label
			},
			placeholder: type.placeholder,
			filters: {...this.state.filters, searchType: type.value}
		});
	};

	changeTerm = (e) => {
		this.setState({
			filters: {...this.state.filters, term: e.target.value}
		})
	};

	accountAccessModal = (id) => {
		this.props.showAccessModal(true);
		this.props.showModal('ACCOUNT_ACCESS_MODAL', { accountId: id });
	};


	setLimitIncrease = (limitIncrease) => {
		let newLimit = this.state.limit + limitIncrease
		this.setState({filters: {...this.state.filters, limit: newLimit}, limit: newLimit})
	}

	showAdditionalAccounts = async (limitIncrease) => {
		await this.setLimitIncrease(limitIncrease)
		const {filters} = this.state
		this.props.getAccounts(filters)
	}

	updateFilters = (accountType, accountStatus, accountTerm) => {
		this.setState({filters: {...this.state.filters, accountType: accountType.toUpperCase(), accountStatus: accountStatus.toUpperCase(), accountTerm: accountTerm.toUpperCase()}},
			() => this.props.getAccounts(this.state.filters)
		)
	}

	render() {
		const { searchType, filters, placeholder, isFetched, accountTermsFilterOptions, accountTypesFilterOptions } = this.state;
		const { dashboardMenuCount, isLoading, getCustomersServedHistory,
			newAccountPermission, searchHistoryPermissions, accountsData, accountStatusOptions } = this.props;

		return (
			<>

				<div className="tab-pane-caption">
					<h1 className="tab-pane-title">{dashboardMenuCount.customersServed || 0} Customers</h1>
					<p className="tab-pane-text">served in 24 hours</p>
				</div>

				<SearchBar
					searchAccounts={this.searchAccounts}
					changeSearchType={this.changeSearchType}
					changeTerm={this.changeTerm}
					updateFilters={this.updateFilters}
					accountStatusOptions={accountStatusOptions}
					accountTermsFilterOptions={accountTermsFilterOptions}
					accountTypesFilterOptions={accountTypesFilterOptions}
					searchType={searchType}
					term={filters.term}
					placeholder={placeholder}
					getCustomersServedHistory={getCustomersServedHistory}
					newAccountPermission={newAccountPermission}
					searchHistoryPermissions={searchHistoryPermissions}
					{...this.props}
				/>

				{isLoading ? (
					<Loader />
				) : (
					<AccountsList
						accounts={accountsData}
						isFetched={isFetched}
						accountAccessModal={this.accountAccessModal}
						showAdditionalAccounts={this.showAdditionalAccounts}
						noMoreResults={this.state.noMoreResults}
						{...this.props}
					/>
				)}

			</>
		);
	}
}

Customers.Customers = {
	accountsData: PropTypes.array.isRequired,
	isLoading: PropTypes.bool,
	getCustomersServed: PropTypes.func,
	getAccounts: PropTypes.func,
	emptyAccounts: PropTypes.func,
	showModal: PropTypes.func,
	showAccessModal: PropTypes.func
};

const accountSearchLoader = createLoadingSelector([ 'DASHBOARD_ACCOUNT_SEARCH' ]);
const getSearchError = getErrorMessage([ 'DASHBOARD_ACCOUNT_SEARCH' ]);

const mapStateToProps = (state) => {
	const accountsData = getDashboardData(state).accounts,
		accountStatusOptions = getDashboardData(state).accountStatusOptions,
		isLoading = accountSearchLoader(state),
		searchError = getSearchError(state),
		newAccountPermission = getUserPermission(state, 'CAM', 'CAM_ACC_ADD'),
		searchHistoryPermissions = getUserPermission(state, 'CAM', 'CAM_ACC_DETAIL');

	return {
		accountsData,
		accountStatusOptions,
		isLoading,
		searchError,
		newAccountPermission,
		searchHistoryPermissions
	};
};

const mapDispatchToProps = {
	getCustomersServedHistory,
	getAccounts,
	emptyAccounts,
	showModal,
	showAccessModal,
	getAccountStatusTypes,
	getAccountTerms,
	getAccountTypes
};

export default connect(mapStateToProps, mapDispatchToProps)(Customers);