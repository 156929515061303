import {isEmpty} from "../containers/CreateAccount/utils";

export const generateAddress = (address1, address2, address3, city, state, zipcode) => {
	let address = '';

	if (address1) {
		address += address1;
	}
	if (address2) {
		address += ', ' + address2;
	}
	if (address3) {
		address += ', ' + address3;
	}
	if (city) {
		address += ', ' + city;
	}
	if (state) {
		address += ', ' + state;
	}
	if (zipcode) {
		address += ', ' + zipcode;
	}

	return address;
};

export const generateAddressNew = (address) => {
	if (typeof address === 'undefined') {
		return '';
	}

	let formattedAddress = '';

	if (address.address1) {
		formattedAddress += address.address1;
	}
	if (address.address2) {
		formattedAddress += ', ' + address.address2;
	}
	if (address.address3) {
		formattedAddress += ', ' + address.address3;
	}
	if (address.city) {
		formattedAddress += ', ' + address.city;
	}
	if (address.state && address.state.name) {
		formattedAddress += ', ' + address.state.name;
	}
	if (address.zipcode) {
		formattedAddress += ', ' + address.zipcode;
		if(address.zip4) {
			formattedAddress += '-' + address.zip4;
		}
	}

	return formattedAddress;
};

export const generateFormatedAddress = (address) => {
	if (typeof address === 'undefined') {
		return '';
	}

	let formattedAddress = '';

    if (address.address2) {
		formattedAddress += address.address2 + '/';
	}
	if (address.address1) {
		formattedAddress += address.address1;
	}
	if (address.address3) {
		formattedAddress += ', ' + address.address3;
	}
	if (address.city) {
		formattedAddress += ', ' + address.city;
	}
	if (address.state && address.state.name) {
		formattedAddress += ', ' + address.state.name;
	}
	if (address.zipcode) {
		formattedAddress += ', ' + address.zipcode;
	}

	return formattedAddress;
};

export const generateFullFormatedAddress = (address) => {
	if (isEmpty(address)) {
		return '';
	}

	let formattedAddress =
		{
			"fullAddress": generateFormatedAddress(address),
			"address1": address.address1,
			"address2": address.address2,
			"address3": address.address3,
			"city": address.city,
			"stateId": address.state? address.state.id: null,
			"state": address.state? address.state.name: null,
			"zip": address.zipcode,
			"zip4": address.zip4,
			"countryId": address.country? address.country.id: null,
			"country": address.country? address.country.name: null,
			"latitude": address.latitude,
			"longitude": address.longitude,
			"addressId": address.id
		};

	return formattedAddress;
};

export const getAddressWithUnit = (address, unit) => {
	let formattedAddress = '';
	if (unit) {
		formattedAddress = unit + '/' + address;
	} else {
		formattedAddress = address;
	}

	return formattedAddress;
};
