import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	getDashboardOrders,
	emptyDashboardOrders,
	getFilteredOrders,
} from '../../../../actions/dashboardActions/orders.actions';
import { executeOrder } from '../../../../actions/dashboardActions/orders.actions';
import { getDashboardData, createLoadingSelector } from '../../../../selectors';
import { groupStyles } from '../../../../utils/SelectStyles';
import PropTypes from 'prop-types';
import Select from 'react-select';
import OrderResults from './OrderResults';

import DatePicker from 'react-datepicker';
import SearchBar from '../Customers/SearchBar';
import Button from '../../../../components/Common/Buttons/Button';
import {getformatedFilters} from './utils';
import {showModal, showAccessModal} from '../../../../actions/modal.actions';
import {isEmpty} from "../../../../utils/helpers";

const orderOptions = [
	{ value: 'myOrders', param: 'myOrders', label: 'My Orders' },
	{ value: 'allOrders', param: 'allOrders', label: 'All Orders' },
	{ value: 'notStarted', param: 'notStarted', label: 'Not Started Orders' },
	{ value: 'completed', param: 'completed', label: 'Completed Orders' },
	{ value: 'incomplete', param: 'incomplete', label: 'Incomplete Orders' },
	{ value: 'userInput', param: 'orderId', label: 'Search by order#' },
	{ value: 'userInput', param: 'orderAddress', label: 'Search by order address' },
	{ value: 'userInput', param: 'customerName', label: 'Search by customer name' },
	{ value: 'userInput', param: 'mappingArea', label: 'Search by mapping area' }

];

const serviceOrderOptions = [
	{ value: 'allServiceOrderTypes', param: 'allServiceOrderTypes', label: 'All Service Order Types' },
	{ value: 'newService', param: 'newService', label: 'New Service' },
	{ value: 'changeService', param: 'changeService', label: 'Change Service' },
	{ value: 'newSignup', param: 'newSignup', label: 'New Signup' },
	{ value: 'newSurvey', param: 'newSurvey', label: 'New Survey' }
];

class Orders extends Component {

	state = {
		searchType: { value: 'myOrders', label: 'My Orders' },
		searchServiceOrdersType: { value: 'allServiceOrderTypes', label: 'All Service Order Types' },
		filters: {
			createdBy: '',
			fromDate: '',
			toDate: '',
			limit: 20,
			userInput: {
				option: '',
				value: ''
			}
		},
		filterType: 'limit',
		filterValue: '20',
		filterButtonText: "Last 20 Orders",
		filterButtonTextAdditional: "",
		isFilterDropDownOpen: false,
		isFetched: false,
		isBulkSelectionEnabled: false,
		bulkSelectionOrders: [],
		searchByOrderId: '',
	};

	componentDidMount() {
		this.getOrdersByFilters();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.userOrdersData !== this.props.userOrdersData) {
			this.setState({
				isFetched: true
			});
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		this.props.emptyDashboardOrders();
	}

	closeFilterDropdown = () => {
		this.setState({isFilterDropDownOpen: false});
	}

	handleClickDropdown = () => {
		this.setState((prevState) => ({
			isFilterDropDownOpen: !prevState.isFilterDropDownOpen
		}));
	};

	handleLimitFilter = (limit) => {
		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = limit;
		// remove date value
		updateFilter['toDate'] = '';
		updateFilter['fromDate'] = '';

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'limit',
				filterValue: limit,
				isFetched: false
			}, () => {
				this.renderButtonText()
				this.getOrdersByFilters()
			}
		);
	};

	handleLastDaysFilter = (lastDays) => {
		let fromDate = moment().subtract(lastDays, 'days').toDate();
		let toDate = moment().toDate();

		let updateFilter = { ...this.state.filters };
		updateFilter['fromDate'] = fromDate;
		updateFilter['toDate'] = toDate;
		updateFilter['limit'] = '';

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'lastDays',
				filterValue: lastDays,
				isFetched: false
			}, () => {
				this.renderButtonText()
				this.getOrdersByFilters()
			}
		);
	};

	handleDateFilter = (value, name) => {
		let updateFilter = { ...this.state.filters };
		updateFilter[name] = value;
		updateFilter['limit'] = '';

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'dateFromTo',
				isFetched: false
			}, () => {
				this.renderButtonText()
				this.getOrdersByFilters()
			}
		);
	};

	readableDateFormat = () => {
		const { filters } = this.state;
		let parseDateFormat = '';
		parseDateFormat += filters.fromDate && moment(filters.fromDate).format('MM/DD/YYYY');
		parseDateFormat += filters.toDate && ' - ' + moment(filters.toDate).format('MM/DD/YYYY');

		return parseDateFormat;
	};

	renderButtonText = () => {
		const { filters, filterType, filterValue } = this.state;

		let filterButtonText = "";
		let filterButtonTextAdditional = "";

		if (filterType === 'dateFromTo') {
			filterButtonText = filters.fromDate && moment(filters.fromDate).format('MM/DD/YYYY');
			if (filters.fromDate.getTime !== filters.toDate.getTime) {
				filterButtonTextAdditional = filters.toDate && moment(filters.toDate).format('MM/DD/YYYY');
			}
		} else if (filterType === 'limit') {
			filterButtonText = `Last ${filterValue} Orders`;
		} else if (filterType === 'lastDays') {
			filterButtonText = `Last ${filterValue} Days`;
		}

		this.setState({
			filterButtonText: filterButtonText,
			filterButtonTextAdditional: filterButtonTextAdditional,
		})
	};

	handleSearchById = (e) => {
		this.setState({
			filters: {
				...this.state.filters,
				userInput: {
					option: e.target.id,
					value: e.target.value
				}
			}
		});
	};

	changeSearchType = (value) => {
		if (value.value === 'userInput') {
			this.setState({
				searchType: value,
				isFetched: false,
				filters: { ...this.state.filters,
					userInput: {
						option: '',
						value: ''
					}}
			});
		} else {
			this.setState(
				{
					searchType: value,
					isFetched: false,
					filters: { ...this.state.filters,
							userInput: {
								option: '',
								value: ''
							}}
				},
				() => {
					this.renderButtonText()
					this.getOrdersByFilters();
				}
			);
		}
	};

	changeServiceOrdersType = (value) => {

		this.setState(
			{
				searchServiceOrdersType: value,
				isFetched: false,
				filters: { ...this.state.filters }
			},
			() => {
				this.renderButtonText()
				this.getOrdersByFilters();
			}
		);
	};

	getOrdersByFilters = () => {
		const { filters, searchType, searchServiceOrdersType } = this.state;
		const { user } = this.props;

		this.props.getFilteredOrders(getformatedFilters(filters, searchType.value, user.id, searchServiceOrdersType.value));
	};

	refreshDashboardOrders = () => {
		this.setState(
			{
				isFetched: false,
			},
			() => {
				this.getOrdersByFilters();
			}
		);
	};

	accountAccessModal = (id) => {
		this.props.showAccessModal(true);
		this.props.showModal('ACCOUNT_ACCESS_MODAL', { accountId: id });
	};

	enableBulkSelection = () => {

		this.setState({
			isBulkSelectionEnabled: !this.state.isBulkSelectionEnabled,
			bulkSelectionOrders: [],
			isFilterDropDownOpen: false,
		})
	};

	handleBulkSelectionAddRemove = (orderId) => {

		let updatedBulkSelectionOrders = this.state.bulkSelectionOrders;

		if (updatedBulkSelectionOrders.includes(orderId)) {
			updatedBulkSelectionOrders.splice(
				updatedBulkSelectionOrders.findIndex(element => element === orderId), 1);
		}
		else {
			updatedBulkSelectionOrders.push(orderId)
		}

		this.setState({bulkSelectionOrders: updatedBulkSelectionOrders});
	}

	handleBulkSelectionSelectAll = () => {

		let updatedBulkSelectionOrders = [];

		this.props.userOrdersData.map(order => {

			if (!order.isCompleted && !order.isStarted && order.canStart) {
				updatedBulkSelectionOrders.push(order.id);
			}
		});

		this.setState({bulkSelectionOrders: updatedBulkSelectionOrders});
	}

	bulkStartOrders = () => {
		this.props.showModal('BULK_START_ORDERS_MODAL', {
			bulkSelectionOrders: this.state.bulkSelectionOrders,
			onCloseCallback: () => {
				this.setState({
					isBulkSelectionEnabled: false,
					bulkSelectionOrders: [],
				})
				this.refreshDashboardOrders();
			},
		});
	}

	render() {

		const {
			filters,
			filterButtonText,
			filterButtonTextAdditional,
			isFetched,
			isFilterDropDownOpen,
			isBulkSelectionEnabled,
			bulkSelectionOrders,
			searchType,
			searchServiceOrdersType,
		} = this.state;

		const {
			dashboardMenuCount,
			user,
			userOrdersData,
			orderLoader,
			executeOrder,
		} = this.props;

		return (
			<Fragment>
				<div className="tab-pane-caption">
					<h1 className="tab-pane-title">{dashboardMenuCount.pendingOrders || 0} Orders</h1>
					<p className="tab-pane-text">still pending</p>
				</div>
				<div className="cmv-container-dashboard-search">

					{!isBulkSelectionEnabled &&
					<div className="cmv-container-dashboard-filter">
						<div className="container">
							<div className="form-row">
								<div className="col-md-2">
									<div className="dropdown dropdown-cn-timefilter show">
										<button
											type="button"
											onClick={this.handleClickDropdown}
											className={'btn btn-outline-secondary dropdown-toggle' + (isFilterDropDownOpen ? ' show' : '') + (filterButtonTextAdditional ? ' dropdown-toggle-multiline' : '')}
											disabled={orderLoader || searchType.value === 'userInput' || isBulkSelectionEnabled}
										>
											{isEmpty(filterButtonTextAdditional)
												? filterButtonText
												: <div><span>{filterButtonText}</span><span>{filterButtonTextAdditional}</span></div>
											}
										</button>
										<div
											onBlur={this.onBlurchangeActiveDropDown}
											className={
												isFilterDropDownOpen ? (
													'dropdown-menu dropdown-menu-right show'
												) : (
													'dropdown-menu dropdown-menu-right'
												)
											}
										>
											<button
												onClick={() => this.handleLimitFilter(20)}
												className="dropdown-item"
											>
												Last 20 Orders
											</button>
											<button
												onClick={() => this.handleLimitFilter(50)}
												className="dropdown-item"
											>
												Last 50 Orders
											</button>
											<button
												onClick={() => this.handleLimitFilter(100)}
												className="dropdown-item"
											>
												Last 100 Orders
											</button>

											<div className="dropdown-divider" />
											<button
												onClick={() => this.handleLastDaysFilter(30)}
												className="dropdown-item"
											>
												Last 30 Days
											</button>
											<button
												onClick={() => this.handleLastDaysFilter(60)}
												className="dropdown-item"
											>
												Last 60 Days
											</button>
											<button
												onClick={() => this.handleLastDaysFilter(90)}
												className="dropdown-item"
											>
												Last 90 Days
											</button>
											<div className="dropdown-divider" />
											<h6 className="dropdown-header">From</h6>

											<div className="form-group col">
												<div
													id="datetimepicker1"
													className="input-group input-group-sm date"
													data-target-input="nearest"
												>
													<DatePicker
														name="fromDate"
														className="form-control datetimepicker-input"
														selected={filters.fromDate}
														onChange={(value) => this.handleDateFilter(value, 'fromDate')}
														placeholderText="MM/DD/YYYY"
													/>
												</div>
											</div>

											<h6 className="dropdown-header">To</h6>

											<div className="form-group col">
												<div
													id="datetimepicker2"
													className="input-group input-group-sm date"
													data-target-input="nearest"
												>
													<DatePicker
														name="toDate"
														className="form-control datetimepicker-input"
														selected={filters.toDate}
														onChange={(value) => this.handleDateFilter(value, 'toDate')}
														placeholderText="MM/DD/YYYY"
													/>
												</div>
											</div>

										</div>
									</div>
								</div>
								<div className="col-md-3">

									<Select
										id="searchType"
										name="searchType"
										value={searchType}
										onChange={this.changeSearchType}
										onFocus={this.closeFilterDropdown}
										options={orderOptions}
										placeholder="My Orders"
										styles={groupStyles}
										isDisabled={orderLoader || isBulkSelectionEnabled}
									/>

								</div>
								<div className="col-md-4 col-xl-3">

									<Select
										id="orderType"
										name="orderType"
										value={searchServiceOrdersType}
										onChange={this.changeServiceOrdersType}
										onFocus={this.closeFilterDropdown}
										options={serviceOrderOptions}
										placeholder="All Service Order Types"
										styles={groupStyles}
										isDisabled={
											orderLoader ||
											(searchType.value === 'userInput' && searchType.param === 'orderId') ||
											searchType.value === 'completed' ||
											isBulkSelectionEnabled
										}
									/>

								</div>
								<div className="col-md-3 col-xl-4">
									<div className="input-group">

										<input
											type="text"
											id={searchType.param}
											name="term"
											className="form-control"
											placeholder={searchType.label}
											autoComplete="off"
											value={filters.userInput.value}
											onChange={this.handleSearchById}
											onFocus={this.closeFilterDropdown}
											disabled={orderLoader || searchType.value !== 'userInput' || isBulkSelectionEnabled}
											onKeyPress={(event) => {
												if (event.key === 'Enter') {
													this.getOrdersByFilters()
												}
											}}
										/>

										<div className="input-group-append">
											<Button
												type="button"
												className="btn btn-primary btn-show-cam-search-results"
												disabled={orderLoader || searchType.value !== 'userInput' || isBulkSelectionEnabled}
												onClick={() => {
													this.closeFilterDropdown();
													this.getOrdersByFilters();
												}}
											>
												<i className="fas fa-search" />
												<span>&nbsp;Search</span>
											</Button>
										</div>

										<div className="input-group-append">
											<div className="btn-group">
												<button
													type="button"
													className={"btn " + (isBulkSelectionEnabled ? "btn-outline-danger" : "btn-outline-secondary")}
													disabled={orderLoader}
													onClick={this.enableBulkSelection}
												>
													<i className="fas fa-tasks"/>
													{isBulkSelectionEnabled
														? <span style={{minWidth: "5.7em"}}>&nbsp;Cancel</span>
														: <span style={{minWidth: "5.7em"}}>&nbsp;Bulk Start</span>
													}
												</button>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
					}

					{isBulkSelectionEnabled &&
					<div className="cmv-container-dashboard-filter">
						<div className="container">
							<div className="form-row">
								<div className="col-sm-6">

									<label>Press START to begin executing the selected orders.</label>

								</div>
								<div className="col-sm-6 d-flex justify-content-end">

									<div className="input-group-append">
										<div className="btn-group">

											<button
												type="button"
												className={"btn btn-sm " + (isBulkSelectionEnabled ? "btn-outline-danger" : "btn-outline-secondary")}
												disabled={orderLoader}
												onClick={this.enableBulkSelection}
											>
												<i className="fas fa-times"/>&nbsp;Cancel
											</button>

											<button
												type="button"
												className="btn btn-sm btn-outline-secondary"
												disabled={orderLoader}
												onClick={this.handleBulkSelectionSelectAll}
											>
												<i className="fas fa-check-double"/>&nbsp;Select All
											</button>

											<button
												type="button"
												className="btn btn-sm btn-primary"
												disabled={orderLoader || isEmpty(bulkSelectionOrders)}
												onClick={this.bulkStartOrders}
											>
												<i className="fas fa-tasks"/>&nbsp;Start
											</button>

										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					}

				</div>

				<OrderResults
					userOrdersData={userOrdersData}
					orderLoader={orderLoader}
					isFetched={isFetched}
					executeOrder={executeOrder}
					history={this.props.history}
					user={user}
					filters={filters}
					searchType={searchType.value}
					searchServiceOrdersType={searchServiceOrdersType.value}
					parentCallback={this.refreshDashboardOrders}
					showModal={this.props.showModal}
					accountAccessModal={this.accountAccessModal}
					isBulkSelectionEnabled={isBulkSelectionEnabled}
					bulkSelectionOrders={bulkSelectionOrders}
					handleBulkSelectionAddRemove={this.handleBulkSelectionAddRemove}
				/>

			</Fragment>
		);
	}
}

Orders.propTypes = {
	user: PropTypes.object.isRequired,
	userOrdersData: PropTypes.array.isRequired,
	orderLoader: PropTypes.bool,
	getDashboardOrders: PropTypes.func,
	emptyDashboardOrders: PropTypes.func,
	executeOrder: PropTypes.func
};

const getOrderLoader = createLoadingSelector([ 'SET_DASHBOARD_ORDERS' ]);

const mapStateToProps = (state) => {
	const userOrdersData = getDashboardData(state).userOrders,
		orderLoader = getOrderLoader(state);

	return {
		userOrdersData,
		orderLoader,
		executeOrder
	};
};

const mapDispatchToProps = {
	getDashboardOrders,
	emptyDashboardOrders,
	executeOrder,
	getFilteredOrders,
	showModal,
	showAccessModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
