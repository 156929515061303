import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import Select from "react-select";
import {groupStyles} from "../../../../utils/SelectStyles";
import moment from "moment/moment";
import {isEmpty} from "../../../../utils/helpers";
import {getFormattedFilters} from "../Appointments/utils";
import {clearAppointments, loadAppointments, loadUserAppointments} from "../../../../actions/appointments.actions";
import {getAppointmentAreas} from "../../../../actions/appointmentAreas.actions";
import {showAccessModal, showModal} from "../../../../actions/modal.actions";
import {
    assignTask,
    emptySystemUsers,
    getSystemUsers,
    unassignTask
} from "../../../../actions/dashboardActions/tasks.actions";
import {connect} from "react-redux";
import {createLoadingSelector, getAppointments} from "../../../../selectors";
import Loader from "../../../../components/Loader";
import PersonalList from "./PersonalList";




class MyDay extends Component {

    state = {
        appointmentsSorted: undefined,
        timeslotOptions: [
            {value: '0', label: '08:00-10:00', placeholder: ''},
            {value: '1', label: '10:00-12:00', placeholder: ''},
            {value: '2', label: '13:00-15:00', placeholder: ''},
            {value: '3', label: '15:00-17:00', placeholder: ''},
            {value: '4', label: '17:00-19:00', placeholder: ''},
        ],
        assigneeOptions: [],
        areaOptions: [],
        usernameOptions: [],
        typeOptions: [
            {value: 'ORDERS', label: 'Orders', placeholder: ''},
            {value: 'TROUBLE_TICKETS', label: 'Trouble Tickets', placeholder: ''},
        ],
        filters: {
            limit: 20,
            fromDate: moment().toDate(),
            toDate: moment().toDate(),
            timeslot: undefined,
            assignee: undefined,
            area: undefined,
            type: undefined,
        },
        filterType: 'limit',
        filterValue: '20',
        filterButtonText: 'Last 20',
        filterButtonTextAdditional: '',
        isFilterDropDownOpen: false,
        isAssigning: false,
        showDropdownById: '',
        viewAs: 'calendar',
        tasksVisible: false,
        orderId: undefined,
        taskDetailsVisible: false
    }

    componentDidMount() {
        const {user} = this.props

        // Get appointments
        this.getAppointmentsByFilters();
        // Get appointment areas
        this.props.getAppointmentAreas().then(response => {
            this.generateAreaOptions(response);
        });

        if (!isEmpty(user.userGroups)) {
            this.props.getSystemUsers({
                userGroups: user.userGroups.map((userGroup) => userGroup.name).join(',')
            }).then(response => {
                this.generateUsernameOptions(response);
            });
        }

    }

    generateAppointments = (appointments) => {
        let updatedAppointment;
        let appointmentsSorted = [];
        let tasksIds = [];
        let taskAssignees = [];
        let assigneeOptions = [];

        // Generate for Orders
        if (!isEmpty(appointments.orders)) {
            appointments.orders.map(appointment => {
                tasksIds = [];
                taskAssignees = [];

                // Collect Tasks IDs and Assignees
                if (!isEmpty(appointment.serviceOrders)) {
                    appointment.serviceOrders.map(serviceOrder => {
                        if (!isEmpty(serviceOrder.task) && !isEmpty(serviceOrder.task.id)) {

                            // Collect all task IDs
                            tasksIds.push(serviceOrder.task.id);

                            // Collect task assignees
                            if (serviceOrder.task.assignee) {

                                // Once per user - for dropdown options
                                if (isEmpty(assigneeOptions.filter((option) => option.value === serviceOrder.task.assignee))) {
                                    assigneeOptions.push({value: serviceOrder.task.assignee, label: serviceOrder.task.assignee, placeholder: ''});
                                }

                                // Every time - for display purposes
                                taskAssignees.push(serviceOrder.task.assignee);
                            }
                        }
                    });
                }

                // Add appointment Type and Tasks IDs then store
                updatedAppointment = appointment;
                updatedAppointment['type'] = 'order';
                updatedAppointment['taskIds'] = tasksIds;
                updatedAppointment['taskAssignees'] = taskAssignees;
                appointmentsSorted.push(updatedAppointment);
            })
        }

        // Generate for Tickets
        if (!isEmpty(appointments.troubleTickets)) {
            appointments.troubleTickets.map(appointment => {
                tasksIds = [];
                taskAssignees = [];

                // Collect Tasks IDs and Assignees
                if (!isEmpty(appointment.tasks)) {

                    appointment.tasks.map(task => {
                        if (!isEmpty(task) && !isEmpty(task.id)) {

                            // Collect all task IDs
                            tasksIds.push(task.id);

                            // Collect task assignees
                            if (task.assignee) {

                                // Once per user - for dropdown options
                                if (isEmpty(assigneeOptions.filter((option) => option.value === task.assignee))) {
                                    assigneeOptions.push({value: task.assignee, label: task.assignee, placeholder: ''});
                                }

                                // Every time - for display purposes
                                taskAssignees.push(task.assignee);
                            }
                        }
                    });
                }

                // Add appointment Type, Tasks IDs, Task Assignees, and store
                updatedAppointment = appointment;
                updatedAppointment['type'] = 'troubleTicket';
                updatedAppointment['taskIds'] = tasksIds;
                updatedAppointment['taskAssignees'] = taskAssignees;
                appointmentsSorted.push(updatedAppointment);
            });
        }

        // Sort Appointments according to completion date
        appointmentsSorted.sort((a, b) => {
            let appointmentMomentA = a.appointmentDetails.date + ' ' + a.appointmentDetails.timeslot.substring(0, 5);
            let appointmentMomentB = b.appointmentDetails.date + ' ' + b.appointmentDetails.timeslot.substring(0, 5);

            if (moment(appointmentMomentA).isAfter(appointmentMomentB))
                return 1;
            else
                return -1;
        });

        // Store generated Appointments
        this.setState({
            appointmentsSorted: appointmentsSorted,
            assigneeOptions: assigneeOptions,
        });
    }

    changeTypeSelected = (searchValue) => {
        let updatedFilters = { ...this.state.filters };
        updatedFilters['type'] = searchValue;

        this.setState({
                filters: updatedFilters,
                isFilterDropDownOpen: false,
            },
            () => {
                this.getAppointmentsByFilters();
            });
    }

    changeAreaSelected = (searchValue) => {
        let updatedFilters = { ...this.state.filters };
        updatedFilters['area'] = searchValue;

        this.setState({
                filters: updatedFilters,
                isFilterDropDownOpen: false,
            },
            () => {
                this.getAppointmentsByFilters();
            });
    }

    generateAreaOptions = (areas) => {
        let areaOptions = [];

        if (!isEmpty(areas.appointmentAreas)) {

            areas.appointmentAreas.map(area => {
                areaOptions.push({value: area.id, label: area.description, placeholder: ''});
            });

            this.setState({areaOptions: areaOptions})
        }
    }

    handleDateChange = (date) => {
        let updatedFilters = this.state.filters;

        if (date === 'prev') {
            updatedFilters.fromDate = moment(updatedFilters.fromDate).subtract('1', 'day').toDate();
            updatedFilters.toDate = moment(updatedFilters.toDate).subtract('1', 'day').toDate();
        } else if (date === 'next') {
            updatedFilters.fromDate = moment(updatedFilters.fromDate).add('1', 'day').toDate();
            updatedFilters.toDate = moment(updatedFilters.toDate).add('1', 'day').toDate();
        } else {
            updatedFilters.fromDate = date;
            updatedFilters.toDate = date;
            // updatedFilters.toDate = moment(date).add('1', 'day').toDate();
        }
        // Store new date
        this.setState({
            filters: updatedFilters,
            showDropdownById: '',
        });

        // Fetch appointments
        this.getAppointmentsByFilters();

        //Removes Tasks from screen when new date
        this.setState({tasksVisible: false, taskDetailsVisible: false})
    }

    getAppointmentsByFilters = () => {
        let updatedFilters = this.state.filters
        updatedFilters.assignee = {value: this.props.username}
        this.setState({
            filters: updatedFilters
        })
        this.props.loadAppointments(getFormattedFilters(this.state.filters)).then(response => {
            this.generateAppointments(response);
        });
    }

    generateUsernameOptions = (users) => {
        if (users.length > 0) {
            let usernameOptions = users.map((systemUser) => {
                return {
                    value: systemUser.username,
                    label: systemUser.firstName + ' ' + systemUser.lastName
                };
            });

            this.setState({usernameOptions: usernameOptions})
        }
    }



    setOrderID = (orderId) => {
        this.setState({orderId: parseInt(orderId)})
    }

    accountAccessModal = (id) => {
        this.props.showAccessModal(true);
        this.props.showModal('ACCOUNT_ACCESS_MODAL', { accountId: id });
    }


    render() {

        const {
            appointmentsSorted,
            assigneeOptions,
            areaOptions,
            typeOptions,
            usernameOptions,
            filters,
            isAssigning,
            viewAs,
            tasksVisible,
            taskDetailsVisible,
            taskId
        } = this.state;

        const {
            dashboardMenuCount,
            appointmentsLoader,
            appointments,
            appointmentAreasLoader,
            appointmentAreas,
            username
        } = this.props;

        return(
            <div>
                <div className="cmv-container-dashboard-search">
                    <div className="cmv-container-dashboard-filter">
                        <div className="container">
                            <div className="form-row">

                                <div className="col-md-2">
                                     <div className="input-group input-group-datepicker">
                                            <div className="input-group-prepend">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-direction="prev"
                                                    onClick={() => this.handleDateChange('prev')}
                                                    disabled={appointmentsLoader || isAssigning}
                                                >
                                                    <i className="fa fa-chevron-left" />
                                                </button>
                                            </div>
                                            <DatePicker
                                                className={"form-control"}
                                                id="date"
                                                name="date"
                                                fieldName="date"
                                                dateFormat="MM/dd/yyyy"
                                                placeholderText="Choose Date"
                                                shouldCloseOnSelect={true}
                                                popperPlacement={"bottom-center"}
                                                selected={filters.fromDate}
                                                onChange={(date) => this.handleDateChange(date)}
                                                disabledKeyboardNavigation
                                                showDisabledMonthNavigation
                                                disabled={appointmentsLoader || isAssigning}
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    data-direction="next"
                                                    onClick={() => this.handleDateChange('next')}
                                                    disabled={appointmentsLoader || isAssigning}
                                                >
                                                    <i className="fa fa-chevron-right" />
                                                </button>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-2">
                                    <Select
                                        inputId={'appointmentAssignee'}
                                        name={'appointmentAssignee'}
                                        defaultValue={{label: username, value: username}}
                                        // placeholder={'Assignee'}
                                        onChange={this.changeAssigneeSelected}
                                        options={assigneeOptions}
                                        styles={groupStyles}
                                        isDisabled={true}
                                        isClearable={false}
                                    />
                                </div>
                                <div className="col-md-4 col-xxl-5">
                                    <Select
                                        inputId={'appointmentArea'}
                                        name={'appointmentArea'}
                                        value={filters.area}
                                        placeholder={'Area(s)'}
                                        onChange={this.changeAreaSelected}
                                        options={areaOptions}
                                        styles={groupStyles}
                                        isLoading={appointmentAreasLoader}
                                        isDisabled={appointmentsLoader || appointmentAreasLoader || isAssigning}
                                        isClearable={true}
                                        // isMulti={true}
                                    />
                                </div>
                                <div className="col-md-4 col-xxl-3">
                                    <div className="input-group">
                                        <Select
                                            inputId={'appointmentType'}
                                            name={'appointmentType'}
                                            value={filters.type}
                                            placeholder={'Type'}
                                            onChange={this.changeTypeSelected}
                                            options={typeOptions}
                                            styles={groupStyles}
                                            isDisabled={appointmentsLoader || isAssigning}
                                            isClearable={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {appointmentsLoader &&
                    <Loader />
                }

                {!appointmentsLoader && isEmpty(appointmentsSorted) &&
                    <div className="container">
                        <p>No data found</p>
                    </div>
                }

                {!appointmentsLoader && !isEmpty(appointmentsSorted) && (
                    <PersonalList
                        appointments={appointmentsSorted}
                        handleShowAssignDropdownById={this.handleShowAssignDropdownById}
                        handleAllTasksAssignee={this.handleAllTasksAssignee}
                        accountAccessModal={this.accountAccessModal}
                        usernameOptions={usernameOptions}
                        isAssigning={isAssigning}
                        setOrderId={this.setOrderID}
                        getAppointmentsByFilters={this.getAppointmentsByFilters}
                    />

                )}
            </div>

        )
    }
}

const getLoaderAppointments = createLoadingSelector(['GET_APPOINTMENTS']);
const getLoaderAppointmentAreas = createLoadingSelector(['GET_APPOINTMENT_AREAS']);

const mapStateToProps = (state) => {

    const appointmentsLoader = getLoaderAppointments(state);
    const appointments = getAppointments(state).appointments;
    const appointmentAreasLoader = getLoaderAppointmentAreas(state);
    const appointmentAreas = getAppointments(state).appointmentAreas;

    return {
        appointmentsLoader,
        appointments,
        appointmentAreasLoader,
        appointmentAreas,
    };
};

const mapDispatchToProps = {
    loadAppointments,
    clearAppointments,
    getAppointmentAreas,
    showModal,
    showAccessModal,
    getSystemUsers,
    emptySystemUsers,
    assignTask,
    unassignTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDay);
