import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import Summary from './Summary/Summary';
import Notes from './Notes/Notes';
import Logs from './Logs';
import StackedBar from '../../../../components/UI/OrdersAndQuotes/StackedBar';
import Button from '../../../../components/Common/Buttons/Button';
import { parseDateTimeFormat } from './utils';
import { isEmpty } from '../../../../utils/helpers';
import TargetDateTime from "../../../../components/UI/TargetDateTime";
import { getUserPermission } from "../../../../selectors";
import { showModal } from "../../../../actions/modal.actions";
import { connect } from "react-redux";
import Documents from "src/containers/AccountDetails/Panels/RightPanel/Tabs/Documents/Documents";


export class ServiceOrder extends Component {

	state = {
		isServiceExpanded: false,
		isDescriptionExpanded: false,
		isDocumentsExpanded: false,
		isNotesExpanded: false,
		isLogVisible: false,
		isDropDownMenuVisible: false,
		isServiceOrderCancelled: false,
		isServiceOrderCompleted: false,
	};

	componentDidMount() {
		if (this.props.serviceInfo.workflowStatusChain[0].groupState === "CANCELLED") {
			this.setState({isServiceOrderCancelled: true})
		}
		if (this.props.serviceInfo.hasOwnProperty('workflowEndDate')) {
			this.setState({isServiceOrderCompleted: true})
		}
	}

	handlerLogShowHide = () => {
		this.setState((prevState) => ({
			isLogVisible: !prevState.isLogVisible
		}));
	};

	handlerServiceExpand = () => {
		this.setState((prevState) => ({
			isServiceExpanded: !prevState.isServiceExpanded,
			isDescriptionExpanded: false,
			isNotesExpanded: false,
			isDocumentsExpanded: false
		}));
	};

	handlerDescriptionExpand = () => {
		this.setState((prevState) => ({
			isServiceExpanded: false,
			isDescriptionExpanded: !prevState.isDescriptionExpanded,
			isNotesExpanded: false,
			isDocumentsExpanded: false
		}));
	};

	handlerNotesExpand = () => {
		this.setState((prevState) => ({
			isServiceExpanded: false,
			isDescriptionExpanded: false,
			isNotesExpanded: !prevState.isNotesExpanded,
			isDocumentsExpanded: false
		}));
	};

	handlerDocumentsExpand = () => {
		this.setState((prevState) => ({
			isServiceExpanded: false,
			isDescriptionExpanded: false,
			isNotesExpanded: false,
			isDocumentsExpanded: !prevState.isDocumentsExpanded
		}));
	};

	handleDropDownMenu = () => {
		this.setState((prevState) => ({
			isDropDownMenuVisible: !prevState.isDropDownMenuVisible
		}));
	};

	renderIconStatus = (statuses) => {
		let icon;
		let findProcesingStatus = statuses.find((x) => x.groupState === 'PROCESSING');
		let findCancelledStatus = statuses.find((x) => x.groupState === 'CANCELLED');
		if (findProcesingStatus) {
			if (findProcesingStatus.currentStatus.processingStatus === 'ERROR') {
				icon = <i className="fas fa-exclamation-triangle text-danger" />;
			} else {
				icon = <i className="fas fa-list-ul" />;
			}
		}
		else {
			icon = <i className="fas fa-list-ul" />;
		}

		return icon;
	};

	checkIfExist = (serviceLines, type) => {
		let isExist = false;
		serviceLines.forEach((element) => {
			if (element[type] && element[type].length > 0) {
				isExist = true;
			}
		});
		return isExist;
	};


	render() {

		const {
			isServiceOrderCancelled,
			isServiceOrderCompleted,
			isServiceExpanded,
			isDescriptionExpanded,
			isNotesExpanded,
			isDocumentsExpanded,
			isLogVisible,
		} = this.state;

		const {
			isCancelled,
			isCompleted,
			isExecuteButtonClicked,
			serviceInfo,
			canUpdateTargetDateTime,
			serviceOrderType,
			parentCallback,
		} = this.props;

		return (
			<div className="order-service-order-wrapper">
				<div className="order-header">

					<div className="order-service-order-status">

						<StackedBar statuses={serviceInfo.workflowStatusChain} isStarted={serviceInfo.workflowStartDate} />

						<span className="d-flex text-muted">
							<small className="d-md-inline-block ml-auto">
								{serviceInfo.workflowDescription}{' '}
								<Button
									onClick={this.handlerLogShowHide}
									className="btn btn-xs btn-order-log-toggle"
									type="button"
								>
									{this.renderIconStatus(serviceInfo.workflowStatusChain)}
								</Button>
							</small>
						</span>

					</div>

					{isLogVisible === true &&
					<Logs serviceOrderId={serviceInfo.id} />
					}

					<div className="row">
						<div className="col-12 col-sm-4 col-md-2">

							<div className="order-info">
								<TargetDateTime targetDateTime={serviceInfo.targetDateTime} />
								<span>
									{'Updated: '}
									<span className="font-weight-bold">
										{serviceInfo.modifiedDateTime && parseDateTimeFormat(serviceInfo.modifiedDateTime)}
									</span>
								</span>
								<span className="font-weight-bold">SO#{serviceInfo.id}</span>
								&nbsp;{'| '}
								{serviceInfo.modifiedBy}
							</div>

						</div>
						<div className="col-12 col-sm-8 col-md-6">

							<div className="order-title">
								<p><i className={serviceInfo.icon} />&nbsp;{serviceInfo.description}</p>
								{serviceOrderType !== 'taskDetails'
								?
								<span className="text-muted">{serviceInfo.serviceAddress}</span>
								:
								<span className="text-muted">
									{serviceInfo.serviceAddress === 'No Address required'
									?
									serviceInfo.serviceAddress
									:
									<a href={`http://maps.google.com/maps?q=${serviceInfo.latitude},${serviceInfo.longitude}`} target="_blank" className="link-map">
										{serviceInfo.serviceAddress}
									</a>
									}
								</span>
								}
							</div>

						</div>
						<div className="col-12 col-md-4">

							<div className="order-toolbar" style={{paddingTop:'15px', paddingBottom:'15px'}}>
								<div className="btn-group btn-group-sm">

									{!isCancelled && serviceInfo.activeUserTasks > 0 && serviceOrderType !== 'taskDetails' && (
									<Link
										className="btn btn-warning"
										to={{
											pathname: `/task-list`,
											search: `?serviceOrderId=${serviceInfo.id}`
										}}
									>
										<i className="fas fa-tools" />&nbsp;{serviceInfo.activeUserTasks} {serviceInfo.activeUserTasks > 1 ? 'Tasks' : 'Task'} Pending
									</Link>
									)}

									{canUpdateTargetDateTime && (isEmpty(serviceOrderType)) &&
									<button
										className="btn btn-outline-secondary"
										disabled={isCancelled || isCompleted || isExecuteButtonClicked || isServiceOrderCancelled || isServiceOrderCompleted}
										onClick={() =>
											this.props.showModal('TARGET_DATE_MODAL', {
												serviceOrderId: serviceInfo.id,
												targetDateTime: serviceInfo.targetDateTime,
												parentCallback: parentCallback
											})}
									>
										<i className="far fa-calendar-check"/>
									</button>
									}
									<button
										title="Show Mapping Information"
										className="btn btn-outline-secondary"
										onClick={() => this.props.showModal('SERVICE_ADDRESS_MODAL', {
											title: "Service Address Information",
											type: "SERVICE_ORDER",
											id: serviceInfo.id || '',
											addressId: serviceInfo.addressId || '',
											service: {
												address1: serviceInfo.address1 || '',
												address2: serviceInfo.address2 || '',
												address3: serviceInfo.address3 || '',
												city: serviceInfo.city || '',
												latitude: serviceInfo.latitude || '',
												longitude: serviceInfo.longitude || '',
												mappingAddressId: serviceInfo.mappingAddressId || '',
												mappingAreaId: serviceInfo.mappingAreaId || '',
												mappingRefArea1: serviceInfo.mappingRefArea1 || '',
												state: serviceInfo.state || '',
												country: serviceInfo.country || '',
												zipcode: serviceInfo.zipcode || '',
												zip4: serviceInfo.zip4 || '',
												mappingNote: serviceInfo.mappingNote || '',
												addressValues: serviceInfo.savedAddressZoneValues || []
											},
											canViewCSIGeocode: false,
											canEditCSIGeocode: false,
											isDisabled: true
										})}
									>
										<i className="fas fa-map" />
									</button>

									<button
										className="btn btn-outline-secondary"
										onClick={this.handlerDescriptionExpand}
										title={"Service Information"}
									>
										<i className="fas fa-info"/>
									</button>

									<button
										className="btn btn-outline-secondary"
										onClick={this.handlerNotesExpand}
										title={"Notes"}
									>
										<i className="fas fa-file-signature"/>
									</button>

										<button
											className="btn btn-outline-secondary"
											onClick={this.handlerDocumentsExpand}
											title={"Attach Document"}
										>
											<i className="fas fa-paperclip"/>
										</button>

									{serviceInfo.serviceLines[0]?.features &&
										<button
											onClick={this.handlerServiceExpand}
											className="btn btn-outline-secondary btn-order-substep-collapse-toggle"
											title={"Service Summary"}
										>
											<i className={isServiceExpanded === true ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down'}/>
										</button>
									}
									
								</div>
							</div>

						</div>
					</div>
				</div>

				{isServiceExpanded &&
				<Summary serviceOrderId={serviceInfo.id} />
				}

				{isDescriptionExpanded === true &&
				<div className="order-body collapse show">
					<div className="row">

						<div className={"col-12" + (serviceInfo.serviceLines.length === 0 ? '' : ' col-sm-4')}>
							<h6>{ReactHtmlParser(serviceInfo.planDescription)}</h6>
							<p>{ReactHtmlParser(serviceInfo.planNote)}</p>
						</div>

						{serviceInfo.serviceLines && this.checkIfExist(serviceInfo.serviceLines, 'numbers') &&
						<div className="col-12 col-sm-4">
							<h6>Numbers</h6>
							{serviceInfo.serviceLines.map((serviceLine, index) => {
								if (serviceLine.numbers) {
									return (
										<p key={index}>
											{serviceLine.numbers.map((number, index, arr) => {
												if (index !== arr.length - 1) {
													return `${number} | `;
												} else {
													return `${number}`;
												}
											})}
										</p>
									);
								}
							})}
						</div>
						}

						{serviceInfo.serviceLines && this.checkIfExist(serviceInfo.serviceLines, 'equipmentItems') &&
						<div className="col-12 col-sm-4">
							<h6>Equipment</h6>
							{serviceInfo.serviceLines.map((serviceLine, index) => {
								if (serviceLine.equipmentItems) {
									return (
										<p key={index}>
											{serviceLine.equipmentItems.map((equipment, index, arr) => {
												if (index !== arr.length - 1) {
													return `${equipment} | `;
												} else {
													return `${equipment}`;
												}
											})}
										</p>
									);
								}
							})}
						</div>
						}

					</div>
				</div>
				}
				<div>
					{isNotesExpanded &&
						<Notes serviceOrderId={serviceInfo.id} />
					}

					{isDocumentsExpanded &&
						<Documents serviceOrderId={serviceInfo.id} accountId={this.props.accountId} canDelete={false} />
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const canUpdateTargetDateTime = getUserPermission(state, 'ADMN', 'ADMN_MANAGE_TARGET_DATE');

	return {
		canUpdateTargetDateTime,
	};
};

const mapDispatchToProps = {
	showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrder);
