import axiosInstance from '../../utils/axios-instance';
import { actionCreator, createRequestTypes } from '../../utils/helpers';

export const DASHBOARD_ACCOUNT_SEARCH = createRequestTypes('DASHBOARD_ACCOUNT_SEARCH');
export const SET_DASHBOARD_MENU_COUNT = createRequestTypes('SET_DASHBOARD_MENU_COUNT');
export const SET_DASHBOARD_ACCOUNT_STATUS_OPTIONS = createRequestTypes('SET_DASHBOARD_ACCOUNT_STATUS_OPTIONS')

// CUSTOMERS //
/**
 * Looks like we have a duplicate of the same action, in `account-search.js`
 * 
 * @param {*} filters 
 * @returns 
 */
export const getAccounts = (filters) => async (dispatch) => {
	dispatch(actionCreator.request(DASHBOARD_ACCOUNT_SEARCH));
	try {
		if (filters.term || filters.accountType || filters.accountStatus || filters.accountTerm) {
			let response = await axiosInstance.get(`/accounts`, { params: filters });
			dispatch(actionCreator.success(DASHBOARD_ACCOUNT_SEARCH, response.data))
		} else {
			dispatch(actionCreator.success(DASHBOARD_ACCOUNT_SEARCH, []))
		}
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(DASHBOARD_ACCOUNT_SEARCH, mockError));
	}
};

export const getCustomersServedHistory = () => async (dispatch) => {
	dispatch(actionCreator.request(DASHBOARD_ACCOUNT_SEARCH));
	try {
		const response = await axiosInstance.get(`/user/servedaccounts`);
		dispatch(actionCreator.success(DASHBOARD_ACCOUNT_SEARCH, response.data));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(DASHBOARD_ACCOUNT_SEARCH, mockError));
	}
};

export const getDashboardMenuCount = () => async (dispatch) => {
	dispatch(actionCreator.request(SET_DASHBOARD_MENU_COUNT));
	try {
		const response = await axiosInstance.get(`/user/counts`);
		dispatch(actionCreator.success(SET_DASHBOARD_MENU_COUNT, response.data.userCounts));
	} catch (err) {
		let mockError = {
			message: 'Not found'
		};
		dispatch(actionCreator.failure(SET_DASHBOARD_MENU_COUNT, mockError));
	}
};

export const emptyAccounts = () => (dispatch) => {
	dispatch(actionCreator.empty(DASHBOARD_ACCOUNT_SEARCH));
};

export const emptyDashboardMenuCount = () => (dispatch) => {
	dispatch(actionCreator.empty(SET_DASHBOARD_MENU_COUNT));
};

export const getAccountStatusTypes = () => async (dispatch) => {
	dispatch(actionCreator.request(SET_DASHBOARD_ACCOUNT_STATUS_OPTIONS));
	try {
		const response = await axiosInstance.get('domain/account_statuses');
		dispatch(actionCreator.success(SET_DASHBOARD_ACCOUNT_STATUS_OPTIONS, response.data.statuses));
	} catch(err) {
		let mockError = {
			message: 'Not found'
		}
		dispatch(actionCreator.failure(SET_DASHBOARD_ACCOUNT_STATUS_OPTIONS, mockError));

	}
}

export const getAccountTerms = () => async (dispatch) => {
	try {
		const response = await axiosInstance.get('domain/accountterms');
		return response.data.account_terms
	} catch(err) {
		let mockError = {
			message: 'Not found'
		}
	}
}

export const getAccountTypes = () => async (dispatch) => {
	try {
		const response = await axiosInstance.get('domain/accounttypes');
		return response.data.account_types
	} catch(err) {
		let mockError = {
			message: 'Not found'
		}
	}
}
