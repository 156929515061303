/**
 * A component to display the summary of item selection for a new sales order.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { formatCurrency } from "src/utils/ui";

/**
 * @param {any} props.values - Formik formprops.values from item selector form
 */
class ItemSelectionSummaryPane extends Component {
  state = {};

  calculateTaxes() {
    const taxes = [];

    this.props.values.lines
      .filter((itemLine) => itemLine?.item?.id)
      .map((itemLine) => itemLine?.item)
      .forEach((item) => {});
    const totalTax = taxes.reduce((acc, tax) => acc + tax.amount, 0);

    const itemsTotal = this.props.values.lines
      .filter((itemLine) => itemLine?.item?.id)
      .reduce((acc, itemLine) => acc + itemLine.quantity * itemLine.item.price, 0);

    const total = itemsTotal + totalTax;

    return { taxes, totalTax, itemsTotal, total };
  }

  //
  //
  //

  render() {
    const { taxes, totalTax, itemsTotal, total } = this.calculateTaxes();

    return (
      <div className="salesorder-summary-pane">
        <div className="py-1 px-2 mb-3 bg-info">
          <h5 className="mb-0 text-white">Summary</h5>
        </div>
        <div className="form-group salesorder-summary-section">
          <h6>Items</h6>
          <hr />
          {this.props.values.lines
            .filter((itemLine) => itemLine?.item?.id)
            .map((itemLine, idx) => {
              const quantity = itemLine.quantity;
              const item = itemLine.item;
              return (
                <div key={`salesorder_summary_item_line_${idx}`} className="form-group row">
                  <div className="col-8">
                    <span>
                      {item.quantity || 1} &times; {item.description}
                    </span>
                    <div className="mw-100 overflow-hidden text-muted small pl-2">
                      {item.sku && <span className="mr-1">SKU:{item.sku}<br/></span>}
                      <span className="mr-1">{(item.numbers || []).map((n, nidx) => `${n.name}:${n.number}`).join(', ')}</span>
                    </div>
                  </div>
                  <div className="col-4">{formatCurrency(item.price * (quantity || 1))}</div>
                </div>
              );
            })}
          <div className="form-group row salesorder-summary-subtotal">
            <div className="col-8 text-right">Subtotal:</div>
            <div className="col-4">{formatCurrency(itemsTotal)}</div>
          </div>
        </div>
        <div className="form-group salesorder-summary-section">
          <h6>Taxes</h6>
          <hr />
          {taxes.map((tax, idx) => (
            <div key={`salesorder_summary_tax_line_${idx}`} className="form-group row">
              <div className="col-8">
                <span>{tax.name}</span>
              </div>
              <div className="col-4">{formatCurrency(tax.amount)}</div>
            </div>
          ))}
          <div className="form-group row salesorder-summary-subtotal">
            <div className="col-8 text-right">Total Tax:</div>
            <div className="col-4">{formatCurrency(totalTax)}</div>
          </div>
        </div>
        <hr />
        <div className="form-group salesorder-summary-total row">
          <div className="col-8 text-right">Total:</div>
          <div className="col-4">{formatCurrency(total)}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ItemSelectionSummaryPane);
