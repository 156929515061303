import {
	GET_APPOINTMENT_TYPES,
	GET_APPOINTMENTS,
	GET_ORDER_TIMESLOTS,
	GET_TROUBLE_TICKET_TIMESLOTS
} from '../actions/appointments.actions';

let initialState = {
	appointments: [],
	orderTimeslots: [],
	troubleTicketTimeslots: [],
	appointmentTypes: []
};

export const appointmentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_APPOINTMENTS.SUCCESS:
			return {
				...state,
				appointments: action.payload.troubleTicketOrderAppointments
			}
		case GET_APPOINTMENTS.CLEAR:
			return {
				...state,
				appointments: []
			}
		case GET_ORDER_TIMESLOTS.SUCCESS:
			return {
				...state,
				orderTimeslots: action.payload
			}
		case GET_ORDER_TIMESLOTS.CLEAR:
			return {
				...state,
				orderTimeslots: []
			}
		case GET_TROUBLE_TICKET_TIMESLOTS.SUCCESS:
			return {
				...state,
				troubleTicketTimeslots: action.payload
			}
		case GET_TROUBLE_TICKET_TIMESLOTS.CLEAR:
			return {
				...state,
				troubleTicketTimeslots: []
			}
		case GET_APPOINTMENT_TYPES.SUCCESS:
			return {
				...state,
				appointmentTypes: action.payload
			}
		case GET_APPOINTMENT_TYPES.CLEAR:
			return {
				...state,
				appointmentTypes: []
			}
		default:
			return state;
	}
}
