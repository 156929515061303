import React, {Component, Fragment} from 'react';
import {Formik, Form} from 'formik';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    getPlanDetails,
    getFeatures,
    emptyPlanDetails,
    updateChangeService
} from '../../../actions/accountDetailsActions/updateService.actions';
import {hideAccountDetailsWizard, startOrder} from '../../../actions/accountDetailsActions/accountDetails.actions';
import {getAccountDetails, getErrorMessage} from '../../../selectors';
import {isEmpty, generateId, applyOnEnum} from '../../../utils/helpers';
import {transformPlanDetails, mapInitialState} from './utils';

import NavTabs from './NavTabs';
import Products from './Tabs/Products/Products';
import Confirmation from './Tabs/Confirmation';
import Button from '../../../components/Common/Buttons/Button';
import {validationSchema} from "./validation"

export class UpdateService extends Component {
    state = {
        loadingError: false,
        initialValues: {
            name: 'SERVICE_CHANGE_ORDER_' + generateId(),
            source: 'CAMVIO-WEB',
            note: 'This is a note',
            accountId: this.props.accountInfo.id,
            createdBy: this.props.userSummary.id,
            planId: this.props.accountDetailsWizardData.wizardData.planId,
            existingServiceLineId: this.props.accountDetailsWizardData.wizardData.serviceLineId,
            applyOn: Object.keys(applyOnEnum)[0],
            features: []
        },
        activeFeaturesSnapshot: [],
        plansDetails: {},
        activeTab: 0,
        isSummaryFullWith: false,
        isSummaryCollapsed: false,
    };

    componentDidMount() {
        const {
            changeNavBarType,
            currentLocation,
            accountInfo,
            getPlanDetails,
            accountDetailsWizardData: {wizardData}
        } = this.props;

        // Change navbar type and color
        document.title = 'Change Features Request - camvio.cloud';
        changeNavBarType('lock', 'newOrder', 'Change Features Request');

        getPlanDetails(accountInfo.id, currentLocation.id, wizardData.planId,wizardData.serviceLineId);
    }

    componentDidUpdate(prevState) {

        if (this.props.planDetails.success === false && this.state.loadingError === false) {
            this.setState({loadingError: this.props.planDetails.error.message || "There was an error."});
            return;
        }

        if (!isEmpty(this.props.error)) {
            return;
        }

        if (prevState.planDetails !== this.props.planDetails) {
            const {accountInfo, planDetails, accountDetailsWizardData: {wizardData}} = this.props;

            let transformedData = transformPlanDetails(wizardData.planRelation, planDetails);

            this.props.getFeatures(accountInfo.id, wizardData.serviceLineId).then((response) => {
                const activeFeatures = response;

                const transformedInitialState = mapInitialState(
                    wizardData.planRelation,
                    transformedData,
                    activeFeatures
                );

                if (transformedInitialState === 'error') {
                    this.setState({loadingError: true});
                }
                else {

                    const transformedActiveFeatures = Object.keys(activeFeatures).reduce((acc, currentValue) => {
                        acc.push(...activeFeatures[currentValue]);
                        return acc;
                    }, []);

                    const copyInitialValues = {...this.state.initialValues};
                    copyInitialValues['features'] = transformedInitialState;

                    //Check to see if a feature is enabled and set price
                    if(transformedData.mainOptionalFeatures) {
                        transformedData.mainOptionalFeatures.map((feature => {
                            if(copyInitialValues.features.findIndex((x) => x.id == feature.id) === -1){
                                feature.recurringMonthlyPrice = feature.price;
                            }
                        }))
                    }

                    this.setState({
                        plansDetails: transformedData,
                        initialValues: copyInitialValues,
                        activeFeaturesSnapshot: transformedActiveFeatures
                    });
                }
            });
        }
    }

    componentWillUnmount() {
        const {changeNavBarType, emptyPlanDetails, hideAccountDetailsWizard, documentTitle, navbarTitle} = this.props;

        // Change navbar type and color
        document.title = documentTitle;
        changeNavBarType('account', 'accountDetails', navbarTitle);

        hideAccountDetailsWizard();
        emptyPlanDetails();
    }

    renderTabContent = (formProps) => {
        const {loadingError, activeTab, plansDetails, isSummaryCollapsed, isSummaryFullWith, errors} = this.state;
        const {updatedServiceInfoData, changeActiveLeftPanelHandler, hideAccountDetailsWizard, accountInfo, values} = this.props;
        let tabContent;
        switch (activeTab) {
            case 0:
                tabContent = (
                    <Products
                        plansDetails={plansDetails}
                        accountInfo = {accountInfo}
                        isSummaryFullWith={isSummaryFullWith}
                        isSummaryCollapsed={isSummaryCollapsed}
                        handleFullWithSummary={this.handleFullWithSummary}
                        handleSummaryCollapsed={this.handleSummaryCollapsed}
                        handleChangeApplyOn={this.handleChangeApplyOn}
                        loadingError={loadingError}
                        errors={errors}
                        {...formProps}
                    />
                );
                break;
            case 1:
                tabContent = (
                    <Confirmation
                        updatedServiceInfoData={updatedServiceInfoData}
                        startOrder={startOrder}
                        handleViewOrder={this.handleViewOrder}
                    />
                );
                break;
            default:
                tabContent = null;
                break;
        }

        return tabContent;
    };

    handleFullWithSummary = () => {
        this.setState((prevState) => ({
            isSummaryFullWith: !prevState.isSummaryFullWith
        }));
    };

    handleSummaryCollapsed = () => {
        this.setState((prevState) => ({
            isSummaryCollapsed: !prevState.isSummaryCollapsed
        }));
    };

    handleViewOrder = () => {
        const {hideAccountDetailsWizard, changeActiveLeftPanelHandler} = this.props;

        changeActiveLeftPanelHandler('ordersAndQuotes');
        hideAccountDetailsWizard();
    };

    handleChangeApplyOn = (value,setFieldValue) => {
        setFieldValue('applyOn',value);
        // this.setState(prevState => ({
        //     initialValues: {
        //         ...prevState.initialValues,
        //         applyOn: value
        //     }
        // }));
    }

    next = (values, actions) => {
        const {activeTab} = this.state;

        let updateRequestValues = {
            ...values,
            features: [...values.features]
        };

        if( updateRequestValues.applyOn === 'ORDER COMPLETION' ){
            updateRequestValues.applyOn = 'ORDER_COMPLETION'
        } else if ( updateRequestValues.applyOn === 'CURRENT BILL CYCLE' ) {
            updateRequestValues.applyOn = 'CURRENT_BILL_CYCLE'
        } else if ( updateRequestValues.applyOn === 'NEXT BILL CYCLE' ) {
            updateRequestValues.applyOn = 'NEXT_BILL_CYCLE'
        } else if ( updateRequestValues.applyOn === 'SELECT DATE') {
            updateRequestValues.applyOn = 'SELECT_DATE'
        }

        // Go over currently active features
        this.state.activeFeaturesSnapshot.forEach((feature) => {

            // Iterate plans
            if (feature.featurePackageId) {
                let findIndex = updateRequestValues.features.findIndex(
                    (x) =>
                        x.featurePackageId === feature.featurePackageId &&
                        x.featurePackageOptionId === feature.featurePackageOptions[0].featurePackageOptionId
                );

                const findPackage = updateRequestValues.features.find(
                    (x) =>
                        x.featurePackageId === feature.featurePackageId &&
                        x.featurePackageOptionId === feature.featurePackageOptions[0].featurePackageOptionId
                );
                if (findPackage === undefined) {
                    let featurePackage = {
                        featurePackageId: feature.featurePackageId,
                        featurePackageDescription: feature.description,
                        featurePackageNote: feature.note,
                        ...feature.featurePackageOptions[0],
                        action: 'DISABLE'
                    };
                    updateRequestValues.features.push(featurePackage);
                } else {
                    updateRequestValues.features = updateRequestValues.features.filter(
                        (x) =>
                            x.featurePackageId !== feature.featurePackageId &&
                            x.featurePackageOptionId !== feature.featurePackageOptions[0].featurePackageOptionId
                    );
                }
            }

            // Iterate features
            else if (feature.featureId) {

                // Look for feature in updated features array
                const findFeature = updateRequestValues.features.find((x) => x.id === feature.featureId);

                // If active feature is not found - DISABLE
                if (findFeature === undefined) {

                    let featurePackage = {
                        id: feature.featureId,
                        action: 'DISABLE'
                    };
                    updateRequestValues.features.push(featurePackage);
                }

                // If active feature is found and is variable quantity
                else if (findFeature.variableQuantity) {

                    // If feature IS NOT variable price or IS, but price is unchanged
                    if (!findFeature.variablePrice ||
                        (findFeature.variablePrice && findFeature.featureVariabledPrice === feature.featureVariabledPrice)
                    ) {

                        // If feature quantity is unchanged - remove from API call
                        if (findFeature.featureVariabledQuantity === feature.featureVariabledQuantity) {
                            updateRequestValues.features = updateRequestValues.features.filter((x) => x.id !== feature.featureId);
                        }
                    }
                }

                // If active feature is found and is variable price
                else if (findFeature.variablePrice) {
                    // If feature IS NOT variable quantity or IS, but quantity is unchanged
                    if (!findFeature.variableQuantity ||
                        (findFeature.variableQuantity && findFeature.featureVariabledQuantity === feature.featureVariabledQuantity)
                    ) {
                        // If feature price is unchanged - remove from API call
                        if (findFeature.featureVariabledPrice === feature.featureVariabledPrice) {
                            updateRequestValues.features = updateRequestValues.features.filter((x) => x.id !== feature.featureId);
                        }
                    }
                }

                // If active feature is found and is not variable quantity/price - remove from API call
                else {
                    updateRequestValues.features = updateRequestValues.features.filter((x) => x.id !== feature.featureId);
                }
            }
        });

        //Transform values in acceptable api post format
        updateRequestValues.features = updateRequestValues.features.map((feature) => {

            if (feature.featurePackageId) {
                return {
                    // id: feature.featurePackageId,
                    featurePackageOptionId: feature.featurePackageOptionId,
                    action: feature.action
                };
            } else if (feature.id) {
                if (feature.variableQuantity) {
                    return {
                        id: feature.id,
                        action: feature.action,
                        featureVariabledQuantity: feature.featureVariabledQuantity,
                        featureVariabledPrice: feature.featureVariabledPrice!=='undefined' ? feature.featureVariabledPrice : null,
                    };
                }
                else {
                    return {
                        id: feature.id,
                        action: feature.action,
                        featureVariabledPrice: feature.featureVariabledPrice!=='undefined' ? feature.featureVariabledPrice : null,
                    };
                }
            }
            return feature;
        });

        updateRequestValues.autoExecuteOrder = true

        this.props.updateChangeService(updateRequestValues).then((response) => {

            if (response.success) {
                this.setState((prevState) => ({
                    activeTab: prevState.activeTab + 1
                }));
            } else {
                console.log('Error')
            }
        });
    };

    render() {
        const {loadingError, initialValues, activeTab, errors} = this.state;
        const {hideAccountDetailsWizard, error} = this.props;

        return (
            <div className="cmv-wizard">
                <div className="wizard-container">
                    {!isEmpty(error) ? (
                        <div className="container">
                            {error}
                        </div>
                    ) : (
                        <div className="container">
                            <NavTabs activeTab={activeTab}/>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema(activeTab)}
                                onSubmit={this.next}
                                enableReinitialize
                                render={(props) => (
                                    <Form onSubmit={props.handleSubmit} className="cmv-form" autoComplete="off">
                                        <div className="tab-content wizard-content">{this.renderTabContent(props)}</div>
                                        <div className="wizard-footer d-flex justify-content-between">
                                            {activeTab === 0 && (
                                                <button
                                                    type="submit"
                                                    className="btn btn-outline-primary btn-next-tab ml-auto"
                                                    disabled={loadingError || !isEmpty({...props.errors})}
                                                >
                                                    SUBMIT REQUEST
                                                </button>
                                            )}
                                            {activeTab === 1 && (
                                                <Button
                                                    className="btn btn-outline-primary btn-next-tab ml-auto"
                                                    type="button"
                                                    onClick={hideAccountDetailsWizard}
                                                >
                                                    Finish
                                                </Button>
                                            )}
                                        </div>
                                    </Form>
                                )}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

UpdateService.propTypes = {
    hideAccountDetailsWizard: PropTypes.func
};

const errorMessageSelector = getErrorMessage(['SET_UPDATE_REQUEST_PLAN_DETAILS']);

const mapStateToProps = (state) => {
    const accountDetailsWizardData = getAccountDetails(state).accountDetails.accountDetailsWizardData,
        planDetails = getAccountDetails(state).updateRequestWizard.planDetails,
        updatedServiceInfoData = getAccountDetails(state).updateRequestWizard.updatedServiceInfo,
        error = errorMessageSelector(state);

    return {
        accountDetailsWizardData,
        updatedServiceInfoData,
        planDetails,
        error
    };
};

const mapDispatchToProps = {
    updateChangeService,
    getPlanDetails,
    emptyPlanDetails,
    getFeatures,
    hideAccountDetailsWizard
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateService);
