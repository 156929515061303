import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { statusColor } from '../../../../utils/ui';

const Account = ({ account, accountAccessModal }) => {
	const [ isShowingAccountId, showHideAccountId ] = useState(false);

	const renderCustomerPortalStatus = () => {
		if(account.systemUser) {
			if(account.systemUser.isActive) {
				return "btn-icon-success"
			} else {
				return "icon-invalid"
			}
		} else {
			return "icon-inactive"
		}
	}

	return (
		<div
			className="cmv-container-client"
			onClick={() => accountAccessModal(account.id)}
			key={account.id}
		>
			<div className="cmv-client-data-basic">
				<div className="cmv-client">
					<h2 className="cmv-client-name">{account.company ? account.company : account.name}</h2>
					<div style={{display:'flex', flexDirection:'column'}}>
						<div className={`cmv-client-status cmv-client-status-${statusColor(account.tierLevel)}`}>
							<span className="status-text">{account.status}</span>
							<div className={`status-bar status-bar-${account.tierLevel * 20}`}>
								<div className="status-bar-separator" />
								<div className="status-bar-separator" />
								<div className="status-bar-separator" />
								<div className="status-bar-separator" />
							</div>
						</div>
					</div>
				</div>
				<div className="cmv-client">
					<p className="cmv-client-address">
						<i className="fas fa-file-invoice-dollar" /> {account.billingAddress}
					</p>
					<div className={"btn-icon-success"} style={{display:'flex', gap:'5px'}}>
						{account.serviceModels && account.serviceModels.map((icon, i) => (
							<i key={`acct-sm-icon-%${i}`} className={icon.icon} title={icon.description}/>
						))}
					</div>
				</div>
			</div>
			<div className="cmv-client-data-extended">
				<div className="row">
					<div className="col-md" style={{flexGrow:0.7}}>
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-hashtag" />
								<span className="d-md-inline-block">
									{isShowingAccountId ? `Account id` : `Account #`}{' '}
								</span>
							</div>
							<div
								className="card-body"
								onClick={(e) => {
									e.stopPropagation();
									showHideAccountId((prevState) => !prevState);
								}}
							>
								<span
									className={
										isShowingAccountId ? 'date-toggable date-toggable-relative' : 'date-toggable'
									}
								>
									{isShowingAccountId ? account.id : account.number}
								</span>
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-list-alt" />
								<span className="d-md-inline-block"> Account type</span>
							</div>
							<div className="card-body">{account.accountType}</div>
						</div>
					</div>
					<div className="col-md" style={{flexGrow:1.4}}>
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-list-alt" />
								<span className="d-md-inline-block"> Account terms</span>
							</div>
							<div className="card-body">{account.accountTerm}</div>
						</div>
					</div>
					<div className="col-md" >
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-history" />
								<span className="d-md-inline-block"> Customer since</span>
							</div>
							<div className="card-body">
								{account.createdDate ? moment(account.createdDate).format('MM/DD/YYYY') : ''}
							</div>
						</div>
					</div>
					<div className="col-md">
						<div className="card card-xs">
							<div className="card-header">
								<i className="fas fa-fw fa-history" />
								<span className="dd-md-inline-block">First Service</span>
							</div>
							<div className="card-body">
								{account.overallServiceStartDate ? moment(account.overallServiceStartDate).format('MM/DD/YYYY') : ''}
							</div>
						</div>
					</div>
					<div className="col-md" style={{paddingTop:'10px', display:'flex'}}>
						<div className="card card-xs">
							<div className="card-body" style={{ display:'flex'}}>
									<div title={"Paperless"} className={account.distributionMethods?.includes("Printed Invoice") ? "icon-inactive" : "btn-icon-success"} style={{fontSize:'1.6rem'}}>
										<i className="fab fa-fw fa-envira"  />
									</div>
									<div title={"AutoPay"} className={account.autoPay ? "btn-icon-success" : "icon-inactive"}>
										<span className="fa-stack fa-1x" style={{fontSize:'0.875rem'}}>
											<i className="fas fa-fw fa-sync-alt fa-stack-2x" />
											<i className="fas fa-fw fa-dollar-sign fa-stack-1x" />
										</span>
									</div>
									<div title={"Customer Portal"} className={renderCustomerPortalStatus()}>
										<span className="fa-stack" style={{fontSize:'0.875rem'}}>
											<i className="fas fa-user fa-stack-2x" />
											<i className="fas fa-desktop fa-stack-1x" style={{backgroundColor:"#E9ECEF", borderTopLeftRadius:'3px',
											fontSize:'1.1em', lineHeight:'1.3', transform:"translate(16px, 13px)", width:'1.5em'}}/>
										</span>
									</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Account.propTypes = {
	account: PropTypes.object,
	history: PropTypes.object,
	portalAccess: PropTypes.object,
};

export default Account;