import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import isEmpty from "../../../../utils/helpers";
import FormSelect from "../../../../components/UI/FormSelect";
import TextInput from "../../../../components/UI/TextInput";

import { hideModal } from '../../../../actions/modal.actions';
import { getModalData, getUser, getDomain, getCreateAccount, getAdmin } from '../../../../selectors';
import { getSystemLocation, addSystemLocation, updateSystemLocation, clearSystemLocation } from '../../../../actions/admin.actions';
import { getSystemCountries } from '../../../../actions/domain.actions';
import { getCountryStates } from '../../../../actions/createAccount.actions';
import LoadingModalContent from '../../../../components/Modal/LoadingModalContent';

class AddLocationModal extends Component {
    state = {
        alertMessage: '',
        isLoading: true,
    };

    componentDidMount() {
        this.props.getCountries();
        this.props.getCountryStates();

        const { modalProps } = this.props;

        if (modalProps.value) {
            this.props.getLocation(modalProps.value).then((response) => {
                this.setState({
                    isLoading: false,
                })
            })
        } else {
            this.props.clearLocation().then(() => {
                this.setState({
                    isLoading: false,
                })
            });
        }
    }

    componentWillUnmount() {
    }

    formSchema = () => {
        let validationSchema = {
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            address1: Yup.string().optional(),
            address2: Yup.string().optional(),
            address3: Yup.string().optional(),
            county: Yup.string().optional(),
            city: Yup.string().optional(),
            zipcode: Yup.string().optional(),
            state: Yup.string().optional(),
            country: Yup.string().optional(),
            geoPosition: Yup.string().optional(),
            allowOrder: Yup.bool().optional(),
            locationUrl: Yup.string().optional(),
            twitterAccount: Yup.string().optional(),
            allowPickup: Yup.bool().optional(),
            ipAddressExpression: Yup.string().optional(),
            posAccount: Yup.number().optional(),
            isScheduled: Yup.bool().optional(),
            timezone: Yup.string().optional(),
        };

        return Yup.object().shape(validationSchema);
    }

    hideModal = () => {
        this.props.clearLocation();
        this.props.hideModal();
    }

    formSubmit = (values, actions) => {
        this.setState({ alertMessage: '' }, () => {
            const method = this.props.modalProps.type === 'create' ? this.props.addLocation : this.props.updateLocation;

            const postData = {
                ...values,
                name: values.name.toUpperCase() // name will contain only the uppercase letters.
            };

            method(postData).then((response) => {
                if (!response.data) {
                    actions.setSubmitting(false);
                    this.setState({ alertMessage: 'Unknown error. Please try again later' });
                    return;
                }

                if (!response.data.success) {
                    actions.setSubmitting(false);
                    this.setState({ alertMessage: response.data.error.message });
                    return;
                }

                this.hideModal();
                this.props.modalProps.reloadFunction();
            });
        });
    }

    scrollDropdownIntoView = (e) => {
        const elementClicked = e.target.nodeName;
        const boundingElement = e.currentTarget;
        const modalBody = document.getElementsByClassName('modal-body')[0];

        if (elementClicked !== 'LABEL') {

            setTimeout(function () {

                // Scroll down if the bottom is hidden...
                if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

                    // ... and the top won't end up hidden by scrolling down
                    if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

                        // Scroll down till bottom of element reaches bottom of screen
                        boundingElement.scrollIntoView({ block: "end" });
                    }
                }
            }, 200);
        }
    }

    render() {
        const { alertMessage } = this.state;
        const { modalProps, location, countries, countryStates } = this.props;

        const countryOptions = [];
        if (!isEmpty(countries)) {
            countries.map((country) => {
                countryOptions.push({ label: country.name, value: country.code3 });
            })
        }

        return (
            <Fragment>
                <div className="modal" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    {this.state.isLoading && (
                        <LoadingModalContent
                            modalTitle={modalProps.type === 'create' ? 'Add Location' : 'Edit Location'}
                            submitButtonLabel={modalProps.type === 'create' ? 'Add Location' : 'Edit Location'}
                        />
                    )}
                    {!this.state.isLoading && (
                        <Formik
                            initialValues={{
                                id: (location && location.id) || null,
                                name: (location && location.name) || '',
                                description: (location && location.description) || '',
                                posAccount: (location && location.posAccount) || '',
                                address1: (location && location.address1) || '',
                                address2: (location && location.address2) || '',
                                city: (location && location.city) || '',
                                state: (location && location.state) || '',
                                zipcode: (location && location.zipcode) || '',
                                country: (location && location.country) || 'USA',
                            }}
                            validationSchema={this.formSchema()}
                            onSubmit={this.formSubmit}
                            render={({
                                handleChange,
                                handleSubmit,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isSubmitting,
                                setFieldValue }) => (

                                <form onSubmit={handleSubmit}>
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">
                                                    {modalProps.type === 'create' ? 'Add Location' : 'Edit Location'}
                                                </h5>
                                                <button onClick={this.hideModal} type="button" className="close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body cmv-form form-horizontal">
                                                {!isEmpty(alertMessage) && (
                                                    <div className="alert alert-inline alert-danger alert-dismissible">
                                                        <p className="mb-0">{alertMessage}</p>
                                                    </div>
                                                )}
                                                <fieldset>
                                                    <legend className="as-label">General</legend>
                                                    <div>
                                                        <TextInput
                                                            label="Name"
                                                            name="name"
                                                            value={values.name.toUpperCase()}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextInput
                                                            label="Description"
                                                            name="description"
                                                            value={values.description}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextInput
                                                            label="POS Account ID"
                                                            name="posAccount"
                                                            value={values.posAccount}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </fieldset>
                                                <fieldset>
                                                    <legend className="as-label">Address</legend>
                                                    <div>
                                                        <TextInput
                                                            label="Address Line 1"
                                                            name="address1"
                                                            value={values.address1}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextInput
                                                            label="Address Line 2"
                                                            name="address2"
                                                            value={values.address2}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextInput
                                                            label="City"
                                                            name="city"
                                                            value={values.city}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                    <div className="form-group" onClick={this.scrollDropdownIntoView}>
                                                        <FormSelect
                                                            title="State"
                                                            fieldName="state"
                                                            options={countryStates.filter(s => s.countryCode == values.country)
                                                                .map((s) => {
                                                                    return { label: s.name, value: s.abbreviation }
                                                                })}
                                                            setFieldValue={setFieldValue}
                                                            value={values.state}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                    <div>
                                                        <TextInput
                                                            label="Zip Code"
                                                            name="zipcode"
                                                            value={values.zipcode}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                    <div className="form-group" onClick={this.scrollDropdownIntoView}>
                                                        <FormSelect
                                                            title="Country"
                                                            fieldName="country"
                                                            options={countryOptions}
                                                            setFieldValue={setFieldValue}
                                                            value={values.country}
                                                            onBlur={handleBlur}
                                                            errors={errors}
                                                            touched={touched}
                                                        />
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="modal-footer">
                                                <button onClick={this.hideModal} className="btn" type="button">
                                                    Cancel
                                                </button>
                                                <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                                                    {modalProps.type === 'create' ? 'Add Location' : 'Edit Location'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        />
                    )}
                </div>
                <div className="modal-backdrop show" tabIndex="1" />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const countries = getDomain(state).countries,
        countryStates = getCreateAccount(state).countryStates,
        modalProps = getModalData(state).modalProps,
        locationWithProps = getAdmin(state).systemLocation,
        currentUser = getUser(state).user;

    return {
        countries,
        countryStates,
        modalProps,
        location: locationWithProps.location || [],
        currentUser
    };
};

const mapDispatchToProps = {
    getCountries: getSystemCountries,
    getCountryStates,
    hideModal,
    addLocation: addSystemLocation,
    updateLocation: updateSystemLocation,
    getLocation: getSystemLocation,
    clearLocation: clearSystemLocation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLocationModal);