import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import isEmpty from "../../../../utils/helpers";

const OperModuleMenu = ({ activeMenu, changeActiveMenu, permissions, dashboardMenuCount }) => {

	return (
		<li
			onClick={() => changeActiveMenu('3')}
			className={activeMenu === '3' ? 'sidebar-dropdown active' : 'sidebar-dropdown'}
		>
			<a>
				<i className="fa fa-globe" />
				<span>Operation</span>
			</a>
			<div className="sidebar-submenu" style={activeMenu === '3' ? { display: 'block' } : { display: 'none' }}>
				<ul>
					{!isEmpty(permissions) && permissions.includes('OPER_VIEW_MY_TASKS') && (
						<li className="sidebar-nav-link">
							<Link to={{pathname: '/home', hash: 'tasks'}}>
								My Tasks
								<span className="badge badge-pill badge-danger">{dashboardMenuCount.pendingTasks}</span>
							</Link>
						</li>
					)}

					{!isEmpty(permissions) && permissions.includes('OPER_VIEW_MY_ORDERS') && (
						<li className="sidebar-nav-link">
							<Link to={{pathname: '/home', hash: 'orders'}}>
								My Orders
							</Link>
						</li>
					)}
				</ul>
			</div>
		</li>
	);
};

OperModuleMenu.propTypes = {
	activeMenu: PropTypes.string.isRequired,
    changeActiveMenu: PropTypes.func.isRequired,
	permissions: PropTypes.array.isRequired,
	dashboardMenuCount: PropTypes.object,
};

export default OperModuleMenu;
