import React, { Component } from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import BootstrapTable from "react-bootstrap-table-next";
import { Formik } from "formik";

import { createLoadingSelector, getMappingProviders, getWorkforceProviders } from "src/selectors";
import {
  getMappingSystemProviderById,
  getMappingSystemCurrentId,
  getMappingSystemAllProviders,
  getMappingSystemAllProviderIDS,
  getWorkforceProviderByID,
  getWorkforceCurrentProviderID,
  getWorkforceAllProviders,
  getWorkforceAllProvidersIDS,
  clearWorkforceProviderById,
  clearWorkforceProvidersAll,
  clearWorkforceProviderIds,
  clearMappingProviderById,
  clearMappingProvidersAll,
  clearMappingProviderIds,
  patchMappingSystem,
  patchWorkforce,
} from "src/actions/workforce.actions";
import { showModal } from "src/actions/modal.actions";
import Loader from "src/components/Loader";
import TextInput from "src/components/UI/TextInput";
import { getErrorMessage } from "src/utils/helpers";

class MappingConfigurationSection extends Component {
  uiMappingSystems = {
    NONE: {
      label: "Google Maps",
      icon: "/images/GoogleMap.png",
      order: 0,
    },
    VETRO_V2: {
      label: "Vetro FiberMap",
      icon: "/images/VetroGIS.png",
      order: 1,
    },
    ARCGIS: {
      label: "ArcGIS by ESRI",
      icon: "/images/ArcGIS.png",
      order: 2,
    },
    MAPCOM: {
      label: "Mapcom VertiGIS",
      icon: "/images/VertiGIS.png",
      order: 3,
    },
  };

  state = {
    newProviderSet: false,
  };

  componentDidMount() {
    this.props.getCurrentProviderId().then((resp) => {
      this.props.getProviderConfigurationById(resp.data.id);
    });

    this.props.getAllProviderIds();
  }

  componentWillUnmount() {
    this.props.clearAllProviderIds();
    this.props.clearAllProviders();
    this.props.clearProviderById();
  }

  handleSubmit = (values, actions) => {
    actions.setSubmitting(false);
  };

  onCurrentProviderChangeHandler = (name, value, formProps) => {
    formProps.setFieldValue("providerId", value);
    if (value) {
      this.props.getProviderConfigurationById(value).then((resp) => {
        formProps.setFieldValue("providerConfiguration", resp.data);
      });
    } else {
      formProps.setFieldValue("providerConfiguration", null);
    }

    this.setState({ newProviderSet: true });
  };

  saveProviderConfiguration = ({ setSubmitting, values }) => {
    setSubmitting(true);

    this.props.saveProviderConfiguration(values.providerId, values.providerConfiguration)
      .then((response) => {
        if (response.success === false) {
          toastr.error(getErrorMessage(response).message, { position: "top-center" });
        } else {
          const message = `${this.props.title} configuration is updated successfully`;

          toastr.success(message, { timeOut: 3000, position: "top-center" });

          this.props.getProviderConfigurationById(values.providerId)
            .then((resp) => setSubmitting(false))
            .catch((error) => {
              console.error(error);
              setSubmitting(false);
            });
        }
      })
      .catch((e) => {
        toastr.error("Something went wrong", { timeOut: 5000, position: "top-center" });
        setSubmitting(false);
      });
  };

  oldValueFormatter = (cell, row) => {
    return <div style={{ maxWidth: "200px", wordBreak: "break-all" }}>{row.oldVal}</div>;
  };

  newValueFormatter = (cell, row) => {
    return <div style={{ maxWidth: "200px", wordBreak: "break-all" }}>{row.newVal}</div>;
  };

  onSaveButtonClick = (saveFunction, updatedProps, formProps) => {
    const columns = [
      {
        dataField: "key",
        text: "Name",
      },
      {
        dataField: "oldVal",
        text: "Old Value",
        formatter: this.oldValueFormatter,
      },
      {
        dataField: "newVal",
        text: "New Value",
        formatter: this.newValueFormatter,
      },
    ];

    const newProvider = this.state.newProviderSet
      ? this.uiMappingSystems[formProps.values.providerId]?.label
      : null;

    this.props.showModal("GENERIC_MODAL", {
      title: "Save Configuration",
      text: "Are you sure you want to make these changes?",
      size: "lg",
      cancelText: "Cancel",
      okText: "Save",
      onOk: () => saveFunction(formProps),
      component: () => (
        <>
          {newProvider &&
            `${this.props.title} provider has been changed to "${newProvider}"`}

          {updatedProps && updatedProps.length > 0 && (
            <BootstrapTable
              classes="table table-striped cmv-table"
              keyField="key"
              data={updatedProps}
              columns={columns}
            />
          )}
        </>
      ),
    });
  };

  render() {
    const {
      isLoading,
      currentProviderId,
      providers,
      allProviderIds,
    } = this.props;

    const providerIdOptions = allProviderIds
      ? allProviderIds
          .map((mid) => ({ label: mid, value: mid, ...(this.uiMappingSystems[mid] || {}) }))
          .sort((a, b) => (a.order || 0) - (b.order || 0))
      : [];

    return (
      <div className="container mb-4">
        <Formik
          initialValues={{
            providerId: currentProviderId,
            providerConfiguration:
              currentProviderId && providers && providers[currentProviderId],
          }}
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
        >
          {(formProps) => {
            const { handleChange, handleSubmit, handleBlur, values, errors, touched, isSubmitting, setFieldValue } =
              formProps;

            const originalProviderConfiguration =
              providers && providers[values && values.providerId];
            const mappingUpdatedProps = [];

            originalProviderConfiguration &&
              values.providerConfiguration &&
              originalProviderConfiguration.properties.forEach((origProp) => {
                const currProp = values.providerConfiguration.properties.find(
                  (currProp) => currProp.key === origProp.key
                );
                if (currProp && currProp.value !== origProp.value) {
                  mappingUpdatedProps.push({ key: currProp.key, oldVal: origProp.value, newVal: currProp.value });
                }
              });

            return (
              <>
                <form onSubmit={handleSubmit}>
                  {isLoading && <Loader />}

                  <section className="form-vertical container">
                    <h3>{this.props.title}</h3>
                    <div className="d-flex flex-row justify-content-around align-items-center">
                      {providerIdOptions.map((o) => (
                        <div key={`mapping_provider_${o.value}`}>
                          <label className="text-center">
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ width: "180px", height: "120px", padding: "20px", boxSizing: "border-box" }}
                            >
                              <img
                                src={o.icon}
                                alt={o.label}
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                }}
                              />
                            </div>
                            <p>{o.label}</p>
                            <div className="d-inline-block ml-4 form-check checkbox-slider checkbox-slider--b-flat">
                              <label>
                                <input
                                  type="checkbox"
                                  name="providerId"
                                  checked={values.providerId === o.value}
                                  value={o.value}
                                  onChange={(event) =>
                                    event.target.checked &&
                                    this.onCurrentProviderChangeHandler(
                                      event.target.name,
                                      event.target.value,
                                      {
                                        values,
                                        setFieldValue,
                                      }
                                    )
                                  }
                                />
                                <span>&nbsp;</span>
                              </label>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                    {!isLoading && values.providerConfiguration && (
                      <div className="card-vertical card-plane">
                        <div className="card-body">
                          <div className="plan-header plan-header-centered">
                            <span className="plan-title"></span>
                          </div>
                          <div className="plan-body">
                            <div className="form-group service-wrapper">
                              {values &&
                                values.providerConfiguration &&
                                values.providerConfiguration.properties.map((prop, idx) => {
                                  const fieldName = `providerConfiguration.properties[${idx}].value`;

                                  return (
                                    <TextInput
                                      key={`config_${prop.key}`}
                                      label={prop.description || prop.key}
                                      type="text"
                                      name={fieldName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={prop.value || ""}
                                      disabled={isSubmitting}
                                      autocomplete={false}
                                      helperText={prop.key}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="plan-footer mt-2" style={{ textAlign: "right" }}>
                      <button
                        onClick={() =>
                          this.onSaveButtonClick(this.saveProviderConfiguration, mappingUpdatedProps, formProps)
                        }
                        disabled={
                          isLoading ||
                          isSubmitting ||
                          (mappingUpdatedProps.length < 1 && !this.state.newProviderSet)
                        }
                        type="button"
                        className="btn btn-primary"
                      >
                        {isSubmitting && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                            style={{ marginRight: "5px" }}
                          />
                        )}
                        Save
                      </button>
                    </div>
                  </section>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    );
  }
}

class MappingConfigurations extends Component {
  componentDidMount() {
    document.title = "Mapping Configuration - camvio.cloud";
    this.props.changeNavBarType("default", "Mapping Configuration");
  }

  componentWillUnmount() {
  }

  render() {
    const {
      isLoadingMappingProvider,
      isLoadingWorkforceProvider,
      currentMappingProviderId,
      currentWorkforceProviderId,
      mappingProviders,
      mappingProviderIds,
      workforceProviders,
      workforceProviderIds,
    } = this.props;

    return (
      <div className="cmv-container cmv-container-dashboard cmv-container-client-selected">
        <MappingConfigurationSection
          title="Mapping"
          getCurrentProviderId={this.props.getMappingSystemCurrentId}
          getProviderConfigurationById={this.props.getMappingSystemProviderById}
          getAllProviderIds={this.props.getMappingSystemAllProviderIDS}
          clearProviderById={this.props.clearMappingProviderById}
          clearAllProviders={this.props.clearMappingProvidersAll}
          clearAllProviderIds={this.props.clearMappingProviderIds}
          currentProviderId={currentMappingProviderId}
          allProviderIds={mappingProviderIds}
          providers={mappingProviders}
          saveProviderConfiguration={this.props.patchMappingSystem}
          isLoading={isLoadingMappingProvider}
          showModal={this.props.showModal}
          />
        <MappingConfigurationSection
          title="Workforce mapping"
          getCurrentProviderId={this.props.getWorkforceCurrentProviderID}
          getProviderConfigurationById={this.props.getWorkforceProviderByID}
          getAllProviderIds={this.props.getWorkforceAllProvidersIDS}
          clearProviderById={this.props.clearWorkforceProviderById}
          clearAllProviders={this.props.clearWorkforceProvidersAll}
          clearAllProviderIds={this.props.clearWorkforceProviderIds}
          currentProviderId={currentWorkforceProviderId}
          allProviderIds={workforceProviderIds}
          providers={workforceProviders}
          saveProviderConfiguration={this.props.patchWorkforce}
          isLoading={isLoadingWorkforceProvider}
          showModal={this.props.showModal}
          />
      </div>
    );
  }
}

const selectCurrentMappingProviderIdLoading = createLoadingSelector(["MAPPING_SYSTEM_CURRENT_ID"]);
const selectLoadingMappingProviderConfiguration = createLoadingSelector(["MAPPING_SYSTEM_FIND_PROVIDER_BY_ID"]);
const selectLoadingMappingProviderIds = createLoadingSelector(["MAPPING_SYSTEM_ALL_PROVIDERS_IDS"]);
const selectCurrentWorkforceProviderIdLoading = createLoadingSelector(["WORKFORCE_FIND_CURRENT_PROVIDER_ID"]);
const selectLoadingWorkforceProviderConfiguration = createLoadingSelector(["WORKFORCE_FIND_PROVIDER_BY_ID"]);
const selectLoadingWorkforceProviderIds = createLoadingSelector(["WORKFORCE_ALL_PROVIDERS_IDS"]);

const mapStateToProps = (state) => {
  const currentMappingProviderId = getMappingProviders(state).currentMappingProviderId,
    mappingProviders = getMappingProviders(state).mappingProviders,
    mappingProviderIds = getMappingProviders(state).mappingProviderIds,
    isLoadingCurrentMappingProviderId = selectCurrentMappingProviderIdLoading(state),
    isLoadingMappingSystemConfiguration = selectLoadingMappingProviderConfiguration(state),
    isLoadingMappingProviderIds = selectLoadingMappingProviderIds(state);

  const currentWorkforceProviderId = getWorkforceProviders(state).currentWorkforceProviderId,
    workforceProviders = getWorkforceProviders(state).workforceProviders,
    workforceProviderIds = getWorkforceProviders(state).workforceProviderIds,
    isLoadingCurrentWorkforceProviderId = selectCurrentWorkforceProviderIdLoading(state),
    isLoadingWorkforceConfiguration = selectLoadingWorkforceProviderConfiguration(state),
    isLoadingWorkforceProviderIds = selectLoadingWorkforceProviderIds(state);

  const isLoadingMappingProvider =
    isLoadingCurrentMappingProviderId ||
    isLoadingMappingSystemConfiguration ||
    isLoadingMappingProviderIds;
  const isLoadingWorkforceProvider =
    isLoadingCurrentWorkforceProviderId ||
    isLoadingWorkforceConfiguration || 
    isLoadingWorkforceProviderIds;

  return {
    currentMappingProviderId,
    mappingProviderIds,
    mappingProviders,
    currentWorkforceProviderId,
    workforceProviderIds,
    workforceProviders,
    isLoadingMappingProvider,
    isLoadingWorkforceProvider,
  };
};

const mapDispatchToProps = {
  showModal,
  getMappingSystemProviderById,
  getMappingSystemCurrentId,
  getMappingSystemAllProviders,
  getMappingSystemAllProviderIDS,
  getWorkforceProviderByID,
  getWorkforceCurrentProviderID,
  getWorkforceAllProviders,
  getWorkforceAllProvidersIDS,
  clearWorkforceProviderById,
  clearWorkforceProvidersAll,
  clearWorkforceProviderIds,
  clearMappingProviderById,
  clearMappingProvidersAll,
  clearMappingProviderIds,
  patchMappingSystem,
  patchWorkforce,
};

export default connect(mapStateToProps, mapDispatchToProps)(MappingConfigurations);
