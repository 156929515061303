import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { createLoadingSelector } from '../../../selectors';
import { statusColor } from '../../../utils/ui';
import { connect } from 'react-redux';
import { sendAuthCode, verifyAuthCode } from '../../../actions/createAccount.actions';
import { hideModal, showAccessModal } from '../../../actions/modal.actions';
import { getModalData } from '../../../selectors/index';
import { getCpniById, sendAccessNote } from '../../../actions/accountDetailsActions/accountDetails.actions';
import { transformNote, initialValues } from './utils';
import { generateAddressNew } from '../../../utils/generateAddresses';
import { formSelectStyles, groupStyles } from '../../../utils/SelectStyles';
import Loader from '../../../components/Loader';
import Select from 'react-select';

class accountAccessModal extends Component {

	state = {
		accountInfo: {},
		allAccounts: [],
		accessNote: '',
		accessForm: {
			cpniCategories: { value: '', label: '' },
			accessRequested: { value: '', label: '' },
			...initialValues
		},
		number: '',
		showVerificationMobileNumber: false,
		isAuthCodeValid: null,
		authCode: '',
		isLoadingData: true,
		//history location
		canRedirectToAccountDetails: false
	};

	componentDidMount() {
		if (this.props.history.location.pathname.includes('/home') || 
				this.props.history.location.pathname.includes('/notification-queue')) {
			//if history location pathname is account-details - apply diffrent settings
			this.setState({
				canRedirectToAccountDetails: true
			});
		}
		getCpniById(this.props.accountId)
			.then((response) => {
				// collect all accounts from primaryContact and autorizedContacts in array.
				let allAccounts = [];
				allAccounts.push(response.primaryContact);
				if (response.authorizedContacts) {
					allAccounts.push(...response.authorizedContacts);
				}

				let updatedAccessForm = { ...this.state.accessForm };

				updatedAccessForm['accessRequested'] = {
					value: response.primaryContact.id,
					label: (response.primaryContact.firstName || "") + ' ' + (response.primaryContact.lastName || "")
				};

				let cpniCategories = response.cpniOpenNoteCategories.find((cpni) => cpni.isDefault === true);
				updatedAccessForm['cpniCategories'] = {
					value: cpniCategories.id,
					label: cpniCategories.description
				};

				this.setState({
					isLoadingData: false,
					accountInfo: response,
					allAccounts: allAccounts,
					accessForm: updatedAccessForm
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	onSelectChange = (values, selectType) => {
		let updatedAccessForm = { ...this.state.accessForm };

		updatedAccessForm[selectType] = {
			value: values.value,
			label: values.label
		};

		if (selectType === 'accessRequested') {
			//if select input is accessRequested - initalValue is equal to empty string = ''
			updatedAccessForm = { ...updatedAccessForm, ...initialValues };

			this.setState({
				accessForm: updatedAccessForm,
				number: '',
				showVerificationMobileNumber: false,
				isAuthCodeValid: null,
				authCode: ''
			});
		} else {
			this.setState({
				accessForm: updatedAccessForm,
				number: ''
			});
		}
	};

	onCheckboxChange = (e) => {
		let updatedAccessForm = { ...this.state.accessForm };
		updatedAccessForm[e.target.name] = e.target.checked;

		this.setState({
			accessForm: updatedAccessForm
		});
	};

	getAccessRequestedOptions = (accountInfo) => {
		let authorizedContacts = [];
		let primaryContacts = [];

		if (accountInfo.primaryContact) {
			primaryContacts.push({
				value: accountInfo.primaryContact.id,
				label: (accountInfo.primaryContact.firstName || "") + ' ' + (accountInfo.primaryContact.lastName || "")
			});
		}

		if (accountInfo.authorizedContacts) {
			authorizedContacts = accountInfo.authorizedContacts.map((el) => {
				return {
					value: el.id,
					label: (el.firstName || "") + ' ' + (el.lastName || "")
				};
			});
		}

		const groupedOptions = [
			{
				label: 'Account Holder',
				options: primaryContacts
			},
			{
				label: 'Authorized Users',
				options: authorizedContacts
			}
		];

		return groupedOptions;
	};

	// AUTH CODE FUNCTIONALITY <== START ==>

	handleNumberChange = (e) => {
		let updatedAccessForm = { ...this.state.accessForm };
		updatedAccessForm['authCode'] = false;

		this.setState({
			accessForm: updatedAccessForm,
			[e.target.name]: e.target.value,
			showVerificationMobileNumber: false,
			isAuthCodeValid: null,
			authCode: ''
		});
	};

	onSubmitAuthCode = () => {
		let data = {
			phoneNumber: this.state.number,
			requester: this.state.accessForm.accessRequested.label // name
		};
		// if there is no error - show field for verification
		this.props.sendAuthCode(data).then((response) => {
			// To Do - need to catch exeption - error
			this.setState({
				showVerificationMobileNumber: true,
				isAuthCodeValid: null,
				authCode: ''
			});
		});
	};

	onChangeAuthCodeHandler = (e) => {
		this.setState({
			authCode: e.target.value
		});
	};

	onSubmitVerifyAuthCode = () => {
		let data = {
			authcode: this.state.authCode,
			phoneNumber: this.state.number,
			requester: this.state.accessForm.accessRequested.label // name
		};
		this.props.verifyAuthCode(data).then((response) => {
			if (response.data.code === 200) {
				let updatedAccessForm = { ...this.state.accessForm };
				updatedAccessForm['authCode'] = true;

				this.setState({
					accessForm: updatedAccessForm,
					isAuthCodeValid: true
				});
			}
			if (response.data.error) {
				let updatedAccessForm = { ...this.state.accessForm };
				updatedAccessForm['authCode'] = false;

				this.setState({
					accessForm: updatedAccessForm,
					isAuthCodeValid: false
				});
			}
		});
	};

	// AUTH CODE FUNCTIONALITY <== END ==>

	closeModal = () => {
		this.props.hideModal();
	};

	goToAccount = (access) => {
		const { accountId, history, hideModal } = this.props;
		let objectForSend;
		let noteText = '';

		Object.keys(this.state.accessForm).forEach((key, inx) => {
			if (this.state.accessForm[key] === true) {
				noteText += transformNote(key) + ', ';
			}
		});
		let removeComma = noteText.substr(0, noteText.length - 2);

		if (access === 'Access Granted') {
			objectForSend = {
				noteCategoryId: this.state.accessForm.cpniCategories.value,
				note: `Access Granted, user: ${this.state.accessForm.accessRequested.label}, validated: ${removeComma}`
			};

			sendAccessNote(this.props.accountId, objectForSend);
			this.props.showAccessModal(true);
			hideModal();

			//if current location is home - redirect to account-details
			if (this.state.canRedirectToAccountDetails) {
				history.push(`/account-details/${accountId}`);
			}
		} else {
			objectForSend = {
				noteCategoryId: this.state.accessForm.cpniCategories.value,
				note: `Access Denied`
			};

			sendAccessNote(this.props.accountId, objectForSend);
			this.props.showAccessModal(true);

			// if history location is equal to /account-details - redirect to home
			// if(this.state.canRedirectToAccountDetails){
			// 	history.push(`/home`);
			// }

			history.push(`/home`);
			hideModal();
		}
	};

	scrollDropdownIntoView = (e) => {
		const elementClicked = e.target.nodeName;
		const boundingElement = e.currentTarget;
		const modalBody = document.getElementsByClassName('modal-body')[0];

		if (elementClicked !== 'LABEL') {

			setTimeout(function () {

				// Scroll down if the bottom is hidden...
				if (boundingElement.getBoundingClientRect().bottom > modalBody.getBoundingClientRect().bottom) {

					// ... and the top won't end up hidden by scrolling down
					if (boundingElement.getBoundingClientRect().height < modalBody.getBoundingClientRect().height) {

						// Scroll down till bottom of element reaches bottom of screen
						boundingElement.scrollIntoView({block: "end"});
					}
				}
			}, 200);
		}
	}

	render() {
		const {
			accountInfo,
			accessForm,
			allAccounts,
			isLoadingData,
			isAuthCodeValid,
			showVerificationMobileNumber,
			authCode
		} = this.state;
		const { authCodeLoader, authCodeVerifyLoader } = this.props;

		let cpniOpenNoteCategories = [];
		let contactsOptions = this.getAccessRequestedOptions(accountInfo);

		let selectedAccountInfo = {};

		if (accountInfo) {
			selectedAccountInfo = allAccounts.find((x) => x.id === this.state.accessForm.accessRequested.value);
		}

		if (accountInfo.cpniOpenNoteCategories) {
			cpniOpenNoteCategories = accountInfo.cpniOpenNoteCategories.map((cpni) => {
				return {
					value: cpni.id,
					label: cpni.description
				};
			});
		}

		return (
			<Fragment>
				<div className="modal show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Account Access Validation</h5>
								<button onClick={this.closeModal} type="button" className="close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="form-group">
									<div onClick={this.scrollDropdownIntoView}>
										<Select
											options={cpniOpenNoteCategories}
											styles={formSelectStyles}
											name="cpniCategories"
											onChange={(values) => this.onSelectChange(values, 'cpniCategories')}
											value={accessForm.cpniCategories}
											isLoading={isLoadingData}
										/>
									</div>
								</div>
								<hr />
								<div className="form-row">
									<div className="form-group col-12 mb-0">
										<div onClick={this.scrollDropdownIntoView}>
											<label>Access Requested By</label>
											<Select
												name="accessRequested"
												value={accessForm.accessRequested}
												onChange={(values) => this.onSelectChange(values, 'accessRequested')}
												options={contactsOptions}
												styles={groupStyles}
												isLoading={isLoadingData}
											/>
										</div>
									</div>
								</div>
								<hr />
								{isLoadingData ? (
									<Loader />
								) : (
									<div className="cpni-access-rules">
										{accountInfo.number && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="accountNumber"
														checked={accessForm.accountNumber}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Account #</div>
													<div className="col-lg-8 checklist-a">
														{accountInfo.number}
														{/* <div className="cmv-client-status-success">
															<span className={`status-text text-${this.colorOfStatus(accountInfo.tierLevel)}`}>{accountInfo.status}</span>
														</div> */}
														<div
															className={`cmv-client-status cmv-client-status-${statusColor(
																accountInfo.tierLevel
															)}`}
														>
															<span className="status-text">{accountInfo.status}</span>
															<div
																className={`status-bar status-bar-${accountInfo.tierLevel *
																	20}`}
															/>
														</div>
													</div>
												</span>
											</div>
										)}
										{selectedAccountInfo && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="name"
														checked={accessForm.name}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Name</div>
													<div className="col-lg-8 checklist-a">
														{selectedAccountInfo.firstName || ""} {selectedAccountInfo.lastName || ""}
														<div className="cmv-client-status-success" />
													</div>
												</span>
											</div>
										)}
										{selectedAccountInfo &&
										selectedAccountInfo.company && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="company"
														checked={accessForm.company}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Company</div>
													<div className="col-lg-8 checklist-a">
														{selectedAccountInfo.company}
														<div className="cmv-client-status-success">
															{/* <span className="status-text">Active</span> */}
														</div>
													</div>
												</span>
											</div>
										)}

										{accountInfo.securityPhrase && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="securityPhrase"
														checked={accessForm.securityPhrase}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Security Phrase</div>
													<div className="col-lg-8 checklist-a">
														{accountInfo.securityPhrase}
													</div>
												</span>
											</div>
										)}

{/*										{selectedAccountInfo && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="balance"
														checked={accessForm.balance}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Balance</div>
													<div className="col-lg-8 checklist-a">
														{accountInfo.accountBalance ? (
															'$' +
															accountInfo.accountBalance
																.toFixed(2)
																.replace(/\d(?=(\d{3})+\.)/g, '$&,')
														) : (
															'0.00'
														)}
													</div>
												</span>
											</div>
										)}*/}

										{selectedAccountInfo &&
										selectedAccountInfo.contactAddresses &&
										selectedAccountInfo.contactAddresses.find((x) => x.type === 'BILLING') && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="billingAddress"
														checked={accessForm.billingAddress}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Billing Address</div>
													<div className="col-lg-8 checklist-a">
														{(selectedAccountInfo &&
															generateAddressNew(
																selectedAccountInfo.contactAddresses.find(
																	(x) => x.type === 'BILLING'
																)
															)) ||
															''}
													</div>
												</span>
											</div>
										)}
										{selectedAccountInfo &&
										selectedAccountInfo.contactAddresses &&
										selectedAccountInfo.contactAddresses.find((x) => x.type === 'MAILING') && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="mailingAddress"
														checked={accessForm.mailingAddress}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Mailing Address</div>
													<div className="col-lg-8 checklist-a">
														{(selectedAccountInfo &&
															generateAddressNew(
																selectedAccountInfo.contactAddresses.find(
																	(x) => x.type === 'MAILING'
																)
															)) ||
															''}
													</div>
												</span>
											</div>
										)}

										{selectedAccountInfo &&
										selectedAccountInfo.contactEmails && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="emails"
														checked={accessForm.emails}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Emails</div>
													{/* <div className="col-lg-8 checklist-a">***-***-***-1210</div>  */}
													<div className="col-lg-8 checklist-a">
														<select className="form-control form-control-sm">
															{selectedAccountInfo.contactEmails.map(
																(currentEmail, inx) => {
																	return (
																		<option key={inx}>{currentEmail.email}</option>
																	);
																}
															)}
														</select>
													</div>
												</span>
											</div>
										)}

										{selectedAccountInfo &&
										selectedAccountInfo.dateOfBirth && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="dateOfBirth"
														checked={accessForm.dateOfBirth}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Date of Birth</div>
													<div className="col-lg-8 checklist-a">
														{selectedAccountInfo.dateOfBirth}
													</div>
												</span>
											</div>
										)}

										{selectedAccountInfo &&
										selectedAccountInfo.ssn && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="ssn"
														checked={accessForm.ssn}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">SSN</div>
													<div className="col-lg-8 checklist-a">
														{selectedAccountInfo && selectedAccountInfo.ssn}
													</div>
												</span>
											</div>
										)}
										{selectedAccountInfo &&
										selectedAccountInfo.taxId && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="taxId"
														checked={accessForm.taxId}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Tax Id</div>
													<div className="col-lg-8 checklist-a">
														{selectedAccountInfo.taxId}
													</div>
												</span>
											</div>
										)}

										{/* {accountInfo.status && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="status"
														checked={accessForm.status}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Status</div>
													<div className="col-lg-8 checklist-a">{accountInfo.status}</div>
												</span>
											</div>
										)} */}

										{/* <div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
										<label>
											<input
												type="checkbox"
												onChange={this.onCheckboxChange}
												name="lastPayment"
												checked={accessForm.lastPayment}
											/>
											<span>&nbsp;</span>
										</label>
										<span className="row checklist-item">
											<div className="col-lg-4 checklist-q">Last Payment</div>
											<div className="col-lg-8 checklist-a">4/2/2018</div>
										</span>
									</div>
									<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
										<label>
											<input
												type="checkbox"
												onChange={this.onCheckboxChange}
												name="email"
												checked={accessForm.email}
											/>
											<span>&nbsp;</span>
										</label>
										<span className="row checklist-item">
											<div className="col-lg-4 checklist-q">Email</div>
											<div className="col-lg-8 checklist-a">jwayne24@mailserver.com</div>
										</span>
									</div> */}
										{/* <div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
										<label>
											<input
												type="checkbox"
												onChange={this.onCheckboxChange}
												name="username"
												checked={accessForm.userName}
											/>
											<span>&nbsp;</span>
										</label>
										<span className="row checklist-item">
											<div className="col-lg-4 checklist-q">Username</div>
											<div className="col-lg-8 checklist-a">jwayne24</div>
										</span>
									</div> */}

										{/* {selectedAccountInfo &&
									selectedAccountInfo.contactAddresses.find((x) => x.type === 'SERVICE') && (
										<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
											<label>
												<input
													type="checkbox"
													onChange={this.onCheckboxChange}
													name="serviceAddress"
													checked={accessForm.serviceAddress}
												/>
												<span>&nbsp;</span>
											</label>
											<span className="row checklist-item">
												<div className="col-lg-4 checklist-q">Service Address</div>
												<div className="col-lg-8 checklist-a">
													{(selectedAccountInfo &&
														generateAddressNew(
															selectedAccountInfo.contactAddresses.find(
																(x) => x.type === 'SERVICE'
															)
														)) ||
														''}
												</div>
											</span>
										</div>
									)} */}

										{selectedAccountInfo &&
										selectedAccountInfo.driverLicense && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														onChange={this.onCheckboxChange}
														name="driverLicense"
														checked={accessForm.driverLicense}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">Driver License</div>
													<div className="col-lg-8 checklist-a">
														{selectedAccountInfo.driverLicense}
													</div>
												</span>
											</div>
										)}
										{selectedAccountInfo &&
										selectedAccountInfo.contactNumbers && (
											<div className="form-check checkbox-slider checkbox-slider--b-flat checklist">
												<label>
													<input
														type="checkbox"
														checked={accessForm.authCode}
														name="authCode"
														readOnly={true}
													/>
													<span>&nbsp;</span>
												</label>
												<span className="row checklist-item">
													<div className="col-lg-4 checklist-q">AuthCode</div>
													<div className="col-lg-8 checklist-a">
														<div className="row">
															<div className="col-12">
																<div className="input-group input-group-sm">
																	<select
																		className="form-control customer-sms-line"
																		onChange={this.handleNumberChange}
																		value={this.state.number}
																		name="number"
																	>
																		<option value="">Select phone number</option>
																		{selectedAccountInfo.contactNumbers
																			.filter((x) => x.verified === true)
																			.map((contact) => (
																				<option key={contact.id}>
																					{contact.number}
																				</option>
																			))}
																	</select>

																	<div
																		className={
																			showVerificationMobileNumber &&
																			!authCodeLoader &&
																			!authCodeVerifyLoader &&
																			isAuthCodeValid === null ? (
																				'input-group-append input-authcode-input'
																			) : (
																				'input-group-append input-authcode-input d-none'
																			)
																		}
																	>
																		<div className="input-group input-group-sm">
																			<div className="input-group-prepend">
																				<span className="input-group-text">
																					<i className="fas fa-key" />
																				</span>
																			</div>
																			<input
																				type="text"
																				className="form-control w-100px authcode-key"
																				placeholder="AuthCode"
																				name="authCode"
																				onChange={this.onChangeAuthCodeHandler}
																				value={authCode}
																			/>
																		</div>
																	</div>

																	<div
																		className={
																			showVerificationMobileNumber &&
																			!authCodeLoader &&
																			!authCodeVerifyLoader &&
																			isAuthCodeValid === null ? (
																				'input-group-append input-authcode-validate'
																			) : (
																				'input-group-append input-authcode-validate d-none'
																			)
																		}
																	>
																		<button
																			className="btn btn-outline-secondary btn-authcode-validate"
																			type="button"
																			onClick={this.onSubmitVerifyAuthCode}
																		>
																			<i className="fas fa-check" /> Verify
																		</button>
																	</div>

																	<div className="input-group-append input-authcode-status">
																		{authCodeLoader && (
																			<span className="input-group-text">
																				<i className="fas fa-circle-notch fa-spin ml-1 mr-1" />&nbsp;Requesting
																			</span>
																		)}
																		{authCodeVerifyLoader && (
																			<span className="input-group-text">
																				<i className="fas fa-circle-notch fa-spin ml-1 mr-1" />&nbsp;Verifying...
																			</span>
																		)}
																		{isAuthCodeValid === true &&
																		!authCodeLoader && (
																			<span className="input-group-text text-success">
																				<i className="fas fa-check" />&nbsp;Valid
																			</span>
																		)}

																		{isAuthCodeValid === false &&
																		!authCodeLoader && (
																			<span className="input-group-text text-danger">
																				<i className="fas fa-exclamation-triangle" />&nbsp;Invalid
																			</span>
																		)}
																	</div>

																	<div
																		className={
																			!showVerificationMobileNumber &&
																			!authCodeLoader &&
																			!authCodeVerifyLoader ? (
																				'input-group-append input-authcode-send'
																			) : (
																				'input-group-append input-authcode-send d-none'
																			)
																		}
																	>
																		<button
																			className="btn btn-outline-secondary btn-authcode-send"
																			type="button"
																			disabled={this.state.number === ''}
																			onClick={this.onSubmitAuthCode}
																		>
																			<i className="fas fa-paper-plane" /> Send
																		</button>
																	</div>

																	<div
																		className={
																			showVerificationMobileNumber &&
																			!authCodeLoader &&
																			!authCodeVerifyLoader &&
																			isAuthCodeValid !== true ? (
																				'input-group-append input-authcode-send'
																			) : (
																				'input-group-append input-authcode-send d-none'
																			)
																		}
																	>
																		<button
																			className="btn btn-outline-secondary btn-authcode-send"
																			type="button"
																			disabled={this.state.number === ''}
																			onClick={this.onSubmitAuthCode}
																		>
																			<i className="fas fa-paper-plane" /> Resend
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</span>
											</div>
										)}
									</div>
								)}
							</div>
							<div className="modal-footer">
								<button onClick={() => this.goToAccount('Access Denied')} className="btn">
									Insufficient validation
								</button>
								<button onClick={() => this.goToAccount('Access Granted')} className="btn btn-primary">
									Access account
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop show" tabIndex="1" />
			</Fragment>
		);
	}
}

accountAccessModal.propTypes = {
	accountId: PropTypes.number.isRequired,
	authCodeLoader: PropTypes.bool,
	authCodeVerifyLoader: PropTypes.bool,
	hideModal: PropTypes.func,
	showAccessModal: PropTypes.func,
	sendAuthCode: PropTypes.func,
	verifyAuthCode: PropTypes.func
};

const setAuthCodeLoader = createLoadingSelector([ 'SEND_AUTH_CODE' ]);
const setAuthCodeVerify = createLoadingSelector([ 'VERIFY_AUTH_CODE' ]);

const mapStateToProps = (state) => {
	const modalProps = getModalData(state).modalProps
	
	const accountId = modalProps?.accountId,
		authCodeLoader = setAuthCodeLoader(state),
		authCodeVerifyLoader = setAuthCodeVerify(state);
	return {
		accountId,
		authCodeLoader,
		authCodeVerifyLoader
	};
};

const mapDispatchToProps = {
	hideModal,
	showAccessModal,
	sendAuthCode,
	verifyAuthCode
};

export default connect(mapStateToProps, mapDispatchToProps)(accountAccessModal);
