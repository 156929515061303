import React, { Component } from "react";
import PropTypes from "prop-types";
import Tile from "../../../components/UI/Tile/Tile";

export class Tiles extends Component {
  render() {
    const { activeTile, handleTileChange, dashboardMenuCount, dashboardMenuCountLoading, enabledTiles } = this.props;

    const urlTile = window.location.hash.split("#").slice(-1)[0];

    if (
      urlTile !== "" &&
      urlTile !== activeTile &&
      ["tasks", "customers", "orders", "tickets", "appointments", "myday"].includes(urlTile)
    ) {
      handleTileChange(urlTile);
    }

    return (
      <div className="cmv-container-dashboard-stats" id="home-tiles" role="tablist">
        {enabledTiles.includes("myday") && (
          <Tile
            tile="myday"
            activeTile={activeTile}
            handleTileChange={handleTileChange}
            cardTitle="My Day"
            cardIcon="fas fa-calendar-day"
            loading={dashboardMenuCountLoading}
          />
        )}

        {enabledTiles.includes("customers") && (
          <Tile
            tile="customers"
            activeTile={activeTile}
            handleTileChange={handleTileChange}
            cardTitle={`${dashboardMenuCount.customersServed || 0} Customers`}
            cardText="served in 24 hrs"
            cardIcon="fas fa-2x fa-user"
            loading={dashboardMenuCountLoading}
          />
        )}

        {enabledTiles.includes("tasks") && (
          <Tile
            tile="tasks"
            activeTile={activeTile}
            handleTileChange={handleTileChange}
            cardTitle={`${dashboardMenuCount.pendingTasks || 0} Tasks`}
            cardText="still incomplete"
            cardIcon="fas fa-2x fa-list-ul"
            loading={dashboardMenuCountLoading}
          />
        )}

        {enabledTiles.includes("orders") && (
          <Tile
            tile="orders"
            activeTile={activeTile}
            handleTileChange={handleTileChange}
            cardTitle={`Orders`}
            cardIcon="fas fa-2x fa-shopping-cart"
            loading={dashboardMenuCountLoading}
          />
        )}

        {enabledTiles.includes("tickets") && (
          <Tile
            tile="tickets"
            activeTile={activeTile}
            handleTileChange={handleTileChange}
            cardTitle={`Tickets`}
            cardIcon="fas fa-2x fa-check-square"
            loading={dashboardMenuCountLoading}
          />
        )}

        {enabledTiles.includes("appointments") && (
          <Tile
            tile="appointments"
            activeTile={activeTile}
            handleTileChange={handleTileChange}
            // cardTitle={`${dashboardMenuCount.pendingAppointments || 0} Appointments`}
            cardTitle={`Appointments`}
            cardText="upcoming"
            cardIcon="fas fa-2x fa-clock"
            loading={dashboardMenuCountLoading}
          />
        )}
      </div>
    );
  }
}

Tiles.propTypes = {
  activeTile: PropTypes.string,
  handleTileChange: PropTypes.func,
  dashboardMenuCount: PropTypes.object,
  enabledTiles: PropTypes.array,
};

export default Tiles;
