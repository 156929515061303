import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { getAccountDetails, createLoadingSelector } from '../../../../../../selectors';
import { showModal } from '../../../../../../actions/modal.actions';
import {
	getAccountNotes,
	getNoteCategories,
	emptyAccountNotes
} from '../../../../../../actions/accountDetailsActions/accountNotes.actions';
import { isEmpty } from '../../../../../../utils/helpers';
import Note from './Note';
import Loader from '../../../../../../components/Loader';
import GenericSelect from '../../../../../../components/UI/GenericSelect';
import Button from "../../../../../../components/Common/Buttons/Button";


class Notes extends Component {
	state = {
		isFilterDropDownOpen: false,
		displayFilters: true,
		filters: {
			accountId: this.props.accountId,
			categoryId: [],
			createdBy: '',
			fromDate: '',
			toDate: '',
			limit: 10,
			searchNotes: ''
		},
		filterType: 'limit',
		filterValue: '10',
		defaultNotesCategoriesValue: [],
		filteredNotes: []
	};

	componentDidMount() {
		this.props.getNoteCategories().then((response) => {
			let filteredNotesCategories = response
				.filter((categorie) => categorie.type === 'ACCOUNT' && categorie.isDefault === true)
				.map((x) => x.id);
			let defaultNotesCategoriesValue = response
				.filter((categorie) => categorie.type === 'ACCOUNT' && categorie.isDefault === true)
				.map((x) => {
					return {
						value: x.id,
						label: x.description
					};
				});
			let copyFilters = { ...this.state.filters };
			copyFilters['categoryId'] = filteredNotesCategories;

			this.setState(
				{
					filters: copyFilters,
					defaultNotesCategoriesValue: defaultNotesCategoriesValue
				},
				() => this.sendGetAccountNotes()
			);
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.accountNotes !== this.props.accountNotes) {
			this.setState({filteredNotes: this.props.accountNotes})
		}
	}

	componentWillUnmount() {
		this.props.emptyAccountNotes();
	}

	handleClickDropdown = () => {
		this.setState((prevState) => ({
			isFilterDropDownOpen: !prevState.isFilterDropDownOpen
		}));
	};

	handleLimitFilter = (limit) => {
		let updateFilter = { ...this.state.filters };
		updateFilter['limit'] = limit;
		// remove date value
		updateFilter['toDate'] = '';
		updateFilter['fromDate'] = '';

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'limit',
				filterValue: limit
			},
			() => this.sendGetAccountNotes()
		);
	};

	handleLastDaysFilter = (lastDays) => {
		let fromDate = moment().subtract(lastDays, 'days').toDate();
		let toDate = moment().toDate();

		let updateFilter = { ...this.state.filters };
		updateFilter['fromDate'] = fromDate;
		updateFilter['toDate'] = toDate;
		updateFilter['limit'] = 10;

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'lastDays',
				filterValue: lastDays
			},
			() => this.sendGetAccountNotes()
		);
	};

	handleDateFilter = (value, name) => {
		let updateFilter = { ...this.state.filters };
		updateFilter[name] = value;
		updateFilter['limit'] = 10;

		this.setState(
			{
				filters: updateFilter,
				isFilterDropDownOpen: false,
				filterType: 'dateFromTo'
			},
			() => this.sendGetAccountNotes()
		);
	};

	handleSelectDate = () => {};

	readableDateFormat = () => {
		const { filters } = this.state;
		let parseDateFormat = '';
		parseDateFormat += filters.fromDate && moment(filters.fromDate).format('MM/DD/YYYY');
		parseDateFormat += filters.toDate && ' - ' + moment(filters.toDate).format('MM/DD/YYYY');

		return parseDateFormat;
	};


	getDefaultNote = ()  => {
		let defaultNote = {
			value: "Please Select A Category",
			id: ''
		}
		let noteCategories = this.props.accountNoteCategories;
		{noteCategories.map((category) => {
			if (category.type === 'ACCOUNT' && category.active === true
				&& category.isDefault === true) {
				defaultNote.value = category.description;
				defaultNote.id = category.id;
			}
		})}
		return defaultNote;

	};

	showNoteModal = (type, noteId, noteCategoryId, note) => {
		if (type === 'createNote') {
			let modalProps = {
				accountId: this.props.accountId,
				type,
				filters: this.prepareFiltersForSending(),
				defaultNote: this.getDefaultNote()
			};
			this.props.showModal('ACCOUNT_NOTE_MODAL', modalProps);
		} else if (type === 'editNote') {
			let modalProps = {
				accountId: this.props.accountId,
				type,
				noteId,
				noteCategoryId,
				note,
				filters: this.prepareFiltersForSending(),
				defaultNote: this.getDefaultNote()
			};
			this.props.showModal('ACCOUNT_NOTE_MODAL', modalProps);
		}
	};

	handleNotesCategoriesChange = (values) => {
		this.setState(
			{
				filters: values
			}
		);
	};

	sendGetAccountNotes = () => {
		let copyFilters = { ...this.state.filters };

		if (copyFilters['fromDate'] !== '') {
			copyFilters['fromDate'] = moment(copyFilters['fromDate']).format('YYYY-MM-DD');
		}
		if (copyFilters['toDate'] !== '') {
			copyFilters['toDate'] = moment(copyFilters['toDate']).format('YYYY-MM-DD');
		}

		for (let filter in copyFilters) {
			if (isEmpty(copyFilters[filter])) {
				delete copyFilters[filter];
			}
		}
		this.props.getAccountNotes(this.props.accountId, copyFilters);
	};

	prepareFiltersForSending = () => {
		let copyFilters = { ...this.state.filters };
		copyFilters = {...copyFilters, searchNotes: ''}

		if (copyFilters['fromDate'] !== '') {
			copyFilters['fromDate'] = moment(copyFilters['fromDate']).format('YYYY-MM-DD');
		}
		if (copyFilters['toDate'] !== '') {
			copyFilters['toDate'] = moment(copyFilters['toDate']).format('YYYY-MM-DD');
		}

		for (let filter in copyFilters) {
			if (isEmpty(copyFilters[filter])) {
				delete copyFilters[filter];
			}
		}

		return copyFilters;
	};

	render() {
		const { filters, isFilterDropDownOpen, filterType, displayFilters, filteredNotes } = this.state;
		const { accountNotes, accountNotesLoader, accountNoteCategories } = this.props;

		let transformedNoteCategories = [];
		if (accountNoteCategories.length !== 0) {
			transformedNoteCategories = accountNoteCategories.map((category) => {
				return {
					value: category.id,
					label: category.description
				};
			});
		}

		return (
			<div className="tab-pane fade show active" role="tabpanel" aria-labelledby="account-notes-tab">
				<div className="container-client-notes">
					<div className="tab-pane-toolbar">
						<div className="dropdown dropdown-cn-timefilter show">
							<button
								onClick={this.handleClickDropdown}
								className={
										'btn btn-outline-secondary dropdown-toggle show'
								}
								type="button"
							>
								{filterType === 'limit' && `Last ${this.state.filterValue} Notes`}
								{filterType === 'dateFromTo' && this.readableDateFormat()}
								{filterType === 'lastDays' && `Last ${this.state.filterValue} days`}
							</button>
								<div className='dropdown-menu dropdown-menu-right show' style={{display:`${this.state.isFilterDropDownOpen?'block':'none'}`}}>
								<button onClick={() => this.handleLimitFilter(10)} className="dropdown-item">
									Last 10 Notes
								</button>
								<button onClick={() => this.handleLimitFilter(20)} className="dropdown-item">
									Last 20 Notes
								</button>
								<button onClick={() => this.handleLimitFilter(50)} className="dropdown-item">
									Last 50 Notes
								</button>
								<button onClick={() => this.handleLimitFilter(100)} className="dropdown-item">
									Last 100 Notes
								</button>
								<div className="dropdown-divider" />
								<button onClick={() => this.handleLastDaysFilter(30)} className="dropdown-item">
									Last 30 Days
								</button>
								<button onClick={() => this.handleLastDaysFilter(60)} className="dropdown-item">
									Last 60 Days
								</button>
								<button onClick={() => this.handleLastDaysFilter(90)} className="dropdown-item">
									Last 90 Days
								</button>
								<div className="dropdown-divider" />
								<h6 className="dropdown-header">From</h6>

								<div className="form-group col">
									<div
										className="input-group input-group-sm date"
										id="datetimepicker1"
										data-target-input="nearest"
									>
										<DatePicker
											className="form-control datetimepicker-input"
											selected={filters.fromDate}
											onChange={(value) => this.handleDateFilter(value, 'fromDate')}
											placeholderText="MM/DD/YYYY"
											name="fromDate"
										/>
									</div>
								</div>

								<h6 className="dropdown-header">To</h6>

								<div className="form-group col">
									<div
										className="input-group input-group-sm date"
										id="datetimepicker2"
										data-target-input="nearest"
									>
										<DatePicker
											className="form-control datetimepicker-input"
											selected={filters.toDate}
											onChange={(value) => this.handleDateFilter(value, 'toDate')}
											placeholderText="MM/DD/YYYY"
											name="toDate"
										/>
									</div>
								</div>
								</div>
						</div>
						<button
							type="button"
							className="btn btn-primary btn-cn-add"
							onClick={() => this.showNoteModal('createNote')}
						>
							<i className="fas fa-plus" /> New Note
						</button>
						<div className="w-100" />
						<div className="form-group">
							<label>
								<i className="fas fa-filter" />&nbsp;Filter Notes by
							</label>
							<div className="input-group">
								<input
									placeholder={"Search Notes"}
									type="text"
									className="form-control"
									autoComplete="off"
									id="search"
									name="search"
									onChange={(e) => this.setState({filters: {...this.state.filters, searchNotes: e.target.value}})}
								/>
								<div className="input-group-append">
									<Button
										type="button"
										className="btn btn-primary btn-show-cam-search-results"
										onClick={() => this.sendGetAccountNotes()}
									>
										<i className="fas fa-search"/>
										<span>&nbsp;Search</span>
									</Button>
									<Button
										type="button"
										onClick={() => {
											this.setState({displayFilters: !displayFilters});
										}}
										className="btn btn-outline-secondary"
									>
										{displayFilters ?
											<i className="fas fa-solid fa-ban"/>
											:
											<i className="fas fa-solid fa-filter"/>
										}
										<span>&nbsp;Filters</span>
									</Button>
								</div>
							</div>
							<div style={{paddingTop:'10px'}}>
							{displayFilters &&
								<GenericSelect
									defaultValues={this.state.defaultNotesCategoriesValue}
									name="categoryId"
									filters={filters}
									type="categoryId"
									isMulti
									options={transformedNoteCategories}
									updateFilter={this.handleNotesCategoriesChange}
								/>
							}
							</div>
						</div>
					</div>
					{accountNotesLoader ? (
						<Loader />
					) : filteredNotes.length > 0 ?
						(
						<ul className="client-notes">
							{filteredNotes.map((note) => (
								<Note noteInfo={note} key={note.id} showNoteModal={this.showNoteModal} />
							))}
						</ul>
					)
						:
						<div className="container">
							<p>No data found</p>
						</div>
					}
				</div>
			</div>
		);
	}
}

Notes.propTypes = {
	accountId: PropTypes.number.isRequired,
	accountNotes: PropTypes.array.isRequired,
	accountNotesLoader: PropTypes.bool,
	accountNoteCategories: PropTypes.array.isRequired,
	showModal: PropTypes.func,
	getAccountNotes: PropTypes.func,
	getNoteCategories: PropTypes.func,
	emptyAccountNotes: PropTypes.func
};

const getAccountNotesLoader = createLoadingSelector([ 'SET_ACCOUNT_NOTES' ]);

const mapStateToProps = (state) => {
	const accountNotes = getAccountDetails(state).accountNotes.notes,
		accountNoteCategories = getAccountDetails(state).accountNotes.noteCategories,
		accountNotesLoader = getAccountNotesLoader(state);
	return {
		accountNotes,
		accountNoteCategories,
		accountNotesLoader
	};
};

const mapDispatchToProps = {
	showModal,
	getAccountNotes,
	getNoteCategories,
	emptyAccountNotes
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
